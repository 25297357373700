// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/**\n * @copyright CBI cbi@chinacbi.com\n * @description 全局主题变量配置，VIP文档内提供多种好看的配色方案（ant-design风格、layui风格、iview风格），请查看VIP文档主题配置篇\n */\n/* stylelint-disable */\n\n.inner-circles-loader:not(:required) {\n  position: relative;\n  display: inline-block;\n  width: 50px;\n  height: 50px;\n  margin-bottom: 10px;\n  overflow: hidden;\n  text-indent: -9999px;\n  background: rgba(25, 165, 152, 0.5);\n  border-radius: 50%;\n  transform: translate3d(0, 0, 0);\n}\n\n.inner-circles-loader:not(:required)::before,\n.inner-circles-loader:not(:required)::after {\n  position: absolute;\n  top: 0;\n  display: inline-block;\n  width: 50px;\n  height: 50px;\n  content: \"\";\n  border-radius: 50%;\n}\n\n.inner-circles-loader:not(:required)::before {\n  left: 0;\n  background: #c7efcf;\n  transform-origin: 0 50%;\n  animation: inner-circles-loader 3s infinite;\n}\n\n.inner-circles-loader:not(:required)::after {\n  right: 0;\n  background: #eef5db;\n  transform-origin: 100% 50%;\n  animation: inner-circles-loader 3s 0.2s reverse infinite;\n}\n\n@keyframes inner-circles-loader {\n  0% {\n    transform: rotate(0deg);\n  }\n  50% {\n    transform: rotate(360deg);\n  }\n  100% {\n    transform: rotate(0deg);\n  }\n}", ""]);
// Exports
exports.locals = {
	"menu-color": "hsla(0deg, 20%, 98%, 0.95)",
	"menu-color-active": "hsla(0deg, 20%, 98%, 0.95)",
	"menu-background": "#869ac2"
};
module.exports = exports;
