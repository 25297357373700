import axios from "axios";
import {
  baseURL,
  contentType,
  invalidCode,
  messageDuration,
  noPermissionCode,
  requestTimeout,
  successCode,
  debounce,
} from "@/config/settings";
import { Loading, Message, MessageBox } from "element-ui";
import store from "@/store";
import qs from "qs";
import router from "@/router";
import _ from "lodash";
import { isArray, isNumber } from "@/utils/validate";
import { returnBitTimestamp, uuid } from "@/utils/index";

const service = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    "Content-Type": contentType,
  },
});

let loadingInstance;

let resCount = 0;
service.interceptors.request.use(
  (config) => {
    if (store.getters["user/accessToken"]) {
      config.headers["X-Token"] = store.getters["user/accessToken"];
      config.headers["Authorization"] =
        "Bearer " + store.getters["user/accessToken"];
    }
    if (config.data) {
      //拦截日期与uuid，给后端进行传输
      let header = config.data.header;
      if (header !== undefined) {
        if (header.currentTime !== undefined) {
          header.currentTime = returnBitTimestamp(new Date());
        }
        if (header.currentTime !== undefined) {
          header.requestId = uuid();
        }
      }
      //这里会过滤所有为空、0、fasle的key，如果不需要请自行注释
      config.data = _.pickBy(config.data, _.identity);
    }
    if (process.env.NODE_ENV !== "preview") {
      if (contentType === "application/x-www-form-urlencoded;charset=UTF-8") {
        if (config.data) {
          config.data = qs.stringify(config.data);
        }
      }
    }
    /** kyle add 用于区分auth和site */
    if (
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "preview"
    ) {
      if (config.url.includes("/cbi-auth-provider/")) {
        config.baseURL = "";
      }
    }
    const needLoading = () => {
      let status = false;
      debounce.forEach((item) => {
        if (_.includes(config.url, item)) {
          status = true;
        }
      });
      return status;
    };
    if (needLoading()) {
      loadingInstance = Loading.service();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const errorMsg = (message) => {
  return Message({
    message: message,
    type: "error",
    duration: messageDuration,
  });
};
const infoMsg = (message) => {
  return Message({
    message: message,
    type: "info",
    duration: messageDuration,
  });
};

service.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    const { status, data, config } = response;
    const { code, msg } = data;
    let codeVerification = false;

    if (isNumber(successCode)) {
      codeVerification = code !== successCode;
    }
    if (isArray(successCode)) {
      for (let i = 0; i < successCode.length; i++) {
        if (code === i) {
          codeVerification = code !== i;
          break;
        }
      }
    }

    if (codeVerification) {
      switch (code) {
        case invalidCode:
          errorMsg(msg || `系统${code}异常`);
          // store.dispatch("user/resetAccessToken");
          break;
        case noPermissionCode:
          router.push({
            path: "/401",
          });
          break;
        case 403:
          errorMsg(msg || `系统${403}异常`);
          store.dispatch("user/resetAccessToken");
          router.push({
            path: "/login",
          });
        default:
          errorMsg(msg || `系统${code}异常`);
          break;
      }
      return Promise.reject(
        "cbi-site-front请求异常拦截:" +
          JSON.stringify({ url: config.url, code, msg }) || "Error"
      );
    } else {
      return data;
    }
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    console.log("err" + error, JSON.parse(JSON.stringify(error)));
    const errorObj = JSON.parse(JSON.stringify(error));
    console.log(error.response);
    /*网络连接过程异常处理*/
    let { message } = error;
    if (message == "Network Error") {
      message = "系统连接异常";
    }
    if (message.includes("timeout")) {
      message = "系统请求超时";
    }
    if (message.includes("Request failed with status code")) {
      if (message.substr(message.length - 3) === "910") {
        if (resCount === 0) {
          MessageBox.confirm("账号在别处登录，请重新登录", "系统提示", {
            confirmButtonText: "重新登录",
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: "warning",
          }).then(() => {
            store.dispatch("user/resetAccessToken").then(() => {
              location.reload(); // 为了重新实例化vue-router对象 避免bug
            });
          });
        }
        resCount++;
      } else if (message.substr(message.length - 3) === "401") {
        if (error.response.data.error === "unauthorized") {
          message = `${error.response.data.error_description}`;
          if (message.includes("user_not_exist")) {
            message = "您当前登录的用户信息不存在";
          }
        } else {
          if (resCount === 0) {
            MessageBox.confirm("会话失效，请您重新登录", "系统提示", {
              confirmButtonText: "重新登录",
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              type: "warning",
            }).then(() => {
              store.dispatch("user/resetAccessToken").then(() => {
                location.reload(); // 为了重新实例化vue-router对象 避免bug
              });
            });
          }
          resCount++;
          // return Promise.reject(error);
        }
      } else {
        message = "系统" + message.substr(message.length - 3) + "异常";
      }
    }
    errorMsg(message || "系统未知异常");
    return Promise.reject(error);
  }
);
export default service;
