<template>
  <el-card style="margin-bottom: 20px">
    <div slot="header" class="clearfix">
      <span class="personCenter-title">我的个人中心</span>
    </div>

    <div class="user-profile">
      <div class="box-center">
        <pan-thumb
          :image="ossDomain + avatar"
          :height="'100px'"
          :width="'100px'"
          :hoverable="false"
        >
          <div style="margin-top: 20px">活力满满</div>
        </pan-thumb>
      </div>
      <div class="box-center">
        <div class="user-name text-center">{{ "你好,  " + userName }}</div>
      </div>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="imagecropperShow = true"
      >
        点击更换头像
      </el-button>
    </div>
    <image-cropper
      v-show="imagecropperShow"
      :key="imagecropperKey"
      :user-no="userNo"
      :width="300"
      :height="300"
      :no-rotate="false"
      :url="ossDomain"
      @close="close"
      @crop-upload-success="cropSuccess"
    />
  </el-card>
</template>

<script>
  import PanThumb from "./panThumb";
  import ImageCropper from "@/components/ImageCropper";
  import { updateUserInfo } from "@/api/user";
  import { returnBitTimestamp } from "@/utils/index";
  import { mapGetters } from "vuex";

  const OSS = require("ali-oss");
  export default {
    components: { ImageCropper, PanThumb },
    data() {
      return {
        // loading
        submitLoading: false,
        currentTime: returnBitTimestamp(new Date()),
        // 组件是否显示
        imagecropperShow: false,
        // 组件id
        imagecropperKey: 0,
        // 返回的头像信息
        image: "",
        // 请求参数
        queryForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        userName: "user/userName",
        avatar: "user/avatar",
        userNo: "user/userNo",
        ossDomain: "user/endpoinxBak",
        bucket: "user/bucketName",
        region: "user/region",
        accessKeyId: "user/accessKeyId",
        accessKeySecret: "user/accessKeySecret",
      }),
    },
    methods: {
      cropSuccess(fileSource) {
        this.submitLoading = true;
        this.imagecropperShow = false;
        this.imagecropperKey = this.imagecropperKey + 1;
        this.image = fileSource;
        const client = new OSS({
          bucket: this.bucket,
          region: this.region,
          accessKeyId: this.accessKeyId,
          accessKeySecret: this.accessKeySecret,
        });
        (this.queryForm.body.userNo = this.userNo),
          (this.queryForm.body.avatar = this.image),
          updateUserInfo(this.queryForm)
            .then(async (response) => {
              if (response.header.retCode === "1") {
                if (
                  !this.avatar.includes(this.image) &&
                  !this.avatar.includes(
                    "temp_default_avatar/avatar_template.gif"
                  )
                ) {
                  let targetUrl = this.avatar.replace(
                    `${this.ossDomain}cbi-site-front/avatar/`,
                    ""
                  );
                  let res = await client.delete(targetUrl);
                }
                this.$store.dispatch("user/getInfo").then(() => {
                  this.submitLoading = false;
                  this.$message({
                    type: "success",
                    message: "头像上传成功！",
                  });
                });
              } else {
                this.$message.error(response.header.retMessage);
              }
            })
            .catch((error) => {
              this.$message.error(error);
              this.submitLoading = false;
            });
      },
      close() {
        this.imagecropperShow = false;
      },
    },
  };
</script>

<style scoped>
  .el-card /deep/.el-card__header {
    padding: 6px 20px;
    background-color: #f3f3f3;
  }
</style>

<style lang="scss" scoped>
  .personCenter-title {
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: #666;
    text-align: left;
  }
  .box-center {
    margin: 0 auto;
    display: table;
  }

  .text-muted {
    color: #777;
  }

  .user-profile {
    text-align: center;
    .user-name {
      font-weight: bold;
    }

    .box-center {
      padding-top: 10px;
    }

    .user-role {
      padding-top: 10px;
      font-weight: 400;
      font-size: 14px;
    }

    .box-social {
      padding-top: 30px;

      .el-table {
        border-top: 1px solid #dfe6ec;
      }
    }

    .user-follow {
      padding-top: 20px;
    }
  }

  .user-bio {
    margin-top: 20px;
    color: #606266;

    span {
      padding-left: 4px;
    }

    .user-bio-section {
      font-size: 14px;
      padding: 15px 0;

      .user-bio-section-header {
        border-bottom: 1px solid #dfe6ec;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
  }
</style>
