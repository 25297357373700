<template>
  <div>
    <el-dialog :title="title"
               class="myDialog"
               :visible.sync="editFormVisible"
               :close-on-click-modal="false"
               append-to-body
               width="60%"
               :fullscreen="fullFlag"
               @close="handleClose">
      <el-backtop target=".myDialog"></el-backtop>
      <i style="position: absolute; top: 24px; right: 41px; cursor: pointer"
         :class="fullIcon"
         :title="fullTitle"
         @click="triggerFullOrHelf"></i>
      <div style="margin-bottom: 10px">{{ companyName }}</div>
      <el-tabs v-model="activeName"
               type="border-card">
        <el-tab-pane label="工单详情"
                     class="edit_title"
                     name="detail">
          <!--工单进度-->
          <order-process :param="param"></order-process>

          <!--基础信息>-->
          <div class="title">基础信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="workOrderNo"
                             label="工单编号"
                             align="center"></el-table-column>
            <el-table-column prop="brWorkNo"
                             label="订单编号"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.brWorkNo || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="reportType"
                             label="报告类型"
                             :class-name="brChange('reportTypeFlag') + ' '+ brChange('subReportTypeFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportType || "-" }}
                {{ scope.row.subReportType ? '/' : ''}}
                {{ scope.row.subReportType}}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="projectSource"
                             label="项目来源"
                             align="center">
              <template slot-scope="scope">
                {{ getProjectSource(scope.row.projectSource) || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             :class-name="brChange('subjectFlag')"
                             align="center"></el-table-column>
            <el-table-column prop="targetAddr1"
                             label="目标地址1"
                             :class-name="brChange('subjectAddressFlag')"
                             align="center"></el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="targetAddr2"
                             label="目标地址2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetContact"
                             label="目标联系人"
                             :class-name="brChange('subjectLinkNameFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetContact || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="contactNumber"
                             label="联系电话"
                             :class-name="brChange('subjectMobileFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.contactNumber || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="duty"
                             label="职务"
                             :class-name="brChange('dutyFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.duty || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderTime"
                             label="下单时间"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.orderTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             align="center"
                             :formatter="eighthBitTime"></el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="clientAbbr"
                             label="委托方简称"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.clientAbbr || "-" }}
              </template>
            </el-table-column>

            <el-table-column prop="reportTimeLimit"
                             label="报告时限"
                             :class-name="brChange('reportTimeLimitFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportTimeLimit || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark1"
                             label="备注1"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark1 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="remark2"
                             label="备注2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark3"
                             label="备注2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark3 || "-" }}
              </template>
            </el-table-column>
            <el-table-column></el-table-column>
          </el-table>
          <!--附加信息>-->
          <div class="title">附加信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectVisitDate"
                             label="预计走访日期"
                             align="center"
                             :formatter="eighthBitTime"></el-table-column>
            <el-table-column prop="expectServeyDay"
                             label="预计所需调查天数"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectServeyDay || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="needUser"
                             label="所需人手"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.needUser || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectHour"
                             label="预计需时（小时）"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectHour || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="registerAddress"
                             label="注册地址"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.registerAddress || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubjectRegStatus"
                             label="目标主体注册状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetSubjectRegStatus || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--特别信息>-->
          <div class="title">特别信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="plSpecialMsg"
                             label="PL特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.plSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="bookSpecialMsg"
                             label="预约特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.bookSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="monitorSpecialMsg"
                             label="督导特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.monitorSpecialMsg || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--附件-->
          <div v-show="annexGroup.length !== 0"
               class="title">附件</div>

          <el-row style="text-align: center; margin-top: 20px">
            <div v-for="(item, index) in annexGroup"
                 :key="index">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="6">
                <a @click.stop.prevent="
                    handleDownload({ name: item.fileName, path: item.path })
                  ">
                  <img src="@/icon/annex.svg"
                       alt=""
                       width="80"
                       height="80" />
                  <p>{{ item.fileName }}</p>
                </a>
              </el-col>
            </div>
          </el-row>

          <!--操作信息>-->
          <div class="title">操作信息</div>

          <el-table :data="operationInfo"
                    stripe
                    border>
            <el-table-column prop="operator"
                             label="操作者"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operator || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="role"
                             label="角色"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.role || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="operAction"
                             label="操作状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operAction || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime"
                             label="操作时间"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.createTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark"
                             label="备注"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--督导操作>-->
          <div class="title">督导操作</div>
          <el-form ref="editForm"
                   :model="editForm"
                   :rules="rules"
                   class="table-class demo-form-inline">
            <el-row :gutter="80">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="是否联系目标"
                              prop="contactTargetFlag">
                  <el-select v-model="editForm.contactTargetFlag"
                             placeholder="请选择是否联系目标"
                             clearable
                             filterable>
                    <el-option v-for="item in contactOrNot"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="特别留言"
                              prop="monitorSpecialMsg">
                  <el-input v-model="editForm.monitorSpecialMsg"
                            type="textarea"
                            class="my-textarea"
                            placeholder="请输入特别留言"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="小记"
                              prop="note">
                  <el-input v-model="editForm.note"
                            type="textarea"
                            class="my-textarea"
                            placeholder="请输入小记"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="查看小记">
          <remark ref="remark"
                  :param="param"></remark>
        </el-tab-pane>
        <el-tab-pane label="退回记录">
          <back ref="back"
                :order="orderNo"></back>
        </el-tab-pane>
        <el-tab-pane label="企业信息">
          <company ref="company"
                   :row="object"></company>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="success"
                   :loading="saveLoading"
                   @click="save">
          保 存
        </el-button>
        <el-button type="primary"
                   @click="submit">提 交</el-button>
      </div>
    </el-dialog>
    <!--提交工单-->
    <submit ref="submit"
            @close="close"
            @refreshData="refreshData"></submit>
  </div>
</template>

<script>
import remark from "@/views/workplace/components/remark";
import back from "@/views/workplace/components/back";
import company from "@/views/workplace/components/company";
import submit from "./submit";
import orderProcess from "@/views/workplace/components/orderProcess";

import {
  getOrderDetail,
  getOperationInfo,
  getUsersListByRole,
  edit,
  getRecommend,
} from "@/api/workplace/supervison/recommend";
import { mapGetters } from "vuex";
import {
  getRoleKey,
  returnBitTimestamp,
  eighthBitTime,
  downloadFile,
  isExistOSS,
  getProjectSource,
} from "@/utils/index";
import { getAttach } from "@/api/workplace/investigate/investigate";
import { searchAttach } from "@/api/workplace/tools";
export default {
  name: "Edit",
  components: {
    remark,
    back,
    company,
    submit,
    orderProcess,
  },
  data() {
    return {
      saveLoading: false,
      annexGroup: [],
      title: "",
      // 全/半屏提示文本
      fullTitle: "最大化",
      // 全/半屏按钮图标
      fullIcon: "el-icon-full-screen",
      // 是否全屏标识
      fullFlag: false,
      editFormVisible: false,
      tableData: [],
      orderNo: null,
      rules: {
        surveyUser: [
          { required: true, trigger: "blur", message: "请选择调查人员" },
        ],
        note: [
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
        monitorSpecialMsg: [
          {
            max: 2000,
            message: "特别留言不能超过2000个汉字",
            trigger: "blur",
          },
        ],
      },
      editForm: {
        contactTargetFlag: "",
        monitorSpecialMsg: "",
        surveyUser: "",
        note: "",
      },
      contactOrNot: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],

      // 获取工单详情
      param: {
        body: {
          pageNum: 1,
          pageSize: 10,
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },

      // 编辑给后端的接口参数
      editParam: {
        body: {
          note: null,
          contactTargetFlag: 0,
          nextUserNo: "",
          nextUserRoleNo: "",
          monitorSpecialMsg: null,
          step: "督导工作台-人员推荐-待处理",

          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 提交人员推荐参数
      submitParam: {
        body: [],
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      obj: {
        console: "supervisor", // 固定写法
        note: null,
        operAction: "人员推荐", // 固定写法
        projectSource: "",
        step: "督导工作台-人员推荐-待处理", // 固定写法
        surveyUser: null,
        bookUser: null,
        userNo: "",
        userRoleNo: "",
        workOrderNo: null,
        procInsId: null,
        taskId: null,
      },
      // 修改成功之后返显推荐人员信息
      editReturnParam: {
        body: {
          workOrderNo: null,
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      operationInfo: [],
      // 查询工单的操作日志
      logParam: {
        body: {
          workOrderNo: "",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 给子组件photo传值
      object: null,
      // 工单附件集合
      attachs: [],
      activeName: "detail",
      companyName: null,
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
      endpoinxBak: "user/endpoinxBak",
      OSSClient: "user/OSSClient",
    }),
  },
  created() {
    this.obj.userNo = this.userNo;
    this.obj.userRoleNo = getRoleKey("supervisor", this.roles);
    this.param.body.userNo = this.userNo;
    this.param.body.userRoleNo = getRoleKey("supervisor", this.roles);
    this.editParam.body.userNo = this.userNo;
    this.editParam.body.userRoleNo = getRoleKey("supervisor", this.roles);
  },
  methods: {
    refreshData() {
      this.$emit("refreshData");
    },
    async showEdit(row) {
      this.tableData = [];
      this.title = "推荐人员";
      this.param.body.workOrderNo = row.workOrderNo;
      this.logParam.body.workOrderNo = row.workOrderNo;
      this.orderNo = row.workOrderNo;
      this.object = row;
      // 获取工单详情
      this.tableData.push(
        Object.assign({}, (await getOrderDetail(this.param)).body)
      );
      this.companyName = this.tableData[0].targetSubject;
      // 获取操作工单日志信息
      this.operationInfo = (await getOperationInfo(this.logParam)).body;

      this.editForm = Object.assign({}, row);

      // 修改成功之后返显推荐人员信息
      this.editReturnParam.body.workOrderNo = row.workOrderNo;
      let result = Object.assign(
        {},
        (await getRecommend(this.editReturnParam)).body
      );
      this.editForm.contactTargetFlag = result.contactTargetFlag;
      this.$set(this.editForm, "monitorSpecialMsg", result.monitorSpecialMsg);
      this.editForm.surveyUser = result.surveyUser;
      // 获取工单附件信息
      this.attachs = (await getAttach(this.param)).body;
      this.editFormVisible = true;
      this.saveLoading = false;
      /** 查询附件信息 */
      this.attach();
      setTimeout(() => {
        this.getDetail();
      }, 10);
    },
    /** 详情 */
    getDetail() {
      this.$refs.back.fetchData();
      this.$refs.remark.fetchData();
      this.$refs.company.init();
      this.activeName = "detail";
    },
    /** 查询附件信息 */
    async attach() {
      this.param.body.workOrderNo = this.orderNo;
      this.param.header.currentTime = returnBitTimestamp(new Date());
      const { body, header } = await searchAttach(this.param);
      if (header.retCode === "1") {
        this.annexGroup = body;
      } else {
        this.$baseMessage(header.retMessage, "error");
      }
    },
    close() {
      this.$refs["editForm"].resetFields();
      this.editForm = this.$options.data().editForm;
      this.editFormVisible = false;
    },
    async save() {
      this.saveLoading = true;
      this.editParam.body.contactTargetFlag = this.editForm.contactTargetFlag;
      this.editParam.body.note = this.editForm.note;
      this.editParam.body.monitorSpecialMsg = this.editForm.monitorSpecialMsg;
      this.editParam.body.userNo = this.editForm.monitorUser;
      this.editParam.body.workOrderNo = this.editForm.workOrderNo;
      // 操作记录
      this.editParam.body.operAction = "保存小记";

      const { header } = await edit(this.editParam);
      if ("1" === header.retCode) {
        this.$baseMessage(header.retMessage, "success");
      } else {
        this.$baseMessage(header.retMessage, "error");
      }
      this.$refs["editForm"].resetFields();
      this.editFormVisible = false;
      this.$emit("fetchData");
      this.editForm = this.$options.data().editForm;
    },
    async submit() {
      this.editParam.body.contactTargetFlag = this.editForm.contactTargetFlag;
      this.editParam.body.note = this.editForm.note;
      this.editParam.body.monitorSpecialMsg = this.editForm.monitorSpecialMsg;
      this.editParam.body.userNo = this.editForm.monitorUser;
      this.editParam.body.workOrderNo = this.editForm.workOrderNo;
      // 操作记录
      this.editParam.body.operAction = "人员推荐";

      const { header } = await edit(this.editParam);

      let rows = [];
      this.editForm.note = "";
      rows.push(this.editForm);

      this.$refs["submit"].showSubmit(rows);
      this.$refs["editForm"].resetFields();

      this.$emit("fetchData");
      this.editForm = this.$options.data().editForm;
    },
    eighthBitTime(row, column) {
      return eighthBitTime(row, column);
    },
    /** 当前dialog关闭 */
    handleClose() {
      this.fullFlag = false;
      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
      this.$refs["editForm"].resetFields();
      this.editForm = this.$options.data().editForm;
      this.editFormVisible = false;
    },
    /** 全屏/半屏切换 */
    triggerFullOrHelf() {
      this.fullFlag = !this.fullFlag;
      if (this.fullFlag) {
        this.fullIcon = "el-icon-minus";
        this.fullTitle = "最小化";
      } else {
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      }
    },
    /** 附件下载 */
    handleDownload(item) {
      isExistOSS(this.OSSClient, item.path)
        .then(() => {
          downloadFile(item);
        })
        .catch(() => {
          this.$message.error("当前文件不存在！");
        });
    },
    getProjectSource(code) {
      return getProjectSource(code);
    },
    // 修改内容标黄
    brChange(data) {
      if (
        this.tableData.length &&
        this.tableData[0].siteBrUpdateColumnRes &&
        this.tableData[0].siteBrUpdateColumnRes[data]
      ) {
        return "is-change";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
body {
  font-weight: 700;
  color: #666;
}
.edit_title .title {
  margin: 10px 0;
}
.el-step__title {
  font-size: 14px;
}
.el-step__title.is-process {
  font-weight: bold;
  color: #666;
}
html body .el-table th .cell {
  font-weight: 700;
  color: #666;
}

.el-form-item__label {
  font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100% !important;
}

.el-select,
.el-cascader {
  width: 100%;
}
.my-textarea > textarea {
  height: 32px;
}
.el-table .el-table__body td.is-change {
  background: #ffff9f;
}
</style>
