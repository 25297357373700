var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.orderProcess !== 32 && _vm.orderProcess !== {}
    ? _c(
        "div",
        [
          _c("div", { staticClass: "title" }, [_vm._v("工单进度")]),
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.orderProcess.currentIndex,
                "process-status": "success",
                "align-center": "",
              },
            },
            _vm._l(_vm.orderProcess.scheduleAndTime, function (item, index) {
              return _c("el-step", {
                key: index,
                attrs: {
                  title: item.schedule,
                  description: _vm.dateFormat(item.time),
                },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }