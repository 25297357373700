var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cbi-site-front-wrapper", class: _vm.classObj },
    [
      "horizontal" === _vm.layout
        ? _c(
            "div",
            {
              staticClass: "layout-container-horizontal",
              class: {
                fixed: _vm.header === "fixed",
                "no-tags-bar": _vm.tagsBar === "false" || _vm.tagsBar === false,
              },
            },
            [
              _c(
                "div",
                { class: _vm.header === "fixed" ? "fixed-header" : "" },
                [
                  _c("top-bar"),
                  _vm.tagsBar === "true" || _vm.tagsBar === true
                    ? _c("div", { class: { "tag-view-show": _vm.tagsBar } }, [
                        _c(
                          "div",
                          { staticClass: "vab-main" },
                          [_c("tags-bar")],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vab-main main-padding" },
                [_c("ad"), _c("app-main")],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "layout-container-vertical",
              class: {
                fixed: _vm.header === "fixed",
                "no-tags-bar": _vm.tagsBar === "false" || _vm.tagsBar === false,
              },
            },
            [
              _vm.device === "mobile" && _vm.collapse === false
                ? _c("div", {
                    staticClass: "mask",
                    on: { click: _vm.handleFoldSideBar },
                  })
                : _vm._e(),
              false
                ? _c(
                    "div",
                    { staticClass: "roleSwitch" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "角色选择",
                            size: "mini",
                            filterable: "",
                          },
                          on: { change: _vm.handleRoleChange },
                          model: {
                            value: _vm.roleVal,
                            callback: function ($$v) {
                              _vm.roleVal = $$v
                            },
                            expression: "roleVal",
                          },
                        },
                        _vm._l(_vm.roleOption, function (item) {
                          return _c("el-option", {
                            key: item.roleKey,
                            attrs: {
                              label: item.roleName,
                              value: item.roleId,
                              disabled: item.disabled,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.organizeShow
                ? _c(
                    "div",
                    { staticClass: "organize" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.organize },
                        },
                        [_vm._v("组织架构")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("side-bar"),
              _c(
                "div",
                {
                  staticClass: "vab-main",
                  class: _vm.collapse ? "is-collapse-main" : "",
                },
                [
                  _c(
                    "div",
                    { class: _vm.header === "fixed" ? "fixed-header" : "" },
                    [
                      _c("nav-bar"),
                      _vm.tagsBar === "true" || _vm.tagsBar === true
                        ? _c("tags-bar")
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("ad"),
                  _c("app-main"),
                ],
                1
              ),
            ],
            1
          ),
      _c("el-backtop"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }