<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="文档模板名称" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入文档模板名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传文档模板" prop="type">
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="3"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <label slot="tip" class="el-upload__tip">
            请选择文件上传，单词只可选1份文件
          </label>
        </el-upload>
      </el-form-item>
      <el-form-item label="项目" prop="resource">
        <el-select
          v-model="form.resource"
          placeholder="请选择项目来源"
          clearable
          filterable
        >
          <el-option
            v-for="item in supervisionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文档模板描述" prop="title">
        <el-input
          v-model="form.title"
          type="textarea"
          placeholder="请描述模板"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "Edit",
    data() {
      return {
        loading: false,
        fileList: [],
        form: {
          title: "",
          type: "",
          property: "",
          resource: "",
        },
        rules: {
          title: [
            { required: true, trigger: "blur", message: "请输入项目名称" },
          ],
          type: [
            { required: true, trigger: "blur", message: "请选择项目类型" },
          ],
          resource: [
            { required: true, trigger: "blur", message: "请选择项目来源" },
          ],
        },
        title: "",
        dialogFormVisible: false,

        supervisionOptions: [],
      };
    },
    created() {},
    methods: {
      showEdit(row) {
        this.title = "编辑文档模板";
        this.form = Object.assign({}, row);
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("fetchData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            const { msg } = await doEdit(this.form);
            this.$baseMessage(msg, "success");
            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("fetchData");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
            files.length + fileList.length
          } 个文件`
        );
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
