<!-- Kyle 拍摄图片 组件 -->
<template>
  <el-card class="box-card"
           shadow="never">
    <div class="size-tips">已选：{{ selectedPicSize | formatFileSize }}</div>
    <!-- 头部导航 -->
    <vab-query-form>
      <vab-query-form-left-panel class="checkAllBox"
                                 :span="16">
        <el-checkbox-group v-model="checkAll"
                           :max="1"
                           @change="handleCheckAllChange">
          <el-checkbox label="all">全选</el-checkbox>
          <el-checkbox label="nosystem">全选自定义</el-checkbox>
          <el-checkbox label="system">全选配置</el-checkbox>
        </el-checkbox-group>
        <el-popover :disabled="!isBeyondLimmit"
                    placement="bottom-start"
                    trigger="click"
                    style="margin: 5px">
          <el-tag size="mini"
                  class="el-icon-info"
                  type="warning"
                  :style="{
              'font-size': '14px',
            }">
            图片单次压缩下载大小不可超过1GB(1024 MB)
          </el-tag>
          <el-button v-if="!isBeyondLimmit"
                     slot="reference"
                     :disabled="!pictureList.length"
                     plain
                     size="small"
                     icon="el-icon-download"
                     @click="handleShowDownloadDialog">
            下载
          </el-button>
          <el-button v-else
                     slot="reference"
                     plain
                     size="small"
                     icon="el-icon-download">
            下载
          </el-button>
        </el-popover>
        <el-button :loading="pdfLoading"
                   :disabled="!albumList.length"
                   @click="handleGeneratePDF(1)">
          生成PDF
        </el-button>
        <el-button :loading="reportLoading"
                   :disabled="!albumList.length"
                   @click="handleGeneratePDF(2)">
          生成报告+pdf
        </el-button>
        <!-- 调查工作台、PL、经理和督导工作台调整兼职调查人员有<水印开关> -->
        <vab-icon v-if="
            row.workplace === 'PL' ||
            row.workplace === 'manager' ||
            row.workplace === 'investigator' ||
            (row.workplace === 'supervisor' && row.isSurveyUser === 'Y')
          "
                  :icon="['fas', 'eye']"
                  style="font-size: 20px; color: #ccc; cursor: pointer"
                  @click="switchWater(null)"></vab-icon>
        <!-- 预约工作台、调查工作台和督导工作台调整兼职调查人员 -->
        <el-button v-if="
            row.workplace === 'book_person' ||
            row.workplace === 'investigator' ||
            (row.workplace === 'supervisor' && row.isSurveyUser === 'Y')
          "
                   type="danger"
                   size="small"
                   icon="el-icon-delete"
                   @click="handleDelete">
          批量删除
        </el-button>
        <!-- 全部确定提交/全部取消状态开关 -->
        <el-switch v-if="
            (row.workplace === 'supervisor' && row.flag === 'edit') ||
            (row.workplace === 'verify_people' && row.flag === 'review')
          "
                   v-model="sumitTag"
                   :active-value="1"
                   :inactive-value="0"
                   @change="switchBatchSubmit"></el-switch>
        <el-popover placement="bottom"
                    width="700"
                    trigger="click"
                    title="查看下载任务状态"
                    style="text-align: center"
                    @show="handleShowTaskPopover"
                    @hide="handleHideTaskPopover">
          <el-table v-loading="downloadTaskLoading"
                    :data="downloadList"
                    :show-header="false"
                    empty-text="暂无下载任务">
            <el-table-column :show-overflow-tooltip="true"
                             min-width="150"
                             prop="fileName" />
            <el-table-column min-width="150"
                             prop="createTime" />
            <el-table-column min-width="100"
                             align="center">
              <template v-slot="slot">
                {{ slot.row.mediaNumber + " 张" }}
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                             min-width="100"
                             align="center">
              <template v-slot="slot">
                <span>{{ slot.row.type | fileTypeFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                             min-width="110"
                             align="center"
                             prop="taskStatusValue" />
            <el-table-column width="90"
                             align="center">
              <template v-slot="slot">
                <el-button v-if="slot.row.type === 0"
                           :disabled="slot.row.taskStatus !== 102"
                           type="text"
                           @click="handleDownloadZip(ossDomain, slot.row.downloadPath)">
                  下载
                </el-button>
                <el-button v-if="slot.row.taskStatus === 103"
                           type="text"
                           @click="handleRetry(slot.row.seqId)">
                  重试
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination small
                         layout="prev, pager, next"
                         :current-page="queryDownloadTaskForm.body.pageNum"
                         :page-size="queryDownloadTaskForm.body.pageSize"
                         :total="downloadTaskTotal"
                         @current-change="handleTaskCurrentChange"></el-pagination>
          <el-button slot="reference"
                     class="task-list-btn"
                     icon="el-icon-download"
                     title="查看下载任务状态"
                     size="mini"></el-button>
        </el-popover>
      </vab-query-form-left-panel>
      <!-- ps: 调查工作台和督导工作台调整兼职调查人员方可操作 -->
      <vab-query-form-right-panel v-if="
          row.workplace === 'book_person' ||
          row.workplace === 'investigator' ||
          (row.workplace === 'supervisor' && row.isSurveyUser === 'Y')
        "
                                  class="上传"
                                  :span="8">
        <el-button type="primary"
                   size="small"
                   icon="el-icon-plus"
                   @click="albumVisible = true">
          新增相册
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>
    <div style="margin: 15px 0"></div>
    <!-- 图片展示 -->
    <el-row>
      <el-col v-for="(photoItem, photoIndex) in photoData"
              ref="siteMediaResource"
              :key="photoIndex">
        <el-row>
          <vab-query-form-left-panel :span="18">
            <div class="title">
              <el-checkbox v-model="photoItem.mychecked"
                           @change="firstChanged(photoIndex, photoItem)"></el-checkbox>
              <span :style="{color: (!photoItem.fromType ? '#2d55ff' : '#000')}"> {{ photoItem.name }}</span>
            </div>
          </vab-query-form-left-panel>
          <!-- ps: 预约工作台、调查工作台和督导工作台调整兼职调查人员方可操作 -->
          <vab-query-form-right-panel v-if="
              row.workplace === 'book_person' ||
              row.workplace === 'investigator' ||
              (row.workplace === 'supervisor' && row.isSurveyUser === 'Y')
            "
                                      :span="6">
            <div class="operationTool"
                 style="text-align: right">
              <a class="add"
                 @click="add(photoItem)">添加</a>
              <a class="rename"
                 @click="rename(photoItem)">重命名</a>
              <a class="del"
                 @click="del(photoItem)">删除</a>
            </div>
          </vab-query-form-right-panel>
          <el-col style="padding: 10px 0 10px">
            <img v-if="photoItem.siteMediaResource.length === 0"
                 src="@/icon/tupian.svg"
                 style="width: 120px; height: 120px"
                 alt="" />
            <draggable v-model="photoItem.siteMediaResource"
                       class="board-column-content"
                       handle=".draggle-element"
                       :options="{ disabled: undraggable }"
                       @end="end(photoItem)">
              <div v-for="(subItem, subIndex) in photoItem.siteMediaResource"
                   :key="subIndex"
                   class="class-detail">
                <div class="draggle-element">
                  <!-- 图片 ——>可预览 -->
                  <el-image v-if="judgeFileType(subItem.path)"
                            style="width: 160px; height: 160px; cursor: pointer"
                            :src="row.endpoinxBak + subItem.path"
                            :fit="fit"
                            :title="photoItem.name"
                            @click="
                      handleView(
                        photoData,
                        photoItem.name,
                        photoIndex,
                        subIndex
                      )
                    "></el-image>
                  <!-- 图片 ——>不可预览 -->
                  <img v-else
                       style="width: 160px; height: 160px; cursor: pointer"
                       src="@/icon/pic.svg"
                       :fit="fit"
                       :title="photoItem.name"
                       @click="
                      handleView(
                        photoData,
                        photoItem.name,
                        photoIndex,
                        subIndex
                      )
                    " />
                </div>
                <el-checkbox v-model="subItem.mychecked"
                             class="img_checkbox"
                             @change="secondChanged(photoIndex, subItem, $event)"></el-checkbox>
                <!-- 0 为有水印， 1 为无水印 -->
                <!-- pl工作台,调查工作台和督导工作台调整兼职调查人员的“非web上传的图片”有<水印开关> -->
                <vab-icon v-if="
                    subItem.watermarkFlag !== null &&
                    (row.workplace === 'PL' ||
                      row.workplace === 'manager' ||
                      row.workplace === 'investigator' ||
                      (row.workplace === 'supervisor' &&
                        row.isSurveyUser === 'Y'))
                  "
                          :icon="['fas', 'eye']"
                          style="
                    font-size: 20px;
                    z-index: 999;
                    position: relative;
                    top: -26px;
                    left: -12px;
                    color: rgb(204, 204, 204);
                    cursor: pointer;
                  "
                          @click="switchWater(subItem, photoItem)"></vab-icon>
                <!-- 提交状态开关 -->
                <el-switch v-if="
                    (row.workplace === 'supervisor' && row.flag === 'edit') ||
                    (row.workplace === 'verify_people' && row.flag === 'review')
                  "
                           v-model="subItem.tag"
                           class="submitSwitch"
                           :active-value="1"
                           :inactive-value="0"
                           @change="switchSubmit(subItem, photoItem)"></el-switch>
                <label-edit v-if="labelEditReload"
                            class="labelEdit"
                            :text="subItem.name"
                            :able-change="operaEnable"
                            @text-updated-blur="
                    textUpdateCallbackBlur($event, subItem, photoItem)
                  "></label-edit>
              </div>
            </draggable>
          </el-col>
        </el-row>
        <div v-if="row.projectSource && row.projectSource === 'process_br'"
             class="intr-box">
          <span>说明 ：</span>
          <el-input v-model="photoItem.remark"
                    type="textarea"
                    :disabled="row.workplace === 'investigator' || (row.workplace === 'supervisor' && row.isSurveyUser === 'Y') ? false: true"
                    @change="savePhotoIntro(photoItem.remark,photoIndex)" />
        </div>
      </el-col>
      <el-col>
        <el-pagination :background="background"
                       :current-page="parameter.body.pageNum"
                       :layout="layout"
                       :page-size="parameter.body.pageSize"
                       :total="total"
                       @current-change="handleCurrentChange"
                       @size-change="handleSizeChange"></el-pagination>
      </el-col>
    </el-row>
    <!-- 图片预览对话框 -->
    <photoView ref="photoView"></photoView>
    <!-- 重命名对话框 -->
    <el-dialog v-drag
               title="相册重命名"
               :visible.sync="renameVisible"
               width="30%"
               append-to-body
               class="renameDialog"
               :close-on-click-modal="false"
               :before-close="renameClose">
      <el-row>
        <el-col :span="24">
          <el-form ref="renameForm"
                   :model="renameForm"
                   :rules="renameFormRules"
                   label-width="80px"
                   label-position="top">
            <el-form-item label="重命名"
                          prop="name">
              <el-input v-model="renameForm.name"
                        type="textarea"
                        placeholder="请输入重新命名之后的名字"
                        clearable></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="renameClose">取 消</el-button>
        <el-button type="primary"
                   @click="renameSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 下载确认对话框 -->
    <el-dialog title="下载确认"
               :visible.sync="downloadVisible"
               width="30%"
               append-to-body
               class="downloadDialog">
      <span>是否要下载所有勾选内容？</span>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="downloadVisible = false">取 消</el-button>
        <el-button :loading="downloadLoading"
                   type="primary"
                   @click="download">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 新增相册 -->
    <el-dialog title="新增相册"
               :visible.sync="albumVisible"
               width="40%"
               append-to-body
               class="albumDialog"
               :close-on-click-modal="false"
               :before-close="albumClose">
      <el-row>
        <el-col :span="24">
          <el-form ref="albumForm"
                   :model="albumForm"
                   :rules="albumFormRules"
                   label-width="80px"
                   label-position="top">
            <el-form-item label="相册名称"
                          prop="name">
              <el-input v-model="albumForm.name"
                        type="textarea"
                        placeholder="请输入新增相册名称"
                        clearable></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="albumClose">取 消</el-button>
        <el-button type="primary"
                   @click="addAlbum">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 批量上传资源对话框 -->
    <el-dialog :title="uploadTitle"
               :visible.sync="openUploadSource"
               width="573px"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :before-close="handleClose"
               append-to-body>
      <el-card class="box-card">
        <el-row align="middle"
                type="flex"
                :gutter="40">
          <el-col :span="24">
            <div style="font-weight: 600; font-size: 15px; margin-bottom: 10px">
              请您上传资源
            </div>
            <div>
              <el-upload ref="upload"
                         class="upload-demo"
                         action="#"
                         :on-error="handleError"
                         :http-request="batch"
                         :before-upload="handelBeforeUpload"
                         :on-remove="handleRemove"
                         :auto-upload="true"
                         multiple
                         accept=".png, .jpg, .jpeg, .bmp, .tif, .ofd,.PNG, .JPG, .JPEG, .BMP, .TIF, .OFD"
                         :file-list="fileList"
                         drag>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将图片拖到此处，或
                  <em>点击上传</em>
                </div>
                <div slot="tip"
                     class="el-upload__tip">
                  支持的图片类型包括：.png, .jpg, .jpeg, .bmp, .tif, .ofd
                </div>
              </el-upload>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button ref="$complete"
                   :loading="subLoading"
                   type="primary"
                   :disabled="submitDisabled"
                   @click="photoSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
const OSS = require("ali-oss");
import axios from "axios";
import labelEdit from "@/plugins/labelEdit";
import {
  queryGroupResource,
  updateWater,
  deleteSource,
  plusAlbum,
  uploadSource,
  delAlbum,
  renameAlbum,
  updateSequence,
  /**提交 */
  updateSubmit,
  // 批量切换提交接口
  updateBatchSubmit,
  checkMediaName,
  checkMediaNameList,
  renameMedia,
  albumToPDF,
  createDownloadTask,
  fetchDownloadTaskList,
  rebuildDownloadTask,
  saveRemark,
} from "@/api/workplace/workDetail";
import photoView from "./photoView";
// 转化当前时间格式
import {
  returnBitTimestamp,
  parseTime,
  eighthBitTimestamp,
  downloadReport,
  getRoleKey,
  isExistOSS,
  /** 文件名称格式校验 */
  fileFormatValid,
} from "@/utils/index";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  name: "Photo",
  components: {
    photoView,
    draggable,
    labelEdit,
  },
  filters: {
    /** 任务类型 */
    fileTypeFilter(val) {
      switch (val) {
        case 1:
          return "生成PDF";
        case 2:
          return "生成报告+PDF";
        default:
          return "生成zip";
      }
    },
    /** 文件大小转换 */
    formatFileSize(byte) {
      if (!byte) {
        return "0 KB";
      }
      const kiloByte = Math.ceil(byte / 1024);
      if (1048576 > kiloByte && kiloByte > 1024) {
        let megaBytes = (kiloByte / 1024).toString();
        const index = megaBytes.indexOf(".");
        if (-1 === index) {
          return `${megaBytes} MB`;
        } else {
          megaBytes = megaBytes.slice(0, index + 3);
          return `${megaBytes} MB`;
        }
      } else if (kiloByte > 1048576) {
        let gigaByte = (kiloByte / 1048576).toString();
        const index = gigaByte.indexOf(".");
        if (-1 === index) {
          return `${gigaByte} GB`;
        } else {
          gigaByte = gigaByte.slice(0, index + 3);
          return `${gigaByte} GB`;
        }
      } else {
        return `${kiloByte} KB`;
      }
    },
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    type: {
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null,
    },
    row: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // 生成PDF的loading
      pdfLoading: false,
      // 生成报告+PDF的loading
      reportLoading: false,
      // 创建下载任务loading
      downloadLoading: false,
      // 任务列表loading
      downloadTaskLoading: false,
      // 下载任务列表定时器
      downloadTimer: null,
      /**影视资源能否编辑名称标识 */
      operaEnable: false,
      // 全部提交/全部取消标记 初始化全部提交
      sumitTag: 1,
      labelEditReload: true,
      // 下载任务总数
      downloadTaskTotal: 0,
      // 下载任务查询参数
      queryDownloadTaskForm: {
        body: {
          pageNum: 1,
          pageSize: 5,
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "111",
          sourceType: "site",
        },
      },
      // 下载任务重试参数
      retryDownloadTaskForm: {
        body: {
          seqId: "",
          userNo: "",
          userRoleNo: "",
        },
        header: {
          currentTime: "",
          requestId: "111",
          sourceType: "site",
        },
      },
      // 下载任务列表
      downloadList: [],
      // 上传资源
      uploadTitle: "",
      checkNameForm: {
        body: {
          groupId: "",
          name: "",
          workOrderNo: "",
          type: "",
        },
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "",
        },
      },
      // 已勾选的图片
      pictureList: [],
      // 已勾选图片的大小
      selectedPicSize: 0,
      // 已勾选的相册
      albumList: [],
      // 用于生成PDF的相册信息
      albumTransformForm: {
        body: {
          mediaSeqIds: [],
        },
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "site",
        },
      },
      /** 检验名称批量接口 */
      checkNameListForm: {
        body: [],
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "site",
        },
      },
      updateNameForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "",
        },
      },
      /**添加文件任务数组 */
      fileArr: [],
      /**添加异步任务数组 */
      promiseArr: [],
      // 影视资源能否拖动排序标识
      undraggable: false,
      albumVisible: false,
      downloadVisible: false,
      subLoading: false,
      // 提交按钮禁用标识
      submitDisabled: false,
      fit: "fill",
      photoData: [],
      photoIntro: [],
      maxSortNumber: 0,
      /**分页 */
      background: true,
      total: 0,
      layout: "total, sizes, prev, pager, next, jumper",
      /**重命名 */
      renameVisible: false,
      renameForm: {
        name: "",
        seqId: "",
      },
      renameFormRules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请务必输入需要更改的相册名称",
          },
        ],
      },
      renamePortForm: {
        body: {
          name: "",
          seqId: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "",
        },
      },
      isIndeterminate: false,
      tabPosition: "left",
      /** 上传资源 */
      // 上传资源请求
      openUploadSource: false,
      fileList: [],
      fileNameList: [],
      uploadSourceForm: {
        body: {
          console: "",
          note: "",
          operAction: "",
          siteMediaResourceReqs: [],
          step: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      albumForm: {
        name: "",
      },
      switchForm: {
        body: [],
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      switchSubmitForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      // 批量全部确定提交/全部取消
      switchBatchSubmitForm: {
        body: {
          batchTag: 0,
          console: "",
          groupId: "",
          type: 0,
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      /** 用于新增相册之后，添加照片功能 */
      groupId: "",
      albumpPortForm: {
        body: {
          name: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "",
        },
      },
      albumFormRules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "请务必输入需要上传的相册名称",
          },
        ],
      },
      // 删除图片
      deleteSourceForm: {
        body: {
          seqId: [],
          userNo: "",
          userRoleNo: "",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      // 删除相册
      deleteAlbumForm: {
        body: [
          {
            seqId: "",
          },
        ],
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "site",
        },
      },
      /** 排序 */
      sequenceForm: {
        body: [],
        header: {
          currentTime: "",
          requestId: "111",
          sourceType: "site",
        },
      },
      parameter: {
        body: {
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
          console: "",
          pageNum: 1,
          pageSize: 100,
        },
        header: {
          currentTime: "",
          requestId: "111",
          sourceType: "site",
        },
      },
      // 批量下载
      batchDownload: {
        body: {
          mediaSeqIds: [],
          targetSubject: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "111",
          sourceType: "site",
        },
      },
      uploadNames: [],
      checkAll: [],
    };
  },
  computed: {
    ...mapGetters({
      ossDomain: "user/endpoinxBak",
      OSSClient: "user/OSSClient",
    }),
    // checkAll: {
    //   get() {
    //     let count = 0;
    //     for (let i = 0; i < this.photoData.length; i += 1) {
    //       if (this.photoData[i].mychecked === true) {
    //         count += 1;
    //       } else {
    //         count -= 1;
    //       }
    //     }
    //     if (count === this.photoData.length) {
    //       return ["all"];
    //     } else {
    //       return [];
    //     }
    //   },
    //   set(val) {
    //     return [val];
    //   },
    // },
    // 超出限制标识
    isBeyondLimmit: function () {
      if (1073741824 <= this.selectedPicSize) {
        return true;
      }
      return false;
    },
  },
  updated() {
    this.initOperation();
  },
  beforeDestroy() {
    window.clearInterval(this.downloadTimer);
    this.downloadTimer = null;
  },
  methods: {
    /** 初始化操作权限 */
    initOperation() {
      if (
        this.row.workplace === "book_person" ||
        this.row.workplace === "investigator" ||
        (this.row.workplace === "supervisor" && this.row.isSurveyUser === "Y")
      ) {
        /** 可以编辑名称 */
        this.operaEnable = true;
        /** 可以拖拽 */
        this.undraggable = false;
      } else {
        this.operaEnable = false;
        this.undraggable = true;
      }
    },
    checkData() {
      const emptyList = this.photoData.filter(
        (item) =>
          item.fromType === "system" &&
          !item.remark &&
          !item.siteMediaResource.length
      );
      return emptyList.length > 0 ? false : true;
    },
    /**提交 */
    switchSubmit(photo, photoGroup) {
      this.switchSubmitForm.body = {
        groupId: photoGroup.seqId,
        seqId: photo.seqId,
        tag: photo.tag,
        sortNumber: photo.sortNumber,
        type: photo.type,
        watermarkFlag: photo.watermarkFlag,
        workOrderNo: this.row.workOrderNo,
        associated: photo.associated,
      };
      updateSubmit(this.switchSubmitForm).then((res) => {
        if (res.header.retCode === "1") {
          this.fetchData();
        } else {
          this.$message.error(`${res.header.retMessage}`);
        }
      });
    },
    /**提交 */
    switchBatchSubmit() {
      // 相册为空的情况下
      if (this.photoData.length === 0) {
        this.$message.error(`暂无数据请勿切换提交状态`);
        return;
      }
      this.switchBatchSubmitForm.body.batchTag = this.photoData[0].batchTag;
      this.switchBatchSubmitForm.body.console = this.row.workplace;
      this.switchBatchSubmitForm.body.type = 50;
      this.switchBatchSubmitForm.body.workOrderNo = this.row.workOrderNo;
      updateBatchSubmit(this.switchBatchSubmitForm).then((res) => {
        if (res.header.retCode === "1") {
          this.$message({
            type: "success",
            message: `${res.header.retMessage}`,
          });
          this.fetchData();
        } else {
          this.$message.error(`${res.header.retMessage}`);
        }
      });
    },
    // 排序
    async end(photoGroup) {
      let sort = 0;

      /**配合后端 组合带有sortNum的数组传递，后端根据sortNumber来排序 */
      photoGroup.siteMediaResource.map((item) => {
        item.sortNumber = sort;
        sort++;
        item.userNo = this.row.userNo;
        item.userRoleNo = this.row.userRoleNo;
        item.workOrderNo = this.row.workOrderNo;
        item.groupId = photoGroup.seqId;
      });
      this.sequenceForm.body = photoGroup.siteMediaResource;
      const { header, body } = await updateSequence(this.sequenceForm);
      if (header.retCode === "1") {
        this.fetchData();
      } else {
        this.$message.error(`${header.retMessage}`);
      }
    },
    /** 分页 */
    handleSizeChange(val) {
      this.parameter.body.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.parameter.body.pageNum = val;
      this.fetchData();
    },
    /**更换水印 */
    switchWater(photo, photoGroup) {
      let photoArray = [];
      let allArray = [];
      /** 个体图片更换水印 */
      if (photo) {
        photoArray.push({
          associated: photo.associated,
          groupId: photoGroup.seqId,
          seqId: photo.seqId,
          sortNumber: photo.sortNumber,
          type: photo.type,
          workOrderNo: this.row.workOrderNo,
          watermarkFlag: photo.watermarkFlag,
        });
        this.switchForm.body = photoArray;
        updateWater(this.switchForm).then((res) => {
          if (res.header.retCode === "1") {
            this.fetchData();
          }
        });
        /**批量整体 */
      } else {
        this.photoData.map((item) => {
          if (item.siteMediaResource.length !== 0) {
            item.siteMediaResource.map((i) => {
              allArray.push({
                associated: i.associated,
                groupId: item.seqId,
                seqId: i.seqId,
                sortNumber: i.sortNumber,
                type: i.type,
                workOrderNo: this.row.workOrderNo,
                watermarkFlag: i.watermarkFlag,
              });
            });
          }
        });
        this.switchForm.body = allArray;
        updateWater(this.switchForm).then((res) => {
          if (res.header.retCode === "1") {
            this.fetchData();
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      }
    },
    /** 点击编辑组件update */
    labelEditRefresh() {
      // 移除组件
      this.labelEditReload = false;
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.labelEditReload = true;
      });
    },
    async textUpdateCallbackBlur(text, photo, photoGroup) {
      /** 验证文件名 */
      // if (!fileFormatValid(text)) {
      // 	this.$message.error("名称中不能包含非法特殊字符,请重新修改!")
      // 	this.labelEditRefresh()
      // 	return false
      // }
      /** 名称判空的情况 */
      if (text.length === 0) {
        this.$message.error("名称不能为空,请重新修改!");
        this.labelEditRefresh();
        return false;
      }
      /** 名字一致的情况下 */
      if (text === photo.name) {
        this.labelEditRefresh();
        return false;
      }
      /**验证名称唯一性 */
      this.checkNameForm.body.groupId = photoGroup.seqId;
      this.checkNameForm.body.name = text;
      this.checkNameForm.body.workOrderNo = this.row.workOrderNo;
      this.checkNameForm.body.type = photo.type;
      const { header } = await checkMediaName(this.checkNameForm);
      /** 确认名称唯一的时候修改 */
      if (header.retCode === "1") {
        this.updateNameForm.body = Object.assign(photo, {
          name: text,
          description: text,
          groupId: photoGroup.seqId,
          workOrderNo: this.row.workOrderNo,
          userRoleNo: this.row.userRoleNo,
          userNo: this.row.userNo,
        });
        renameMedia(this.updateNameForm).then((res) => {
          if (res.header.retCode !== "1") {
            this.$message.error(`${res.header.retMessage}`);
          } else {
            this.$message({
              type: "success",
              message: `${res.header.retMessage}`,
            });
          }
          this.fetchData();
        });
      } else {
        this.$message.error(`${header.retMessage}`);
        this.labelEditRefresh();
        return false;
      }
    },
    /** 判断文件类型 */
    judgeFileType(filePath) {
      var testmsg = filePath
        .split("?")[0]
        .substring(filePath.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension2 = testmsg === "jpeg";
      const extension3 = testmsg === "png";
      const extension4 = testmsg === "JPG";
      const extension5 = testmsg === "JPEG";
      const extension6 = testmsg === "PNG";
      return !(
        !extension &&
        !extension2 &&
        !extension3 &&
        !extension4 &&
        !extension5 &&
        !extension6
      );
    },
    /** 展示下载提示遮罩 */
    handleShowDownloadDialog() {
      this.downloadVisible = true;
    },
    /** 创建下载任务 */
    async download() {
      this.batchDownload.body.mediaSeqIds = this.pictureList;
      this.batchDownload.body.type = 0;
      this.batchDownload.body.userRoleNo = this.row.userRoleNo;
      this.batchDownload.body.userNo = this.row.userNo;
      this.batchDownload.body.workOrderNo = this.row.workOrderNo;
      this.batchDownload.body.targetSubject = this.row.targetSubject;
      this.downloadLoading = true;
      try {
        const { header } = await createDownloadTask(this.batchDownload);
        if (header.retCode === "1") {
          this.fetchData();
          this.$message.success(header.retMessage);
        } else {
          this.$message.error(header.retMessage);
        }
        this.downloadLoading = false;
      } catch (error) {
        this.downloadLoading = false;
      }
      this.downloadVisible = false;
    },
    /** 批量删除文件 */
    async handleDelete() {
      let photoArr = [];
      let albumArr = [];
      this.photoData.map((item) => {
        /** 记录删除相册 */
        /** 后端希望用工单号为null表示系统相册 */
        if (item.mychecked === true && item.workOrderNo !== null) {
          albumArr.push({ seqId: item.seqId });
        }
        /** 记录删除图片 */
        if (item.siteMediaResource.length !== 0) {
          item.siteMediaResource.map((i) => {
            if (i.mychecked === true) {
              photoArr.push({
                associated: i.associated,
                groupId: item.seqId,
                fileSource: i.fileSource,
                seqId: i.seqId,
                type: 50,
                userNo: this.row.userNo,
                userRoleNo: this.row.userRoleNo,
                workOrderNo: this.row.workOrderNo,
              });
            }
          });
        }
      });
      if (photoArr.length === 0 && albumArr.length === 0) {
        this.$message.error(`未选中需要删除的文件`);
      } else {
        this.$baseConfirm("你确定要删除当前项吗", null, async () => {
          /** 向实地系统发送删除请求 实为逻辑删除 */
          /***
           * @description 后端删除图片和删除相册为两个不同的接口
           */
          /** 删除图片 */
          if (photoArr.length !== 0) {
            /** deleteSourceForm 配置*/
            this.deleteSourceForm.body = photoArr;
            try {
              let { header } = await deleteSource(this.deleteSourceForm);
              if (header.retCode !== "1") {
                this.$message.error(`${header.retMessage}`);
              }
            } catch (e) {
              this.$message.error(`${e}`);
            }
          }
          /** 删除相册 */
          if (albumArr.length !== 0) {
            this.deleteAlbumForm.body = albumArr;
            try {
              let { header } = await delAlbum(this.deleteAlbumForm);
              if (header.retCode !== "1") {
                this.$message.error(`${header.retMessage}`);
              }
            } catch (e) {
              this.$message.error(`${e}`);
            }
          }
          this.fetchData();
        });
      }
    },
    /** 单行添加相册 */
    add(album) {
      this.groupId = album.seqId;
      // 上传标题
      this.uploadTitle = `请您上传资源至（ ${album.name} ）`;
      this.maxSortNumber =
        album.maxSortNumber !== undefined ? album.maxSortNumber : 0;
      this.openUploadSource = true;
    },
    // 单行删除相册
    del(album) {
      let albumArr = [];
      if (album.fromType === "system" || album.workOrderNo === null) {
        this.$message.error("不能变更当前系统相册!");
      } else {
        albumArr.push({ seqId: album.seqId });
        this.$baseConfirm("你确定要删除当前项吗", null, async () => {
          this.deleteAlbumForm.body = albumArr;
          try {
            let { header } = await delAlbum(this.deleteAlbumForm);
            if (header.retCode !== "1") {
              this.$message.error(`${header.retMessage}`);
            }
          } catch (e) {
            this.$message.error(`${e}`);
          }
          this.fetchData();
        });
      }
    },
    /** 单行重命名 */
    rename(album) {
      if (album.fromType === "system" || album.workOrderNo === null) {
        this.$message.error("不能变更当前系统相册!");
      } else {
        this.renameForm.seqId = album.seqId;
        this.renameForm.name = album.name;
        this.renameVisible = true;
      }
    },
    /** 相册重命名 */
    async renameSubmit() {
      this.$refs["renameForm"].validate((valid) => {
        if (valid) {
          this.renamePortForm.body.name = this.renameForm.name;
          this.renamePortForm.body.seqId = this.renameForm.seqId;
          this.renamePortForm.body.userNo = this.row.userNo;
          this.renamePortForm.body.userRoleNo = this.row.userRoleNo;
          this.renamePortForm.body.workOrderNo = this.row.workOrderNo;
          renameAlbum(this.renamePortForm).then((res) => {
            if (res.header.retCode === "1") {
              this.groupId = res.body.seqId;
              this.$message({
                message: `重命名成功！`,
                type: "success",
              });
              this.fetchData();
              this.renameClose();
            } else {
              this.$message.error(`${res.header.retMessage}`);
              this.renameClose();
            }
          });
        }
      });
    },
    renameClose() {
      this.$refs["renameForm"].resetFields();
      this.renameVisible = false;
    },
    albumClose() {
      this.$refs["albumForm"].resetFields();
      this.albumVisible = false;
    },
    /** 新增相册 */
    async addAlbum() {
      this.$refs["albumForm"].validate((valid) => {
        if (valid) {
          this.albumpPortForm.body.name = this.albumForm.name;
          this.albumpPortForm.body.userNo = this.row.userNo;
          this.albumpPortForm.body.userRoleNo = this.row.userRoleNo;
          this.albumpPortForm.body.workOrderNo = this.row.workOrderNo;
          plusAlbum(this.albumpPortForm).then((res) => {
            if (res.header.retCode === "1") {
              this.groupId = res.body.seqId;
              this.$message({
                message: `新增相册${this.albumForm.name}成功！`,
                type: "success",
              });
              this.fetchData();
              this.uploadTitle = `请您上传资源至（ ${this.albumForm.name} ）`;
              this.albumClose();
              this.openUploadSource = true;
            } else {
              this.$message.error(`${res.header.retMessage}`);
              this.albumClose();
            }
          });
        }
      });
    },
    /**上传 */
    // 上传窗口关闭
    handleClose() {
      this.fileList = [];
      this.uploadSourceForm.body.siteMediaResourceReqs = [];
      this.fileNameList = [];
      this.fileArr = [];
      this.openUploadSource = false;
      this.submitDisabled = false;
    },
    async handelBeforeUpload(file) {
      const self = this;
      let fileTypes = ["jpg", "jpeg", "png", "bmp", "tif", "tiff", "ofd"];
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isType = fileTypes.includes(fileType.toLowerCase());
      const isSize = file.size / 1024 / 1024 < 1024;
      const isSameName = this.fileArr.filter(
        (item) => item.file.name === file.name
      );

      return new Promise((resolve, reject) => {
        /** 判断文件格式 */
        if (!isType) {
          self.$message.error(`上传文件只能是图片格式, 请检查`);
          self.submitDisabled = true;
          reject();
        }
        /*** 判断文件大小 */
        if (!isSize) {
          self.$message.error(`文件大小不能超过 1G, 请检查`);
          self.submitDisabled = true;
          reject();
        }

        /** 判断文件同名 */
        if (isSameName.length > 0) {
          this.$message.error("请勿上传同名文件！");
          self.submitDisabled = true;
          reject();
        }
        resolve(file);
      });
    },
    /** 上传资源 */
    /**
     * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @author   shanshuizinong
     * @param    {object}   option [上传选项]
     * @return   {null}   [没有返回]
     */
    fnUploadRequest(option, index) {
      console.log("fnUploadRequest", option, index);
      return new Promise((resolve, reject) => {
        /** 带有时间戳的文件名 */
        const fileName =
          new Date().getTime() +
          index +
          "." +
          option.file.name.match(/^(.*)(\.)(.{1,8})$/)[3];
        // 获取OSS配置信息
        const uploadConfig = new FormData();
        /**名称统一使用 "cbi-site-front/workplace/ + 工单号 + 影像资料 +文件*/
        uploadConfig.append(
          "key",
          "cbi-site-front/workplace/" +
            this.row.workOrderNo +
            "/photo/" +
            fileName
        );
        uploadConfig.append("OSSAccessKeyId", this.row.accessKeyId);
        uploadConfig.append("policy", this.row.policy);
        uploadConfig.append("Signature", this.row.signature);
        uploadConfig.append("success_action_status", 200);
        uploadConfig.append(
          "name",
          option.file.name.substring(0, option.file.name.lastIndexOf("."))
        );
        /** 表单域 file 必须为最后一个 */
        uploadConfig.append("file", option.file);
        /** uploadSourceForm 配置*/
        this.uploadSourceForm.body.userNo = this.row.userNo;
        this.uploadSourceForm.body.userRoleNo = this.row.userRoleNo;
        this.uploadSourceForm.body.workOrderNo = this.row.workOrderNo;
        this.uploadSourceForm.body.console = this.row.workplace;
        axios
          .post(this.row.endpoinxBak, uploadConfig, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 200) {
              //上传成功 上传的路径就是要使用的路径
              this.uploadSourceForm.body.siteMediaResourceReqs.push({
                console: this.row.workplace,
                path:
                  "cbi-site-front/workplace/" +
                  this.row.workOrderNo +
                  "/photo/" +
                  fileName,
                name: option.file.name.substring(
                  0,
                  option.file.name.lastIndexOf(".")
                ),
                description: option.file.name.substring(
                  0,
                  option.file.name.lastIndexOf(".")
                ),
                fileSource: 0,
                groupId: this.groupId,
                /** 给当前最大sort加1 */
                sortNumber: this.maxSortNumber + index,
                // 图片大小
                pictureSize: option.file.size,
                /**照片 */
                type: "50",
                userNo: this.row.userNo,
                userRoleNo: this.row.userRoleNo,
                visitFlag: 0,
                /** web上传 */
                watermarkFlag: null,
              });
              this.fileNameList.push(option.file.name);
            }
            resolve(res);
          })
          .catch((error) => {
            const index = this.indexOf(
              option.file.name,
              this.uploadSourceForm.body.siteMediaResourceReqs
            );
            this.uploadSourceForm.body.siteMediaResourceReqs.splice(index, 1);
            this.$message.error(`${option.file.name}上传错误,请重新上传`);
            reject(error);
          });
      });
    },
    /** 自定义上传方法，使其多次添加资源 */
    batch(option) {
      this.fileArr.push(option);
    },
    /*** 匹配返回数组索引 */
    indexOf(val, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].name == val) {
          return i;
        }
      }
      return -1;
    },
    /*** 删除附件 */
    async handleRemove(file, filelist) {
      // 重复文件计数
      let count = 0;
      let exitedObj = {};
      if (filelist.length > 1) {
        for (let i in filelist) {
          if (exitedObj[filelist[i].name]) {
            this.$message.error(`请勿上传同名文件！`);
            count++;
            break;
          }
          exitedObj[filelist[i].name] = true;
        }
      }
      /*** 判断文件大小 */
      if (filelist.length > 0) {
        for (let item of filelist) {
          const expSize = item.size / 1024 / 1024 < 1024;
          if (!expSize) {
            this.$message.error(`文件大小不能超过 1G, 请检查`);
            count++;
            break;
          }
        }
      }
      /** 判断文件格式 */
      if (filelist.length > 0) {
        for (let item of filelist) {
          // 优化
          let fileType = ["jpg", "jpeg", "png", "bmp", "tif", "tiff", "ofd"];
          fileType.map((item) => {
            fileType.push(item.toUpperCase());
          });
          const itemType = item.name.substring(item.name.lastIndexOf(".") + 1);
          if (fileType.includes(itemType)) {
          } else {
            this.$message.error(`上传文件只能是图片格式!, 请检查`);
            count++;
            break;
          }
        }
      }
      // 判断文件命名非法字符校验
      // if (filelist.length > 0) {
      // 	for (let item of filelist) {
      // 		const filename = item.name.substring(0, item.name.lastIndexOf("."))
      // 		if (!fileFormatValid(filename)) {
      // 			this.$message.error("文件名称中不能包含非法特殊字符, 请检查")
      // 			count++
      // 			break
      // 		}
      // 	}
      // }
      this.submitDisabled = count >= 1;
      const client = new OSS({
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        bucket: this.row.bucketName,
        region: this.row.region,
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: this.row.accessKeyId,
        accessKeySecret: this.row.accessKeySecret,
      });
      console.log(this.fileArr);
      this.fileArr.forEach((item, index) => {
        if (item.file.name === file.name) {
          this.fileArr.splice(index, 1);
        }
      });

      console.log("delete", this.fileArr);

      // if (this.fileNameList.indexOf(file.name) > -1) {
      //   var index = this.indexOf(
      //     file.name,
      //     this.uploadSourceForm.body.siteMediaResourceReqs
      //   );
      //   if (index > -1) {
      //     this.uploadSourceForm.body.siteMediaResourceReqs.splice(index, 1);
      //     this.uploadNames.splice(index, 1);
      //   }
      // }
    },
    /** 上传失败的回调 */
    handleError(err, file, fileList) {
      this.$message.error(`上传错误,错误信息 ${err}`);
    },
    // 通过oss的数据发送给后端
    /**
     * @method: 自定义上传方法 本方法包括：elment组件上传(实为添加文件);验证文件名称唯一性;上传oss;将结果回调site后端;
     * @description: 调用执行完element内部的文件上传生命周期，其次执行自定义上传
     */
    async photoSubmit() {
      console.log("this.fileArr", this.fileArr);
      this.subLoading = true;
      // await this.$refs.upload.submit();
      /* 置空 */
      this.checkNameListForm.body = [];
      // 判空
      if (this.fileArr.length === 0) {
        this.subLoading = false;
        this.$message({
          message: "请添加需要上传的文件",
          type: "warning",
        });
        return;
      }
      /**批量检验名称  */
      this.fileArr.map((el) => {
        this.checkNameListForm.body.push({
          // 0_pc
          fileSource: 0,
          /** 描述 */
          description: el.file.name.substring(0, el.file.name.lastIndexOf(".")),
          groupId: this.groupId,
          /** 名称 */
          name: el.file.name.substring(0, el.file.name.lastIndexOf(".")),
          type: 50,
          workOrderNo: this.row.workOrderNo,
        });
      });
      const { header, body } = await checkMediaNameList(this.checkNameListForm);
      if (header.retCode === "1") {
        this.submitDisabled = false;
      } else {
        // this.fileArr = [];
        this.$message({
          duration: 10000,
          showClose: true,
          message: `${header.retMessage}`,
          type: "error",
        });
        this.subLoading = false;
        return;
      }
      this.promiseArr = this.fileArr.map((el, index) => {
        this.maxSortNumber++;
        return this.fnUploadRequest(el, index);
      });
      Promise.all(this.promiseArr)
        .then((res) => {
          // 获取系统当前时间
          this.uploadSourceForm.header.currentTime = returnBitTimestamp(
            new Date()
          );
          /** 影视资料判断上传为空的优化 */
          if (this.uploadSourceForm.body.siteMediaResourceReqs.length === 0) {
            this.subLoading = false;
            this.$message({
              message: "请添加需要上传的文件",
              type: "warning",
            });
            return;
          }
          this.uploadSourceForm.body.siteMediaResourceReqs = this.sortArray(
            this.fileArr,
            this.uploadSourceForm.body.siteMediaResourceReqs
          );
          uploadSource(this.uploadSourceForm).then((response) => {
            this.uploadSourceForm.body.siteMediaResourceReqs = [];
            if (response.header.retCode === "1") {
              this.$message({
                message: `${response.header.retMessage}`,
                type: "success",
              });
              this.fetchData();
              this.handleClose();
            } else {
              this.$message.error(`错误信息: ${response.header.retMessage}`);
            }
            this.subLoading = false;
          });
          this.promiseArr = [];
          this.fileArr = [];
        })
        .catch((error) => {
          this.promiseArr = [];
          this.fileArr = [];
          this.uploadSourceForm.body.siteMediaResourceReqs = [];
          this.subLoading = false;
          this.$message.error(`${error}`);
          // reject(error);
        });
    },
    fetchData() {
      // 重置批量下载、生成PDF、已选图片
      this.selectedPicSize = 0;
      this.pictureList = [];
      this.albumList = [];
      this.checkAll = [];
      this.albumTransformForm.body.mediaSeqIds = [];

      this.parameter.body.type = this.type;
      this.parameter.body.userNo = this.row.userNo;
      this.parameter.body.userRoleNo = this.row.userRoleNo;
      this.parameter.body.workOrderNo = this.row.workOrderNo;
      this.parameter.body.console = this.row.workplace;

      queryGroupResource(this.parameter).then((res) => {
        if (res.header.retCode === "1") {
          this.photoData = res.body.records.map((item) => {
            if (item.siteMediaResource.length !== 0) {
              item.siteMediaResource.map((i) => {
                Object.assign(i, { mychecked: false });
              });
            }
            Object.assign(item, { mychecked: false });
            return item;
          });
          // 用于动态联动 <提交开关>的状态，后端接口返回batchTag都一致，故取非空数组第一索引元素中的值
          this.sumitTag =
            this.photoData.length > 0 ? this.photoData[0].batchTag : 0;
          this.total = res.body.total;
        } else {
          this.$message.error(`查询拍摄照片错误信息: ${res.header.retMessage}`);
        }
        /**重载部分组件 */
        // 移除组件
        this.labelEditReload = false;
        // 在组件移除后，重新渲染组件
        // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
        this.$nextTick(() => {
          this.labelEditReload = true;
        });
      });
    },
    // 总的全选
    handleCheckAllChange(val, type) {
      if (this.checkAll.length) {
        for (let i = 0; i < this.photoData.length; i += 1) {
          if (this.checkAll[0] === "all") {
            this.photoData[i].mychecked = true;
            this.firstChanged(i, this.photoData[i], true); // 调用一级change事件
          } else {
            if (
              this.photoData[i].fromType === this.checkAll[0] ||
              !this.photoData[i].fromType & (this.checkAll[0] === "nosystem")
            ) {
              this.photoData[i].mychecked = true;
              this.firstChanged(i, this.photoData[i], true); // 调用一级change事件
            }
          }
        }
      } else {
        for (let i = 0; i < this.photoData.length; i += 1) {
          this.photoData[i].mychecked = false;
          this.firstChanged(i, this.photoData[i], true); // 调用一级change事件
        }
      }
    },
    // 一级change事件
    firstChanged(index, val, update) {
      const { siteMediaResource } = this.photoData[index];
      if (this.photoData[index].mychecked === false) {
        this.albumList.splice(this.albumList.indexOf(val), 1);
        siteMediaResource.forEach((item) => {
          this.$set(item, "mychecked", false);
          // 选择相册时更新已选图片大小
          if (this.pictureList.includes(item.seqId)) {
            this.pictureList.splice(this.pictureList.indexOf(item.seqId), 1);
            this.selectedPicSize -= Number(item.pictureSize);
          }
        });
      } else {
        this.albumList.push(val);
        siteMediaResource.forEach((item) => {
          this.$set(item, "mychecked", true);
          // 选择相册时更新已选图片大小
          if (!this.pictureList.includes(item.seqId)) {
            this.pictureList.push(item.seqId);
            this.selectedPicSize += Number(item.pictureSize);
          }
        });
      }
      if (!update) {
        const system = {
          count: 0,
          checked: 0,
        };
        const nosystem = {
          count: 0,
          checked: 0,
        };
        this.photoData.forEach((item) => {
          if (item.fromType === "system") {
            system.count++;
            if (item.mychecked) {
              system.checked++;
            }
          } else {
            nosystem.count++;
            if (item.mychecked) {
              nosystem.checked++;
            }
          }
        });

        if (this.photoData.length === system.checked + nosystem.checked) {
          this.checkAll = ["all"];
        } else {
          if (
            system.count === system.checked &&
            system.checked > 0 &&
            !nosystem.checked
          ) {
            this.checkAll = ["system"];
          } else if (
            nosystem.count === nosystem.checked &&
            nosystem.checked > 0 &&
            !system.checked
          ) {
            this.checkAll = ["nosystem"];
          } else {
            this.checkAll = [];
          }
        }
      }
    },
    // 二级change事件
    secondChanged(index, item, isChecked) {
      // 选择图片时更新已选图片大小
      if (isChecked && !this.pictureList.includes(item.seqId)) {
        this.pictureList.push(item.seqId);
        this.selectedPicSize += Number(item.pictureSize);
      }
      if (!isChecked && this.pictureList.includes(item.seqId)) {
        this.pictureList.splice(this.pictureList.indexOf(item.seqId), 1);
        this.selectedPicSize -= Number(item.pictureSize);
      }
      const subData = this.photoData[index].siteMediaResource;
      let tickCount = 0;
      const len = subData.length;
      for (let i = 0; i < len; i += 1) {
        if (subData[i].mychecked === true) {
          tickCount += 1;
        } else {
          tickCount -= 1;
        }
      }
    },
    // 打开预览
    handleView(photoData, photoGroupTitle, photoIndex, subIndex) {
      this.$refs["photoView"].bindData(
        photoData,
        photoGroupTitle,
        photoIndex,
        subIndex,
        this.row.endpoinxBak,
        this.row.OSSClient
      );
      this.$refs["photoView"].showView();
    },
    /** 相册生成PDF */
    async handleGeneratePDF(source) {
      if (!this.albumList.length) {
        this.$message.error(`请先勾选相册`);
      } else {
        let verification = false;
        for (const ablum of this.albumList) {
          if (ablum.siteMediaResource.length) {
            verification = true;
            break;
          }
        }
        if (!verification) {
          this.$message.error(`相册为空，无法生成PDF`);
          return;
        }
        source === 1 ? (this.pdfLoading = true) : (this.reportLoading = true);
        for (const item of this.albumList) {
          if (item.siteMediaResource.length) {
            this.albumTransformForm.body.mediaSeqIds.push(item.seqId);
          }
        }
        this.albumTransformForm.body.type = source;
        this.albumTransformForm.body.userRoleNo = this.row.userRoleNo;
        this.albumTransformForm.body.userNo = this.row.userNo;
        this.albumTransformForm.body.workOrderNo = this.row.workOrderNo;
        this.albumTransformForm.body.targetSubject = this.row.targetSubject;
        try {
          const { header } = await createDownloadTask(this.albumTransformForm);
          if (header.retCode === "1") {
            this.fetchData();
            this.$message.success(header.retMessage);
          } else {
            this.$message.error(header.retMessage);
          }
          source === 1
            ? (this.pdfLoading = false)
            : (this.reportLoading = false);
        } catch (error) {
          source === 1
            ? (this.pdfLoading = false)
            : (this.reportLoading = false);
        }
      }
    },
    /** 生成报告&PDF */
    async handleGenerateReportPDF() {},
    /** 查询下载任务列表 */
    async fetchDownloadTaskData() {
      this.downloadTaskLoading = true;
      this.queryDownloadTaskForm.body.userNo = this.row.userNo;
      this.queryDownloadTaskForm.body.userRoleNo = this.row.userRoleNo;
      this.queryDownloadTaskForm.body.workOrderNo = this.row.workOrderNo;
      try {
        const { header, body } = await fetchDownloadTaskList(
          this.queryDownloadTaskForm
        );
        if (header.retCode === "1") {
          this.downloadTaskTotal = body.total;
          this.downloadList = body.records;
        } else {
          this.$message.error(heder.retMessage);
        }
        this.downloadTaskLoading = false;
      } catch (error) {
        this.downloadList = [];
        this.downloadTaskLoading = false;
      }
    },
    /** 下载任务列表页码改变 */
    handleTaskCurrentChange(val) {
      this.queryDownloadTaskForm.body.pageNum = val;
      this.fetchDownloadTaskData();
    },
    /** 显示下载任务弹出框 */
    handleShowTaskPopover() {
      this.fetchDownloadTaskData();
      window.clearInterval(this.downloadTimer);
      this.downloadTimer = window.setInterval(
        this.fetchDownloadTaskData,
        10 * 1000
      );
    },
    /** 隐藏下载任务弹出框 */
    handleHideTaskPopover() {
      window.clearInterval(this.downloadTimer);
    },
    /** 重新创建下载任务 */
    async handleRetry(data) {
      this.retryDownloadTaskForm.body.seqId = data;
      this.retryDownloadTaskForm.body.userNo = this.row.userNo;
      this.retryDownloadTaskForm.body.userRoleNo = this.row.userRoleNo;
      const { header } = await rebuildDownloadTask(this.retryDownloadTaskForm);
      if (header.retCode === "1") {
        this.$message.success(header.retMessage);
        this.fetchDownloadTaskData();
      } else {
        this.$message.error(header.retMessage);
      }
    },
    /** 下载zip包 */
    handleDownloadZip(domain, path) {
      const http = "site/downOss?path=";
      let downloadPath;
      if (
        path.indexOf(",") ||
        path.indexOf(";") ||
        path.indexOf("，") ||
        path.indexOf("；")
      ) {
        downloadPath = http + path;
      } else {
        downloadPath = domain + path;
      }
      downloadReport(downloadPath);
    },
    /** 指定数组排序 */
    sortArray(originArr, arr) {
      let sortedArr = [];
      for (const orgItem of originArr) {
        let currName = orgItem.file.name.substring(
          0,
          orgItem.file.name.lastIndexOf(".")
        );
        for (const item of arr) {
          if (item.name === currName) {
            sortedArr.push(item);
          }
        }
      }
      return sortedArr;
    },
    /** 保存相册备注 */
    async savePhotoIntro(val, index) {
      console.log("===", val);
      const item = this.photoData[index];
      const fheader = this.uploadSourceForm.header;
      const { header } = await saveRemark({
        header: fheader,
        body: {
          mediaResourceGroupSeqId: item.seqId,
          orderWorkNo: item.workOrderNo,
          remark: val,
        },
      });
      if (header.retCode === "1") {
        this.$message.success(header.retMessage);
        this.fetchDownloadTaskData();
      } else {
        this.$message.error(header.retMessage);
      }
    },
  },
};
</script>

<style scoped>
.size-tips {
  position: fixed;
  top: 20%;
  right: 50%;
  min-width: 100px;
  max-width: 150px;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  z-index: 99;
}
.labelEdit {
  margin: -20px 0px 0px 0px;
}
.title {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  font-weight: 700;
  font-style: normal;
  text-align: left;
  line-height: 28px;
}
.class-detail {
  float: left;
  width: 160px;
  height: 160px;
  margin-right: 30px;
  margin-bottom: 50px;
}
/* checkbox 浮层样式 */
.img_checkbox {
  z-index: 999;
  position: relative;
  top: -170.5px;
  left: 0px;
}
.submitSwitch {
  width: 35px;
  /* text-align: center; */
  z-index: 999;
  position: relative;
  top: -28px;
  left: 110px;
}
.checkAllBox >>> .el-checkbox-group {
  margin-right: 30px;
}
.checkAllBox >>> .el-checkbox {
  margin-right: 10px;
}
.checkAllBox >>> .el-checkbox.is-bordered.el-checkbox--small {
  padding: 7px 15px 5px 10px;
}
.downloadDialog >>> .el-dialog__body {
  padding: 45px 20px;
}
.el-icon-upload {
  display: flex;
  justify-content: center;
  font-size: 70px;
  color: #ccc;
}
.operationTool .add {
  margin-right: 10px;
}
.operationTool .rename {
  margin-right: 10px;
}
.operationTool .del {
  color: #ff4d4f;
}
.task-list-btn,
.task-list-btn:hover,
.task-list-btn:focus {
  font-size: 16px;
  background-color: #098ea0;
  border-color: #098ea0;
  color: #fff;
}
.task-list-btn:hover {
  opacity: 0.8;
}
</style>
<style>
.el-dialog__header {
  word-break: break-all;
  word-wrap: break-word;
  padding-right: 32px;
}
.intr-box {
  display: flex;
  margin-bottom: 20px;
}
.intr-box span {
  white-space: nowrap;
}
</style>
