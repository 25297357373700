<template>
  <!-- 添加或修改目录对话框 -->
  <el-dialog
    :title="subTitle"
    :visible.sync="open"
    width="58%"
    :close-on-click-modal="false"
    append-to-body
    :before-close="handleNodeCancel"
  >
    <el-form
      ref="contentForm"
      :model="contentForm"
      :rules="contentFormRules"
      label-width="100px"
      size="mini"
      style="padding: 10px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="数据类型" prop="fieldType">
            <el-radio-group
              v-model="contentForm.fieldType"
              @change="dataChange"
            >
              <el-radio label="input">输入框</el-radio>
              <el-radio label="select">下拉选择器</el-radio>
              <el-radio label="radio">单选框</el-radio>
              <el-radio label="checkbox">多选框</el-radio>
              <el-radio label="album">相册</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 输入框 -->
      <el-row>
        <el-col v-if="contentForm.fieldType === 'input'" :span="12">
          <!-- 是否必填 -->
          <el-form-item label="是否必填" prop="required">
            <el-select
              v-model="contentForm.required"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in requireOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'input'" :span="12">
          <el-form-item label="类型" prop="displayType">
            <el-select
              v-model="contentForm.displayType"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in inputTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'input'" :span="12">
          <el-form-item label="字段名称" prop="elementTitle">
            <el-input
              v-model.trim="contentForm.elementTitle"
              type="textarea"
              placeholder="请输入字段名称"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'input'" :span="12">
          <el-form-item prop="fieldKey" label="英文名称">
            <el-input
              v-model.trim="contentForm.fieldKey"
              placeholder="请输入英文名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'input'" :span="24">
          <el-form-item label="字段值" prop="itemValue">
            <el-input
              v-model="contentForm.itemValue"
              type="textarea"
              placeholder="请输入字段值"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 选择器 -->
      <el-row>
        <el-col v-if="contentForm.fieldType === 'select'" :span="12">
          <el-form-item label="展示选择" prop="displayChoice">
            <el-select
              v-model="contentForm.displayChoice"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col v-if="contentForm.fieldType === 'select'" :span="12">
          <el-form-item prop="fieldKey" label="英文名称">
            <el-input
              v-model.trim="contentForm.fieldKey"
              placeholder="请输入英文名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'select'" :span="12">
          <el-form-item label="类型" prop="" hidden>
            <el-select v-model="contentForm.displayType" filterable>
              <el-option
                v-for="item in selectTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'select'" :span="12">
          <el-form-item label="可清空" prop="clearable">
            <el-select
              v-model="contentForm.clearable"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in clearableOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'select'" :span="12">
          <el-form-item prop="elementTitle" label="字段名称">
            <el-input
              v-model.trim="contentForm.elementTitle"
              type="textarea"
              placeholder="请输入字段名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 单选框 -->
      <el-row>
        <el-col v-if="contentForm.fieldType === 'radio'" :span="12">
          <el-form-item prop="elementTitle" label="字段名称">
            <el-input
              v-model.trim="contentForm.elementTitle"
              type="textarea"
              placeholder="请输入字段名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'radio'" :span="12">
          <el-form-item prop="fieldKey" label="英文名称">
            <el-input
              v-model.trim="contentForm.fieldKey"
              placeholder="请输入英文名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 多选框 -->
      <el-row>
        <el-col v-if="contentForm.fieldType === 'checkbox'" :span="12">
          <el-form-item prop="elementTitle" label="字段名称">
            <el-input
              v-model.trim="contentForm.elementTitle"
              type="textarea"
              placeholder="请输入字段名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'checkbox'" :span="12">
          <el-form-item prop="fieldKey" label="英文名称">
            <el-input
              v-model.trim="contentForm.fieldKey"
              placeholder="请输入英文名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 相册 -->
      <el-row>
        <el-col v-if="contentForm.fieldType === 'album'" :span="12">
          <el-form-item prop="albumLength" label="图片宽度X">
            <el-input
              v-model.number="contentForm.albumLength"
              placeholder="请输入（厘米）"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'album'" :span="12">
          <el-form-item prop="albumWidth" label="图片高度Y">
            <el-input
              v-model.number="contentForm.albumWidth"
              placeholder="请输入（厘米）"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'album'" :span="12">
          <el-form-item prop="elementTitle" label="字段名称">
            <el-input
              v-model.trim="contentForm.elementTitle"
              type="textarea"
              placeholder="请输入字段名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="contentForm.fieldType === 'album'" :span="12">
          <el-form-item prop="fieldKey" label="英文名称">
            <el-input
              v-model.trim="contentForm.fieldKey"
              placeholder="请输入英文名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleNodeCancel">取消</el-button>
      <el-button type="primary" @click="handleNodeCertain">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from "vuex";
  import { isOneToNinetyNine } from "@/utils/validate";
  import {
    // 模板配置新增接口
    addSystemData,
    // 模板配置修改接口
    updateSystemData,
  } from "@/api/project/systemTemplate";
  export default {
    name: "FormOption",
    data() {
      return {
        // 是否显示弹出层
        open: false,
        // flag为true的时候新增，false的时候编辑
        flag: true,
        // 子弹出层标题
        subTitle: "",
        // 节点
        addForm: {
          body: {
            elementLevel: 1,
            elementTitle: "",
            // 节点
            elementType: 1,
            fieldKey: "",
            fieldType: "",
            parentId: 0,
            projectId: "",
            userNo: "",
            userRoleNo: "commonRole",
            // 级联标志(0:非级联 1:级联)
            linkageSign: 0,
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        updateForm: {
          body: {
            elementLevel: 1,
            elementTitle: "",
            // 节点
            elementType: 1,
            fieldKey: "",
            fieldType: "",
            parentId: 0,
            projectId: "",
            userNo: "",
            userRoleNo: "commonRole",
            // 级联标志(0:非级联 1:级联)
            linkageSign: 0,
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        contentForm: {
          elementTitle: "",
          fieldType: "input",
          // 展示选择(复选框:checkbox 五角星:fivePointedStar 感叹号:exclamatoryMark)
          displayChoice: "",
          // 元素类型
          title: "",
          value: "",
          required: "",
          // 展示类型(text:单行文本框 textarea:多行文本框 single:单选 multiple:多选)
          displayType: "",
          fieldKey: "",
          clearable: "",
          albumLength: null,
          albumWidth: null,
          itemValue: "",
        },
        contentFormRules: {
          albumLength: [
            {
              // required: true,
              validator: isOneToNinetyNine,
            },
          ],
          albumWidth: [
            {
              // required: true,
              validator: isOneToNinetyNine,
            },
          ],
          displayChoice: [
            {
              required: true,
              trigger: "change",
              message: "请选择展示",
            },
          ],
          fieldType: [
            {
              required: true,
              trigger: "blur",
              message: "请选择需要配置的元素",
            },
          ],
          required: [
            {
              required: true,
              trigger: "change",
              message: "请选择是否必填",
            },
          ],
          elementTitle: [
            {
              required: true,
              trigger: "blur",
              message: "请输入字段名称",
            },
          ],
          displayType: [
            {
              required: true,
              trigger: "change",
              message: "请选择需要的元素类型",
            },
          ],
          fieldKey: [
            {
              required: true,
              trigger: "blur",
              message: "请输入英文名称",
            },
          ],
        },
        requireOption: [
          {
            value: 0,
            label: "否",
          },
          {
            value: 1,
            label: "是",
          },
        ],
        inputTypeOptions: [
          {
            value: "text",
            label: "单行文本框",
          },
          {
            value: "textarea",
            label: "多行文本框",
          },
        ],
        selectTypeOptions: [
          {
            value: "single",
            label: "单选选择器",
          },
          // {
          //   value: "multiple",
          //   label: "多选选择器",
          // },
        ],
        clearableOptions: [
          {
            value: 0,
            label: "否",
          },
          {
            value: 1,
            label: "是",
          },
        ],
        selectOptions: [
          {
            value: "checkbox",
            label: "复选框",
          },
          {
            value: "fivePointedStar",
            label: "五角星",
          },
          // {
          //   value: "exclamatoryMark",
          //   label: "感叹号",
          // },
        ],
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.contentForm.displayType = this.selectTypeOptions[0].value;
    },
    methods: {
      // 添加
      showOptionAdd(row) {
        this.subTitle = "新增节点";
        this.contentForm = this.$options.data().contentForm;
        // 即在行信息为节点时添加，希望添加至此下级，故level+1
        this.addForm.body.elementLevel = row.elementLevel + 1;
        this.addForm.body.parentId = row.seqId;
        this.addForm.body.userNo = this.userNo;
        this.addForm.body.projectId = row.projectId;
        this.flag = true;
        this.open = true;
      },
      // 添加联动
      linkage(row) {
        this.subTitle = "新增联动";
        this.contentForm = this.$options.data().contentForm;
        // 即在行信息为节点时添加，希望添加至此下级，故level+1
        this.addForm.body.elementLevel = row.elementLevel + 1;
        this.addForm.body.parentId = row.seqId;
        this.addForm.body.userNo = this.userNo;
        this.addForm.body.projectId = row.projectId;
        // 0:非级联 1:级联
        this.addForm.body.linkageSign = 1;
        this.flag = true;
        this.open = true;
      },
      // 编辑
      showOptionEdit(row) {
        this.subTitle = "编辑节点";
        this.flag = false;
        this.contentForm = Object.assign(this.contentForm, row);
        // 即在行信息为节点时添加，希望添加至此下级，故level+1
        this.updateForm.body.elementLevel = row.elementLevel;
        this.updateForm.body.parentId = row.parentId;
        this.updateForm.body.seqId = row.seqId;
        this.updateForm.body.userNo = this.userNo;
        this.updateForm.body.projectId = row.projectId;
        this.open = true;
      },
      // 配置取消按钮
      handleNodeCancel() {
        this.addForm = this.$options.data().addForm;
        this.$refs.contentForm.resetFields();
        this.open = false;
      },
      /** radio切换清空数据 */
      dataChange(val) {
        this.contentForm.elementTitle = "";
        this.contentForm.value = "";
        this.contentForm.required = "";
        this.contentForm.displayType = "";
        this.contentForm.fieldKey = "";
        this.contentForm.clearable = "";
        this.contentForm.albumLength = null;
        this.contentForm.albumWidth = null;
        this.contentForm.displayChoice = "";
      },
      /** 配置 提交按钮 */
      handleNodeCertain: function () {
        this.$refs["contentForm"].validate((valid) => {
          if (valid) {
            this.addForm.body = Object.assign(
              this.addForm.body,
              this.contentForm
            );
            this.updateForm.body = Object.assign(
              this.updateForm.body,
              this.contentForm
            );
            // 新增
            if (this.flag) {
              addSystemData(this.addForm).then((res) => {
                if (res.header.retCode === "1") {
                  this.$message({
                    type: "success",
                    message: `${res.header.retMessage}`,
                  });
                  this.open = false;
                  this.$refs.contentForm.resetFields();
                  this.$emit("fetchData");
                } else {
                  this.$message.error(`${res.header.retMessage}`);
                }
              });
            } else {
              // 编辑
              updateSystemData(this.updateForm).then((res) => {
                if (res.header.retCode === "1") {
                  this.$message({
                    type: "success",
                    message: `${res.header.retMessage}`,
                  });
                  this.open = false;
                  this.$refs.contentForm.resetFields();
                  this.$emit("fetchData");
                } else {
                  this.$message.error(`${res.header.retMessage}`);
                }
              });
            }
          }
        });
      },
    },
  };
</script>
