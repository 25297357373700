<!-- 会议录像预览Dialog-->
<template>
  <!-- 录像预览 -->
  <el-dialog
    :visible.sync="dialogvideoVisible"
    class="videoDialog"
    append-to-body
    @close="closeView"
  >
    <el-row>
      <el-col style="text-align: center">
        <video
          ref="myVideo"
          :src="videoUrl"
          controls="controls"
          style="width: 90%; height: 300px"
        ></video>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
  export default {
    name: "MeetingView",
    data() {
      return {
        videoData: "",
        tabPosition: "left",
        dialogvideoVisible: false,
        fit: "fill",
        activeTabsValue: "2",
        video_index: "",
        videoUrl: "",
        tab_name: "",
      };
    },
    created() {},
    methods: {
      showView() {
        this.dialogvideoVisible = true;
      },
      closeView() {
        this.$refs.myVideo.pause(); //暂停
      },
      bindData(videoUrl) {
        this.videoUrl = videoUrl;
        // this.$nextTick(() => {
        //   this.$refs["videoCarousel"].setActiveItem(videoIndex);
        // });
      },
    },
  };
</script>

<style scoped>
  .videoDialog {
    z-index: 10;
  }
  .videoDialog /deep/.el-dialog__header {
    padding: 0 20px;
  }
  .videoDialog /deep/.el-dialog__body {
    padding-top: 40px;
  }
</style>
