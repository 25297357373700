<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px; margin-left: 4px">
      该工单是否确认提交？
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="小记" prop="note">
        <el-input
          v-model.trim="form.note"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getRoleKey } from "@/utils";
  import { mapGetters } from "vuex";
  import { submit } from "@/api/workplace/user/pchUser";

  export default {
    name: "Submit",
    data() {
      return {
        loading: false,
        form: {
          note: "",
        },
        rules: {
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
        investigateOptions: [],
        // 提交人员推荐参数
        parameter: {
          body: {
            console: "customer", // 固定写法
            note: null,
            operAction: "工单二审", // 固定写法
            projectSource: "",
            step: "用户工作台-工单二审-待处理", // 固定写法
            pchUser: "373",
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            procInsId: null,
            taskId: null,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        object: null,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.parameter.body.userNo = this.userNo;
      this.parameter.body.userRoleNo = getRoleKey("customer", this.roles);
    },
    methods: {
      async showSubmit(row) {
        this.title = "提交工单";
        this.form = Object.assign({}, row[0]);
        this.dialogFormVisible = true;
        this.object = Object.assign({}, row);
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("refreshData");
      },
      async save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            this.parameter.body.note = this.form.note;
            this.parameter.body.procInsId = this.object.procInsId;
            this.parameter.body.taskId = this.object.taskId;
            this.parameter.body.projectSource = this.object.source;
            this.parameter.body.workOrderNo = this.object.workOrderNo;
            const { header } = await submit(this.parameter);
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }

            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("refreshData");
            this.$emit("close");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
