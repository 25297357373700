<!-- 保存提交 成功-->
<template>
  <el-card type="box-card">
    <el-row>
      <el-col :span="24" style="text-align: center" class="icon-row">
        <i
          class="el-icon-success"
          style="
            font-family: 'FontAwesome';
            font-weight: 400;
            font-style: normal;
            font-size: 100px;
            color: #4bd863;
            line-height: 120px;
          "
        ></i>
      </el-col>
      <el-col :span="24">
        <h3>{{ msg }}</h3>
      </el-col>
      <el-col class="tool-button">
        <el-button plain @click="getBack">重新编辑</el-button>
        <el-button type="primary" @click="getIndex">返回列表</el-button>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
  export default {
    name: "Save",
    data() {
      return {
        msg: "工单提交/保存成功",
      };
    },
    methods: {
      // 返回打开界面
      getIndex() {
        this.$router.go(-2);
      },
      // 返回
      getBack() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style scoped>
  body {
    font-weight: 700;
    color: #666;
  }
  .icon-row {
    margin-top: 140px;
  }
  .tool-button {
    margin-top: 60px;
    text-align: center;
  }
  .tool-button >>> .el-button {
    width: 140px;
    height: 40px;
  }
  .tool-button >>> .el-button:first-child {
    margin-right: 15px;
  }
  h3 {
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    margin-top: 15px;
    color: #666666;
    text-align: center;
    line-height: 24px;
  }
</style>
