var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { background: "#f6f8f9" } },
    [
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v(_vm._s(_vm.companyName)),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card", "tab-position": _vm.tabPosition },
          on: { "tab-click": _vm.handleClick },
        },
        [
          _c(
            "el-tab-pane",
            { staticClass: "edit_title", attrs: { label: "工单详情" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("order-process", { attrs: { param: _vm.param } }),
                  _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "workOrderNo",
                          label: "工单编号",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brWorkNo",
                          label: "订单编号",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.brWorkNo || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reportType",
                          label: "报告类型",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.reportType || "-") +
                                    " " +
                                    _vm._s(scope.row.subReportType ? "/" : "") +
                                    " " +
                                    _vm._s(scope.row.subReportType) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "projectNameNo",
                          label: "Project Name",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectNameNo || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "projectName",
                          label: "项目",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "projectSource",
                          label: "项目来源",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getProjectSource(
                                        scope.row.projectSource
                                      ) || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubject",
                          label: "目标主体",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetSubject || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          label: "国家/省份/城市/地域（县级市）",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.address || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetAddr1",
                          label: "目标地址1",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetAddr1 || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetAddr2",
                          label: "目标地址2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetAddr2 || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetContact",
                          label: "目标联系人",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetContact || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "contactNumber",
                          label: "联系电话",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.contactNumber || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "duty", label: "职务", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.duty || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderTime",
                          label: "下单时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.orderTime || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "innerDeadline",
                          label: "内部截止日期",
                          align: "center",
                          formatter: _vm.eighthBitTime,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "clientFullName",
                          label: "委托方全称",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.clientFullName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "clientAbbr",
                          label: "委托方简称",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.clientAbbr || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reportTimeLimit",
                          label: "报告时限",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.reportTimeLimit || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark1",
                          label: "备注1",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark1 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark2",
                          label: "备注2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark2 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark3",
                          label: "备注3",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark3 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("附加信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectVisitDate",
                          label: "预计走访日期",
                          align: "center",
                          formatter: _vm.eighthBitTime,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectServeyDay",
                          label: "预计所需调查天数",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.expectServeyDay || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "needUser",
                          label: "所需人手",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.needUser || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectHour",
                          label: "预计需时（小时）",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.expectHour || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "registerAddress",
                          label: "注册地址",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.registerAddress || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubjectRegStatus",
                          label: "目标主体注册状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.targetSubjectRegStatus || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "visitFee",
                          label: "走访收费",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.visitFee || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "urgentVisitFee",
                          label: "加急走访收费",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.urgentVisitFee || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "againVisitFee",
                          label: "再次走访收费",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.againVisitFee || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "feeBasis",
                          label: "收费依据",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.feeBasis || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetOrganization1",
                          label: "目标机构1",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.targetOrganization1 || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetOrganization2",
                          label: "目标机构2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.targetOrganization2 || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetResult",
                          label: "目标地址预核结果",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetResult || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "infeasibleReason",
                          label: "不可行原因/待确认项目",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.infeasibleReason || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mailHeader",
                          label: "邮件抬头",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.mailHeader || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("特别信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "plSpecialMsg",
                          label: "PL特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.plSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bookSpecialMsg",
                          label: "预约特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.bookSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "monitorSpecialMsg",
                          label: "督导特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.monitorSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.annexGroup.length !== 0,
                          expression: "annexGroup.length !== 0",
                        },
                      ],
                      staticClass: "title",
                    },
                    [_vm._v("附件")]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "20px",
                      },
                    },
                    _vm._l(_vm.annexGroup, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.handleDownload({
                                        name: item.fileName,
                                        path: item.path,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/icon/annex.svg"),
                                      alt: "",
                                      width: "80",
                                      height: "80",
                                    },
                                  }),
                                  _c("p", [_vm._v(_vm._s(item.fileName))]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("操作信息")]),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.operationInfo,
                        stripe: "",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "operator",
                          label: "操作者",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.operator || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "role", label: "角色", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.role || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "operAction",
                          label: "操作状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.operAction || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "操作时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.createTime || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark",
                          label: "备注",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "20px",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                        [
                          _c(
                            "el-dropdown",
                            {
                              staticClass: "adjustPerson",
                              on: { command: _vm.adjustStaff },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "large",
                                    icon: "el-icon-s-custom",
                                  },
                                },
                                [
                                  _vm._v(" 调整人员 "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "bookUser" } },
                                    [_vm._v(" 调整预约 ")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "pl" } },
                                    [_vm._v("调整PL")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "part_surveyUser" } },
                                    [_vm._v(" 调整兼职调查员 ")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "full_surveyUser" } },
                                    [_vm._v(" 调整全职调查员 ")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "supervisor" } },
                                    [_vm._v(" 调整督导 ")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "audit" } },
                                    [_vm._v("调整内审")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: _vm.staffTitle,
                                visible: _vm.openAdjustStaff,
                                width: "40%",
                                "before-close": _vm.adjustStaffClose,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.openAdjustStaff = $event
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "adjustFrom",
                                      attrs: { model: _vm.adjustFrom },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "调整人员",
                                            prop: "staffValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "260px" },
                                              attrs: {
                                                multiple: false,
                                                "multiple-limit":
                                                  _vm.multipleLimit,
                                                placeholder: "",
                                                filterable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.adjustFrom.staffValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.adjustFrom,
                                                    "staffValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "adjustFrom.staffValue",
                                              },
                                            },
                                            _vm._l(
                                              _vm.staffData,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.adjustStaffClose } },
                                    [_vm._v("取 消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.staffConfirm("adjustFrom")
                                        },
                                      },
                                    },
                                    [_vm._v(" 确 定 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "取证资料", name: "evidence" } },
            [
              _vm.object.projectSign == null
                ? _c("evidence", {
                    ref: "evidence",
                    attrs: { row: _vm.object },
                  })
                : _c("evidence-history", {
                    ref: "evidenceHistory",
                    attrs: { row: _vm.object },
                  }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄照片", name: "photo" } },
            [
              _c("photo", {
                ref: "photo",
                attrs: { type: "50", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄录像", name: "screenVideo" } },
            [
              _c("screenVideo", {
                ref: "screenVideo",
                attrs: { type: "51", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "扫描文件", name: "scanFile" } },
            [
              _c("scanFile", {
                ref: "scanFile",
                attrs: { type: "52", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "录制音频", name: "screenAudio" } },
            [
              _c("screenAudio", {
                ref: "screenAudio",
                attrs: { type: "53", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "其他文件", name: "telRecord" } },
            [
              _c("telRecord", {
                ref: "telRecord",
                attrs: { type: "54", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "打卡记录", name: "clockIn" } },
            [
              _c("clockIn", {
                ref: "clockIn",
                attrs: { type: "55", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "会议信息", name: "meeting" } },
            [
              _c("meeting", {
                ref: "meeting",
                attrs: { type: "57", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "查看小记", name: "remark" } },
            [_c("remark", { ref: "remark", attrs: { param: _vm.param } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "退回记录", name: "back" } },
            [_c("back", { ref: "back", attrs: { order: _vm.orderNo } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "标注记录", name: "labelRecord" } },
            [
              _c("labelRecord", {
                ref: "labelRecord",
                attrs: { type: "56", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "企业信息", name: "company" } },
            [_c("company", { ref: "company", attrs: { row: _vm.object } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }