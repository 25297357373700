<template>
  <div class="app-container">
    <div v-if="user">
      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <user-card :user="user" />
        </el-col>

        <el-col :span="18" :xs="24">
          <el-card>
            <el-tabs v-model="activeTab" :tab-position="tabPosition">
              <el-tab-pane label="基本信息" name="account">
                <account :user="user" />
              </el-tab-pane>
              <el-tab-pane label="安全设置" name="safe">
                <safe />
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import userCard from "./components/userCard";
  import account from "./components/account";
  import safe from "./components/safe";

  export default {
    name: "PersonalCenter",
    components: { userCard, safe, account },
    data() {
      return {
        tabPosition: "left",
        user: {
          name: "Bunny",
          role: "经理",
          avatar:
            "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
        },
        activeTab: "account",
      };
    },
    created() {
      this.getUser();
    },
    methods: {
      getUser() {
        this.user = {
          name: this.user.name,
          role: this.user.role,
          email: "admin@test.com",
          avatar: this.user.avatar,
        };
      },
    },
  };
</script>
