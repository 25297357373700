var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "账户名称", prop: "name" } },
        [
          _c("el-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.userName,
              callback: function ($$v) {
                _vm.userName = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "userName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "真实姓名", prop: "nickName" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.nickName,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "nickName",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.nickName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "联系电话", prop: "phoneNumber" } },
        [
          _c("el-input", {
            attrs: {
              oninput: "if(value.length > 11)value = value.slice(0, 11)",
              type: "number",
            },
            model: {
              value: _vm.form.phoneNumber,
              callback: function ($$v) {
                _vm.$set(_vm.form, "phoneNumber", _vm._n($$v))
              },
              expression: "form.phoneNumber",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Email", prop: "email" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.email,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "email",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.email",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "入职时间", prop: "inductionTime" } },
        [
          _c("el-date-picker", {
            staticStyle: { width: "100%" },
            attrs: {
              type: "date",
              placeholder: "请选择日期",
              required: "",
              clearable: "",
              disabled: "",
            },
            model: {
              value: _vm.form.inductionTime,
              callback: function ($$v) {
                _vm.$set(_vm.form, "inductionTime", $$v)
              },
              expression: "form.inductionTime",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.submit },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }