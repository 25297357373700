// Kyle API 对账ID管理 -> 添加对账ID
import request from "@/utils/request";

// 根据工单编号查询对账ID明细
export function getList(data) {
  return request({
    url: "/plWorkOrder/queryReconciliationByWorkOrderNo",
    method: "post",
    data,
  });
}

export function doDelete(data) {
  return request({
    url: "/plAddVerify/doDelete",
    method: "post",
    data,
  });
}
