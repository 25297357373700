<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <p style="margin-bottom: 20px">该工单是否确认提交？</p>
      <el-form-item label="预约人员" prop="bookUser">
        <el-select
          v-model="form.bookUser"
          autocomplete="off"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in roleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model="form.note"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="certainLoading" @click="certain">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  // 列表页提交接口
  import { doSubmit } from "@/api/workplace/pl/plOrderTodo";
  // 获取工具API
  import {
    // 角色
    getRole,
  } from "@/api/workplace/tools";
  import { mapGetters } from "vuex";
  // 时间转化
  import { returnBitTimestamp, getRoleKey } from "@/utils";

  export default {
    name: "Submit",
    data() {
      return {
        // 防抖
        certainLoading: false,
        form: {
          workOrderNos: [],
          console: "PL",
          userNo: "",
          userRoleNo: "",
          operAction: "工单派单",
          step: "PL工作台-工单管理-待处理",
          note: "",
          bookUser: "",
          remark: "",
          nextUserNo: "139",
          nextUserRoleNo: "",
          projectSource: "",
        },
        formPort: {
          body: {},
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        rules: {
          bookUser: [
            { required: true, trigger: "blur", message: "选择预约人员" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        //   角色人员
        roleOptions: [],
        roleForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        title: "",
        dialogFormVisible: false,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.form.userNo = this.userNo;
      this.form.userRoleNo = getRoleKey("PL", this.roles);
    },
    methods: {
      showCommit(row, bookUserNo) {
        if (bookUserNo) {
          this.form.bookUser = bookUserNo;
        }
        this.title = "提交工单";
        this.form.workOrderNos = row.map((item) => {
          return item.workOrderNo;
        });
        this.getRoleOption();
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
      },
      // 获取角色配置项
      getRoleOption() {
        this.roleForm.header.currentTime = returnBitTimestamp(new Date());
        // 预约人员[包括经理]
        this.roleForm.body = [
          {
            roleKey: "book_person",
          },
        ];
        getRole(this.roleForm).then((response) => {
          if (response.header.retCode === "1") {
            this.roleOptions = response.body;
          }
        });
      },
      certain() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.certainLoading = true;
            this.formPort.header.currentTime = returnBitTimestamp(new Date());
            this.form.userNo = this.userNo;
            this.form.userRoleNo = getRoleKey("PL", this.roles);
            this.formPort.body = this.form;
            doSubmit(this.formPort).then((response) => {
              if (response.header.retCode === "1") {
                this.$refs["form"].resetFields();
                this.$message({
                  message: `${response.header.retMessage}`,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.$emit("refreshData");
              } else if (response.header.retCode === "700004") {
                if (
                  response.body.workFlowFail !== null &&
                  response.body.workFlowFail.length !== 0
                ) {
                  this.$message.error(
                    `${response.header.retMessage}, 提交异常失败，请联系开发人员`
                  );
                } else if (
                  response.body.commited !== null &&
                  response.body.commited.length !== 0
                ) {
                  this.$message({
                    showClose: true,
                    duration: 10000,
                    message: "存在已经被提交的工单，请刷新后查看提交",
                    type: "warning",
                  });
                }
                this.dialogFormVisible = false;
                this.form = this.$options.data().form;
                this.$emit("refreshData");
              } else {
                this.form = this.$options.data().form;
                this.dialogFormVisible = false;
                this.$message.error(
                  `${response.header.retMessage}, 提交异常失败，请刷新页面后重试`
                );
              }
              this.certainLoading = false;
            });
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
