<template>
  <!-- 添加或修改目录对话框 -->
  <el-dialog
    :title="subTitle"
    :visible.sync="open"
    width="55%"
    :close-on-click-modal="false"
    append-to-body
    :before-close="handleInfoCancel"
  >
    <el-form
      ref="contentForm"
      :model="contentForm"
      :rules="contentFormRules"
      label-width="100px"
      size="mini"
      style="padding: 10px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="elementTitle"
            label="一级目录"
            :rules="[
              {
                required: true,
                message: '请输入一级目录',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model.trim="contentForm.elementTitle"
              type="textarea"
              placeholder="请输入一级目录"
              @keyup.enter.native="handleInfoCertain"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleInfoCancel">取消</el-button>
      <el-button type="primary" @click="handleInfoCertain">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    // 模板配置新增接口
    addSystemData,
    // 模板配置修改接口
    updateSystemData,
  } from "@/api/project/systemTemplate";
  import { mapGetters } from "vuex";

  export default {
    name: "FormInfo",
    data() {
      return {
        // 是否显示弹出层
        open: false,
        // 默认新增，否则为对话框编辑flag
        flag: true,
        // 子弹出层标题
        subTitle: "",
        contentForm: {
          elementTitle: "",
        },
        // 一级目录
        addForm: {
          body: {
            elementLevel: 1,
            elementTitle: "",
            // 一级目录
            elementType: 0,
            fieldKey: null,
            fieldType: null,
            parentId: 0,
            projectId: "",
            userNo: "",
            userRoleNo: "commonRole",
            // 级联标志(0:非级联 1:级联)
            linkageSign: 0,
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        // 一级目录
        updateForm: {
          body: {
            elementLevel: 1,
            elementTitle: "",
            // 一级目录
            elementType: 0,
            fieldKey: null,
            fieldType: null,
            parentId: 0,
            projectId: "",
            userNo: "",
            userRoleNo: "commonRole",
            // 级联标志(0:非级联 1:级联)
            linkageSign: 0,
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {},
    methods: {
      // 添加
      showInfoAdd(info) {
        // 初始化
        this.contentForm.elementTitle = "";
        this.addForm.body = Object.assign(this.addForm.body, info);
        this.flag = true;
        this.subTitle = "新增一级目录";
        this.open = true;
      },
      // 编辑
      showInfoEdit(row, projectId) {
        this.flag = false;
        this.subTitle = "编辑一级目录";
        this.contentForm = Object.assign(this.contentForm, row);
        this.updateForm.body = Object.assign(this.updateForm.body, row);
        this.updateForm.body.projectId = projectId;
        this.updateForm.body.userNo = this.userNo;
        this.open = true;
      },
      // 配置取消按钮
      handleInfoCancel() {
        this.addForm = this.$options.data().addForm;
        this.updateForm = this.$options.data().updateForm;
        this.$refs.contentForm.resetFields();
        this.open = false;
      },
      handleInfoCertain() {
        this.$refs["contentForm"].validate((valid) => {
          if (valid) {
            this.addForm.body.elementTitle = this.contentForm.elementTitle;
            // 新增
            if (this.flag) {
              addSystemData(this.addForm).then((res) => {
                if (res.header.retCode === "1") {
                  this.$message({
                    type: "success",
                    message: `${res.header.retMessage}`,
                  });
                  this.open = false;
                  this.$refs.contentForm.resetFields();
                  this.$emit("fetchData");
                } else {
                  this.$message.error(`${res.header.retMessage}`);
                }
              });
            } else {
              this.updateForm.body.elementTitle = this.contentForm.elementTitle;
              // 编辑
              updateSystemData(this.updateForm).then((res) => {
                if (res.header.retCode === "1") {
                  this.$message({
                    type: "success",
                    message: `${res.header.retMessage}`,
                  });
                  this.open = false;
                  this.$refs.contentForm.resetFields();
                  this.$emit("fetchData");
                } else {
                  this.$message.error(`${res.header.retMessage}`);
                }
              });
            }
            // 编辑
          }
        });
      },
      // 添加联动
      linkage(item) {},
    },
  };
</script>
