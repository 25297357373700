<!-- 标注记录预览Dialog-->
<template>
  <!-- 标注记录预览 -->
  <el-dialog :visible.sync="dialogimgVisible" append-to-body class="imgDialog">
    <el-tabs
      v-model="activeTabsValue"
      :tab-position="tabPosition"
      style="height: 350px"
    >
      <el-tab-pane
        v-for="(imgItem, imgIndex) in imgData"
        :key="imgIndex"
        :name="imgItem.name"
        stretch="true"
        :label="imgItem.name"
      >
        <el-row>
          <el-col>
            <el-row>
              <el-col style="text-align: center">
                <el-tooltip
                  :content="imgItem.name"
                  placement="top"
                  effect="light"
                >
                  <p style="width: 95%; margin: 0 auto; text-align: center">
                    {{
                      imgItem.name.length > 30
                        ? imgItem.name.substring(0, 30) + "..."
                        : imgItem.name
                    }}
                  </p>
                </el-tooltip>
                <viewer v-if="judgeFileType(imgItem.path)">
                  <img
                    style="max-height: 300px"
                    :src="ossPath + imgItem.path"
                  />
                </viewer>
                <!-- 图片 ——>不可预览 -->
                <img
                  v-else
                  style="width: 160px; height: 160px; cursor: pointer"
                  src="@/icon/pic.svg"
                  :fit="fit"
                  :title="imgItem.name"
                  @click="handleDownload(imgItem)"
                />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
  import { downloadFile, isExistOSS } from "@/utils/index";
  export default {
    name: "LabelRecordView",
    data() {
      return {
        imgData: "",
        tabPosition: "left",
        dialogimgVisible: false,
        fit: "fill",
        activeTabsValue: "2",
        img_index: "",
        urlList: [],
        tab_name: "",
        ossPath: "",
        OSSClient: {},
      };
    },
    created() {},
    methods: {
      /** 判断文件类型 */
      judgeFileType(filePath) {
        /** 可预览的文件类型 */
        const fileType = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
        const itemType = filePath.substring(filePath.lastIndexOf(".") + 1);
        return fileType.includes(itemType);
      },
      /** 点击下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch((err) => {
            this.$message.error("当前文件不存在！");
          });
      },
      showView(row) {
        this.dialogimgVisible = true;
      },
      bindData(imgData, imgIndex, imgName, ossPath, OSSClient) {
        this.ossPath = ossPath;
        this.OSSClient = OSSClient;
        this.activeTabsValue = imgName;
        this.imgData = imgData;
      },
    },
  };
</script>

<style scoped>
  .imgDialog {
    z-index: 10;
  }
  .imgDialog /deep/.el-dialog__header {
    padding: 0 20px;
  }
  .imgDialog /deep/.el-dialog__body {
    padding-top: 40px;
  }
</style>
