<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    :fullscreen="true"
    class="evidence"
  >
    <div v-if="saveTips" class="size-tips">
      取证资料已于{{ saveDate }}自动保存
    </div>
    <el-collapse
      v-for="(item, index) in formTemplate"
      :key="index"
      v-model="activeNames"
      @change="fetchAllData(index)"
    >
      <el-collapse-item :title="item.label" :name="index">
        <VueDragFormBuild
          ref="formBuild"
          :form-template="item"
          :models="models"
        />
      </el-collapse-item>
    </el-collapse>

    <div v-if="tempSaveVisiable" class="evidence-operation">
      <el-button type="success" :loading="saveLoading" @click="handleTempSave">
        保存数据
      </el-button>
    </div>
  </div>
</template>

<script>
  import { uuid } from "@/utils/index";
  import { saveEvidence } from "@/api/workplace/workDetail";
  import { webTemplate } from "@/api/project/manage";
  import { returnBitTimestamp } from "@/utils/index";
  export default {
    name: "Evidence",
    props: {
      row: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        saveTips: false,
        saveDate: "",
        background: true,
        reloadCreate: false,
        loading: false,
        saveLoading: false,

        // 保存数据按钮可见标识
        tempSaveVisiable: false,
        content: "",
        /**保存 */
        param: {
          body: {
            elementValueReqs: [],
            projectId: "",
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        obj: {
          elementId: "",
          elementValue: "",
          seqId: "",
        },
        parameter: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: uuid(),
            sourceType: "site",
          },
        },
        models: {},
        formTemplate: [],
        formTemplateAll: [],
        phoneOpen: false,
        activeNames: [0],
        evidenceTimer: null,
      };
    },
    methods: {
      async fetchData() {
        this.parameter.body.userNo = this.row.userNo;
        this.parameter.body.userRoleNo = this.row.userRoleNo;
        this.parameter.body.workOrderNo = this.row.workOrderNo;
        this.parameter.body.projectId = this.row.projectId;
        // 当前系统时间
        this.parameter.header.currentTime = returnBitTimestamp(new Date());
        this.loading = true;
        const { body, header } = await webTemplate(this.parameter);
        if (header.retCode === "1") {
          this.formTemplateAll = Object.assign(this.formTemplateAll, body);
          this.activeNames.push(0);
          let obj = JSON.parse(JSON.stringify(body));
          obj.forEach((form, index) => {
            if (this.activeNames.indexOf(index) === -1) {
              form.list = [];
            }
          });
          this.formTemplate = Object.assign(this.formTemplate, obj);
          this.reloadCreate = true;
          /**只有督导兼职调查员和 调查工作台具体操作权限 */
          if (!body || body.length === 0) {
            this.content = "当前工单没有取证资料信息";
          } else {
            if (
              (this.row.workplace === "supervisor" &&
                this.row.isSurveyUser === "Y") ||
              this.row.workplace === "book_person" ||
              this.row.workplace === "investigator"
            ) {
              this.$nextTick(() => {});
              this.tempSaveVisiable = true;
            } else {
              this.$nextTick(() => {});
              this.tempSaveVisiable = false;
            }
          }
        } else {
          this.content = "当前取证资料后台返回异常，请稍后尝试";
        }
        this.disabledFormElements();
        this.loading = false;
      },
      // 禁用表单元素
      disabledFormElements() {
        let formElements = [];
        if (this.formTemplate.length !== 0) {
          this.formTemplate.forEach((item, index) => {
            if (item.length !== 0) {
              item.list.forEach((subItem, index) => {
                if (!this.tempSaveVisiable) {
                  subItem.options.disabled = true;
                }
              });
            }
          });
        }
      },
      save() {
        return new Promise((resolve, reject) => {
          this.param.body.workOrderNo = this.row.workOrderNo;

          const formBuilds = this.$refs.formBuild;

          let datas = [];

          for (let i in formBuilds) {
            datas.push(formBuilds[i].getData());
          }
          Promise.all(datas)
            .then((res) => {
              for (let i in res) {
                if (res[i]) {
                  this.handleTempSave();
                  resolve();
                  return;
                }
              }
            })
            .catch(function (reason) {
              reject(reason);
            });
        });
      },

      async handleTempSave() {
        this.saveLoading = true;
        let arr1 = Object.keys(this.models)
          .filter((item) => {
            return item.split("_").length < 3;
          })
          .map((item) => {
            return item;
          });

        arr1.forEach((item, index) => {
          this.obj.elementId = item.substr(4, item.length - 1);
          this.obj.elementValue = this.models[item] + "";
          this.param.body.elementValueReqs.push(Object.assign({}, this.obj));
        }),
          (this.param.header.currentTime = returnBitTimestamp(new Date()));
        this.param.body.projectId = this.row.projectId;
        this.param.body.userNo = this.row.userNo;
        this.param.body.userRoleNo = this.row.userRoleNo;
        this.param.body.workOrderNo = this.row.workOrderNo;
        const { body, header } = await saveEvidence(this.param);
        if (header.retCode === "1") {
          this.$baseMessage("数据保存成功！", "success");
          this.param.body.elementValueReqs = [];
          await this.fetchData();
        } else {
          this.$message.error(`${header.retMessage}`);
          this.param.body.elementValueReqs = [];
        }
        this.saveLoading = false;
      },
      async finalSave() {
        let arr1 = Object.keys(this.models)
          .filter((item) => {
            return item.split("_").length < 3;
          })
          .map((item) => {
            return item;
          });

        arr1.forEach((item, index) => {
          this.obj.elementId = item.substr(4, item.length - 1);
          this.obj.elementValue = this.models[item] + "";
          this.param.body.elementValueReqs.push(Object.assign({}, this.obj));
        }),
          (this.param.header.currentTime = returnBitTimestamp(new Date()));
        this.param.body.projectId = this.row.projectId;
        this.param.body.userNo = this.row.userNo;
        this.param.body.userRoleNo = this.row.userRoleNo;
        this.param.body.workOrderNo = this.row.workOrderNo;
        const { body, header } = await saveEvidence(this.param);
        if (header.retCode === "1") {
          this.saveTips = true;
          this.saveDate = returnBitTimestamp(new Date());
          setTimeout(() => {
            this.saveTips = false;
          }, 3500);
          this.param.body.elementValueReqs = [];
        } else {
          this.$message.error(`${header.retMessage}`);
        }
      },

      reset() {
        this.clearInterval();
        if (this.models === null || this.models === {}) {
        } else {
          this.models = {};
          this.formTemplate = [];
        }
        this.reloadCreate = false;
        this.activeNames = [];
        this.activeNames.push(0);
      },

      createTimer() {
        // 如果当前页面打开每隔10分钟，自动保存一次取证资料页面
        if (
          this.row.isSurveyUser !== null &&
          this.row.isSurveyUser !== undefined
        ) {
          if (this.row.isSurveyUser === "Y") {
            this.evidenceTimer = setInterval(this.finalSave, 60 * 10 * 1000);
          }
        } else {
          this.evidenceTimer = setInterval(this.finalSave, 60 * 10 * 1000);
        }
      },
      clearInterval() {
        clearInterval(this.evidenceTimer);
      },
      fetchAllData(val) {
        if (
          null === this.formTemplate[val].list ||
          this.formTemplate[val].list.length === 0
        ) {
          this.formTemplate.splice(val, 1, this.formTemplateAll[val]);
          if (this.activeNames.indexOf(val) === -1) {
            this.activeNames.push(val);
          }
        }
        this.disabledFormElements();
      },
    },
  };
</script>

<style scoped>
  .label-title {
    width: 100%;
    display: block;
    background: #005dca;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-weight: 700;
    padding-left: 10px;
    text-align: center;
    margin: 10px 0 20px 0;
  }
  .myEl {
    margin: 40px 0;
  }
  .myEl img {
    width: 180px;
    height: 150px;
  }
  .evidence {
    min-height: 100px;
  }
  .evidence-operation {
    display: flex;
    justify-content: center;
  }
  .size-tips {
    position: fixed;
    top: 19%;
    right: 44%;
    min-width: 100px;
    text-align: center;
    background: rgba(209, 226, 195, 0.8);
    z-index: 99;
    padding: 5px 10px;
  }
</style>
<style>
  .el-collapse-item__header {
    font-weight: 700 !important;
    height: auto !important;
    line-height: 24px;
  }
</style>
