var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.subTitle,
        visible: _vm.open,
        width: "58%",
        "close-on-click-modal": false,
        "append-to-body": "",
        "before-close": _vm.handleNodeCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "contentForm",
          staticStyle: { padding: "10px" },
          attrs: {
            model: _vm.contentForm,
            rules: _vm.contentFormRules,
            "label-width": "100px",
            size: "mini",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据类型", prop: "fieldType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.dataChange },
                          model: {
                            value: _vm.contentForm.fieldType,
                            callback: function ($$v) {
                              _vm.$set(_vm.contentForm, "fieldType", $$v)
                            },
                            expression: "contentForm.fieldType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "input" } }, [
                            _vm._v("输入框"),
                          ]),
                          _c("el-radio", { attrs: { label: "select" } }, [
                            _vm._v("下拉选择器"),
                          ]),
                          _c("el-radio", { attrs: { label: "radio" } }, [
                            _vm._v("单选框"),
                          ]),
                          _c("el-radio", { attrs: { label: "checkbox" } }, [
                            _vm._v("多选框"),
                          ]),
                          _c("el-radio", { attrs: { label: "album" } }, [
                            _vm._v("相册"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.contentForm.fieldType === "input"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否必填", prop: "required" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", filterable: "" },
                              model: {
                                value: _vm.contentForm.required,
                                callback: function ($$v) {
                                  _vm.$set(_vm.contentForm, "required", $$v)
                                },
                                expression: "contentForm.required",
                              },
                            },
                            _vm._l(_vm.requireOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "input"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型", prop: "displayType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", filterable: "" },
                              model: {
                                value: _vm.contentForm.displayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.contentForm, "displayType", $$v)
                                },
                                expression: "contentForm.displayType",
                              },
                            },
                            _vm._l(_vm.inputTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "input"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "字段名称", prop: "elementTitle" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入字段名称",
                            },
                            model: {
                              value: _vm.contentForm.elementTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "elementTitle",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.elementTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "input"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fieldKey", label: "英文名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入英文名称" },
                            model: {
                              value: _vm.contentForm.fieldKey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "fieldKey",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.fieldKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "input"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "字段值", prop: "itemValue" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入字段值",
                            },
                            model: {
                              value: _vm.contentForm.itemValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.contentForm, "itemValue", $$v)
                              },
                              expression: "contentForm.itemValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.contentForm.fieldType === "select"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "展示选择", prop: "displayChoice" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", filterable: "" },
                              model: {
                                value: _vm.contentForm.displayChoice,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.contentForm,
                                    "displayChoice",
                                    $$v
                                  )
                                },
                                expression: "contentForm.displayChoice",
                              },
                            },
                            _vm._l(_vm.selectOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "select"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fieldKey", label: "英文名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入英文名称" },
                            model: {
                              value: _vm.contentForm.fieldKey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "fieldKey",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.fieldKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "select"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型", prop: "", hidden: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "" },
                              model: {
                                value: _vm.contentForm.displayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.contentForm, "displayType", $$v)
                                },
                                expression: "contentForm.displayType",
                              },
                            },
                            _vm._l(_vm.selectTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "select"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "可清空", prop: "clearable" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", filterable: "" },
                              model: {
                                value: _vm.contentForm.clearable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.contentForm, "clearable", $$v)
                                },
                                expression: "contentForm.clearable",
                              },
                            },
                            _vm._l(_vm.clearableOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "select"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "elementTitle", label: "字段名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入字段名称",
                            },
                            model: {
                              value: _vm.contentForm.elementTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "elementTitle",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.elementTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.contentForm.fieldType === "radio"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "elementTitle", label: "字段名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入字段名称",
                            },
                            model: {
                              value: _vm.contentForm.elementTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "elementTitle",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.elementTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "radio"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fieldKey", label: "英文名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入英文名称" },
                            model: {
                              value: _vm.contentForm.fieldKey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "fieldKey",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.fieldKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.contentForm.fieldType === "checkbox"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "elementTitle", label: "字段名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入字段名称",
                            },
                            model: {
                              value: _vm.contentForm.elementTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "elementTitle",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.elementTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "checkbox"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fieldKey", label: "英文名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入英文名称" },
                            model: {
                              value: _vm.contentForm.fieldKey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "fieldKey",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.fieldKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.contentForm.fieldType === "album"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "albumLength", label: "图片宽度X" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入（厘米）" },
                            model: {
                              value: _vm.contentForm.albumLength,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "albumLength",
                                  _vm._n($$v)
                                )
                              },
                              expression: "contentForm.albumLength",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "album"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "albumWidth", label: "图片高度Y" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入（厘米）" },
                            model: {
                              value: _vm.contentForm.albumWidth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "albumWidth",
                                  _vm._n($$v)
                                )
                              },
                              expression: "contentForm.albumWidth",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "album"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "elementTitle", label: "字段名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入字段名称",
                            },
                            model: {
                              value: _vm.contentForm.elementTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "elementTitle",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.elementTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentForm.fieldType === "album"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fieldKey", label: "英文名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入英文名称" },
                            model: {
                              value: _vm.contentForm.fieldKey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contentForm,
                                  "fieldKey",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "contentForm.fieldKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleNodeCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handleNodeCertain },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }