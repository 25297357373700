var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "evidence",
      attrs: {
        "element-loading-text": "拼命加载中",
        "element-loading-spinner": "el-icon-loading",
        fullscreen: true,
      },
    },
    [
      _vm.reloadCreate ? _c("div", { attrs: { id: "form-create" } }) : _vm._e(),
      !_vm.rule || _vm.rule.length === 0 || _vm.rule === {}
        ? _c("div", [_vm._v(_vm._s(_vm.content))])
        : _vm._e(),
      _vm.tempSaveVisiable
        ? _c(
            "div",
            { staticClass: "evidence-operation" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.handleTempSave },
                },
                [_vm._v("保存数据")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }