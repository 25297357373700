<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否确认放弃？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="内审人员" prop="nsUser">
        <el-select
          v-model="form.nsUser"
          placeholder="请选择内审人员"
          clearable
          filterable
        >
          <el-option
            v-for="item in nsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="放弃原因" prop="quitReason">
        <el-select
          v-model="form.quitReason"
          placeholder="请选择放弃原因"
          clearable
          filterable
        >
          <el-option
            v-for="item in quitOptions"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model="form.note"
          type="textarea"
          placeholder="请输入小记"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getInternalAudit } from "@/api/workplace/investigate/investigate";
  import { getQuitReason, quit } from "@/api/workplace/order/order";
  import { mapGetters } from "vuex";
  import { returnBitTimestamp, getRoleKey } from "@/utils";
  export default {
    name: "Edit",
    data() {
      return {
        // 防抖
        loading: false,
        form: {
          nsUser: "",
          quitReason: "",
          note: "",
        },
        rules: {
          nsUser: [
            { required: true, trigger: "blur", message: "请选择内审人员" },
          ],
          quitReason: [
            { required: true, trigger: "blur", message: "请选择放弃原因" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
        nsOptions: [],
        // 查询内审人员参数
        nsParam: {
          body: [
            {
              roleKey: "verify_people",
            },
          ],
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        // 查询放弃原因
        quitOptions: [],
        quitParam: {
          body: {
            templateTypeSeqId: "6694249168413790208",
          },
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        object: [],
        // 放弃提交需要的参数
        quitParameter: {
          body: [],
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        obj: {
          abandonCommentId: null,
          auditUser: null,
          console: "book_person",
          nextTaskId: "",
          nextUserNo: "",
          nextUserRoleNo: "",
          note: null,
          operAction: "工单放弃",
          procInsId: "",
          projectSource: "",
          remark: "",
          step: "预约工作台-工单预约-待处理",
          taskId: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.obj.userNo = this.userNo;
      this.obj.userRoleNo = getRoleKey("book_person", this.roles);
    },
    methods: {
      async showQuit(row) {
        this.title = "放弃工单";

        // 获取内审人员
        this.nsOptions = Object.assign(
          {},
          (await getInternalAudit(this.nsParam)).body
        );
        // 获取放弃原因
        this.quitOptions = Object.assign(
          {},
          (await getQuitReason(this.quitParam)).body
        );

        this.object = row;

        this.form = Object.assign({}, row[0]);
        this.dialogFormVisible = true;

        this.quitParameter.body = [];
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("refreshData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            for (let i = 0; i < this.object.length; i++) {
              this.obj.procInsId = this.object[i].procInsId;
              this.obj.taskId = this.object[i].taskId;
              this.obj.projectSource = this.object[i].source;
              this.obj.workOrderNo = this.object[i].workOrderNo;
              this.obj.abandonCommentId = this.form.quitReason;
              this.obj.auditUser = this.form.nsUser;
              this.obj.note = this.form.note;

              this.quitParameter.body.push(Object.assign({}, this.obj));
            }

            const { header } = await quit(
              Object.assign({}, this.quitParameter)
            );
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }

            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("refreshData");
            this.form = this.$options.data().form;

            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
