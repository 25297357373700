<!--Kyle 工作台 ->导入附件-->
<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="573px"
    :before-close="handleClose"
  >
    <div class="title">上传附件</div>
    <el-steps :active="2" style="margin: 30px -40%" align-center>
      <el-step title="上传工单" icon="el-icon-success"></el-step>
      <el-step title="上传附件">
        <vab-remix-icon icon-class="number-2" />
      </el-step>
    </el-steps>
    <el-card class="box-card">
      <el-row align="middle" type="flex" :gutter="20">
        <el-col :span="24">
          <div style="font-weight: 600; font-size: 15px; margin-bottom: 10px">
            请您上传资源
          </div>
          <div>
            <el-upload
              ref="upload"
              class="upload-demo"
              :action="endpoinxBak"
              :on-error="handleError"
              :on-remove="handleRemove"
              :on-change="handleFileChange"
              :http-request="batch"
              multiple
              :auto-upload="false"
              :file-list="fileList"
              drag
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
              <div slot="tip" class="el-upload__tip">附件大小不得大于 1G</div>
            </el-upload>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-form>
        <el-form-item align="center">
          <el-button
            ref="$complete"
            :loading="subLoading"
            type="primary"
            :disabled="submitDisabled"
            @click="completeAnnex"
          >
            完成
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  const OSS = require("ali-oss");
  const attachmentPath = "cbi-site-front/workplace/attachment/";
  import axios from "axios";
  import { uploadAttachment } from "@/api/workplace/importAnnex";
  import {
    returnBitTimestamp,
    getRoleKey /** 文件名称格式校验 */,
    fileFormatValid,
  } from "@/utils/index";
  import { mapGetters } from "vuex";
  export default {
    name: "ImportAnnex",
    data() {
      return {
        /**添加文件任务数组 */
        fileArr: [],
        /**添加异步任务数组 */
        promiseArr: [],
        subLoading: false,
        checkImport: true,
        fileList: [],
        dialogTitle: "",
        dialogFormVisible: false,
        // 提交按钮禁用标识
        submitDisabled: false,
        fileNameList: [],
        // 上传附件 Request
        uploadAttachmentForm: {
          body: {
            files: [],
            workOrderNos: [],
            userNo: "",
            userRoleNo: "",
          },
          header: {
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
            currentTime: "",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        accessKeyId: "user/accessKeyId",
        accessKeySecret: "user/accessKeySecret",
        bucketName: "user/bucketName",
        endpoint: "user/endpoint",
        endpoinxBak: "user/endpoinxBak",
        region: "user/region",
        policy: "user/policy",
        signature: "user/signature",
      }),
    },
    created() {
      this.uploadAttachmentForm.body.userNo = this.userNo;
      this.uploadAttachmentForm.body.userRoleNo = getRoleKey("PL", this.roles);
    },
    methods: {
      // 返回打开界面 完成
      async completeAnnex() {
        await this.$refs.upload.submit();
        this.subLoading = true;
        this.promiseArr = this.fileArr.map((el, index) => {
          return this.fnUploadRequest(el, index);
        });
        Promise.all(this.promiseArr)
          .then((res) => {
            this.promiseArr = [];
            this.fileArr = [];
            if (this.uploadAttachmentForm.body.files.length !== 0) {
              // 获取系统当前时间
              this.uploadAttachmentForm.header.currentTime = returnBitTimestamp(
                new Date()
              );
              uploadAttachment(this.uploadAttachmentForm).then((response) => {
                this.uploadAttachmentForm.body.files = [];
                if (response.header.retCode === "1") {
                  this.subLoading = false;
                  this.handleClose();
                  this.$baseMessage(header.retMessage, "success");
                } else {
                  this.subLoading = false;
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},附件上传失败，请重新上传！`
                  );
                }
              });
            } else {
              this.subLoading = false;
              this.handleClose();
            }
          })
          .catch((error) => {
            this.subLoading = false;
            this.promiseArr = [];
            this.fileArr = [];
            this.uploadAttachmentForm.body.files = [];
            reject(error);
          });
      },
      handleClose() {
        /** 关闭父级对话框， 刷新列表页 */
        this.$emit("annex-todo");
        this.fileList = [];
        this.uploadAttachmentForm.body.files = [];
        this.fileNameList = [];
        this.dialogFormVisible = false;
        this.submitDisabled = false;
      },
      // 打开创建工单
      showAnnex(workOrderNos, step, operAction, console_info) {
        this.dialogTitle = "上传附件";
        // 工单号
        this.uploadAttachmentForm.body.workOrderNos = workOrderNos;
        this.dialogFormVisible = true;
      },
      handleFileChange(file, filelist) {
        let exitedObj = {};
        /*** 判断文件大小 */
        if (filelist.length > 0) {
          for (let item of filelist) {
            const expSize = item.size / 1024 / 1024 < 1024;
            if (!expSize) {
              this.$message.error(`文件大小不能超过 1G, 请检查`);
              this.submitDisabled = true;
              return;
            }
          }
        }
        // 判断文件命名非法字符校验
        if (filelist.length > 0) {
          for (let item of filelist) {
            const filename = item.name.substring(0, item.name.lastIndexOf("."));
            if (!fileFormatValid(filename)) {
              this.$message.error("文件名称中不能包含非法特殊字符, 请检查");
              this.submitDisabled = true;
              return;
            }
          }
        }
        /** 判断文件同名 */
        if (filelist.length > 1) {
          for (let i in filelist) {
            if (exitedObj[filelist[i].name]) {
              this.$message.error("请勿上传同名文件！");
              this.submitDisabled = true;
              return;
            }
            exitedObj[filelist[i].name] = true;
          }
        }
        this.submitDisabled = false;
      },
      /**
       * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
       * @author   shanshuizinong
       * @param    {object}   option [上传选项]
       * @param index
       * @return   {null}   [没有返回]
       */
      async fnUploadRequest(option, index) {
        return new Promise((resolve, reject) => {
          /** 带有时间戳的文件名 */
          const fileName =
            new Date().getTime() +
            index +
            "." +
            option.file.name.match(/^(.*)(\.)(.{1,8})$/)[3];
          // 获取OSS配置信息
          const uploadConfig = new FormData();
          uploadConfig.append("key", attachmentPath + fileName);
          uploadConfig.append("OSSAccessKeyId", this.accessKeyId);
          uploadConfig.append("policy", this.policy);
          uploadConfig.append("Signature", this.signature);
          uploadConfig.append("success_action_status", 200);
          uploadConfig.append("name", option.file.name);
          /** 表单域 file 必须为最后一个 */
          uploadConfig.append("file", option.file);

          axios
            .post(this.endpoinxBak, uploadConfig, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.status == 200) {
                this.fileNameList.push(option.file.name);
                //上传成功 上传的路径就是要使用的路径
                this.uploadAttachmentForm.body.files.push({
                  url: attachmentPath + fileName,
                  name: option.file.name,
                });
              }
              resolve(res);
            })
            .catch((error) => {
              const index = this.indexOf(
                option.file.name,
                this.uploadAttachmentForm.body.files
              );
              this.uploadAttachmentForm.body.files.splice(index, 1);
              this.$message.error(`${option.file.name}上传错误,请重新上传`);
              reject(error);
            });
        });
      },
      /** 自定义上传方法，使其多次添加资源 */
      batch(option) {
        this.fileArr.push(option);
      },
      /*** 匹配返回数组索引 */
      indexOf(val, arr) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].name == val) {
            return i;
          }
        }
        return -1;
      },
      /*** 删除附件 */
      async handleRemove(file, filelist) {
        // 重复文件计数
        let count = 0;
        let exitedObj = {};
        if (filelist.length > 1) {
          for (let i in filelist) {
            if (exitedObj[filelist[i].name]) {
              count++;
            }
            exitedObj[filelist[i].name] = true;
          }
        }
        /*** 判断文件大小 */
        if (filelist.length > 0) {
          for (let item of filelist) {
            const expSize = item.size / 1024 / 1024 < 1024;
            if (!expSize) {
              this.$message.error(`文件大小不能超过 1G, 请检查`);
              count++;
            }
          }
        }
        // 判断文件命名非法字符校验
        if (filelist.length > 0) {
          for (let item of filelist) {
            const filename = item.name.substring(0, item.name.lastIndexOf("."));
            if (!fileFormatValid(filename)) {
              this.$message.error("文件名称中不能包含非法特殊字符, 请检查");
              count++;
            }
          }
        }
        this.submitDisabled = count >= 1 ? true : false;
        if (this.fileNameList.indexOf(file.name) > -1) {
          const client = new OSS({
            // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
            bucket: this.bucketName,
            region: this.region,
            // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
            accessKeyId: this.accessKeyId,
            accessKeySecret: this.accessKeySecret,
          });
          /** 删除oss文件 */
          // let res = await client.delete(attachmentPath + file.name);
          var index = this.indexOf(
            file.name,
            this.uploadAttachmentForm.body.files
          );
          if (index > -1) {
            this.uploadAttachmentForm.body.files.splice(index, 1);
          }
        }
      },
      /** 上传失败的回调 */
      handleError(err, file, fileList) {
        this.$message.error(`上传错误,错误信息 ${err}`);
      },
    },
  };
</script>
<style lang="scss" scoped>
  h3 {
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-weight: 700;
    text-decoration: none;
    line-height: 30px;
  }
  .title {
    margin: 10px 0;
  }
  .el-icon-upload {
    display: flex;
    justify-content: center;
    font-size: 100px;
    color: #ccc;
  }
</style>
