/**
 * @description 禁用今天之前的日期
 * @author miller
 *  @param {string} time
 */
export function disabledDate(time) {
  return time.getTime() < Date.now() - 8.24e7;
}
/**
 * @description 禁用当前时间之前
 * @author miller
 *  @param {string} time
 */

export function selectableRange() {
  return new Date().toTimeString().substring(0, 5) + ":00" + " - 23:59:59";
}
