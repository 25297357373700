<template>
  <div class="table-container">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-table ref="tableSort" v-loading="loading" :data="list" border>
          <el-table-column
            label="序号"
            align="center"
            width="80"
            prop="seq"
          ></el-table-column>
          <el-table-column
            prop="in_date"
            label="判决时间"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.date || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="类型" width="120" align="center">
            <template slot-scope="scope">
              {{ scope.row.type || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="案号">
            <template slot-scope="scope">
              {{ scope.row.case_no || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="标题">
            <template slot-scope="scope">
              {{ scope.row.title || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="案由" width="250">
            <template slot-scope="scope">
              {{ scope.row.case_cause || "-" }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row v-if="total > 0" :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :current-page="queryForm.pageNum"
            :page-size="queryForm.pageSize"
            :total="total"
            :background="background"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getLawsuitsPost } from "@/api/workplace/companyInfo";

  export default {
    name: "JudgementDocument",

    data() {
      return {
        list: [],
        loading: false,
        total: 0,
        background: true,
        layout: "total, sizes, prev, pager, next, jumper",
        queryForm: {
          body: {
            keyword: "",
            seqId: "",
            skip: 0,
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          pageNum: 1,
          pageSize: 20,
        },
        obj: null,
      };
    },

    methods: {
      getLawsuitsPost(param) {
        this.loading = true;
        this.obj = param;
        this.queryForm.body.keyword = param.keyword;
        this.queryForm.body.seqId = param.seqId;
        this.queryForm.body.skip = (this.queryForm.pageNum - 1) * 20;
        getLawsuitsPost(this.queryForm)
          .then((response) => {
            if (response.header.retCode === "1") {
              this.list = response.body.items;
              this.total = response.body.total;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.getLawsuitsPost(this.obj);
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.getLawsuitsPost(this.obj);
      },
    },
  };
</script>

<style scoped></style>
