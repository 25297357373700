<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item
        title="企业基本信息"
        name="1"
        @click.native="showBaseInfo"
      >
        <baseInfo ref="baseInfo" :param="param"></baseInfo>
      </el-collapse-item>
      <el-collapse-item
        title="主要人员"
        name="2"
        @click.native.prevent="showMainPerson"
      >
        <mainPerson ref="mainPerson" :param="param"></mainPerson>
      </el-collapse-item>
      <el-collapse-item
        title="分支机构"
        name="3"
        @click.native="showDepartment"
      >
        <department ref="department" :param="param"></department>
      </el-collapse-item>
      <el-collapse-item
        title="变更记录"
        name="4"
        @click.native="showChangeRecord"
      >
        <changeRecord ref="changeRecord" :param="param"></changeRecord>
      </el-collapse-item>
      <el-collapse-item
        title="股东信息"
        name="5"
        @click.native="showShareholder"
      >
        <shareholder ref="shareholder" :param="param"></shareholder>
      </el-collapse-item>
      <el-collapse-item
        title="企业年报"
        name="6"
        @click.native="showYearReport"
      >
        <companyYearReport
          ref="companyYearReport"
          :param="param"
        ></companyYearReport>
      </el-collapse-item>
      <el-collapse-item
        title="股权出质"
        name="7"
        @click.native="showEquityPledge"
      >
        <equityPledge ref="equityPledge" :param="param"></equityPledge>
      </el-collapse-item>
      <el-collapse-item
        title="经营异常"
        name="8"
        @click.native="showAbnormalOperation"
      >
        <abnormalOperation
          ref="abnormalOperation"
          :param="param"
        ></abnormalOperation>
      </el-collapse-item>
      <el-collapse-item
        title="严重违法失信企业名单（黑名单）信息"
        name="9"
        @click.native="showBlackList"
      >
        <blackList ref="blackList" :param="param"></blackList>
      </el-collapse-item>
      <el-collapse-item
        title="重大税收违法案件信息"
        name="10"
        @click.native="showTaxAgainLaw"
      >
        <taxAgainLaw ref="taxAgainLaw" :param="param"></taxAgainLaw>
      </el-collapse-item>
      <el-collapse-item
        title="裁判文书"
        name="11"
        @click.native="showJudgementDocument"
      >
        <judgementDocument
          ref="judgementDocument"
          :param="param"
        ></judgementDocument>
      </el-collapse-item>
      <el-collapse-item
        title="开庭公告"
        name="12"
        @click.native="showKaiTingGongGao"
      >
        <kaiTingGongGao ref="kaiTingGongGao" :param="param"></kaiTingGongGao>
      </el-collapse-item>
      <el-collapse-item
        title="立案信息"
        name="13"
        @click.native="showLiAnXinXi"
      >
        <liAnXinXi ref="liAnXinXi" :param="param"></liAnXinXi>
      </el-collapse-item>
      <el-collapse-item
        title="失信被执行"
        name="14"
        @click.native="showShiXinBeiZhiXingRen"
      >
        <shiXinBeiZhiXingRen
          ref="shiXinBeiZhiXingRen"
          :param="param"
        ></shiXinBeiZhiXingRen>
      </el-collapse-item>
      <el-collapse-item
        title="被执行人"
        name="15"
        @click.native="showBeiZhiXingRen"
      >
        <beiZhiXingRen ref="beiZhiXingRen" :param="param"></beiZhiXingRen>
      </el-collapse-item>
      <el-collapse-item title="招聘信息" name="16" @click.native="showJobInfo">
        <jobInfo ref="jobInfo" :param="param"></jobInfo>
      </el-collapse-item>
      <el-collapse-item
        title="行政处罚"
        name="17"
        @click.native="showPunishment"
      >
        <punishment ref="punishment" :param="param"></punishment>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
  import baseInfo from "@/views/workplace/components/company/baseInfo";
  import mainPerson from "@/views/workplace/components/company/mainPerson";
  import department from "@/views/workplace/components/company/department";
  import changeRecord from "@/views/workplace/components/company/changeRecord";
  import shareholder from "@/views/workplace/components/company/shareholder";
  import companyYearReport from "@/views/workplace/components/company/companyYearReport";
  import equityPledge from "@/views/workplace/components/company/equityPledge";
  import abnormalOperation from "@/views/workplace/components/company/abnormalOperation";
  import blackList from "@/views/workplace/components/company/blackList";
  import taxAgainLaw from "@/views/workplace/components/company/taxAgainLaw";
  import judgementDocument from "@/views/workplace/components/company/judgementDocument";
  import kaiTingGongGao from "@/views/workplace/components/company/kaiTingGongGao";
  import liAnXinXi from "@/views/workplace/components/company/liAnXinXi";
  import shiXinBeiZhiXingRen from "@/views/workplace/components/company/shiXinBeiZhiXingRen";
  import beiZhiXingRen from "@/views/workplace/components/company/beiZhiXingRen";
  import jobInfo from "@/views/workplace/components/company/jobInfo";
  import punishment from "@/views/workplace/components/company/punishment";
  export default {
    name: "Company",
    components: {
      baseInfo,
      mainPerson,
      department,
      changeRecord,
      shareholder,
      companyYearReport,
      equityPledge,
      abnormalOperation,
      blackList,
      taxAgainLaw,
      judgementDocument,
      kaiTingGongGao,
      liAnXinXi,
      shiXinBeiZhiXingRen,
      beiZhiXingRen,
      jobInfo,
      punishment,
    },
    props: {
      // eslint-disable-next-line vue/require-default-prop
      row: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: null,
      },
    },
    data() {
      return {
        activeNames: ["1"],
        param: {
          keyword: "",
          seqId: "",
        },
      };
    },
    created() {},
    methods: {
      init() {
        this.param.keyword = this.row.targetSubject;
        this.param.seqId = this.row.seqId;

        setTimeout(() => {
          this.$refs.baseInfo.getBaseInfo(this.param);
        }, 10);
      },

      showBaseInfo() {
        this.$refs.baseInfo.getBaseInfo(this.param);
      },
      showMainPerson() {
        this.$refs.mainPerson.getMainPerson(this.param);
      },
      showDepartment() {
        this.$refs.department.getDepartment(this.param);
      },
      showChangeRecord() {
        this.$refs.changeRecord.getChangeRecord(this.param);
      },
      showShareholder() {
        this.$refs.shareholder.getShareholder(this.param);
      },
      showYearReport() {
        this.$refs.companyYearReport.getYearReport(this.param);
      },
      showEquityPledge() {
        this.$refs.equityPledge.getEquityPledge(this.param);
      },
      showAbnormalOperation() {
        this.$refs.abnormalOperation.getAbnormalOperation(this.param);
      },
      showBlackList() {
        this.$refs.blackList.getBlackList(this.param);
      },
      showTaxAgainLaw() {
        this.$refs.taxAgainLaw.getHugeTaxPunishment(this.param);
      },
      showJudgementDocument() {
        this.$refs.judgementDocument.getLawsuitsPost(this.param);
      },
      showKaiTingGongGao() {
        this.$refs.kaiTingGongGao.getNoticeDetail(this.param);
      },
      showLiAnXinXi() {
        this.$refs.liAnXinXi.getCasesPost(this.param);
      },
      showShiXinBeiZhiXingRen() {
        this.$refs.shiXinBeiZhiXingRen.getDataPost(this.param);
      },
      showBeiZhiXingRen() {
        this.$refs.beiZhiXingRen.getPersonsPost(this.param);
      },
      showJobInfo() {
        this.$refs.jobInfo.getJobInfo(this.param);
      },
      showPunishment() {
        this.$refs.punishment.getPunishments(this.param);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .info-table {
    border-collapse: collapse;
    font-size: 14px;
    color: #333;

    tr td {
      border: 1px solid #ccc;
      padding: 10px;

      &:nth-of-type(even) {
        width: 35%;
        font-weight: normal;
      }

      &:nth-of-type(odd) {
        width: 15%;
        background: #f5f7fa;
      }
    }
  }
  .company-info-title {
    padding: 10px 0;
  }
  html body .el-card {
    margin-bottom: 0;
  }
</style>
