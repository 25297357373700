<template>
  <div class="table-container">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-table ref="tableSort" v-loading="loading" :data="list" border>
          <el-table-column
            label="序号"
            align="center"
            width="80"
            prop="seq"
          ></el-table-column>
          <el-table-column prop="inReason" label="列入原因">
            <template slot-scope="scope">
              {{ scope.row.inReason || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="列入日期" width="180" align="center">
            <template slot-scope="scope">
              {{ scope.row.inDate || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="作出决定机关（列入）">
            <template slot-scope="scope">
              {{ scope.row.inDepartment || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="移出经营异常名录原因">
            <template slot-scope="scope">
              {{ scope.row.outReason || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="作出决定机关" width="250">
            <template slot-scope="scope">
              {{ scope.row.outDepartment || "-" }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row v-if="total > 0" :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :current-page="queryForm.pageNum"
            :page-size="queryForm.pageSize"
            :background="background"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getBlackList } from "@/api/workplace/companyInfo";

  export default {
    // 严重违法失信企业名单（黑名单）信息
    name: "BlackList",

    data() {
      return {
        list: [],
        loading: false,
        total: 0,
        background: true,
        layout: "total, sizes, prev, pager, next, jumper",
        queryForm: {
          body: {
            keyword: "",
            seqId: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          pageNum: 1,
          pageSize: 20,
        },
        obj: null,
      };
    },

    methods: {
      getBlackList(param) {
        this.loading = true;
        this.obj = param;
        this.queryForm.body.keyword = param.keyword;
        this.queryForm.body.seqId = param.seqId;
        this.queryForm.body.skip = (this.queryForm.pageNum - 1) * 20;
        getBlackList(this.queryForm)
          .then((response) => {
            if (response.header.retCode === "1") {
              this.list = response.body.items;
              this.total = response.body.total;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.getBlackList(this.obj);
      },
      handleCurrentChange(val) {
        this.queryFormd.pageNum = val;
        this.getBlackList(this.obj);
      },
    },
  };
</script>

<style scoped></style>
