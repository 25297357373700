<!--Kyle PL工作台 -> 对账ID -> 待处理-->
<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class">
            <el-row :gutter="20">
              <!-- 下单日期范围  -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item>
                  <el-date-picker v-model="reset.orderScopeDate"
                                  type="daterange"
                                  unlink-panels
                                  :clearable="true"
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.trim="queryForm.workOrderNo"
                            placeholder="工单编号"
                            :clearable="true"
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            placeholder="目标主体"
                            :clearable="true"
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item>
                  <el-cascader :value="queryForm.projectNo"
                               :options="projectInfo"
                               :clearable="true"
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <transition name="fade">
              <el-row v-show="isShow"
                      transiton="fade"
                      :gutter="20">
                <!-- 内部截止 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="innerDeadline">
                    <el-date-picker v-model="reset.innerDeadline"
                                    type="daterange"
                                    unlink-panels
                                    :clearable="true"
                                    range-separator="至"
                                    start-placeholder="内部截止开始日期"
                                    end-placeholder="内部截止结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 工单状态 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="workOrderStatus">
                    <el-select v-model="queryForm.workOrderStatus"
                               placeholder="工单状态"
                               :clearable="true"
                               filterable>
                      <el-option v-for="item in orderStatusOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 选择地区 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item>
                    <el-cascader v-model="reset.block"
                                 :options="cityInfo"
                                 :clearable="true"
                                 :filterable="true"
                                 placeholder="地区"
                                 :props="{ checkStrictly: true }"
                                 @change="handleBlockChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- Project Name -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="projectNameNo">
                    <el-input v-model.trim="queryForm.projectNameNo"
                              placeholder="Project Name"
                              :clearable="true"
                              @keyup.enter.native="handleQuery"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 订单编号 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="brWorkNo">
                    <el-input v-model.trim="queryForm.brWorkNo"
                              placeholder="订单编号"
                              clearable
                              @keyup.enter.native="handleQuery"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 报告类型 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reportType">
                    <el-select v-model="queryForm.reportType"
                               placeholder="报告类型"
                               clearable
                               filterable>
                      <el-option v-for="item in reportType"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 项目来源 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="projectSource">
                    <el-select v-model="queryForm.projectSource"
                               placeholder="请选择项目来源"
                               clearable
                               filterable>
                      <el-option v-for="item in resourceOptions"
                                 :key="item.key"
                                 :label="item.name"
                                 :value="item.key"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </transition>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="batchAdd">
            批量添加
          </el-button>
          <el-button :loading="downloadLoading"
                     type="primary"
                     @click="handleDownload">
            导出
          </el-button>
          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    style="margin-top: 15px"
                    border
                    @selection-change="setSelectRows"
                    @sort-change="tableSortChange">
            <el-table-column type="selection"
                             width="40"
                             align="center"></el-table-column>
            <el-table-column label="工单编号"
                             prop="workOrderNo"
                             align="center"
                             width="130"></el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderTime"
                             label="下单日期"
                             :formatter="dateFormat"
                             width="120"
                             align="center"
                             sortable></el-table-column>
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             width="130"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.innerDeadline || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="80px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="添加"
                            placement="top-start">
                  <el-button size="mini"
                             icon="el-icon-plus"
                             type="primary"
                             circle
                             @click="getVerify(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!-- 批量添加对话框 -->
    <el-dialog title="批量添加"
               :visible.sync="openBatchAdd"
               :close-on-click-modal="false"
               width="36%"
               @close="handleClose">
      <el-form ref="batchAddFrom"
               :model="batchAddFrom"
               :rules="batchAddFromRules">
        <el-form-item label="添加对账ID"
                      prop="reconciliationId">
          <el-input v-model="batchAddFrom.reconciliationId"
                    placeholder="请输入对账ID"></el-input>
        </el-form-item>
        <el-form-item label="添加SV Code"
                      prop="svCode">
          <el-input v-model="batchAddFrom.svCode"
                    placeholder="请输入SV Code"></el-input>
        </el-form-item>
        <el-form-item label="小记"
                      prop="content">
          <el-input v-model="batchAddFrom.content"
                    placeholder="请输入小记"
                    type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="addSave('batchAddFrom')">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 打开对账ID -->
    <verify ref="verify"
            @refreshData="refreshData"
            @fetchData="fetchData"></verify>
  </div>
</template>

<script>
import { getList, doEdit } from "@/api/workplace/pl/plVerifyTodo"
import verify from "./addVerify"
import { mapGetters } from "vuex"
// 转化当前时间格式
import {
	returnBitTimestamp,
	eighthBitTimestamp,
	returnDeadtimestamp,
	getRoleKey,
} from "@/utils/index"
// 获取工具API
import {
	getBlock,
	getProjectSource,
	getWorkOrderStatus,
	getReportTypeList,
} from "@/api/workplace/tools"
export default {
	name: "ComprehensiveTable",
	components: {
		verify,
	},
	filters: {
		statusFilter(status) {
			const statusMap = {
				published: "success",
				draft: "gray",
				deleted: "danger",
			}
			return statusMap[status]
		},
	},
	data() {
		return {
			isShow: false,
			list: [],
			imageList: [],
			listLoading: true,
			layout: "total, sizes, prev, pager, next, jumper",
			total: 0,
			background: true,
			selectRows: "",
			elementLoadingText: "正在加载...",
			// 处理级联及日期范围控件表单重置
			reset: {
				projectNo: [],
				block: [],
				orderScopeDate: [],
				innerDeadline: [],
			},
			// 列表页
			queryForm: {
				console: "PL",
				address: "",
				innerDeadlineEnd: "",
				innerDeadlineStart: "",
				orderTimeEnd: "",
				orderTimeStart: "",
				pageNum: 1,
				pageSize: 10,
				workOrderNo: "",
				nextUserNo: "",
				projectNo: "",
				nextUserRoleNo: "",
				projectSource: "",
				targetSubject: "",
				projectNameNo: "",
				userNo: "",
				userRoleNo: "",
				workOrderStatus: "",
				// 查询待办列表   operType: "0",
				operType: "0",
			},
			queryPortForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
			},
			//  工单状态
			orderStatusOptions: [],
			// 查询工单内审已处理的工单参数
			param: {
				body: {},
				header: {
					currentTime: "2020-09-09 12:12:12",
					requestId: "1",
					sourceType: "site",
				},
			},
			// 添加对话框Form
			openBatchAdd: false,
			batchAddFrom: {
				workOrderNos: [],
				console: "PL",
				content: "",
				nextUserNo: "139",
				nextUserRoleNo: "",
				projectSource: "process_hk",
				reconciliationId: "",
				remark: "",
				operAction: "添加对账ID",
				step: "PL工作台-对账ID管理-待处理",
				svCode: "",
				userNo: "",
				userRoleNo: "",
			},
			batchAddPortFrom: {
				body: {},
				header: {
					currentTime: "",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
			},
			batchAddFromRules: {
				reconciliationId: [
					{ required: true, message: "请添加对账ID", trigger: "blur" },
					{
						max: 50,
						message: "对账ID不能超过50个数字",
						trigger: "blur",
					},
				],
				svCode: [
					{ required: true, message: "请添加SV Code", trigger: "blur" },
					{
						max: 50,
						message: "SV Code不能超过50个数字",
						trigger: "blur",
					},
				],
				content: [
					{ required: true, message: "请输入小记", trigger: "blur" },
					{
						max: 500,
						message: "小记不能超过500个汉字",
						trigger: "blur",
					},
				],
			},
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit("pick", [start, end])
						},
					},
				],
			},
			projectInfo: [], // 项目
			projectForm: {
				body: {
					source: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			cityInfo: [],
			cityForm: {
				body: {
					cityCode: "",
					countryCode: "",
					regionCode: "",
					stateCode: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			downloadLoading: false,
			reportType: [], // 报告类型
			// 项目来源
			resourceOptions: [
				{
					name: "香港业务流程",
					key: "process_hk",
				},
				{
					name: "本地业务流程",
					key: "process_nd",
				},
				{
					name: "BR业务流程",
					key: "process_br",
				},
			],
		}
	},
	computed: {
		...mapGetters({
			userNo: "user/userNo",
			roles: "user/roles",
		}),
	},
	created() {
		this.queryForm.userNo = this.userNo
		this.queryForm.userRoleNo = getRoleKey("PL", this.roles)
		this.batchAddFrom.userNo = this.userNo
		this.batchAddFrom.userRoleNo = getRoleKey("PL", this.roles)
	},
	beforeDestroy() {},
	methods: {
		/** 数据初始化 */
		handleInitData() {
			// 列表查询
			this.fetchData()
			// 获取项目
			this.getProject()
			// 获取地区
			this.getAddress()
			// 获取报告类型
			this.getReportType()
		},
		/** 导出Excel */
		handleDownload() {
			if (this.selectRows.length > 0) {
				this.downloadLoading = true
				import("@/vendor/ExportExcel").then((excel) => {
					const tHeader = [
						"工单编号",
						"订单编号",
						"目标主体",
						"目标地址",
						"项目",
						"报告类型",
						"二级类型",
						"下单日期",
						"内部截止日期",
					]
					const filterVal = [
						"workOrderNo",
						"brWorkNo",
						"targetSubject",
						"address",
						"projectName",
						"reportType",
						"subReportType",
						"orderTime",
						"innerDeadline",
					]
					const list = this.selectRows
					const data = this.formatJson(filterVal, list)
					excel.export_json_to_excel({
						header: tHeader,
						data,
						filename: "工单列表",
						autoWidth: true,
						bookType: "xlsx",
					})
					this.downloadLoading = false
				})
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},
		// 获取工单状态
		getOrderStatus() {
			this.param.header.currentTime = returnBitTimestamp(new Date())
			getWorkOrderStatus(this.param).then((response) => {
				if ("1" === response.header.retCode) {
					this.orderStatusOptions = response.body
				} else {
					this.$message.error(
						`${response.header.retMessage},获取工单状态失败，请重新刷新页面`
					)
				}
			})
		},
		/** 组装导出数据 */
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					if (!v[j]) {
						return "——"
					}
					return v[j]
				})
			)
		},
		// 8位下单日期格式处理
		dateFormat(row, column) {
			var date = row[column.property]
			if (date === undefined) {
				return "-"
			}
			return eighthBitTimestamp(Date.parse(date))
		},
		tableSortChange() {
			const imageList = []
			this.$refs.tableSort.tableData.forEach((item) => {
				imageList.push(item.img)
			})
			this.imageList = imageList
		},
		setSelectRows(val) {
			this.selectRows = val
		},
		// 打开对账ID
		getVerify(row) {
			const todo = "0"
			this.$refs["verify"].openVerify(row.workOrderNo, todo)
		},
		// 刷新数据
		refreshData() {
			let refresh_data = {
				todo_total: this.total,
				isDone: true,
			}
			this.$emit("child-todo", refresh_data)
			this.fetchData()
		},
		// 重置
		resetForm(formName) {
			if (this.$refs[formName] !== undefined) {
				this.queryForm = this.$options.data().queryForm
				this.reset = this.$options.data().reset
			}
		},
		// 批量添加
		batchAdd() {
			if (this.selectRows.length > 0) {
				this.openBatchAdd = true
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},
		// 批量添加 提交保存
		addSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.batchAddFrom.workOrderNos = this.selectRows.map((item) => {
						return item.workOrderNo
					})
					this.batchAddPortFrom.header.currentTime = returnBitTimestamp(
						new Date()
					)
					this.batchAddPortFrom.body = this.batchAddFrom
					doEdit(this.batchAddPortFrom).then((response) => {
						if (response.header.retCode === "1") {
							this.$message({
								message: `${response.header.retMessage}`,
								type: "success",
							})
							this.openBatchAdd = false
							this.$refs["batchAddFrom"].resetFields()
							let refresh_data = {
								todo_total: this.total,
								isDone: true,
							}
							this.$emit("child-todo", refresh_data)
							this.fetchData()
						} else {
							this.$message.error(
								`错误信息: ${response.header.retMessage},添加失败，请重新提交！`
							)
						}
					})
				} else {
					this.$message.error("添加失败，请输入正确的信息")
					return false
				}
			})
		},
		handleClose() {
			this.$refs["batchAddFrom"].resetFields()
			this.openBatchAdd = false
		},
		handleSizeChange(val) {
			this.queryForm.pageSize = val
			this.fetchData()
		},
		handleCurrentChange(val) {
			this.queryForm.pageNum = val
			this.fetchData()
		},
		heightQuery() {
			this.isShow = !this.isShow
			if (this.isShow) {
				this.getOrderStatus()
			}
		},
		handleQuery() {
			this.queryForm.pageNum = 1
			this.fetchData()
		},
		// 获取地区
		getAddress() {
			this.cityForm.header.currentTime = returnBitTimestamp(new Date())
			getBlock(this.cityForm).then((response) => {
				if (response.header.retCode === "1") {
					let block_info = []
					block_info.push(response.body[0])
					this.cityInfo = block_info
				} else {
					this.$message.error(
						`${response.header.retMessage},获取地区失败，请重新刷新页面`
					)
				}
			})
		},
		// 选择地区
		handleBlockChange(value) {
			if (value.length === 0) {
				this.queryForm.address = ""
			} else {
				this.queryForm.address = value.join(",")
			}
		},
		// 获取项目
		getProject() {
			this.projectForm.header.currentTime = returnBitTimestamp(new Date())
			getProjectSource(this.projectForm).then((response) => {
				if (response.header.retCode === "1") {
					this.projectInfo = response.body
				} else {
					this.$message.error(
						`${response.header.retMessage},获取项目失败，请重新刷新页面`
					)
				}
			})
		},
		// 选择项目
		handleProjectChange(value) {
			if (value.length === 0) {
				this.queryForm.projectNo = ""
			} else {
				this.queryForm.projectNo = value[1]
			}
		},
		// 获取报告分类
		getReportType() {
			getReportTypeList(this.queryPortForm).then((response) => {
				if (response.header.retCode === "1") {
					this.reportType = response.body
				}
			})
		},
		// 获取todo列表页数据
		fetchData() {
			this.listLoading = true
			// 当前系统时间
			this.queryPortForm.header.currentTime = returnBitTimestamp(new Date())
			// 下单日期范围
			if (this.reset.orderScopeDate == null) {
				this.queryForm.orderTimeStart = ""
				this.queryForm.orderTimeEnd = ""
			} else if (this.reset.orderScopeDate.length !== 0) {
				this.queryForm.orderTimeStart =
					this.reset.orderScopeDate.length === 2
						? returnBitTimestamp(this.reset.orderScopeDate[0])
						: ""
				this.queryForm.orderTimeEnd =
					this.reset.orderScopeDate.length === 2
						? returnDeadtimestamp(this.reset.orderScopeDate[1])
						: ""
			}
			// 内部截止日期范围
			if (this.reset.innerDeadline == null) {
				this.queryForm.innerDeadlineStart = ""
				this.queryForm.innerDeadlineEnd = ""
			} else if (this.reset.innerDeadline.length !== 0) {
				this.queryForm.innerDeadlineStart =
					this.reset.innerDeadline.length === 2
						? returnBitTimestamp(this.reset.innerDeadline[0])
						: ""
				this.queryForm.innerDeadlineEnd =
					this.reset.innerDeadline.length === 2
						? returnDeadtimestamp(this.reset.innerDeadline[1])
						: ""
			}
			this.queryForm.userNo = this.userNo
			this.queryForm.userRoleNo = getRoleKey("PL", this.roles)
			Object.assign(this.queryPortForm.body, this.queryForm)
			// 获取对账ID工作台订单list
			getList(this.queryPortForm)
				.then((response) => {
					if (response.header.retCode === "1") {
						this.list = response.body.records
						this.total = response.body.total
						let refresh_data = {
							todo_total: this.total,
							isDone: false,
						}
						this.$emit("child-todo", refresh_data)
					}
					this.listLoading = false
				})
				.catch(() => {
					this.listLoading = false
				})
		},
	},
}
</script>
<style scoped>
.el-col {
	margin-bottom: 10px;
}
.el-col :last-child {
	margin-bottom: 10px;
	margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100%;
}

.el-select,
.el-cascader {
	width: 100%;
}

.el-dropdown-menu__item {
	padding: 0;
}
.el-table /deep/.warning-row {
	background: #f8b7bc;
}
.my-form-class {
	margin-bottom: -10px !important;
}
</style>
<style>
.myBack .el-badge__content {
	background-color: transparent;
	border-radius: 10px;
	color: #da1414;
	display: inline-block;
	font-size: 12px;
	height: 18px;
	line-height: 18px;
	padding: 0 6px;
	text-align: center;
	white-space: nowrap;
	border: 0 solid #fff;
}
.myBack sup {
	top: -1.5em;
	position: absolute;
	left: -14px;
}
</style>
