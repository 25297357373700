/**
 * @copyright CBI cbi@chinacbi.com
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { asyncRoutes, constantRoutes } from "@/router";
import { getRouterList, getRouter } from "@/api/router";
import { filterAllRoutes, filterAsyncRoutes } from "@/utils/handleRoutes";
import Layout from "@/layouts";

const state = { routes: [], partialRoutes: [] };
const getters = {
  routes: (state) => state.routes,
  partialRoutes: (state) => state.partialRoutes,
};
const mutations = {
  setRoutes(state, routes) {
    state.routes = constantRoutes.concat(routes);
  },
  /** kyle */
  setRoute: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  setAllRoutes(state, routes) {
    state.routes = constantRoutes.concat(routes);
  },
  setPartialRoutes(state, routes) {
    state.partialRoutes = constantRoutes.concat(routes);
  },
};
const actions = {
  /** 原系统方法*/
  async setRoutes({ commit }, permissions) {
    let accessedRoutes = [];
    if (permissions.includes("admin")) {
      accessedRoutes = asyncRoutes;
    } else {
      accessedRoutes = await filterAsyncRoutes(asyncRoutes, permissions);
    }
    commit("setRoutes", accessedRoutes);
    return accessedRoutes;
  },
  /** add by route */
  setRoute({ commit }, roleId) {
    return new Promise((resolve) => {
      // 向后端请求路由数据
      getRouter().then((res) => {
        let routeData = [];
        // 返回实地系统所需路由列表
        res.data.forEach((_item) => {
          if (_item.meta.title === "Site System") {
            /**配置实地图标 */
            _item.children.map((i) => {
              if (i.meta.title === "项目") {
                i.meta.icon = "layer-group";
              }
              if (i.meta.title === "工作台") {
                i.meta.icon = "users-cog";
              }
              if (i.meta.title === "数据") {
                i.meta.icon = "chart-pie";
              }
              if (i.meta.title === "日志") {
                i.meta.icon = "journal-whills";
              }
            });
            routeData = _item.children;
          }
        });
        const accessedRoutes = filterAsyncRouter(routeData);
        accessedRoutes.push({ path: "*", redirect: "/404", hidden: true });
        commit("setRoute", accessedRoutes);
        resolve(accessedRoutes);
        return accessedRoutes;
      });
    });
  },
  async setAllRoutes({ commit }) {
    let { data } = await getRouterList();
    data.push({ path: "*", redirect: "/404", hidden: true });
    let accessRoutes = filterAllRoutes(data);
    commit("setAllRoutes", accessRoutes);
    return accessRoutes;
  },
  setPartialRoutes({ commit }, accessRoutes) {
    commit("setPartialRoutes", accessRoutes);
    return accessRoutes;
  },
};
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter((route) => {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === "Layout") {
        route.component = Layout;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}

export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve);
};
export default { state, getters, mutations, actions };
