var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", {
        staticClass: "htmlContent",
        domProps: { innerHTML: _vm._s(_vm.content) },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.handleReject } },
            [_vm._v("驳回")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.agree } },
            [_vm._v("同意")]
          ),
        ],
        1
      ),
      _c("reject", {
        ref: "reject",
        on: { close: _vm.close, refreshData: _vm.refreshData },
      }),
      _c("review", {
        ref: "review",
        on: { close: _vm.close, refreshData: _vm.refreshData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }