<!--Kyle PL工作台 -> 工单管理 ->导入页面-->
<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="60%"
      :show-close="false"
      :close-on-press-escape="false"
      @close="handleClose"
    >
      <div class="title">批量导入</div>
      <el-steps :active="1" style="margin: 30px -45%" align-center>
        <el-step title="上传工单">
          <vab-remix-icon icon-class="number-1" />
        </el-step>
        <el-step title="上传附件">
          <vab-remix-icon icon-class="number-2" />
        </el-step>
      </el-steps>
      <el-card class="box-card">
        <el-row align="middle" type="flex" :gutter="20">
          <el-col :span="4"><i class="el-icon-upload"></i></el-col>
          <el-col :span="20">
            <h3>请上传填好的工单导入模板</h3>
            <div>
              <p
                v-if="checkImport"
                style="
                  font-family: '微软雅黑';
                  font-weight: 400;
                  text-decoration: none;
                  color: #999999;
                "
              >
                请先上传工单导入列表，格式为Excel的文件后缀名必须为xls、xlsx，文件大小不得大于10M
              </p>
              <el-upload
                ref="uploadTemplate"
                class="upload-demo"
                :action="uploadUrl"
                :headers="headers"
                :data="uploadData"
                :on-remove="handleShowlist"
                :on-change="handleShowlist"
                :on-success="handleSuccess"
                :on-error="handleError"
                :before-upload="beforeUpload"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <el-link
                  v-if="checkImport"
                  ref="uploadBtn"
                  type="primary"
                  :underline="false"
                  style="margin-top: 30px"
                >
                  上传文件
                </el-link>
              </el-upload>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <el-row align="middle" type="flex" :gutter="20">
          <el-col :span="2"></el-col>
          <el-col :span="22">
            <h3>
              本次可导入工单数量
              <span class="unlimitedImportNum">{{ unlimitedImportNum }}</span>
            </h3>
            <h3>
              本次不可导入工单数量
              <span class="limitedImportNum">{{ limitedImportNum }}</span>
            </h3>
          </el-col>
        </el-row>
      </el-card>
      <div class="title">不可导入工单列表</div>

      <el-table :data="importStatisticsData" stripe border>
        <el-table-column
          prop="lineNum"
          label="行数"
          align="left"
          width="100"
        ></el-table-column>
        <el-table-column label="错误提示" align="left">
          <template slot-scope="scope">
            <span class="errorTips">{{ scope.row.detail }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-form>
          <el-form-item>
            <el-button plain @click="handleClose">取消</el-button>
            <el-button v-if="reUploadShow" type="primary" @click="reUpload">
              重新上传
            </el-button>
            <el-button
              ref="nextStep"
              type="primary"
              disabled
              @click="goImportAnnex"
            >
              下一步
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 导入附件 -->
    <importAnnex ref="importAnnex" @annex-todo="closeAll"></importAnnex>
  </div>
</template>

<script>
  import { batchCreate } from "@/api/workplace/importTemplate";
  import { returnBitTimestamp, getRoleKey } from "@/utils/index";
  import importAnnex from "./importAnnex";
  import { mapGetters } from "vuex";
  import store from "@/store";
  export default {
    name: "ImportTemplate",
    components: { importAnnex },
    data() {
      return {
        dialogTitle: "",
        dialogFormVisible: false,
        unlimitedImportNum: "",
        limitedImportNum: "",
        step: "",
        operAction: "",
        workOrderNos: "",
        isSuccess: 0,
        console: "",
        checkImport: true,
        // 重新上传
        reUploadShow: false,
        // uploadUrl: "http://192.168.4.28/site/plWorkOrder/batchCreate",
        uploadUrl: "/site/plWorkOrder/batchCreate",
        importStatisticsData: [],
        uploadData: {
          userNo: "",
          userRoleNo: "",
        },
        headers: {
          Authorization: "Bearer " + store.getters["user/accessToken"],
        },
        fileList: [],
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.uploadData.userNo = this.userNo;
      this.uploadData.userRoleNo = getRoleKey("PL", this.roles);
    },
    methods: {
      handleClose() {
        this.fileList = [];
        this.unlimitedImportNum = "";
        this.limitedImportNum = "";
        this.importStatisticsData = [];
        this.isSuccess = 0;
        this.dialogFormVisible = false;
        this.reUploadShow = false;
      },
      // 关闭所有
      closeAll() {
        this.$emit("fetchData");
      },
      // 打开导入
      showImport() {
        this.checkImport = this.fileList.length === 0;
        this.dialogTitle = "批量导入";
        this.dialogFormVisible = true;
        this.reUploadShow = false;
      },
      // 打开附件
      goImportAnnex(row) {
        this.$refs["importAnnex"].showAnnex(this.workOrderNos);
        this.fileList = [];
        this.unlimitedImportNum = "";
        this.limitedImportNum = "";
        this.importStatisticsData = [];
        this.isSuccess = 0;
        this.dialogFormVisible = false;
        this.reUploadShow = false;
      },
      /** 上传成功的回调 */
      handleSuccess(res, file) {
        if (res.header.retCode === "1") {
          this.unlimitedImportNum = res.body.successNum + "条";
          this.limitedImportNum = res.body.failNum + "条";
          this.importStatisticsData = res.body.failMessages;
          // 成功上传时，重新上传隐藏
          if (res.body.failNum === 0) {
            this.isSuccess = 1;
            this.checkImport = false;
            this.reUploadShow = false;
            this.workOrderNos = res.body.workOrderNos;
            this.$emit("fetchData");
            this.$nextTick(() => {
              this.$refs["nextStep"].disabled = false;
            });
          } else {
            this.reUploadShow = true;
          }
        } else {
          this.fileList = [];
          this.checkImport = true;
          this.reUploadShow = true;
          this.$message.error(`上传错误,错误信息: ${res.header.retMessage}`);
        }
      },
      handleError(err, file, fileList) {
        this.$message.error(`上传错误,错误信息 ${err}`);
      },
      // 重新上传
      reUpload() {
        this.unlimitedImportNum = "";
        this.limitedImportNum = "";
        this.importStatisticsData = [];
        this.checkImport = true;
        this.$refs["uploadTemplate"].clearFiles();
        this.$refs["uploadTemplate"].$el.children[0].children[0].click();
      },
      // response 暂未使用
      submitUpload(param) {
        var fileData = new FormData(); // FormData 对象
        this.fileList.forEach((file) => {
          fileData.append("file", param.file); // 文件对象
          fileData.append("userNo", this.userNo);
        });
        const msgElement = this.$createElement;
        batchCreate(fileData)
          .then((response) => {
            if (response.header.retcode === "1") {
              this.inReview = response.body.authenticationResult;
            } else {
              this.$alert("提示", {
                title: "提示",
                message: msgElement("div", null, "上传失败"),
                confirmButtonText: "确定",
              });
            }
          })
          .catch((error) => {});
      },
      // 导入
      beforeUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extension = testmsg === "xls";
        const extension2 = testmsg === "xlsx";
        const expSize = file.size / 1024 / 1024 < 10;
        if (!extension && !extension2) {
          this.$message({
            message: "上传文件只能是 xls、xlsx格式!",
            type: "warning",
          });
          return false;
        }
        if (!expSize) {
          this.$message({
            message: "上传文件大小不能超过 10MB!",
            type: "warning",
          });
          return false;
        }
        return extension || (extension2 && expSize);
      },
      // 根据文件状态判断样式
      handleShowlist(files, fileList) {
        this.fileList = fileList;
        this.checkImport = fileList.length === 0;
      },
      // 超出限制时的钩子
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
            files.length + fileList.length
          } 个文件`
        );
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
      async uploadFile(item) {
        let fileObj = item.file;
        const form = new FormData(); // FormData 对象
        form.append("file", fileObj); // 文件对象  'upload'是后台接收的参数名
        const { header } = await batchCreate(form);
        if (header.retCode === "1") {
          this.$baseMessage(header.retMessage, "success");
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  h3 {
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-weight: 700;
    text-decoration: none;
    line-height: 30px;
  }
  .title {
    margin: 10px 0;
  }
  .unlimitedImportNum {
    font-family: "微软雅黑";
    font-weight: 400;
    text-decoration: none;
    color: #0079fe;
    margin-left: 40px;
  }
  .limitedImportNum {
    font-family: "微软雅黑";
    font-weight: 400;
    text-decoration: none;
    color: #0079fe;
    margin-left: 40px;
  }
  .errorTips {
    color: #f56c6c;
  }
  .el-icon-upload {
    display: flex;
    justify-content: center;
    font-size: 100px;
    color: #ccc;
  }
</style>
