var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "evidence",
      attrs: {
        "element-loading-text": "拼命加载中",
        "element-loading-spinner": "el-icon-loading",
        fullscreen: true,
      },
    },
    [
      _vm.saveTips
        ? _c("div", { staticClass: "size-tips" }, [
            _vm._v(" 取证资料已于" + _vm._s(_vm.saveDate) + "自动保存 "),
          ])
        : _vm._e(),
      _vm._l(_vm.formTemplate, function (item, index) {
        return _c(
          "el-collapse",
          {
            key: index,
            on: {
              change: function ($event) {
                return _vm.fetchAllData(index)
              },
            },
            model: {
              value: _vm.activeNames,
              callback: function ($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames",
            },
          },
          [
            _c(
              "el-collapse-item",
              { attrs: { title: item.label, name: index } },
              [
                _c("VueDragFormBuild", {
                  ref: "formBuild",
                  refInFor: true,
                  attrs: { "form-template": item, models: _vm.models },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.tempSaveVisiable
        ? _c(
            "div",
            { staticClass: "evidence-operation" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.saveLoading },
                  on: { click: _vm.handleTempSave },
                },
                [_vm._v(" 保存数据 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }