<template>
  <div>
    <el-dialog :title="title"
               class="myEditDialog"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="60%"
               :fullscreen="flag"
               @close="close(0)">
      <el-backtop target=".myEditDialog"></el-backtop>
      <i style="position: absolute; top: 24px; right: 41px; cursor: pointer"
         :class="fullIcon"
         :title="fullTitle"
         @click="fullOrClose"></i>
      <el-tabs v-model="activeName"
               type="border-card"
               @tab-click="handleClick">
        <el-tab-pane label="编辑工单"
                     class="edit_title"
                     name="edit">
          <div class="createForm-box"
               style="padding: 0 10px">
            <el-form ref="createForm"
                     :model="createForm"
                     :rules="createFormRules"
                     label-width="80px"
                     label-position="top">
              <!-- 基础信息 -->
              <h3>基础信息</h3>
              <el-row :gutter="60">
                <!-- 订单编号 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="订单编号"
                                prop="projectNameNo">
                    <el-input v-model="createForm.brWorkNo"
                              placeholder="请输入订单编号"
                              disabled
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 报告类型 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="报告类型"
                                prop="reportType">
                    <el-input v-model="createForm.reportType"
                              :class="brChange('reportTypeFlag')"
                              placeholder="请选择报告类型"
                              disabled
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 二级类型 -->
                <el-col v-if="createForm.subReportType"
                        :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="二级类型"
                                prop="subReportType">
                    <el-input v-model="createForm.subReportType"
                              :class="brChange('subReportTypeFlag')"
                              placeholder="请选择二级类型"
                              disabled
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- Project Name -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="Project Name"
                                prop="projectNameNo">
                    <el-input v-model="createForm.projectNameNo"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 项目 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="项目"
                                prop="project">
                    <el-cascader v-model="createForm.project"
                                 :options="projectInfo"
                                 clearable
                                 disabled
                                 :filterable="true"
                                 separator=":"
                                 placeholder="请选择项目"
                                 @change="handleProjectChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 项目来源 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">

                  <el-form-item label="项目来源">
                    <el-select v-model="createForm.projectSource"
                               placeholder="请选择项目来源"
                               disabled
                               filterable>
                      <el-option v-for="item in resourceOptions"
                                 :key="item.key"
                                 :label="item.name"
                                 :value="item.key"></el-option>
                    </el-select>
                    <!--el-input v-model="createForm.projectSource"
                              placeholder="请输入"
                              disabled></el-input-->
                  </el-form-item>
                </el-col>
                <!-- 目标主体 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标主体"
                                prop="targetSubject">
                    <el-input v-model="createForm.targetSubject"
                              :class="brChange('subjectFlag')"
                              placeholder="请输入"
                              clearable
                              :disabled="createForm.projectSource === 'process_br'"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 国家/省份/城市/地域（县级市） -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="国家/省份/城市/地域（县级市）"
                                prop="block">
                    <el-cascader v-model="createForm.block"
                                 :class="brChange('addressFlag')"
                                 :options="cityInfo"
                                 clearable
                                 separator="/"
                                 :props="{ checkStrictly: true }"
                                 placeholder="请选择地区"
                                 :filterable="true"
                                 @change="handleBlockChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 目标地址1 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标地址1"
                                prop="targetAddr1">
                    <el-input v-model="createForm.targetAddr1"
                              :class="brChange('subjectAddressFlag')"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 目标地址2 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标地址2"
                                prop="targetAddr2">
                    <el-input v-model="createForm.targetAddr2"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 目标联系人 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标联系人"
                                prop="targetContact">
                    <el-input v-model="createForm.targetContact"
                              :class="brChange('subjectLinkNameFlag')"
                              placeholder="请输入"
                              clearable
                              :disabled="createForm.projectSource === 'process_br'"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 联系电话 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="联系电话"
                                prop="contactNumber">
                    <el-input v-model="createForm.contactNumber"
                              :class="brChange('subjectMobileFlag')"
                              placeholder="请输入"
                              clearable
                              :disabled="createForm.projectSource === 'process_br'"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 职务 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="职务"
                                prop="duty">
                    <el-input v-model="createForm.duty"
                              :class="brChange('dutyFlag')"
                              placeholder="请输入"
                              clearable
                              :disabled="createForm.projectSource === 'process_br'"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 下单时间 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="下单时间"
                                prop="orderTimeTmp">
                    <el-date-picker v-model="createForm.orderTimeTmp"
                                    type="datetime"
                                    clearable
                                    placeholder="选择下单时间"
                                    format="yyyy-MM-dd HH:mm"
                                    style="width: 100%"
                                    :disabled="createForm.projectSource === 'process_br'"
                                    @focus="pickFocus"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 内部截止日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="内部截止日期"
                                prop="innerDeadlineTmp">
                    <el-date-picker v-model="createForm.innerDeadlineTmp"
                                    type="date"
                                    clearable
                                    placeholder="请选择日期"
                                    style="width: 100%"
                                    required
                                    default-time="23:59:59"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 对账ID -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="对账ID"
                                prop="reconciliationId">
                    <el-input v-model="createForm.reconciliationId"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- SV Code -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="SV Code"
                                prop="svCode">
                    <el-input v-model="createForm.svCode"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 委托方全称 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="委托方全称"
                                prop="clientFullName">
                    <el-input v-model="createForm.clientFullName"
                              :class="brChange('assigneeFlag')"
                              placeholder="请输入"
                              clearable
                              :disabled="createForm.projectSource === 'process_br'"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 委托方简称 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="委托方简称"
                                prop="clientAbbr">
                    <el-input v-model="createForm.clientAbbr"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 报告期限 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="报告期限"
                                prop="reportTimeLimit">
                    <el-select v-model="createForm.reportTimeLimit"
                               :class="brChange('reportTimeLimitFlag')"
                               placeholder="请选择"
                               clearable
                               filterable
                               :disabled="createForm.projectSource === 'process_br'">
                      <el-option v-for="item in reportTime"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 备注1 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="备注1"
                                prop="remark1">
                    <el-input v-model="createForm.remark1"
                              type="textarea"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 备注2 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="备注2"
                                prop="remark2">
                    <el-input v-model="createForm.remark2"
                              type="textarea"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 备注3 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="备注3"
                                prop="remark3">
                    <el-input v-model="createForm.remark3"
                              type="textarea"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 附加信息 -->
              <h3>附加信息</h3>
              <el-row :gutter="60">
                <!-- 预计走访日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="预计走访日期"
                                prop="expectVisitDateTmp">
                    <el-date-picker v-model="createForm.expectVisitDateTmp"
                                    type="date"
                                    placeholder="请选择日期"
                                    style="width: 100%"
                                    clearable
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 预计所需调查天数 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="预计所需调查天数"
                                prop="expectServeyDay">
                    <el-input v-model="createForm.expectServeyDay"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 所需人手 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="所需人手"
                                prop="needUser">
                    <el-input v-model.number="createForm.needUser"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 预计需时（小时） -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="预计需时（小时）"
                                prop="expectHour">
                    <el-input v-model="createForm.expectHour"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 注册地址 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="注册地址"
                                prop="registerAddress">
                    <el-input v-model="createForm.registerAddress"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 目标主体注册状态 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标主体注册状态"
                                prop="targetSubjectRegStatus">
                    <el-input v-model="createForm.targetSubjectRegStatus"
                              placeholder="请输入"
                              type="text"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 走访收费 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="走访收费"
                                prop="visitFee">
                    <el-input id="visitFee"
                              v-model="createForm.visitFee"
                              placeholder="请输入"
                              type="password"
                              autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 加急走访收费 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="加急走访收费"
                                prop="urgentVisitFee">
                    <el-input id="urgentVisitFee"
                              v-model="createForm.urgentVisitFee"
                              placeholder="请输入"
                              type="password"
                              autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 再次走访收费 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="再次走访收费"
                                prop="againVisitFee">
                    <el-input id="againVisitFee"
                              v-model="createForm.againVisitFee"
                              placeholder="请输入"
                              type="password"
                              auto-complete="new-password"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 收费依据 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="收费依据"
                                prop="feeBasis">
                    <el-input v-model="createForm.feeBasis"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 目标机构1 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标机构1"
                                prop="targetOrganization1">
                    <el-input v-model="createForm.targetOrganization1"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 目标机构2 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标机构2"
                                prop="targetOrganization2">
                    <el-input v-model="createForm.targetOrganization2"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!--  目标地址预核结果 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="目标地址预核结果"
                                prop="targetResult">
                    <el-input v-model="createForm.targetResult"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 不可行原因/待确认项目 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="不可行原因/待确认项目"
                                prop="infeasibleReason">
                    <el-input v-model="createForm.infeasibleReason"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 邮件抬头 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="邮件抬头"
                                prop="mailHeader">
                    <el-input v-model="createForm.mailHeader"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 特别留言 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="特别留言"
                                prop="plSpecialMsg">
                    <el-input v-model="createForm.plSpecialMsg"
                              type="textarea"
                              placeholder="请输入特别留言"
                              :rows="7"
                              show-word-limit
                              clearable></el-input>
                  </el-form-item>
                </el-col>
                <!-- 上传附件 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="16"
                        :xl="16">
                  <el-form-item label="上传附件">
                    <el-upload ref="upload"
                               class="upload-demo"
                               list-type="picture-card"
                               action="#"
                               :on-error="handleError"
                               :before-upload="beforeUpload"
                               :http-request="fnUploadRequest"
                               :file-list="fileList">
                      <i slot="default"
                         class="el-icon-plus"></i>
                      <div slot="file"
                           slot-scope="{ file }">
                        <img class="el-upload-list__item-thumbnail"
                             src="@/icon/annex.svg"
                             :alt="file.name" />
                        <p style="
                            position: absolute;
                            top: 103px;
                            left: 10px;
                            color: rgb(255, 255, 255);
                            font-size: 13px;
                            font-weight: 100;
                          ">
                          {{
                            file.name.length > 8
                              ? file.name.substring(0, 8) + "..."
                              : file.name
                          }}
                        </p>
                        <span class="el-upload-list__item-actions">
                          <span v-if="form_flag === 'edit'"
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)">
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span v-if="form_flag === 'edit'"
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)">
                            <i class="el-icon-download"></i>
                          </span>
                          <span v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file)">
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </el-form-item>
                  <el-dialog title="预览"
                             :visible.sync="uploadDialog"
                             append-to-body
                             width="40%">
                    <div class="htmlContent">
                      <viewer>
                        <img :src="dialogImageUrl"
                             style="max-height: 300px"
                             alt="图片预览" />
                      </viewer>
                    </div>
                  </el-dialog>
                </el-col>
              </el-row>
              <!-- 派单信息 -->
              <h3>派单信息</h3>
              <el-row :gutter="60">
                <!-- 预约人员 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="预约人员"
                                prop="bookUser">
                    <el-select v-model="createForm.bookUser"
                               class="bookUser_input"
                               suffix-icon="el-icon-user"
                               placeholder="请选择"
                               clearable
                               filterable>
                      <el-option v-for="item in bookUserData"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 小记 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="8"
                        :xl="8">
                  <el-form-item label="小记"
                                prop="note">
                    <el-input v-model="createForm.note"
                              type="textarea"
                              :rows="1"
                              placeholder="请输入"
                              clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="取证资料"
                     name="evidence">
          <evidence ref="evidence"
                    :row="object"></evidence>
        </el-tab-pane>
        <el-tab-pane label="拍摄照片"
                     name="photo">
          <photo ref="photo"
                 :type="'50'"
                 :row="object"></photo>
        </el-tab-pane>
        <el-tab-pane label="拍摄录像"
                     name="screenVideo">
          <screenVideo ref="screenVideo"
                       :type="'51'"
                       :row="object"></screenVideo>
        </el-tab-pane>
        <el-tab-pane label="扫描文件"
                     name="scanFile">
          <scanFile ref="scanFile"
                    :type="'52'"
                    :row="object"></scanFile>
        </el-tab-pane>
        <el-tab-pane label="录制音频"
                     name="screenAudio">
          <screenAudio ref="screenAudio"
                       :type="'53'"
                       :row="object"></screenAudio>
        </el-tab-pane>
        <el-tab-pane label="其他文件"
                     name="telRecord">
          <telRecord ref="telRecord"
                     :type="'54'"
                     :row="object"></telRecord>
        </el-tab-pane>
        <el-tab-pane label="打卡记录"
                     name="clockIn">
          <clockIn ref="clockIn"
                   :type="'55'"
                   :row="object"></clockIn>
        </el-tab-pane>
        <el-tab-pane label="会议信息"
                     name="meeting">
          <meeting ref="meeting"
                   :type="'57'"
                   :row="object"></meeting>
        </el-tab-pane>
        <el-tab-pane label="查看小记"
                     name="remark">
          <remark ref="remark"
                  :param="param"></remark>
        </el-tab-pane>
        <el-tab-pane label="退回记录"
                     name="back">
          <back ref="back"
                :order="orderNo"></back>
        </el-tab-pane>
        <el-tab-pane label="标注记录"
                     name="labelRecord">
          <labelRecord ref="labelRecord"
                       :type="'56'"
                       :row="object"></labelRecord>
        </el-tab-pane>
        <el-tab-pane label="企业信息"
                     name="company">
          <company ref="company"
                   :row="object"></company>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer"
           class="dialog-footer">
        <el-button plain
                   @click="close">取 消</el-button>
        <el-button v-if="activeName === 'edit'"
                   type="success"
                   :loading="saveLoading"
                   @click="saveOrder('createForm', saveFlag)">
          保 存
        </el-button>
        <el-button type="primary"
                   :loading="submitLoading"
                   @click="submitOrder('createForm', submitFlag)">
          提 交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const OSS = require("ali-oss");
const attachmentPath = "cbi-site-front/workplace/attachment/";
import axios from "axios";
import back from "@/views/workplace/components/back";
import company from "@/views/workplace/components/company";
import remark from "@/views/workplace/components/remark";
import meeting from "@/views/workplace/components/meeting";
// 取证资料
import evidence from "@/views/workplace/components/evidence";
// 拍摄照片
import photo from "@/views/workplace/components/photo";
// 拍摄录像
import screenVideo from "@/views/workplace/components/screenVideo";
// 扫描文件
import scanFile from "@/views/workplace/components/scanFile";
// 录制音频
import screenAudio from "@/views/workplace/components/screenAudio";
// 其他文件
import telRecord from "@/views/workplace/components/telRecord";
// 打卡记录
import clockIn from "@/views/workplace/components/clockIn";
import labelRecord from "@/views/workplace/components/labelRecord";

import {
  // 获取编辑回显
  getEditList,
  // 编辑保存
  getEditSave,
  // 提交保存[总]
  submitCreate,
} from "@/api/workplace/orderCreate";
import { mapGetters } from "vuex";
import { getRoleKey, uuid, eighthBitTime, apiParams } from "@/utils";
import {
  returnBitTimestamp,
  downloadFile,
  isExistOSS,
  returnDeadtimestamp,
} from "@/utils/index";
import { disabledDate, selectableRange } from "@/utils/date";
import { getRole, getReportTypeList } from "@/api/workplace/tools";

export default {
  name: "Edit",
  components: {
    telRecord,
    evidence,
    photo,
    screenVideo,
    scanFile,
    screenAudio,
    clockIn,
    meeting,
    remark,
    back,
    labelRecord,
    company,
  },
  data() {
    // Form-vlidator 判断数字且小数点后保留两位有效数字
    const isPriceVlidator = (rule, value, callback) => {
      // var pattern = /^\d+.?\d{0,2}$/;
      let pattern = /^\d+(\.\d{1,2})?$/;
      // 可以正常留空
      if (value === null || value === "") {
        return callback();
      } else if (!pattern.test(value)) {
        return callback(new Error("请输入数字,并且小数点后最多只能输入两位"));
      } else return callback();
    };
    return {
      activeName: "edit",
      flag: false,
      fullTitle: "最大化",
      fullIcon: "el-icon-full-screen",
      // 防抖
      loading: false,
      submitLoading: false,
      isShowOne: true,
      isShowTwo: false,
      title: "",
      dialogFormVisible: false,
      workOrderNo: "",
      tableData: [],

      contactTargetFlagOptions: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      pendingFlagOptions: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],

      // cfe add
      roleOptions: [
        { value: "supervisor", label: "督导人员" },
        { value: "full_investigator", label: "调查人员" },
      ],
      investigateOptions: [],

      partInvestigateOptions: [],
      roleForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 编辑接口所需参数
      parameter: {
        body: {
          bookSpecialMsg: null,
          bookVisitTime: null,
          console: "book_person",
          contactTargetFlag: null,
          monitorUser: null,
          nextTaskId: "",
          nextUserNo: "",
          nextUserRoleNo: null,
          note: null,
          operAction: "工单预约",
          pendingFlag: null,
          procInsId: null,
          projectSource: null,
          remark: "",
          step: "预约工作台-工单预约-待处理",
          surveyUser: null,
          taskId: null,
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      pickerOptions: { disabledDate, selectableRange: "" },
      // 提交工单调查参数
      param: {
        body: {
          pageNum: 1,
          pageSize: 10,
          console: "book_person",
          nextTaskId: "",
          nextUserNo: "",
          nextUserRoleNo: "",
          note: "",
          operAction: "工单预约",
          procInsId: "",
          projectSource: "",
          remark: "",
          step: "预约工作台-工单预约-待处理",
          taskId: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 批量提交的参数
      batchPara: {
        body: [],
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: uuid(),
          sourceType: "site",
        },
      },

      // 获取工单详情
      detailParam: {
        body: {
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 获取工单预约详情
      orderParam: {
        body: {
          workOrderNo: null,
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      rules: {
        note: [
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
        bookSpecialMsg: [
          {
            max: 2000,
            message: "特别留言不能超过2000个汉字",
            trigger: "blur",
          },
        ],
      },
      // 给子组件传值
      row: null,
      // 操作工单日志信息
      operationInfo: [],

      createForm: {
        /** 媒介字段 __start */
        block: "",
        project: "",
        orderTimeTmp: "",
        expectVisitDateTmp: "",
        innerDeadlineTmp: "",
        /** 媒介字段 __end */
        clientAbbr: "",
        reportTimeLimit: "",
        clientFullName: "",
        workOrderNo: "",
        address: "",
        againVisitFee: "",
        bookUser: "",
        console: "PL",
        contactNumber: "",
        note: "",
        duty: "",
        expectHour: "",
        expectServeyDay: "",
        expectVisitDate: "",
        feeBasis: "",
        infeasibleReason: "",
        innerDeadline: "",
        mailHeader: "",
        needUser: null,
        nextUserNo: "",
        nextUserRoleNo: "",
        orderTime: "",
        files: [],
        step: "",
        operAction: "",
        projectNo: "",
        projectNameNo: "",
        projectSource: "",
        projectType: "",
        reconciliationId: undefined,
        registerAddress: "",
        remark1: "",
        remark2: "",
        remark3: "",
        plSpecialMsg: "",
        svCode: "",
        targetAddr1: "",
        targetAddr2: "",
        targetContact: "",
        targetOrganization1: "",
        targetOrganization2: "",
        targetResult: null,
        targetSubject: "",
        targetSubjectRegStatus: "",
        urgentVisitFee: "",
        userNo: "",
        userRoleNo: "",
        visitFee: "",
        siteBrUpdateColumnRes: {},
      },
      createFormRules: {
        projectNameNo: [
          {
            max: 200,
            message: "Project Name不能超过200个英文字符",
            trigger: "blur",
          },
        ],
        targetSubject: [
          { required: true, message: "请输入目标主体", trigger: "blur" },
          {
            max: 100,
            message: "目标主体不能超过100个文字",
            trigger: "blur",
          },
        ],
        targetAddr1: [
          { required: true, message: "请输入目标地址1", trigger: "blur" },
          {
            max: 200,
            message: "目标地址1不能超过200个字数",
            trigger: "blur",
          },
        ],
        targetAddr2: [
          {
            max: 200,
            message: "目标地址2不能超过200个字数",
            trigger: "blur",
          },
        ],
        targetContact: [
          {
            max: 30,
            message: "目标联系人不能超过30个汉字",
            trigger: "blur",
          },
        ],
        contactNumber: [
          {
            max: 50,
            message: "联系电话过长，请重新填写",
            trigger: "blur",
          },
        ],
        duty: [
          {
            max: 30,
            message: "职务不能超过30个汉字",
            trigger: "blur",
          },
        ],
        remark1: [
          {
            max: 200,
            message: "备注不能超过200个汉字",
            trigger: "blur",
          },
        ],
        remark2: [
          {
            max: 200,
            message: "备注不能超过200个汉字",
            trigger: "blur",
          },
        ],
        remark3: [
          {
            max: 200,
            message: "备注不能超过200个汉字",
            trigger: "blur",
          },
        ],
        clientFullName: [
          {
            max: 100,
            message: "委托方全称不能超过100个汉字",
            trigger: "blur",
          },
        ],
        clientAbbr: [
          {
            max: 100,
            message: "委托方全称不能超过100个汉字",
            trigger: "blur",
          },
        ],
        registerAddress: [
          {
            max: 100,
            message: "注册地址不能超过100个汉字",
            trigger: "blur",
          },
        ],
        targetSubjectRegStatus: [
          {
            max: 30,
            message: "目标主体注册状态不能超过30个汉字",
            trigger: "blur",
          },
        ],
        reconciliationId: [
          {
            max: 50,
            message: "对账ID不能超过50位数字",
            trigger: "blur",
          },
        ],
        expectServeyDay: [
          {
            max: 5,
            message: "预计所需调查天数不能超过5位数字",
            trigger: "blur",
          },
        ],
        needUser: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              // 非空校验
              if (this.createForm.needUser > 100000) {
                return callback(new Error("所需人手不能超过5位数字"));
              } else {
                callback();
              }
            },
          },
        ],
        expectHour: [
          {
            max: 5,
            message: "预计需时（小时）不能超过5个数字",
            trigger: "blur",
          },
        ],
        svCode: [
          {
            max: 50,
            message: "SV Code不能超过50位数字",
            trigger: "blur",
          },
        ],
        feeBasis: [
          {
            max: 200,
            message: "收费依据不能超过200个汉字",
            trigger: "blur",
          },
        ],
        targetOrganization1: [
          {
            max: 50,
            message: "目标机构1不能超过50个汉字",
            trigger: "blur",
          },
        ],
        targetOrganization2: [
          {
            max: 50,
            message: "目标机构2不能超过50个汉字",
            trigger: "blur",
          },
        ],
        targetResult: [
          {
            max: 50,
            message: "目标地址预核结果不能超过50个汉字",
            trigger: "blur",
          },
        ],
        infeasibleReason: [
          {
            max: 100,
            message: "不可行原因/待确认项目不能超过100个汉字",
            trigger: "blur",
          },
        ],
        mailHeader: [
          {
            max: 50,
            message: "邮件抬头不能超过50个汉字",
            trigger: "blur",
          },
        ],
        plSpecialMsg: [
          {
            max: 2000,
            message: "特别留言不能超过2000个汉字",
            trigger: "blur",
          },
        ],
        project: [
          {
            required: true,
            message: "请选择项目",
            trigger: "change",
            validator: (rule, value, callback) => {
              // 非空校验
              if (this.createForm.project === "") {
                return callback(new Error("请选择项目"));
              } else {
                callback();
              }
            },
          },
        ],
        block: [
          {
            required: true,
            message: "请选择地区",
            trigger: "change",
            validator: (rule, value, callback) => {
              // 非空校验
              if (this.createForm.block === "") {
                return callback(new Error("请选择地区"));
              } else {
                callback();
              }
            },
          },
        ],
        orderTimeTmp: [
          { required: true, message: "请选择下单时间", trigger: "change" },
        ],
        innerDeadlineTmp: [
          {
            required: true,
            message: "请选择内部截止日期",
            trigger: "change",
          },
        ],
        note: [
          { required: true, message: "请输入小记", trigger: "blur" },
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
        bookUser: [
          {
            required: true,
            message: "请选择预约人员",
            trigger: "change",
          },
        ],
        visitFee: [
          { validator: isPriceVlidator, trigger: "blur" },
          {
            max: 10,
            message: "走访收费不能超过10位数字",
            trigger: "blur",
          },
        ],
        urgentVisitFee: [
          { validator: isPriceVlidator, trigger: "blur" },
          {
            max: 10,
            message: "加急走访收费不能超过10位数字",
            trigger: "blur",
          },
        ],
        againVisitFee: [
          { validator: isPriceVlidator, trigger: "blur" },
          {
            max: 10,
            message: "再次走访收费不能超过10位数字",
            trigger: "blur",
          },
        ],
      },
      // 省市区
      cityInfo: [],
      // 项目
      projectInfo: [],
      reportTime: [
        {
          value: "0",
          label: "普通",
        },
        {
          value: "1",
          label: "加急",
        },
        {
          value: "2",
          label: "特急",
        },
      ],
      fileList: [],
      form_flag: "create",
      disabled: false,
      uploadDialog: false,
      dialogImageUrl: "",
      bookUserData: [],
      saveLoading: false,
      // 查询编辑信息
      queryForm: {
        body: {
          nextUserNo: "",
          nextUserRoleNo: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      orderNo: null,
      createPortForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      // 给子组件传值
      object: {
        userNo: null,
        userRoleNo: null,
        workOrderNo: null,
        seqId: null,
      },
      submitFlag: 0,
      saveFlag: 0,
      // 项目来源
      resourceOptions: [
        {
          name: "香港业务流程",
          key: "process_hk",
        },
        {
          name: "本地业务流程",
          key: "process_nd",
        },
        {
          name: "BR业务流程",
          key: "process_br",
        },
      ],
      reportType: [], // 报告类型
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",

      accessKeyId: "user/accessKeyId",
      accessKeySecret: "user/accessKeySecret",
      bucketName: "user/bucketName",
      endpoint: "user/endpoint",
      endpoinxBak: "user/endpoinxBak",
      region: "user/region",
      policy: "user/policy",
      signature: "user/signature",
      OSSClient: "user/OSSClient",
    }),
  },
  created() {
    this.param.body.userNo = this.userNo;
    this.param.body.userRoleNo = getRoleKey("book_person", this.roles);
    this.parameter.body.userNo = this.userNo;
    this.parameter.body.userRoleNo = getRoleKey("book_person", this.roles);
    this.detailParam.body.userNo = this.userNo;
    this.detailParam.body.userRoleNo = getRoleKey("book_person", this.roles);
    this.detailParam.header.currentTime = returnBitTimestamp(new Date());
  },
  methods: {
    async showEdit(row, configInfo, flag) {
      /** 初始化loading */
      this.saveFlag = 0;
      this.submitFlag = 0;
      this.saveLoading = false;
      this.submitLoading = false;
      this.title = "编辑工单";
      this.dialogFormVisible = true;
      this.saveLoading = false;
      this.submitLoading = false;
      this.queryForm.body.userNo = this.userNo;
      this.queryForm.body.userRoleNo = getRoleKey("PL", this.roles);
      this.createForm.userNo = this.userNo;
      this.createForm.userRoleNo = getRoleKey("PL", this.roles);

      this.orderNo = row.workOrderNo;
      /** 给子组件的传参 */
      this.object = row;
      this.object.workplace = "pl";
      this.object.flag = "edit";
      this.object.userNo = this.userNo;
      this.object.userRoleNo = getRoleKey("PL", this.roles);
      this.object.accessKeyId = this.accessKeyId;
      this.object.accessKeySecret = this.accessKeySecret;
      this.object.bucketName = this.bucketName;
      this.object.endpoint = this.endpoint;
      this.object.endpoinxBak = this.endpoinxBak;
      this.object.OSSClient = this.OSSClient;
      this.object.region = this.region;
      this.object.policy = this.policy;
      this.object.signature = this.signature;

      this.form_flag = flag;
      this.createFormTitle = "编辑工单";
      this.createForm.step = configInfo.step;

      this.createForm.console = configInfo.console_info;
      /* 优化接口 */
      this.cityInfo = configInfo.blockInfo;
      this.projectInfo = configInfo.projectInfo;
      /** 获取工单信息*/
      this.queryForm.body.workOrderNo = configInfo.workOrderNo;
      this.param.body.workOrderNo = configInfo.workOrderNo;
      this.queryForm.header.currentTime = returnBitTimestamp(new Date());
      getEditList(this.queryForm).then((response) => {
        if (response.header.retCode === "1") {
          Object.assign(this.createForm, response.body);
          this.createForm.project = response.body.projectNo;
          this.createForm.address = response.body.addressCode;
          if (response.body.reportTimeLimitCode === null) {
            this.createForm.reportTimeLimit = "";
          } else {
            this.createForm.reportTimeLimit = response.body.reportTimeLimitCode;
          }
          /** 将回显的字段与地址组件绑定 */
          let result = response.body.addressCode + "";
          this.createForm.block = result
            .substr(0, result.length - 1)
            .split(",");
          if (response.body.files !== null) {
            this.fileList = response.body.files;
          }
          // 下单时间
          this.createForm.orderTimeTmp = Date.parse(response.body.orderTime);
          // 内部截止日期
          this.createForm.innerDeadlineTmp = Date.parse(
            response.body.innerDeadline
          );
          // 预计走访日期
          this.createForm.expectVisitDateTmp = Date.parse(
            response.body.expectVisitDate
          );
        }
      });
      // 获取预约人员
      this.getRoleOption();

      // 获取报告类型
      this.getReportType();
      this.activeName = "edit";
      setTimeout(() => {
        this.getCompanyDetail();
      }, 10);
    },

    getCompanyDetail() {
      this.$refs["photo"].fetchData();
      this.$refs["screenVideo"].fetchData();
      this.$refs["scanFile"].fetchData();
      this.$refs["screenAudio"].fetchData();
      this.$refs["telRecord"].fetchData();
      this.$refs["clockIn"].fetchData();
      this.$refs["meeting"].fetchData();
      this.$refs["remark"].fetchData();
      this.$refs["back"].fetchData();
      this.$refs["labelRecord"].fetchData();
      this.$refs["company"].init();
    },
    handleClick(tab, event) {
      if (tab.name === "evidence") {
        this.$refs["evidence"].reset();
        this.$refs["evidence"].fetchData();
      } else if (tab.name === "scanFile") {
        this.$refs["scanFile"].fetchData();
      }
    },
    close() {
      this.reset();
      this.saveLoading = false;
      this.submitLoading = false;
      this.flag = false;

      this.dialogFormVisible = false;

      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
    },
    // 重置表单
    reset() {
      this.createForm = this.$options.data().createForm;
      this.$refs["createForm"].resetFields();
      this.fileList = [];
      this.queryForm = this.$options.data().queryForm;
      this.createPortForm = this.$options.data().createPortForm;
      this.bookUserValue = [];
    },
    save() {
      this.$refs["orderForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.parameter.body.procInsId = this.orderForm.procInsId;
          this.parameter.body.bookSpecialMsg = this.orderForm.bookSpecialMsg;
          if (this.orderForm.bookVisitTimeTmp) {
            this.orderForm.bookVisitTime = returnBitTimestamp(
              this.orderForm.bookVisitTimeTmp
            );
          }
          this.parameter.body.bookVisitTime = this.orderForm.bookVisitTime;
          this.parameter.body.contactTargetFlag =
            this.orderForm.contactTargetFlag;
          this.parameter.body.pendingFlag = this.orderForm.pendingFlag;
          if (null !== this.orderForm.monitorUser) {
            this.parameter.body.nextUserNo = this.orderForm.monitorUser;
          }
          if (null !== this.orderForm.investigate) {
            this.parameter.body.nextUserNo = this.orderForm.investigate;
          }
          this.parameter.body.nextUserRoleNo = this.orderForm.nextUserRoleNo;
          this.parameter.body.note = this.orderForm.note;
          this.parameter.body.workOrderNo = this.orderForm.workOrderNo;
          // 操作记录
          this.parameter.body.operAction = "保存小记";

          const { header } = await edit(Object.assign({}, this.parameter));
          if (header.retCode === "1") {
            this.$baseMessage(header.retMessage, "success");
          } else {
            this.$baseMessage(header.retMessage, "error");
          }

          this.dialogFormVisible = false;
          this.$emit("refreshData");
          this.form = this.$options.data().form;
          this.isShowOne = false;
          this.isShowTwo = false;
          this.loading = false;
          return true;
        } else {
          return false;
        }
      });
    },

    changeRole(value) {
      "supervisor" === value
        ? (this.isShowOne = true)
        : (this.isShowOne = false);
      "full_investigator" === value
        ? (this.isShowTwo = true)
        : (this.isShowTwo = false);
    },
    // 获取预约人员
    getRoleOption() {
      this.roleForm.header.currentTime = returnBitTimestamp(new Date());
      // 预约人员[包括经理]
      this.roleForm.body = [
        {
          roleKey: "book_person",
        },
      ];
      getRole(this.roleForm).then((response) => {
        if (response.header.retCode === "1") {
          this.bookUserData = response.body;
        }
      });
    },

    fullOrClose() {
      this.flag = !this.flag;
      if (this.flag) {
        this.fullIcon = "el-icon-minus";
        this.fullTitle = "最小化";
      } else {
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      }
    },
    eighthBitTime(row, column) {
      return eighthBitTime(row, column);
    },
    // 选择项目
    handleProjectChange(value) {
      this.createForm.projectNo = value[1];
    },
    // 选择地区
    handleBlockChange(value) {
      this.createForm.address = value.join(",");
    },

    pickFocus() {
      this.pickerOptions.selectableRange = selectableRange();
    },
    // 上传失败的回调
    handleError(err) {
      this.$message.error(`上传错误,错误信息 ${err}`);
    },
    // 导入附件
    beforeUpload(file) {
      if (this.saveLoading || this.submitLoading) {
        this.$message({
          message: "请等待当前操作处理完毕",
          type: "warning",
        });
        return false;
      }
      if (this.createForm.files !== null) {
        const delArr = this.createForm.files.filter((item) => {
          return item.name === file.name;
        });
        if (delArr.length !== 0) {
          this.$message({
            message: "请勿上传同名文件",
            type: "warning",
          });
          return false;
        }
      }
      const expSize = file.size / 1024 / 1024 < 1024;
      if (!expSize) {
        this.$message({
          duration: 10000,
          showClose: true,
          message: `文件${file.name}大小不能超过 1G, 请检查`,
          type: "warning",
        });
        return false;
      }
      return expSize;
    },

    /**
     * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @author   shanshuizinong
     * @param    {object}   option [上传选项]
     * @return   {null}   [没有返回]
     */
    async fnUploadRequest(option) {
      try {
        this.submitLoading = true;
        this.saveLoading = true;
        /** 带有时间戳的文件名 */
        const fileName =
          new Date().getTime() +
          "." +
          option.file.name.match(/^(.*)(\.)(.{1,8})$/)[3];
        // 获取OSS配置信息
        const uploadConfig = new FormData();
        uploadConfig.append("key", attachmentPath + fileName);
        uploadConfig.append("OSSAccessKeyId", this.accessKeyId);
        uploadConfig.append("policy", this.policy);
        uploadConfig.append("Signature", this.signature);
        uploadConfig.append("success_action_status", 200);
        uploadConfig.append("name", option.file.name);
        /** 表单域 file 必须为最后一个 */
        uploadConfig.append("file", option.file);
        await axios
          .post(this.endpoinxBak, uploadConfig, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status === 200) {
              /** 对files处理，防止后端response为null */
              if (this.createForm.files === null) {
                this.createForm.files = [];
              }
              //上传成功 上传的路径就是要使用的路径
              this.createForm.files.push({
                url: attachmentPath + fileName,
                name: option.file.name,
              });
              this.submitLoading = false;
              this.saveLoading = false;
            }
          })
          .catch(() => {
            const index = this.indexOf(option.file.name, this.createForm.files);
            this.createForm.files.splice(index, 1);
            this.submitLoading = false;
            this.saveLoading = false;
          });
      } catch (error) {
        this.submitLoading = false;
        this.saveLoading = false;
        this.$message.error(`上传错误,错误信息 ${error}`);
      }
    },
    /** 预览文件 */
    handlePictureCardPreview(file) {
      try {
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extension = testmsg === "jpg";
        const extension2 = testmsg === "jpeg";
        const extension3 = testmsg === "png";
        const extension4 = testmsg === "JPG";
        const extension5 = testmsg === "JPEG";
        const extension6 = testmsg === "PNG";
        if (
          !extension &&
          !extension2 &&
          !extension3 &&
          !extension4 &&
          !extension5 &&
          !extension6
        ) {
          this.$message({
            message: "预览文件只能是 jpg、jpeg、png格式!",
            type: "warning",
          });
          return false;
        }
        /** 判断是否为当前上传文件，或是编辑回显的文件 */
        var index = this.indexOf(file.name, this.createForm.files);
        if (index > -1) {
          isExistOSS(this.OSSClient, this.createForm.files[index].url)
            .then(() => {
              this.dialogImageUrl =
                this.endpoinxBak + this.createForm.files[index].url;
              this.uploadDialog = true;
            })
            .catch(() => {
              this.$message.error("当前文件不存在！");
            });
        }
        return true;
      } catch (error) {
        this.$message.error(`正在上传中，请勿操作`);
      }
    },
    /** 下载文件 */
    handleDownload(file) {
      try {
        /** 判断是否为当前上传文件，或是编辑回显的文件 */
        var index = this.indexOf(file.name, this.createForm.files);
        if (index > -1) {
          isExistOSS(this.OSSClient, this.createForm.files[index].url)
            .then(() => {
              downloadFile({
                name: this.createForm.files[index].name,
                path: this.createForm.files[index].url,
              });
            })
            .catch(() => {
              this.$message.error("当前文件不存在！");
            });
        }
      } catch (error) {
        this.$message.error(`正在上传中，请勿操作`);
      }
    },
    async handleRemove(file) {
      try {
        var index = this.indexOf(file.name, this.createForm.files);
        /** 调用element upload api */
        this.$refs.upload.handleRemove(file);
        if (index > -1) {
          this.createForm.files.splice(index, 1);
        }
      } catch (error) {
        this.$message.error(`正在上传中，请勿操作`);
      }
    },
    /* 保存工单 */
    saveOrder(formName, saveFlag) {
      this.saveFlag = saveFlag + 1;
      if (0 === saveFlag) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveLoading = true;
            // 下单时间
            if (this.createForm.orderTimeTmp) {
              this.createForm.orderTime = returnBitTimestamp(
                this.createForm.orderTimeTmp
              );
            }
            // 内部截止日期
            if (this.createForm.innerDeadlineTmp) {
              this.createForm.innerDeadline = returnDeadtimestamp(
                this.createForm.innerDeadlineTmp
              );
            }
            // 预计走访日期
            if (this.createForm.expectVisitDateTmp) {
              this.createForm.expectVisitDate = returnBitTimestamp(
                this.createForm.expectVisitDateTmp
              );
            }
            this.createPortForm.header.currentTime = returnBitTimestamp(
              new Date()
            );
            // 回显地址格式转化为后台所需带有连接符的字符串
            if (this.createForm.address instanceof Array) {
              this.createForm.address = this.createForm.address.join(",");
            }
            // 操作记录
            this.createForm.operAction = "保存小记";
            Object.assign(this.createPortForm.body, this.createForm);

            getEditSave(this.createPortForm).then((response) => {
              if (response.header.retCode === "1") {
                this.$emit("refreshData");
                this.$message({
                  message: `${response.header.retMessage}`,
                  type: "success",
                });
                // 重置表单
                this.reset();
                this.dialogFormVisible = false;
              } else {
                this.$message.error(
                  `错误信息: ${response.header.retMessage},\t请重新保存编辑订单！`
                );
              }
              // this.saveLoading = false;
            });
          } else {
            this.$message.error("保存失败，请重新检查工单数据");
            return false;
          }
        });
      }
      this.flag = false;
      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
    },
    /* 提交工单 */
    submitOrder(formName, submitFlag) {
      if (0 === submitFlag) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            // 下单时间
            if (this.createForm.orderTimeTmp) {
              this.createForm.orderTime = returnBitTimestamp(
                this.createForm.orderTimeTmp
              );
            }
            // 内部截止日期
            if (this.createForm.innerDeadlineTmp) {
              this.createForm.innerDeadline = returnDeadtimestamp(
                this.createForm.innerDeadlineTmp
              );
            }
            // 预计走访日期
            if (this.createForm.expectVisitDateTmp) {
              this.createForm.expectVisitDate = returnBitTimestamp(
                this.createForm.expectVisitDateTmp
              );
            }
            this.createPortForm.header.currentTime = returnBitTimestamp(
              new Date()
            );
            // 回显地址格式转化为后台所需带有连接符的字符串
            if (this.createForm.address instanceof Array) {
              this.createForm.address = this.createForm.address.join(",");
            }
            // 操作记录
            this.createForm.operAction = "工单派单";

            /** 后端根据是否添加工单号判断 编辑提交 或是 创建提交 */
            this.createForm.workOrderNo = this.queryForm.body.workOrderNo;
            Object.assign(this.createPortForm.body, this.createForm);
            submitCreate(this.createPortForm).then((response) => {
              if (response.header.retCode === "1") {
                // 刷新已处理
                this.$emit("refreshData");
                this.$message({
                  message: `${response.header.retMessage}`,
                  type: "success",
                });
                this.submitFlag = submitFlag + 1;
                // 重置表单
                this.reset();
                this.flag = false;
                this.dialogFormVisible = false;
              } else {
                this.$message.error(
                  `错误信息: ${response.header.retMessage}, 请重新提交编辑订单！`
                );
              }
              // this.submitLoading = false;
            });
          } else {
            this.$message.error("提交失败，请重新检查工单数据");

            return false;
          }
        });
      }

      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
    },

    /*** 匹配返回数组索引 */
    indexOf(val, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].name === val) {
          return i;
        }
      }
      return -1;
    },

    // 获取报告分类
    getReportType() {
      getReportTypeList(apiParams()).then((response) => {
        if ("1" === response.header.retCode) {
          this.reportType = response.body.data.OrderType;
        }
      });
    },
    // 修改内容标黄
    brChange(data) {
      if (
        this.createForm &&
        this.createForm.siteBrUpdateColumnRes &&
        this.createForm.siteBrUpdateColumnRes[data]
      ) {
        return "is-change";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
body {
  font-weight: 700;
  color: #666;
}
.bookUser_input >>> .el-input__inner {
  cursor: pointer;
}
.createForm-box >>> .el-form {
  padding-right: 0;
}
.edit_title .title {
  margin: 10px 0;
}
.el-step__title {
  font-size: 14px;
}
.el-step__title.is-process {
  font-weight: bold;
  color: #666;
}
html body .el-table th .cell {
  font-weight: 700;
  color: #666;
}

.el-form-item__label {
  font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100% !important;
}

.el-select,
.el-cascader {
  width: 100%;
}
.my-textarea > textarea {
  height: 32px;
}
.el-input.is-change .el-input__inner {
  background: #ffff9f;
}
</style>
