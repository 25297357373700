var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "myDialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "60%",
        fullscreen: _vm.fullFlag,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("el-backtop", { attrs: { target: ".myDialog" } }),
      _c("i", {
        class: _vm.fullIcon,
        staticStyle: {
          position: "absolute",
          top: "24px",
          right: "41px",
          cursor: "pointer",
        },
        attrs: { title: _vm.fullTitle },
        on: { click: _vm.triggerFullOrHelf },
      }),
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v(_vm._s(_vm.companyName)),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "取证资料", name: "evidence" } },
            [_c("evidence", { ref: "evidence", attrs: { row: _vm.object } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄照片" } },
            [
              _c("photo", {
                ref: "photo",
                attrs: { type: "50", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄录像" } },
            [
              _c("screenVideo", {
                ref: "screenVideo",
                attrs: { type: "51", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "扫描文件", name: "scanFile" } },
            [
              _c("scanFile", {
                ref: "scanFile",
                attrs: { type: "52", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "录制音频" } },
            [
              _c("screenAudio", {
                ref: "screenAudio",
                attrs: { type: "53", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "会议信息" } },
            [
              _c("meeting", {
                ref: "meeting",
                attrs: { type: "57", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "标注记录" } },
            [
              _c("labelRecord", {
                ref: "labelRecord",
                attrs: { type: "56", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "企业信息" } },
            [_c("company", { ref: "company", attrs: { row: _vm.object } })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", plain: "" },
              on: { click: _vm.smallNoteOpen },
            },
            [_vm._v(" 添加小记 ")]
          ),
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.backShowOpen } },
            [_vm._v("退回")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitShowOpen } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提交",
            visible: _vm.submitShow,
            width: "500px",
            "before-close": _vm.submitShowClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.submitShow = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v("该工单是否确认提交？"),
          ]),
          _c(
            "el-form",
            {
              ref: "submitForm",
              staticClass: "demo-form-inline",
              attrs: {
                model: _vm.submitForm,
                rules: _vm.submitFormRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "小记", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.submitForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitForm, "note", $$v)
                      },
                      expression: "submitForm.note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.submitShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitSave("submitForm")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加小记",
            visible: _vm.openSmallNote,
            width: "40%",
            "close-on-click-modal": false,
            "before-close": _vm.smallNoteClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openSmallNote = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "smallNoteForm",
              staticClass: "my-note",
              attrs: {
                model: _vm.smallNoteForm,
                rules: _vm.smallNoteFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "小记", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.smallNoteForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.smallNoteForm, "note", $$v)
                      },
                      expression: "smallNoteForm.note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.openSmallNote = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.noteLoading },
                  on: {
                    click: function ($event) {
                      return _vm.saveSmallNote("smallNoteForm")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退回",
            visible: _vm.backShow,
            "close-on-click-modal": false,
            "before-close": _vm.backShowClose,
            width: "55%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.backShow = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v("该工单是否退回？"),
          ]),
          _c(
            "el-form",
            {
              ref: "backForm",
              attrs: {
                model: _vm.backForm,
                rules: _vm.backFormRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退回类型", prop: "fallbackCommentType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择退回类型",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.changeType },
                      model: {
                        value: _vm.backForm.fallbackCommentType,
                        callback: function ($$v) {
                          _vm.$set(_vm.backForm, "fallbackCommentType", $$v)
                        },
                        expression: "backForm.fallbackCommentType",
                      },
                    },
                    _vm._l(_vm.roleOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.showFlag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "普通退回原因",
                        prop: "fallbackCommentId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "普通退回原因",
                            clearable: true,
                            filterable: "",
                          },
                          model: {
                            value: _vm.backForm.fallbackCommentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.backForm, "fallbackCommentId", $$v)
                            },
                            expression: "backForm.fallbackCommentId",
                          },
                        },
                        _vm._l(_vm.backOptions, function (item) {
                          return _c("el-option", {
                            key: item.seqId,
                            attrs: { label: item.content, value: item.seqId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "小记", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.backForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.backForm, "note", $$v)
                      },
                      expression: "backForm.note",
                    },
                  }),
                ],
                1
              ),
              _vm.showContent
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "vab-quill-content",
                      attrs: { label: "内容", prop: "fallbackComment" },
                    },
                    [
                      _c("vab-quill", {
                        attrs: { "min-height": 200 },
                        model: {
                          value: _vm.backForm.fallbackComment,
                          callback: function ($$v) {
                            _vm.$set(_vm.backForm, "fallbackComment", $$v)
                          },
                          expression: "backForm.fallbackComment",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.backShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.withdrawLoading },
                  on: {
                    click: function ($event) {
                      return _vm.backSave("backForm")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }