// Kyle API 工作台 -> 工具
import request from "@/utils/request";

// 调整人员
export function adjustPerson(data) {
  return request({
    url: "/common/adjust",
    method: "post",
    data,
  });
}

// 生成报告
export function generateReport(data) {
  return request({
    url: "/createReports",
    method: "post",
    data,
  });
}

// 下载资源
export function downloadReport(data) {
  return request({
    url: "/down",
    method: "get",
    params: data,
  });
}
