<!-- Kyle 扫描文件 组件 -->
<template>
  <el-card class="box-card" shadow="never">
    <!-- 头部导航 -->
    <vab-query-form>
      <vab-query-form-left-panel class="checkAllBox">
        <el-checkbox
          v-model="checkAll"
          border
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
        <el-button
          plain
          size="small"
          icon="el-icon-download"
          @click="downloadVisible = true"
        >
          下载
        </el-button>
        <el-button
          v-if="
            row.workplace === 'investigator' ||
            (row.workplace === 'supervisor' && row.isSurveyUser === 'Y')
          "
          type="danger"
          size="small"
          icon="el-icon-delete"
          @click="handleDelete"
        >
          批量删除
        </el-button>
        <!-- 全部确定提交/全部取消状态开关 -->
        <el-switch
          v-if="
            (row.workplace === 'supervisor' && row.flag === 'edit') ||
            (row.workplace === 'verify_people' && row.flag === 'review')
          "
          v-model="sumitTag"
          :active-value="1"
          :inactive-value="0"
          @change="switchBatchSubmit"
        ></el-switch>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel
        v-if="
          row.workplace === 'investigator' ||
          (row.workplace === 'supervisor' && row.isSurveyUser === 'Y')
        "
        class="上传"
      >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="openUploadSource = true"
        >
          上传
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>
    <div style="margin: 15px 0"></div>
    <!-- 录像展示 -->
    <el-row>
      <el-col style="padding: 10px">
        <draggable
          v-model="fileData"
          class="board-column-content"
          handle=".draggle-element"
          :options="{ disabled: undraggable }"
          @end="end"
        >
          <div
            v-for="(fileItem, fileIndex) in fileData"
            :key="fileIndex"
            class="class-detail"
          >
            <div class="draggle-element">
              <a
                rel="noopener noreferrer"
                @click.stop.prevent="preview(fileItem.name, fileItem.path)"
              >
                <img
                  style="margin-left: 12px; width: 100px; height: 100px"
                  src="@/icon/pdf.svg"
                  :fit="fit"
                />
              </a>
            </div>
            <div class="tip-detail">
              <el-checkbox
                v-model="fileItem.mychecked"
                class="file_checkbox"
                @change="handlecheckedChange(fileIndex)"
              ></el-checkbox>
              <!-- 提交状态开关 -->
              <el-switch
                v-if="
                  (row.workplace === 'supervisor' && row.flag === 'edit') ||
                  (row.workplace === 'verify_people' && row.flag === 'review')
                "
                v-model="fileItem.tag"
                class="submitSwitch"
                :active-value="1"
                :inactive-value="0"
                @change="switchSubmit(fileItem)"
              ></el-switch>
              <label-edit
                v-if="labelEditReload && fileItem.generateTag !== 'generate'"
                class="labelEdit"
                :text="fileItem.name"
                :able-change="operaEnable"
                @text-updated-blur="textUpdateCallbackBlur($event, fileItem)"
              ></label-edit>
              <p v-else :title="fileItem.name" class="fileTip">
                {{
                  fileItem.name.length > 10
                    ? fileItem.name.substring(0, 10) + "..."
                    : fileItem.name
                }}
              </p>
            </div>
          </div>
        </draggable>
      </el-col>
      <el-col>
        <el-pagination
          :background="background"
          :current-page="parameter.body.pageNum"
          :layout="layout"
          :page-size="parameter.body.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </el-col>
    </el-row>
    <!-- 下载确认对话框 -->
    <el-dialog
      title="下载确认"
      :visible.sync="downloadVisible"
      width="30%"
      append-to-body
      class="downloadDialog"
    >
      <span>是否要下载所有勾选内容？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadVisible = false">取 消</el-button>
        <el-button type="primary" @click="download">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 批量上传资源对话框 -->
    <el-dialog
      title="上传资源"
      :visible.sync="openUploadSource"
      width="573px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      append-to-body
    >
      <el-card class="box-card">
        <el-row align="middle" type="flex" :gutter="40">
          <el-col :span="24">
            <div style="font-weight: 600; font-size: 15px; margin-bottom: 10px">
              请您上传资源
            </div>
            <div>
              <el-upload
                ref="upload"
                class="upload-demo"
                :action="row.endpoinxBak"
                :on-error="handleError"
                :on-remove="handleRemove"
                :on-change="handleFileChange"
                :http-request="batch"
                :auto-upload="false"
                multiple
                accept=".pdf,.PDF"
                :file-list="fileList"
                drag
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文档拖到此处，或
                  <em>点击上传</em>
                </div>
                <div slot="tip" class="el-upload__tip">
                  支持的文档类型包括：.pdf
                </div>
              </el-upload>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          ref="$complete"
          :loading="subLoading"
          type="primary"
          :disabled="submitDisabled"
          @click="scanFileSubmit"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  const OSS = require("ali-oss");
  import axios from "axios";
  import labelEdit from "@/plugins/labelEdit";
  import {
    detailMediaResource,
    uploadSource,
    deleteSource,
    updateSequence,
    updateSubmit,
    // 批量切换提交接口
    updateBatchSubmit,
    checkMediaName,
    checkMediaNameList,
    renameMedia,
  } from "@/api/workplace/workDetail";
  import {
    returnBitTimestamp,
    downloadFile,
    downloadFile2,
    /** 文件名称格式校验 */
    fileFormatValid,
  } from "@/utils/index";
  import draggable from "vuedraggable";
  export default {
    name: "ScanFile",
    components: {
      draggable,
      labelEdit,
    },
    props: {
      // eslint-disable-next-line vue/require-default-prop
      type: {
        type: String,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: null,
      },
      row: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        /**影视资源能否编辑名称标识 */
        operaEnable: false,
        // 全部提交/全部取消标记
        sumitTag: 1,
        labelEditReload: true,
        checkNameForm: {
          body: {
            groupId: "",
            name: "",
            workOrderNo: "",
            type: "",
          },
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "",
          },
        },
        /** 检验名称批量接口 */
        checkNameListForm: {
          body: [],
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "site",
          },
        },
        updateNameForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "",
          },
        },
        /**添加文件任务数组 */
        fileArr: [],
        /**添加异步任务数组 */
        promiseArr: [],
        // 影视资源能否拖动排序标识
        undraggable: false,
        checkAll: false,
        downloadVisible: false,
        subLoading: false,
        // 提交按钮禁用标识
        submitDisabled: false,
        fit: "fill",
        fileData: [],
        maxSortNumber: 0,
        /**分页 */
        background: true,
        total: 0,
        layout: "total, sizes, prev, pager, next, jumper",
        isIndeterminate: false,
        tabPosition: "left",
        /** 上传资源 */
        fileList: [],
        fileNameList: [],
        // 上传资源请求
        uploadSourceForm: {
          body: {
            console: "",
            note: "",
            operAction: "",
            siteMediaResourceReqs: [],
            step: "",
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        switchSubmitForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        // 批量全部确定提交/全部取消
        switchBatchSubmitForm: {
          body: {
            batchTag: 0,
            console: "",
            type: 0,
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        // 删除
        deleteSourceForm: {
          body: {
            seqId: [],
            userNo: "",
            userRoleNo: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        /** 排序 */
        sequenceForm: {
          body: [],
          header: {
            currentTime: "",
            requestId: "111",
            sourceType: "site",
          },
        },
        openUploadSource: false,
        parameter: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
            pageNum: 1,
            pageSize: 10,
          },
          header: {
            currentTime: "",
            requestId: "111",
            sourceType: "site",
          },
        },
      };
    },
    updated() {
      this.initOperation();
    },
    methods: {
      /** 初始化操作权限 */
      initOperation() {
        if (
          this.row.workplace === "investigator" ||
          (this.row.workplace === "supervisor" && this.row.isSurveyUser === "Y")
        ) {
          /** 可以编辑名称 */
          this.operaEnable = true;
          /** 可以拖拽 */
          this.undraggable = false;
        } else {
          this.operaEnable = false;
          this.undraggable = true;
        }
      },
      /** 预览 */
      preview(name, path) {
        window.open(`/site/showOss?path=${path}&name=${name}`, "_blank");
      },
      /** 点击编辑组件update */
      labelEditRefresh() {
        // 移除组件
        this.labelEditReload = false;
        // 在组件移除后，重新渲染组件
        // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
        this.$nextTick(() => {
          this.labelEditReload = true;
        });
      },
      async textUpdateCallbackBlur(text, media) {
        /** 验证文件名 */
        if (!fileFormatValid(text)) {
          this.$message.error("名称中不能包含非法特殊字符,请重新修改!");
          this.labelEditRefresh();
          return false;
        }
        /** 名称判空的情况 */
        if (text.length === 0) {
          this.$message.error("名称不能为空,请重新修改!");
          this.labelEditRefresh();
          return false;
        }
        /** 名字一致的情况下 */
        if (text === media.name) {
          this.labelEditRefresh();
          return false;
        }
        /**验证名称唯一性 */
        this.checkNameForm.body.name = text;
        this.checkNameForm.body.workOrderNo = this.row.workOrderNo;
        this.checkNameForm.body.type = media.type;
        const { header } = await checkMediaName(this.checkNameForm);
        /** 确认名称唯一的时候修改 */
        if (header.retCode === "1") {
          this.updateNameForm.body = Object.assign(media, {
            name: text,
            description: text,
            // groupId: photoGroup.seqId,
            workOrderNo: this.row.workOrderNo,
            userRoleNo: this.row.userRoleNo,
            userNo: this.row.userNo,
          });
          renameMedia(this.updateNameForm).then((res) => {
            if (res.header.retCode !== "1") {
              this.$message.error(`${res.header.retMessage}`);
            } else {
              this.$message({
                type: "success",
                message: `${res.header.retMessage}`,
              });
            }
            this.fetchData();
          });
        } else {
          this.$message.error(`${header.retMessage}`);
          this.labelEditRefresh();
          return false;
        }
      },
      /**提交 */
      switchSubmit(file) {
        let obj = {};
        this.switchSubmitForm.body = {
          seqId: file.seqId,
          tag: file.tag,
          sortNumber: file.sortNumber,
          type: file.type,
          watermarkFlag: file.watermarkFlag,
          workOrderNo: this.row.workOrderNo,
          associated: file.associated,
        };
        updateSubmit(this.switchSubmitForm).then((res) => {
          if (res.header.retCode === "1") {
            this.fetchData();
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      },
      /** 批量提交状态切换 */
      switchBatchSubmit() {
        // 资源为空的情况下
        if (this.fileData.length === 0) {
          this.$message.error(`暂无数据请勿切换提交状态`);
          return;
        }
        this.switchBatchSubmitForm.body.batchTag = this.fileData[0].batchTag;
        this.switchBatchSubmitForm.body.console = this.row.workplace;
        this.switchBatchSubmitForm.body.type = 52;
        this.switchBatchSubmitForm.body.workOrderNo = this.row.workOrderNo;
        updateBatchSubmit(this.switchBatchSubmitForm).then((res) => {
          if (res.header.retCode === "1") {
            this.$message({
              type: "success",
              message: `${res.header.retMessage}`,
            });
            this.fetchData();
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      },
      // 排序
      async end(e) {
        let sort = this.fileData.length;

        /**配合后端 组合带有sortNum的数组传递，后端根据sortNumber来排序 */
        this.fileData.map((item) => {
          item.sortNumber = sort;
          sort--;
          item.userNo = this.row.userNo;
          item.userRoleNo = this.row.userRoleNo;
        });
        this.sequenceForm.body = this.fileData;
        const { header, body } = await updateSequence(this.sequenceForm);
        if (header.retCode === "1") {
          this.fetchData();
        } else {
          this.$message.error(`${header.retMessage}`);
        }
      },
      /** 分页 */
      handleSizeChange(val) {
        this.parameter.body.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.parameter.body.pageNum = val;
        this.fetchData();
      },
      /** 下载 */
      async download() {
        let downloadArr = [];
        this.fileData.map((item) => {
          if (item.mychecked === true) {
            downloadFile(item);
            downloadArr.push(item.path);
          }
        });
        if (downloadArr.length === 0) {
          this.$message.error(`未选中需要下载的文件`);
        }
        this.downloadVisible = false;
      },
      /** 批量删除文件 */
      async handleDelete() {
        let seqIdArr = [];
        if (this.fileData.length !== 0) {
          this.fileData.map((item) => {
            if (item.mychecked === true) {
              seqIdArr.push({
                seqId: item.seqId,
                type: 52,
                userNo: this.row.userNo,
                userRoleNo: this.row.userRoleNo,
                workOrderNo: this.row.workOrderNo,
              });
            }
          });
          if (seqIdArr.length === 0) {
            this.$message.error(`未选中需要删除的文件`);
          } else {
            this.$baseConfirm("你确定要删除当前项吗", null, async () => {
              /** 向实地系统发送删除请求 实为逻辑删除 */
              /** deleteSourceForm 配置*/
              this.deleteSourceForm.body = seqIdArr;
              try {
                let { header } = await deleteSource(this.deleteSourceForm);
                if (header.retCode !== "1") {
                  this.$message.error(`${header.retMessage}`);
                }
              } catch (e) {
                this.$message.error(`${e}`);
              }
              this.fetchData();
            });
          }
        } else {
          this.$message.error(`没有需要删除的文件`);
        }
      },
      handleFileChange(file, filelist) {
        let exitedObj = {};
        /** 判断文件格式 */
        if (filelist.length > 0) {
          for (let item of filelist) {
            // 优化
            let fileType = ["pdf"];
            fileType.map((item) => {
              fileType.push(item.toUpperCase());
            });
            const itemType = item.name.substring(
              item.name.lastIndexOf(".") + 1
            );
            if (fileType.includes(itemType)) {
            } else {
              this.$message.error(`上传文件只能是pdf格式!, 请检查`);
              this.submitDisabled = true;
              return;
            }
          }
        }
        /*** 判断文件大小 */
        if (filelist.length > 0) {
          for (let item of filelist) {
            const expSize = item.size / 1024 / 1024 < 1024;
            if (!expSize) {
              this.$message.error(`文件大小不能超过 1G, 请检查`);
              this.submitDisabled = true;
              return;
            }
          }
        }
        // 判断文件命名非法字符校验
        if (filelist.length > 0) {
          for (let item of filelist) {
            const filename = item.name.substring(0, item.name.lastIndexOf("."));
            if (!fileFormatValid(filename)) {
              this.$message.error("文件名称中不能包含非法特殊字符, 请检查");
              this.submitDisabled = true;
              return;
            }
          }
        }
        /** 判断文件同名 */
        if (filelist.length > 1) {
          for (let i in filelist) {
            if (exitedObj[filelist[i].name]) {
              this.$message.error("请勿上传同名文件！");
              this.submitDisabled = true;
              return;
            }
            exitedObj[filelist[i].name] = true;
          }
        }
        this.submitDisabled = false;
      },
      /** 上传资源 */
      /**
       * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
       * @author   shanshuizinong
       * @param    {object}   option [上传选项]
       * @return   {null}   [没有返回]
       */
      async fnUploadRequest(option, index) {
        return new Promise((resolve, reject) => {
          /** 带有时间戳的文件名 */
          const fileName =
            new Date().getTime() +
            index +
            "." +
            option.file.name.match(/^(.*)(\.)(.{1,8})$/)[3];
          // 获取OSS配置信息
          const uploadConfig = new FormData();
          /**名称统一使用 "cbi-site-front/workplace/ + 工单号 + 影像资料 +文件*/
          uploadConfig.append(
            "key",
            "cbi-site-front/workplace/" +
              this.row.workOrderNo +
              "/scanFile/" +
              fileName
          );
          uploadConfig.append("OSSAccessKeyId", this.row.accessKeyId);
          uploadConfig.append("policy", this.row.policy);
          uploadConfig.append("Signature", this.row.signature);
          uploadConfig.append("success_action_status", 200);
          uploadConfig.append(
            "name",
            option.file.name.substring(0, option.file.name.lastIndexOf("."))
          );
          /** 表单域 file 必须为最后一个 */
          uploadConfig.append("file", option.file);
          /** uploadSourceForm 配置*/
          this.uploadSourceForm.body.userNo = this.row.userNo;
          this.uploadSourceForm.body.userRoleNo = this.row.userRoleNo;
          this.uploadSourceForm.body.workOrderNo = this.row.workOrderNo;
          this.uploadSourceForm.body.console = this.row.workplace;

          axios
            .post(this.row.endpoinxBak, uploadConfig, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.status == 200) {
                //上传成功 上传的路径就是要使用的路径
                this.uploadSourceForm.body.siteMediaResourceReqs.push({
                  console: this.row.workplace,
                  path:
                    "cbi-site-front/workplace/" +
                    this.row.workOrderNo +
                    "/scanFile/" +
                    fileName,
                  name: option.file.name.substring(
                    0,
                    option.file.name.lastIndexOf(".")
                  ),
                  description: option.file.name.substring(
                    0,
                    option.file.name.lastIndexOf(".")
                  ),
                  fileSource: 0,
                  seqId: "",
                  /** 给当前最大sort加1 */
                  sortNumber: this.maxSortNumber + index,
                  type: "52",
                  userNo: this.row.userNo,
                  userRoleNo: this.row.userRoleNo,
                  visitFlag: 0,
                  watermarkFlag: null,
                });
                this.fileNameList.push(option.file.name);
              }
              resolve(res);
            })
            .catch((error) => {
              const index = this.indexOf(
                option.file.name,
                this.uploadSourceForm.body.siteMediaResourceReqs
              );
              this.uploadSourceForm.body.siteMediaResourceReqs.splice(index, 1);
              this.$message.error(`${option.file.name}上传错误,请重新上传`);
              reject(error);
            });
        });
      },
      /** 自定义上传方法，使其多次添加资源 */
      batch(option) {
        this.fileArr.push(option);
      },
      /*** 匹配返回数组索引 */
      indexOf(val, arr) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].name == val) {
            return i;
          }
        }
        return -1;
      },
      /*** 删除附件 */
      async handleRemove(file, filelist) {
        // 重复文件计数
        let count = 0;
        let exitedObj = {};
        if (filelist.length > 1) {
          for (let i in filelist) {
            if (exitedObj[filelist[i].name]) {
              this.$message.error(`请勿上传同名文件！`);
              count++;
              break;
            }
            exitedObj[filelist[i].name] = true;
          }
        }
        /*** 判断文件大小 */
        if (filelist.length > 0) {
          for (let item of filelist) {
            const expSize = item.size / 1024 / 1024 < 1024;
            if (!expSize) {
              this.$message.error(`文件大小不能超过 1G, 请检查`);
              count++;
              break;
            }
          }
        }
        /** 判断文件格式 */
        if (filelist.length > 0) {
          for (let item of filelist) {
            // 优化
            let fileType = ["pdf"];
            fileType.map((item) => {
              fileType.push(item.toUpperCase());
            });
            const itemType = item.name.substring(
              item.name.lastIndexOf(".") + 1
            );
            if (fileType.includes(itemType)) {
            } else {
              this.$message.error(`上传文件只能是pdf格式!, 请检查`);
              count++;
              break;
            }
          }
        }
        // 判断文件命名非法字符校验
        if (filelist.length > 0) {
          for (let item of filelist) {
            const filename = item.name.substring(0, item.name.lastIndexOf("."));
            if (!fileFormatValid(filename)) {
              this.$message.error("文件名称中不能包含非法特殊字符, 请检查");
              count++;
              break;
            }
          }
        }
        this.submitDisabled = count >= 1;
        const client = new OSS({
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          bucket: this.row.bucketName,
          region: this.row.region,
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: this.row.accessKeyId,
          accessKeySecret: this.row.accessKeySecret,
        });
        if (this.fileNameList.indexOf(file.name) > -1) {
          var index = this.indexOf(
            file.name,
            this.uploadSourceForm.body.siteMediaResourceReqs
          );
          if (index > -1) {
            this.uploadSourceForm.body.siteMediaResourceReqs.splice(index, 1);
          }
        }
      },
      /** 上传失败的回调 */
      handleError(err, file, fileList) {
        this.$message.error(`上传错误,错误信息 ${err}`);
      },
      // 通过oss的数据发送给后端
      /**
       * @method: 自定义上传方法 本方法包括：elment组件上传(实为添加文件);验证文件名称唯一性;上传oss;将结果回调site后端;
       * @description: 调用执行完element内部的文件上传生命周期，其次执行自定义上传
       */
      async scanFileSubmit() {
        this.subLoading = true;
        await this.$refs.upload.submit();
        /* 置空 */
        this.checkNameListForm.body = [];
        // 判空
        if (this.fileArr.length === 0) {
          this.subLoading = false;
          this.$message({
            message: "请添加需要上传的文件",
            type: "warning",
          });
          return;
        }
        /**批量检验名称  */
        this.fileArr.map((el) => {
          this.checkNameListForm.body.push({
            // 0_pc
            fileSource: 0,
            /** 描述 */
            description: el.file.name.substring(
              0,
              el.file.name.lastIndexOf(".")
            ),
            /** 名称 */
            name: el.file.name.substring(0, el.file.name.lastIndexOf(".")),
            type: 52,
            workOrderNo: this.row.workOrderNo,
          });
        });
        const { header, body } = await checkMediaNameList(
          this.checkNameListForm
        );
        if (header.retCode === "1") {
          this.submitDisabled = false;
        } else {
          this.fileArr = [];
          this.$message({
            duration: 10000,
            showClose: true,
            message: `${header.retMessage}`,
            type: "error",
          });
          this.subLoading = false;
          return;
        }
        this.promiseArr = this.fileArr.map((el, index) => {
          this.maxSortNumber++;
          return this.fnUploadRequest(el, index);
        });
        Promise.all(this.promiseArr)
          .then((res) => {
            this.promiseArr = [];
            this.fileArr = [];
            // 获取系统当前时间
            this.uploadSourceForm.header.currentTime = returnBitTimestamp(
              new Date()
            );
            /** 影视资料判断上传为空的优化 */
            if (this.uploadSourceForm.body.siteMediaResourceReqs.length === 0) {
              this.subLoading = false;
              this.$message({
                message: "请添加需要上传的文件",
                type: "warning",
              });
              return;
            }
            uploadSource(this.uploadSourceForm).then((response) => {
              this.uploadSourceForm.body.siteMediaResourceReqs = [];
              if (response.header.retCode === "1") {
                this.$message({
                  message: `${response.header.retMessage}`,
                  type: "success",
                });
                this.fetchData();
                this.handleClose();
              } else {
                this.$message.error(`错误信息: ${response.header.retMessage}`);
              }
              this.subLoading = false;
            });
          })
          .catch((error) => {
            this.promiseArr = [];
            this.fileArr = [];
            this.uploadSourceForm.body.siteMediaResourceReqs = [];
            this.subLoading = false;
            this.$message.error(`${error}`);
            // reject(error);
          });
      },
      // 上传窗口关闭
      handleClose() {
        this.fileList = [];
        this.uploadSourceForm.body.siteMediaResourceReqs = [];
        this.fileNameList = [];
        this.openUploadSource = false;
        this.submitDisabled = false;
      },
      fetchData() {
        this.parameter.body.type = this.type;
        this.parameter.body.userNo = this.row.userNo;
        this.parameter.body.userRoleNo = this.row.userRoleNo;
        this.parameter.body.workOrderNo = this.row.workOrderNo;
        this.parameter.body.console = this.row.workplace;

        detailMediaResource(this.parameter).then((res) => {
          if (res.header.retCode === "1") {
            this.fileData = res.body.records.map((item) => {
              item.mychecked = false;
              return item;
            });
            /** fetchdata获取最大的maxSortNumer */
            if (res.body.records.length !== 0) {
              this.maxSortNumber = res.body.records[0].maxSortNumber;
            } else {
              this.maxSortNumber = 0;
            }
            // 用于动态联动 <提交开关>的状态，后端接口返回batchTag都一致，故取非空数组第一索引元素中的值
            this.sumitTag =
              this.fileData.length > 0 ? this.fileData[0].batchTag : 0;
            this.total = res.body.total;
          } else {
            this.$message.error(
              `查询扫描文件错误信息: ${res.header.retMessage}`
            );
          }
          /**重载部分组件 */
          // 移除组件
          this.labelEditReload = false;
          // 在组件移除后，重新渲染组件
          // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
          this.$nextTick(() => {
            this.labelEditReload = true;
          });
        });
      },
      // 总的全选
      handleCheckAllChange(val) {
        if (val) {
          for (let i = 0; i < this.fileData.length; i += 1) {
            this.fileData[i].mychecked = true;
          }
        } else {
          for (let i = 0; i < this.fileData.length; i += 1) {
            this.fileData[i].mychecked = false;
          }
        }
        this.isIndeterminate = false;
      },
      // 打开预览
      handleView(fileData, fileIndex) {
        this.$refs["screenfileView"].bindData(fileData, fileIndex);
        this.$refs["screenfileView"].showView();
      },
      handlecheckedChange() {
        const subData = this.fileData;
        let tickCount = 0;
        const len = subData.length;
        for (let i = 0; i < len; i += 1) {
          if (subData[i].mychecked === true) {
            tickCount += 1;
          } else {
            tickCount -= 1;
          }
        }
      },
    },
  };
</script>

<style scoped>
  .labelEdit {
    margin: -30px 0px 0px 0px;
  }
  .fileTip {
    margin: -30px 0px 0px 0px;
    color: #000;
  }
  .tip-detail {
    height: 20px;
    padding-top: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
  .title {
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-weight: 700;
    font-style: normal;
    text-align: left;
    line-height: 28px;
  }
  .class-detail {
    float: left;
    margin-right: 40px;
    margin-bottom: 40px;
  }
  /* checkbox 浮层样式 */
  .file_checkbox {
    z-index: 999;
    position: relative;
    top: -135px;
    left: 0px;
  }
  .submitSwitch {
    width: 35px;
    /* text-align: center; */
    z-index: 999;
    position: relative;
    top: -38px;
    left: 75px;
  }
  .checkAllBox >>> .el-checkbox.is-bordered.el-checkbox--small {
    padding: 7px 15px 5px 10px;
  }
  .downloadDialog >>> .el-dialog__body {
    padding: 45px 20px;
  }
  .el-icon-upload {
    display: flex;
    justify-content: center;
    font-size: 70px;
    color: #ccc;
  }
</style>
