<template>
  <div>
    <!--检索模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-form
            ref="form"
            :inline="true"
            :model="form"
            class="demo-form-inline"
          >
            <el-form-item prop="type">
              <el-select
                v-model="form.type"
                placeholder="请选择字段类型"
                clearable
                filterable
              >
                <el-option
                  v-for="item in fieldType"
                  :key="item.seqId"
                  :label="item.content"
                  :value="item.seqId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                plain
                style="margin-bottom: 15px"
                @click="handleQuery"
              >
                查询
              </el-button>
              <el-button
                icon="el-icon-refresh-left"
                plain
                native-type="submit"
                @click="resetForm('form')"
              >
                重置
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            icon="el-icon-plus"
            style="margin-bottom: 15px"
            type="primary"
            @click="handleAdd"
          >
            新增
          </el-button>

          <el-table
            ref="tableSort"
            v-loading="listLoading"
            border
            :data="list"
            :element-loading-text="elementLoadingText"
            @selection-change="setSelectRows"
          >
            <el-table-column label="字段类型" prop="fieldType" width="100">
              <template slot-scope="scope">
                {{ scope.row.fieldType }}
              </template>
            </el-table-column>

            <el-table-column label="标题" prop="content">
              <template slot-scope="scope">
                {{ scope.row.content }}
              </template>
            </el-table-column>
            <el-table-column label="入库时间" prop="createTime" sortable>
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="140px"
              fixed="right"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="编辑"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit-outline"
                    circle
                    @click="handleEdit(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="升序"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    plain
                    icon="el-icon-top"
                    circle
                    @click="order(scope.row, 0)"
                  ></el-button>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="dark"
                  content="降序"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    plain
                    icon="el-icon-bottom"
                    circle
                    @click="order(scope.row, 1)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.body.pageNum"
            :layout="layout"
            :page-size="queryForm.body.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!--新增项目-->
    <add ref="add" @fetchData="fetchData"></add>

    <!--编辑项目-->
    <edit ref="edit" @fetchData="fetchData"></edit>
  </div>
</template>

<script>
  import { getFields, getFieldType, upOrDown } from "@/api/project/setting";
  import edit from "./components/edit";
  import add from "./components/add";
  import { returnBitTimestamp } from "@/utils";
  import { mapGetters } from "vuex";
  export default {
    name: "ComprehensiveTable",
    components: {
      add,
      edit,
    },

    data() {
      return {
        isShow: false,
        imgShow: true,
        form: {
          title: "",
          type: "",
        },
        list: [],
        listLoading: true,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
        background: true,
        selectRows: "",
        elementLoadingText: "正在加载...",
        queryForm: {
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          body: {
            pageNum: 1,
            pageSize: 10,
            templateTypeSeqId: null,
          },
        },
        fieldType: [],
        // 升序或者降序
        param: {
          body: {
            ascOrDesc: null,
            parentId: null,
            seqId: null,
            userNo: null,
            userRoleNo: "commonRole",
          },
          header: {
            currentTime: "",
            requestId: "1",
            sourceType: "site",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },

    created() {
      this.param.body.userNo = this.userNo;
    },
    beforeDestroy() {},
    mounted() {
      this.fetchData();
      this.fetchFieldType();
    },
    methods: {
      // 获取字段类型
      async fetchFieldType() {
        this.queryForm.header.currentTime = returnBitTimestamp(new Date());
        const { body } = await getFieldType(this.queryForm);
        this.fieldType = body;
      },

      // 查询字段编辑
      async fetchData() {
        this.listLoading = true;
        this.queryForm.body.templateTypeSeqId = this.form.type;
        this.queryForm.header.currentTime = returnBitTimestamp(new Date());
        const { body } = await getFields(this.queryForm);
        this.list = body.records;
        this.total = body.total;
        setTimeout(() => {
          this.listLoading = false;
        }, 500);
      },

      setSelectRows(val) {
        this.selectRows = val;
      },
      handleAdd() {
        this.$refs["add"].showAdd(this.fieldType);
      },
      handleEdit(row) {
        this.$refs["edit"].showEdit(row);
      },

      order(row, flag) {
        this.param.header.currentTime = returnBitTimestamp(new Date());
        this.param.body.ascOrDesc = flag;
        this.param.body.seqId = row.seqId;
        this.param.body.parentId = row.parentId;
        if (row.id && 0 === flag) {
          this.$baseConfirm("你确定要升序吗", null, async () => {
            const { header } = await upOrDown(this.param);
            this.$baseMessage(header.retMessage, "success");
            await this.fetchData();
          });
        } else {
          this.$baseConfirm("你确定要降序吗", null, async () => {
            const { header } = await upOrDown(this.param);
            this.$baseMessage(header.retMessage, "success");
            await this.fetchData();
          });
        }
      },
      handleSizeChange(val) {
        this.queryForm.body.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.queryForm.body.pageNum = val;
        this.fetchData();
      },
      handleQuery() {
        this.queryForm.body.pageNum = 1;
        this.fetchData();
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
  };
</script>
<style scoped>
  .el-form-item__content button {
    margin-bottom: 0 !important;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
</style>
