<template>
  <div class="table-container">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-table ref="tableSort" v-loading="loading" :data="list" border>
          <el-table-column label="序号" align="center" width="80" prop="seq">
            <template slot-scope="scope">
              {{ scope.row.seq || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="企业名称">
            <template slot-scope="scope">
              {{ scope.row.name || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="report_year" label="报送年度">
            <template slot-scope="scope">
              {{ scope.row.report_year || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="公示日期" prop="report_date">
            <template slot-scope="scope">
              {{ scope.row.report_date || "-" }}
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            width="100px"
            fixed="right"
            align="center"
            fit="true"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="查看详情"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-document"
                  circle
                  @click="showDetail(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row v-if="total > 0" :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :current-page="queryForm.pageNum"
            :page-size="queryForm.pageSize"
            :total="total"
            :background="background"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!--查看详情-->
    <el-dialog
      title="年报详情"
      :visible.sync="flag"
      :close-on-click-modal="false"
      width="70%"
      append-to-body
      @close="close"
    >
      <table
        class="info-table"
        cellspacing="0"
        style="width: 100%; height: 100%"
      >
        <tbody>
          <tr>
            <td>从业人数</td>
            <td>{{ report.collegues_num || "-" }}</td>
            <td>实际员工数量</td>
            <td>{{ report.prac_person_num || "-" }}</td>
          </tr>
          <tr>
            <td>营业总收入</td>
            <td>{{ report.sale_income || "-" }}</td>
            <td>纳税总额</td>
            <td>{{ report.tax_total || "-" }}</td>
          </tr>
          <tr>
            <td>负债总额</td>
            <td>{{ report.debit_amount || "-" }}</td>
            <td>资产总额</td>
            <td>{{ report.total_equity || "-" }}</td>
          </tr>
          <tr>
            <td>净利润</td>
            <td>{{ report.net_amount || "-" }}</td>
            <td>主营业务收入</td>
            <td>{{ report.serv_fare_income || "-" }}</td>
          </tr>
          <tr>
            <td>所有者权益合计</td>
            <td>{{ report.profit_reta || "-" }}</td>
            <td>利润总额</td>
            <td>{{ report.profit_total || "-" }}</td>
          </tr>
        </tbody>
      </table>
    </el-dialog>
  </div>
</template>

<script>
  import { getYearReport } from "@/api/workplace/companyInfo";

  export default {
    name: "CompanyYearReport",

    data() {
      return {
        flag: false,
        list: [],
        loading: false,
        total: 0,
        background: true,
        layout: "total, sizes, prev, pager, next, jumper",
        queryForm: {
          body: {
            keyword: "",
            seqId: "",
            skip: 0,
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          pageNum: 1,
          pageSize: 20,
        },
        report: {},
        obj: null,
      };
    },

    methods: {
      getYearReport(param) {
        this.loading = true;
        this.obj = param;
        this.queryForm.body.keyword = param.keyword;
        this.queryForm.body.seqId = param.seqId;

        this.queryForm.body.skip = (this.queryForm.pageNum - 1) * 20;
        getYearReport(this.queryForm)
          .then((response) => {
            if (response.header.retCode === "1") {
              this.list = response.body.items;
              this.total = response.body.total;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.getYearReport(this.obj);
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.getYearReport(this.obj);
      },
      showDetail(row) {
        this.flag = true;
        this.report = row;
      },
      close() {
        this.flag = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .info-table {
    border-collapse: collapse;
    font-size: 14px;
    color: #333;

    tr td {
      border: 1px solid #ccc;
      padding: 10px;

      &:nth-of-type(even) {
        width: 35%;
        font-weight: normal;
      }

      &:nth-of-type(odd) {
        width: 15%;
        background: #f5f7fa;
      }
    }
  }
</style>
