var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.notes, stripe: "", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.createTime || "-") + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operator",
              label: "操作人",
              width: "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.operator || "-") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "step", label: "环节", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.step || "-") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operAction",
              label: "操作",
              width: "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.operAction || "-") + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "note", label: "小记", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.note || "-") + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c("el-pagination", {
                    attrs: {
                      background: _vm.background,
                      "current-page": _vm.queryForm.pageNum,
                      layout: _vm.layout,
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }