var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { attrs: { type: "box-card" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticClass: "icon-row",
              staticStyle: { "text-align": "center" },
              attrs: { span: 24 },
            },
            [
              _c("i", {
                staticClass: "el-icon-success",
                staticStyle: {
                  "font-family": "'FontAwesome'",
                  "font-weight": "400",
                  "font-style": "normal",
                  "font-size": "100px",
                  color: "#4bd863",
                  "line-height": "120px",
                },
              }),
            ]
          ),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h3", [_vm._v(_vm._s(_vm.msg))]),
          ]),
          _c(
            "el-col",
            { staticClass: "tool-button" },
            [
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.getBack } },
                [_vm._v("重新编辑")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getIndex } },
                [_vm._v("返回列表")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }