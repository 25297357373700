import request from "@/utils/request";

export function getRouterList(data) {
  return request({
    url: "/menu/navigate",
    method: "post",
    data,
  });
}
export function getRouter(params) {
  return request({
    url: "/cbi-auth-provider/getRouters",
    method: "get",
    params,
  });
}
