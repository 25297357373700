<!--Kyle 工作台 -> 创建工单 -> 合规 本土-->
<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="createFormVisible"
    width="62%"
    :close-on-click-modal="false"
    @close="createFormClose"
  >
    <el-form
      ref="createForm"
      :model="createForm"
      :rules="createFormRules"
      label-width="80px"
      label-position="top"
    >
      <!-- 基础信息 -->
      <h3>基础信息</h3>
      <el-row :gutter="60">
        <!-- Project Name -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="Project Name" prop="projectNameNo">
            <el-input
              v-model="createForm.projectNameNo"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 项目 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="项目" prop="project">
            <el-cascader
              v-model="createForm.project"
              :options="projectOptions"
              :props="{ expandTrigger: 'hover' }"
              separator=":"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <!-- 目标主体 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标主体" prop="targetSubject">
            <el-input
              v-model="createForm.targetSubject"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 国家/省份/城市/地域（县级市） -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="国家/省份/城市/地域（县级市）" prop="block">
            <el-cascader
              v-model="createForm.block"
              :options="cityInfo"
              :change-on-select="false"
              :clearable="true"
              :filterable="true"
              placeholder="请选择地区"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <!-- 目标地址1 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标地址1" prop="targetAddress1">
            <el-input
              v-model="createForm.targetAddress1"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 目标地址2 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标地址2" prop="targetAddress2">
            <el-input
              v-model="createForm.targetAddress2"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 目标联系人 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标联系人" prop="targetPerson">
            <el-input
              v-model="createForm.targetPerson"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 联系电话 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="联系电话" prop="contactTel">
            <el-input
              v-model="createForm.contactTel"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 职务 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="职务" prop="position">
            <el-input
              v-model="createForm.position"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 下单时间 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="下单时间" prop="placeOrderTime">
            <el-date-picker
              v-model="createForm.placeOrderTime"
              type="date"
              placeholder="请选择日期"
              style="width: 100%"
              required
              clearable
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 内部截止日期 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="内部截止日期" prop="internalDeadline">
            <el-date-picker
              v-model="createForm.internalDeadline"
              type="date"
              placeholder="请选择日期"
              style="width: 100%"
              required
              clearable
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 对账ID -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="对账ID" prop="verifyID">
            <el-input
              v-model="createForm.verifyID"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- SV Code -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="SV Code" prop="svCode">
            <el-input
              v-model="createForm.svCode"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 备注1 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="备注1" prop="remark1">
            <el-input
              v-model="createForm.remark1"
              type="textarea"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 备注2 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="备注2" prop="remark2">
            <el-input
              v-model="createForm.remark2"
              type="textarea"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 备注3 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="备注3" prop="remark3">
            <el-input
              v-model="createForm.remark3"
              type="textarea"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 附加信息 -->
      <h3>附加信息</h3>
      <el-row :gutter="60">
        <!-- 预计走访日期 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="预计走访日期" prop="expectedVisitDate">
            <el-date-picker
              v-model="createForm.expectedVisitDate"
              type="date"
              placeholder="请选择日期"
              style="width: 100%"
              clearable
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 预计所需调查天数 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="预计所需调查天数" prop="expectedInvestigateDay">
            <el-input
              v-model="createForm.expectedInvestigateDay"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 所需人手 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="所需人手" prop="requiredPersonNum">
            <el-input
              v-model="createForm.requiredPersonNum"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 预计需时（小时） -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="预计需时（小时）" prop="expectedHour">
            <el-input
              v-model="createForm.expectedHour"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 注册地址 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="注册地址" prop="registAddress">
            <el-input
              v-model="createForm.registAddress"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 目标主体注册状态 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标主体注册状态" prop="targetNameStatus">
            <el-input
              v-model="createForm.targetNameStatus"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 走访收费 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="走访收费" prop="visitPrice">
            <el-input
              v-model="createForm.visitPrice"
              placeholder="请输入"
              autocomplete="off"
              show-password
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 加急走访收费 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="加急走访收费" prop="expeditedVisitPrice">
            <el-input
              v-model="createForm.expeditedVisitPrice"
              placeholder="请输入"
              autocomplete="off"
              show-password
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 再次走访收费 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="再次走访收费" prop="againVisitPrice">
            <el-input
              v-model="createForm.againVisitPrice"
              placeholder="请输入"
              autocomplete="off"
              show-password
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 收费依据 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="收费依据" prop="chargeEvidence">
            <el-input
              v-model="createForm.chargeEvidence"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 目标机构1 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标机构1" prop="targetAgency1">
            <el-input
              v-model="createForm.targetAgency1"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 目标机构2 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标机构2" prop="targetAgency2">
            <el-input
              v-model="createForm.targetAgency2"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!--  目标地址预核结果 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="目标地址预核结果" prop="targetAddressResult">
            <el-select
              v-model="createForm.targetAddressResult"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in addressPreVerify"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 不可行原因/待确认项目 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="不可行原因/待确认项目" prop="unableReason">
            <el-input
              v-model="createForm.unableReason"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 邮件抬头 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="邮件抬头" prop="emailHeader">
            <el-input
              v-model="createForm.emailHeader"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 特别留言 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="特别留言" prop="especialMsg">
            <el-input
              v-model="createForm.especialMsg"
              type="textarea"
              placeholder="请输入特别留言"
              :rows="7"
              maxlength="300"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 上传附件 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="上传附件">
            <el-upload action="#" list-type="picture-card" :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-dialog :visible.sync="AnnexDialog">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-col>
      </el-row>
      <!-- 派单信息 -->
      <h3>派单信息</h3>
      <el-row :gutter="60">
        <!-- 预约走访时间 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="预约走访时间">
            <el-date-picker
              v-model="createForm.title"
              type="datetime"
              placeholder="选择预约走访时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <!-- 调查人员 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="调查人员" prop="inspector">
            <el-input
              v-model="createForm.inspector"
              suffix-icon="el-icon-user"
              placeholder="请输入"
              clearable
              @focus="inspectorDialog = true"
            ></el-input>
          </el-form-item>
          <!-- 选择人员 -->
          <el-dialog
            title="选择人员"
            :visible.sync="inspectorDialog"
            width="40%"
            :before-close="handleClose"
          >
            <span slot="footer" class="dialog-footer">
              <el-button @click="inspectorDialog = false">取 消</el-button>
              <el-button type="primary" @click="inspectorDialog = false">
                确 定
              </el-button>
            </span>
          </el-dialog>
        </el-col>
        <!-- 是否联系目标 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="是否联系目标" prop="isContactTarget">
            <el-select
              v-model="createForm.isContactTarget"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in isContactTarget"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 督导人员 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="督导人员" prop="supervisor">
            <el-input
              v-model="createForm.supervisor"
              suffix-icon="el-icon-user"
              placeholder="请输入"
              clearable
              @focus="supervisorDialog = true"
            ></el-input>
          </el-form-item>
          <!-- 选择人员 -->
          <el-dialog
            title="选择人员"
            :visible.sync="supervisorDialog"
            width="40%"
            :before-close="handleClose"
          >
            <span slot="footer" class="dialog-footer">
              <el-button @click="supervisorDialog = false">取 消</el-button>
              <el-button type="primary" @click="supervisorDialog = false">
                确 定
              </el-button>
            </span>
          </el-dialog>
        </el-col>
        <!-- 小记 -->
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
          <el-form-item label="小记" prop="smallNote">
            <el-input
              v-model="createForm.smallNote"
              type="textarea"
              :rows="1"
              show-word-limit
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button plain size="large" @click="createFormClose">取消</el-button>
      <el-button type="success" size="large" @click="saveOrder('createForm')">
        保存
      </el-button>
      <el-button type="primary" size="large" @click="submitOrder('createForm')">
        提交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "LocalCreate",
    data() {
      // Form-vlidator 判断数字且小数点后保留两位有效数字
      const isPriceVlidator = (rule, value, callback) => {
        // var pattern = /^\d+.?\d{0,2}$/;
        let pattern = /^\d+(\.\d{1,2})?$/;
        // 可以正常留空
        if (value == null || value == undefined || value == "") {
          return callback();
        } else if (!pattern.test(value)) {
          return callback(new Error("请输入数字,并且小数点后最多只能输入两位"));
        } else return callback();
      };
      // Form-vlidator 判断项目名称必填
      const projectValidator = (rule, value, callback) => {
        if (this.createForm.project.length == 0) {
          callback(new Error("请选择项目名称"));
        } else {
          callback();
        }
      };
      return {
        dialogImageUrl: "",
        dialogTitle: "",
        createFormVisible: false,
        AnnexDialog: false,
        supervisorDialog: false,
        inspectorDialog: false,
        disabled: false,
        title: [],
        mode: "transfer",

        cityForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        cityInfo: [],
        // 项目名称
        createForm: {
          projectNameNo: "",
          project: "",
          projectType: "",
          targetSubject: "",
          targetPerson: "",
          contactTel: "",
          position: "",
          placeOrderTime: "",
          verifyID: "",
          // 加急走访收费
          expeditedVisitPrice: "",
          smallNote: "",
          reservationStaff: "",
        },
        createFormRules: {
          projectNameNo: [
            {
              max: 200,
              message: "Project Name不能超过200个英文",
              trigger: "blur",
            },
          ],
          targetSubject: [
            { required: true, message: "请输入目标主体", trigger: "blur" },
            {
              max: 16,
              message: "目标主体不能超过100个文字",
              trigger: "blur",
            },
          ],
          projectType: [
            { required: true, message: "请选择项目类型", trigger: "change" },
          ],
          project: [
            {
              required: true,
              message: "请选择项目名称",
              trigger: "change",
              validator: projectValidator,
            },
          ],
          block: [{ required: true, message: "请选择地区", trigger: "change" }],
          targetAddress1: [
            { required: true, message: "请输入目标地址1", trigger: "blur" },
          ],
          placeOrderTime: [
            { required: true, message: "请选择下单时间", trigger: "change" },
          ],
          internalDeadline: [
            {
              required: true,
              message: "请选择内部截止日期",
              trigger: "change",
            },
          ],
          smallNote: [
            { required: true, message: "请输入小记", trigger: "blur" },
          ],
          reservationStaff: [
            { required: true, message: "请选择预约人员", trigger: "change" },
          ],
          visitPrice: [{ validator: isPriceVlidator, trigger: "blur" }],
          expeditedVisitPrice: [
            { validator: isPriceVlidator, trigger: "blur" },
          ],
          againVisitPrice: [{ validator: isPriceVlidator, trigger: "blur" }],
        },
        block: "",
        // 项目类型
        projectType: [
          {
            value: "选项1",
            label: "雇员系统",
          },
          {
            value: "选项2",
            label: "雇员系统",
          },
        ],
        //  项目
        projectOptions: [
          {
            value: "选项1",
            label: "远程审计",
            children: [
              {
                value: "选项1",
                label: "事务所",
              },
            ],
          },
          {
            value: "选项2",
            label: "合规",
            children: [
              {
                value: "选项1",
                label: "HCO",
              },
              {
                value: "选项2",
                label: "site",
              },
            ],
          },
        ],
        // 目标地址预核结果
        addressPreVerify: [
          {
            value: "选项1",
            label: "可行",
          },
          {
            value: "选项2",
            label: "不可行",
          },
        ],
        isContactTarget: [
          {
            value: "选项2",
            label: "翻译系统",
          },
          {
            value: "选项3",
            label: "实地平台",
          },
        ],
        toData: [],
      };
    }, // 注册
    beforeDestroy() {},
    mounted() {},
    methods: {
      createFormClose() {
        // 重置表单
        this.createForm = this.$options.data().createForm;
        this.reset = this.$options.data().reset;
        this.createFormVisible = false;
      },
      // 打开合规工单
      showCreate(configInfo) {
        this.dialogTitle = "合规工单";
        // this.form = Object.assign({}, row);
        /* 优化接口 */
        this.cityInfo = configInfo.blockInfo;
        this.createFormVisible = true;
      },
      saveOrder(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.$router.push("/workplace/save");
            this.createFormVisible = false;
          } else {
            this.$message.error("保存失败，请重新检查工单数据");
            return false;
          }
        });
      },
      submitOrder(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.$router.push("/workplace/save");
            this.createFormVisible = false;
          } else {
            this.$message.error("提交失败，请重新检查工单数据");
            return false;
          }
        });
      },
      getBack() {
        this.$router.go(-1);
      },
      // 上传文件处理
      handleRemove(file) {},
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.annexDialog = true;
      },
      handleDownload(file) {},
      handleChange(value) {
        this.createForm.addressProvince = this.createForm.block[0];
        this.createForm.addressCity = this.createForm.block[1];
        this.createForm.addressDistrict = this.createForm.block[2];
      },
      // dialogo
      handleClose(done) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      },
    },
  };
</script>
