<template>
  <div>
    <!--检索模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     style="margin-bottom: 15px"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   class="demo-form-inline my-form-class">
            <el-row :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item>
                  <el-date-picker v-model="reset.updateScopeDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="更新开始日期"
                                  end-placeholder="更新结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item>
                  <el-input v-model.trim="queryForm.fileName"
                            placeholder="文档模板名称"
                            clearable
                            @keyup.enter.native="handleQuery" />
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item>
                  <el-cascader :value="queryForm.projectNo"
                               :options="projectInfo"
                               :clearable="true"
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-plus"
                     type="primary"
                     @click="handleAdd">
            创建文档模板
          </el-button>
          <el-button icon="el-icon-delete"
                     type="danger"
                     @click="handleDelete">
            批量删除
          </el-button>

          <el-table ref="tableSort"
                    v-loading="listLoading"
                    style="margin-top: 15px"
                    border
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    @selection-change="setSelectRows"
                    @sort-change="tableSortChange">
            <el-table-column type="selection"
                             width="40"></el-table-column>
            <el-table-column label="文档模板名称"
                             prop="fileName"
                             width="200">
              <template slot-scope="scope">
                <span v-html="getModelName(scope.row.fileName)"></span>
              </template>
            </el-table-column>
            <el-table-column prop="type"
                             label="项目"
                             min-width="160">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="文档模板描述"
                             prop="description"
                             min-width="200">
              <template slot-scope="scope">
                {{ scope.row.description || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="更新时间"
                             prop="updateTime"
                             sortable
                             align="center"
                             width="160"></el-table-column>
            <el-table-column label="操作"
                             width="140px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="编辑"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-edit-outline"
                             circle
                             @click="handleEdit(scope.row)"></el-button>
                </el-tooltip>

                <el-tooltip class="item"
                            effect="dark"
                            content="下载"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-download"
                             circle
                             @click="handleDownload(scope.row)"></el-button>
                </el-tooltip>

                <el-tooltip class="item"
                            effect="dark"
                            content="删除"
                            placement="top-start">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             circle
                             @click="handleDelete(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!--新增项目-->
    <add ref="add"
         @fetchData="fetchData"></add>

    <!--编辑项目-->
    <edit ref="edit"
          @fetchData="fetchData"></edit>
  </div>
</template>

<script>
import {
  returnBitTimestamp,
  returnDeadtimestamp,
  download,
} from "@/utils/index";
import { getFileList, deleteFile, downloadDocFile } from "@/api/project/manage";
// 获取工具API
import { getProjectSource } from "@/api/workplace/tools";
import { mapGetters } from "vuex";
import edit from "./components/documentModelEdit";
import add from "./components/documentModelAdd";
export default {
  name: "ComprehensiveTable",
  components: {
    add,
    edit,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      isShow: false,
      imgShow: true,
      list: [],
      imageList: [],
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      background: true,
      selectRows: "",
      elementLoadingText: "正在加载...",
      // 处理级联及日期范围控件表单重置
      reset: {
        updateScopeDate: [],
      },
      queryForm: {
        fileName: "",
        fileId: "",
        name: "",
        projectNo: "",
        pageNum: 1,
        pageSize: 10,
        updateTimeEnd: "",
        updateTimeStart: "",
      },
      queryPortForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      // 批量删除，禁用，启用，单个删除，单个启用，禁用使用的参数
      parameter: {
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
        body: [],
      },
      obj: {
        fileId: null,
        enabled: 0,
        userNo: "",
        userRoleNo: "commonRole",
      },
      projectInfo: [], // 项目
      projectForm: {
        body: {
          source: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
    }),
  },

  created() {
    this.obj.userNo = this.userNo;
    // 列表查询
    this.fetchData();
    // 获取项目
    this.getProject();
  },
  methods: {
    // 高级筛选重置
    resetForm(formName) {
      if (this.$refs[formName] !== undefined) {
        this.queryForm = this.$options.data().queryForm;
        this.reset = this.$options.data().reset;
      }
    },
    tableSortChange() {
      const imageList = [];
      this.$refs.tableSort.tableData.forEach((item) => {
        imageList.push(item.img);
      });
      this.imageList = imageList;
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    // 获取项目
    getProject() {
      this.projectForm.header.currentTime = returnBitTimestamp(new Date());
      getProjectSource(this.projectForm).then((response) => {
        if (response.header.retCode === "1") {
          this.projectInfo = response.body;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取项目失败，请重新刷新页面`
          );
        }
      });
    },
    // 选择项目
    handleProjectChange(value) {
      if (value.length === 0) {
        this.queryForm.projectNo = "";
      } else {
        this.queryForm.projectNo = value[1];
      }
    },
    handleAdd() {
      this.$refs["add"].showAdd(this.projectInfo, "add", null);
    },
    handleEdit(row) {
      this.$refs["add"].showAdd(this.projectInfo, "edit", row);
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    handleQuery() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      // 当前系统时间
      this.queryPortForm.header.currentTime = returnBitTimestamp(new Date());
      // 日期范围
      if (this.reset.updateScopeDate == null) {
        this.queryForm.updateTimeStart = "";
        this.queryForm.updateTimeEnd = "";
      } else if (this.reset.updateScopeDate.length !== 0) {
        this.queryForm.updateTimeStart =
          this.reset.updateScopeDate.length === 2
            ? returnBitTimestamp(this.reset.updateScopeDate[0])
            : "";
        this.queryForm.updateTimeEnd =
          this.reset.updateScopeDate.length === 2
            ? returnDeadtimestamp(this.reset.updateScopeDate[1])
            : "";
      }
      Object.assign(this.queryPortForm.body, this.queryForm);
      getFileList(this.queryPortForm)
        .then((response) => {
          if (response.header.retCode === "1") {
            this.list = response.body.records;
            this.total = response.body.total;
          }
          setTimeout(() => {
            this.listLoading = false;
          }, 500);
        })
        .catch({});
    },

    // 单个删除 & 批量删除
    handleDelete(row) {
      if (row.fileId) {
        this.obj.fileId = row.fileId;
        this.parameter.body.push({ ...this.obj, projectNo: row.projectNo });
        this.$baseConfirm("你确定要删除当前项吗", null, async () => {
          const { header } = await deleteFile(this.parameter);
          if (header.retCode === "1") {
            this.$baseMessage(header.retMessage, "success");
          } else {
            this.$baseMessage(header.retMessage, "error");
          }
          this.parameter.body = [];
          this.fetchData();
        });
      } else {
        if (this.selectRows.length > 0) {
          this.selectRows.map((item) => {
            (this.obj.fileId = item.fileId),
              this.parameter.body.push(
                Object.assign({}, this.obj, {
                  projectNo: item.projectNo,
                })
              );
          });
          this.$baseConfirm("你确定要删除选中项吗", null, async () => {
            const { header } = await deleteFile(this.parameter);
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.parameter.body = [];
            await this.fetchData();
          });
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      }
    },

    // 下载按钮操作
    async handleDownload(row) {
      download(`/site/templateFile/download?projectNo=${row.projectNo}`);
    },

    getModelName(str) {
      return str.replace(/\n/g, "<br/>");
    },
  },
};
</script>
<style scoped>
.my-form-class {
  margin-bottom: -15px !important;
}
</style>
