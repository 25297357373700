<template>
  <div>
    <el-table v-loading="listLoading" :data="notes" stripe border>
      <el-table-column prop="createTime" label="时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime || "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="operator"
        label="操作人"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.operator || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="step" label="环节" align="center">
        <template slot-scope="scope">
          {{ scope.row.step || "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="operAction"
        label="操作"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.operAction || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="note" label="小记" align="center">
        <template slot-scope="scope">
          {{ scope.row.note || "-" }}
        </template>
      </el-table-column>
    </el-table>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNum"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getNoteList } from "@/api/workplace/tools";
  export default {
    name: "Remark",
    props: {
      // eslint-disable-next-line vue/require-default-prop
      param: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: null,
      },
    },
    data() {
      return {
        background: true,
        total: 0,
        layout: "total, sizes, prev, pager, next, jumper",
        listLoading: true,
        queryForm: {
          pageNum: 1,
          pageSize: 10,
        },
        notes: [],
      };
    },
    created() {},
    methods: {
      async fetchData() {
        this.listLoading = true;
        const { body } = await getNoteList(this.param);
        this.notes = body.records;
        this.total = body.total;
        this.listLoading = false;
      },
      handleSizeChange(val) {
        this.param.body.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.param.body.pageNum = val;
        this.fetchData();
      },
    },
  };
</script>

<style scoped></style>
