var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", { staticClass: "personCenter-title" }, [
            _vm._v("我的个人中心"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "user-profile" },
        [
          _c(
            "div",
            { staticClass: "box-center" },
            [
              _c(
                "pan-thumb",
                {
                  attrs: {
                    image: _vm.ossDomain + _vm.avatar,
                    height: "100px",
                    width: "100px",
                    hoverable: false,
                  },
                },
                [
                  _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _vm._v("活力满满"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "box-center" }, [
            _c("div", { staticClass: "user-name text-center" }, [
              _vm._v(_vm._s("你好, " + _vm.userName)),
            ]),
          ]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.imagecropperShow = true
                },
              },
            },
            [_vm._v(" 点击更换头像 ")]
          ),
        ],
        1
      ),
      _c("image-cropper", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imagecropperShow,
            expression: "imagecropperShow",
          },
        ],
        key: _vm.imagecropperKey,
        attrs: {
          "user-no": _vm.userNo,
          width: 300,
          height: 300,
          "no-rotate": false,
          url: _vm.ossDomain,
        },
        on: { close: _vm.close, "crop-upload-success": _vm.cropSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }