<template>
  <div style="background: #f6f8f9">
    <div style="margin-bottom: 10px">{{ companyName }}</div>
    <el-tabs type="border-card"
             :tab-position="tabPosition"
             @tab-click="handleClick">
      <el-tab-pane label="工单详情"
                   class="edit_title">
        <el-card class="box-card">
          <!--工单进度-->
          <order-process :param="param"></order-process>
          <!--基础信息-->
          <div class="title">基础信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="workOrderNo"
                             label="工单编号"
                             align="center"></el-table-column>
            <el-table-column prop="brWorkNo"
                             label="订单编号"
                             align="center"></el-table-column>
            <el-table-column prop="reportType"
                             label="报告类型"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportType || "-" }}
                {{ scope.row.subReportType ? '/' : ''}}
                {{ scope.row.subReportType}}{{scope.row.subReportType|| "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="projectNameNo"
                             label="Project Name"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.projectNameNo || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="projectName"
                             label="项目"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetSubject || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="address"
                             label="国家/省份/城市/地域（县级市）"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetAddr1"
                             label="目标地址1"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr1 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetAddr2"
                             label="目标地址2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr2 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="targetContact"
                             label="目标联系人"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetContact || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="contactNumber"
                             label="联系电话"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.contactNumber || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="duty"
                             label="职务"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.duty || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="orderTime"
                             :formatter="Minutetamp"
                             label="下单时间"
                             align="center"></el-table-column>
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             align="center"
                             :formatter="eighthBitTime"></el-table-column>
            <el-table-column prop="clientFullName"
                             label="委托方全称"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.clientFullName || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>

            <el-table-column prop="clientAbbr"
                             label="委托方简称"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.clientAbbr || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="reportTimeLimit"
                             label="报告时限"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportTimeLimit || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark1"
                             label="备注1"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark1 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="remark2"
                             label="备注2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark3"
                             label="备注3"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark3 || "-" }}
              </template>
            </el-table-column>
            <el-table-column></el-table-column>
          </el-table>
          <!--附加信息-->
          <div class="title">附加信息</div>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectVisitDate"
                             label="预计走访日期"
                             :formatter="eighthBitTime"
                             align="center"></el-table-column>
            <el-table-column prop="expectServeyDay"
                             label="预计所需调查天数"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectServeyDay || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="needUser"
                             label="所需人手"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.needUser || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectHour"
                             label="预计需时（小时）"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectHour || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="registerAddress"
                             label="注册地址"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.registerAddress || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubjectRegStatus"
                             label="目标主体注册状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetSubjectRegStatus || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="visitFee"
                             label="走访收费"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.visitFee || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="urgentVisitFee"
                             label="加急走访收费"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.urgentVisitFee || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="againVisitFee"
                             label="再次走访收费"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.againVisitFee || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="feeBasis"
                             label="收费依据"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetOrganization1"
                             label="目标机构1"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetOrganization1 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetOrganization2"
                             label="目标机构2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetOrganization2 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="targetResult"
                             label="目标地址预核结果"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetResult || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="infeasibleReason"
                             label="不可行原因/待确认项目"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.infeasibleReason || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="mailHeader"
                             label="邮件抬头"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.mailHeader || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <!--特别信息-->
          <div class="title">特别信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="plSpecialMsg"
                             label="PL特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.plSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="bookSpecialMsg"
                             label="预约特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.bookSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="monitorSpecialMsg"
                             label="督导特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.monitorSpecialMsg || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--附件-->
          <div v-show="annexGroup.length !== 0"
               class="title">附件</div>

          <el-row style="text-align: center; margin-top: 20px">
            <div v-for="(item, index) in annexGroup"
                 :key="index">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="6">
                <a @click.stop.prevent="
                    handleDownload({ name: item.fileName, path: item.path })
                  ">
                  <img src="@/icon/annex.svg"
                       alt=""
                       width="80"
                       height="80" />
                  <p>{{ item.fileName }}</p>
                </a>
              </el-col>
            </div>
          </el-row>

          <!--操作信息-->
          <div class="title">操作信息</div>
          <el-table :data="operationInfo"
                    stripe
                    border>
            <el-table-column prop="operator"
                             label="操作者"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operator || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="role"
                             label="角色"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.role || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="operAction"
                             label="操作状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operAction || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime"
                             label="操作时间"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.createTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark"
                             label="备注"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <el-row style="text-align: center; margin-top: 20px">
            <el-col :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="24"
                    :xl="24">
              <el-dropdown class="adjustPerson"
                           @command="adjustStaff">
                <el-button type="primary"
                           size="large"
                           icon="el-icon-s-custom">
                  调整人员
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="bookUser">
                    调整预约
                  </el-dropdown-item>
                  <el-dropdown-item command="part_surveyUser">
                    调整兼职调查员
                  </el-dropdown-item>
                  <el-dropdown-item command="full_surveyUser">
                    调整全职调查员
                  </el-dropdown-item>
                  <el-dropdown-item command="supervisor">
                    调整督导
                  </el-dropdown-item>
                  <el-dropdown-item command="audit">调整内审</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- 调整人员 -->
              <el-dialog :title="staffTitle"
                         :visible.sync="openAdjustStaff"
                         width="30%"
                         :before-close="adjustStaffClose">
                <div style="
                    text-align: center;
                    padding-left: 25px;
                    padding-right: 25px;
                  ">
                  <el-form ref="adjustFrom"
                           :model="adjustFrom">
                    <el-form-item :label="staffTitle"
                                  prop="staffValue">
                      <el-select v-model="adjustFrom.staffValue"
                                 :multiple="false"
                                 :multiple-limit="multipleLimit"
                                 placeholder=""
                                 filterable
                                 style="width: 260px">
                        <el-option v-for="item in staffData"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
                <span slot="footer"
                      class="dialog-footer">
                  <el-button @click="adjustStaffClose">取 消</el-button>
                  <el-button type="primary"
                             @click="staffConfirm('adjustFrom')">
                    确 定
                  </el-button>
                </span>
              </el-dialog>
            </el-col>
          </el-row>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="取证资料"
                   name="evidence">
        <evidence v-if="object.projectSign == null"
                  ref="evidence"
                  :row="object"></evidence>
        <evidence-history v-else
                          ref="evidenceHistory"
                          :row="object"></evidence-history>
      </el-tab-pane>
      <el-tab-pane label="拍摄照片"
                   name="photo">
        <photo ref="photo"
               :type="'50'"
               :row="object"></photo>
      </el-tab-pane>
      <el-tab-pane label="拍摄录像"
                   name="screenVideo">
        <screenVideo ref="screenVideo"
                     :type="'51'"
                     :row="object"></screenVideo>
      </el-tab-pane>
      <el-tab-pane label="扫描文件"
                   name="scanFile">
        <scanFile ref="scanFile"
                  :type="'52'"
                  :row="object"></scanFile>
      </el-tab-pane>
      <el-tab-pane label="录制音频"
                   name="screenAudio">
        <screenAudio ref="screenAudio"
                     :type="'53'"
                     :row="object"></screenAudio>
      </el-tab-pane>
      <el-tab-pane label="其他文件"
                   name="telRecord">
        <telRecord ref="telRecord"
                   :type="'54'"
                   :row="object"></telRecord>
      </el-tab-pane>
      <el-tab-pane label="打卡记录"
                   name="clockIn">
        <clockIn ref="clockIn"
                 :type="'55'"
                 :row="object"></clockIn>
      </el-tab-pane>
      <el-tab-pane label="会议信息"
                   name="meeting">
        <meeting ref="meeting"
                 :type="'57'"
                 :row="object"></meeting>
      </el-tab-pane>
      <el-tab-pane label="查看小记"
                   name="remark">
        <remark ref="remark"
                :param="param"></remark>
      </el-tab-pane>
      <el-tab-pane label="退回记录"
                   name="back">
        <back ref="back"
              :order="orderNo"></back>
      </el-tab-pane>
      <el-tab-pane label="标注记录"
                   name="labelRecord">
        <labelRecord ref="labelRecord"
                     :type="'56'"
                     :row="object"></labelRecord>
      </el-tab-pane>
      <el-tab-pane label="企业信息"
                   name="company">
        <company ref="company"
                 :row="object"></company>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import orderProcess from "@/views/workplace/components/orderProcess"
//  pl工作台列表处理基本信息查询
import {
	// 获取基本信息回显
	getEditList,
} from "@/api/workplace/orderCreate"
// 获取工具API
import {
	// 角色
	getRole,
	// 附件查询
	searchAttach,
	queryRolesByUserNo,
	getReportType,
} from "@/api/workplace/tools"
// 转化当前时间格式
import {
	returnBitTimestamp,
	parseTime,
	eighthBitTimestamp,
	returnMinutetamp,
	downloadFile,
	isExistOSS,
} from "@/utils"
// 取证资料
import evidence from "@/views/workplace/components/evidence"
import evidenceHistory from "@/views/workplace/components/evidenceHistory"
// 拍摄照片
import photo from "@/views/workplace/components/photo"
// 拍摄录像
import screenVideo from "@/views/workplace/components/screenVideo"
// 扫描文件
import scanFile from "@/views/workplace/components/scanFile"
// 录制音频
import screenAudio from "@/views/workplace/components/screenAudio"
// 其他文件
import telRecord from "@/views/workplace/components/telRecord"
// 打卡记录
import clockIn from "@/views/workplace/components/clockIn"
// 会议信息
import meeting from "@/views/workplace/components/meeting"
// 查看小记
import remark from "@/views/workplace/components/remark"
// 退回记录
import back from "@/views/workplace/components/back"
// 标注记录
import labelRecord from "@/views/workplace/components/labelRecord"
// 企业信息
import company from "@/views/workplace/components/company"
import { getOperationInfo } from "@/api/workplace/investigate/investigate"
import { adjustPerson } from "@/api/workplace/commonOperation"
import { mapGetters } from "vuex"
export default {
	name: "Detail",
	components: {
		evidence,
		evidenceHistory,
		photo,
		screenVideo,
		scanFile,
		screenAudio,
		telRecord,
		clockIn,
		meeting,
		remark,
		back,
		labelRecord,
		company,
		orderProcess,
	},
	data() {
		return {
			fullTitle: "最大化",
			fullIcon: "el-icon-full-screen",
			annexGroup: [],
			// 附件
			queryAttach: {
				body: {
					workOrderNo: "",
				},
				header: {
					currentTime: "",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
			},
			orderNo: null,
			stepActive: "stepActive",

			param: {
				body: {
					pageNum: 1,
					pageSize: 10,
					userNo: "",
					userRoleNo: "",
					workOrderNo: "",
				},
				header: {
					currentTime: "",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
			},
			staffPortForm: {
				body: {
					userAfter: "",
					userAfterRole: "",
					workflowInfoReqList: [
						{
							console: "PL",
							nextTaskId: "",
							nextUserNo: "",
							nextUserRoleNo: "",
							note: "",
							operAction: "",
							procInsId: "",
							projectSource: "",
							remark: "",
							step: "",
							taskId: "",
							userNo: "",
							userRoleNo: "",
							workOrderNo: "",
						},
					],
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			// 进度Form
			processForm: {
				body: {
					userNo: "",
					userRoleNo: "",
					workOrderNo: "",
				},
				header: {
					currentTime: "",
					requestId: "111",
					sourceType: "site",
				},
			},
			// 工单进度
			orderProcess: {},
			// 获取基本信息回显
			queryPortForm: {
				body: {
					nextUserNo: "",
					nextUserRoleNo: "",
					userNo: "",
					userRoleNo: "",
					workOrderNo: "",
				},
				header: {
					currentTime: "",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
			},
			tabPosition: "top",
			tableData: [],
			// 操作工单日志信息
			operationInfo: [],
			tableRes: {
				workOrderNo: "",
				projectNameNo: "",
				projectName: "",
				targetSubject: "",
				address: "",
				targetAddr1: "",
				targetAddr2: "",
				targetContact: "",
				contactNumber: "",
				duty: "",
				orderTime: "",
				innerDeadline: "",
				remark1: "",
				remark2: "",
				remark3: "",
				expectVisitDate: "",
				expectServeyDay: "",
				needUser: "",
				expectHour: "",
				registerAddress: "",
				feeBasis: "",
				targetSubjectRegStatus: "",
				targetOrganization1: "",
				targetOrganization2: "",
				urgentVisitFee: "",
				againVisitFee: "",
				visitFee: "",
				infeasibleReason: "",
				targetResult: "",
				mailHeader: "",
				plSpecialMsg: "",
				bookSpecialMsg: "",
				monitorSpecialMsg: "",
			},
			rules: {
				remark: [
					{ required: true, message: "请输入小记", trigger: "blur" },
					{
						max: 500,
						message: "小记不能超过500个汉字",
						trigger: "blur",
					},
				],
			},
			roleForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			// 调整人员
			adjustFrom: {
				staffValue: [],
			},
			multipleLimit: 1,
			staffTitle: "",
			staffSearch: "",
			staffData: [],
			openAdjustStaff: false,
			// 给子组件传值
			object: {
				userNo: null,
				userRoleNo: null,
				workOrderNo: null,
				seqId: null,
			},
			companyName: null,
		}
	},
	computed: {
		...mapGetters({
			OSSClient: "user/OSSClient",
			endpoinxBak: "user/endpoinxBak",
		}),
	},

	created() {
		this.orderNo = this.$route.query.workOrderNo
		this.processForm.body.workOrderNo = this.$route.query.workOrderNo
		this.processForm.body.userNo = this.$route.query.userNo
		this.processForm.body.userRoleNo = this.$route.query.userRoleNo
		/** 基本信息回显 */
		this.queryPortForm.body.workOrderNo = this.$route.query.workOrderNo
		this.queryPortForm.body.userNo = this.$route.query.userNo
		this.queryPortForm.body.userRoleNo = this.$route.query.userRoleNo
		/** 获取工单详情 */
		this.param.body.workOrderNo = this.$route.query.workOrderNo
		this.param.body.userNo = this.$route.query.userNo
		this.param.body.userRoleNo = this.$route.query.userRoleNo
		this.param.header.currentTime = returnBitTimestamp(new Date())

		this.fetchData()

		/** 查询附件信息 */
		this.attach()

		// 获取操作工单日志信息
		this.getOperationInfo()

		//初始化调用调整人员参数
		this.staffPortForm.body.workflowInfoReqList = [
			{
				userNo: this.$route.query.userNo,
				userRoleNo: this.$route.query.userRoleNo,
				workOrderNo: this.$route.query.workOrderNo,
				workOrderStatus: this.$route.query.workOrderStatus,
				operStatus: this.$route.query.operStatus,
				procInsId: this.$route.query.procInsId,
				taskId: this.$route.query.taskId,
				surveyUser: this.$route.query.surveyUser,
			},
		]
	},
	methods: {
		// 时分下单日期格式处理
		Minutetamp(row, column) {
			var date = row[column.property]
			if (date === undefined) {
				return "-"
			}
			return returnMinutetamp(Date.parse(date))
		},
		// 时分下单日期格式处理
		eighthBitTime(row, column) {
			var date = row[column.property]
			if (date === undefined || date === null) {
				return "-"
			}
			return eighthBitTimestamp(Date.parse(date))
		},
		handleClick(tab, event) {
			if (tab.name === "evidence") {
				if (this.object.projectSign !== null) {
					this.$refs["evidenceHistory"].reset()
					this.$refs["evidenceHistory"].fetchData()
				} else {
					this.$refs["evidence"].reset()
					this.$refs["evidence"].fetchData()
				}
			}
			if (tab.name === "photo") {
				this.$refs["photo"].fetchData()
			}
			if (tab.name === "screenVideo") {
				this.$refs["screenVideo"].fetchData()
			}
			if (tab.name === "scanFile") {
				this.$refs["scanFile"].fetchData()
			}
			if (tab.name === "screenAudio") {
				this.$refs["screenAudio"].fetchData()
			}
			if (tab.name === "telRecord") {
				this.$refs["telRecord"].fetchData()
			}
			if (tab.name === "clockIn") {
				this.$refs["clockIn"].fetchData()
			}
			if (tab.name === "meeting") {
				this.$refs["meeting"].fetchData()
			}
			if (tab.name === "remark") {
				this.$refs["remark"].fetchData()
			}
			if (tab.name === "back") {
				this.$refs["back"].fetchData()
			}
			if (tab.name === "labelRecord") {
				this.$refs["labelRecord"].fetchData()
			}
			if (tab.name === "company") {
				this.$refs["company"].init()
			}
		},
		async getOperationInfo() {
			this.operationInfo = (await getOperationInfo(this.param)).body
		},
		/** 查询附件信息 */
		async attach() {
			this.queryAttach.body.workOrderNo = this.$route.query.workOrderNo
			this.queryAttach.header.currentTime = returnBitTimestamp(new Date())
			const { body, header } = await searchAttach(this.queryAttach)
			if (header.retCode === "1") {
				this.annexGroup = body
			} else {
				this.$baseMessage(header.retMessage, "error")
			}
		},

		fetchData() {
			this.object.projectId = this.$route.query.projectId
			this.object.userNo = this.$route.query.userNo
			this.object.userRoleNo = this.$route.query.userRoleNo
			this.object.workOrderNo = this.$route.query.workOrderNo
			// this.object.workOrderNo = "202009210058";
			this.object.seqId = this.$route.query.seqId
			this.object.targetSubject = this.$route.query.target
			this.object.endpoinxBak = this.endpoinxBak
			this.object.OSSClient = this.OSSClient
			this.object.projectSign = this.$route.query.projectSign
			this.object = Object.assign(
				this.object,
				{ workplace: "PL" },
				{ flag: "detail" }
			)
			this.listLoading = true
			this.queryPortForm.header.currentTime = returnBitTimestamp(new Date())
			getEditList(this.queryPortForm).then((response) => {
				if (response.header.retCode === "1") {
					let _arr = []
					this.tableRes = response.body
					/** 回显返回部分数据格式为code形式，需要再次调用接口，现优化由路由传递 */
					this.tableRes.projectName = this.$route.query.projectName
					_arr.push(this.tableRes)
					this.tableData = _arr
					this.companyName = _arr[0].targetSubject
				}
			})
			setTimeout(() => {
				this.listLoading = false
			}, 500)
		},
		// 获取角色配置项 [不包括经理]
		getRoleAdjustOption(role) {
			this.roleForm.header.currentTime = returnBitTimestamp(new Date())
			switch (role) {
				// 不包括经理
				// 预约人员
				case "bookUser":
					this.roleForm.body = [
						{
							roleKey: "book_person",
						},
					]
					break
				// 调查人员
				case "part_surveyUser":
					this.roleForm.body = [
						{
							roleKey: "part_investigator",
						},
					]
					break
				// 调查人员
				case "full_surveyUser":
					this.roleForm.body = [
						{
							roleKey: "full_investigator",
						},
					]
					break
				// 督导人员
				case "supervisor":
					this.roleForm.body = [
						{
							roleKey: "supervisor",
						},
					]
					break
				// 内审人员
				case "audit":
					this.roleForm.body = [
						{
							roleKey: "verify_people",
						},
					]
					break
				// 用户
				case "user":
					this.roleForm.body = [
						{
							roleKey: "customer",
						},
					]
					break
				default:
					break
			}
			getRole(this.roleForm).then((response) => {
				if (response.header.retCode === "1") {
					this.staffData = response.body
					this.staffPortForm.body.userAfterRole = this.roleForm.body
						.map((item) => {
							return item.roleKey
						})
						.join(",")
				}
			})
		},
		// 判断调整人员
		async judgeAdjust(person) {
			let selectRows = this.staffPortForm.body.workflowInfoReqList
			let adjustOk = []
			let adjustNo = []
			let surverUsers = []
			let userRole = []
			switch (person) {
				// 调整预约
				case "bookUser":
					selectRows.forEach((item) => {
						if (item.workOrderStatus === "2" || item.workOrderStatus === "11") {
							adjustOk.push(item.workOrderNo)
						} else {
							adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整全职调查员
				case "full_surveyUser":
					userRole = "full"
					selectRows.forEach((item) => {
						if (item.workOrderStatus === "4" || item.workOrderStatus === "5") {
							adjustOk.push(item.workOrderNo)
							surverUsers.push(item.surveyUser)
						} else {
							adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整兼职调查员
				case "part_surveyUser":
					userRole = "part"
					selectRows.forEach((item) => {
						if (item.workOrderStatus === "4" || item.workOrderStatus === "5") {
							adjustOk.push(item.workOrderNo)
							surverUsers.push(item.surveyUser)
						} else {
							adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整督导
				case "supervisor":
					selectRows.forEach((item) => {
						if (item.workOrderStatus === "3" || item.workOrderStatus === "6") {
							adjustOk.push(item.workOrderNo)
						} else {
							adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整内审
				case "audit":
					selectRows.forEach((item) => {
						if (item.workOrderStatus === "7") {
							adjustOk.push(item.workOrderNo)
						} else {
							adjustNo.push(item.workOrderNo)
						}
					})
					break
				default:
					break
			}
			let msg = "1"
			if (adjustNo.length === 0) {
				if (surverUsers.length != 0) {
					for (let i = 0; i < surverUsers.length; i++) {
						let userForm = {}
						userForm.body = surverUsers[i]
						//查询原调查人角色列表
						let { header, body } = await queryRolesByUserNo(userForm)
						if (header.retCode === "1") {
							//角色列表中是否于本次调正一致
							let tempArr = body.filter((e) => e.roleKey.startsWith(userRole))
							if (tempArr.length == 0) {
								msg =
									userRole === "part"
										? "全职调查不能调整给兼职人员调查"
										: "兼职调查不能调整给全职人员调查"
								break
							}
						}
					}
				}
				if (msg != "1") {
					this.$message({
						dangerouslyUseHTMLString: true,
						showClose: true,
						duration: 10000,
						message: `<br>${msg}:</br>
                        <br>无法调整的工单: ${adjustOk.join(",")};</br>
                      `,
						type: "warning",
					})
					return
				}
				this.openAdjustStaff = true
			} else {
				this.$message({
					dangerouslyUseHTMLString: true,
					showClose: true,
					duration: 10000,
					message: `<br>以下工单无法调整人员:</br>
                      <br>无法调整的工单: ${adjustNo.join(",")};</br>
                    `,
					type: "warning",
				})
			}
		},
		// 调整人员
		//调整人员对话框
		adjustStaff(command) {
			this.judgeAdjust(command)
			if ("bookUser" === command) {
				this.staffTitle = "调整预约"
				this.staffSearch = "请输入预约人员名称"
				this.getRoleAdjustOption("bookUser")
				this.multipleLimit = 1
			} else if ("full_surveyUser" === command) {
				this.staffTitle = "调整调查员"
				this.staffSearch = "请输入调查员名称"
				this.getRoleAdjustOption("full_surveyUser")
				this.multipleLimit = 0
			} else if ("part_surveyUser" === command) {
				this.staffTitle = "调整调查员"
				this.staffSearch = "请输入调查员名称"
				this.getRoleAdjustOption("part_surveyUser")
				this.multipleLimit = 0
			} else if ("supervisor" === command) {
				this.staffTitle = "调整督导"
				this.staffSearch = "请输入督导人员名称"
				this.getRoleAdjustOption("supervisor")
				this.multipleLimit = 1
			} else if ("audit" === command) {
				this.staffTitle = "调整内审"
				this.staffSearch = "请输入内审人员名称"
				this.getRoleAdjustOption("audit")
				this.multipleLimit = 1
			}
			// this.openAdjustStaff = true;
		},
		staffConfirm(formData) {
			this.openAdjustStaff = false
			this.staffPortForm.body.userAfter = this.adjustFrom.staffValue
			this.staffPortForm.body.workflowInfoReqList.map((item) => {
				item.userRoleNo = this.staffPortForm.body.userAfterRole
				return item
			})
			adjustPerson(this.staffPortForm).then((res) => {
				if (res.header.retCode === "1") {
					this.$message({
						message: `${res.header.retMessage}`,
						type: "success",
					})
				} else {
					this.$message.error(`${res.header.retMessage}`)
				}
			})
			/** 避免重置后出现选择器不期望的数据格式 */
			this.$refs[formData].resetFields()
			// this.fetchData();
		},
		// 调整人员窗口关闭
		adjustStaffClose() {
			// this.staffValue = [];
			/** 避免重置后出现选择器不期望的数据格式 */
			this.$refs["adjustFrom"].resetFields()
			this.openAdjustStaff = false
		},
		/** 附件下载 */
		handleDownload(item) {
			isExistOSS(this.OSSClient, item.path)
				.then(() => {
					downloadFile(item)
				})
				.catch(() => {
					this.$message.error("当前文件不存在！")
				})
		},
	},
}
</script>

<style>
body {
	font-weight: 700;
	color: #666;
}
.edit_title .title {
	margin: 10px 0;
}
.el-step__title {
	font-size: 14px;
}
.el-step__title.is-process {
	font-weight: bold;
	color: #666;
}
html body .el-table th .cell {
	font-weight: 700;
	color: #666;
}

.el-form-item__label {
	font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100% !important;
}

.el-select,
.el-cascader {
	width: 100%;
}

.myDiv {
	width: 150px;
	height: 150px;
	text-align: center;
}

.myDiv img {
	width: 130px;
	height: 130px;
	text-align: center;
}
</style>
