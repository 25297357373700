<!--手机预览-->
<template>
  <el-dialog
    title="预览"
    :visible.sync="phoneOpen"
    width="50%"
    :close-on-click-modal="false"
    append-to-body
    :before-close="getBack"
  >
    <el-collapse
      v-for="(item, index) in formTemplate"
      :key="index"
      v-model="activeNames"
    >
      <el-collapse-item :title="item.label" :name="index">
        <VueDragFormBuild :form-template="item" :models="models" />
      </el-collapse-item>
    </el-collapse>

    <div slot="footer" class="dialog-footer">
      <el-button @click="getBack">返 回</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "PhonePreview",
    data() {
      return {
        models: {},
        formTemplate: [],
        phoneOpen: false,
        activeNames: [0],
      };
    },
    created() {},
    methods: {
      preview(data) {
        this.activeNames.push(0);
        this.phoneOpen = true;
        this.formTemplate = data;

        this.reloadCreate = true;
      },
      getBack() {
        this.phoneOpen = false;
        this.models = {};
        this.formTemplate = [];
        this.activeNames = [];
      },
    },
  };
</script>
<style>
  .el-collapse-item__header {
    font-weight: 700;
    height: auto !important;
    line-height: 24px;
  }
</style>
