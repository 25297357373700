<!-- 图片预览Dialog-->
<template>
  <!-- 图片预览 -->
  <el-dialog :visible.sync="dialogImgVisible"
             append-to-body
             class="imgDialog">
    <el-tabs v-model="activeTabsValue"
             :tab-position="tabPosition"
             :destroy-on-close="true"
             style="height: 350px">
      <el-tab-pane v-for="(photoItem, photoIndex) in photoData"
                   :key="photoIndex"
                   :name="photoItem.name"
                   :stretch="true"
                   :label="
          photoItem.name.length > 12
            ? photoItem.name.substring(0, 12) + '...'
            : photoItem.name
        ">
        <el-row>
          <el-col>
            <el-carousel ref="imgCarousel"
                         trigger="click"
                         height="350px"
                         :initial-index="pictureIndex"
                         indicator-position="none"
                         :autoplay="false">
              <el-carousel-item v-for="(subItem, subIndex) in photoItem.siteMediaResource"
                                :key="subItem"
                                :name="subIndex">
                <el-row>
                  <el-col style="text-align: center">
                    <el-tooltip :content="subItem.name"
                                placement="top"
                                effect="light">
                      <p style="width: 95%; margin: 0 auto; text-align: center">
                        {{
                          subItem.name +
                          "." +
                          subItem.path.split('?')[0].substring(
                            subItem.path.lastIndexOf(".") + 1
                          )
                        }}
                      </p>
                    </el-tooltip>
                    <div v-if="judgeFileType(subItem.path)">
                      <img :src="ossPath + subItem.path"
                           style="max-height: 270px"
                           @click="
                          handleViewerShow(
                            subIndex,
                            photoItem.siteMediaResource
                          )
                        " />
                      <viewer v-show="false"
                              :images="imagesList"
                              @inited="inited">
                        <img v-for="item in imagesList"
                             :key="item"
                             :src="ossPath + item.path"
                             :alt="
                            item.name +
                            '.' +
                            item.path.substring(item.path.lastIndexOf('.') + 1)
                          "
                             style="max-height: 270px" />
                      </viewer>
                    </div>
                    <!-- 图片 ——>不可预览 -->
                    <img v-else
                         style="width: 160px; height: 160px; cursor: pointer"
                         src="@/icon/pic.svg"
                         :fit="fit"
                         :title="photoItem.name"
                         @click="handleDownload(subItem)" />
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { downloadFile, isExistOSS } from "@/utils/index";
export default {
  name: "PhotoView",
  data() {
    return {
      photoData: "",
      tabPosition: "left",
      dialogImgVisible: false,
      fit: "fill",
      activeTabsValue: "2",
      img_index: 0,
      photo_index: 0,
      tab_name: "",
      ossPath: "",
      OSSClient: {},
      // 当前展示的相册数据
      imagesList: [],
      // 相册索引
      albumIndex: null,
      // 当前图片索引
      pictureIndex: null,
    };
  },
  created() {},
  methods: {
    /** 判断文件类型 */
    judgeFileType(filePath) {
      /** 可预览的文件类型 */
      const fileType = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      const itemType = filePath
        .split("?")[0]
        .substring(filePath.lastIndexOf(".") + 1);
      return fileType.includes(itemType);
    },
    /** 点击下载 */
    handleDownload(item) {
      isExistOSS(this.OSSClient, item.path)
        .then(() => {
          downloadFile(item);
        })
        .catch(() => {
          this.$message.error("当前文件不存在！");
        });
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    /** 打开viewer */
    handleViewerShow(idx, arr) {
      this.$viewer.index = idx;
      this.imagesList = arr;
      this.$viewer.show();
    },
    showView() {
      this.dialogImgVisible = true;
    },
    bindData(photoData, tab_name, photo_index, img_index, ossPath, OSSClient) {
      this.imagesList = photoData[photo_index].siteMediaResource;
      this.ossPath = ossPath;
      this.OSSClient = OSSClient;
      this.activeTabsValue = tab_name;
      this.photoData = photoData;
      this.albumIndex = photo_index;
      this.pictureIndex = img_index;
      this.$nextTick(() => {
        this.$refs["imgCarousel"][this.albumIndex].setActiveItem(
          this.pictureIndex
        );
      });
    },
  },
};
</script>

<style scoped>
.imgDialog /deep/.el-dialog__header {
  padding: 0 20px;
}
.imgDialog /deep/.el-dialog__body {
  padding-top: 40px;
}
.el-carousel__item.is-animating {
  transition: none !important;
}
</style>
<style>
.viewer-container {
  z-index: 9999 !important;
}
</style>
