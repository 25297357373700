<template>
  <el-dialog :title="title"
             :visible.sync="dialogFormVisible"
             :close-on-click-modal="false"
             width="500px"
             @close="close">
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-width="120px">
      <el-form-item v-if="title === '编辑文档模板'"
                    label="文档模板名称"
                    prop="fileName">
        <el-input v-model="form.fileName"
                  type="textarea"
                  :autosize="{ minRows:1, maxRows: 2}"
                  placeholder="请输入文档模板名称"
                  disabled></el-input>
      </el-form-item>
      <el-form-item label="上传文档模板"
                    prop="path">
        <el-upload ref="upload"
                   class="upload-demo"
                   :action="endpoinxBak"
                   accept=".docx,.xlsx,.xls"
                   :on-error="handleError"
                   :on-remove="handleRemove"
                   :on-change="handelChange"
                   :before-upload="beforeUpload"
                   :http-request="fnUploadRequest"
                   :multiple="true"
                   :limit="2"
                   :on-exceed="handleExceed"
                   :file-list="fileList">
          <el-button size="small"
                     type="primary">点击上传</el-button>
          <label slot="tip"
                 class="el-upload__tip">
            请选择文件上传，支持docx/xlsx/xls文件
          </label>
        </el-upload>
      </el-form-item>
      <el-form-item label="项目"
                    prop="projectNo">
        <el-cascader v-model="form.projectNo"
                     :options="projectInfo"
                     :clearable="true"
                     :filterable="true"
                     :disabled="projectDisable"
                     separator=":"
                     placeholder="项目"
                     @change="handleProjectChange"></el-cascader>
      </el-form-item>
      <el-form-item label="文档模板描述"
                    prop="description">
        <el-input v-model="form.description"
                  type="textarea"
                  placeholder="请描述模板"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary"
                 :loading="loading"
                 @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
const OSS = require("ali-oss");
const documentPath = "cbi-site-front/project/document/";

import { createFile, updateFile } from "@/api/project/manage";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Add",
  data() {
    return {
      loading: false,
      fileList: [],
      files: [],
      fileNameList: [],
      form: {
        description: "",
        fileName: "",
        path: "",
        projectNo: "",
        userNo: "",
        userRoleNo: "commonRole",
      },
      formPort: {
        body: [],
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      formEditPort: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 项目级联选择器禁用标识
      projectDisable: false,
      rules: {
        projectNo: [
          { required: true, trigger: "blur", message: "请输入项目名称" },
        ],
        path: [
          {
            required: true,
            trigger: "blur",
            message: "请上传文件",
            validator: (rule, value, callback) => {
              // 非空校验
              if (this.form.path == "") {
                return callback(new Error("请选择项目"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      title: "",
      dialogFormVisible: false,
      projectInfo: [], // 项目
      projectForm: {
        body: {
          source: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      row: {},
      objectType: {
        docx: "word",
        xlsx: "excel",
        xls: "excel",
      },
      seltFiles: [],
      messageMod: null,
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
      accessKeyId: "user/accessKeyId",
      accessKeySecret: "user/accessKeySecret",
      bucketName: "user/bucketName",
      endpoint: "user/endpoint",
      endpoinxBak: "user/endpoinxBak",
      region: "user/region",
      policy: "user/policy",
      signature: "user/signature",
    }),
  },
  created() {
    this.form.userNo = this.userNo;
  },
  methods: {
    showAdd(projectInfo, type, row) {
      if (type === "add") {
        this.title = "创建文档模板";
        this.projectDisable = false;
      } else {
        this.title = "编辑文档模板";
        this.form = Object.assign(this.form, row);
        this.row = row;
        if (row.isExistOrder) {
          this.projectDisable = true;
        } else {
          this.projectDisable = false;
        }
      }
      /** 防止多次调用项目接口 */
      this.projectInfo = projectInfo;
      this.dialogFormVisible = true;
    },
    // 选择项目
    handleProjectChange(value) {
      if (value.length === 0) {
        this.form.projectNo = "";
      } else {
        this.form.projectNo = value[1];
      }
    },
    close() {
      this.fileList = [];
      this.files = [];
      this.fileNameList = [];
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
      // this.$emit("fetchData");
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.form.userNo = this.userNo;
          this.formPort.body = [];
          const { description, fileName, projectNo, userNo, userRoleNo } =
            this.form;

          if (this.title === "创建文档模板") {
            this.files.forEach((item) => {
              this.formPort.body.push({
                description,
                fileName,
                projectNo,
                userNo,
                userRoleNo,
                fileId: "",
                fileName: item.name,
                path: item.path,
              });
            });
            const { header } = await createFile(this.formPort);
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
          } else {
            if (this.files.length) {
              this.files.forEach((item) => {
                this.formPort.body.push({
                  description,
                  fileName,
                  projectNo: this.row.projectNo,
                  userNo,
                  userRoleNo,
                  fileId: "",
                  fileName: item.name,
                  path: item.path,
                  updateProjectNo:
                    projectNo === this.row.projectNo ? "" : projectNo,
                });
              });
            } else {
              this.formPort.body.push({
                description,
                projectNo: this.row.projectNo,
                fileName: "",
                path: "",
                userNo,
                userRoleNo,
                fileId: "",
                updateProjectNo:
                  projectNo === this.row.projectNo ? "" : projectNo,
              });
            }
            const { header } = await updateFile(this.formPort);
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
          }
          this.close();
          this.$emit("fetchData");
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    beforeUpload(file) {
      // 对当次选择的多个文件进行类型判断
      const allTypes = this.checkList(this.seltFiles);
      if (!allTypes) {
        return allTypes;
      }

      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const expSize = file.size / 1024 / 1024 < 1024;
      const isDocx = fileSuffix === "docx";
      const isXlsx = fileSuffix === "xlsx";
      const isXls = fileSuffix === "xls";
      if (isDocx || isXlsx || isXls) {
        return this.checkList(this.$refs.upload.uploadFiles);
      } else {
        this.$message({
          duration: 10000,
          showClose: true,
          message: `文档模板仅支持上传 .docx,.xlsx,.xls 格式文件`,
          type: "warning",
        });
        return false;
      }

      if (!expSize) {
        this.$message({
          duration: 10000,
          showClose: true,
          message: `文件${file.name}大小不能超过 1G, 请检查`,
          type: "warning",
        });
        return false;
      }
      return expSize && isDocx;
    },
    /** 上传失败的回调 */
    handleError(err, file, fileList) {
      this.$message.error(`上传错误,错误信息 ${err}`);
    },
    handelChange(file, fileList) {
      const files =
        this.$refs.upload.$el.querySelector(".el-upload__input").files;
      const len = files.length;
      if (len > 0) {
        this.seltFiles = [];
      }
      for (let i = 0; i < len; i++) {
        this.seltFiles.push(files[i]);
      }
    },
    checkList(fileList) {
      let isCheck = true;
      const types = {};
      fileList.forEach((file) => {
        const type = file.name.substring(file.name.lastIndexOf(".") + 1);
        const key = this.objectType[type];
        if (types[key]) {
          types[key] += 1;
        } else {
          types[key] = 1;
        }
      });
      Object.values(types).forEach((val) => {
        if (val == 2) {
          isCheck = false;
        }
      });
      if (!isCheck) {
        const self = this;
        if (!this.messageMod) {
          this.messageMod = this.$message({
            message: "上传失败,请上传1份word、1份excel",
            type: "error",
            onClose: () => {
              self.messageMod = null;
            },
          });
        }
      }
      return isCheck;
    },
    getType(name) {
      return name.substring(name.lastIndexOf(".") + 1);
    },
    /**
     * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @author   shanshuizinong
     * @param    {object}   option [上传选项]
     * @return   {null}   [没有返回]
     */
    async fnUploadRequest(option) {
      try {
        /** 带有时间戳的文件名 */
        const fileName =
          new Date().getTime() +
          "." +
          option.file.name.match(/^(.*)(\.)(.{1,8})$/)[3];
        // 获取OSS配置信息
        const uploadConfig = new FormData();
        uploadConfig.append("key", documentPath + fileName);
        uploadConfig.append("OSSAccessKeyId", this.accessKeyId);
        uploadConfig.append("policy", this.policy);
        uploadConfig.append("Signature", this.signature);
        uploadConfig.append("success_action_status", 200);
        uploadConfig.append("name", option.file.name);
        /** 表单域 file 必须为最后一个 */
        uploadConfig.append("file", option.file);
        axios
          .post(this.endpoinxBak, uploadConfig, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 200) {
              //上传成功 上传的路径就是要使用的路径
              this.files.push({
                path: documentPath + fileName,
                name: option.file.name,
              });
              this.fileNameList.push(option.file.name);

              let nameList = this.form.fileName.split("\n");
              let isHave = false;

              for (let i = 0; i < nameList.length; i++) {
                const fileType = this.objectType[this.getType(nameList[i])];
                const newFileType =
                  this.objectType[this.getType(option.file.name)];

                if (fileType === newFileType) {
                  nameList[i] = option.file.name;
                  isHave = true;
                }
              }

              if (!isHave) {
                nameList.push(option.file.name);
              }
              this.form.fileName = nameList.join("\n");
              this.form.path = "";
              this.form.path += this.files.forEach((file) => file.path);
            }
          })
          .catch((error) => {});
      } catch (error) {
        this.$message.error(`上传错误,错误信息 ${error}`);
      }
    },
    /*** 匹配返回数组索引 */
    indexOf(val, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].name == val) {
          return i;
        }
      }
      return -1;
    },
    async handleRemove(file, fileList) {
      if (this.fileNameList.indexOf(file.name) > -1) {
        const client = new OSS({
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          bucket: this.bucketName,
          region: this.region,
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: this.accessKeyId,
          accessKeySecret: this.accessKeySecret,
        });
        /** 删除oss文件 */
        // let res = await client.delete(documentPath + file.name);
        var index = this.indexOf(file.name, this.files);
        if (index > -1) {
          this.files.splice(index, 1);
          // this.fileList.splice(index, 1);
        }

        // 上传文件都删除后显示原数据
        if (!fileList.length) {
          this.form.fileName = this.row.fileName || "";
        } else {
          const oRow = {},
            oList = {};
          this.row.fileName?.split("\n").forEach((item) => {
            const key = this.objectType[this.getType(item)];
            oRow[key] = item;
          });

          this.form.fileName?.split("\n").forEach((item) => {
            if (item === file.name) return;
            const key = this.objectType[this.getType(item)];
            oList[key] = item;
          });
          Object.assign(oRow, oList);
          this.form.fileName = Object.values(oRow).join("\n");
          this.form.path = "";
          this.form.path += this.files.forEach((file) => file.path);
        }
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning("上传失败，最多只允许上传2个文件");
    },
  },
};
</script>
<style scoped>
.el-select,
.el-cascader {
  width: 100%;
}
</style>
