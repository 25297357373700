// Kyle API 调查工作台 -> 工单录入 -> 待处理
import request from "@/utils/request";

// 调查工作台查询待处理列表
export function getTodo(data) {
  return request({
    url: "/queryPendingSurveyList",
    method: "post",
    data,
  });
}
// 调查工作台查询已处理列表
export function getDone(data) {
  return request({
    url: "/queryProcessedSurveyList",
    method: "post",
    data,
  });
}
// 调查工作台获取详情
export function getOrderDetail(data) {
  return request({
    url: "/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}
// 调查工作台获取操作信息
export function getOperationInfo(data) {
  return request({
    url: "/log/query",
    method: "post",
    data,
  });
}

// 使用角色查询用户列表
export function getUsersListByRole(data) {
  return request({
    url: "/queryUsersListByRole",
    method: "post",
    data,
  });
}
// 使用角色查询用户列表
export function submit(data) {
  return request({
    url: "/commitSurvey",
    method: "post",
    data,
  });
}
// 查询退回原因
export function getReturnReason(data) {
  return request({
    url: "/queryCommentTemplateContent",
    method: "post",
    data,
  });
}
//  退回
export function withdraw(data) {
  return request({
    url: "/fallbackSurvey",
    method: "post",
    data,
  });
}
//  放弃
export function quit(data) {
  return request({
    url: "/abandonSurvey",
    method: "post",
    data,
  });
}
//  查询内审人员
export function getInternalAudit(data) {
  return request({
    url: "/queryUsersListByRole",
    method: "post",
    data,
  });
}

//  查询放弃类型
export function getQuitType(data) {
  return request({
    url: "/queryCommentTemplateType",
    method: "post",
    data,
  });
}
//  查询工单附件
export function getAttach(data) {
  return request({
    url: "/workOrderDetails/queryAttach",
    method: "post",
    data,
  });
}
//  工单详情-退回记录
export function getBackRecords(data) {
  return request({
    url: "/workOrderDetails/queryReturnReson",
    method: "post",
    data,
  });
}
//  跳过打卡
export function skipClock(data) {
  return request({
    url: "/skipClockIn",
    method: "post",
    data,
  });
}
