<!-- Kyle 打卡记录 组件 -->
<template>
  <el-card class="box-card" shadow="never">
    <!-- 头部导航 -->
    <vab-query-form>
      <vab-query-form-left-panel class="checkAllBox">
        <el-checkbox
          v-model="checkAll"
          border
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
        <el-button
          plain
          size="small"
          icon="el-icon-download"
          @click="downloadVisible = true"
        >
          下载
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <div style="margin: 15px 0"></div>
    <el-row>
      <el-col ref="photoGroup">
        <el-row>
          <el-col>
            <div class="title">走访开始</div>
          </el-col>
          <el-col style="padding: 10px">
            <div>
              <draggable
                :list="photoVisit"
                class="board-column-content"
                :options="{ sort: true }"
                @change="change"
              >
                <div
                  v-for="(subItem, subIndex) in photoVisit.filter((item) => {
                    return item.visitFlag === 0;
                  })"
                  :key="subItem.seqId"
                  class="class-detail"
                >
                  <el-image
                    v-if="subItem.visitFlag === 0"
                    style="width: 160px; height: 160px; cursor: pointer"
                    :src="row.endpoinxBak + subItem.path"
                    :fit="fit"
                    :title="subItem.name"
                    @click="handleView(photoVisit, '走访开始', 0, subIndex)"
                  ></el-image>
                  <el-checkbox
                    v-if="subItem.visitFlag === 0"
                    :key="subIndex"
                    v-model="subItem.mychecked"
                    class="img_checkbox"
                    @change="handlecheckedChange(subIndex)"
                  ></el-checkbox>
                </div>
              </draggable>
            </div>
          </el-col>
          <el-col>
            <div class="title">走访结束</div>
          </el-col>
          <el-col style="padding: 10px">
            <div>
              <draggable
                :list="photoVisit"
                class="board-column-content"
                @change="change"
              >
                <div
                  v-for="(subItem, subIndex) in photoVisit.filter((item) => {
                    return item.visitFlag === 1;
                  })"
                  v-show="subItem.visitFlag === 1"
                  :key="subItem.seqId"
                  class="class-detail"
                >
                  <el-image
                    v-if="subItem.visitFlag === 1"
                    style="width: 160px; height: 160px; cursor: pointer"
                    :src="row.endpoinxBak + subItem.path"
                    :fit="fit"
                    :title="subItem.name"
                    @click="handleView(photoVisit, '走访结束', 1, subIndex)"
                  ></el-image>
                  <el-checkbox
                    v-if="subItem.visitFlag === 1"
                    v-model="subItem.mychecked"
                    class="img_checkbox"
                    @change="handlecheckedChange(subIndex)"
                  ></el-checkbox>
                </div>
              </draggable>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 图片预览对话框 -->
    <clockInView ref="clockInView"></clockInView>
    <!-- 下载确认对话框 -->
    <el-dialog
      title="下载确认"
      :visible.sync="downloadVisible"
      width="30%"
      append-to-body
      class="downloadDialog"
    >
      <span>是否要下载所有勾选内容？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadVisible = false">取 消</el-button>
        <el-button type="primary" @click="download">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
  import { detailMediaResource } from "@/api/workplace/workDetail";
  import clockInView from "./clockInView";
  import { downloadFile } from "@/utils/index";
  import draggable from "vuedraggable";
  export default {
    name: "ClockIn",
    components: {
      clockInView,
      draggable,
    },
    props: {
      // eslint-disable-next-line vue/require-default-prop
      type: {
        type: String,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: null,
      },
      row: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        checkedPhotos: [],
        photoVisit: [],
        startVisit: [],
        endVisit: [],
        downloadVisible: false,
        fit: "fill",
        photoData: [],
        isIndeterminate: false,
        tabPosition: "left",
        parameter: {
          body: {
            pageNum: 1,
            pageSize: 10,
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "111",
            sourceType: "site",
          },
        },
      };
    },
    computed: {
      checkAll: {
        get() {
          let count = 0;
          for (let i = 0; i < this.photoVisit.length; i += 1) {
            if (this.photoVisit[i].mychecked === true) {
              count += 1;
            } else {
              count -= 1;
            }
          }
          return count === this.photoVisit.length;
        },
        set(val) {
          return val;
        },
      },
    },
    created() {},
    methods: {
      /** 下载 */
      async download() {
        let downloadArr = [];
        this.photoVisit.map((item) => {
          if (item.mychecked === true) {
            downloadFile(item);
            downloadArr.push(item.path);
          }
        });
        if (downloadArr.length === 0) {
          this.$message.error(`未选中需要下载的文件`);
        }
        this.downloadVisible = false;
      },
      handlecheckedChange(index) {
        const subData = this.photoVisit;
        let tickCount = 0;
        const len = subData.length;
        for (let i = 0; i < len; i += 1) {
          if (subData[i].mychecked === true) {
            tickCount += 1;
          } else {
            tickCount -= 1;
          }
        }
      },
      change(val) {},
      fetchData() {
        this.photoVisit = [];
        this.startVisit = [];
        this.endVisit = [];
        this.parameter.body.type = this.type;
        this.parameter.body.userNo = this.row.userNo;
        this.parameter.body.userRoleNo = this.row.userRoleNo;
        this.parameter.body.workOrderNo = this.row.workOrderNo;
        this.parameter.body.console = this.row.workplace;
        detailMediaResource(this.parameter).then((res) => {
          res.body.records.forEach((item) => {
            Object.assign(item, { mychecked: false });
            if (item.visitFlag === 0) {
              this.photoVisit.push(item);
              this.startVisit.push(item);
            } else if (item.visitFlag === 1) {
              this.photoVisit.push(item);
              this.endVisit.push(item);
            }
          });
          // visitFlag 0 走访开始
        });
      },
      // 总的全选
      handleCheckAllChange(val) {
        if (val) {
          for (let i = 0; i < this.photoVisit.length; i += 1) {
            this.photoVisit[i].mychecked = true;
            // this.firstChanged(i); // 调用一级change事件
          }
        } else {
          for (let i = 0; i < this.photoVisit.length; i += 1) {
            this.photoVisit[i].mychecked = false;
            // this.firstChanged(i); // 调用一级change事件
          }
        }
      },
      // 一级change事件
      firstChanged(index) {
        const { photoGroup } = this.photoData[index];
        if (this.photoData[index].mychecked === false) {
          photoGroup.forEach((item) => {
            this.$set(item, "mychecked", false);
          });
        } else {
          photoGroup.forEach((item) => {
            this.$set(item, "mychecked", true);
          });
        }
      },
      // 二级change事件
      secondChanged(index) {
        const subData = this.photoData[index].photoGroup;
        let tickCount = 0;
        const len = subData.length;
        for (let i = 0; i < len; i += 1) {
          if (subData[i].mychecked === true) {
            tickCount += 1;
          } else {
            tickCount -= 1;
          }
        }
      },
      // 打开预览
      handleView(photoData, photoGroupTitle, photoIndex, subIndex) {
        this.$refs["clockInView"].bindData(
          photoData,
          photoGroupTitle,
          photoIndex,
          subIndex,
          this.row.endpoinxBak
        );
        this.$refs["clockInView"].showView();
      },
    },
  };
</script>

<style scoped>
  .title {
    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-weight: 700;
    font-style: normal;
    text-align: left;
    line-height: 28px;
  }
  .class-detail {
    float: left;
    width: 160px;
    height: 160px;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  /* checkbox 浮层样式 */
  .img_checkbox {
    z-index: 999;
    position: relative;
    top: -170.5px;
    left: 0;
  }
  .checkAllBox >>> .el-checkbox.is-bordered.el-checkbox--small {
    padding: 7px 15px 5px 10px;
  }
  .downloadDialog >>> .el-dialog__body {
    padding: 45px 20px;
  }
</style>
