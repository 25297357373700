var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "never" } },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { staticClass: "checkAllBox" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { border: "", indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [_vm._v(" 全选 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "small", icon: "el-icon-download" },
                  on: {
                    click: function ($event) {
                      _vm.downloadVisible = true
                    },
                  },
                },
                [_vm._v(" 下载 ")]
              ),
              _vm.row.workplace === "investigator" ||
              (_vm.row.workplace === "supervisor" &&
                _vm.row.isSurveyUser === "Y")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v(" 批量删除 ")]
                  )
                : _vm._e(),
              (_vm.row.workplace === "supervisor" && _vm.row.flag === "edit") ||
              (_vm.row.workplace === "verify_people" &&
                _vm.row.flag === "review")
                ? _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    on: { change: _vm.switchBatchSubmit },
                    model: {
                      value: _vm.sumitTag,
                      callback: function ($$v) {
                        _vm.sumitTag = $$v
                      },
                      expression: "sumitTag",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.row.workplace === "investigator" ||
          (_vm.row.workplace === "supervisor" && _vm.row.isSurveyUser === "Y")
            ? _c(
                "vab-query-form-right-panel",
                { staticClass: "上传" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          _vm.openUploadSource = true
                        },
                      },
                    },
                    [_vm._v(" 上传 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "15px 0" } }),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "draggable",
                {
                  staticClass: "board-column-content",
                  attrs: {
                    handle: ".draggle-element",
                    options: { disabled: _vm.undraggable },
                  },
                  on: { end: _vm.end },
                  model: {
                    value: _vm.fileData,
                    callback: function ($$v) {
                      _vm.fileData = $$v
                    },
                    expression: "fileData",
                  },
                },
                _vm._l(_vm.fileData, function (fileItem, fileIndex) {
                  return _c(
                    "div",
                    { key: fileIndex, staticClass: "class-detail" },
                    [
                      _c("div", { staticClass: "draggle-element" }, [
                        _c(
                          "a",
                          {
                            attrs: { rel: "noopener noreferrer" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.preview(fileItem.name, fileItem.path)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                "margin-left": "12px",
                                width: "100px",
                                height: "100px",
                              },
                              attrs: {
                                src: require("@/icon/pdf.svg"),
                                fit: _vm.fit,
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "tip-detail" },
                        [
                          _c("el-checkbox", {
                            staticClass: "file_checkbox",
                            on: {
                              change: function ($event) {
                                return _vm.handlecheckedChange(fileIndex)
                              },
                            },
                            model: {
                              value: fileItem.mychecked,
                              callback: function ($$v) {
                                _vm.$set(fileItem, "mychecked", $$v)
                              },
                              expression: "fileItem.mychecked",
                            },
                          }),
                          (_vm.row.workplace === "supervisor" &&
                            _vm.row.flag === "edit") ||
                          (_vm.row.workplace === "verify_people" &&
                            _vm.row.flag === "review")
                            ? _c("el-switch", {
                                staticClass: "submitSwitch",
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.switchSubmit(fileItem)
                                  },
                                },
                                model: {
                                  value: fileItem.tag,
                                  callback: function ($$v) {
                                    _vm.$set(fileItem, "tag", $$v)
                                  },
                                  expression: "fileItem.tag",
                                },
                              })
                            : _vm._e(),
                          _vm.labelEditReload &&
                          fileItem.generateTag !== "generate"
                            ? _c("label-edit", {
                                staticClass: "labelEdit",
                                attrs: {
                                  text: fileItem.name,
                                  "able-change": _vm.operaEnable,
                                },
                                on: {
                                  "text-updated-blur": function ($event) {
                                    return _vm.textUpdateCallbackBlur(
                                      $event,
                                      fileItem
                                    )
                                  },
                                },
                              })
                            : _c(
                                "p",
                                {
                                  staticClass: "fileTip",
                                  attrs: { title: fileItem.name },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        fileItem.name.length > 10
                                          ? fileItem.name.substring(0, 10) +
                                              "..."
                                          : fileItem.name
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "el-col",
            [
              _c("el-pagination", {
                attrs: {
                  background: _vm.background,
                  "current-page": _vm.parameter.body.pageNum,
                  layout: _vm.layout,
                  "page-size": _vm.parameter.body.pageSize,
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "downloadDialog",
          attrs: {
            title: "下载确认",
            visible: _vm.downloadVisible,
            width: "30%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.downloadVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否要下载所有勾选内容？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.downloadVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.download } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传资源",
            visible: _vm.openUploadSource,
            width: "573px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openUploadSource = $event
            },
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", type: "flex", gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "600",
                          "font-size": "15px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v(" 请您上传资源 ")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.row.endpoinxBak,
                              "on-error": _vm.handleError,
                              "on-remove": _vm.handleRemove,
                              "on-change": _vm.handleFileChange,
                              "http-request": _vm.batch,
                              "auto-upload": false,
                              multiple: "",
                              accept: ".pdf,.PDF",
                              "file-list": _vm.fileList,
                              drag: "",
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v(" 将文档拖到此处，或 "),
                              _c("em", [_vm._v("点击上传")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [_vm._v(" 支持的文档类型包括：.pdf ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  ref: "$complete",
                  attrs: {
                    loading: _vm.subLoading,
                    type: "primary",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.scanFileSubmit },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }