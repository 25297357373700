<template>
  <el-dialog :title="title"
             class="myDialog"
             :visible.sync="dialogFormVisible"
             :close-on-click-modal="false"
             append-to-body
             width="60%"
             :fullscreen="fullFlag"
             @close="close">
    <el-backtop target=".myDialog"></el-backtop>
    <i style="position: absolute; top: 24px; right: 41px; cursor: pointer"
       :class="fullIcon"
       :title="fullTitle"
       @click="triggerFullOrHelf"></i>
    <div style="margin-bottom: 10px">{{ companyName }}</div>
    <el-tabs v-model="activeName"
             type="border-card"
             @tab-click="handleClick">
      <el-tab-pane label="工单详情"
                   class="edit_title"
                   name="detail">
        <!-- 放弃类型 -->
        <el-form v-if="reviewFlag === '1'"
                 ref="abandonForm"
                 :model="abandonForm"
                 :rules="abandonFormRules"
                 style="margin-top: 10px">
          <el-row>
            <el-col>
              <el-form-item label="放弃类型:"
                            prop="abandonType">
                <el-input v-model="abandonForm.abandonType"
                          class="abandonType"
                          rows="1"
                          type="textarea"
                          disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="放弃原因:"
                            prop="abandonReason">
                <el-input v-model="abandonForm.abandonReason"
                          class="abandonReason"
                          rows="1"
                          type="textarea"
                          disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!--工单进度-->
        <order-process v-if="reviewFlag !== '1'"
                       :param="param"></order-process>
        <!--基础信息-->
        <div class="title">基础信息</div>

        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="workOrderNo"
                           label="工单编号"
                           align="center"></el-table-column>
          <el-table-column prop="brWorkNo"
                           label="订单编号"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.brWorkNo || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="reportType"
                           label="报告类型"
                           :class-name="brChange('reportTypeFlag') + ' '+ brChange('subReportTypeFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.reportType || "-" }}
              {{ scope.row.subReportType ? '/' : ''}}
              {{ scope.row.subReportType}}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="projectSource"
                           label="项目来源"
                           align="center">
            <template slot-scope="scope">
              {{ getProjectSource(scope.row.projectSource)}}
            </template>
          </el-table-column>
          <el-table-column prop="targetSubject"
                           label="目标主体"
                           :class-name="brChange('subjectFlag')"
                           align="center"></el-table-column>
          <el-table-column prop="targetAddr1"
                           label="目标地址1"
                           :class-name="brChange('subjectAddressFlag')"
                           align="center"></el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="targetAddr2"
                           label="目标地址2"
                           align="center"></el-table-column>
          <el-table-column prop="targetContact"
                           label="目标联系人"
                           :class-name="brChange('subjectLinkNameFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.targetContact || "-" }}
            </template>
          </el-table-column>

          <el-table-column prop="contactNumber"
                           label="联系电话"
                           :class-name="brChange('subjectMobileFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.contactNumber || "-" }}
            </template>
          </el-table-column>
        </el-table>

        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="duty"
                           label="职务"
                           :class-name="brChange('dutyFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.duty || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="orderTime"
                           label="下单时间"
                           align="center"
                           :formatter="dateFormatHM"></el-table-column>
          <el-table-column prop="innerDeadline"
                           label="内部截止日期"
                           :formatter="eighthBitTime"
                           align="center"></el-table-column>
        </el-table>

        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="clientAbbr"
                           label="委托方简称"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.clientAbbr || "-" }}
            </template>
          </el-table-column>

          <el-table-column prop="reportTimeLimit"
                           label="报告时限"
                           :class-name="brChange('reportTimeLimitFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.reportTimeLimit || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark1"
                           label="备注1"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.remark1 || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="remark2"
                           label="备注2"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.remark2 || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark3"
                           label="备注2"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.remark3 || "-" }}
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>
        <!--附加信息-->
        <div class="title">附加信息</div>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="expectVisitDate"
                           label="预计走访日期"
                           :formatter="eighthBitTime"
                           align="center"></el-table-column>
          <el-table-column prop="expectServeyDay"
                           label="预计所需调查天数"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.expectServeyDay || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="needUser"
                           label="所需人手"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.needUser || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="expectHour"
                           label="预计需时（小时）"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.expectHour || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="registerAddress"
                           label="注册地址"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.registerAddress || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="targetSubjectRegStatus"
                           label="目标主体注册状态"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.targetSubjectRegStatus || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <!--特别信息-->
        <div class="title">特别信息</div>

        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="plSpecialMsg"
                           label="PL特别留言"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.plSpecialMsg || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="bookSpecialMsg"
                           label="预约特别留言"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.bookSpecialMsg || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="monitorSpecialMsg"
                           label="督导特别留言"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.monitorSpecialMsg || "-" }}
            </template>
          </el-table-column>
        </el-table>

        <!--附件-->
        <div v-show="annexGroup.length !== 0"
             class="title">附件</div>

        <el-row style="text-align: center; margin-top: 20px">
          <div v-for="(item, index) in annexGroup"
               :key="index">
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <a @click.stop.prevent="
                  handleDownload({ name: item.fileName, path: item.path })
                ">
                <img src="@/icon/annex.svg"
                     alt=""
                     width="80"
                     height="80" />
                <p>{{ item.fileName }}</p>
              </a>
            </el-col>
          </div>
        </el-row>

        <!--操作信息-->
        <div class="title">操作信息</div>
        <el-table :data="operationInfo"
                  stripe
                  border>
          <el-table-column prop="operator"
                           label="操作者"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.operator || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="role"
                           label="角色"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.role || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="operAction"
                           label="操作状态"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.operAction || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime"
                           label="操作时间"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark"
                           label="备注"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.remark || "-" }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="取证资料"
                   name="evidence">
        <evidence ref="evidence"
                  :row="object"></evidence>
      </el-tab-pane>
      <el-tab-pane label="拍摄照片">
        <photo ref="photo"
               :type="'50'"
               :row="object"></photo>
      </el-tab-pane>
      <el-tab-pane label="拍摄录像">
        <screenVideo ref="screenVideo"
                     :type="'51'"
                     :row="object"></screenVideo>
      </el-tab-pane>
      <el-tab-pane label="扫描文件"
                   name="scanFile">
        <scanFile ref="scanFile"
                  :type="'52'"
                  :row="object"></scanFile>
      </el-tab-pane>
      <el-tab-pane label="录制音频">
        <screenAudio ref="screenAudio"
                     :type="'53'"
                     :row="object"></screenAudio>
      </el-tab-pane>
      <el-tab-pane label="其他文件">
        <telRecord ref="telRecord"
                   :type="'54'"
                   :row="object"></telRecord>
      </el-tab-pane>
      <el-tab-pane label="打卡记录">
        <clockIn ref="clockIn"
                 :type="'55'"
                 :row="object"></clockIn>
      </el-tab-pane>
      <el-tab-pane label="会议信息">
        <meeting ref="meeting"
                 :type="'57'"
                 :row="object"></meeting>
      </el-tab-pane>
      <el-tab-pane label="查看小记">
        <remark ref="remark"
                :param="param"></remark>
      </el-tab-pane>
      <el-tab-pane label="退回记录">
        <back ref="back"
              :order="orderNo"></back>
      </el-tab-pane>
      <el-tab-pane label="企业信息">
        <company ref="company"
                 :row="object"></company>
      </el-tab-pane>
    </el-tabs>

    <div v-if="!preview"
         slot="footer"
         class="dialog-footer">
      <el-button type="default"
                 plain
                 @click="openSmallNote = true">
        添加小记
      </el-button>
      <el-button type="warning"
                 @click="withdraw">退回</el-button>
      <el-button v-if="activeName === 'evidence'"
                 type="primary"
                 @click="handleReport">
        生成报告
      </el-button>
      <el-button type="primary"
                 @click="submitClick">提交</el-button>
    </div>
    <div v-else
         slot="footer"
         class="dialog-footer">
      <el-button type="primary"
                 @click="dialogFormVisible=false">关闭</el-button>
    </div>

    <!-- 支持文件校验 -->
    <el-dialog title="提交"
               :visible.sync="fileCheck"
               :close-on-click-modal="false"
               width="500px"
               append-to-body>
      <div style="margin: 20px;">没有支持文件，请确认是否提交?</div>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="fileCheck = false">取 消</el-button>
        <el-button type="primary"
                   @click="showSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 提交 对话框 -->
    <el-dialog title="提交"
               :visible.sync="submitShow"
               width="500px"
               :before-close="submitShowClose"
               :close-on-click-modal="false"
               append-to-body>
      <div v-if="object && object.auditStatusValue=='正常'&& object.brWorkNo"
           style="margin-bottom: 20px">该工单是否确认提交至BR？</div>
      <div v-else
           style="margin-bottom: 20px">该工单是否确认提交？</div>
      <el-form ref="submitForm"
               :model="submitForm"
               :rules="submitFormRules"
               class="demo-form-inline"
               label-width="80px">
        <template v-if="object && object.auditStatusValue!=='正常'|| !object.brWorkNo">
          <el-form-item v-if="reviewFlag === '0'"
                        label="用户"
                        prop="pchUser">
            <el-select v-model="submitForm.pchUser"
                       autocomplete="off"
                       placeholder="请选择用户"
                       filterable>
              <el-option v-for="item in userOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else-if="reviewFlag === '2'"
                        label="用户"
                        prop="pchUserName">
            <el-input v-model="submitForm.pchUserName"
                      type="text"
                      autocomplete="off"
                      disabled></el-input>
          </el-form-item>
        </template>
        <el-form-item label="小记"
                      prop="note">
          <el-input v-model="submitForm.note"
                    type="textarea"
                    autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="submitShow = false">取 消</el-button>
        <el-button type="primary"
                   :loading="loading"
                   @click="submitSave('submitForm')">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 添加小记 对话框 -->
    <el-dialog title="添加小记"
               :visible.sync="openSmallNote"
               width="40%"
               :close-on-click-modal="false"
               :before-close="smallNoteClose"
               append-to-body>
      <el-form ref="smallNoteForm"
               :model="smallNoteForm"
               :rules="smallNoteFormRules"
               class="my-note">
        <el-form-item label="小记"
                      prop="note">
          <el-input v-model="smallNoteForm.note"
                    type="textarea"
                    autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="openSmallNote = false">取 消</el-button>
        <el-button type="primary"
                   @click="saveSmallNote('smallNoteForm')">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 退回对话框 -->
    <el-dialog title="退回"
               :visible.sync="backShow"
               :close-on-click-modal="false"
               :before-close="backShowClose"
               width="500px"
               append-to-body>
      <div style="margin-bottom: 20px">该工单是否退回？</div>
      <el-form ref="backForm"
               :model="backForm"
               :rules="backFormRules"
               label-width="80px">
        <el-form-item v-if="showSelect"
                      label="退回至"
                      prop="nextUserRoleNo">
          <el-select v-model="backForm.nextUserRoleNo"
                     placeholder="请选择退回至"
                     clearable
                     filterable>
            <el-option v-for="item in roleOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="showReturn"
                      label="退回至"
                      prop="returnToName">
          <el-input v-model="backForm.returnToName"
                    type="text"></el-input>
        </el-form-item>
        <el-form-item label="退回原因"
                      prop="fallbackCommentId">
          <el-select v-model="backForm.fallbackCommentId"
                     placeholder="退回原因"
                     :clearable="true"
                     filterable>
            <el-option v-for="item in backOptions"
                       :key="item.seqId"
                       :label="item.content"
                       :value="item.seqId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小记"
                      prop="note">
          <el-input v-model="backForm.note"
                    type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="backShow = false">取 消</el-button>
        <el-button type="primary"
                   :loading="backLoading"
                   @click="backSave('backForm')">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { downloadFile, downloadReport } from "@/utils/index";
// 取证资料
import evidence from "@/views/workplace/components/evidence";
// 拍摄照片
import photo from "@/views/workplace/components/photo";
// 拍摄录像
import screenVideo from "@/views/workplace/components/screenVideo";
// 扫描文件
import scanFile from "@/views/workplace/components/scanFile";
// 录制音频
import screenAudio from "@/views/workplace/components/screenAudio";
// 其他文件
import telRecord from "@/views/workplace/components/telRecord";
// 打卡记录
import clockIn from "@/views/workplace/components/clockIn";
// 会议信息
import meeting from "@/views/workplace/components/meeting";

// 退回记录
import back from "@/views/workplace/components/back";
// 企业信息
import company from "@/views/workplace/components/company";

import {
  getGeneralReason,
  getAllReasonOption,
  getOperationInfo,
  getOrderDetail,
  getUsersListByRole,
  searchAttach,
} from "@/api/workplace/tools";
import { generateReport } from "@/api/workplace/commonOperation";
import orderProcess from "@/views/workplace/components/orderProcess";
// 查看小记
import remark from "@/views/workplace/components/remark";
import {
  saveNote,
  submit,
  submitOne,
  fallBack,
  getReturnTo,
  querySupportFileCount,
} from "@/api/workplace/internalAudit/internalAudit";
import { mapGetters } from "vuex";
import {
  returnBitTimestamp,
  getRoleKey,
  eighthBitTime,
  dateFormatHM,
  isExistOSS,
  getProjectSource,
} from "@/utils";

export default {
  name: "Review",
  components: {
    evidence,
    photo,
    screenVideo,
    scanFile,
    screenAudio,
    telRecord,
    clockIn,
    meeting,
    remark,
    back,
    company,

    orderProcess,
  },
  props: {
    dialogTitle: {
      type: String,
      default: "审核工单",
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      backLoading: false,
      activeName: "detail",
      showSelect: true,
      showReturn: true,
      fileCheck: false,
      // 全/半屏提示文本
      fullTitle: "最大化",
      // 全/半屏按钮图标
      fullIcon: "el-icon-full-screen",
      // 是否全屏标识
      fullFlag: false,
      // 工单附件集合
      annexGroup: [],
      // 添加小记
      openSmallNote: false,
      submitShow: false,
      backShow: false,
      title: "",
      dialogFormVisible: false,
      tableData: [],
      // 提交dialog中用户数据框可见标识 常规 0 常规|放弃 1 答复 2
      reviewFlag: "0",
      smallNoteForm: {
        note: "",
      },
      submitForm: {
        pchUser: "",
        note: "",
      },
      backForm: {
        nextUserRoleNo: "",
        returnToName: null,
        fallbackCommentId: "",
        note: null,
      },
      reportForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      smallNoteFormRules: {
        note: [
          { required: true, message: "请输入小记", trigger: "blur" },
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
      },
      submitFormRules: {
        pchUser: [{ required: true, trigger: "blur", message: "请选择用户" }],
        note: [
          { required: true, message: "请输入小记", trigger: "blur" },
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
      },
      backFormRules: {
        nextUserRoleNo: [
          { required: true, trigger: "blur", message: "请选择退回至" },
        ],
        fallbackCommentId: [
          { required: true, trigger: "blur", message: "请选择退回原因" },
        ],
        note: [
          { required: true, message: "请输入小记", trigger: "blur" },
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
      },
      roleOptions: [
        {
          value: "book_person",
          label: "预约",
        },
        {
          value: "supervisor",
          label: "督导",
        },
      ],
      orderNo: null,
      // 获取工单详情
      param: {
        body: {
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
          pageSize: 10,
          pageNum: 1,
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      operationInfo: [],
      // 查询工单的操作日志
      logParam: {
        body: {
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 单独记录小记
      noteParam: {
        body: {
          note: "",
          step: "内审工作台-工单初审-待处理",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 提交参数
      submitParam: {
        body: {
          console: "verify_people",
          note: "",
          operAction: "工单初审",
          step: "内审工作台-工单初审-待处理",
          procInsId: "",
          taskId: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
          // 异步生成报告5个触发点参数
          triggerPoint: "",
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      userOptions: [],
      // 查寻用户
      userParam: {
        body: [
          {
            roleKey: "customer",
          },
        ],
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      /** 放弃回显 */
      abandonForm: {
        abandonType: "",
        abandonReason: "ok的",
      },
      abandonFormRules: {
        abandonType: [
          { required: true, trigger: "blur", message: "放弃类型显示异常" },
        ],
        abandonReason: [
          { required: true, trigger: "blur", message: "放弃原因显示异常" },
        ],
      },
      // 退回参数
      backParam: {
        body: {
          console: "verify_people",
          note: "",
          fallbackCommentId: "",
          operAction: "工单退回",
          step: "内审工作台-工单初审-待处理",
          procInsId: "",
          taskId: "",
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
          nextUserRoleNo: null,
          returnTo: "",
          returnToRoleKey: "",
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 退回原因form
      backOptions: [],
      // 给子组件传值
      object: {},
      // 工单附件集合
      attachs: [],
      companyName: null,
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
      endpoinxBak: "user/endpoinxBak",
      OSSClient: "user/OSSClient",
    }),
  },

  created() {
    this.param.body.userNo = this.userNo;
    this.param.body.userRoleNo = getRoleKey("verify_people", this.roles);
    this.noteParam.body.userNo = this.userNo;
    this.noteParam.body.userRoleNo = getRoleKey("verify_people", this.roles);
    this.submitParam.body.userNo = this.userNo;
    this.submitParam.body.userRoleNo = getRoleKey("verify_people", this.roles);
    this.backParam.body.userNo = this.userNo;
    this.backParam.body.userRoleNo = getRoleKey("verify_people", this.roles);
  },

  methods: {
    async showReview(row, flag) {
      this.backForm.returnToName = 123;
      this.object = row;
      this.title = this.dialogTitle || "审核工单";
      this.object = Object.assign(
        this.object,
        { workplace: "verify_people" },
        { flag: "review" }
      );
      this.object.endpoinxBak = this.endpoinxBak;
      this.object.OSSClient = this.OSSClient;
      this.object.userNo = this.userNo;
      this.object.userRoleNo = getRoleKey("verify_people", this.roles);
      this.object.auditStatusValue = row.auditStatusValue;
      this.object.projectSource = row.projectSource;
      if (flag === "reply") {
        this.reviewFlag = "2";
        this.submitParam.body.questionStatus = 41;
        this.submitForm.pchUserName = row.pchUserName;
      } else {
        this.reviewFlag = row.auditStatusValue === "放弃" ? "1" : "0";
      }

      this.smallNoteForm = Object.assign({}, row);

      this.submitForm = Object.assign({}, row);

      this.backForm = Object.assign({}, row);

      /** 生成报告 */
      this.reportForm.body.workOrderNo = row.workOrderNo;

      this.param.body.workOrderNo = row.workOrderNo;
      // 获取系统当前时间
      this.param.header.currentTime = returnBitTimestamp(new Date());

      this.dialogFormVisible = true;

      this.tableData = [];
      this.orderNo = row.workOrderNo;
      // 获取工单详情
      this.param.body.workOrderNo = row.workOrderNo;
      if (this.reviewFlag === "1") {
        this.param.body.auditFlag = row.auditFlag;
      }
      const detailInfo = Object.assign(
        {},
        (await getOrderDetail(this.param)).body
      );
      this.tableData.push(detailInfo);
      this.companyName = this.tableData[0].targetSubject;
      this.abandonForm.abandonType = detailInfo.abandonType;
      this.abandonForm.abandonReason = detailInfo.abandonReason;

      // 获取系统当前时间
      this.logParam.header.currentTime = returnBitTimestamp(new Date());
      // 获取操作工单日志信息
      this.logParam.body.workOrderNo = row.workOrderNo;
      this.operationInfo = (await getOperationInfo(this.logParam)).body;
      // 获取系统当前时间
      this.userParam.header.currentTime = returnBitTimestamp(new Date());

      // 查新督导人员
      const { body } = await getUsersListByRole(this.userParam);
      this.userOptions = body;
      //查询退回原因
      let getForm = {};
      getForm.body = {};
      getGeneralReason(getForm).then((response) => {
        if ("1" === response.header.retCode) {
          this.backOptions = response.body;
        }
      });

      /** 查询附件信息 */
      await this.attach();
      this.getDetail();
    },
    /** 查询附件信息 */
    async attach() {
      this.param.body.workOrderNo = this.orderNo;
      this.param.header.currentTime = returnBitTimestamp(new Date());
      const { body, header } = await searchAttach(this.param);
      if (header.retCode === "1") {
        this.annexGroup = body;
      } else {
        this.$baseMessage(header.retMessage, "error");
      }
    },
    getDetail() {
      // this.$refs["evidence"].fetchData();
      this.$refs["photo"].fetchData();
      this.$refs["screenVideo"].fetchData();
      this.$refs["scanFile"].fetchData();
      this.$refs["screenAudio"].fetchData();
      this.$refs["telRecord"].fetchData();
      this.$refs["clockIn"].fetchData();
      this.$refs["meeting"].fetchData();
      this.$refs["remark"].fetchData();
      this.$refs["back"].fetchData();
      this.$refs.company.init();
      this.activeName = "detail";
    },
    // 生成报告
    async handleReport(row) {
      this.reportForm.header.currentTime = returnBitTimestamp(new Date());
      const { header, body } = await generateReport(this.reportForm);
      if (header.retCode === "1") {
        downloadReport(`/site/downOssAndTemp?workOrderNo=${this.orderNo}`);
      } else {
        this.$baseMessage(`${header.retMessage}`, "error");
      }
    },
    smallNoteClose() {
      this.$refs["smallNoteForm"].resetFields();
      this.openSmallNote = false;
    },
    submitShowClose() {
      this.$refs["submitForm"].resetFields();
      /** 清空放弃回显Form */
      // this.$refs["abandonForm"].resetFields();
      this.submitShow = false;
    },
    backShowClose() {
      this.$refs["backForm"].resetFields();
      this.backShow = false;
    },
    // 提交话框
    submitSave(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.submitParam.header.currentTime = returnBitTimestamp(new Date());
          this.submitParam.body.workOrderNo = this.submitForm.workOrderNo;
          this.submitParam.body.note = this.submitForm.note;

          this.submitParam.body.procInsId = this.submitForm.procInsId;
          this.submitParam.body.taskId = this.submitForm.taskId;
          // 11:申请放弃
          if (this.object.auditFlag === 11) {
            const { header } = await submitOne(
              Object.assign({}, this.submitParam)
            );
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
          } else {
            this.submitParam.body.pchUser = this.submitForm.pchUser;
            this.submitParam.body.triggerPoint = "audit_commit";
            const { header } = await submit(
              Object.assign({}, this.submitParam)
            );
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
          }
          this.$emit("refreshData");
          this.$refs[formName].resetFields();

          this.submitForm = this.$options.data().submitForm;

          this.dialogFormVisible = false;
          this.submitShow = false;
          // this.loading = false;
          // 保存
        } else {
          this.$message.error("保存失败，请重新检查输入内容");
          return false;
        }
      });
    },
    // 保存小记对话框
    saveSmallNote(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.noteParam.header.currentTime = returnBitTimestamp(new Date());
          this.noteParam.body.workOrderNo = this.smallNoteForm.workOrderNo;
          this.noteParam.body.note = this.smallNoteForm.note;
          this.noteParam.body.operAction = "保存小记";

          const { header } = await saveNote(Object.assign({}, this.noteParam));
          if ("1" === header.retCode) {
            this.$baseMessage(header.retMessage, "success");
          } else {
            this.$baseMessage(header.retMessage, "error");
          }
          this.openSmallNote = false;
          // 保存
        } else {
          this.$message.error("保存失败，请重新检查输入内容");
          return false;
        }
      });
    },
    //提交按钮
    async submitClick() {
      if (!!this.tableData[0].brWorkNo) {
        await querySupportFileCount(this.tableData[0].workOrderNo).then(
          (res) => {
            if (res.header.retCode === "1") {
              if (res.body === 0) {
                this.fileCheck = true;
              } else {
                this.showSubmit();
              }
            } else {
              this.$message.error(`${res.header.retMessage}`);
              return;
            }
          }
        );
      } else {
        this.showSubmit();
      }
    },
    showSubmit() {
      this.loading = false;
      this.submitShow = true;
      this.fileCheck = false;
    },
    withdraw() {
      this.param.body.procInsId = this.object.procInsId;
      this.param.body.taskId = this.object.taskId;
      this.param.body.workOrderNo = this.object.workOrderNo;
      this.backLoading = false;
      getReturnTo(this.param).then((res) => {
        const { body } = res;
        this.backForm.returnToName = body.returnToName;
        // this.backForm.note = body.brRollbackReason
        this.$set(this.backForm, "note", body.brRollbackReason);
        this.backParam.body.returnTo = body.returnTo;
        this.backParam.body.returnToRoleKey = body.returnToRoleKey;
        this.backShow = true;
        if (this.object.auditFlag === 11) {
          this.showSelect = false;
          this.showReturn = true;
        } else {
          this.showSelect = true;
          this.showReturn = false;
        }
      });
    },

    // 退回对话框
    backSave(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.backLoading = true;
          this.backParam.header.currentTime = returnBitTimestamp(new Date());
          this.backParam.body.workOrderNo = this.backForm.workOrderNo;
          this.backParam.body.note = this.backForm.note;
          this.backParam.body.fallbackCommentId =
            this.backForm.fallbackCommentId;
          this.backParam.body.procInsId = this.backForm.procInsId;
          this.backParam.body.taskId = this.backForm.taskId;
          this.backParam.body.nextUserRoleNo = this.backForm.nextUserRoleNo;
          this.backParam.body.auditFlag = this.backForm.auditFlag;

          /** 答复同意之后的审核退回需要添加质疑状态 */
          if (this.reviewFlag === "2") {
            this.backParam.body.questionStatus = 41;
          }

          const { header } = await fallBack(Object.assign({}, this.backParam));
          if ("1" === header.retCode) {
            this.$baseMessage(header.retMessage, "success");
          } else {
            this.$baseMessage(header.retMessage, "error");
          }
          this.backShow = false;
          this.$emit("close");
          this.$emit("refreshData");
          this.$refs[formName].resetFields();

          this.backForm = this.$options.data().backForm;
          this.dialogFormVisible = false;
          // 保存
          // this.backLoading = false;
        } else {
          this.$message.error("保存失败，请重新检查输入内容");
          return false;
        }
      });
    },
    close() {
      this.fullFlag = false;
      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
      this.dialogFormVisible = false;
      this.$refs["evidence"].reset();
    },
    handleClick(tab, event) {
      if (tab.name === "evidence") {
        this.$refs["evidence"].reset();
        this.$refs["evidence"].fetchData();
      } else if (tab.name === "scanFile") {
        this.$refs["scanFile"].fetchData();
      }
      this.$refs["remark"].fetchData();
    },
    // 时分日期格式处理
    eighthBitTime(row, column) {
      return eighthBitTime(row, column);
    },
    dateFormatHM(row, column) {
      return dateFormatHM(row, column);
    },
    /** 全屏/半屏切换 */
    triggerFullOrHelf() {
      this.fullFlag = !this.fullFlag;
      if (this.fullFlag) {
        this.fullIcon = "el-icon-minus";
        this.fullTitle = "最小化";
      } else {
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      }
    },
    /** 附件下载 */
    handleDownload(item) {
      isExistOSS(this.OSSClient, item.path)
        .then(() => {
          downloadFile(item);
        })
        .catch(() => {
          this.$message.error("当前文件不存在！");
        });
    },
    // 修改内容标黄
    brChange(data) {
      if (
        this.tableData.length &&
        this.tableData[0]?.siteBrUpdateColumnRes &&
        this.tableData[0]?.siteBrUpdateColumnRes[data]
      ) {
        return "is-change";
      } else {
        return "";
      }
    },
    getProjectSource,
  },
};
</script>

<style>
body {
  font-weight: 700;
  color: #666;
}
.edit_title .title {
  margin: 10px 0;
}
.el-step__title {
  font-size: 14px;
}
.el-step__title.is-process {
  font-weight: bold;
  color: #666;
}
html body .el-table th .cell {
  font-weight: 700;
  color: #666;
}
/* .el-table--enable-row-hover .el-table__body tr:hover > td { */
/* background-color: #f5f7fa; */
/* } */

.el-form-item__label {
  font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100% !important;
}

.el-select,
.el-cascader {
  width: 100%;
}

.my-note {
  padding-right: 0 !important;
}
.abandonType {
  width: 40%;
}
.abandonReason {
  width: 40%;
}
.el-table .el-table__body td.is-change {
  background: #ffff9f;
}
</style>
