<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            icon="el-icon-search"
            plain
            native-type="submit"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            type="info"
            plain
            native-type="submit"
            @click="resetForm('queryForm')"
          >
            重置
          </el-button>
          <el-button
            icon="el-icon-sort"
            plain
            native-type="submit"
            @click="heightQuery"
          >
            高级筛选
          </el-button>

          <el-form
            ref="queryForm"
            :model="queryForm"
            style="margin-top: 15px"
            class="demo-form-inline my-form-class"
          >
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="orderDate">
                  <el-date-picker
                    v-model="queryForm.orderDate"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="下单开始日期"
                    end-placeholder="下单结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input
                    v-model.number="queryForm.workOrderNo"
                    placeholder="工单编号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input
                    v-model.trim="queryForm.targetSubject"
                    placeholder="目标主体"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader
                    :value="queryForm.projectId"
                    :options="projectInfo"
                    :clearable="false"
                    :filterable="true"
                    separator=":"
                    placeholder="项目"
                    @change="handleProjectChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="isShow" transiton="fade" :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="visitDate">
                  <el-date-picker
                    v-model="queryForm.visitDate"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="走访开始日期"
                    end-placeholder="走访结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>

              <!-- 选择地区 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="address">
                  <el-cascader
                    :value="queryForm.address"
                    :options="cityInfo"
                    :change-on-select="false"
                    :clearable="true"
                    :filterable="true"
                    placeholder="地区"
                    :props="{ checkStrictly: true }"
                    @change="handleBlockChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>

              <!-- 工单状态 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="workOrderStatus">
                  <el-select
                    v-model="queryForm.workOrderStatus"
                    placeholder="工单状态"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in orderStatusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 质疑状态 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="questionStatus">
                  <el-select
                    v-model="queryForm.questionStatus"
                    placeholder="质疑状态"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="item in questionStatusOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-show="isShow" transiton="fade" :gutter="20">
              <!-- 内部截止 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="innerDeadDate">
                  <el-date-picker
                    v-model="queryForm.innerDeadDate"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="内部截止开始日期"
                    end-placeholder="内部截止结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 二审结束日期范围/也就是工单完成日期 -->
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="workOrderFinishDate">
                  <el-date-picker
                    v-model="queryForm.workOrderFinishDate"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="二审结束日期开始日期"
                    end-placeholder="二审结束日期结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            :loading="downloadLoading"
            type="primary"
            @click="handleDownload"
          >
            导出
          </el-button>
          <!--已处理工单列表-->
          <el-table
            ref="tableSort"
            v-loading="listLoading"
            :data="list"
            stripe
            style="margin-top: 15px"
            border
            :element-loading-text="elementLoadingText"
            @selection-change="setSelectRows"
          >
            <el-table-column
              type="selection"
              width="40"
              align="center"
            ></el-table-column>
            <el-table-column
              label="工单编号"
              prop="workOrderNo"
              align="center"
              width="130"
            >
              <template slot-scope="scope">
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column
              prop="targetSubject"
              label="目标主体"
              width="150"
            ></el-table-column>
            <el-table-column label="目标地址" min-width="250" prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目" prop="projectName" width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="工单状态"
              prop="workOrderStatusValue"
              width="90"
            >
              <template slot-scope="scope">
                {{ scope.row.workOrderStatusValue || "-" }}
              </template>
            </el-table-column>
            <!-- 预约走访时间 -->
            <el-table-column
              prop="bookVisitTime"
              label="预约走访时间"
              width="130"
              align="center"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="visitTime"
              label="走访时间"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                {{ scope.row.visitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="innerDeadline"
              label="内部截止日期"
              sortable
              align="center"
              width="130"
            >
              <template slot-scope="scope">
                {{ scope.row.innerDeadline || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="stepAndStranded"
              align="center"
              label="环节/整体滞留(天)"
              fit="true"
              width="90"
            >
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="operStatusValue"
              label="操作状态"
              width="90px"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.operStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="questionStatusValue"
              label="质疑状态"
              sortable
              width="105"
            >
              <template slot-scope="scope">
                {{ scope.row.questionStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100px"
              fixed="right"
              align="center"
              fit="true"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="详情"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-document"
                    circle
                    @click="goDetailPage(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNum"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getDone } from "@/api/workplace/user/pchUser";
  import { returnBitTimestamp, returnDeadtimestamp, getRoleKey } from "@/utils";
  import { mapGetters } from "vuex";
  import {
    getAllReasonOption,
    getBlock,
    getProjectSource,
    getRole,
    getWorkOperStatus,
    getWorkOrderStatus,
  } from "@/api/workplace/tools";
  export default {
    name: "ComprehensiveTable",
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: "success",
          draft: "gray",
          deleted: "danger",
        };
        return statusMap[status];
      },
    },
    data() {
      return {
        isShow: false,
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: false,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
        background: true,
        selectRows: "",
        elementLoadingText: "正在加载...",
        queryForm: {
          orderDate: null, // 表单数据，不传递到后端
          orderTimeEnd: "",
          orderTimeStart: "",
          workOrderNo: "",
          targetSubject: "",
          projectId: "",

          visitDate: null, // 表单数据，不传递到后端
          clockEndTime: "",
          clockStartTime: "",
          workOrderStatus: "",
          surveyUser: "",
          bookUser: "",

          innerDeadDate: null, // 表单数据，不传递到后端
          innerDeadlineEnd: "",
          innerDeadlineStart: "",
          plUser: "",
          operStatus: "",
          fallbackReasonId: "",

          address: "",

          pageNum: 1,
          pageSize: 10,
          nextUserNo: "",
          nextUserRoleNo: "",
          userNo: "",
          userRoleNo: "",
          preliminaryDate: null,
          firstAuditDateStart: null,
          firstAuditDateEnd: null,
          workOrderFinishDate: null,
          workOrderFinishDateStart: null,
          workOrderFinishDateEnd: null,
        },
        queryPortForm: {
          body: {
            userNo: "",
            userRoleNo: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        param: {
          body: {},
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        pickerOptions: {
          shortcuts: [
            {
              text: "最近一周",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近三个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              },
            },
          ],
        },
        operateOptions: [],

        reasonOptions: [],
        //  工单状态
        orderStatusOptions: [],
        // 退回原因form
        allBackForm: {
          body: {
            templateType: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        projectInfo: [], // 项目
        projectForm: {
          body: {
            source: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        questionStatusOptions: [
          {
            value: 40,
            label: "待答复",
          },
          {
            value: 41,
            label: "质疑成立",
          },
          {
            value: 42,
            label: "质疑驳回",
          },
        ],
        //   角色人员
        roleOptions: [],
        roleOptions_arr: [],
        roleForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        allAbandonForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        cityInfo: [],
        cityForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        // 操作状态
        operStatusInfo: [],
        operStatusForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        allAbandonInfo: [],
        downloadLoading: false,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      // this.queryPortForm.userNo = this.userNo;
      // this.queryPortForm.userRoleNo = getRoleKey("customer", this.roles);
      // this.queryForm.userNo = this.userNo;
      // this.queryForm.userRoleNo = getRoleKey("customer", this.roles);
    },
    methods: {
      /** 数据初始化 */
      handleInitData() {
        this.fetchData();
        // 获取项目
        this.getProject();
      },
      /** 导出Excel */
      handleDownload() {
        if (this.selectRows.length > 0) {
          this.downloadLoading = true;
          import("@/vendor/ExportExcel").then((excel) => {
            const tHeader = [
              "工单编号",
              "目标主体",
              "目标地址",
              "项目",
              "工单状态",
              "预约走访时间",
              "走访时间",
              "内部截止日期",
              "环节/整体滞留(天)",
              "操作状态",
              "质疑状态",
            ];
            const filterVal = [
              "workOrderNo",
              "targetSubject",
              "address",
              "projectName",
              "workOrderStatusValue",
              "bookVisitTime",
              "visitTime",
              "innerDeadline",
              "stepAndStranded",
              "operStatusValue",
              "questionStatusValue",
            ];
            const list = this.selectRows;
            const data = this.formatJson(filterVal, list);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: "工单列表",
              autoWidth: true,
              bookType: "xlsx",
            });
            this.downloadLoading = false;
          });
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      },
      /** 组装导出数据 */
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if (!v[j]) {
              return "——";
            }
            return v[j];
          })
        );
      },
      /** 打开工单单详情页面 */
      goDetailPage(row) {
        let routeData = this.$router.resolve({
          path: "/workplace/user/orderSecondReview/detail",
          name: "Detail",
          query: {
            target: row.targetSubject,
            workOrderNo: row.workOrderNo,
            address: row.address,
            projectName: row.projectName,
            projectId: row.projectId,
            seqId: row.seqId,
            userNo: this.userNo,
            procInsId: row.procInsId,
            taskId: row.taskId,
            userRoleNo: getRoleKey("customer", this.roles),
            projectSign: row.projectSign,
          },
        });
        /*** 截掉site字符 */
        routeData.href = routeData.href.slice(5);
        window.open(routeData.href, "_blank");
        // this.$router.push("/workplace/user/summary/detail");
      },
      setSelectRows(val) {
        this.selectRows = val;
      },
      // 重置
      resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
          // this.$refs[formName].resetFields();
          this.queryForm = this.$options.data().queryForm;
          this.reset = this.$options.data().reset;
        }
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm("你确定要删除当前项吗", null, async () => {
            const { msg } = await doDelete({ ids: row.id });
            this.$baseMessage(msg, "success");
            this.fetchData();
          });
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join();
            this.$baseConfirm("你确定要删除选中项吗", null, async () => {
              const { msg } = await doDelete({ ids: ids });
              this.$baseMessage(msg, "success");
              this.fetchData();
            });
          } else {
            this.$baseMessage("未选中任何行", "error");
            return false;
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.fetchData();
      },
      heightQuery() {
        this.isShow = !this.isShow;
        // 获取工单状态
        this.getOrderStatus();
        // 获取角色列表
        this.getRoleList();
        // 放弃原因
        this.getAllAbandon();
        // 获取地区
        this.getAddress();
        // 获取退回原因
        this.getAllBack();
        // 获取操作状态
        this.getOperStatus();
      },
      // 获取放弃原因 上门前放弃&上门后
      getAllAbandon() {
        this.allAbandonForm.header.currentTime = returnBitTimestamp(new Date());
        this.allAbandonForm.body.templateType = "80";
        getAllReasonOption(this.allAbandonForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.allAbandonInfo = response.body;
          }
        });
      },
      // 获取工单状态
      getOrderStatus() {
        this.param.header.currentTime = returnBitTimestamp(new Date());
        getWorkOrderStatus(this.param).then((response) => {
          if ("1" === response.header.retCode) {
            this.orderStatusOptions = response.body;
          } else {
            this.$message.error(
              `${response.header.retMessage},获取工单状态失败，请重新刷新页面`
            );
          }
        });
      },
      // 获取地区
      getAddress() {
        this.cityForm.header.currentTime = returnBitTimestamp(new Date());
        getBlock(this.cityForm).then((response) => {
          if ("1" === response.header.retCode) {
            let block_info = [];
            block_info.push(response.body[0]);
            this.cityInfo = block_info;
          } else {
            this.$message.error(
              `${response.header.retMessage},获取地区失败，请重新刷新页面`
            );
          }
        });
      },
      // 选择地区
      handleBlockChange(value) {
        this.queryForm.address = value.join(",");
      },
      // 获取项目
      getProject() {
        this.projectForm.header.currentTime = returnBitTimestamp(new Date());
        getProjectSource(this.projectForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.projectInfo = response.body;
          }
        });
      },
      // 选择项目
      handleProjectChange(value) {
        this.queryForm.projectId = value[1];
      },
      getRoleList() {
        let role_arr = [];
        let roleForm = {
          body: [],
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        };
        let roleOptions_arr = [];
        // PL人员
        const plUser = [
          {
            roleKey: "PL",
          },
        ];

        // 预约人员
        const bookUser = [
          {
            roleKey: "book_person",
          },
        ];

        // 调查人员
        const surveyUser = [
          {
            roleKey: "full_investigator",
          },
          {
            roleKey: "part_investigator",
          },
        ];

        // 督导人员
        const supervisor = [
          {
            roleKey: "supervisor",
          },
        ];

        // 内审
        const auditUser = [
          {
            roleKey: "verify_people",
          },
        ];

        // 用户
        const pchUser = [
          {
            roleKey: "customer",
          },
        ];
        role_arr.push(
          plUser,
          bookUser,
          surveyUser,
          supervisor,
          auditUser,
          pchUser
        );
        // 异步调用接口，使其按顺序执行
        async function getAsyncRole() {
          roleForm.header.currentTime = returnBitTimestamp(new Date());
          for (let index = 0; index < role_arr.length; index++) {
            roleForm.body = role_arr[index];
            await getRole(roleForm).then((response) => {
              if ("1" === response.header.retCode) {
                roleOptions_arr.push(response.body);
              } else {
                roleOptions_arr.push([]);
              }
            });
            if (index === role_arr.length - 1) {
              return roleOptions_arr;
            }
          }
        }
        getAsyncRole().then((res) => {
          this.roleOptions_arr = res;
        });
      },

      // 获取角色配置项
      getRoleOption(role) {
        switch (role) {
          case "plUser":
            this.roleOptions = this.roleOptions_arr[0];
            break;
          case "bookUser":
            this.roleOptions = this.roleOptions_arr[1];
            break;
          case "surveyUser":
            this.roleOptions = this.roleOptions_arr[2];
            break;
          case "supervisor":
            this.roleOptions = this.roleOptions_arr[3];
            break;
          case "auditUser":
            this.roleOptions = this.roleOptions_arr[4];
            break;
          case "pchUser":
            this.roleOptions = this.roleOptions_arr[5];
            break;
          default:
            break;
        }
      },
      // 获取退回原因 普通退回+待定退回
      getAllBack() {
        this.allBackForm.header.currentTime = returnBitTimestamp(new Date());
        this.allBackForm.body.templateType = "81";
        getAllReasonOption(this.allBackForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.reasonOptions = response.body;
          }
        });
      },
      // 获取操作状态
      getOperStatus() {
        this.operStatusForm.header.currentTime = returnBitTimestamp(new Date());
        getWorkOperStatus(this.operStatusForm).then((response) => {
          if (response.header.retCode === "1") {
            this.operateOptions = response.body;
          } else {
            this.$message.error(
              `${response.header.retMessage},获取操作状态失败，请重新刷新页面`
            );
          }
        });
      },
      handleQuery() {
        this.queryForm.pageNum = 1;
        this.fetchData();
      },
      async fetchData() {
        this.listLoading = true;
        this.queryPortForm.header.currentTime = returnBitTimestamp(new Date());
        // 下单日期范围
        if (null !== this.queryForm.orderDate) {
          if (0 < this.queryForm.orderDate.length) {
            this.queryForm.orderTimeStart = returnBitTimestamp(
              this.queryForm.orderDate[0]
            );
            this.queryForm.orderTimeEnd = returnDeadtimestamp(
              this.queryForm.orderDate[1]
            );
          }
        }
        // 走访日期范围
        if (null !== this.queryForm.visitDate) {
          if (0 < this.queryForm.visitDate.length) {
            this.queryForm.clockStartTime = returnBitTimestamp(
              this.queryForm.visitDate[0]
            );
            this.queryForm.clockEndTime = returnDeadtimestamp(
              this.queryForm.visitDate[1]
            );
          }
        } else {
          this.queryForm.clockStartTime = null;
          this.queryForm.clockEndTime = null;
        }
        // 初审开始日期范围
        if (null !== this.queryForm.preliminaryDate) {
          if (0 < this.queryForm.preliminaryDate.length) {
            this.queryForm.firstAuditDateStart = returnBitTimestamp(
              this.queryForm.preliminaryDate[0]
            );

            this.queryForm.firstAuditDateEnd = returnDeadtimestamp(
              this.queryForm.preliminaryDate[1]
            );
          }
        } else {
          this.queryForm.firstAuditDateStart = null;
          this.queryForm.firstAuditDateEnd = null;
        }
        // 二审开始日期范围
        if (null !== this.queryForm.workOrderFinishDate) {
          if (0 < this.queryForm.workOrderFinishDate.length) {
            this.queryForm.workOrderFinishDateStart = returnBitTimestamp(
              this.queryForm.workOrderFinishDate[0]
            );

            this.queryForm.workOrderFinishDateEnd = returnDeadtimestamp(
              this.queryForm.workOrderFinishDate[1]
            );
          } else {
            this.queryForm.workOrderFinishDateStart = null;
            this.queryForm.workOrderFinishDateEnd = null;
          }
        } else {
          this.queryForm.workOrderFinishDateStart = null;
          this.queryForm.workOrderFinishDateEnd = null;
        }
        // 内部截止日期范围
        if (null !== this.queryForm.innerDeadDate) {
          if (0 < this.queryForm.innerDeadDate.length) {
            this.queryForm.innerDeadlineStart = returnBitTimestamp(
              this.queryForm.innerDeadDate[0]
            );

            this.queryForm.innerDeadlineEnd = returnDeadtimestamp(
              this.queryForm.innerDeadDate[1]
            );
          }
        }

        (this.queryForm.userNo = this.userNo),
          (this.queryForm.userRoleNo = getRoleKey("customer", this.roles)),
          (this.param.body = this.queryForm);

        const { body } = await getDone(this.param);
        this.list = body.records;
        this.total = body.total;
        this.$emit("update:done-val", this.total);
        this.listLoading = false;
      },
      testMessage() {
        this.$baseMessage("test1", "success");
      },
      testALert() {
        this.$baseAlert("11");
        this.$baseAlert("11", "自定义标题", () => {
          /* 可以写回调; */
        });
        this.$baseAlert("11", null, () => {
          /* 可以写回调; */
        });
      },
      testConfirm() {
        this.$baseConfirm(
          "你确定要执行该操作?",
          null,
          () => {
            /* 可以写回调; */
          },
          () => {
            /* 可以写回调; */
          }
        );
      },
      testNotify() {
        this.$baseNotify("测试消息提示", "test", "success", "bottom-right");
      },
      handleChange(value) {
        this.queryForm.addressProvince = this.queryForm.selectedOptions[0];
        this.queryForm.addressCity = this.queryForm.selectedOptions[1];
        this.queryForm.addressDistrict = this.queryForm.selectedOptions[2];
      },
      // 打开工单单详情页面
      // goDetailPage(row) {
      //   this.$router.push("/workplace/supervision/recommend/detail");
      // },
    },
  };
</script>
<style scoped>
  .el-col {
    margin-bottom: 10px;
  }
  .el-col :last-child {
    margin-bottom: 0;
  }

  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100%;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }
  .my-form-class {
    margin-bottom: -10px !important;
  }
</style>
<style>
  .my-back-img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 2px;
  }
  .el-dropdown-menu__item {
    padding: 0;
  }
</style>
