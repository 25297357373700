var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "预览",
        visible: _vm.phoneOpen,
        width: "50%",
        "close-on-click-modal": false,
        "append-to-body": "",
        "before-close": _vm.getBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.phoneOpen = $event
        },
      },
    },
    [
      _vm._l(_vm.formTemplate, function (item, index) {
        return _c(
          "el-collapse",
          {
            key: index,
            model: {
              value: _vm.activeNames,
              callback: function ($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames",
            },
          },
          [
            _c(
              "el-collapse-item",
              { attrs: { title: item.label, name: index } },
              [
                _c("VueDragFormBuild", {
                  attrs: { "form-template": item, models: _vm.models },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.getBack } }, [_vm._v("返 回")])],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }