// Kyle API 工作台 -> 工具
import request from "@/utils/request";

// 获取地区
export function getBlock(data) {
  return request({
    url: "/siteLocal/query",
    method: "post",
    data,
  });
}

// 查询项目名称和类型列表
export function getProjectSource(data) {
  return request({
    url: "/queryProjectAndSourceList",
    method: "post",
    data,
  });
}

// 使用角色查询人员配置列表
export function getRole(data) {
  return request({
    url: "/queryUsersListByRole",
    method: "post",
    data,
  });
}

// 查询  退回原因[退回原因+ 普通/待定]，放弃原因[上门前放弃,上门后]
export function getAllReasonOption(data) {
  return request({
    url: "/queryCommentTemplateTree",
    method: "post",
    data,
  });
}
// 查询  退回原因【 普通 】
export function getGeneralReason(data) {
  return request({
    url: "/queryCommentTemplateContent",
    method: "post",
    data,
  });
}

// 获取工单状态
export function getWorkOrderStatus(data) {
  return request({
    url: "/queryWorkOrderStatus",
    method: "post",
    data,
  });
}

// 获取查询操作状态
export function getWorkOperStatus(data) {
  return request({
    url: "/queryWorkOperStatus",
    method: "post",
    data,
  });
}
// 获取退回类型
export function getBackType(data) {
  return request({
    url: "/queryCommentTemplateType",
    method: "post",
    data,
  });
}
// 工单详情-退回记录
export function getBackRecords(data) {
  return request({
    url: "/workOrderDetails/queryReturnReson",
    method: "post",
    data,
  });
}
// 荐获取详情
export function getOrderDetail(data) {
  return request({
    url: "/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}
// 获取操作信息
export function getOperationInfo(data) {
  return request({
    url: "/log/query",
    method: "post",
    data,
  });
}
// 获取工单详情-小记查询
export function getNoteList(data) {
  return request({
    url: "/workOrderDetails/queryNote",
    method: "post",
    data,
  });
}
// 使用角色查询用户列表
export function getUsersListByRole(data) {
  return request({
    url: "/queryUsersListByRole",
    method: "post",
    data,
  });
}

/** 首页事务查询 */
export function todoSearch(data) {
  return request({
    url: "/homePage/queryPendingTransaction",
    method: "post",
    data,
  });
}

/** 首页统计总数 */
export function searchTotal(data) {
  return request({
    url: "/homePage/queryWorkOrderStatisticsSum",
    method: "post",
    data,
  });
}

/** 首页统计总数 */
export function searchStatistic(data) {
  return request({
    url: "/homePage/queryWorkOrderStatistic",
    method: "post",
    data,
  });
}

/** 查询详情附件 */
export function searchAttach(data) {
  return request({
    url: "/workOrderDetails/queryAttach",
    method: "post",
    data,
  });
}

/** 根据userNo查询角色列表 */
export function queryRolesByUserNo(data) {
  return request({
    url: "/queryRolesByUserNo",
    method: "post",
    data,
  });
}

/** oss参数查询 */

export function queryOssSearch(data) {
  return request({
    url: "/ossParams",
    method: "post",
    data,
  });
}

/** 查询系统所有角色及用户 */
export function querySystemUsers(data) {
  return request({
    url: "/queryRolesAndUsers",
    method: "post",
    data,
  });
}

// 查询项目名称和类型列表
export function getReportTypeList(data) {
  return request({
    url: "/br/queryReportType",
    method: "post",
    data
  });
}
