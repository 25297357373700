<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否退回？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="退回原因" prop="title">
        <el-select v-model="form.title" placeholder="请选择退回原因" clearable>
          <el-option
            v-for="item in withdrawOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "TableEdit",
    data() {
      return {
        loading: false,
        form: {
          title: "",
          author: "",
          remark: "",
        },
        rules: {
          remark: [{ required: true, trigger: "blur", message: "请输入作者" }],
        },
        title: "",
        dialogFormVisible: false,
        withdrawOptions: [
          {
            value: "选项1",
            label: "不愿意通过",
          },
          {
            value: "选项2",
            label: "资格不够",
          },
          {
            value: "选项3",
            label: "你再想想",
          },
          {
            value: "选项4",
            label: "想不起来",
          },
          {
            value: "选项5",
            label: "算了不申请了",
          },
        ],
      };
    },
    created() {},
    methods: {
      showWithdraw(row) {
        this.title = "退回工单";
        this.form = Object.assign({}, row);
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("fetchData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            const { msg } = await doEdit(this.form);
            this.$baseMessage(msg, "success");
            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("fetchData");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
