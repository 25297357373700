// 内审工作天
import request from "@/utils/request";

// 内审待处理列表
export function getTodo(data) {
  return request({
    url: "/workOrderAudit/queryPendingPage",
    method: "post",
    data,
  });
}
// 内审已处理列表
export function getDone(data) {
  return request({
    url: "/workOrderAudit/queryProcessedPage",
    method: "post",
    data,
  });
}
// 内审已处理列表
export function getDoults(data) {
  return request({
    url: "/workOrderAudit/queryQuestionPage",
    method: "post",
    data,
  });
}
// 单独记录小记
export function saveNote(data) {
  return request({
    url: "/user/note",
    method: "post",
    data,
  });
}
// 内审提交 审核状态不为11调本接口
export function submit(data) {
  return request({
    url: "/workOrderAudit/commit",
    method: "post",
    data,
  });
}
// 内审提交 审核状态11调本接口
export function submitOne(data) {
  return request({
    url: "/workOrderAudit/abandon",
    method: "post",
    data,
  });
}
// 内审退回
export function fallBack(data) {
  return request({
    url: "/workOrderAudit/fallback",
    method: "post",
    data,
  });
}

// 内审驳回
export function reject(data) {
  return request({
    url: "/workOrderAudit/reject",
    method: "post",
    data,
  });
}
// 查询放弃退回人员
export function getReturnTo(data) {
  return request({
    url: "/workOrderAudit/queryAbandonFallback",
    method: "post",
    data,
  });
}

// 查询放弃退回人员
export function getRecordTable(data) {
  return request({
    url: "/workOrderAudit/queryRecord",
    method: "post",
    data,
  });
}

// 质疑 查看富文本
export function queryContent(data) {
  return request({
    url: "/queryComment",
    method: "post",
    data,
  });
}
// 质疑 查看质疑原因
export function getRecordReason(data) {
  return request({
    url: "/workOrderAudit/queryQuestionContent",
    method: "post",
    data,
  });
}


/**
 * 支持文件数量查询
 */
export function querySupportFileCount(workNo) {
  return request({
    url: "/querySupportFileCount",
    method: "get",
    params: {
      workNo,
    },
  });
}
