// Kyle API 调查工作台 -> 工单录入 -> 已处理
import request from "@/utils/request";

// 查询督导工作台待处理列表
export function getTodo(data) {
  return request({
    url: "/recommend/queryPendingPage",
    method: "post",
    data,
  });
}
// 查询督导工作台已处理列表
export function getDone(data) {
  return request({
    url: "/recommend/queryProcessedPage",
    method: "post",
    data,
  });
}
// 使用角色查询用户列表
export function getUsersListByRole(data) {
  return request({
    url: "/queryUsersListByRole",
    method: "post",
    data,
  });
}

// 督导-人员推荐提交
export function submit(data) {
  return request({
    url: "/recommend/submit",
    method: "post",
    data,
  });
}
// 督导-人员推荐获取详情
export function getOrderDetail(data) {
  return request({
    url: "/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}
// 督导-人员推荐获取详情-获取操作信息
export function getOperationInfo(data) {
  return request({
    url: "/log/query",
    method: "post",
    data,
  });
}
// 督导-人员推荐 工单退回
export function withdraw(data) {
  return request({
    url: "/recommend/fallback",
    method: "post",
    data,
  });
}
// 督导-人员推荐 工单编辑
export function edit(data) {
  return request({
    url: "/recommend/update",
    method: "post",
    data,
  });
}
// 修改成功之后返显推荐人员信息
export function getRecommend(data) {
  return request({
    url: "/recommend/queryRecoSurveyInfo",
    method: "post",
    data,
  });
}
