import request from "@/utils/request";

// 查询工单导出权限树
export function getTreeData(data) {
  return request({
    url: "/queryWorkOrderExportAuth",
    method: "post",
    data,
  });
}

// 查询工单导出详情
export function getCheckboxData(data) {
  return request({
    url: "/queryWorkOrderExportConfig",
    method: "post",
    data,
  });
}

// 工单导出权限-启停
export function changeRole(data) {
  return request({
    url: "/enableOrDisableExportAuth",
    method: "post",
    data,
  });
}
// 查询角色下的菜单
export function queryMenus(data) {
  return request({
    url: "/queryMenu",
    method: "post",
    data,
  });
}
// 工单导出配置-启停
export function setDetail(data) {
  return request({
    url: "/enableOrDisableExportConfig",
    method: "post",
    data,
  });
}

// *************************
// 查询字段编辑
export function getFields(data) {
  return request({
    url: "/queryFieldEdit",
    method: "post",
    data,
  });
}
// 查询字段编辑
export function getFieldType(data) {
  return request({
    url: "/queryTemplateType",
    method: "post",
    data,
  });
}
// 新增字段信息
export function createField(data) {
  return request({
    url: "/insertCommentTemplate",
    method: "post",
    data,
  });
}
// 编辑字段信息
export function updateField(data) {
  return request({
    url: "/editCommentTemplate",
    method: "post",
    data,
  });
}
// 字段升序降序
export function upOrDown(data) {
  return request({
    url: "/updateCommentTemplate",
    method: "post",
    data,
  });
}
