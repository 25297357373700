<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    append-to-body
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否确认提交？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="督导人员" prop="monitorUser">
        <el-select
          v-model="form.monitorUser"
          placeholder="请选择督导人员"
          clearable
          filterable
        >
          <el-option
            v-for="item in investigateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model.trim="form.note"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    getUsersListByRole,
    submit,
  } from "@/api/workplace/investigate/investigate";
  import { mapGetters } from "vuex";
  import { returnBitTimestamp, getRoleKey } from "@/utils";

  export default {
    name: "Submit",
    data() {
      return {
        loading: false,
        openLevel: 0,
        form: {
          monitorUser: null,
          note: null,
        },
        rules: {
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
          monitorUser: [
            { required: true, trigger: "blur", message: "请选择督导人员" },
          ],
        },
        title: "",
        dialogFormVisible: false,
        investigateOptions: [],
        // 查新督导人员
        param: {
          body: [
            {
              roleKey: "supervisor",
            },
          ],
          header: {
            currentTime: "",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 提交工单调查参数
        parameter: {
          body: [],
          header: {
            currentTime: "",
            requestId: "1",
            sourceType: "site",
          },
        },
        obj: {
          console: "investigator", // 固定写法
          note: null,
          operAction: "工单录入", // 固定写法
          step: "调查工作台-工单录入-待处理", // 固定写法
          monitorUser: null,
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
          procInsId: null,
          taskId: null,
          // 异步生成报告5个触发点参数
          triggerPoint: "survey_commit",
        },
        object: null,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.obj.userNo = this.userNo;
      this.obj.userRoleNo = getRoleKey("investigate", this.roles);
    },
    methods: {
      async showSubmit(row) {
        this.title = "提交工单";
        this.param.header.currentTime = returnBitTimestamp(new Date());
        // 查新督导人员
        const { body } = await getUsersListByRole(this.param);
        this.investigateOptions = body;

        this.form = Object.assign({}, row[0]);

        this.object = row;

        this.dialogFormVisible = true;
        this.parameter.body = [];
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.parameter.header.currentTime = returnBitTimestamp(new Date());
            this.loading = true;
            for (let i = 0; i < this.object.length; i++) {
              this.obj.monitorUser = this.form.monitorUser;
              this.obj.note = this.form.note;
              this.obj.procInsId = this.object[i].procInsId;
              this.obj.taskId = this.object[i].taskId;
              this.obj.projectSource = this.object[i].source;
              this.obj.workOrderNo = this.object[i].workOrderNo;
              this.parameter.body.push(Object.assign({}, this.obj));
            }
            const { header } = await submit(Object.assign({}, this.parameter));
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            if (this.openLevel === 0) {
              //列表页打开提交框
            } else {
              //编辑页打开提交框
              this.$emit("close");
            }
            /**优化刷新 */
            this.$emit("refreshData");
            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
