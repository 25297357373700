var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "myDialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "60%",
        fullscreen: _vm.fullFlag,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("el-backtop", { attrs: { target: ".myDialog" } }),
      _c("i", {
        class: _vm.fullIcon,
        staticStyle: {
          position: "absolute",
          top: "24px",
          right: "41px",
          cursor: "pointer",
        },
        attrs: { title: _vm.fullTitle },
        on: { click: _vm.triggerFullOrHelf },
      }),
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _vm._v(_vm._s(_vm.companyName)),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              staticClass: "edit_title",
              attrs: { label: "工单详情", name: "detail" },
            },
            [
              _vm.reviewFlag === "1"
                ? _c(
                    "el-form",
                    {
                      ref: "abandonForm",
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        model: _vm.abandonForm,
                        rules: _vm.abandonFormRules,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "放弃类型:",
                                    prop: "abandonType",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "abandonType",
                                    attrs: {
                                      rows: "1",
                                      type: "textarea",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.abandonForm.abandonType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.abandonForm,
                                          "abandonType",
                                          $$v
                                        )
                                      },
                                      expression: "abandonForm.abandonType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "放弃原因:",
                                    prop: "abandonReason",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "abandonReason",
                                    attrs: {
                                      rows: "1",
                                      type: "textarea",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.abandonForm.abandonReason,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.abandonForm,
                                          "abandonReason",
                                          $$v
                                        )
                                      },
                                      expression: "abandonForm.abandonReason",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reviewFlag !== "1"
                ? _c("order-process", { attrs: { param: _vm.param } })
                : _vm._e(),
              _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "workOrderNo",
                      label: "工单编号",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brWorkNo",
                      label: "订单编号",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.brWorkNo || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "reportType",
                      label: "报告类型",
                      "class-name":
                        _vm.brChange("reportTypeFlag") +
                        " " +
                        _vm.brChange("subReportTypeFlag"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.reportType || "-") +
                                " " +
                                _vm._s(scope.row.subReportType ? "/" : "") +
                                " " +
                                _vm._s(scope.row.subReportType) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "projectSource",
                      label: "项目来源",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getProjectSource(scope.row.projectSource)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "targetSubject",
                      label: "目标主体",
                      "class-name": _vm.brChange("subjectFlag"),
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "targetAddr1",
                      label: "目标地址1",
                      "class-name": _vm.brChange("subjectAddressFlag"),
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "targetAddr2",
                      label: "目标地址2",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "targetContact",
                      label: "目标联系人",
                      "class-name": _vm.brChange("subjectLinkNameFlag"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.targetContact || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contactNumber",
                      label: "联系电话",
                      "class-name": _vm.brChange("subjectMobileFlag"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.contactNumber || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "duty",
                      label: "职务",
                      "class-name": _vm.brChange("dutyFlag"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.duty || "-") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderTime",
                      label: "下单时间",
                      align: "center",
                      formatter: _vm.dateFormatHM,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "innerDeadline",
                      label: "内部截止日期",
                      formatter: _vm.eighthBitTime,
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "clientAbbr",
                      label: "委托方简称",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.clientAbbr || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "reportTimeLimit",
                      label: "报告时限",
                      "class-name": _vm.brChange("reportTimeLimitFlag"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.reportTimeLimit || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "remark1", label: "备注1", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.remark1 || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "remark2", label: "备注2", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.remark2 || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "remark3", label: "备注2", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.remark3 || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column"),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [_vm._v("附加信息")]),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "expectVisitDate",
                      label: "预计走访日期",
                      formatter: _vm.eighthBitTime,
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "expectServeyDay",
                      label: "预计所需调查天数",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.expectServeyDay || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "needUser",
                      label: "所需人手",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.needUser || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "expectHour",
                      label: "预计需时（小时）",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.expectHour || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "registerAddress",
                      label: "注册地址",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.registerAddress || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "targetSubjectRegStatus",
                      label: "目标主体注册状态",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.targetSubjectRegStatus || "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [_vm._v("特别信息")]),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "plSpecialMsg",
                      label: "PL特别留言",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.plSpecialMsg || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bookSpecialMsg",
                      label: "预约特别留言",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.bookSpecialMsg || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "monitorSpecialMsg",
                      label: "督导特别留言",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.monitorSpecialMsg || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.annexGroup.length !== 0,
                      expression: "annexGroup.length !== 0",
                    },
                  ],
                  staticClass: "title",
                },
                [_vm._v("附件")]
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "text-align": "center", "margin-top": "20px" },
                },
                _vm._l(_vm.annexGroup, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleDownload({
                                    name: item.fileName,
                                    path: item.path,
                                  })
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/icon/annex.svg"),
                                  alt: "",
                                  width: "80",
                                  height: "80",
                                },
                              }),
                              _c("p", [_vm._v(_vm._s(item.fileName))]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c("div", { staticClass: "title" }, [_vm._v("操作信息")]),
              _c(
                "el-table",
                { attrs: { data: _vm.operationInfo, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "operator",
                      label: "操作者",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.operator || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "role", label: "角色", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.role || "-") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "operAction",
                      label: "操作状态",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.operAction || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "操作时间",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.createTime || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "remark", label: "备注", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.remark || "-") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "取证资料", name: "evidence" } },
            [_c("evidence", { ref: "evidence", attrs: { row: _vm.object } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄照片" } },
            [
              _c("photo", {
                ref: "photo",
                attrs: { type: "50", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "拍摄录像" } },
            [
              _c("screenVideo", {
                ref: "screenVideo",
                attrs: { type: "51", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "扫描文件", name: "scanFile" } },
            [
              _c("scanFile", {
                ref: "scanFile",
                attrs: { type: "52", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "录制音频" } },
            [
              _c("screenAudio", {
                ref: "screenAudio",
                attrs: { type: "53", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "其他文件" } },
            [
              _c("telRecord", {
                ref: "telRecord",
                attrs: { type: "54", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "打卡记录" } },
            [
              _c("clockIn", {
                ref: "clockIn",
                attrs: { type: "55", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "会议信息" } },
            [
              _c("meeting", {
                ref: "meeting",
                attrs: { type: "57", row: _vm.object },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "查看小记" } },
            [_c("remark", { ref: "remark", attrs: { param: _vm.param } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "退回记录" } },
            [_c("back", { ref: "back", attrs: { order: _vm.orderNo } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "企业信息" } },
            [_c("company", { ref: "company", attrs: { row: _vm.object } })],
            1
          ),
        ],
        1
      ),
      !_vm.preview
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.openSmallNote = true
                    },
                  },
                },
                [_vm._v(" 添加小记 ")]
              ),
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.withdraw } },
                [_vm._v("退回")]
              ),
              _vm.activeName === "evidence"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleReport },
                    },
                    [_vm._v(" 生成报告 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitClick } },
                [_vm._v("提交")]
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提交",
            visible: _vm.fileCheck,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fileCheck = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { margin: "20px" } }, [
            _vm._v("没有支持文件，请确认是否提交?"),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.fileCheck = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.showSubmit } },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提交",
            visible: _vm.submitShow,
            width: "500px",
            "before-close": _vm.submitShowClose,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.submitShow = $event
            },
          },
        },
        [
          _vm.object &&
          _vm.object.auditStatusValue == "正常" &&
          _vm.object.brWorkNo
            ? _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                _vm._v("该工单是否确认提交至BR？"),
              ])
            : _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                _vm._v("该工单是否确认提交？"),
              ]),
          _c(
            "el-form",
            {
              ref: "submitForm",
              staticClass: "demo-form-inline",
              attrs: {
                model: _vm.submitForm,
                rules: _vm.submitFormRules,
                "label-width": "80px",
              },
            },
            [
              (_vm.object && _vm.object.auditStatusValue !== "正常") ||
              !_vm.object.brWorkNo
                ? [
                    _vm.reviewFlag === "0"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "用户", prop: "pchUser" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  autocomplete: "off",
                                  placeholder: "请选择用户",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.submitForm.pchUser,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.submitForm, "pchUser", $$v)
                                  },
                                  expression: "submitForm.pchUser",
                                },
                              },
                              _vm._l(_vm.userOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.reviewFlag === "2"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "用户", prop: "pchUserName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                autocomplete: "off",
                                disabled: "",
                              },
                              model: {
                                value: _vm.submitForm.pchUserName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.submitForm, "pchUserName", $$v)
                                },
                                expression: "submitForm.pchUserName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "小记", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.submitForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitForm, "note", $$v)
                      },
                      expression: "submitForm.note",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.submitShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitSave("submitForm")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加小记",
            visible: _vm.openSmallNote,
            width: "40%",
            "close-on-click-modal": false,
            "before-close": _vm.smallNoteClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openSmallNote = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "smallNoteForm",
              staticClass: "my-note",
              attrs: {
                model: _vm.smallNoteForm,
                rules: _vm.smallNoteFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "小记", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.smallNoteForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.smallNoteForm, "note", $$v)
                      },
                      expression: "smallNoteForm.note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.openSmallNote = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveSmallNote("smallNoteForm")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退回",
            visible: _vm.backShow,
            "close-on-click-modal": false,
            "before-close": _vm.backShowClose,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.backShow = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v("该工单是否退回？"),
          ]),
          _c(
            "el-form",
            {
              ref: "backForm",
              attrs: {
                model: _vm.backForm,
                rules: _vm.backFormRules,
                "label-width": "80px",
              },
            },
            [
              _vm.showSelect
                ? _c(
                    "el-form-item",
                    { attrs: { label: "退回至", prop: "nextUserRoleNo" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择退回至",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.backForm.nextUserRoleNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.backForm, "nextUserRoleNo", $$v)
                            },
                            expression: "backForm.nextUserRoleNo",
                          },
                        },
                        _vm._l(_vm.roleOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showReturn
                ? _c(
                    "el-form-item",
                    { attrs: { label: "退回至", prop: "returnToName" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.backForm.returnToName,
                          callback: function ($$v) {
                            _vm.$set(_vm.backForm, "returnToName", $$v)
                          },
                          expression: "backForm.returnToName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "退回原因", prop: "fallbackCommentId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "退回原因",
                        clearable: true,
                        filterable: "",
                      },
                      model: {
                        value: _vm.backForm.fallbackCommentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.backForm, "fallbackCommentId", $$v)
                        },
                        expression: "backForm.fallbackCommentId",
                      },
                    },
                    _vm._l(_vm.backOptions, function (item) {
                      return _c("el-option", {
                        key: item.seqId,
                        attrs: { label: item.content, value: item.seqId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小记", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.backForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.backForm, "note", $$v)
                      },
                      expression: "backForm.note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.backShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.backLoading },
                  on: {
                    click: function ($event) {
                      return _vm.backSave("backForm")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }