<template>
  <div>
    <!--工单导出-->
    <el-row :gutter="15">
      <!--左侧页面-->
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card class="myStyle">
          <el-form
            ref="authorForm"
            :model="authorForm"
            status-icon
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="工单导出权限" prop="label">
              <el-tree
                ref="tree"
                v-model="authorForm.label"
                :data="data"
                show-checkbox
                node-key="menuId"
                default-expand-all
                :props="defaultProps"
                :default-checked-keys="checkKeys"
                @check-change="checkChange"
              ></el-tree>
            </el-form-item>
            <el-form-item>
              <el-button
                class="myBtn"
                type="primary"
                disabled
                @click="handleCheckChange"
              >
                保存
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <!--右侧页面-->
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card class="myStyle">
          <el-form status-icon label-width="100px">
            <el-form-item label="工单导出角色">
              <div class="tag-group">
                <el-tag
                  v-for="item in roles"
                  :key="item"
                  :type="item"
                  effect="plain"
                  @click="getMenu(item)"
                >
                  {{ item }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item label="工单导出菜单" prop="seqId">
              <div class="tag-group">
                <el-tag
                  v-for="item in menus"
                  :key="item.seqId"
                  v-model="menuId"
                  :type="item.type"
                  effect="plain"
                  @click="getDetail(item.seqId)"
                >
                  {{ item.menuName }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item label="工单导出详情" prop="pass">
              <el-checkbox
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
              >
                全选
              </el-checkbox>
              <div style="margin: 15px 0"></div>
              <el-checkbox-group
                v-model="checkedFields"
                @change="handleCheckedFieldsChange"
              >
                <el-checkbox
                  v-for="field in fields"
                  :key="field.configName"
                  :label="field.configName"
                  style="width: 8em"
                >
                  {{ field.configName }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item>
              <el-button
                class="myBtn"
                type="primary"
                disabled
                @click="submitForm('detailForm')"
              >
                保存
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    getTreeData,
    getCheckboxData,
    changeRole,
    queryMenus,
    setDetail,
  } from "@/api/project/setting";
  export default {
    name: "ComprehensiveTable",

    filters: {
      statusFilter(status) {
        const statusMap = {
          published: "success",
          draft: "gray",
          deleted: "danger",
        };
        return statusMap[status];
      },
    },
    data() {
      return {
        roles: [],
        menus: [],
        activeName: "second",
        checkAll: false,
        checkedFields: [],
        menuId: null,
        fields: [],
        role: null,
        menu: null,
        isIndeterminate: true,

        authorForm: {
          label: null,
        },
        detailForm: {
          item: "",
        },
        data: [],
        defaultProps: {
          children: "children",
          label: "label",
        },
        checkKeys: [],
        param: {
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          body: [],
        },
        parameter: {
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          body: {},
        },
        obj: {
          seqId: null,
          enabled: 0,
        },

        // 详情参数
        detailParam: {
          body: {
            configList: [],
            menuId: "",
          },
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
      };
    },

    created() {
      this.fetchTreeData();
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      // 获取工单导出权限树
      async fetchTreeData() {
        this.listLoading = true;
        const { body } = await getTreeData(this.param).catch(() => {});
        this.data = body;
        this.data.map((item) => {
          let menus = item.children;
          menus.map((menu) => {
            /* update by cfe 临时处理，默认选中全部*/
            // if (1 === menu.enabled) {
            this.checkKeys.push(menu.menuId);
            if (this.roles.indexOf(item.label) === -1) {
              this.roles.push(item.label);
              this.roles.sort();
              this.getDetail(menu.menuId);
            }
            // }
          });
        });
        for (let key of this.roles) {
          await this.getMenu(key);
        }
        // 督导人员预约人员
        setTimeout(() => {
          this.listLoading = false;
        }, 500);
      },
      // 选中后改变enabled值
      checkChange(data, checked) {
        if (checked) {
          data.enabled = 1;
        } else {
          data.enabled = 0;
        }
      },
      // 保存工单导出权限
      async handleCheckChange() {
        this.roles = [];
        this.param.body = [];

        this.data.forEach((role) => {
          if (role.hasOwnProperty("children")) {
            role.children.forEach((son) => {
              (this.obj.seqId = son.menuId), (this.obj.enabled = son.enabled);
              this.param.body.push(Object.assign({}, this.obj));
              if (1 === son.enabled) {
                this.checkKeys.push(son.menuId);
                if (this.roles.indexOf(role.label) === -1) {
                  this.roles.push(role.label);
                }
              }
            });
          }
        });
        this.roles.sort();
        const { header } = await changeRole(this.param).catch(() => {});
        if (header.retCode === "1") {
          this.$baseMessage(header.retMessage, "success");
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      },
      // 获取工单导出菜单
      async getMenu(param) {
        this.$set(this.parameter.body, "roleName", param);
        const { body } = await queryMenus(this.parameter).catch(() => {});
        for (let i = 0; i < body.length; i++) {
          if (1 === body[i].enabled) {
            this.menus.push(body[i]);
            await this.getDetail(body[i].seqId);
          }
        }
        this.menus.sort();
      },
      // 获取工单导出详情
      async getDetail(param) {
        this.fields = [];
        this.checkedFields = [];
        this.parameter.body.menuId = param;
        const { body } = await getCheckboxData(this.parameter).catch(() => {});
        this.fields = body;
        let fieldNames = [];
        this.fields.map((item) => {
          if (item.enabled === 1) {
            fieldNames.push(item.configName);
          }
        });
        this.checkedFields = fieldNames;
        this.menuId = param;
      },
      handleCheckAllChange(val) {
        let fieldNames = [];
        this.fields.map((item) => {
          fieldNames.push(item.configName);
        });
        this.checkedFields = val ? fieldNames : [];
        this.isIndeterminate = false;
      },
      handleCheckedFieldsChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.fields.length;
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.fields.length;
      },

      // 提交详情
      async submitForm() {
        this.detailParam.body.configList = [];
        this.detailParam.body.menuId = this.menuId;
        if (this.checkedFields.length > 0) {
          this.fields.forEach((field) => {
            field.enabled = 0;
            this.detailParam.body.configList.push(Object.assign({}, field));
          });
          this.fields.forEach((field) => {
            this.checkedFields.forEach((fieldName) => {
              if (fieldName.trim() === field.configName.trim()) {
                field.enabled = 1;
              }
            });
            this.detailParam.body.configList.push(Object.assign({}, field));
          });
        } else {
          this.fields.forEach((field) => {
            field.enabled = 0;
            this.detailParam.body.configList.push(Object.assign({}, field));
          });
        }

        const { header } = await setDetail(this.detailParam).catch(() => {});
        if ("1" === header.retCode) {
          this.$baseMessage(header.retMessage, "success");
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      },
    },
  };
</script>
<style scoped>
  .myStyle {
    min-height: calc(100vh - 55px - 55px - 20px - 20px - 55px);
  }
</style>
