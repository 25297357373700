var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.meetingData, stripe: "", border: "" } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "startTime",
              label: "会议开始时间",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "endTime", label: "会议结束时间", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "录像名称", align: "center" },
          }),
        ],
        1
      ),
      _vm.total > 2
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-card",
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: _vm.background,
                          "current-page": _vm.meetingParam.pageNum,
                          layout: _vm.layout,
                          "page-size": _vm.meetingParam.pageSize,
                          total: _vm.total,
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange,
                          "size-change": _vm.handleSizeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleView },
                },
                [_vm._v(" 查看录像 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("meetingView", { ref: "meetingView" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }