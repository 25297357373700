// Burton API 企业信息
import request from "@/utils/request";

export function getCompanyDetailInfo(data) {
  return request({
    url: "/siteCompanyInfo/getDetailByName",
    method: "post",
    data,
  });
}
export function getMainPerson(data) {
  return request({
    url: "/siteCompanyInfo/findEmployeesByKeyword",
    method: "post",
    data,
  });
}
export function getDepartment(data) {
  return request({
    url: "/siteCompanyInfo/getBranchByName",
    method: "post",
    data,
  });
}
export function getChangeRecord(data) {
  return request({
    url: "/siteCompanyInfo/getCompanyChangeLogPageList",
    method: "post",
    data,
  });
}
export function getShareholder(data) {
  return request({
    url: "/siteCompanyInfo/getPartnersPageListByName",
    method: "post",
    data,
  });
}
export function getYearReport(data) {
  return request({
    url: "/siteCompanyInfo/getReportListByName",
    method: "post",
    data,
  });
}
export function getEquityPledge(data) {
  return request({
    url: "/siteCompanyInfo/getEquityqualityListByNamePost",
    method: "post",
    data,
  });
}
export function getAbnormalOperation(data) {
  return request({
    url: "/siteCompanyInfo/getAbnormalListByNamePost",
    method: "post",
    data,
  });
}
export function getBlackList(data) {
  return request({
    url: "/getSeriousByName",
    method: "post",
    data,
  });
}
export function getLawsuitsPost(data) {
  return request({
    url: "/siteCompanyInfo/getLawsuitsPost",
    method: "post",
    data,
  });
}
export function getNoticeDetail(data) {
  return request({
    url: "/siteCompanyInfo/getKaitinggonggaosRelationsPost",
    method: "post",
    data,
  });
}
export function getCasesPost(data) {
  return request({
    url: "/siteCompanyInfo/getCasesPageList",
    method: "post",
    data,
  });
}
export function getDataPost(data) {
  return request({
    url: "/getDataPost",
    method: "post",
    data,
  });
}
export function getPersonsPost(data) {
  return request({
    url: "/getPersonsPost",
    method: "post",
    data,
  });
}
export function getJobInfo(data) {
  return request({
    url: "/siteCompanyInfo/getNewJobsListByName",
    method: "post",
    data,
  });
}
export function getHugeTaxPunishment(data) {
  return request({
    url: "/siteCompanyInfo/getHugeTaxPunishmentByName",
    method: "post",
    data,
  });
}
export function getPunishments(data) {
  return request({
    url: "/siteCompanyInfo/getPunishments",
    method: "post",
    data,
  });
}
