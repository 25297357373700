<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plaind
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class">
            <el-row :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="transitDate">
                  <el-date-picker v-model="queryForm.transitDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="流转开始日期"
                                  end-placeholder="流转结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number.trim="queryForm.workOrderNo"
                            placeholder="工单编号"></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            placeholder="目标主体"></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               :filterable="true"
                               clearable
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="isShow"
                    :gutter="20">
              <!-- 内部截止 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="innerDeadDate">
                  <el-date-picker v-model="queryForm.innerDeadDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="内部截止开始日期"
                                  end-placeholder="内部截止结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 工单状态 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderStatus">
                  <el-select v-model="queryForm.workOrderStatus"
                             placeholder="工单状态"
                             clearable
                             filterable>
                    <el-option v-for="item in orderStatusOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 选择人员 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="userInfo">
                  <el-cascader :value="queryForm.userInfo"
                               :options="staffInfo"
                               clearable
                               :filterable="true"
                               separator=":"
                               placeholder="选择人员"
                               @change="handleSatffChange"></el-cascader>
                </el-form-item>
              </el-col>
              <!-- 操作状态 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="operateStatus">
                  <el-select v-model="queryForm.operStatus"
                             placeholder="操作状态"
                             clearable
                             filterable>
                    <el-option v-for="item in operateOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="orderDate">
                  <el-date-picker v-model="queryForm.orderDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 选择地区 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="address">
                  <el-cascader :value="queryForm.address"
                               :options="cityInfo"
                               :change-on-select="false"
                               :clearable="true"
                               :filterable="true"
                               placeholder="选择地区"
                               :props="{ checkStrictly: true }"
                               @change="handleBlockChange"></el-cascader>
                </el-form-item>
              </el-col>
              <!-- 订单编号 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="brWorkNo">
                  <el-input v-model.trim="queryForm.brWorkNo"
                            placeholder="订单编号"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 报告类型 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="reportType">
                  <el-select v-model="queryForm.reportType"
                             placeholder="报告类型"
                             clearable
                             filterable>
                    <el-option v-for="item in reportType"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 项目来源 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectSource">
                  <el-select v-model="queryForm.projectSource"
                             placeholder="请选择项目来源"
                             clearable
                             filterable>
                    <el-option v-for="item in resourceOptions"
                               :key="item.key"
                               :label="item.name"
                               :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <!--已处理工单按钮-->
          <el-button icon="el-icon-s-custom"
                     plain
                     @click="adjustStaff">
            调整兼职调查员
          </el-button>
          <el-button :loading="downloadLoading"
                     type="primary"
                     @click="handleDownload">
            导出
          </el-button>
          <!--已处理工单列表-->
          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    stripe
                    style="margin-top: 15px"
                    border
                    :element-loading-text="elementLoadingText"
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"></el-table-column>
            <el-table-column label="工单编号"
                             prop="OrderNum"
                             align="left"
                             width="130">
              <template slot-scope="scope">
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="工单状态"
                             prop="workOrderStatusValue"
                             width="100"></el-table-column>
            <!-- 预约走访时间 -->
            <el-table-column prop="bookVisitTime"
                             label="预约走访时间"
                             width="130"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="visitTime"
                             label="走访时间"
                             width="100"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.visitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="nowUserName"
                             label="当前操作人">
              <template slot-scope="scope">
                {{ scope.row.nowUserName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="circulationTime"
                             label="流转日期">
              <template slot-scope="scope">
                {{ scope.row.circulationTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             label="环节/整体滞留(天)"
                             fit="true"
                             width="90"></el-table-column>
            <el-table-column prop="operStatusValue"
                             label="操作状态"
                             width="90px"
                             align="center"></el-table-column>
            <el-table-column label="操作"
                             width="80px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="详情"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-document"
                             circle
                             @click="goDetailPage(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!-- 调整人员 -->
    <el-dialog :title="staffTitle"
               :visible.sync="openAdjustStaff"
               width="40%"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :close="adjustStaffClose">
      <el-form ref="adjustFrom"
               :model="adjustFrom"
               :rules="adjustFromRules"
               style="text-align: center">
        <el-form-item label="调整人员"
                      prop="staffValue">
          <el-select v-model="adjustFrom.staffValue"
                     :multiple="false"
                     :multiple-limit="multipleLimit"
                     placeholder="请选择"
                     filterable
                     style="width: 260px"
                     @change="adjustStaffChange">
            <el-option v-for="item in roleOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="adjustStaffClose">取 消</el-button>
        <el-button type="primary"
                   :loading="adjustLoading"
                   @click="staffConfirm('adjustFrom')">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDone, doDelete } from "@/api/workplace/supervison/recommend"
import { adjustPerson } from "@/api/workplace/commonOperation"
import {
	returnBitTimestamp,
	returnDeadtimestamp,
	getRoleKey,
	eighthBitTimestamp,
} from "@/utils"
import { mapGetters } from "vuex"
import {
	getAllReasonOption,
	getBlock,
	getProjectSource,
	getWorkOperStatus,
	getWorkOrderStatus,
	getRole,
	querySystemUsers,
	getReportTypeList,
} from "@/api/workplace/tools"
import { export_json_to_excel } from "@/vendor/ExportExcel"

export default {
	name: "ComprehensiveTable",
	filters: {
		statusFilter(status) {
			const statusMap = {
				published: "success",
				draft: "gray",
				deleted: "danger",
			}
			return statusMap[status]
		},
	},
	data() {
		return {
			isShow: false,
			imgShow: true,
			list: [],
			imageList: [],
			listLoading: true,
			adjustLoading: false,
			layout: "total, sizes, prev, pager, next, jumper",
			total: 0,
			background: true,
			selectRows: "",
			elementLoadingText: "正在加载...",
			adjustFrom: {
				staffValue: "",
			},
			adjustFromRules: {
				staffValue: [
					{ required: true, message: "请选择调整人员", trigger: "change" },
				],
			},
			multipleLimit: 0,
			// 调整人员
			adjustOk: [],
			adjustNo: [],
			staffPortForm: {
				body: {
					userAfter: "",
					userAfterRole: "part_investigator",
					workflowInfoReqList: [],
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			queryForm: {
				transitDate: [], // 流转日期范围，不传递到后端
				circulationTimeEnd: null,
				circulationTimeStart: null,
				orderDate: [], // 下单时间范围，不传递到后端
				orderTimeEnd: null,
				orderTimeStart: null,
				workOrderNo: "",
				targetSubject: "",
				projectId: "",
				userInfo: null, // 人员信息
				roleUser: null, // 选择人员参数

				workOrderStatus: "",
				surveyUser: "",
				bookUser: "",

				innerDeadDate: [], // 内部截止日期范围，不传递到后端
				innerDeadlineEnd: null,
				innerDeadlineStart: null,
				plUser: "",
				operStatus: "",
				fallbackReasonId: "",

				address: "",

				pageNum: 1,
				pageSize: 10,
				nextUserNo: "",
				nextUserRoleNo: "",
				// userNo: "",
				// userRoleNo: "",
			},
			param: {
				body: {},
				header: {
					currentTime: "2020-09-09 12:12:12",
					requestId: "1",
					sourceType: "site",
				},
			},
			// 调整人员
			staffTitle: "调整兼职调查员",
			openAdjustStaff: false,
			transferTitle: ["选择员工", "已选择 1 个，还可以选 0 个"],
			toData: [],
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit("pick", [start, end])
						},
					},
				],
			},
			operateOptions: [],

			reasonOptions: [],
			//  工单状态
			orderStatusOptions: [],
			// 退回原因form
			allBackForm: {
				body: {
					templateType: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			staffInfo: [],
			staffForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			projectInfo: [], // 项目
			projectForm: {
				body: {
					source: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			//   角色人员
			roleOptions: [],
			roleOptions_arr: [],
			// 可调整人员数组
			adjList: [],
			roleForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},

			cityInfo: [],
			cityForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			// 操作状态
			operStatusInfo: [],
			operStatusForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			//放弃原因
			allAbandonInfo: [],
			downloadLoading: false,
			reportType: [], // 报告类型
			// 项目来源
			resourceOptions: [
				{
					name: "香港业务流程",
					key: "process_hk",
				},
				{
					name: "本地业务流程",
					key: "process_nd",
				},
				{
					name: "BR业务流程",
					key: "process_br",
				},
			],
		}
	},
	computed: {
		...mapGetters({
			userNo: "user/userNo",
			roles: "user/roles",
		}),
	},
	created() {
		this.param.body.userNo = this.userNo
		this.param.body.userRoleNo = getRoleKey("supervisor", this.roles)
	},
	beforeDestroy() {},
	mounted() {},
	methods: {
		/** 数据初始化 */
		handleInitData() {
			this.fetchData()
			// 获取项目
			this.getProject()
			// 获取人员选项
			this.getStaffInfo()
			this.getRoleList()
			// 获取报告类型
			this.getReportType()
		},
		/** 导出Excel */
		handleDownload() {
			if (this.selectRows.length > 0) {
				this.downloadLoading = true
				const tHeader = [
					"工单编号",
					"订单编号",
					"目标主体",
					"目标地址",
					"项目",
					"报告类型",
					"二级类型",
					"工单状态",
					"预约走访时间",
					"走访时间",
					"当前操作人",
					"流转日期",
					"环节/整体滞留(天)",
					"操作状态",
				]
				const filterVal = [
					"workOrderNo",
					"brWorkNo",
					"targetSubject",
					"address",
					"projectName",
					"reportType",
					"subReportType",
					"workOrderStatusValue",
					"bookVisitTime",
					"visitTime",
					"nowUserName",
					"circulationTime",
					"stepAndStranded",
					"operStatusValue",
				]
				const list = this.selectRows
				const data = this.formatJson(filterVal, list)
				export_json_to_excel({
					header: tHeader,
					data,
					filename: "工单列表",
				})
				this.downloadLoading = false
			} else {
				this.$baseMessage("未选中任何行", "error")
			}
		},
		/** 组装导出数据 */
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					if (!v[j]) {
						return "—"
					}
					if ("circulationTime" === j && v["circulationTime"]) {
						//格式化流转日期
						return eighthBitTimestamp(Date.parse(v["circulationTime"]))
					}
					return v[j]
				})
			)
		},
		setSelectRows(val) {
			this.selectRows = val
		},
		handleAdd() {
			this.$refs["edit"].showEdit()
		},
		handleEdit(row) {
			this.$refs["edit"].showEdit(row)
		},
		// 重置
		resetForm(formName) {
			if (this.$refs[formName] !== undefined) {
				this.$refs[formName].resetFields()
				this.queryForm = this.$options.data().queryForm
			}
		},
		handleSizeChange(val) {
			this.queryForm.pageSize = val
			this.fetchData()
		},
		handleCurrentChange(val) {
			this.queryForm.pageNum = val
			this.fetchData()
		},
		heightQuery() {
			this.isShow = !this.isShow
			// 展开时查询选项的下拉数据
			if (this.isShow) {
				// 获取工单状态
				this.getOrderStatus()
				// 获取地区
				this.getAddress()
				// 获取退回原因
				this.getAllBack()
				// 获取操作状态
				this.getOperStatus()
				//获取放弃原因
				this.getAllAbandon()
			}
		},
		// 获取工单状态
		getOrderStatus() {
			this.param.header.currentTime = returnBitTimestamp(new Date())
			getWorkOrderStatus(this.param).then((response) => {
				if ("1" === response.header.retCode) {
					this.orderStatusOptions = response.body
				} else {
					this.$message.error(
						`${response.header.retMessage},获取工单状态失败，请重新刷新页面`
					)
				}
			})
		},
		// 获取地区
		getAddress() {
			this.cityForm.header.currentTime = returnBitTimestamp(new Date())
			getBlock(this.cityForm).then((response) => {
				if ("1" === response.header.retCode) {
					let block_info = []
					block_info.push(response.body[0])
					this.cityInfo = block_info
				} else {
					this.$message.error(
						`${response.header.retMessage},获取地区失败，请重新刷新页面`
					)
				}
			})
		},
		// 选择地区
		handleBlockChange(value) {
			this.queryForm.address = value.join(",")
		},
		// 获取项目
		getProject() {
			this.projectForm.header.currentTime = returnBitTimestamp(new Date())
			getProjectSource(this.projectForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.projectInfo = response.body
				}
			})
		},
		/** 获取人员信息 */
		async getStaffInfo() {
			this.staffForm.header.currentTime = returnBitTimestamp(new Date())
			const { header, body } = await querySystemUsers(this.staffForm)
			if (header.retCode === "1") {
				this.staffInfo = body
			}
		},
		/** 选择人员数据框改变值 */
		handleSatffChange(value) {
			if (value) this.queryForm.userInfo = value
		},
		// 选择项目
		handleProjectChange(value) {
			if (value) {
				this.queryForm.projectId = value[1]
			}
		},
		// 获取退回原因 普通退回+待定退回
		getAllBack() {
			this.allBackForm.header.currentTime = returnBitTimestamp(new Date())
			this.allBackForm.body.templateType = "81"
			getAllReasonOption(this.allBackForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.reasonOptions = response.body
				}
			})
		},
		// 获取放弃原因 上门前放弃&上门后
		getAllAbandon() {
			let allAbandonForm = {}
			allAbandonForm.body = { templateType: "80" }
			getAllReasonOption(allAbandonForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.allAbandonInfo = response.body
				}
			})
		},
		// 获取操作状态
		getOperStatus() {
			this.operStatusForm.header.currentTime = returnBitTimestamp(new Date())
			getWorkOperStatus(this.operStatusForm).then((response) => {
				if (response.header.retCode === "1") {
					this.operateOptions = response.body
				} else {
					this.$message.error(
						`${response.header.retMessage},获取操作状态失败，请重新刷新页面`
					)
				}
			})
		},
		adjustStaffChange(value) {
			this.staffPortForm.body.userAfter = value
		},
		getRoleList() {
			let role_arr = []
			let roleForm = {
				body: [],
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			}
			let roleOptions_arr = []
			// 调查人员
			const surveyUser = [
				{
					roleKey: "full_investigator",
				},
				{
					roleKey: "part_investigator",
				},
			]
			// 预约人员
			const bookUser = [
				{
					roleKey: "book_person",
				},
			]
			// PL
			const pL = [
				{
					roleKey: "PL",
				},
			]
			const part_investigator = [
				{
					roleKey: "part_investigator",
				},
			]
			role_arr.push(surveyUser, bookUser, pL, part_investigator)
			// 异步调用接口，使其按顺序执行
			async function getAsyncRole() {
				roleForm.header.currentTime = returnBitTimestamp(new Date())
				for (let index = 0; index < role_arr.length; index++) {
					roleForm.body = role_arr[index]
					await getRole(roleForm).then((response) => {
						if ("1" === response.header.retCode) {
							roleOptions_arr.push(response.body)
						} else {
							roleOptions_arr.push([])
						}
					})
					if (index === role_arr.length - 1) {
						return roleOptions_arr
					}
				}
			}
			getAsyncRole().then((res) => {
				this.roleOptions_arr = res
			})
		},
		// 获取角色配置项
		getRoleOption(role) {
			switch (role) {
				case "surveyUser":
					this.roleOptions = this.roleOptions_arr[0]
					break
				case "bookUser":
					this.roleOptions = this.roleOptions_arr[1]
					break
				case "pL":
					this.roleOptions = this.roleOptions_arr[2]
					break
				case "part_investigator":
					this.roleOptions = this.roleOptions_arr[3]
				default:
					break
			}
		},
		//调整人员对话框
		adjustStaff(row) {
			if (this.selectRows.length > 0) {
				/** 经Ivan与产品经理bunny确认 调整人员不可超过50个工单 */
				if (this.selectRows.length > 50) {
					this.$baseMessage("建议调整人员不要超过50条工单", "error")
					return false
				}
				this.adjustLoading = false
				let adjOk = []
				let adjNo = []
				this.selectRows.map((item) => {
					/** 已处理中待调查，调查中方可调整兼职调查人员  */
					if (item.workOrderStatus === 4 || item.workOrderStatus === 5) {
						adjOk.push(item)
					} else {
						adjNo.push(item)
					}
				})
				if (adjNo.length === 0) {
					this.adjList = adjOk
					this.openAdjustStaff = true
					this.getRoleOption("part_investigator")
				} else {
					this.$message({
						dangerouslyUseHTMLString: true,
						showClose: true,
						duration: 10000,
						message: `<br>以下工单无法无法调整兼职调查人员:</br>
                  ${adjNo
										.map((item) => {
											return item.workOrderNo
										})
										.join(",")}`,
						type: "warning",
					})
				}
				// });
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},
		// 调整人员窗口关闭
		adjustStaffClose() {
			// this.staffValue = [];
			/** 避免重置后出现选择器不期望的数据格式 */
			this.openAdjustStaff = false
			this.$refs["adjustFrom"].resetFields()
			this.adjustLoading = false
		},
		/** 调整兼职调查人员 */
		staffConfirm(formData) {
			this.$refs[formData].validate(async (valid) => {
				if (valid) {
					this.staffPortForm.header.currentTime = returnBitTimestamp(new Date())
					// 接口所需其他参数对象
					const extra_obj = {
						console: "supervisor",
						note: null,
						remark: "调查人员已调整",
						operAction: "调整兼职调查人员", // 固定写法
						step: "督导工作台-人员推荐-已处理", // 固定写法,
						// nextUserNo: this.adjustFrom.staffValue.join(","),
						// nextUserRoleNo: "",
					}
					/** 后台规定传值，2020/11/11  SITE-1009 jira*/
					this.staffPortForm.body.userAfterRole = "part_investigator"
					this.staffPortForm.body.userAfter = this.adjustFrom.staffValue
					this.staffPortForm.body.workflowInfoReqList = this.adjList.map(
						(item) => {
							return Object.assign(
								{
									taskId: item.taskId,
									workOrderNo: item.workOrderNo,
									projectSource: item.source,
									procInsId: item.procInsId,
									nextTaskId: item.nextTaskId,
									userNo: item.surveyUser,
									/** 后台规定传值，2020/11/11  SITE-1009 jira*/
									userRoleNo: "part_investigator",
								},
								extra_obj
							)
						}
					)
					this.adjustLoading = true
					adjustPerson(this.staffPortForm).then((res) => {
						if (res.header.retCode === "1") {
							this.$baseMessage(res.header.retMessage, "success")
							this.fetchData()
							this.adjustStaffClose()
						} else {
							this.adjustLoading = false
							this.$message({
								showClose: true,
								duration: 10000,
								message: `${res.header.retMessage}`,
								type: "error",
							})
						}
					})
					/** 可调整人员数组 */
					this.adjList = []
				} else {
					this.adjustLoading = false
					return false
				}
			})
		},
		handleQuery() {
			this.queryForm.pageNum = 1
			this.fetchData()
		},
		async fetchData() {
			this.listLoading = true
			// 选择人员数据
			if (this.queryForm.userInfo && this.queryForm.userInfo.length > 0) {
				this.queryForm.roleUser = `${this.queryForm.userInfo[0]}:${this.queryForm.userInfo[1]}`
			} else {
				this.queryForm.roleUser = ""
			}
			// 流转日期范围
			if (null !== this.queryForm.transitDate) {
				if (0 < this.queryForm.transitDate.length) {
					this.queryForm.circulationTimeStart = returnBitTimestamp(
						this.queryForm.transitDate[0]
					)
					this.queryForm.circulationTimeEnd = returnDeadtimestamp(
						this.queryForm.transitDate[1]
					)
				}
			} else {
				this.queryForm.circulationTimeStart = null
				this.queryForm.circulationTimeEnd = null
			}
			// 下单日期范围
			if (null !== this.queryForm.orderDate) {
				if (0 < this.queryForm.orderDate.length) {
					this.queryForm.orderTimeStart = returnBitTimestamp(
						this.queryForm.orderDate[0]
					)
					this.queryForm.orderTimeEnd = returnDeadtimestamp(
						this.queryForm.orderDate[1]
					)
				}
			} else {
				this.queryForm.orderTimeStart = null
				this.queryForm.orderTimeEnd = null
			}
			// 内部截止日期范围
			if (null !== this.queryForm.innerDeadDate) {
				if (0 < this.queryForm.innerDeadDate.length) {
					this.queryForm.innerDeadlineStart = returnBitTimestamp(
						this.queryForm.innerDeadDate[0]
					)

					this.queryForm.innerDeadlineEnd = returnDeadtimestamp(
						this.queryForm.innerDeadDate[1]
					)
				}
			} else {
				this.queryForm.innerDeadlineStart = null
				this.queryForm.innerDeadlineEnd = null
			}
			this.param.body = Object.assign(this.param.body, this.queryForm)
			const { body } = await getDone(this.param)
			this.list = body.records
			this.total = body.total
			this.$emit("update:done-val", this.total)
			this.listLoading = false
		},

		// 打开工单单详情页面
		goDetailPage(row) {
			let routeData = this.$router.resolve({
				path: "/workplace/supervision/recommend/detail",
				name: "Detail",
				query: {
					target: row.targetSubject,
					workOrderNo: row.workOrderNo,
					address: row.address,
					projectName: row.projectName,
					projectId: row.projectId,
					seqId: row.seqId,
					userNo: this.userNo,
					userRoleNo: getRoleKey("supervisor", this.roles),
					projectSign: row.projectSign,
				},
			})
			/*** 截掉site字符 */
			routeData.href = routeData.href.slice(5)
			window.open(routeData.href, "_blank")
		},
		// 获取报告分类
		getReportType() {
			getReportTypeList(this.param).then((response) => {
				if (response.header.retCode === "1") {
					this.reportType = response.body
				}
			})
		},
	},
}
</script>
<style scoped>
.el-col {
	margin-bottom: 10px;
}
.el-col :last-child {
	margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100%;
}

.el-select,
.el-cascader {
	width: 100%;
}
.my-form-class {
	margin-bottom: -10px !important;
}
</style>
<style>
.my-back-img {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 4px;
	left: 2px;
}
.el-dropdown-menu__item {
	padding: 0;
}
</style>
