<!--手机预览-->
<template>
  <el-dialog
    title="预览"
    :visible.sync="phoneOpen"
    width="60%"
    :close-on-click-modal="false"
    append-to-body
    :before-close="getBack"
  >
    <div v-if="reloadCreate" id="form-create"></div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="getBack">返 回</el-button>
    </div>
  </el-dialog>
</template>

<script>
  let $f = {};
  let form_flag = true;
  export default {
    name: "PhonePreview",
    data() {
      return {
        phoneTitle: "",
        phoneOpen: false,
        reloadCreate: false,
        // 实例对象
        fApi: {},
        rule: [],
        option: {
          onSubmit: function (formData) {
            alert(JSON.stringify(formData));
          },
        },
      };
    },
    updated: function () {
      if (form_flag) {
        const root = document.getElementById("form-create");
        // $f为表单api
        $f = window.formCreate.create(
          //表单生成规则
          this.rule,
          //组件参数配置
          {
            el: root,
            //显示表单重置按钮
            resetBtn: false,
            submitBtn: false,
            //表单提交事件
            onSubmit: function (formData) {
              //按钮进入提交状态
              alert(JSON.stringify(formData));
            },
          }
        );
      }
      form_flag = false;
      // Code that will run only after the
      // entire view has been re-rendered
    },
    methods: {
      preview(textarea) {
        this.phoneOpen = true;
        this.rule = textarea;
        form_flag = true;
        this.reloadCreate = true;
      },
      getBack() {
        this.phoneOpen = false;
        this.reloadCreate = false;
        form_flag = false;
      },
    },
  };
</script>
