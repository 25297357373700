<template>
  <el-form ref="form" :model="form" :rules="rules" class="demo-ruleForm">
    <el-form-item label="原密码" prop="oldPassword">
      <el-input
        v-model.trim="form.oldPassword"
        placeholder="请输入原密码"
        autocomplete="new-password"
        onfocus="this.type='password'"
      />
    </el-form-item>
    <el-form-item label="新密码" prop="newPassword">
      <el-input
        v-model.trim="form.newPassword"
        placeholder="请输入新密码"
        auto-complete="new-password"
        onfocus="this.type='password'"
      />
    </el-form-item>
    <el-form-item label="确认密码" prop="confirmPassword">
      <el-input
        v-model.number="form.confirmPassword"
        placeholder="请确认密码"
        auto-complete="new-password"
        onfocus="this.type='password'"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :loading="loading" @click="submit">
        确定
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { update } from "@/api/user";
  import { mapGetters } from "vuex";
  import { recordRoute } from "@/config/settings";
  import { encryptEn } from "@/utils/encrypt";

  export default {
    props: {
      user: {
        type: Object,
        default: () => {
          return {
            name: "",
            email: "",
          };
        },
      },
    },
    data() {
      let validate = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入原密码"));
        } else {
          if (this.form.oldPassword !== this.password) {
            callback(new Error("原密码输入错误"));
          }
          callback();
        }
      };
      let validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入新密码"));
        } else {
          if (this.form.newPassword !== "") {
            this.$refs["form"].validateField("confirmPwd");
          }
          callback();
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请确认密码"));
        } else if (value.toString() !== this.form.newPassword) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      };
      return {
        loading: false,
        form: {
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        },
        rules: {
          oldPassword: [
            { required: true, trigger: "blur", message: "请输入原密码" },
            {
              min: 6,
              max: 16,
              message: "长度在 6 到 16 个字符",
              trigger: "blur",
            },
            { validator: validate, trigger: "blur" },
          ],
          newPassword: [
            { required: true, message: "请输入新密码", trigger: "blur" },
            {
              min: 6,
              max: 16,
              message: "长度在 6 到 16 个字符",
              trigger: "blur",
            },
            { validator: validatePass, trigger: "blur" },
          ],
          confirmPassword: [
            { required: true, message: "请确认密码", trigger: "blur" },
            { validator: validatePass2, trigger: "blur" },
          ],
        },
        param: {
          body: {
            password: "",
            passwordKey: "",
            userNo: "",
          },
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        password: null,
      };
    },
    computed: {
      ...mapGetters({
        userName: "user/userName",
        userNo: "user/userNo",
        avatar: "user/avatar",
        accessToken: "user/accessToken",
      }),
    },
    created() {
      this.password = sessionStorage.getItem("password");
    },
    methods: {
      async submit() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            this.param.body.password = encryptEn(this.form.oldPassword);
            this.param.body.passwordKey = encryptEn(this.form.newPassword);

            this.param.body.userNo = this.userNo;
            let param = "updatePassword";
            const { header } = await update(this.param);
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");

              await this.logout({}, param);
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.loading = false;
          }
        });
      },
      async logout({}, param) {
        await this.$store.dispatch("user/logout", param);
        if (recordRoute) {
          const fullPath = this.$route.fullPath;
          this.$router.push(`/login?redirect=${fullPath}`);
        } else {
          this.$router.push("/login");
        }
      },
    },
  };
</script>
