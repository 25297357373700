var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "myModelDialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("el-backtop", { attrs: { target: ".myModelDialog" } }),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "addSystemTemplateForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    model: _vm.addSystemTemplateForm,
                    rules: _vm.addSystemTemplateFormRules,
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        display: "block",
                      },
                    },
                    [_vm._v("基础信息")]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "系统模板名称", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写模板名称" },
                                model: {
                                  value: _vm.addSystemTemplateForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addSystemTemplateForm,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "addSystemTemplateForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目", prop: "projectNo" } },
                            [
                              _c("el-cascader", {
                                ref: "refProject",
                                attrs: {
                                  value: _vm.addSystemTemplateForm.projectNo,
                                  options: _vm.projectInfo,
                                  clearable: true,
                                  filterable: true,
                                  separator: ":",
                                  placeholder: "请选择项目",
                                  disabled: _vm.showOrNot,
                                },
                                on: { change: _vm.handleProjectChange },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "文档模板名称",
                                prop: "fileName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 1, maxRows: 2 },
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.addSystemTemplateForm.fileName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addSystemTemplateForm,
                                      "fileName",
                                      $$v
                                    )
                                  },
                                  expression: "addSystemTemplateForm.fileName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.brSource
                        ? [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "委托方", prop: "client" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择委托方",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: { change: _vm.filterReport },
                                        model: {
                                          value:
                                            _vm.addSystemTemplateForm.client,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addSystemTemplateForm,
                                              "client",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "addSystemTemplateForm.client",
                                        },
                                      },
                                      _vm._l(
                                        _vm.clientInfo.clientOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.customer,
                                            attrs: {
                                              label: item.customerName,
                                              value: item.customer,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "报告类型",
                                      prop: "reportType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择报告类型",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: {
                                          change: _vm.filterSubReport,
                                          focus: _vm.filterTip,
                                        },
                                        model: {
                                          value:
                                            _vm.addSystemTemplateForm
                                              .reportType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addSystemTemplateForm,
                                              "reportType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "addSystemTemplateForm.reportType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.clientInfo.reportOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.orderType_After,
                                            attrs: {
                                              label: item.orderType_Title,
                                              value: item.orderType_After,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.clientInfo.subReportOptions.length
                              ? _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "二级类型",
                                          prop: "subReportType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择二级类型",
                                              clearable: "",
                                              filterable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.addSystemTemplateForm
                                                  .subReportType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addSystemTemplateForm,
                                                  "subReportType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "addSystemTemplateForm.subReportType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.clientInfo.subReportOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 16, xl: 16 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "系统模板描述",
                                prop: "description",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入描述信息",
                                },
                                model: {
                                  value: _vm.addSystemTemplateForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addSystemTemplateForm,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "addSystemTemplateForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "vab-query-form",
                    [
                      _c(
                        "vab-query-form-left-panel",
                        [
                          _c("label", [_vm._v("配置内容")]),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                plain: "",
                                icon: "el-icon-mobile-phone",
                              },
                              on: { click: _vm.webPhone },
                            },
                            [_vm._v(" 预览 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vab-query-form-right-panel",
                        [
                          _vm.templateList.length === 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-upload2",
                                  },
                                  on: { click: _vm.uploadFun },
                                },
                                [_vm._v(" 导入 ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-download", plain: "" },
                              on: { click: _vm.handleDownload },
                            },
                            [_vm._v(" 导出 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-plus", type: "primary" },
                              on: { click: _vm.addContent },
                            },
                            [_vm._v(" 添加一级目录 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.templateLoading,
                                  expression: "templateLoading",
                                },
                              ],
                              attrs: {
                                data: _vm.templateList,
                                "row-key": "seqId",
                                "node-key": "seqId",
                                "tree-props": {
                                  children: "children",
                                  hasChildren: "hasChildren",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "elementTitle",
                                  label: "表单及元素名称",
                                  "show-overflow-tooltip": true,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "fieldKey",
                                  label: "英文名称",
                                  width: "140",
                                  "show-overflow-tooltip": true,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(scope.row.fieldKey) + " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "fieldType",
                                  label: "元素类型",
                                  width: "120",
                                  "show-overflow-tooltip": true,
                                  formatter: _vm.judgeElementType,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "排序",
                                  align: "center",
                                  width: "100",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm.judgeSort(scope.row)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "升序",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    plain: "",
                                                    icon: "el-icon-top",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.order(
                                                        scope.row,
                                                        0
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.judgeSort(scope.row)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "降序",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    plain: "",
                                                    icon: "el-icon-bottom",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.order(
                                                        scope.row,
                                                        1
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "right",
                                  width: "240",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm.judgeLinkage(scope.row)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "联动",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "warning",
                                                    icon: "el-icon-magic-stick",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleLinkage(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.judgeCopy(scope.row)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "复制",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "primary",
                                                    icon: "el-icon-document-copy",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleCopy(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.judgeAdd(scope.row)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "新增",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "primary",
                                                    icon: "el-icon-plus",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleAdd(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.judgeEdit(scope.row)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "编辑",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "primary",
                                                    icon: "el-icon-edit-outline",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleUpdate(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.judgeDelete(scope.row)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "删除",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "danger",
                                                    icon: "el-icon-delete",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelete(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: _vm.uploadTitle,
                                visible: _vm.uploadOpen,
                                width: "600px",
                                "append-to-body": "",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.uploadOpen = $event
                                },
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "upload-demo",
                                  attrs: {
                                    drag: "",
                                    action:
                                      "https://jsonplaceholder.typicode.com/posts/",
                                    multiple: "",
                                    align: "center",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v(" 将文件拖到此处，或 "),
                                      _c("em", [_vm._v("点击上传")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " 只能上传jpg/png文件，且不超过500kb "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.getBack("uploadOpen")
                                        },
                                      },
                                    },
                                    [_vm._v("返 回")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("form-option", {
        ref: "formOption",
        on: { fetchData: _vm.fetchData },
      }),
      _c("form-info", { ref: "formInfo", on: { fetchData: _vm.fetchData } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置项",
            visible: _vm.optionVisivle,
            width: "56%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleOptionClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.optionVisivle = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "optionForm",
              staticStyle: { padding: "10px" },
              attrs: {
                model: _vm.optionForm,
                rules: _vm.optionFormRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "elementTitle", label: "配置项" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入配置项的字段名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleOptionCertain.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.optionForm.elementTitle,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.optionForm,
                          "elementTitle",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "optionForm.elementTitle",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleOptionClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleOptionCertain },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("phone-preview", { ref: "phonePreview" }),
      _c("web-preview", { ref: "webPreviewOne" }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogFormVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.certainLoading },
              on: { click: _vm.save },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传资源",
            visible: _vm.openUploadSource,
            width: "50%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleImportClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openUploadSource = $event
            },
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", type: "flex", gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("i", { staticClass: "el-icon-upload" }),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c("h3", [_vm._v("请您上传资源")]),
                    _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-family": "'微软雅黑', serif",
                              "font-weight": "400",
                              "text-decoration": "none",
                              color: "#999999",
                            },
                          },
                          [_vm._v(" 请上传导入文件，文件后缀名必须为txt ")]
                        ),
                        _c(
                          "el-upload",
                          {
                            ref: "uploadTemplate",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              data: _vm.uploadData,
                              headers: _vm.headers,
                              "on-success": _vm.handleSuccess,
                              "on-error": _vm.handleError,
                              "before-upload": _vm.beforeUpload,
                              limit: 1,
                              "on-exceed": _vm.handleExceed,
                              "file-list": _vm.fileList,
                            },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                ref: "uploadBtn",
                                staticStyle: { "margin-top": "30px" },
                                attrs: { type: "primary", underline: false },
                              },
                              [_vm._v(" 上传文件 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleImportClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  ref: "nextStep",
                  attrs: { type: "primary", disabled: "" },
                  on: { click: _vm.handleImportClose },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }