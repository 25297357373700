<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否退回？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="退回原因" prop="fallbackCommentId">
        <el-select
          v-model="form.fallbackCommentId"
          placeholder="请选择退回原因"
          clearable
          filterable
        >
          <el-option
            v-for="item in withdrawOptions"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model="form.note"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getReturnReason } from "@/api/workplace/investigate/investigate";
  import { withdraw } from "@/api/workplace/order/order";
  import { mapGetters } from "vuex";
  import { returnBitTimestamp, getRoleKey } from "@/utils";
  export default {
    name: "TableEdit",
    data() {
      return {
        // 防抖
        loading: false,
        form: {
          fallbackCommentId: "",
          note: "",
        },
        rules: {
          fallbackCommentId: [
            { required: true, trigger: "blur", message: "请选择退回原因" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
        withdrawOptions: [],
        backPara: {
          body: [],
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        obj: {
          console: "book_person",
          fallbackCommentId: null,
          nextTaskId: null,
          note: null,
          operAction: "工单退回",
          procInsId: null,
          projectSource: null,
          remark: null,
          step: "预约工作台-工单预约-待处理",
          taskId: null,
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        // 查询退回原因参数
        param: {
          body: {},
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        object: [],
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.obj.userNo = this.userNo;
      this.obj.userRoleNo = getRoleKey("book_person", this.roles);
    },
    methods: {
      async showWithdraw(row) {
        this.title = "退回工单";
        const { body } = await getReturnReason(this.param);
        this.withdrawOptions = body;
        this.object = row;
        this.dialogFormVisible = true;
        this.backPara.body = [];
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("refreshData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            for (let i = 0; i < this.object.length; i++) {
              this.obj.procInsId = this.object[i].procInsId;
              this.obj.taskId = this.object[i].taskId;
              this.obj.projectSource = this.object[i].source;
              this.obj.workOrderNo = this.object[i].workOrderNo;
              this.obj.fallbackCommentId = this.form.fallbackCommentId;
              this.obj.note = this.form.note;

              this.backPara.body.push(Object.assign({}, this.obj));
            }

            const { header } = await withdraw(Object.assign({}, this.backPara));
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }

            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("refreshData");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
