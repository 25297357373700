var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      attrs: { type: "border-card", value: "todo" },
      on: { "tab-click": _vm.handleTriggerTab },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { name: "todo" } },
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _c(
                "el-badge",
                {
                  staticClass: "item todo",
                  attrs: { value: _vm.todoVal > 99 ? "99⁺" : _vm.todoVal },
                },
                [_vm._v(" 待处理 ")]
              ),
            ],
            1
          ),
          _c("todo", { ref: "todoChild", on: { "child-todo": _vm.todoEvent } }),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "done" } },
        [
          _c(
            "span",
            { attrs: { slot: "label" }, slot: "label" },
            [
              _c(
                "el-badge",
                {
                  staticClass: "item done",
                  attrs: { value: _vm.doneVal > 99 ? "99⁺" : _vm.doneVal },
                },
                [_vm._v(" 已处理 ")]
              ),
            ],
            1
          ),
          _c("done", {
            ref: "doneChild",
            attrs: { "done-val": _vm.doneVal },
            on: {
              "update:doneVal": function ($event) {
                _vm.doneVal = $event
              },
              "update:done-val": function ($event) {
                _vm.doneVal = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }