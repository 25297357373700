<template>
  <el-dialog :title="title"
             class="myDialog"
             :visible.sync="dialogFormVisible"
             :close-on-click-modal="false"
             append-to-body
             width="60%"
             :fullscreen="fullFlag"
             @opened="handleOpen"
             @close="handleClose">
    <el-backtop target=".myDialog"></el-backtop>
    <i style="position: absolute; top: 24px; right: 41px; cursor: pointer"
       :class="fullIcon"
       :title="fullTitle"
       @click="triggerFullOrHelf"></i>
    <div style="margin-bottom: 10px">{{ companyName }}</div>
    <el-tabs v-model="activeName"
             type="border-card"
             :tab-position="tabPosition"
             @tab-click="handleClick">
      <el-tab-pane label="工单详情"
                   class="edit_title"
                   name="edit">
        <!--工单进度-->
        <order-process :param="param"></order-process>

        <!--基础信息>-->
        <div class="title">基础信息</div>

        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="workOrderNo"
                           label="工单编号"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.workOrderNo || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="brWorkNo"
                           label="订单编号"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.brWorkNo || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="reportType"
                           label="报告类型"
                           :class-name="brChange('reportTypeFlag') + ' '+ brChange('subReportTypeFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.reportType || "-" }}
              {{ scope.row.subReportType ? '/' : ''}}
              {{ scope.row.subReportType}}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="projectSource"
                           label="项目来源"
                           align="center">
            <template slot-scope="scope">
              {{ getProjectSource(scope.row.projectSource) || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="targetSubject"
                           label="目标主体"
                           :class-name="brChange('subjectFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.targetSubject || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="targetAddr1"
                           label="目标地址1"
                           :class-name="brChange('subjectAddressFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.targetAddr1 || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="targetAddr2"
                           label="目标地址2"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.targetAddr2 || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="targetContact"
                           label="目标联系人"
                           :class-name="brChange('subjectLinkNameFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.targetContact || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="contactNumber"
                           label="联系电话"
                           :class-name="brChange('subjectMobileFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.contactNumber || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="duty"
                           label="职务"
                           :class-name="brChange('dutyFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.duty || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="orderTime"
                           label="下单时间"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.orderTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="innerDeadline"
                           label="内部截止日期"
                           align="center"
                           :formatter="eighthBitTime">
            <template slot-scope="scope">
              {{ scope.row.innerDeadline || "-" }}
            </template>
          </el-table-column>
        </el-table>

        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="clientAbbr"
                           label="委托方简称"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.clientAbbr || "-" }}
            </template>
          </el-table-column>

          <el-table-column prop="reportTimeLimit"
                           label="报告时限"
                           :class-name="brChange('reportTimeLimitFlag')"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.reportTimeLimit || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark1"
                           label="备注1"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.remark1 || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column prop="remark2"
                           label="备注2"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.remark2 || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark3"
                           label="备注3"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.remark3 || "-" }}
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>

        <!--特别信息>-->
        <div class="title">特别信息</div>

        <el-table :data="tableData"
                  stripe
                  border>
          <el-table-column v-if="param.body.userRoleNo !== 'part_investigator'"
                           prop="plSpecialMsg"
                           label="PL特别留言"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.plSpecialMsg || "-" }}
            </template>
          </el-table-column>
          <el-table-column v-if="param.body.userRoleNo !== 'part_investigator'"
                           prop="bookSpecialMsg"
                           label="预约特别留言"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.bookSpecialMsg || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="monitorSpecialMsg"
                           label="督导特别留言"
                           align="center">
            <template slot-scope="scope">
              {{ scope.row.monitorSpecialMsg || "-" }}
            </template>
          </el-table-column>
        </el-table>

        <!--附件-->
        <div v-show="annexGroup.length !== 0"
             class="title">附件</div>

        <el-row style="text-align: center; margin-top: 20px">
          <div v-for="(item, index) in annexGroup"
               :key="index">
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="6">
              <a @click.stop.prevent="
                  handleDownload({ name: item.fileName, path: item.path })
                ">
                <img src="@/icon/annex.svg"
                     alt=""
                     width="80"
                     height="80" />
                <p>{{ item.fileName }}</p>
              </a>
            </el-col>
          </div>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="取证资料"
                   name="evidence">
        <evidence ref="evidence"
                  :row="object"></evidence>
      </el-tab-pane>
      <el-tab-pane label="拍摄照片"
                   name="photo">
        <photo ref="photo"
               :type="'50'"
               :row="object"></photo>
      </el-tab-pane>
      <el-tab-pane label="拍摄录像"
                   name="screenVideo">
        <screenVideo ref="screenVideo"
                     :type="'51'"
                     :row="object"></screenVideo>
      </el-tab-pane>
      <el-tab-pane label="扫描文件"
                   name="scanFile">
        <scanFile ref="scanFile"
                  :type="'52'"
                  :row="object"></scanFile>
      </el-tab-pane>
      <el-tab-pane label="录制音频"
                   name="screenAudio">
        <screenAudio ref="screenAudio"
                     :type="'53'"
                     :row="object"></screenAudio>
      </el-tab-pane>
      <el-tab-pane label="其他文件"
                   name="telRecord">
        <telRecord ref="telRecord"
                   :type="'54'"
                   :row="object"></telRecord>
      </el-tab-pane>
      <el-tab-pane v-if="remarkFlag"
                   label="查看小记"
                   name="remark">
        <remark ref="remark"
                :param="param"></remark>
      </el-tab-pane>
      <el-tab-pane label="退回记录"
                   name="back">
        <back ref="back"
              :order="orderNo"></back>
      </el-tab-pane>
      <el-tab-pane label="企业信息"
                   name="company">
        <company ref="company"
                 :row="object"></company>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer"
         class="dialog-footer">
      <el-button type="warning"
                 @click="handleWithdraw">退回</el-button>
      <el-button v-if="activeName === 'evidence'"
                 type="success"
                 @click="saveEnvidence">
        保存
      </el-button>
      <el-button v-if="activeName === 'evidence'"
                 type="primary"
                 @click="handleReport">
        生成报告
      </el-button>
      <el-button type="primary"
                 :loading="submitLoading"
                 @click="submit">
        提 交
      </el-button>
      <!-- <el-button type="primary" @click="submitShow = true">
        提交
      </el-button> -->
      <el-button type="danger"
                 @click="handleQuit">放弃</el-button>
    </div>
    <!--提交工单-->
    <submit ref="submit"
            @close="close"
            @refreshData="refreshData"></submit>
    <!--退囘工單-->
    <withdraw ref="withdraw"
              @close="close"
              @refreshData="refreshData"></withdraw>
    <!--放弃工单-->
    <quit ref="quit"
          @close="close"
          @refreshData="refreshData"></quit>
  </el-dialog>
</template>

<script>
import orderProcess from "@/views/workplace/components/orderProcess";
import back from "@/views/workplace/components/back";
import photo from "@/views/workplace/components/photo";
import screenVideo from "@/views/workplace/components/screenVideo";
import scanFile from "@/views/workplace/components/scanFile";
import screenAudio from "@/views/workplace/components/screenAudio";
import telRecord from "@/views/workplace/components/telRecord";
import company from "@/views/workplace/components/company";
import evidence from "@/views/workplace/components/evidence";
import remark from "@/views/workplace/components/remark";
import withdraw from "./withdraw";
import quit from "./quit";
import submit from "./submit";
import { getOrderDetail } from "@/api/workplace/investigate/investigate";
import { generateReport } from "@/api/workplace/commonOperation";

import {
  returnBitTimestamp,
  getRoleKey,
  eighthBitTime,
  downloadFile,
  isExistOSS,
  downloadReport,
  getProjectSource,
} from "@/utils/index";
import { mapGetters } from "vuex";
import { searchAttach } from "@/api/workplace/tools";

export default {
  name: "Detail",
  components: {
    evidence,
    back,
    company,
    photo,
    screenVideo,
    scanFile,
    screenAudio,
    telRecord,
    orderProcess,
    submit,
    withdraw,
    quit,
    remark,
  },
  data() {
    return {
      annexGroup: [],
      title: "",
      // 全/半屏提示文本
      fullTitle: "最大化",
      // 全/半屏按钮图标
      fullIcon: "el-icon-full-screen",
      // 是否全屏标识
      fullFlag: false,
      dialogFormVisible: false,
      submitLoading: false,
      orderNo: null,
      activeName: "edit",
      tabPosition: "top",
      tableData: [],
      orderForm: {
        remark: "",
      },

      // 获取工单详情
      param: {
        body: {
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
          pageNum: 1,
          pageSize: 10,
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },

      // 给子组件传值
      object: null,

      remarkFlag: true,
      reportForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      companyName: null,
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
      /** 编辑 */
      accessKeyId: "user/accessKeyId",
      accessKeySecret: "user/accessKeySecret",
      bucketName: "user/bucketName",
      endpoint: "user/endpoint",
      endpoinxBak: "user/endpoinxBak",
      region: "user/region",
      policy: "user/policy",
      signature: "user/signature",
      OSSClient: "user/OSSClient",
    }),
  },
  created() {
    this.param.body.userNo = this.userNo;
    this.param.body.userRoleNo = getRoleKey("investigate", this.roles);
  },
  methods: {
    close() {
      this.dialogFormVisible = false;
    },
    refreshData() {
      this.$emit("refreshData");
    },
    handleClick(tab) {
      this.$refs["evidence"].clearInterval();
      if (tab.name === "evidence") {
        this.$refs["evidence"].reset(this.orderNo);
        this.$refs["evidence"].fetchData();
        this.$refs["photo"].fetchData();
        this.$refs["evidence"].createTimer();
      } else if (tab.name === "scanFile") {
        this.$refs["scanFile"].fetchData();
      } else if (tab.name === "screenVideo") {
        this.$refs["screenVideo"].fetchData();
      } else if (tab.name === "photo") {
        this.$refs["photo"].fetchData();
      } else if (tab.name === "screenAudio") {
        this.$refs["screenAudio"].fetchData();
      } else if (tab.name === "telRecord") {
        this.$refs["telRecord"].fetchData();
      }
    },
    /**取证资料保存 */
    saveEnvidence() {
      this.$refs["evidence"].save();
    },
    /** 提交 */
    async submit() {
      // if (
      //   this.tableData[0].projectSource === "process_br" &&
      //   !this.$refs["photo"].checkData()
      // ) {
      //   this.$message.error(`提交失败，请对空相册添加说明后再提交。`);
      //   return;
      // }
      this.$refs["evidence"]
        .save()
        .then(() => {
          let rows = [];
          rows.push(this.object);
          this.$refs["submit"].openLevel = 1;
          this.$refs["submit"].showSubmit(rows);
          // this.dialogFormVisible = false;
        })
        .catch((error) => {
          this.$message.error(
            "“取证资料”页面有必填内容暂未填写，请补充后再次提交"
          );
        });
    },
    // 退回
    handleWithdraw() {
      let rows = [];
      rows.push(this.object);
      this.$refs["withdraw"].openLevel = 1;
      this.$refs["withdraw"].showWithdraw(rows);
      // this.dialogFormVisible = false;
    },
    // 放弃
    handleQuit() {
      let rows = [];
      rows.push(this.object);
      this.$refs["quit"].openLevel = 1;
      this.$refs["quit"].showQuit(rows);
      // this.dialogFormVisible = false;
    },
    async showDetail(row) {
      this.object = Object.assign(
        row,
        { workplace: "investigator" },
        { flag: "detail" }
      );
      this.object.accessKeyId = this.accessKeyId;
      this.object.accessKeySecret = this.accessKeySecret;
      this.object.bucketName = this.bucketName;
      this.object.endpoint = this.endpoint;
      this.object.endpoinxBak = this.endpoinxBak;
      this.object.OSSClient = this.OSSClient;
      this.object.region = this.region;
      this.object.policy = this.policy;
      this.object.signature = this.signature;
      this.object.userNo = this.userNo;

      this.object.userRoleNo = getRoleKey("investigate", this.roles);

      this.object.userRoleNo === "full_investigator"
        ? (this.remarkFlag = true)
        : (this.remarkFlag = false);

      this.title = "录入工单-工单详情";
      this.param.header.currentTime = returnBitTimestamp(new Date());
      this.orderNo = row.workOrderNo;
      this.dialogFormVisible = true;
      this.tableData = [];
      this.param.body.workOrderNo = row.workOrderNo;
      // 获取工单详情
      this.tableData.push(
        Object.assign({}, (await getOrderDetail(this.param)).body)
      );
      this.companyName = this.tableData[0].targetSubject;
      /** 查询附件信息 */
      this.attach();
      /**详情 */
      this.getDetail();
    },
    getDetail() {
      this.activeName = "edit";

      this.$refs.remark.fetchData();
      this.$refs["back"].fetchData();
      this.$refs.company.init();
    },
    /** 查询附件信息 */
    async attach() {
      this.param.body.workOrderNo = this.orderNo;
      this.param.header.currentTime = returnBitTimestamp(new Date());
      const { body, header } = await searchAttach(this.param);
      if (header.retCode === "1") {
        this.annexGroup = body;
      } else {
        this.$baseMessage(header.retMessage, "error");
      }
    },
    back() {
      this.$router.go(-1);
    },
    eighthBitTime(row, column) {
      return eighthBitTime(row, column);
    },
    /** 当前dialog关闭 */
    handleClose() {
      this.dialogFormVisible = false;
      this.fullFlag = false;
      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
      this.$refs.evidence.reset();
    },

    /** 全屏/半屏切换 */
    triggerFullOrHelf() {
      this.fullFlag = !this.fullFlag;
      if (this.fullFlag) {
        this.fullIcon = "el-icon-minus";
        this.fullTitle = "最小化";
      } else {
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      }
    },
    /** 附件下载 */
    handleDownload(item) {
      isExistOSS(this.OSSClient, item.path)
        .then(() => {
          downloadFile(item);
        })
        .catch(() => {
          this.$message.error("当前文件不存在！");
        });
    },
    // 生成报告
    async handleReport(row) {
      this.reportForm.header.currentTime = returnBitTimestamp(new Date());
      this.reportForm.body.workOrderNo = this.orderNo;
      const { header } = await generateReport(this.reportForm);
      if (header.retCode === "1") {
        downloadReport(`/site/downOssAndTemp?workOrderNo=${this.orderNo}`);
      } else {
        this.$baseMessage(`${header.retMessage}`, "error");
      }
    },
    handleOpen() {
      this.handleClick({ name: "evidence" });
      this.handleClick({ name: "edit" });
    },
    getProjectSource(code) {
      return getProjectSource(code);
    },
    // 修改内容标黄
    brChange(data) {
      if (
        this.tableData.length &&
        this.tableData[0].siteBrUpdateColumnRes &&
        this.tableData[0].siteBrUpdateColumnRes[data]
      ) {
        return "is-change";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
body {
  font-weight: 700;
  color: #666;
}
.edit_title .title {
  margin: 10px 0;
}
.el-step__title {
  font-size: 14px;
}
.el-step__title.is-process {
  font-weight: bold;
  color: #666;
}
html body .el-table th .cell {
  font-weight: 700;
  color: #666;
}

.el-form-item__label {
  font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100% !important;
}

.el-select,
.el-cascader {
  width: 100%;
}
</style>
<style>
.el-upload-dragger {
  width: 490px !important;
}
.el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.el-table .el-table__body td.is-change {
  background: #ffff9f;
}
</style>
