<!--Kyle 工作台 -> 创建工单-->
<template>
  <el-dialog
    :title="createFormTitle"
    class="myDialog"
    :visible.sync="createFormVisible"
    :close-on-click-modal="false"
    width="70%"
    :fullscreen="flag"
    :before-close="createFormClose"
  >
    <el-backtop target=".myDialog"></el-backtop>
    <i
      style="position: absolute; top: 24px; right: 41px; cursor: pointer"
      :class="fullIcon"
      :title="fullTitle"
      @click="fullOrClose"
    ></i>
    <div class="createForm-box" style="padding: 0 10px">
      <el-form
        ref="createForm"
        :model="createForm"
        :rules="createFormRules"
        label-width="80px"
        label-position="top"
      >
        <!-- 基础信息 -->
        <h3>基础信息</h3>
        <el-row :gutter="60">
          <!-- Project Name -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="Project Name" prop="projectNameNo">
              <el-input
                v-model="createForm.projectNameNo"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 项目 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="项目" prop="project">
              <el-cascader
                v-model="createForm.project"
                :options="projectInfo"
                clearable
                :filterable="true"
                separator=":"
                placeholder="请选择项目"
                @change="handleProjectChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <!-- 目标主体 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="目标主体" prop="targetSubject">
              <el-input
                v-model="createForm.targetSubject"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 国家/省份/城市/地域（县级市） -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="国家/省份/城市/地域（县级市）" prop="block">
              <el-cascader
                v-model="createForm.block"
                :options="cityInfo"
                clearable
                separator="/"
                :props="{ checkStrictly: true }"
                placeholder="请选择地区"
                :filterable="true"
                @change="handleBlockChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <!-- 目标地址1 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="目标地址1" prop="targetAddr1">
              <el-input
                v-model="createForm.targetAddr1"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 目标地址2 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="目标地址2" prop="targetAddr2">
              <el-input
                v-model="createForm.targetAddr2"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 目标联系人 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="目标联系人" prop="targetContact">
              <el-input
                v-model="createForm.targetContact"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 联系电话 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="联系电话" prop="contactNumber">
              <el-input
                v-model="createForm.contactNumber"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 职务 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="职务" prop="duty">
              <el-input
                v-model="createForm.duty"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 下单时间 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="下单时间" prop="orderTimeTmp">
              <el-date-picker
                v-model="createForm.orderTimeTmp"
                type="datetime"
                clearable
                placeholder="选择下单时间"
                format="yyyy-MM-dd HH:mm"
                style="width: 100%"
                @focus="pickFocus"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- 内部截止日期 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="内部截止日期" prop="innerDeadlineTmp">
              <el-date-picker
                v-model="createForm.innerDeadlineTmp"
                type="date"
                clearable
                placeholder="请选择日期"
                style="width: 100%"
                required
                default-time="23:59:59"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- 对账ID -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="对账ID" prop="reconciliationId">
              <el-input
                v-model="createForm.reconciliationId"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- SV Code -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="SV Code" prop="svCode">
              <el-input
                v-model="createForm.svCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 委托方全称 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="委托方全称" prop="clientFullName">
              <el-input
                v-model="createForm.clientFullName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 委托方简称 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="委托方简称" prop="clientAbbr">
              <el-input
                v-model="createForm.clientAbbr"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 报告期限 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="报告期限" prop="reportTimeLimit">
              <el-select
                v-model="createForm.reportTimeLimit"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="item in reportTime"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 备注1 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="备注1" prop="remark1">
              <el-input
                v-model="createForm.remark1"
                type="textarea"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 备注2 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="备注2" prop="remark2">
              <el-input
                v-model="createForm.remark2"
                type="textarea"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 备注3 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="备注3" prop="remark3">
              <el-input
                v-model="createForm.remark3"
                type="textarea"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 附加信息 -->
        <h3>附加信息</h3>
        <el-row :gutter="60">
          <!-- 预计走访日期 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="预计走访日期" prop="expectVisitDateTmp">
              <el-date-picker
                v-model="createForm.expectVisitDateTmp"
                type="date"
                placeholder="请选择日期"
                style="width: 100%"
                clearable
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- 预计所需调查天数 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="预计所需调查天数" prop="expectServeyDay">
              <el-input
                v-model="createForm.expectServeyDay"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 所需人手 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="所需人手" prop="needUser">
              <el-input
                v-model.number="createForm.needUser"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 预计需时（小时） -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="预计需时（小时）" prop="expectHour">
              <el-input
                v-model="createForm.expectHour"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 注册地址 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="注册地址" prop="registerAddress">
              <el-input
                v-model="createForm.registerAddress"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 目标主体注册状态 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item
              label="目标主体注册状态"
              prop="targetSubjectRegStatus"
            >
              <el-input
                v-model="createForm.targetSubjectRegStatus"
                placeholder="请输入"
                type="text"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 走访收费 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="走访收费" prop="visitFee">
              <el-input
                id="visitFee"
                v-model="createForm.visitFee"
                placeholder="请输入"
                type="password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 加急走访收费 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="加急走访收费" prop="urgentVisitFee">
              <el-input
                id="urgentVisitFee"
                v-model="createForm.urgentVisitFee"
                placeholder="请输入"
                type="password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 再次走访收费 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="再次走访收费" prop="againVisitFee">
              <el-input
                id="againVisitFee"
                v-model="createForm.againVisitFee"
                placeholder="请输入"
                type="password"
                auto-complete="new-password"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 收费依据 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="收费依据" prop="feeBasis">
              <el-input
                v-model="createForm.feeBasis"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 目标机构1 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="目标机构1" prop="targetOrganization1">
              <el-input
                v-model="createForm.targetOrganization1"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 目标机构2 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="目标机构2" prop="targetOrganization2">
              <el-input
                v-model="createForm.targetOrganization2"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!--  目标地址预核结果 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="目标地址预核结果" prop="targetResult">
              <el-input
                v-model="createForm.targetResult"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 不可行原因/待确认项目 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="不可行原因/待确认项目" prop="infeasibleReason">
              <el-input
                v-model="createForm.infeasibleReason"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 邮件抬头 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="邮件抬头" prop="mailHeader">
              <el-input
                v-model="createForm.mailHeader"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 特别留言 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="特别留言" prop="plSpecialMsg">
              <el-input
                v-model="createForm.plSpecialMsg"
                type="textarea"
                placeholder="请输入特别留言"
                :rows="7"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 上传附件 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
            <el-form-item label="上传附件">
              <el-upload
                ref="upload"
                class="upload-demo"
                list-type="picture-card"
                action="#"
                :on-error="handleError"
                :before-upload="beforeUpload"
                :http-request="fnUploadRequest"
                :file-list="fileList"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    src="@/icon/annex.svg"
                    :alt="file.name"
                  />
                  <p
                    style="
                      position: absolute;
                      top: 103px;
                      left: 10px;
                      color: rgb(255, 255, 255);
                      font-size: 13px;
                      font-weight: 100;
                    "
                  >
                    {{
                      file.name.length > 8
                        ? file.name.substring(0, 8) + "..."
                        : file.name
                    }}
                  </p>
                  <span class="el-upload-list__item-actions">
                    <span
                      v-if="form_flag === 'edit'"
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="form_flag === 'edit'"
                      class="el-upload-list__item-delete"
                      @click="handleDownload(file)"
                    >
                      <i class="el-icon-download"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
            <el-dialog
              title="预览"
              :visible.sync="uploadDialog"
              append-to-body
              width="40%"
            >
              <div class="htmlContent">
                <viewer>
                  <img :src="dialogImageUrl" style="max-height: 300px" />
                </viewer>
              </div>
            </el-dialog>
          </el-col>
        </el-row>
        <!-- 派单信息 -->
        <h3>派单信息</h3>
        <el-row :gutter="60">
          <!-- 预约人员 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="预约人员" prop="bookUser">
              <el-select
                v-model="createForm.bookUser"
                class="bookUser_input"
                suffix-icon="el-icon-user"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="item in bookUserData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 小记 -->
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="小记" prop="note">
              <el-input
                v-model="createForm.note"
                type="textarea"
                :rows="1"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button plain size="large" @click="createFormClose">取消</el-button>
      <el-button
        type="success"
        size="large"
        :loading="saveLoading"
        @click="saveOrder('createForm')"
      >
        保存
      </el-button>
      <el-button
        type="primary"
        size="large"
        :loading="submitLoading"
        @click="submitOrder('createForm')"
      >
        提交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  const OSS = require("ali-oss");
  const attachmentPath = "cbi-site-front/workplace/attachment/";
  import axios from "axios";
  import {
    // 创建保存
    createList,
    // 获取编辑回显
    getEditList,
    // 编辑保存
    getEditSave,
    // 提交保存[总]
    submitCreate,
  } from "@/api/workplace/orderCreate";
  // 转化当前时间格式
  import {
    returnBitTimestamp,
    parseTime,
    downloadFile,
    isExistOSS,
    returnDeadtimestamp,
    getRoleKey,
    /** 文件名称格式校验 */
    fileFormatValid,
  } from "@/utils/index";
  import { downloadOss } from "@/api/workplace/commonOperation";
  import { mapGetters } from "vuex";
  // 获取工具API
  import {
    // 角色
    getRole,
  } from "@/api/workplace/tools";
  import { disabledDate, selectableRange } from "@/utils/date";
  const sum = [];
  let id = 0;
  export default {
    name: "Create",
    data() {
      // Form-vlidator 判断数字且小数点后保留两位有效数字
      const isPriceVlidator = (rule, value, callback) => {
        // var pattern = /^\d+.?\d{0,2}$/;
        let pattern = /^\d+(\.\d{1,2})?$/;
        // 可以正常留空
        if (value == null || value == undefined || value == "") {
          return callback();
        } else if (!pattern.test(value)) {
          return callback(new Error("请输入数字,并且小数点后最多只能输入两位"));
        } else return callback();
      };
      return {
        fullTitle: "最大化",
        fullIcon: "el-icon-full-screen",
        flag: false,
        pickerOptions: {
          disabledDate,
          selectableRange: "",
        },
        saveLoading: false,
        submitLoading: false,
        createFormTitle: "",
        createFormVisible: false,
        form_flag: "create",
        createPortForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        // 附件
        queryAttach: {
          body: {
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        createForm: {
          /** 媒介字段 __start */
          block: "",
          project: "",
          orderTimeTmp: "",
          expectVisitDateTmp: "",
          innerDeadlineTmp: "",
          /** 媒介字段 __end */
          clientAbbr: "",
          reportTimeLimit: "",
          clientFullName: "",
          workOrderNo: "",
          address: "",
          againVisitFee: "",
          bookUser: "",
          console: "PL",
          contactNumber: "",
          note: "",
          duty: "",
          expectHour: "",
          expectServeyDay: "",
          expectVisitDate: "",
          feeBasis: "",
          infeasibleReason: "",
          innerDeadline: "",
          mailHeader: "",
          needUser: null,
          nextUserNo: "",
          nextUserRoleNo: "",
          orderTime: "",
          files: [],
          step: "",
          operAction: "",
          projectNo: "",
          projectNameNo: "",
          projectSource: "",
          projectType: "",
          reconciliationId: undefined,
          registerAddress: "",
          remark1: "",
          remark2: "",
          remark3: "",
          plSpecialMsg: "",
          svCode: "",
          targetAddr1: "",
          targetAddr2: "",
          targetContact: "",
          targetOrganization1: "",
          targetOrganization2: "",
          targetResult: null,
          targetSubject: "",
          targetSubjectRegStatus: "",
          urgentVisitFee: "",
          userNo: "",
          userRoleNo: "",
          visitFee: "",
        },
        // 查询编辑信息
        queryForm: {
          body: {
            nextUserNo: "",
            nextUserRoleNo: "",
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        dialogImageUrl: "",
        uploadDialog: false,
        staffDialog: false,
        disabled: false,
        cityInfo: [],
        // 调整人员
        bookUserData: [],
        bookUserValue: [],
        projectInfo: [], // 项目

        // 获取预约人员表单
        roleForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        fileList: [],
        // 上传附件 Request
        createFormRules: {
          projectNameNo: [
            {
              max: 200,
              message: "Project Name不能超过200个英文字符",
              trigger: "blur",
            },
          ],
          targetSubject: [
            { required: true, message: "请输入目标主体", trigger: "blur" },
            {
              max: 100,
              message: "目标主体不能超过100个文字",
              trigger: "blur",
            },
          ],
          targetAddr1: [
            { required: true, message: "请输入目标地址1", trigger: "blur" },
            {
              max: 200,
              message: "目标地址1不能超过200个字数",
              trigger: "blur",
            },
          ],
          targetAddr2: [
            {
              max: 200,
              message: "目标地址2不能超过200个字数",
              trigger: "blur",
            },
          ],
          targetContact: [
            {
              max: 30,
              message: "目标联系人不能超过30个汉字",
              trigger: "blur",
            },
          ],
          contactNumber: [
            {
              max: 50,
              message: "联系电话过长，请重新填写",
              trigger: "blur",
            },
          ],
          duty: [
            {
              max: 30,
              message: "职务不能超过30个汉字",
              trigger: "blur",
            },
          ],
          remark1: [
            {
              max: 200,
              message: "备注不能超过200个汉字",
              trigger: "blur",
            },
          ],
          remark2: [
            {
              max: 200,
              message: "备注不能超过200个汉字",
              trigger: "blur",
            },
          ],
          remark3: [
            {
              max: 200,
              message: "备注不能超过200个汉字",
              trigger: "blur",
            },
          ],
          clientFullName: [
            {
              max: 100,
              message: "委托方全称不能超过100个汉字",
              trigger: "blur",
            },
          ],
          clientAbbr: [
            {
              max: 100,
              message: "委托方全称不能超过100个汉字",
              trigger: "blur",
            },
          ],
          registerAddress: [
            {
              max: 100,
              message: "注册地址不能超过100个汉字",
              trigger: "blur",
            },
          ],
          targetSubjectRegStatus: [
            {
              max: 30,
              message: "目标主体注册状态不能超过30个汉字",
              trigger: "blur",
            },
          ],
          reconciliationId: [
            {
              max: 50,
              message: "对账ID不能超过50位数字",
              trigger: "blur",
            },
          ],
          expectServeyDay: [
            {
              max: 5,
              message: "预计所需调查天数不能超过5位数字",
              trigger: "blur",
            },
          ],
          needUser: [
            {
              trigger: "blur",
              validator: (rule, value, callback) => {
                // 非空校验
                if (this.createForm.needUser > 100000) {
                  return callback(new Error("所需人手不能超过5位数字"));
                } else {
                  callback();
                }
              },
            },
          ],
          expectHour: [
            {
              max: 5,
              message: "预计需时（小时）不能超过5个数字",
              trigger: "blur",
            },
          ],
          svCode: [
            {
              max: 50,
              message: "SV Code不能超过50位数字",
              trigger: "blur",
            },
          ],
          feeBasis: [
            {
              max: 200,
              message: "收费依据不能超过200个汉字",
              trigger: "blur",
            },
          ],
          targetOrganization1: [
            {
              max: 50,
              message: "目标机构1不能超过50个汉字",
              trigger: "blur",
            },
          ],
          targetOrganization2: [
            {
              max: 50,
              message: "目标机构2不能超过50个汉字",
              trigger: "blur",
            },
          ],
          targetResult: [
            {
              max: 50,
              message: "目标地址预核结果不能超过50个汉字",
              trigger: "blur",
            },
          ],
          infeasibleReason: [
            {
              max: 100,
              message: "不可行原因/待确认项目不能超过100个汉字",
              trigger: "blur",
            },
          ],
          mailHeader: [
            {
              max: 50,
              message: "邮件抬头不能超过50个汉字",
              trigger: "blur",
            },
          ],
          plSpecialMsg: [
            {
              max: 2000,
              message: "特别留言不能超过2000个汉字",
              trigger: "blur",
            },
          ],
          project: [
            {
              required: true,
              message: "请选择项目",
              trigger: "change",
              validator: (rule, value, callback) => {
                // 非空校验
                if (this.createForm.project == "") {
                  return callback(new Error("请选择项目"));
                } else {
                  callback();
                }
              },
            },
          ],
          block: [
            {
              required: true,
              message: "请选择地区",
              trigger: "change",
              validator: (rule, value, callback) => {
                // 非空校验
                if (this.createForm.block == "") {
                  return callback(new Error("请选择地区"));
                } else {
                  callback();
                }
              },
            },
          ],
          orderTimeTmp: [
            { required: true, message: "请选择下单时间", trigger: "change" },
          ],
          innerDeadlineTmp: [
            {
              required: true,
              message: "请选择内部截止日期",
              trigger: "change",
            },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
          bookUser: [
            {
              required: true,
              message: "请选择预约人员",
              trigger: "change",
            },
          ],
          visitFee: [
            { validator: isPriceVlidator, trigger: "blur" },
            {
              max: 10,
              message: "走访收费不能超过10位数字",
              trigger: "blur",
            },
          ],
          urgentVisitFee: [
            { validator: isPriceVlidator, trigger: "blur" },
            {
              max: 10,
              message: "加急走访收费不能超过10位数字",
              trigger: "blur",
            },
          ],
          againVisitFee: [
            { validator: isPriceVlidator, trigger: "blur" },
            {
              max: 10,
              message: "再次走访收费不能超过10位数字",
              trigger: "blur",
            },
          ],
        },
        reportTime: [
          {
            value: "0",
            label: "普通",
          },
          {
            value: "1",
            label: "加急",
          },
          {
            value: "2",
            label: "特急",
          },
        ],
      };
    }, // 注册
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        accessKeyId: "user/accessKeyId",
        accessKeySecret: "user/accessKeySecret",
        bucketName: "user/bucketName",
        endpoint: "user/endpoint",
        endpoinxBak: "user/endpoinxBak",
        region: "user/region",
        policy: "user/policy",
        signature: "user/signature",
        OSSClient: "user/OSSClient",
      }),
      listenOrderTimeTmp() {
        return this.createForm.orderTimeTmp;
      },
    },
    watch: {
      listenOrderTimeTmp(val) {
        if (val instanceof Date && val.getDate() == new Date().getDate()) {
          this.$set(this.pickerOptions, "selectableRange", selectableRange());
        } else {
          // this.pickerOptions.selectableRange = "00:00:00 - 23:59:59";
          this.$set(
            this.pickerOptions,
            "selectableRange",
            "00:00:00 - 23:59:59"
          );
        }
      },
    },
    methods: {
      /** 下载文件 */
      handleDownload(file) {
        try {
          /** 判断是否为当前上传文件，或是编辑回显的文件 */
          var index = this.indexOf(file.name, this.createForm.files);
          if (index > -1) {
            isExistOSS(this.OSSClient, this.createForm.files[index].url)
              .then(() => {
                downloadFile({
                  name: this.createForm.files[index].name,
                  path: this.createForm.files[index].url,
                });
              })
              .catch(() => {
                this.$message.error("当前文件不存在！");
              });
          }
        } catch (error) {
          this.$message.error(`正在上传中，请勿操作`);
        }
      },
      /** 预览文件 */
      handlePictureCardPreview(file) {
        try {
          var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
          const extension = testmsg === "jpg";
          const extension2 = testmsg === "jpeg";
          const extension3 = testmsg === "png";
          const extension4 = testmsg === "JPG";
          const extension5 = testmsg === "JPEG";
          const extension6 = testmsg === "PNG";
          if (
            !extension &&
            !extension2 &&
            !extension3 &&
            !extension4 &&
            !extension5 &&
            !extension6
          ) {
            this.$message({
              message: "预览文件只能是 jpg、jpeg、png格式!",
              type: "warning",
            });
            return false;
          }
          /** 判断是否为当前上传文件，或是编辑回显的文件 */
          var index = this.indexOf(file.name, this.createForm.files);
          if (index > -1) {
            isExistOSS(this.OSSClient, this.createForm.files[index].url)
              .then(() => {
                this.dialogImageUrl =
                  this.endpoinxBak + this.createForm.files[index].url;
                this.uploadDialog = true;
              })
              .catch(() => {
                this.$message.error("当前文件不存在！");
              });
          }
          return true;
        } catch (error) {
          this.$message.error(`正在上传中，请勿操作`);
        }
      },
      pickFocus() {
        this.pickerOptions.selectableRange = selectableRange();
      },
      showCreate(configInfo, flag) {
        /** 初始化loading */
        this.saveLoading = false;
        this.submitLoading = false;
        this.queryForm.body.userNo = this.userNo;
        this.queryForm.body.userRoleNo = getRoleKey("PL", this.roles);
        this.createForm.userNo = this.userNo;
        this.createForm.userRoleNo = getRoleKey("PL", this.roles);
        // 打开创建工单
        if (flag === "create") {
          this.form_flag = "create";
          this.createFormTitle = "创建工单";
          this.createForm.step = configInfo.step;
          // this.createForm.operAction = configInfo.operAction;
          this.createForm.console = configInfo.console_info;
          /* 优化接口 */
          this.cityInfo = configInfo.blockInfo;
          this.projectInfo = configInfo.projectInfo;
        }
        // 打开编辑工单
        if (flag === "edit") {
          this.form_flag = "edit";
          this.createFormTitle = "编辑工单";
          this.createForm.step = configInfo.step;
          // this.createForm.operAction = configInfo.operAction;
          this.createForm.console = configInfo.console_info;
          /* 优化接口 */
          this.cityInfo = configInfo.blockInfo;
          this.projectInfo = configInfo.projectInfo;
          /** 获取工单信息*/
          this.queryForm.body.workOrderNo = configInfo.workOrderNo;
          this.queryForm.header.currentTime = returnBitTimestamp(new Date());
          getEditList(this.queryForm).then((response) => {
            if (response.header.retCode == "1") {
              Object.assign(this.createForm, response.body);
              this.createForm.project = response.body.projectNo;
              this.createForm.address = response.body.addressCode;
              if (response.body.reportTimeLimitCode === null) {
                this.createForm.reportTimeLimit = "";
              } else {
                this.createForm.reportTimeLimit =
                  response.body.reportTimeLimitCode;
              }
              /** 将回显的字段与地址组件绑定 */
              this.createForm.block = response.body.addressCode.split(",");
              if (response.body.files !== null) {
                this.fileList = response.body.files;
              }
              // 下单时间
              this.createForm.orderTimeTmp = Date.parse(
                response.body.orderTime
              );
              // 内部截止日期
              this.createForm.innerDeadlineTmp = Date.parse(
                response.body.innerDeadline
              );
              // 预计走访日期
              this.createForm.expectVisitDateTmp = Date.parse(
                response.body.expectVisitDate
              );
            }
          });
        }
        // 获取预约人员
        this.getRoleOption();
        this.createFormVisible = true;
      },
      // 重置表单
      reset() {
        this.createForm = this.$options.data().createForm;
        this.$refs["createForm"].resetFields();
        this.fileList = [];
        this.queryForm = this.$options.data().queryForm;
        this.createPortForm = this.$options.data().createPortForm;
        this.bookUserValue = [];
      },
      // 关闭创建/编辑工单
      createFormClose() {
        // 重置
        this.reset();
        this.createFormVisible = false;
        this.saveLoading = false;
        this.submitLoading = false;
        this.flag = false;
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      },
      // 选择地区
      handleBlockChange(value) {
        this.createForm.address = value.join(",");
      },
      // 选择项目
      handleProjectChange(value) {
        let project = value[1];
        this.createForm.projectNo = project;
      },
      // 获取预约人员
      getRoleOption() {
        this.roleForm.header.currentTime = returnBitTimestamp(new Date());
        // 预约人员[包括经理]
        this.roleForm.body = [
          {
            roleKey: "book_person",
          },
        ];
        getRole(this.roleForm).then((response) => {
          if (response.header.retCode == "1") {
            this.bookUserData = response.body;
          }
        });
      },
      /* 保存工单 */
      saveOrder(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveLoading = true;
            // 下单时间
            if (this.createForm.orderTimeTmp) {
              this.createForm.orderTime = returnBitTimestamp(
                this.createForm.orderTimeTmp
              );
            }
            // 内部截止日期
            if (this.createForm.innerDeadlineTmp) {
              this.createForm.innerDeadline = returnDeadtimestamp(
                this.createForm.innerDeadlineTmp
              );
            }
            // 预计走访日期
            if (this.createForm.expectVisitDateTmp) {
              this.createForm.expectVisitDate = returnBitTimestamp(
                this.createForm.expectVisitDateTmp
              );
            }
            this.createPortForm.header.currentTime = returnBitTimestamp(
              new Date()
            );
            // 回显地址格式转化为后台所需带有连接符的字符串
            if (this.createForm.address instanceof Array) {
              this.createForm.address = this.createForm.address.join(",");
            }
            // 操作记录
            this.createForm.operAction = "保存小记";
            Object.assign(this.createPortForm.body, this.createForm);
            if (this.form_flag === "create") {
              createList(this.createPortForm).then((response) => {
                if (response.header.retCode == "1") {
                  this.$emit("fetchData");
                  this.$message({
                    message: `${response.header.retMessage}`,
                    type: "success",
                  });
                  this.createFormVisible = false;
                  // 重置表单
                  this.reset();
                } else {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},\t请重新保存创建订单！`
                  );
                }
                // this.saveLoading = false;
              });
            } else if (this.form_flag === "edit") {
              getEditSave(this.createPortForm).then((response) => {
                if (response.header.retCode == "1") {
                  this.$emit("fetchData");
                  this.$message({
                    message: `${response.header.retMessage}`,
                    type: "success",
                  });
                  this.createFormVisible = false;
                  // 重置表单
                  this.reset();
                } else {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},\t请重新保存编辑订单！`
                  );
                }
                // this.saveLoading = false;
              });
            }
          } else {
            this.$message.error("保存失败，请重新检查工单数据");
            return false;
          }
        });
        this.flag = false;
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      },
      /* 提交工单 */
      submitOrder(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            // 下单时间
            if (this.createForm.orderTimeTmp) {
              this.createForm.orderTime = returnBitTimestamp(
                this.createForm.orderTimeTmp
              );
            }
            // 内部截止日期
            if (this.createForm.innerDeadlineTmp) {
              this.createForm.innerDeadline = returnDeadtimestamp(
                this.createForm.innerDeadlineTmp
              );
            }
            // 预计走访日期
            if (this.createForm.expectVisitDateTmp) {
              this.createForm.expectVisitDate = returnBitTimestamp(
                this.createForm.expectVisitDateTmp
              );
            }
            this.createPortForm.header.currentTime = returnBitTimestamp(
              new Date()
            );
            // 回显地址格式转化为后台所需带有连接符的字符串
            if (this.createForm.address instanceof Array) {
              this.createForm.address = this.createForm.address.join(",");
            }
            // 操作记录
            this.createForm.operAction = "工单派单";
            if (this.form_flag == "create") {
              Object.assign(this.createPortForm.body, this.createForm);
              submitCreate(this.createPortForm).then((response) => {
                if (response.header.retCode == "1") {
                  // 刷新已处理
                  this.$emit("refreshData");
                  this.$message({
                    message: `${response.header.retMessage}`,
                    type: "success",
                  });
                  this.createFormVisible = false;
                  // 重置表单
                  this.reset();
                } else {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},\t请重新提交创建订单！`
                  );
                }
                // this.submitLoading = false;
              });
            } else if (this.form_flag == "edit") {
              /** 后端根据是否添加工单号判断 编辑提交 或是 创建提交 */
              this.createForm.workOrderNo = this.queryForm.body.workOrderNo;
              Object.assign(this.createPortForm.body, this.createForm);
              submitCreate(this.createPortForm).then((response) => {
                if (response.header.retCode == "1") {
                  // 刷新已处理
                  this.$emit("refreshData");
                  this.$message({
                    message: `${response.header.retMessage}`,
                    type: "success",
                  });
                  this.createFormVisible = false;
                  // 重置表单
                  this.reset();
                  this.flag = false;
                } else {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage}, 请重新提交编辑订单！`
                  );
                }
                // this.submitLoading = false;
              });
            }
          } else {
            this.$message.error("提交失败，请重新检查工单数据");
            return false;
          }
        });
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      },
      /** 上传失败的回调 */
      handleError(err, file, fileList) {
        this.$message.error(`上传错误,错误信息 ${err}`);
      },
      // 导入附件
      beforeUpload(file) {
        if (this.saveLoading || this.submitLoading) {
          this.$message({
            message: "请等待当前操作处理完毕",
            type: "warning",
          });
          return false;
        }
        // 同名文件判断
        if (this.createForm.files !== null) {
          const delArr = this.createForm.files.filter((item) => {
            return item.name === file.name;
          });
          if (delArr.length !== 0) {
            this.$message({
              message: "请勿上传同名文件",
              type: "warning",
            });
            return false;
          }
        }
        // 判断文件命名非法字符校验
        const filename = file.name.substring(0, file.name.lastIndexOf("."));
        if (!fileFormatValid(filename)) {
          this.$message({
            message: "文件名称中不能包含非法特殊字符, 请检查",
            type: "warning",
          });
          return false;
        }
        // 大小判断
        const expSize = file.size / 1024 / 1024 < 1024;
        if (!expSize) {
          this.$message({
            duration: 10000,
            showClose: true,
            message: `文件${file.name}大小不能超过 1G, 请检查`,
            type: "warning",
          });
          return false;
        }
        return expSize;
      },
      /*** 匹配返回数组索引 */
      indexOf(val, arr) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].name === val) {
            return i;
          }
        }
        return -1;
      },
      async handleRemove(file) {
        try {
          var index = this.indexOf(file.name, this.createForm.files);
          /** 调用element upload api */
          this.$refs.upload.handleRemove(file);
          if (index > -1) {
            this.createForm.files.splice(index, 1);
          }
        } catch (error) {
          this.$message.error(`正在上传中，请勿操作`);
        }
      },
      /**
       * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
       * @author   shanshuizinong
       * @param    {object}   option [上传选项]
       * @return   {null}   [没有返回]
       */
      async fnUploadRequest(option) {
        try {
          this.submitLoading = true;
          this.saveLoading = true;
          /** 带有时间戳的文件名 */
          const fileName =
            new Date().getTime() +
            "." +
            option.file.name.match(/^(.*)(\.)(.{1,8})$/)[3];
          // 获取OSS配置信息
          const uploadConfig = new FormData();
          uploadConfig.append("key", attachmentPath + fileName);
          uploadConfig.append("OSSAccessKeyId", this.accessKeyId);
          uploadConfig.append("policy", this.policy);
          uploadConfig.append("Signature", this.signature);
          uploadConfig.append("success_action_status", 200);
          uploadConfig.append("name", option.file.name);
          /** 表单域 file 必须为最后一个 */
          uploadConfig.append("file", option.file);
          await axios
            .post(this.endpoinxBak, uploadConfig, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.status == 200) {
                /** 对files处理，防止后端response为null */
                if (this.createForm.files === null) {
                  this.createForm.files = [];
                }
                //上传成功 上传的路径就是要使用的路径
                this.createForm.files.push({
                  url: attachmentPath + fileName,
                  name: option.file.name,
                });
                this.submitLoading = false;
                this.saveLoading = false;
              }
            })
            .catch((error) => {
              const index = this.indexOf(
                option.file.name,
                this.createForm.files
              );
              this.createForm.files.splice(index, 1);
              this.submitLoading = false;
              this.saveLoading = false;
            });
        } catch (error) {
          this.submitLoading = false;
          this.saveLoading = false;
          this.$message.error(`上传错误,错误信息 ${error}`);
        }
      },
      fullOrClose() {
        this.flag = !this.flag;
        if (this.flag) {
          this.fullIcon = "el-icon-minus";
          this.fullTitle = "最小化";
        } else {
          this.fullIcon = "el-icon-full-screen";
          this.fullTitle = "最大化";
        }
      },
    },
  };
</script>
<style scoped>
  .bookUser_input >>> .el-input__inner {
    cursor: pointer;
  }
  .createForm-box >>> .el-form {
    padding-right: 0px;
  }
  .htmlContent {
    max-height: 400px;
    overflow: auto;
    display: flex;
    justify-content: center;
  }
  .htmlContent >>> img {
    display: block;
    max-width: 100%;
    max-height: 300px;
  }
</style>
<style>
  .el-cascader-panel .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 10px;
    left: 5px;
  }
  .viewer-container {
    z-index: 9999 !important;
  }
  /* 隐藏此刻
  .el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
    display: none;
  } */
</style>
