var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "never" } },
    [
      _c("div", { staticClass: "size-tips" }, [
        _vm._v(
          "已选：" + _vm._s(_vm._f("formatFileSize")(_vm.selectedPicSize))
        ),
      ]),
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { staticClass: "checkAllBox", attrs: { span: 16 } },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { max: 1 },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "all" } }, [
                    _vm._v("全选"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "nosystem" } }, [
                    _vm._v("全选自定义"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "system" } }, [
                    _vm._v("全选配置"),
                  ]),
                ],
                1
              ),
              _c(
                "el-popover",
                {
                  staticStyle: { margin: "5px" },
                  attrs: {
                    disabled: !_vm.isBeyondLimmit,
                    placement: "bottom-start",
                    trigger: "click",
                  },
                },
                [
                  _c(
                    "el-tag",
                    {
                      staticClass: "el-icon-info",
                      style: {
                        "font-size": "14px",
                      },
                      attrs: { size: "mini", type: "warning" },
                    },
                    [_vm._v(" 图片单次压缩下载大小不可超过1GB(1024 MB) ")]
                  ),
                  !_vm.isBeyondLimmit
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            disabled: !_vm.pictureList.length,
                            plain: "",
                            size: "small",
                            icon: "el-icon-download",
                          },
                          on: { click: _vm.handleShowDownloadDialog },
                          slot: "reference",
                        },
                        [_vm._v(" 下载 ")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            plain: "",
                            size: "small",
                            icon: "el-icon-download",
                          },
                          slot: "reference",
                        },
                        [_vm._v(" 下载 ")]
                      ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.pdfLoading,
                    disabled: !_vm.albumList.length,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleGeneratePDF(1)
                    },
                  },
                },
                [_vm._v(" 生成PDF ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.reportLoading,
                    disabled: !_vm.albumList.length,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleGeneratePDF(2)
                    },
                  },
                },
                [_vm._v(" 生成报告+pdf ")]
              ),
              _vm.row.workplace === "PL" ||
              _vm.row.workplace === "manager" ||
              _vm.row.workplace === "investigator" ||
              (_vm.row.workplace === "supervisor" &&
                _vm.row.isSurveyUser === "Y")
                ? _c("vab-icon", {
                    staticStyle: {
                      "font-size": "20px",
                      color: "#ccc",
                      cursor: "pointer",
                    },
                    attrs: { icon: ["fas", "eye"] },
                    on: {
                      click: function ($event) {
                        return _vm.switchWater(null)
                      },
                    },
                  })
                : _vm._e(),
              _vm.row.workplace === "book_person" ||
              _vm.row.workplace === "investigator" ||
              (_vm.row.workplace === "supervisor" &&
                _vm.row.isSurveyUser === "Y")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                      },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v(" 批量删除 ")]
                  )
                : _vm._e(),
              (_vm.row.workplace === "supervisor" && _vm.row.flag === "edit") ||
              (_vm.row.workplace === "verify_people" &&
                _vm.row.flag === "review")
                ? _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    on: { change: _vm.switchBatchSubmit },
                    model: {
                      value: _vm.sumitTag,
                      callback: function ($$v) {
                        _vm.sumitTag = $$v
                      },
                      expression: "sumitTag",
                    },
                  })
                : _vm._e(),
              _c(
                "el-popover",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    placement: "bottom",
                    width: "700",
                    trigger: "click",
                    title: "查看下载任务状态",
                  },
                  on: {
                    show: _vm.handleShowTaskPopover,
                    hide: _vm.handleHideTaskPopover,
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.downloadTaskLoading,
                          expression: "downloadTaskLoading",
                        },
                      ],
                      attrs: {
                        data: _vm.downloadList,
                        "show-header": false,
                        "empty-text": "暂无下载任务",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          "min-width": "150",
                          prop: "fileName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { "min-width": "150", prop: "createTime" },
                      }),
                      _c("el-table-column", {
                        attrs: { "min-width": "100", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (slot) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(slot.row.mediaNumber + " 张") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          "min-width": "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (slot) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fileTypeFilter")(slot.row.type)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          "min-width": "110",
                          align: "center",
                          prop: "taskStatusValue",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { width: "90", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (slot) {
                              return [
                                slot.row.type === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: slot.row.taskStatus !== 102,
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDownloadZip(
                                              _vm.ossDomain,
                                              slot.row.downloadPath
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 下载 ")]
                                    )
                                  : _vm._e(),
                                slot.row.taskStatus === 103
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRetry(
                                              slot.row.seqId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 重试 ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      small: "",
                      layout: "prev, pager, next",
                      "current-page": _vm.queryDownloadTaskForm.body.pageNum,
                      "page-size": _vm.queryDownloadTaskForm.body.pageSize,
                      total: _vm.downloadTaskTotal,
                    },
                    on: { "current-change": _vm.handleTaskCurrentChange },
                  }),
                  _c("el-button", {
                    staticClass: "task-list-btn",
                    attrs: {
                      slot: "reference",
                      icon: "el-icon-download",
                      title: "查看下载任务状态",
                      size: "mini",
                    },
                    slot: "reference",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.row.workplace === "book_person" ||
          _vm.row.workplace === "investigator" ||
          (_vm.row.workplace === "supervisor" && _vm.row.isSurveyUser === "Y")
            ? _c(
                "vab-query-form-right-panel",
                { staticClass: "上传", attrs: { span: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          _vm.albumVisible = true
                        },
                      },
                    },
                    [_vm._v(" 新增相册 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "15px 0" } }),
      _c(
        "el-row",
        [
          _vm._l(_vm.photoData, function (photoItem, photoIndex) {
            return _c(
              "el-col",
              { key: photoIndex, ref: "siteMediaResource", refInFor: true },
              [
                _c(
                  "el-row",
                  [
                    _c("vab-query-form-left-panel", { attrs: { span: 18 } }, [
                      _c(
                        "div",
                        { staticClass: "title" },
                        [
                          _c("el-checkbox", {
                            on: {
                              change: function ($event) {
                                return _vm.firstChanged(photoIndex, photoItem)
                              },
                            },
                            model: {
                              value: photoItem.mychecked,
                              callback: function ($$v) {
                                _vm.$set(photoItem, "mychecked", $$v)
                              },
                              expression: "photoItem.mychecked",
                            },
                          }),
                          _c(
                            "span",
                            {
                              style: {
                                color: !photoItem.fromType ? "#2d55ff" : "#000",
                              },
                            },
                            [_vm._v(" " + _vm._s(photoItem.name))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.row.workplace === "book_person" ||
                    _vm.row.workplace === "investigator" ||
                    (_vm.row.workplace === "supervisor" &&
                      _vm.row.isSurveyUser === "Y")
                      ? _c(
                          "vab-query-form-right-panel",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "operationTool",
                                staticStyle: { "text-align": "right" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "add",
                                    on: {
                                      click: function ($event) {
                                        return _vm.add(photoItem)
                                      },
                                    },
                                  },
                                  [_vm._v("添加")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "rename",
                                    on: {
                                      click: function ($event) {
                                        return _vm.rename(photoItem)
                                      },
                                    },
                                  },
                                  [_vm._v("重命名")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return _vm.del(photoItem)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { staticStyle: { padding: "10px 0 10px" } },
                      [
                        photoItem.siteMediaResource.length === 0
                          ? _c("img", {
                              staticStyle: { width: "120px", height: "120px" },
                              attrs: {
                                src: require("@/icon/tupian.svg"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "draggable",
                          {
                            staticClass: "board-column-content",
                            attrs: {
                              handle: ".draggle-element",
                              options: { disabled: _vm.undraggable },
                            },
                            on: {
                              end: function ($event) {
                                return _vm.end(photoItem)
                              },
                            },
                            model: {
                              value: photoItem.siteMediaResource,
                              callback: function ($$v) {
                                _vm.$set(photoItem, "siteMediaResource", $$v)
                              },
                              expression: "photoItem.siteMediaResource",
                            },
                          },
                          _vm._l(
                            photoItem.siteMediaResource,
                            function (subItem, subIndex) {
                              return _c(
                                "div",
                                { key: subIndex, staticClass: "class-detail" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "draggle-element" },
                                    [
                                      _vm.judgeFileType(subItem.path)
                                        ? _c("el-image", {
                                            staticStyle: {
                                              width: "160px",
                                              height: "160px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              src:
                                                _vm.row.endpoinxBak +
                                                subItem.path,
                                              fit: _vm.fit,
                                              title: photoItem.name,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleView(
                                                  _vm.photoData,
                                                  photoItem.name,
                                                  photoIndex,
                                                  subIndex
                                                )
                                              },
                                            },
                                          })
                                        : _c("img", {
                                            staticStyle: {
                                              width: "160px",
                                              height: "160px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              src: require("@/icon/pic.svg"),
                                              fit: _vm.fit,
                                              title: photoItem.name,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleView(
                                                  _vm.photoData,
                                                  photoItem.name,
                                                  photoIndex,
                                                  subIndex
                                                )
                                              },
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c("el-checkbox", {
                                    staticClass: "img_checkbox",
                                    on: {
                                      change: function ($event) {
                                        return _vm.secondChanged(
                                          photoIndex,
                                          subItem,
                                          $event
                                        )
                                      },
                                    },
                                    model: {
                                      value: subItem.mychecked,
                                      callback: function ($$v) {
                                        _vm.$set(subItem, "mychecked", $$v)
                                      },
                                      expression: "subItem.mychecked",
                                    },
                                  }),
                                  subItem.watermarkFlag !== null &&
                                  (_vm.row.workplace === "PL" ||
                                    _vm.row.workplace === "manager" ||
                                    _vm.row.workplace === "investigator" ||
                                    (_vm.row.workplace === "supervisor" &&
                                      _vm.row.isSurveyUser === "Y"))
                                    ? _c("vab-icon", {
                                        staticStyle: {
                                          "font-size": "20px",
                                          "z-index": "999",
                                          position: "relative",
                                          top: "-26px",
                                          left: "-12px",
                                          color: "rgb(204, 204, 204)",
                                          cursor: "pointer",
                                        },
                                        attrs: { icon: ["fas", "eye"] },
                                        on: {
                                          click: function ($event) {
                                            return _vm.switchWater(
                                              subItem,
                                              photoItem
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  (_vm.row.workplace === "supervisor" &&
                                    _vm.row.flag === "edit") ||
                                  (_vm.row.workplace === "verify_people" &&
                                    _vm.row.flag === "review")
                                    ? _c("el-switch", {
                                        staticClass: "submitSwitch",
                                        attrs: {
                                          "active-value": 1,
                                          "inactive-value": 0,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.switchSubmit(
                                              subItem,
                                              photoItem
                                            )
                                          },
                                        },
                                        model: {
                                          value: subItem.tag,
                                          callback: function ($$v) {
                                            _vm.$set(subItem, "tag", $$v)
                                          },
                                          expression: "subItem.tag",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.labelEditReload
                                    ? _c("label-edit", {
                                        staticClass: "labelEdit",
                                        attrs: {
                                          text: subItem.name,
                                          "able-change": _vm.operaEnable,
                                        },
                                        on: {
                                          "text-updated-blur": function (
                                            $event
                                          ) {
                                            return _vm.textUpdateCallbackBlur(
                                              $event,
                                              subItem,
                                              photoItem
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.row.projectSource && _vm.row.projectSource === "process_br"
                  ? _c(
                      "div",
                      { staticClass: "intr-box" },
                      [
                        _c("span", [_vm._v("说明 ：")]),
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            disabled:
                              _vm.row.workplace === "investigator" ||
                              (_vm.row.workplace === "supervisor" &&
                                _vm.row.isSurveyUser === "Y")
                                ? false
                                : true,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.savePhotoIntro(
                                photoItem.remark,
                                photoIndex
                              )
                            },
                          },
                          model: {
                            value: photoItem.remark,
                            callback: function ($$v) {
                              _vm.$set(photoItem, "remark", $$v)
                            },
                            expression: "photoItem.remark",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "el-col",
            [
              _c("el-pagination", {
                attrs: {
                  background: _vm.background,
                  "current-page": _vm.parameter.body.pageNum,
                  layout: _vm.layout,
                  "page-size": _vm.parameter.body.pageSize,
                  total: _vm.total,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("photoView", { ref: "photoView" }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "drag", rawName: "v-drag" }],
          staticClass: "renameDialog",
          attrs: {
            title: "相册重命名",
            visible: _vm.renameVisible,
            width: "30%",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.renameClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.renameVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "renameForm",
                      attrs: {
                        model: _vm.renameForm,
                        rules: _vm.renameFormRules,
                        "label-width": "80px",
                        "label-position": "top",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "重命名", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入重新命名之后的名字",
                              clearable: "",
                            },
                            model: {
                              value: _vm.renameForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.renameForm, "name", $$v)
                              },
                              expression: "renameForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.renameClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.renameSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "downloadDialog",
          attrs: {
            title: "下载确认",
            visible: _vm.downloadVisible,
            width: "30%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.downloadVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否要下载所有勾选内容？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.downloadVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.downloadLoading, type: "primary" },
                  on: { click: _vm.download },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "albumDialog",
          attrs: {
            title: "新增相册",
            visible: _vm.albumVisible,
            width: "40%",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.albumClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.albumVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "albumForm",
                      attrs: {
                        model: _vm.albumForm,
                        rules: _vm.albumFormRules,
                        "label-width": "80px",
                        "label-position": "top",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "相册名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入新增相册名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.albumForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.albumForm, "name", $$v)
                              },
                              expression: "albumForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.albumClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addAlbum } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.uploadTitle,
            visible: _vm.openUploadSource,
            width: "573px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openUploadSource = $event
            },
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", type: "flex", gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "600",
                          "font-size": "15px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v(" 请您上传资源 ")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "upload-demo",
                            attrs: {
                              action: "#",
                              "on-error": _vm.handleError,
                              "http-request": _vm.batch,
                              "before-upload": _vm.handelBeforeUpload,
                              "on-remove": _vm.handleRemove,
                              "auto-upload": true,
                              multiple: "",
                              accept:
                                ".png, .jpg, .jpeg, .bmp, .tif, .ofd,.PNG, .JPG, .JPEG, .BMP, .TIF, .OFD",
                              "file-list": _vm.fileList,
                              drag: "",
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v(" 将图片拖到此处，或 "),
                              _c("em", [_vm._v("点击上传")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [
                                _vm._v(
                                  " 支持的图片类型包括：.png, .jpg, .jpeg, .bmp, .tif, .ofd "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  ref: "$complete",
                  attrs: {
                    loading: _vm.subLoading,
                    type: "primary",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.photoSubmit },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }