// Kyle API 工作台 -> 新建工单
import request from "@/utils/request";

// 创建工单 ->保存
export function createList(data) {
  return request({
    url: "/plWorkOrder/create",
    method: "post",
    data,
  });
}

//  pl工作台列表待处理编辑查询
export function getEditList(data) {
  return request({
    url: "/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}

// pl工作台列表待处理编辑保存
export function getEditSave(data) {
  return request({
    url: "/plWorkOrder/update",
    method: "post",
    data,
  });
}

// pl工作台列表待处理创建提交
export function submitCreate(data) {
  return request({
    url: "/plWorkOrder/saveAndCommit",
    method: "post",
    data,
  });
}
