// Kyle API 工单管理 -> 已处理
import request from "@/utils/request";

// 获取pl工作台 已处理列表
export function getList(data) {
  return request({
    url: "/plWorkOrder/queryPLProcessedPage",
    method: "post",
    data,
  });
}

export function doDelete(data) {
  return request({
    url: "/plOrderDone/doDelete",
    method: "post",
    data,
  });
}
