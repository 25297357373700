import request from "@/utils/request";

// 查询项目名称分页列表
export function queryProjectPage(data) {
  return request({
    url: "/queryProjectPage",
    // url: "/table/getList",
    method: "post",
    data,
  });
}

// 查询项目名称
export function queryProjectName(data) {
  return request({
    url: "/queryProjectNameList",
    method: "post",
    data,
  });
}

// 查询项目类型
export function queryProjectType(data) {
  return request({
    url: "/queryProjectTypeList",
    method: "post",
    data,
  });
}

// 查询项目来源
export function queryResource(data) {
  return request({
    url: "/queryProjectSource",
    method: "post",
    data,
  });
}

// 创建项目
export function create(data) {
  return request({
    url: "/createProject",
    method: "post",
    data: data,
  });
}

// 修改项目
export function update(data) {
  return request({
    url: "/updateProject",
    method: "post",
    data: data,
  });
}

// 修改项目来源
export function updateProjectSource(data) {
  return request({
    url: "/updateProjectSource",
    method: "post",
    data: data,
  });
}

// 删除项目
export function deleteProject(data) {
  return request({
    url: "/deleteProject",
    method: "post",
    data: data,
  });
}

// 启用禁用项目
export function startOrStop(data) {
  return request({
    url: "/enableOrDisableProject",
    method: "post",
    data: data,
  });
}

// 查询查询角色与项目来源信息
export function getProjectSource(data) {
  return request({
    url: "/queryRoleProjectSourcePage",
    // url: "/table/getList",
    method: "post",
    data,
  });
}

// 系统模板  查询模板数据
export function getSystemList(data) {
  return request({
    url: "/template/query",
    method: "post",
    data,
  });
}

// 系统模板 启动 禁用
export function switchEnable(data) {
  return request({
    url: "/template/enable",
    method: "post",
    data: data,
  });
}

// 系统模板 删除
export function deleteTemplate(data) {
  return request({
    url: "/template/delete",
    method: "post",
    data: data,
  });
}

// 系统模板 预览
export function previewTemplate(data) {
  return request({
    url: "/template/preview",
    method: "post",
    data: data,
  });
}
// 系统模板 预览
export function webTemplate(data) {
  return request({
    url: "/form/previewSysTemplateForm",
    method: "post",
    data: data,
  });
}

// 系统模块管理 ->新增模板
export function addSystemTemplate(data) {
  return request({
    url: "/template/create",
    method: "post",
    data,
  });
}
// 系统模块 —>查询文档模板
export function getTemplateFile() {
  return request({
    url: "/templateFile/getSelect",
    method: "post",
  });
}

// 系统模板  ->编辑回显
export function getEditTemplate(data) {
  return request({
    url: "/template/queryById",
    method: "post",
    data,
  });
}

// 系统模板 ->编辑保存
export function eEditTemplateSave(data) {
  return request({
    url: "/template/update",
    method: "post",
    data,
  });
}

// 系统模块管理 ->获取预览模板
export function getSystemTemplate(data) {
  return request({
    url: "/plWorkOrder/test",
    method: "post",
    data,
  });
}

// 系统模板 ->导出模板
export function downloadReport(seqId) {
  return request({
    url: "/template/export",
    method: "get",
    params: {
      seqId,
    },
  });
}

// 系统模板 ->寻找文档模板
export function searchDocument(data) {
  return request({
    url: "/template/queryFileTemplateByProjectNo",
    method: "post",
    data,
  });
}

// 文档模块管理 ->获取模板列表
export function getFileList(data) {
  return request({
    url: "/templateFile/query",
    method: "post",
    data,
  });
}

// 文档模块管理 ->启用
export function fileEnable(data) {
  return request({
    url: "/templateFile/enable",
    method: "post",
    data,
  });
}

// 文档模块管理 ->删除
export function deleteFile(data) {
  return request({
    url: "/templateFile/delete",
    method: "post",
    data,
  });
}

// 文档模块管理 ->创建
export function createFile(data) {
  return request({
    url: "/templateFile/create",
    method: "post",
    data,
  });
}

// 文档模块管理 ->创建
export function updateFile(data) {
  return request({
    url: "/templateFile/update",
    method: "post",
    data,
  });
}
// 文档模块管理 -> 取消关闭时清空元素
export function emptyElements(data) {
  return request({
    url: "/form/clearElement?projectId=" + data.projectId,
    method: "post",
    data,
  });
}


// 委托方与报告类型
export function getClientReportType() {
  return request({
    url: "/bhapi/Evidence/GetOrderTypeDictionary",
    method: "get",
  });
}

// 下载文档模板
export function downloadDocFile(projectNo) {
  return request({
    url: "/templateFile/download?projectNo=" + projectNo,
    method: "get",
  });
}