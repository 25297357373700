/**
 * @copyright CBI cbi@chinacbi.com
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑，不建议修改
 */

import { getInfo, login, logout, revokeTokenBatch } from "@/api/user";
import { queryOssSearch } from "@/api/workplace/tools";
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
} from "@/utils/accessToken";
import { resetRouter } from "@/router";
import { encryptEn, encrypt, deBackcrypt } from "@/utils/encrypt";
import cookie from "js-cookie";

const getDefaultState = () => {
  return {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    name: "",
    nickName: "",
    userNo: "",
    userId: "",
    roleId: "",
    userName: "",
    avatar: "cbi-site-front/avatar/temp_default_avatar/avatar_template.gif",
    permissions: [],
    roles: [],
    /** oss相关信息 */
    accessKeyId: "",
    accessKeySecret: "",
    bucketName: "",
    OSSClient: {},
    endpoint: "",
    endpoinxBak: "",
    region: "",
    policy: "",
    signature: "",
  };
};
const state = {
  accessToken: getAccessToken(),
  // refreshToken: getRefreshToken(),
  userName: "",
  avatar: "cbi-site-front/avatar/temp_default_avatar/avatar_template.gif",
  permissions: [],
  userNo: "",
  userId: "",
  roleId: "",
  roles: [],
  /** oss相关信息 */
  accessKeyId: "",
  accessKeySecret: "",
  bucketName: "",
  OSSClient: {},
  endpoint: "",
  endpoinxBak: "",
  region: "",
  policy: "",
  signature: "",
};
const getters = {
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  avatar: (state) => state.avatar,
  permissions: (state) => state.permissions,
  userId: (state) => state.userId,
  userNo: (state) => state.userNo,
  roles: (state) => state.roles,
  roleId: (state) => state.roleId,
  userName: (state) => state.userName,
  accessKeyId: (state) => state.accessKeyId,
  accessKeySecret: (state) => state.accessKeySecret,
  bucketName: (state) => state.bucketName,
  OSSClient: (state) => state.OSSClient,
  endpoint: (state) => state.endpoint,
  endpoinxBak: (state) => state.endpoinxBak,
  region: (state) => state.region,
  policy: (state) => state.policy,
  signature: (state) => state.signature,
};
const mutations = {
  /** 保存oss信息 */
  setAccessKeyId: (state, accessKeyId) => {
    state.accessKeyId = accessKeyId;
  },
  setAccessKeySecret: (state, accessKeySecret) => {
    state.accessKeySecret = accessKeySecret;
  },
  setBucketName: (state, bucketName) => {
    state.bucketName = bucketName;
  },
  setEndpoint: (state, endpoint) => {
    state.endpoint = endpoint;
  },
  setEndpoinxBak: (state, endpoinxBak) => {
    state.endpoinxBak = endpoinxBak;
  },
  setRegion: (state, region) => {
    state.region = region;
  },
  setPolicy: (state, policy) => {
    state.policy = policy;
  },
  setSignature: (state, signature) => {
    state.signature = signature;
  },
  setOSSClient: (state, OSSClient) => {
    state.OSSClient = OSSClient;
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_REFRESH_TOKEN: (state, refreshToken) => {
    state.refreshToken = refreshToken;
  },
  setCurrentRoleId: (state, roleId) => {
    state.roleId = roleId;
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
    setAccessToken(accessToken);
  },
  setUserID(state, userId) {
    state.userId = userId;
  },
  setUserNo(state, userNo) {
    state.userNo = userNo;
  },
  setUserName(state, userName) {
    state.userName = userName;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setAvatar(state, avatar) {
    state.avatar = avatar;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
};
const actions = {
  setPermissions({ commit }, permissions) {
    commit("setPermissions", permissions);
  },
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      let params;
      const client_id = "site";
      const client_secret = "123456";
      const grant_type = "password";
      // 实地系统用户类型
      const userType = "00";
      const { username, password } = userInfo;
      if (username) {
        params = {
          username: username.trim(),
          password: encryptEn(password),
          client_id,
          client_secret,
          grant_type,
          userType,
        };
      } else {
        params = userInfo;
      }
      login(params)
        .then((response) => {
          commit("setAccessToken", response.access_token);
          commit("SET_REFRESH_TOKEN", response.refresh_token);
          setAccessToken(response.access_token);
          setRefreshToken(response.refresh_token);
          cookie.set("expires_in", response.expires_in * 1000 + Date.now());
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      let systemId = 1122;
      getInfo(systemId)
        .then((response) => {
          const { user } = response;
          const avatar =
            !user.avatar || user.avatar == ""
              ? "cbi-site-front/avatar/temp_default_avatar/avatar_template.gif"
              : `${user.avatar}`;
          if (!response) {
            reject("验证失败，请重新登录..");
          }
          commit("setUserID", user.userId);
          commit("setUserName", user.userName);
          commit("setUserNo", user.userNo);
          commit("setRoles", response.roles);
          commit("setAvatar", avatar);
          let roleId = sessionStorage.getItem("roleId");
          if (roleId) {
            commit("setCurrentRoleId", parseInt(roleId));
          } else {
            commit("setCurrentRoleId", response.roles[0].roleId);
          }
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  setRoleId: ({ commit }, roleId) => {
    console.log(roleId);
    commit("setCurrentRoleId", roleId);
  },
  /** Oss信息 */
  getOssInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      let param = {
        body: {},
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "",
        },
      };
      queryOssSearch(param)
        .then((response) => {
          if (response.header.retCode === "1") {
            const crypto = require("crypto");
            const policyText =
              '{"expiration": "2090-01-01T12:00:00.000Z","conditions": [["content-length-range", 0, 1048576000]]}';
            const policy = Buffer.from(policyText).toString("base64");
            /**OSS 签名 */
            const signature = crypto
              .createHmac("sha1", deBackcrypt(response.body.accessKeySecret))
              .update(policy)
              .digest("base64");
            commit("setAccessKeyId", deBackcrypt(response.body.accessKeyId));
            commit(
              "setAccessKeySecret",
              deBackcrypt(response.body.accessKeySecret)
            );
            commit("setBucketName", deBackcrypt(response.body.bucketName));
            commit("setEndpoint", deBackcrypt(response.body.endpoint));
            commit("setEndpoinxBak", deBackcrypt(response.body.endpoinxBak));
            commit("setRegion", deBackcrypt(response.body.region));
            commit("setPolicy", policy);
            commit("setSignature", signature);
            const OSS = require("ali-oss");
            const client = new OSS({
              bucket: state.bucketName,
              region: state.region,
              accessKeyId: state.accessKeyId,
              accessKeySecret: state.accessKeySecret,
            });
            commit("setOSSClient", client);
          } else {
            this.$baseMessage(response.header.retMessage, "error");
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  logout({ commit, state, dispatch }, param) {
    return new Promise((resolve, reject) => {
      if (param === "updatePassword") {
        revokeTokenBatch({ access_token: state.accessToken })
          .then(() => {
            removeAccessToken();
            removeRefreshToken(); // and refresh token
            sessionStorage.clear(); // remove candidate data of createOrder page
            cookie.remove("resetTime");
            commit("RESET_STATE");
            clearInterval(window.interval); // remember clear time interval
            dispatch("tagsBar/delAllRoutes", null, { root: true });
            resetRouter();
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      } else {
        logout({ access_token: state.accessToken })
          .then(() => {
            removeAccessToken();
            removeRefreshToken(); // and refresh token
            sessionStorage.clear(); // remove candidate data of createOrder page
            cookie.remove("resetTime");
            commit("RESET_STATE");
            clearInterval(window.interval); // remember clear time interval
            dispatch("tagsBar/delAllRoutes", null, { root: true });
            resetRouter();
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    });
  },
  resetAccessToken({ commit }) {
    return new Promise((resolve) => {
      // commit("setPermissions", []);
      commit("setAccessToken", "");
      sessionStorage.clear();
      cookie.remove("resetTime");
      removeAccessToken();
      removeRefreshToken();
      commit("RESET_STATE");
      clearInterval(window.interval);
      resolve();
    });
  },
};
export default { state, getters, mutations, actions };
