// Kyle API 工作台 -> 新建工单
import request from "@/utils/request";

// 详情 查询影像资料信息
export function detailMediaResource(data) {
  return request({
    url: "/workOrderDetails/queryMediaResource",
    method: "post",
    data,
  });
}

// 工单详情-web拍摄照片查询
export function queryGroupResource(data) {
  return request({
    url: "/workOrderDetails/queryWebGroupMediaSearch",
    method: "post",
    data,
  });
}

// 切换提交状态
export function updateSubmit(data) {
  return request({
    url: "/workOrderDetails/updateTag",
    method: "post",
    data,
  });
}

// 批量切换提交状态
export function updateBatchSubmit(data) {
  return request({
    /** abia 原接口地址 */
    // url: "/workOrderDetails/updateTags",
    // 优化接口地址
    url: "/workOrderDetails/updateTagList",
    method: "post",
    data,
  });
}

// 工单详情-切换水印
export function updateWater(data) {
  return request({
    url: "/workOrderDetails/updateWatermarkFlag",
    method: "post",
    data,
  });
}

// 工单详情-切换顺序
export function updateSequence(data) {
  return request({
    url: "/workOrderDetails/updataMediaResource",
    method: "post",
    data,
  });
}

// 详情 查询工单详情-按相册分组查询照片信息
export function detailPhotoResource(data) {
  return request({
    url: "/workOrderDetails/webWatermarkFlag",
    method: "post",
    data,
  });
}

// 详情 取证资料
export function evidenceOper(data) {
  return request({
    url: "/template/webGenerate",
    method: "post",
    data,
  });
}

// 详情 取证资料 保存
export function evidenceSave(data) {
  return request({
    url: "/template/webSubmit",
    method: "post",
    data,
  });
}

// 取证资料 保存 重构 devin
export function saveEvidence(data) {
  return request({
    url: "/saveWebFormElementValue",
    method: "post",
    data,
  });
}

// 详情 上传音像资料
export function uploadSource(data) {
  return request({
    url: "/workOrderDetails/insertMediaResource",
    method: "post",
    data,
  });
}

// 详情 上传音像资料
export function deleteSource(data) {
  return request({
    url: "/workOrderDetails/deleteMediaResource",
    method: "post",
    data,
  });
}

// 详情 新增相册
export function plusAlbum(data) {
  return request({
    url: "/workOrderDetails/insertMediaResourceGroup",
    method: "post",
    data,
  });
}

// 详情 删除相册
export function delAlbum(data) {
  return request({
    url: "/workOrderDetails/deleteMediaResourceGroup",
    method: "post",
    data,
  });
}

// 详情 新增相册
export function renameAlbum(data) {
  return request({
    url: "/workOrderDetails/updataMediaResourceGroupName",
    method: "post",
    data,
  });
}

export function saveRemark(data) {
  return request({
    url: "/saveOrUpdateMediaResourceRemark",
    method: "post",
    data,
  });
}

// 详情 重命名影视资料
export function renameMedia(data) {
  return request({
    url: "/workOrderDetails/updateSiteMediaResourceByName",
    method: "post",
    data,
  });
}

// 详情 验证唯一
export function checkMediaName(data) {
  return request({
    url: "/workOrderDetails/checkResourceName",
    method: "post",
    data,
  });
}

// 详情 验证唯一 List接口
export function checkMediaNameList(data) {
  return request({
    url: "/workOrderDetails/checkResourceNameList",
    method: "post",
    data,
  });
}

// 附件验证唯一名称 list接口
export function checkAttachNameList(data) {
  return request({
    url: "/workOrderDetails/checkAttachList",
    method: "post",
    data,
  });
}

/**
 * 下载图片压缩包
 */
export function downloadAlbumCompressed(data) {
  return request({
    url: "/downLoadTest",
    method: "post",
    data,
    responseType: "blob",
  });
}

/**
 * 拍摄照片相册转PDF
 */
export function albumToPDF(data) {
  return request({
    url: "/webPicToPDF",
    method: "post",
    data,
  });
}

/**
 * 创建下载任务
 */
export function createDownloadTask(data) {
  return request({
    url: "/createTask",
    method: "post",
    data,
  });
}

/**
 * 查询下载任务列表
 */
export function fetchDownloadTaskList(data) {
  return request({
    url: "/queryTask",
    method: "post",
    data,
  });
}

/**
 * 重试创建下载任务
 */
export function rebuildDownloadTask(data) {
  return request({
    url: "/retryTask",
    method: "post",
    data,
  });
}
