<!-- 其他文件预览Dialog-->
<template>
  <!-- 其他文件预览 -->
  <el-dialog
    :visible.sync="dialogtelRecordVisible"
    append-to-body
    class="telRecordDialog"
    @close="closeView"
  >
    <el-tabs
      v-model="activeTabsValue"
      :tab-position="tabPosition"
      style="height: 350px"
      @tab-click="handleTabsClick"
    >
      <el-tab-pane
        v-for="(telRecordItem, telRecordIndex) in telRecordData"
        :key="telRecordIndex"
        :name="telRecordItem.name"
        :stretch="true"
        :label="
          telRecordItem.name.length > 12
            ? telRecordItem.name.substring(0, 12) + '...'
            : telRecordItem.name
        "
      >
        <el-row>
          <el-col>
            <el-row>
              <el-col style="text-align: center">
                <el-tooltip
                  :content="telRecordItem.name"
                  placement="top"
                  effect="light"
                >
                  <p style="width: 95%; margin: 0 auto; text-align: center">
                    {{
                      telRecordItem.name +
                      "." +
                      telRecordItem.path.substring(
                        telRecordItem.path.lastIndexOf(".") + 1
                      )
                    }}
                  </p>
                </el-tooltip>
                <audio
                  v-if="judgeFileType(telRecordItem.path)"
                  ref="myAudio"
                  :src="ossPath + telRecordItem.path"
                  controls="controls"
                  style="width: 95%; height: 170px"
                ></audio>
                <!-- audio ——>不可预览 -->
                <img
                  v-else
                  style="width: 160px; height: 160px; cursor: pointer"
                  src="@/icon/annex.svg"
                  :fit="fit"
                  :title="telRecordItem.name"
                  @click="handleDownload(telRecordItem)"
                />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
  import { downloadFile, isExistOSS } from "@/utils/index";
  export default {
    name: "TelRecordView",
    data() {
      return {
        telRecordData: "",
        tabPosition: "left",
        dialogtelRecordVisible: false,
        fit: "fill",
        activeTabsValue: "2",
        telRecord_index: "",
        urlList: [],
        tab_name: "",
        ossPath: "",
        OSSClient: {},
      };
    },
    created() {},
    methods: {
      showView() {
        this.dialogtelRecordVisible = true;
      },
      handleTabsClick() {
        if (this.$refs.myAudio !== undefined) {
          this.$refs.myAudio.forEach((item) => {
            item.pause(); //暂停
          });
        }
      },
      closeView() {
        if (this.$refs.myAudio !== undefined) {
          this.$refs.myAudio.forEach((item) => {
            item.pause(); //暂停
          });
        }
      },
      /** 判断文件类型 */
      judgeFileType(filePath) {
        /** 可预览的文件类型 */
        // TODO: 经与产品bunny沟通，暂处理Google业务，不启用火狐兼容方案
        // 优化
        let fileType = ["mp3", "ogg", "wav", "m4a"];
        fileType.map((item) => {
          fileType.push(item.toUpperCase());
        });
        const itemType = filePath.substring(filePath.lastIndexOf(".") + 1);
        return fileType.includes(itemType);
      },
      /** 点击下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
      bindData(
        telRecordData,
        telRecordIndex,
        telRecordName,
        ossPath,
        OSSClient
      ) {
        this.ossPath = ossPath;
        this.OSSClient = OSSClient;
        this.activeTabsValue = telRecordName;
        this.telRecordData = telRecordData;
      },
    },
  };
</script>

<style scoped>
  .telRecordDialog {
    z-index: 10;
  }
  .telRecordDialog /deep/.el-dialog__header {
    padding: 0 20px;
  }
  .telRecordDialog /deep/.el-dialog__body {
    padding-top: 40px;
  }
</style>
