var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "imgDialog",
      attrs: { visible: _vm.dialogImgVisible, "append-to-body": "" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogImgVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { height: "350px" },
          attrs: { "tab-position": _vm.tabPosition },
          model: {
            value: _vm.activeTabsValue,
            callback: function ($$v) {
              _vm.activeTabsValue = $$v
            },
            expression: "activeTabsValue",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "走访开始", stretch: "true", label: "走访开始" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-carousel",
                        {
                          ref: "imgCarousel1",
                          attrs: {
                            trigger: "click",
                            height: "300px",
                            autoplay: false,
                            "indicator-position": "none",
                          },
                        },
                        _vm._l(
                          _vm.photoData.filter((item) => {
                            return item.visitFlag === 0
                          }),
                          function (subItem, subIndex) {
                            return _c(
                              "el-carousel-item",
                              { key: subIndex, attrs: { name: "subIndex" } },
                              [
                                subItem.visitFlag === 0
                                  ? _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("viewer", [
                                              subItem.visitFlag === 0
                                                ? _c("img", {
                                                    staticStyle: {
                                                      height: "300px",
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.ossPath +
                                                        subItem.path,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "走访结束", stretch: "true", label: "走访结束" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-carousel",
                        {
                          ref: "imgCarousel2",
                          attrs: {
                            trigger: "click",
                            height: "300px",
                            autoplay: false,
                            "indicator-position": "none",
                          },
                        },
                        _vm._l(
                          _vm.photoData.filter((item) => {
                            return item.visitFlag === 1
                          }),
                          function (subItem, subIndex) {
                            return _c(
                              "el-carousel-item",
                              { key: subIndex, attrs: { name: "subIndex" } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c("viewer", [
                                          subItem.visitFlag === 1
                                            ? _c("img", {
                                                staticStyle: {
                                                  "max-height": "300px",
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.ossPath + subItem.path,
                                                  "preview-src-list":
                                                    _vm.urlList,
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }