var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" },
        },
        [
          _vm.title === "编辑文档模板"
            ? _c(
                "el-form-item",
                { attrs: { label: "文档模板名称", prop: "fileName" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 1, maxRows: 2 },
                      placeholder: "请输入文档模板名称",
                      disabled: "",
                    },
                    model: {
                      value: _vm.form.fileName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fileName", $$v)
                      },
                      expression: "form.fileName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "上传文档模板", prop: "path" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.endpoinxBak,
                    accept: ".docx,.xlsx,.xls",
                    "on-error": _vm.handleError,
                    "on-remove": _vm.handleRemove,
                    "on-change": _vm.handelChange,
                    "before-upload": _vm.beforeUpload,
                    "http-request": _vm.fnUploadRequest,
                    multiple: true,
                    limit: 2,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v(" 请选择文件上传，支持docx/xlsx/xls文件 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目", prop: "projectNo" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.projectInfo,
                  clearable: true,
                  filterable: true,
                  disabled: _vm.projectDisable,
                  separator: ":",
                  placeholder: "项目",
                },
                on: { change: _vm.handleProjectChange },
                model: {
                  value: _vm.form.projectNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "projectNo", $$v)
                  },
                  expression: "form.projectNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文档模板描述", prop: "description" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "请描述模板" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }