<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class"
                   @submit.native.prevent>
            <el-row :gutter="20">
              <!-- 流转日期 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="circulationTime">
                  <el-date-picker v-model="queryForm.circulationTime"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="流转开始日期"
                                  end-placeholder="流转结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number.trim="queryForm.workOrderNo"
                            clearable
                            placeholder="工单编号"></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            clearable
                            placeholder="目标主体"></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               clearable
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <transition name="fade">
              <el-row v-show="isShow"
                      transiton="fade"
                      :gutter="20">
                <!-- 预约走访日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="visitDate">
                    <el-date-picker v-model="queryForm.visitDate"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="预约走访开始日期"
                                    end-placeholder="预约走访结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- TODO: 人员选择, 需要后端提供接口 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="userInfo">
                    <el-cascader :value="queryForm.userInfo"
                                 :options="staffInfo"
                                 clearable
                                 :filterable="true"
                                 separator=" "
                                 placeholder="选择人员"
                                 @change="handleSatffChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 选择地区 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="address">
                    <el-cascader v-model="queryForm.address"
                                 :options="cityInfo"
                                 :filterable="true"
                                 :clearable="true"
                                 separator="/"
                                 placeholder="地区"
                                 :props="{ checkStrictly: true }"
                                 @change="handleBlockChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 是否退回 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="normalFallbackFlag">
                    <el-select v-model="queryForm.normalFallbackFlag"
                               placeholder="是否普通退回"
                               clearable
                               filterable>
                      <el-option v-for="item in isGiveBacks"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 内部截止日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="innerDeadDate">
                    <el-date-picker v-model="queryForm.innerDeadDate"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="内部截止开始日期"
                                    end-placeholder="内部截止结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 到期时间-->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reachTime">
                    <el-select v-model="queryForm.reachTime"
                               placeholder="到期时间"
                               clearable
                               filterable>
                      <el-option v-for="item in reachTimeOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 订单编号 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="brWorkNo">
                    <el-input v-model.trim="queryForm.brWorkNo"
                              placeholder="订单编号"
                              clearable
                              @keyup.enter.native="handleQuery"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 报告类型 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reportType">
                    <el-select v-model="queryForm.reportType"
                               placeholder="报告类型"
                               clearable
                               filterable>
                      <el-option v-for="item in reportType"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 项目来源 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="projectSource">
                    <el-select v-model="queryForm.projectSource"
                               placeholder="请选择项目来源"
                               clearable
                               filterable>
                      <el-option v-for="item in resourceOptions"
                                 :key="item.key"
                                 :label="item.name"
                                 :value="item.key"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </transition>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <!-- <el-button icon="el-icon-upload" type="primary" @click="handleSubmit">
            批量提交
          </el-button> -->
          <el-button icon="el-icon-back"
                     type="warning"
                     @click="handleWithdraw">
            批量退回
          </el-button>
          <el-button icon="el-icon-document-delete"
                     type="danger"
                     @click="handleQuit">
            批量放弃
          </el-button>
          <el-button :loading="downloadLoading"
                     type="primary"
                     @click="handleDownload">
            导出
          </el-button>

          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    :row-class-name="tableRowClassName"
                    style="margin-top: 15px"
                    border
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"
                             :selectable="selectable"></el-table-column>
            <el-table-column label="工单编号"
                             prop="workOrderNo"
                             align="center"
                             width="130">
              <template slot-scope="scope">
                <div class="img-icon">
                  <img v-if="scope.row.operStatus === 31"
                       src="@/icon/back.svg"
                       alt="退"
                       class="my-back-img" />
                  <img v-if="scope.row.brChangeFlag"
                       src="@/icon/edit.svg"
                       alt="修"
                       class="my-edit-img" />
                </div>
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address"></el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             sortable
                             width="150"></el-table-column>
            <el-table-column prop="maturityDate"
                             label="到期时间(天)"
                             width="70"
                             align="center"></el-table-column>
            <el-table-column prop="bookUserName"
                             label="预约人员"
                             sortable
                             width="110"></el-table-column>
            <el-table-column prop="bookVisitTime"
                             label="预约走访时间"
                             sortable
                             width="130">
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <!-- 流转日期 -->
            <el-table-column prop="circulationTime"
                             label="流转日期"
                             width="120"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.circulationTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             label="环节/整体滞留(天)"
                             fit="true"
                             width="90"></el-table-column>
            <el-table-column label="操作"
                             width="220px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="编辑"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             circle
                             icon="el-icon-edit-outline"
                             :disabled="
                      4 === scope.row.workOrderStatus ||
                      userNo != scope.row.surveyUser
                        ? true
                        : false
                    "
                             @click="handleDetail(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="跳过打卡"
                            placement="top-start">
                  <el-button type="primary"
                             circle
                             size="mini"
                             icon="el-icon-delete-location"
                             :disabled="4 !== scope.row.workOrderStatus ? true : false"
                             @click="skipClockIn(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="退回"
                            placement="top-start">
                  <el-button size="mini"
                             type="warning"
                             icon="el-icon-back"
                             circle
                             :disabled="userNo != scope.row.surveyUser ? true : false"
                             @click="handleWithdraw(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="放弃"
                            placement="top-start">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-document-delete"
                             circle
                             :disabled="userNo != scope.row.surveyUser ? true : false"
                             @click="handleQuit(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!--提交工单-->
    <submit ref="submit"
            @refreshData="refreshData"></submit>
    <!--退囘工單-->
    <withdraw ref="withdraw"
              @refreshData="refreshData"></withdraw>
    <!--放弃工单-->
    <quit ref="quit"
          @refreshData="refreshData"></quit>
    <!--编辑---详情工单-->
    <detail ref="detail"
            @refreshData="refreshData"></detail>
  </div>
</template>

<script>
import { getTodo, skipClock } from "@/api/workplace/investigate/investigate";
import submit from "./submit";
import withdraw from "./withdraw";
import quit from "./quit";
import detail from "./detail";
import { querySystemUsers } from "@/api/workplace/tools";
import { returnBitTimestamp, returnDeadtimestamp, getRoleKey } from "@/utils";
import {
  getBlock,
  getGeneralReason,
  getProjectSource,
  getReportTypeList,
} from "@/api/workplace/tools";
import { mapGetters } from "vuex";

export default {
  name: "Todo",
  components: {
    submit,
    withdraw,
    quit,
    detail,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      isShow: false,
      list: [],
      imageList: [],
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      background: true,
      selectRows: "",
      elementLoadingText: "正在加载...",
      staffInfo: [],
      staffForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 查询列表
      queryForm: {
        circulationTime: [], // 表单数据，不传递到后端
        circulationTimeStart: "",
        circulationTimeEnd: "",
        workOrderNo: "",
        targetSubject: "",
        projectId: "",

        userInfo: null, // 人员信息
        roleUser: null, // 选择人员参数

        visitDate: [],
        bookVisitTimeStart: "",
        bookVisitTimeEnd: "",
        innerDeadDate: [], // 表单数据，不传递到后端
        innerDeadlineEnd: null,
        innerDeadlineStart: null,
        bookUser: "",
        address: "",
        monitorUser: "",

        reachTime: "",
        plUser: "",
        normalFallbackFlag: "",
        normalFallbackReasonId: "",

        pageNum: 1,
        pageSize: 10,
        nextUserNo: "",
        nextUserRoleNo: "",
      },
      param: {
        body: {},
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 角色人员
      roleOptions: [],
      roleOptions_arr: [],
      // 项目
      projectInfo: [],
      projectForm: {
        body: {
          source: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },

      cityInfo: [],
      cityForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      reachTimeOptions: [
        {
          value: 0.5,
          label: "0.5天",
        },
        {
          value: 1,
          label: "1天",
        },
        {
          value: 2,
          label: "2天",
        },
        {
          value: 3,
          label: "3天",
        },
        {
          value: 5,
          label: "5天",
        },
        {
          value: 7,
          label: "7天",
        },
        {
          value: 10,
          label: "10天",
        },
        {
          value: 15,
          label: "15天",
        },
        {
          value: 20,
          label: "20天",
        },
        {
          value: 30,
          label: "30天",
        },
      ],
      isGiveBacks: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      //  退回原因参数
      backReasonParam: {
        body: {
          templateTypeSeqId: "6694249429643431936",
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      reasonOptions: [],
      downloadLoading: false,
      clockPara: {
        body: [],
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      obj: {
        bookUser: "",
        bookVisitTime: "",
        content: "",
        operAction: "",
        pageNum: 0,
        pageSize: 0,
        step: "",
        userNo: "",
        userRoleNo: "",
        workOrderNo: "",
      },
      reportType: [], // 报告类型
      // 项目来源
      resourceOptions: [
        {
          name: "香港业务流程",
          key: "process_hk",
        },
        {
          name: "本地业务流程",
          key: "process_nd",
        },
        {
          name: "BR业务流程",
          key: "process_br",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
    }),
  },
  created() {
    this.param.body.userNo = this.userNo;
    this.param.body.userRoleNo = getRoleKey("investigate", this.roles);
  },
  beforeDestroy() {},
  methods: {
    /** 数据初始化 */
    handleInitData() {
      this.fetchData();
      // 获取项目
      this.getProject();
      // 获取人员选项
      this.getStaffInfo();

      this.clockPara.body = [];
      // 获取报告类型
      this.getReportType();
    },
    /** 获取人员信息 */
    async getStaffInfo() {
      this.staffForm.header.currentTime = returnBitTimestamp(new Date());
      const { header, body } = await querySystemUsers(this.staffForm);
      if (header.retCode === "1") {
        this.staffInfo = body;
      }
    },
    /** 选择人员数据框改变值 */
    handleSatffChange(value) {
      if (value) this.queryForm.userInfo = value;
    },
    /** 导出Excel */
    handleDownload() {
      if (this.selectRows.length > 0) {
        this.downloadLoading = true;
        import("@/vendor/ExportExcel").then((excel) => {
          const tHeader = [
            "工单编号",
            "订单编号",
            "目标主体",
            "目标地址",
            "项目",
            "报告类型",
            "二级类型",
            "到期时间(天)",
            "预约人员",
            "预约走访时间",
            "流转日期",
            "环节/整体滞留(天)",
          ];
          const filterVal = [
            "workOrderNo",
            "brWorkNo",
            "targetSubject",
            "address",
            "projectName",
            "reportType",
            "subReportType",
            "maturityDate",
            "bookUserName",
            "bookVisitTime",
            "circulationTime",
            "stepAndStranded",
          ];
          const list = this.selectRows;
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: "工单列表",
            autoWidth: true,
            bookType: "xlsx",
          });
          this.downloadLoading = false;
        });
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },
    /** 组装导出数据 */
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (!v[j]) {
            return "——";
          }
          return v[j];
        })
      );
    },
    // 刷新数据
    refreshData() {
      /* 添加tab标签  传参 */
      let refresh_data = {
        todo_total: this.total,
        isDone: true,
      };
      this.$emit("child-todo", refresh_data);
      this.fetchData();
    },
    // 获取项目
    getProject() {
      this.projectForm.header.currentTime = returnBitTimestamp(new Date());
      getProjectSource(this.projectForm).then((response) => {
        if (response.header.retCode == "1") {
          this.projectInfo = response.body;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取项目失败，请重新刷新页面`
          );
        }
      });
    },
    // 选择项目
    handleProjectChange(value) {
      this.queryForm.projectId = value.length > 0 ? value[1] : null;
    },
    // 获取地区
    getAddress() {
      this.cityForm.header.currentTime = returnBitTimestamp(new Date());
      getBlock(this.cityForm).then((response) => {
        if (response.header.retCode == "1") {
          let block_info = [];
          block_info.push(response.body[0]);
          this.cityInfo = block_info;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取地区失败，请重新刷新页面`
          );
        }
      });
    },
    // 选择地区
    handleBlockChange(value) {
      if (value.length == 0) {
        this.queryForm.address = "";
      } else {
        this.queryForm.address = value.join(",");
      }
    },
    // 获取报告分类
    getReportType() {
      getReportTypeList(this.param).then((response) => {
        if (response.header.retCode === "1") {
          this.reportType = response.body;
        }
      });
    },
    // 调查工作天查询待处理列表
    async fetchData() {
      this.listLoading = true;
      // 选择人员数据
      if (this.queryForm.userInfo && this.queryForm.userInfo.length > 0) {
        this.queryForm.roleUser = `${this.queryForm.userInfo[0]}:${this.queryForm.userInfo[1]}`;
      } else {
        this.queryForm.roleUser = "";
      }
      // 流转日期范围
      if (null !== this.queryForm.circulationTime) {
        if (0 < this.queryForm.circulationTime.length) {
          this.queryForm.circulationTimeStart = returnBitTimestamp(
            this.queryForm.circulationTime[0]
          );
          this.queryForm.circulationTimeEnd = returnDeadtimestamp(
            this.queryForm.circulationTime[1]
          );
        }
      } else {
        this.queryForm.circulationTimeStart = null;
        this.queryForm.circulationTimeEnd = null;
      }
      // 预约走访日期范围
      if (null !== this.queryForm.visitDate) {
        if (0 < this.queryForm.visitDate.length) {
          this.queryForm.bookVisitTimeStart = returnBitTimestamp(
            this.queryForm.visitDate[0]
          );
          this.queryForm.bookVisitTimeEnd = returnDeadtimestamp(
            this.queryForm.visitDate[1]
          );
        }
      } else {
        this.queryForm.bookVisitTimeStart = null;
        this.queryForm.bookVisitTimeEnd = null;
      }
      // 内部截止日期范围
      if (null !== this.queryForm.innerDeadDate) {
        if (0 < this.queryForm.innerDeadDate.length) {
          this.queryForm.innerDeadlineStart = returnBitTimestamp(
            this.queryForm.innerDeadDate[0]
          );

          this.queryForm.innerDeadlineEnd = returnDeadtimestamp(
            this.queryForm.innerDeadDate[1]
          );
        }
      } else {
        this.queryForm.innerDeadlineStart = null;
        this.queryForm.innerDeadlineEnd = null;
      }
      this.param.header.currentTime = returnBitTimestamp(new Date());
      this.param.body = Object.assign(this.param.body, this.queryForm);
      const { body } = await getTodo(this.param);
      this.list = body.records;
      this.total = body.total;
      let refresh_data = {
        todo_total: this.total,
        isDone: false,
      };
      this.$emit("child-todo", refresh_data);
      this.listLoading = false;
    },

    setSelectRows(val) {
      this.selectRows = val;
    },
    // 重置
    resetForm(formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
        this.queryForm = this.$options.data().queryForm;
      }
    },

    // 单个提交及批量提交
    handleSubmit(row) {
      // 单个提交
      if (row.id) {
        let rows = [];
        rows.push(row);
        this.$refs["submit"].openLevel = 0;
        this.$refs["submit"].showSubmit(rows);
      } else {
        // 批量提交
        if (this.selectRows.length > 0) {
          let array = [];
          this.selectRows.map((item) => {
            if (-1 === array.indexOf(item.workOrderStatus)) {
              array.push(item.workOrderStatus);
            }
          });
          if (-1 !== array.indexOf(4)) {
            this.$baseMessage("选中的工单未打卡，不允许提交", "error");
            return false;
          }

          if (1 !== array.length) {
            this.$baseMessage("当前工单状态不一致，无法批量提交", "warning");
            return false;
          } else {
            this.$refs["submit"].showSubmit(this.selectRows);
          }
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      }
    },
    // 单个退回及批量退回
    handleWithdraw(row) {
      this.$refs["withdraw"].openLevel = 0;
      // 单个退回
      if (row.id) {
        let rows = [];
        rows.push(row);
        this.$refs["withdraw"].showWithdraw(rows);
      } else {
        // 批量退回
        if (this.selectRows.length > 0) {
          let array = [];
          this.selectRows.map((item) => {
            if (-1 === array.indexOf(item.workOrderStatus)) {
              array.push(item.workOrderStatus);
            }
          });
          if (1 !== array.length) {
            this.$baseMessage("当前工单状态不一致，无法批量退回", "warning");
            return false;
          } else {
            this.$refs["withdraw"].showWithdraw(this.selectRows);
          }
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      }
    },

    // 单个放弃及批量放弃
    handleQuit(row) {
      // 单个放弃
      if (row.id) {
        let rows = [];
        rows.push(row);
        this.$refs["quit"].openLevel = 0;
        this.$refs["quit"].showQuit(rows);
      } else {
        // 批量放弃
        if (this.selectRows.length > 0) {
          let array = [];
          this.selectRows.map((item) => {
            if (-1 === array.indexOf(item.workOrderStatus)) {
              array.push(item.workOrderStatus);
            }
          });
          if (1 !== array.length) {
            this.$baseMessage("当前工单状态不一致，无法批量放弃", "warning");
            return false;
          } else {
            this.$refs["quit"].showQuit(this.selectRows);
          }
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      }
    },
    // 打开工单单详情页面
    handleDetail(row) {
      row.userNo = this.queryForm.userNo;
      row.userRoleNo = this.queryForm.userRoleNo;
      this.$refs["detail"].showDetail(row);
    },
    // 跳过打卡
    async skipClockIn(row) {
      this.obj.workOrderNo = row.workOrderNo;
      this.obj.userNo = this.userNo;
      this.clockPara.body.push(Object.assign({}, this.obj));

      const { header } = await skipClock(this.clockPara);
      if (header.retCode === "1") {
        this.$baseMessage(header.retMessage, "success");
      } else {
        this.$baseMessage(header.retMessage, "error");
      }
      this.clockPara.body = [];
      await this.fetchData();
    },

    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    heightQuery() {
      this.isShow = !this.isShow;
      // 获取地区
      this.getAddress();
      // 获取普通退回原因
      this.getNormalReason();
    },
    async getNormalReason() {
      this.backReasonParam.header.currentTime = returnBitTimestamp(new Date());
      this.reasonOptions = Object.assign(
        {},
        (await getGeneralReason(this.backReasonParam)).body
      );
    },
    handleQuery() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },

    // 表格显色
    tableRowClassName({ row }) {
      // 到期时间小于3，行内高亮
      if (row.maturityDate <= 3) {
        return "warning-row";
      } else return "";
    },
    selectable(row, column) {
      return this.userNo == row["surveyUser"];
    },
  },
};
</script>
<style scoped>
.el-col {
  margin-bottom: 10px;
}
.el-col :last-child {
  margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}

.el-select,
.el-cascader {
  width: 100%;
}

.el-dropdown-menu__item {
  padding: 0;
}
.el-table /deep/.warning-row {
  background: #f8b7bc;
}
.el-table /deep/.change-row {
  background: #ffff9f;
}
.my-form-class {
  margin-bottom: -10px !important;
}
</style>
<style>
.my-back-img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 2px;
}
</style>
