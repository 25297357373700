<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="项目来源" prop="projectSource">
        <el-select
          v-model="form.projectSource"
          placeholder="请选择项目来源"
          clearable
          filterable
        >
          <el-option
            v-for="item in resourceOptions"
            :key="item.key"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { updateProjectSource } from "@/api/project/manage";

  export default {
    name: "SourceEdit",
    data() {
      return {
        loading: false,
        form: {
          roleId: null,
          roleName: null,
          projectSource: null,
          updateTime: null,
        },
        parameter: {
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          body: {
            roleId: null,
            roleName: null,
            projectSource: null,
            updateTime: null,
          },
        },
        rules: {
          projectSource: [
            { required: true, trigger: "blur", message: "请选择项目来源" },
          ],
        },
        title: "",
        dialogFormVisible: false,
        resourceOptions: [
          {
            name: "香港业务流程",
            key: "process_hk",
          },
          {
            name: "本地业务流程",
            key: "process_nd",
          },
        ],
        param: {
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
      };
    },
    methods: {
      showEdit(row) {
        this.title = "编辑项目来源";
        this.form = Object.assign({}, row);
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("fetchData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            this.parameter.body.roleId = this.form.roleId;
            this.parameter.body.roleName = this.form.roleName;
            this.parameter.body.projectSource = this.form.projectSource;
            const { header } = await updateProjectSource(this.parameter);
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("fetchData");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
