import {
  storage,
  tokenTableName,
  refreshTokenTableName,
} from "@/config/settings";
import cookie from "js-cookie";
import store from "@/store";

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 获取accessToken
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getAccessToken() {
  if (storage) {
    if ("localStorage" === storage) {
      return localStorage.getItem(tokenTableName);
    } else if ("sessionStorage" === storage) {
      return sessionStorage.getItem(tokenTableName);
    } else if ("cookie" === storage) {
      return cookie.get(tokenTableName);
    } else {
      return localStorage.getItem(tokenTableName);
    }
  } else {
    return localStorage.getItem(tokenTableName);
  }
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 存储accessToken
 * @param accessToken
 * @returns {void|*}
 */
export function setAccessToken(accessToken) {
  if (storage) {
    if ("localStorage" === storage) {
      return localStorage.setItem(tokenTableName, accessToken);
    } else if ("sessionStorage" === storage) {
      return sessionStorage.setItem(tokenTableName, accessToken);
    } else if ("cookie" === storage) {
      return cookie.set(tokenTableName, accessToken);
    } else {
      return localStorage.setItem(tokenTableName, accessToken);
    }
  } else {
    return localStorage.setItem(tokenTableName, accessToken);
  }
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */
export function removeAccessToken() {
  if (storage) {
    if ("localStorage" === storage) {
      return localStorage.removeItem(tokenTableName);
    } else if ("sessionStorage" === storage) {
      return sessionStorage.clear();
    } else if ("cookie" === storage) {
      return cookie.remove(tokenTableName);
    } else {
      return localStorage.removeItem(tokenTableName);
    }
  } else {
    return localStorage.removeItem(tokenTableName);
  }
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */
export function getRefreshToken() {
  return cookie.get(refreshTokenTableName);
}

export function setRefreshToken(accessToken) {
  return cookie.set(refreshTokenTableName, accessToken);
}

export function removeRefreshToken() {
  return cookie.remove(refreshTokenTableName);
}

/**
 * 通过refresh token请求并返回新的access token
 * @param {String} param 用于请求的refresh token
 */
export function handleGetRefreshToken() {
  let params = {
    grant_type: "refresh_token",
    client_id: "site",
    client_secret: "123456",
    refresh_token: getRefreshToken(),
  };
  store.dispatch("user/login", params);
}

/**
 * 倒计时，并更新Cookie中的resetTime的值
 * @param {Number} timestamp 倒计时的起始时间，单位为秒
 */
export function isRefreshTokenExpired(timestamp) {
  clearInterval(window.interval);
  window.interval = setInterval(() => {
    timestamp = timestamp - 1;
    cookie.set("resetTime", timestamp);
    if (timestamp <= 30 || timestamp == undefined) {
      if (!window.isReresh) {
        handleGetRefreshToken();
      }
    }
  }, 1000);
}
