<!--Kyle PL工作台 -> 工单管理 -> 已处理-->
<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   class="demo-form-inline my-form-class"
                   style="margin-top: 15px">
            <el-row :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item>
                  <el-date-picker v-model="reset.orderScopeDate"
                                  type="daterange"
                                  unlink-panels
                                  :clearable="true"
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number="queryForm.workOrderNo"
                            placeholder="工单编号"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetBody">
                  <el-input v-model.trim="queryForm.targetSubject"
                            placeholder="目标主体"
                            :clearable="true"
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item>
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               :clearable="true"
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <transition name="fade">
              <el-row v-show="isShow"
                      transiton="fade"
                      :gutter="20">
                <!-- 走访日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item>
                    <el-date-picker v-model="reset.visitScopeDate"
                                    type="daterange"
                                    unlink-panels
                                    :clearable="true"
                                    range-separator="至"
                                    start-placeholder="走访开始日期"
                                    end-placeholder="走访结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 人员选择 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="userInfo">
                    <el-cascader :value="queryForm.userInfo"
                                 :options="staffInfo"
                                 clearable
                                 filterable
                                 separator=" "
                                 placeholder="选择人员"
                                 @change="handleSatffChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 工单状态 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="workOrderStatus">
                    <el-select v-model="queryForm.workOrderStatus"
                               placeholder="工单状态"
                               :clearable="true"
                               filterable>
                      <el-option v-for="item in orderStatusInfo"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 选择地区 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item>
                    <el-cascader v-model="reset.block"
                                 :options="cityInfo"
                                 :clearable="true"
                                 :filterable="true"
                                 placeholder="地区"
                                 :props="{ checkStrictly: true }"
                                 @change="handleBlockChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 创建日期  -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="createTimeTmp">
                    <el-date-picker v-model="reset.createTimeTmp"
                                    type="daterange"
                                    unlink-panels
                                    clearable
                                    range-separator="至"
                                    start-placeholder="创建开始日期"
                                    end-placeholder="创建结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 内部截止 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item>
                    <el-date-picker v-model="reset.innerDeadline"
                                    type="daterange"
                                    unlink-panels
                                    :clearable="true"
                                    range-separator="至"
                                    start-placeholder="内部截止开始日期"
                                    end-placeholder="内部截止结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 到期时间 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reachTime">
                    <el-select v-model="queryForm.reachTime"
                               placeholder="到期时间"
                               :clearable="true"
                               filterable>
                      <el-option v-for="item in reachTimeOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 操作状态 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="operStatus">
                    <el-select v-model="queryForm.operStatus"
                               placeholder="操作状态"
                               :clearable="true"
                               filterable>
                      <el-option v-for="item in operStatusInfo"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 订单编号 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="brWorkNo">
                    <el-input v-model.trim="queryForm.brWorkNo"
                              placeholder="订单编号"
                              clearable
                              @keyup.enter.native="handleQuery"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 报告类型 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reportType">
                    <el-select v-model="queryForm.reportType"
                               placeholder="报告类型"
                               clearable
                               filterable>
                      <el-option v-for="item in reportType"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 项目来源 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="projectSource">
                    <el-select v-model="queryForm.projectSource"
                               placeholder="请选择项目来源"
                               clearable
                               filterable>
                      <el-option v-for="item in resourceOptions"
                                 :key="item.key"
                                 :label="item.name"
                                 :value="item.key"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </transition>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-dropdown trigger="click"
                       @command="adjustStaff">
            <el-button plain
                       icon="el-icon-s-custom">
              调整人员
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="bookUser">调整预约</el-dropdown-item>
              <el-dropdown-item command="part_surveyUser">
                调整兼职调查员
              </el-dropdown-item>
              <el-dropdown-item command="full_surveyUser">
                调整全职调查员
              </el-dropdown-item>
              <el-dropdown-item command="supervisor">调整督导</el-dropdown-item>
              <el-dropdown-item command="audit">调整内审</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button :loading="downloadLoading"
                     type="primary"
                     style="margin-left: 10px"
                     @click="handleDownload">
            导出
          </el-button>

          <el-table ref="tableSort"
                    v-loading="listLoading"
                    style="margin-top: 15px"
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    :row-class-name="tableRowClassName"
                    border
                    fit
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"
                             align="center"></el-table-column>
            <el-table-column label="工单编号"
                             prop="workOrderNo"
                             width="130"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             sortable
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="到期时间(天)"
                             width="70"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.maturityDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="工单状态"
                             prop="workOrderStatusValue"
                             width="120"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.workOrderStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="下单日期"
                             prop="orderTime"
                             width="120"
                             align="center"
                             :formatter="dateFormat"
                             sortable></el-table-column>
            <!-- 创建日期-->
            <el-table-column label="创建日期"
                             prop="createTime"
                             width="120"
                             align="center"
                             :formatter="dateFormat"
                             sortable></el-table-column>
            <!-- 预约走访时间 -->
            <el-table-column prop="bookVisitTime"
                             label="预约走访时间"
                             width="130"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <!-- 走访时间 -->
            <el-table-column label="走访时间"
                             prop="visitTime"
                             width="120"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.visitTime || "-" }}
              </template>
            </el-table-column>
            <!-- 当前操作人 -->
            <el-table-column label="当前操作人"
                             prop="nowUserName"
                             width="120"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.nowUserName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             label="环节/整体滞留(天)"
                             width="90"
                             fit="true">
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="operStatusValue"
                             label="操作状态"
                             width="100px">
              <template slot-scope="scope">
                {{ scope.row.operStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             fixed="right"
                             fit
                             align="center"
                             width="120px">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="详情"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-document"
                             circle
                             @click="goDetailPage(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="生成报告"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-document-add"
                             circle
                             @click="handleReport(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!-- 调整人员 -->
    <el-dialog :title="staffTitle"
               :visible.sync="openAdjustStaff"
               width="40%"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :close="adjustStaffClose">
      <!-- <div style="text-align: center;"> -->
      <el-form ref="adjustFrom"
               :model="adjustFrom"
               :rules="adjustFromRules"
               style="text-align: center">
        <el-form-item label="调整人员"
                      prop="staffValue">
          <el-select v-model="adjustFrom.staffValue"
                     :multiple="false"
                     :multiple-limit="multipleLimit"
                     placeholder="请选择"
                     filterable
                     style="width: 260px">
            <el-option v-for="item in staffData"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- </div> -->
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="adjustStaffClose">取 消</el-button>
        <el-button type="primary"
                   :loading="adjustLoading"
                   @click="staffConfirm('adjustFrom')">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from "@/api/workplace/pl/plOrderDone";
import { querySystemUsers } from "@/api/workplace/tools";
import { adjustPerson, generateReport } from "@/api/workplace/commonOperation";
import { mapGetters } from "vuex";
import {
  returnBitTimestamp,
  eighthBitTimestamp,
  returnDeadtimestamp,
  getRoleKey,
  downloadReport,
  defineRoleParamType,
  apiParams,
} from "@/utils/index";
// 获取工具API
import {
  getBlock,
  getProjectSource,
  // 角色
  getRole,
  // 退回原因
  getAllReasonOption,
  // 工单状态
  getWorkOrderStatus,
  // 操作状态
  getWorkOperStatus,
  queryRolesByUserNo,
  getReportTypeList,
} from "@/api/workplace/tools";
export default {
  name: "PLDone",
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      isShow: false,
      imgShow: true,
      list: [],
      total: 0,
      listLoading: true,
      adjustLoading: false,
      layout: "total, sizes, prev, pager, next, jumper",
      background: true,
      selectRows: "",
      elementLoadingText: "正在加载...",
      staffInfo: [],
      staffForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      reportForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 查询列表
      queryForm: {
        address: "",
        auditUser: "",
        bookUser: "",
        clockEndTime: "",
        clockStartTime: "",

        userInfo: null, // 人员信息
        roleUser: null, // 选择人员参数

        /** 创建日期 */
        createTimeStart: "",
        createTimeEnd: "",
        innerDeadlineEnd: "",
        innerDeadlineStart: "",
        monitorUser: "",
        operStatus: "",
        operType: "",
        orderTimeEnd: "",
        orderTimeStart: "",
        pageNum: 1,
        pageSize: 10,
        projectId: "",
        reachTime: "",
        surveyUser: "",
        targetSubject: "",
        pchUser: "",
        fallbackReasonId: "",
        abandonCommentId: "",
        workOrderNo: "",
        workOrderStatus: "",
        console: "PL",
        nextUserNo: "",
        nextUserRoleNo: "",
        projectSource: "",
      },
      queryPortForm: {
        body: {
          userNo: "",
          userRoleNo: "",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      adjustFrom: {
        staffValue: "",
      },
      adjustFromRules: {
        staffValue: [
          { required: true, message: "请选择调整人员", trigger: "change" },
        ],
      },
      multipleLimit: 1,
      // 调整人员
      adjustOk: [],
      adjustNo: [],
      staffTitle: "",
      staffData: [],
      openAdjustStaff: false,
      // 处理级联及日期范围控件表单重置
      reset: {
        projectId: [],
        block: [],
        orderScopeDate: [],
        innerDeadline: [],
        createTimeTmp: [],
        visitScopeDate: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 选择到期时间
      reachTimeOptions: [
        {
          value: 0.5,
          label: "0.5天",
        },
        {
          value: 1,
          label: "1天",
        },
        {
          value: 2,
          label: "2天",
        },
        {
          value: 3,
          label: "3天",
        },
        {
          value: 5,
          label: "5天",
        },
        {
          value: 7,
          label: "7天",
        },
        {
          value: 10,
          label: "10天",
        },
        {
          value: 15,
          label: "15天",
        },
        {
          value: 20,
          label: "20天",
        },
        {
          value: 30,
          label: "30天",
        },
      ],
      // 角色人员
      roleOptions: [],
      roleForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      staffPortForm: {
        body: {
          userAfter: "",
          userAfterRole: "",
          workflowInfoReqList: [
            {
              console: "PL",
              nextTaskId: "",
              nextUserNo: "",
              nextUserRoleNo: "",
              note: "",
              operAction: "",
              procInsId: "",
              projectSource: "",
              remark: "",
              step: "",
              taskId: "",
              userNo: "",
              userRoleNo: "",
              workOrderNo: "",
            },
          ],
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      reasonOptions: [],
      // 退回原因form
      allBackForm: {
        body: {
          templateType: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      allBackInfo: [],
      allAbandonInfo: [],
      // 退回原因form
      allAbandonForm: {
        body: {
          templateType: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      projectInfo: [], // 项目
      projectForm: {
        body: {
          source: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 工单状态
      orderStatusInfo: [],
      orderStatusForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 操作状态
      operStatusInfo: [],
      operStatusForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      cityInfo: [],
      cityForm: {
        body: {
          cityCode: "",
          countryCode: "",
          regionCode: "",
          stateCode: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      roleListForm: {
        body: {
          cityCode: "",
          countryCode: "",
          regionCode: "",
          stateCode: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      downloadLoading: false,
      reportType: [], // 报告类型
      // 项目来源
      resourceOptions: [
        {
          name: "香港业务流程",
          key: "process_hk",
        },
        {
          name: "本地业务流程",
          key: "process_nd",
        },
        {
          name: "BR业务流程",
          key: "process_br",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
    }),
  },
  created() {
    this.queryPortForm.body.userNo = this.userNo;
    this.queryPortForm.body.userRoleNo = getRoleKey("PL", this.roles);
  },
  beforeDestroy() {},
  methods: {
    /** 数据初始化 */
    handleInitData() {
      // 列表查询
      this.fetchData();
      // 获取项目
      this.getProject();
      // 获取地区
      this.getAddress();
      // 获取人员选项
      this.getStaffInfo();
      // 获取报告类型
      this.getReportType();
    },
    /** 获取人员信息 */
    async getStaffInfo() {
      this.staffForm.header.currentTime = returnBitTimestamp(new Date());
      const { header, body } = await querySystemUsers(this.staffForm);
      if (header.retCode === "1") {
        this.staffInfo = body;
      }
    },
    /** 选择人员数据框改变值 */
    handleSatffChange(value) {
      if (value) this.queryForm.userInfo = value;
    },
    /** 导出Excel */
    handleDownload() {
      if (this.selectRows.length > 0) {
        this.downloadLoading = true;
        import("@/vendor/ExportExcel").then((excel) => {
          const tHeader = [
            "工单编号",
            "订单编号",
            "目标主体",
            "目标地址",
            "项目",
            "报告类型",
            "二级类型",
            "到期时间(天)",
            "工单状态",
            "下单日期",
            "创建日期",
            "预约走访时间",
            "走访时间",
            "当前操作人",
            "环节/整体滞留(天)",
            "操作状态",
          ];
          const filterVal = [
            "workOrderNo",
            "brWorkNo",
            "targetSubject",
            "address",
            "projectName",
            "reportType",
            "subReportType",
            "maturityDate",
            "workOrderStatusValue",
            "orderTime",
            "createTime",
            "bookVisitTime",
            "visitTime",
            "nowUserName",
            "stepAndStranded",
            "operStatusValue",
          ];
          const list = this.selectRows;
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: "工单列表",
            autoWidth: true,
            bookType: "xlsx",
          });
          this.downloadLoading = false;
        });
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },
    /** 组装导出数据 */
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (!v[j]) {
            return "-";
          }
          if ("orderTime" === j && v["orderTime"]) {
            //格式化下单日期
            return eighthBitTimestamp(Date.parse(v["orderTime"]));
          }
          //格式化创建 日期
          if ("createTime" === j && v["createTime"]) {
            return eighthBitTimestamp(Date.parse(v["createTime"]));
          }
          return v[j];
        })
      );
    },
    // 8位下单日期歌手处理
    dateFormat(row, column) {
      var date = row[column.property];
      if (date === undefined) {
        return "-";
      }
      return eighthBitTimestamp(Date.parse(date));
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    // 打开工单单详情页面
    goDetailPage(row) {
      let routeData = this.$router.resolve({
        path: "/workplace/pl/orderManage/detail",
        name: "Detail",
        query: {
          target: row.targetSubject,
          projectName: row.projectName,
          projectId: row.projectId,
          seqId: row.seqId,
          userNo: this.userNo,
          userRoleNo: getRoleKey("PL", this.roles),
          workOrderNo: row.workOrderNo,
          workOrderStatus: row.workOrderStatus,
          operStatus: row.operStatus,
          procInsId: row.procInsId,
          taskId: row.taskId,
          surveyUser: row.surveyUser,
          projectSign: row.projectSign,
        },
      });
      /*** 截掉site字符 */
      routeData.href = routeData.href.slice(5);
      window.open(routeData.href, "_blank");
    },
    // 生成报告
    async handleReport(row) {
      this.reportForm.header.currentTime = returnBitTimestamp(new Date());
      this.reportForm.body.workOrderNo = row.workOrderNo;
      const { header } = await generateReport(this.reportForm);
      if (header.retCode === "1") {
        downloadReport(`/site/downOssAndTemp?workOrderNo=${row.workOrderNo}`);
      } else {
        this.$baseMessage(`${header.retMessage}`, "error");
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    heightQuery() {
      this.isShow = !this.isShow;
      if (this.isShow) {
        // 获取工单状态
        this.getOrderStatus();
        // 获取操作状态
        this.getOperStatus();
        // 退回原因
        this.getAllBack();
        // 放弃原因
        this.getAllAbandon();
      }
    },
    // 重置
    resetForm(formName) {
      if (this.$refs[formName] !== undefined) {
        // this.$refs[formName].resetFields();
        this.queryForm = this.$options.data().queryForm;
        this.reset = this.$options.data().reset;
      }
    },
    handleQuery() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },
    // 获取地区
    getAddress() {
      this.cityForm.header.currentTime = returnBitTimestamp(new Date());
      getBlock(this.cityForm).then((response) => {
        if (response.header.retCode === "1") {
          let block_info = [];
          block_info.push(response.body[0]);
          this.cityInfo = block_info;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取地区失败，请重新刷新页面`
          );
        }
      });
    },
    // 选择地区
    handleBlockChange(value) {
      if (value.length === 0) {
        this.queryForm.address = "";
      } else {
        this.queryForm.address = value.join(",");
      }
    },
    // 获取项目
    getProject() {
      this.projectForm.header.currentTime = returnBitTimestamp(new Date());
      getProjectSource(this.projectForm).then((response) => {
        if (response.header.retCode === "1") {
          this.projectInfo = response.body;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取项目失败，请重新刷新页面`
          );
        }
      });
    },
    // 选择项目
    handleProjectChange(value) {
      if (value.length === 0) {
        this.queryForm.projectId = "";
      } else {
        this.queryForm.projectId = value[1];
      }
    },
    // 获取工单状态
    getOrderStatus() {
      this.orderStatusForm.header.currentTime = returnBitTimestamp(new Date());
      getWorkOrderStatus(this.orderStatusForm).then((response) => {
        if (response.header.retCode === "1") {
          this.orderStatusInfo = response.body;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取工单状态失败，请重新刷新页面`
          );
        }
      });
    },
    // 获取操作状态
    getOperStatus() {
      this.operStatusForm.header.currentTime = returnBitTimestamp(new Date());
      getWorkOperStatus(this.operStatusForm).then((response) => {
        if (response.header.retCode === "1") {
          this.operStatusInfo = response.body;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取操作状态失败，请重新刷新页面`
          );
        }
      });
    },
    // 获取退回原因 普通退回+待定退回
    getAllBack() {
      this.allBackForm.header.currentTime = returnBitTimestamp(new Date());
      this.allBackForm.body.templateType = "81";
      getAllReasonOption(this.allBackForm).then((response) => {
        if ("1" === response.header.retCode) {
          this.allBackInfo = response.body;
        }
      });
    },
    // 获取放弃原因 上门前放弃&上门后
    getAllAbandon() {
      this.allAbandonForm.header.currentTime = returnBitTimestamp(new Date());
      this.allAbandonForm.body.templateType = "80";
      getAllReasonOption(this.allAbandonForm).then((response) => {
        if ("1" === response.header.retCode) {
          this.allAbandonInfo = response.body;
        }
      });
    },
    // 获取报告分类
    getReportType() {
      getReportTypeList(apiParams()).then((response) => {
        if (response.header.retCode === "1") {
          this.reportType = response.body;
        }
      });
    },
    // 获取done列表页数据
    fetchData() {
      this.listLoading = true;
      // 当前系统时间
      this.queryPortForm.header.currentTime = returnBitTimestamp(new Date());
      // 选择人员数据
      if (this.queryForm.userInfo && this.queryForm.userInfo.length > 0) {
        this.queryForm.roleUser = `${this.queryForm.userInfo[0]}:${this.queryForm.userInfo[1]}`;
      } else {
        this.queryForm.roleUser = "";
      }

      // 下单日期范围
      if (this.reset.orderScopeDate == null) {
        this.queryForm.orderTimeStart = "";
        this.queryForm.orderTimeEnd = "";
      } else if (this.reset.orderScopeDate.length !== 0) {
        this.queryForm.orderTimeStart =
          this.reset.orderScopeDate.length === 2
            ? returnBitTimestamp(this.reset.orderScopeDate[0])
            : "";
        this.queryForm.orderTimeEnd =
          this.reset.orderScopeDate.length === 2
            ? returnDeadtimestamp(this.reset.orderScopeDate[1])
            : "";
      }

      // 走访日期范围
      if (this.reset.visitScopeDate == null) {
        this.queryForm.clockStartTime = "";
        this.queryForm.clockEndTime = "";
      } else if (this.reset.visitScopeDate.length !== 0) {
        this.queryForm.clockStartTime =
          this.reset.visitScopeDate.length === 2
            ? returnBitTimestamp(this.reset.visitScopeDate[0])
            : "";
        this.queryForm.clockEndTime =
          this.reset.visitScopeDate.length === 2
            ? returnDeadtimestamp(this.reset.visitScopeDate[1])
            : "";
      }

      // 创建日期范围
      if (this.reset.createTimeTmp == null) {
        this.queryForm.createTimeStart = "";
        this.queryForm.createTimeEnd = "";
      } else if (this.reset.createTimeTmp.length !== 0) {
        this.queryForm.createTimeStart =
          this.reset.createTimeTmp.length === 2
            ? returnBitTimestamp(this.reset.createTimeTmp[0])
            : "";
        this.queryForm.createTimeEnd =
          this.reset.createTimeTmp.length === 2
            ? returnDeadtimestamp(this.reset.createTimeTmp[1])
            : "";
      }

      // 内部截止日期范围
      if (this.reset.innerDeadline == null) {
        this.queryForm.innerDeadlineStart = "";
        this.queryForm.innerDeadlineEnd = "";
      } else if (this.reset.innerDeadline.length !== 0) {
        this.queryForm.innerDeadlineStart =
          this.reset.innerDeadline.length === 2
            ? returnBitTimestamp(this.reset.innerDeadline[0])
            : "";
        this.queryForm.innerDeadlineEnd =
          this.reset.innerDeadline.length === 2
            ? returnDeadtimestamp(this.reset.innerDeadline[1])
            : "";
      }

      Object.assign(this.queryPortForm.body, this.queryForm);
      // 获取pl工作台订单 已处理list
      getList(this.queryPortForm)
        .then((response) => {
          if (response.header.retCode === "1") {
            this.list = response.body.records;
            this.total = response.body.total;
            this.$emit("update:done-val", this.total);
          }
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 表格显色
    tableRowClassName({ row }) {
      // 到期时间小于3，行内高亮
      if (row.maturityDate <= 3) {
        return "warning-row";
      } else return "";
    },
    // 获取角色配置项 [不包括经理]
    getRoleAdjustOption(role) {
      this.roleForm.header.currentTime = returnBitTimestamp(new Date());
      switch (role) {
        // 不包括经理
        // 预约人员
        case "bookUser":
          this.roleForm.body = [
            {
              roleKey: "book_person",
            },
          ];
          break;
        // 调查人员
        case "part_surveyUser":
          this.roleForm.body = [
            {
              roleKey: "part_investigator",
            },
          ];
          break;
        // 调查人员
        case "full_surveyUser":
          this.roleForm.body = [
            {
              roleKey: "full_investigator",
            },
          ];
          break;
        // 督导人员
        case "supervisor":
          this.roleForm.body = [
            {
              roleKey: "supervisor",
            },
          ];
          break;
        // 内审人员
        case "audit":
          this.roleForm.body = [
            {
              roleKey: "verify_people",
            },
          ];
          break;
        // 用户
        case "user":
          this.roleForm.body = [
            {
              roleKey: "customer",
            },
          ];
          break;
        default:
          break;
      }
      getRole(this.roleForm).then((response) => {
        if (response.header.retCode === "1") {
          this.staffData = response.body;
          this.adjustFrom.userAfterRole = this.roleForm.body
            .map((item) => {
              return item.roleKey;
            })
            .join(",");
        }
      });
    },
    // 判断调整人员
    async judgeAdjust(person) {
      this.adjustOk = [];
      this.adjustNo = [];
      let surverUsers = [];
      let userRole = [];
      switch (person) {
        // 调整预约
        case "bookUser":
          this.selectRows.forEach((item) => {
            if (item.workOrderStatus === 2 || item.workOrderStatus === 11) {
              this.adjustOk.push(item.workOrderNo);
            } else {
              this.adjustNo.push(item.workOrderNo);
            }
          });
          break;
        // 调整全职调查员
        case "full_surveyUser":
          userRole = "full";
          this.selectRows.forEach((item) => {
            if (item.workOrderStatus === 4 || item.workOrderStatus === 5) {
              this.adjustOk.push(item.workOrderNo);
              surverUsers.push(item.surveyUser);
            } else {
              this.adjustNo.push(item.workOrderNo);
            }
          });
          break;
        // 调整兼职调查员
        case "part_surveyUser":
          userRole = "part";
          this.selectRows.forEach((item) => {
            if (item.workOrderStatus === 4 || item.workOrderStatus === 5) {
              this.adjustOk.push(item.workOrderNo);
              surverUsers.push(item.surveyUser);
            } else {
              this.adjustNo.push(item.workOrderNo);
            }
          });
          break;
        // 调整督导
        case "supervisor":
          this.selectRows.forEach((item) => {
            if (item.workOrderStatus === 3 || item.workOrderStatus === 6) {
              this.adjustOk.push(item.workOrderNo);
            } else {
              this.adjustNo.push(item.workOrderNo);
            }
          });
          break;
        // 调整内审
        case "audit":
          this.selectRows.forEach((item) => {
            if (item.workOrderStatus === 7) {
              this.adjustOk.push(item.workOrderNo);
            } else {
              this.adjustNo.push(item.workOrderNo);
            }
          });
          break;
        default:
          break;
      }
      let msg = "1";
      if (this.adjustNo.length === 0) {
        if (surverUsers.length != 0) {
          for (let i = 0; i < surverUsers.length; i++) {
            let userForm = {};
            userForm.body = surverUsers[i];
            //查询原调查人角色列表
            let { header, body } = await queryRolesByUserNo(userForm);
            if (header.retCode === "1") {
              //角色列表中是否于本次调正一致
              let tempArr = body.filter((e) => e.roleKey.startsWith(userRole));
              if (tempArr.length == 0) {
                msg =
                  userRole === "part"
                    ? "全职调查不能调整给兼职人员调查"
                    : "兼职调查不能调整给全职人员调查";
                break;
              }
            }
          }
        }
        if (msg != "1") {
          this.$message({
            dangerouslyUseHTMLString: true,
            showClose: true,
            duration: 10000,
            message: `<br>${msg}:</br>
                        <br>无法调整的工单: ${this.adjustOk.join(",")};</br>
                      `,
            type: "warning",
          });
          return;
        }
        this.openAdjustStaff = true;
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          showClose: true,
          duration: 10000,
          message: `<br>以下工单无法调整人员:</br>
                      <br>无法调整的工单: ${this.adjustNo.join(",")};</br>
                    `,
          type: "warning",
        });
      }
    },
    // 调整人员
    //调整人员对话框
    adjustStaff(command) {
      if (this.selectRows.length > 0) {
        /** 经Ivan与产品经理bunny确认 调整人员不可超过50个工单 */
        if (this.selectRows.length > 50) {
          this.$baseMessage("建议调整人员不要超过50条工单", "error");
          return false;
        }
        this.adjustLoading = false;
        if ("bookUser" === command) {
          this.staffTitle = "调整预约";
          this.getRoleAdjustOption("bookUser");
          this.multipleLimit = 1;
          this.judgeAdjust("bookUser");
        } else if ("full_surveyUser" === command) {
          this.staffTitle = "调整全职调查员";
          this.getRoleAdjustOption("full_surveyUser");
          this.multipleLimit = 0;
          this.judgeAdjust("full_surveyUser");
        } else if ("part_surveyUser" === command) {
          this.staffTitle = "调整兼职调查员";
          this.getRoleAdjustOption("part_surveyUser");
          this.multipleLimit = 0;
          this.judgeAdjust("part_surveyUser");
        } else if ("supervisor" === command) {
          this.staffTitle = "调整督导";
          this.getRoleAdjustOption("supervisor");
          this.multipleLimit = 1;
          this.judgeAdjust("supervisor");
        } else if ("audit" === command) {
          this.staffTitle = "调整内审";
          this.getRoleAdjustOption("audit");
          this.multipleLimit = 1;
          this.judgeAdjust("audit");
        }
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },
    staffConfirm(formData) {
      this.$refs[formData].validate(async (valid) => {
        if (valid) {
          /** 记得处理userNo与 userRoleNo */
          this.staffPortForm.body.userAfterRole = this.adjustFrom.userAfterRole;
          this.staffPortForm.body.userAfter = this.adjustFrom.staffValue;
          this.staffPortForm.body.workflowInfoReqList =
            this.$refs.tableSort.selection;
          this.staffPortForm.body.workflowInfoReqList.map((item) => {
            item.userNo = this.userNo;
            item.userRoleNo = this.adjustFrom.userAfterRole;
            return item;
          });
          this.adjustLoading = true;
          adjustPerson(this.staffPortForm).then((res) => {
            if (res.header.retCode === "1") {
              this.$message({
                message: `${res.header.retMessage}`,
                type: "success",
              });
              this.fetchData();
              this.adjustStaffClose();
            } else {
              this.adjustLoading = false;
              this.$message.error(`${res.header.retMessage}`);
            }
          });
        } else {
          this.adjustLoading = false;
          return false;
        }
      });
    },
    // 调整人员窗口关闭
    adjustStaffClose() {
      // this.staffValue = [];
      /** 避免重置后出现选择器不期望的数据格式 */
      this.openAdjustStaff = false;
      this.$refs["adjustFrom"].resetFields();
      this.adjustLoading = false;
    },
  },
};
</script>
<style scoped>
.el-col {
  margin-bottom: 10px;
}
.el-col :last-child {
  margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}

.el-select,
.el-cascader {
  width: 100%;
}
.el-table /deep/.warning-row {
  background: #f8b7bc;
}
.my-form-class {
  margin-bottom: -10px !important;
}
</style>
