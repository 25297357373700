// in -> 工单列表
import request from "@/utils/request";

// 质控工作台查询工单列表
export function getOrderList(data) {
  return request({
    url: "/workOrderManager/queryManagerSearchPage",
    method: "post",
    data,
  });
}
