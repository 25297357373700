// Kyle API 调查工作台 -> 工单补充 -> 待处理
import request from "@/utils/request";

// 查询督导工作台工单补充待处理列表
export function getTodo(data) {
  return request({
    url: "/supply/queryPendingPage",
    method: "post",
    data,
  });
}

// 查询退回原因
export function getReturnReason(data) {
  return request({
    url: "/queryCommentTemplateContent",
    method: "post",
    data,
  });
}

// 使用角色查询用户列表
export function getUsersListByRole(data) {
  return request({
    url: "/queryUsersListByRole",
    method: "post",
    data,
  });
}

// 查询督导工作台工单补充已处理列表
export function getDone(data) {
  return request({
    url: "/supply/queryProcessedPage",
    method: "post",
    data,
  });
}

export function submit(data) {
  return request({
    url: "/supply/submit",
    method: "post",
    data,
  });
}

//编辑会议信息
export function edit(data) {
  return request({
    url: "/supply/saveOrUpdate",
    method: "post",
    data,
  });
}

//更新影像可见标记
export function disable(data) {
  return request({
    url: "/supply/disableDisplayRes",
    method: "post",
    data,
  });
}

//删除影像信息
export function deleteRes(data) {
  return request({
    url: "/supply/deleteRes",
    method: "post",
    data,
  });
}

// 督导-人员推荐 工单退回
export function withdraw(data) {
  return request({
    url: "/supply/fallback",
    method: "post",
    data,
  });
}
// 督导-人员推荐获取详情
export function getOrderDetail(data) {
  return request({
    url: "/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}
// 督导-工单补充-获取操作信息
export function getOperationInfo(data) {
  return request({
    url: "/log/query",
    method: "post",
    data,
  });
}
// 督导-工单补充-查询会议信息
export function getMeeting(data) {
  return request({
    url: "/supply/queryMeetting",
    method: "post",
    data,
  });
}
