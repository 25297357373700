var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        type: "info",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("queryForm")
                        },
                      },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-sort",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.heightQuery },
                    },
                    [_vm._v(" 高级筛选 ")]
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticClass: "demo-form-inline my-form-class",
                      staticStyle: { "margin-top": "15px" },
                      attrs: { model: _vm.queryForm },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "orderDate" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      clearable: "",
                                      "range-separator": "至",
                                      "start-placeholder": "下单开始日期",
                                      "end-placeholder": "下单结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.orderDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "orderDate",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.orderDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "workOrderNo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "工单编号",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleQuery.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.queryForm.workOrderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "workOrderNo",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "queryForm.workOrderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "targetSubject" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "目标主体",
                                    },
                                    model: {
                                      value: _vm.queryForm.targetSubject,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "targetSubject",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryForm.targetSubject",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "projectId" } },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      value: _vm.queryForm.projectId,
                                      options: _vm.projectInfo,
                                      clearable: "",
                                      filterable: true,
                                      separator: ":",
                                      placeholder: "项目",
                                    },
                                    on: { change: _vm.handleProjectChange },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _c(
                            "el-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isShow,
                                  expression: "isShow",
                                },
                              ],
                              attrs: { transiton: "fade", gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "expectVisitDate" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "unlink-panels": "",
                                          "range-separator": "至",
                                          "start-placeholder":
                                            "预约走访开始日期",
                                          "end-placeholder": "预约走访结束日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.queryForm.expectVisitDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "expectVisitDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.expectVisitDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: " address" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "selectedOptions" } },
                                        [
                                          _c("el-cascader", {
                                            attrs: {
                                              options: _vm.cityInfo,
                                              filterable: true,
                                              clearable: "",
                                              separator: "/",
                                              placeholder: "选择地区",
                                              props: { checkStrictly: true },
                                            },
                                            on: {
                                              change: _vm.handleBlockChange,
                                            },
                                            model: {
                                              value: _vm.queryForm.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryForm,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression: "queryForm.address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "workOrderStatus" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "工单状态",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm.workOrderStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "workOrderStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.workOrderStatus",
                                          },
                                        },
                                        _vm._l(
                                          _vm.orderStatusInfo,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "reachTime" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "到期时间",
                                            clearable: true,
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.queryForm.reachTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "reachTime",
                                                $$v
                                              )
                                            },
                                            expression: "queryForm.reachTime",
                                          },
                                        },
                                        _vm._l(
                                          _vm.reachTimeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "innerDeadDate" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "unlink-panels": "",
                                          "range-separator": "至",
                                          "start-placeholder":
                                            "内部截止开始日期",
                                          "end-placeholder": "内部截止结束日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.queryForm.innerDeadDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "innerDeadDate",
                                              $$v
                                            )
                                          },
                                          expression: "queryForm.innerDeadDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "preliminaryDate" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "unlink-panels": "",
                                          "range-separator": "至",
                                          "start-placeholder": "初审开始日期",
                                          "end-placeholder": "初审结束日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.queryForm.preliminaryDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "preliminaryDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.preliminaryDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "orderFinishDate" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "unlink-panels": "",
                                          "range-separator": "至",
                                          "start-placeholder":
                                            "工单结束起始日期",
                                          "end-placeholder": "工单结束终止日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.queryForm.orderFinishDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "orderFinishDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryForm.orderFinishDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "userInfo" } },
                                    [
                                      _c("el-cascader", {
                                        attrs: {
                                          value: _vm.queryForm.userInfo,
                                          options: _vm.staffInfo,
                                          clearable: "",
                                          filterable: true,
                                          separator: " ",
                                          placeholder: "选择人员",
                                          "collapse-tags": "",
                                        },
                                        on: { change: _vm.handleSatffChange },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "brWorkNo" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "订单编号",
                                          clearable: "",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.handleQuery.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.queryForm.brWorkNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "brWorkNo",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "queryForm.brWorkNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "reportType" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "报告类型",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.queryForm.reportType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "reportType",
                                                $$v
                                              )
                                            },
                                            expression: "queryForm.reportType",
                                          },
                                        },
                                        _vm._l(_vm.reportType, function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "projectSource" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择项目来源",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.queryForm.projectSource,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "projectSource",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.projectSource",
                                          },
                                        },
                                        _vm._l(
                                          _vm.resourceOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.key,
                                              attrs: {
                                                label: item.name,
                                                value: item.key,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click" },
                      on: { command: _vm.adjustStaff },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { plain: "", icon: "el-icon-s-custom" } },
                        [
                          _vm._v(" 调整人员 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "bookUser" } },
                            [_vm._v("调整预约")]
                          ),
                          _c("el-dropdown-item", { attrs: { command: "pl" } }, [
                            _vm._v("调整PL"),
                          ]),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "part_surveyUser" } },
                            [_vm._v(" 调整兼职调查员 ")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "full_surveyUser" } },
                            [_vm._v(" 调整全职调查员 ")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "supervisor" } },
                            [_vm._v("调整督导")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "audit" } },
                            [_vm._v("调整内审")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { loading: _vm.downloadLoading, type: "primary" },
                      on: { click: _vm.handleDownload },
                    },
                    [_vm._v(" 导出 ")]
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tableSort",
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        data: _vm.list,
                        "element-loading-text": _vm.elementLoadingText,
                        border: "",
                        "default-sort": {
                          prop: "workOrderNo",
                          order: "descending",
                        },
                      },
                      on: { "selection-change": _vm.setSelectRows },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "40" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "工单编号",
                          width: "130",
                          prop: "workOrderNo",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubject",
                          label: "目标主体",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "目标地址",
                          "min-width": "250",
                          prop: "address",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.address || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "项目",
                          prop: "projectName",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bookVisitTime",
                          label: "预约走访时间",
                          width: "130",
                          align: "center",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.bookVisitTime || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "visitTime",
                          label: "走访时间",
                          align: "center",
                          width: "110",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.visitTime || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "innerDeadline",
                          label: "内部截止日期",
                          align: "center",
                          width: "130",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.innerDeadline || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "firstAuditDateEnd",
                          label: "初审结束日期",
                          align: "center",
                          width: "130",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.firstAuditDateEnd || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderFinishDate",
                          label: "工单结束日期",
                          align: "center",
                          width: "130",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.orderFinishDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "maturityDate",
                          label: "到期时间",
                          align: "center",
                          width: "130",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.maturityDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "nowUserName",
                          label: "当前操作人",
                          width: "120",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.nowUserName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "workOrderStatusValue",
                          label: "工单状态",
                          width: "110",
                          align: "center",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.workOrderStatusValue || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stepAndStranded",
                          align: "center",
                          label: "环节/整体滞留(天)",
                          fit: "true",
                          width: "90",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.stepAndStranded || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "120px",
                          fixed: "right",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "详情",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-document",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goDetailPage(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "生成报告",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-document-add",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleReport(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c("el-pagination", {
                    attrs: {
                      background: _vm.background,
                      "current-page": _vm.queryForm.pageNum,
                      layout: _vm.layout,
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.staffTitle,
            visible: _vm.openAdjustStaff,
            width: "40%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            close: _vm.adjustStaffClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openAdjustStaff = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "adjustFrom",
              staticStyle: { "text-align": "center" },
              attrs: { model: _vm.adjustFrom, rules: _vm.adjustFromRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "调整人员", prop: "staffValue" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "260px" },
                      attrs: {
                        multiple: false,
                        "multiple-limit": _vm.multipleLimit,
                        placeholder: "请选择",
                        filterable: "",
                      },
                      model: {
                        value: _vm.adjustFrom.staffValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.adjustFrom, "staffValue", $$v)
                        },
                        expression: "adjustFrom.staffValue",
                      },
                    },
                    _vm._l(_vm.staffData, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.adjustStaffClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.adjustLoading },
                  on: {
                    click: function ($event) {
                      return _vm.staffConfirm("adjustFrom")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }