<template>
  <el-tabs type="border-card" value="todo" @tab-click="handleTriggerTab">
    <el-tab-pane name="todo">
      <span slot="label">
        <el-badge :value="todoVal > 99 ? '99⁺' : todoVal" class="item todo">
          待处理
        </el-badge>
      </span>

      <todo ref="todoChild" @child-todo="todoEvent"></todo>
    </el-tab-pane>
    <el-tab-pane name="done">
      <span slot="label">
        <el-badge :value="doneVal > 99 ? '99⁺' : doneVal" class="item done">
          已处理
        </el-badge>
      </span>
      <done ref="doneChild" :done-val.sync="doneVal"></done>
    </el-tab-pane>
    <el-tab-pane name="query">
      <span slot="label">
        <el-badge :value="queryVal > 99 ? '99⁺' : queryVal" class="item query">
          质 疑
        </el-badge>
      </span>
      <query ref="queryChild" @child-query="queryEvent"></query>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import todo from "./components/todo";
  import done from "./components/done";
  import query from "./components/query";
  export default {
    name: "IndexVue",
    components: { todo, done, query },
    data() {
      return {
        todoVal: null,
        doneVal: "",
        queryVal: "",
      };
    },
    mounted() {
      this.handleTriggerTab({ name: "todo" });
    },
    methods: {
      todoEvent(refresh_data) {
        this.todoVal = refresh_data.todoVal;
        if (refresh_data.isDone) {
          this.$nextTick(() => {
            this.$refs.doneChild.fetchData();
          });
        }
        /** 质疑页面需要刷新待处理和已处理的数据 */
        if (refresh_data.isTodo) {
          this.$nextTick(() => {
            this.$refs.todoChild.fetchData();
          });
        }
        if (refresh_data.isQuery) {
          this.$nextTick(() => {
            this.$refs.queryChild.fetchData();
          });
        }
      },
      queryEvent(refresh_data) {
        this.queryVal = refresh_data.queryVal;
        if (refresh_data.isDone) {
          this.$nextTick(() => {
            this.$refs.doneChild.fetchData();
          });
        }
        /** 质疑页面需要刷新待处理和已处理的数据 */
        if (refresh_data.isTodo) {
          this.$nextTick(() => {
            this.$refs.todoChild.fetchData();
          });
        }
        if (refresh_data.isQuery) {
          this.$nextTick(() => {
            this.$refs.queryChild.fetchData();
          });
        }
      },
      /** tabs切换 */
      handleTriggerTab(tab) {
        if (tab.name === "todo") {
          this.$refs.todoChild.handleInitData();
        } else if (tab.name === "done") {
          this.$refs.doneChild.handleInitData();
        } else {
          this.$refs.queryChild.handleInitData();
        }
      },
    },
  };
</script>

<style>
  .todo sup {
    background: none;
    position: absolute !important;
    top: 10px !important;
    color: red;
    height: 0;
    line-height: 0;
    border: none;
    padding-left: 10px;
  }
  .done sup {
    background: none;
    position: absolute !important;
    top: 10px !important;
    color: #0d980d;
    height: 0;
    line-height: 0;
    border: none;
    padding-left: 10px;
  }
  .query sup {
    background: none;
    position: absolute !important;
    top: 10px !important;
    color: #d97c09;
    height: 0;
    line-height: 0;
    border: none;
    padding-left: 10px;
  }
</style>
