<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    append-to-body
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否退回？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="退回原因" prop="fallbackCommentId">
        <el-select
          v-model="form.fallbackCommentId"
          placeholder="请选择退回原因"
          clearable
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model.trim="form.note"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    getReturnReason,
    withdraw,
  } from "@/api/workplace/investigate/investigate";
  import { returnBitTimestamp, getRoleKey } from "@/utils";
  import { mapGetters } from "vuex";

  export default {
    name: "TableEdit",
    data() {
      return {
        loading: false,
        openLevel: 0,
        form: {
          fallbackCommentId: "",
          note: "",
        },
        rules: {
          fallbackCommentType: [
            { required: true, trigger: "blur", message: "请选择退回原因" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
        // 查询退回原因参数
        param: {
          body: {},
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        options: [],
        // 退回提交时需要的参数
        parameter: {
          body: [],
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        obj: {
          console: "investigator",
          fallbackCommentId: null,
          note: null,
          operAction: "工单退回",
          step: "调查工作台-工单录入-待处理",
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
          procInsId: null,
          taskId: null,
          auditFlag: null,
          // 异步生成报告5个触发点参数
          triggerPoint: "",
        },
        object: null,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.obj.userNo = this.userNo;
      this.obj.userRoleNo = getRoleKey("investigate", this.roles);
    },
    methods: {
      async showWithdraw(row) {
        this.title = "退回工单";
        this.form = Object.assign({}, row[0]);

        // 查询退回原因
        const { body } = await getReturnReason(this.param);
        this.options = body;

        this.object = row;

        this.dialogFormVisible = true;

        this.parameter.body = [];
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("refreshData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            for (let i = 0; i < this.object.length; i++) {
              this.obj.fallbackCommentId = this.form.fallbackCommentId;
              this.obj.note = this.form.note;
              this.obj.procInsId = this.object[i].procInsId;
              this.obj.taskId = this.object[i].taskId;
              this.obj.workOrderNo = this.object[i].workOrderNo;
              this.obj.auditFlag = this.object[i].auditFlag;
              // 异步生成报告5个触发点，参数名称triggerPoint
              if (
                null !== this.object[i].visitTime &&
                "" != this.object[i].visitTime
              ) {
                this.obj.triggerPoint = "survey_fallback";
              } else {
                this.obj.triggerPoint = "";
              }
              this.parameter.body.push(Object.assign({}, this.obj));
            }
            const { header } = await withdraw(this.parameter);
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            if (this.openLevel === 0) {
            } else {
              //编辑页打开
              this.$emit("close");
            }
            this.$emit("refreshData");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
