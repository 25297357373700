<!-- 打卡记录预览Dialog-->
<template>
  <!-- 图片预览 -->
  <el-dialog :visible.sync="dialogImgVisible" append-to-body class="imgDialog">
    <el-tabs
      v-model="activeTabsValue"
      :tab-position="tabPosition"
      style="height: 350px"
    >
      <el-tab-pane name="走访开始" stretch="true" label="走访开始">
        <el-row>
          <el-col>
            <el-carousel
              ref="imgCarousel1"
              trigger="click"
              height="300px"
              :autoplay="false"
              indicator-position="none"
            >
              <el-carousel-item
                v-for="(subItem, subIndex) in photoData.filter((item) => {
                  return item.visitFlag === 0;
                })"
                :key="subIndex"
                name="subIndex"
              >
                <el-row v-if="subItem.visitFlag === 0">
                  <el-col style="text-align: center">
                    <viewer>
                      <img
                        v-if="subItem.visitFlag === 0"
                        style="height: 300px"
                        :src="ossPath + subItem.path"
                      />
                    </viewer>
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="走访结束" stretch="true" label="走访结束">
        <el-row>
          <el-col>
            <el-carousel
              ref="imgCarousel2"
              trigger="click"
              height="300px"
              :autoplay="false"
              indicator-position="none"
            >
              <el-carousel-item
                v-for="(subItem, subIndex) in photoData.filter((item) => {
                  return item.visitFlag === 1;
                })"
                :key="subIndex"
                name="subIndex"
              >
                <el-row>
                  <el-col style="text-align: center">
                    <viewer>
                      <img
                        v-if="subItem.visitFlag === 1"
                        style="max-height: 300px"
                        :src="ossPath + subItem.path"
                        :preview-src-list="urlList"
                      />
                    </viewer>
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
  export default {
    name: "ClockInView",
    data() {
      return {
        photoData: [],
        tabPosition: "left",
        dialogImgVisible: false,
        fit: "fill",
        urlList: [],
        activeTabsValue: "2",
        img_index: "",
        tab_name: "",
        ossPath: "",
      };
    },
    created() {},
    methods: {
      showView() {
        this.dialogImgVisible = true;
      },
      bindData(photoData, tab_name, photo_index, img_index, ossPath) {
        this.activeTabsValue = tab_name;
        this.photoData = photoData;
        this.urlList = photoData;
        this.ossPath = ossPath;
        this.$nextTick(() => {
          if (photo_index === 0) {
            this.$refs["imgCarousel1"].setActiveItem(img_index);
          } else {
            this.$refs["imgCarousel2"].setActiveItem(img_index);
          }
        });
      },
    },
  };
</script>

<style scoped>
  .imgDialog /deep/.el-dialog__header {
    padding: 0 20px;
  }
  .imgDialog /deep/.el-dialog__body {
    padding-top: 40px;
  }
</style>
<style>
  .viewer-container {
    z-index: 9999 !important;
  }
</style>
