<!-- 音频预览Dialog-->
<template>
  <!-- 音频预览 -->
  <el-dialog
    :visible.sync="dialogaudioVisible"
    append-to-body
    class="audioDialog"
    @close="closeView"
  >
    <el-tabs
      v-model="activeTabsValue"
      :tab-position="tabPosition"
      style="height: 350px"
      @tab-click="handleTabsClick"
    >
      <el-tab-pane
        v-for="(audioItem, audioIndex) in audioData"
        :key="audioIndex"
        :name="audioItem.name"
        :stretch="true"
        :label="
          audioItem.name.length > 12
            ? audioItem.name.substring(0, 12) + '...'
            : audioItem.name
        "
      >
        <el-row>
          <el-col>
            <el-row>
              <el-col style="text-align: center">
                <el-tooltip
                  :content="audioItem.name"
                  placement="top"
                  effect="light"
                >
                  <p style="width: 95%; margin: 0 auto; text-align: center">
                    {{
                      audioItem.name +
                      "." +
                      audioItem.path.substring(
                        audioItem.path.lastIndexOf(".") + 1
                      )
                    }}
                  </p>
                </el-tooltip>
                <audio
                  v-if="judgeFileType(audioItem.path)"
                  ref="myAudio"
                  :src="ossPath + audioItem.path"
                  controls="controls"
                  style="width: 95%; height: 170px"
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <!-- audio ——>不可预览 -->
                <img
                  v-else
                  style="width: 160px; height: 160px; cursor: pointer"
                  src="@/icon/yinpin.svg"
                  :fit="fit"
                  :title="audioItem.name"
                  @click="handleDownload(audioItem)"
                />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
  import { downloadFile, isExistOSS } from "@/utils/index";
  export default {
    name: "ScreenAudioView",
    data() {
      return {
        audioData: "",
        tabPosition: "left",
        dialogaudioVisible: false,
        fit: "fill",
        activeTabsValue: "2",
        audio_index: "",
        urlList: [],
        tab_name: "",
        ossPath: "",
        OSSClient: {},
      };
    },
    created() {},
    methods: {
      showView() {
        this.dialogaudioVisible = true;
      },
      handleTabsClick() {
        this.$refs.myAudio.forEach((item) => {
          item.pause(); //暂停
        });
      },
      closeView() {
        this.$refs.myAudio.forEach((item) => {
          item.pause(); //暂停
        });
      },
      /** 判断文件类型 */
      judgeFileType(filePath) {
        /** 可预览的文件类型 */
        // TODO: 经与产品bunny沟通，暂处理Google业务，不启用火狐兼容方案
        // 优化
        let fileType = ["mp3", "ogg", "wav", "m4a"];
        fileType.map((item) => {
          fileType.push(item.toUpperCase());
        });
        const itemType = filePath.substring(filePath.lastIndexOf(".") + 1);
        return fileType.includes(itemType);
      },
      /** 点击下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
      bindData(audioData, audioIndex, audioName, ossPath, OSSClient) {
        this.ossPath = ossPath;
        this.OSSClient = OSSClient;
        this.activeTabsValue = audioName;
        this.audioData = audioData;
        // this.$nextTick(() => {
        //   this.$refs["audioCarousel"].setActiveItem(audioIndex);
        // });
      },
    },
  };
</script>

<style scoped>
  .audioDialog {
    z-index: 10;
  }
  .audioDialog /deep/.el-dialog__header {
    padding: 0 20px;
  }
  .audioDialog /deep/.el-dialog__body {
    padding-top: 40px;
  }
</style>
