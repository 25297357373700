// Bing API  督导 ->成本录入
import request from "@/utils/request";
export function add(data) {
  return request({
    url: "/cost/add",
    method: "post",
    data,
  });
}
export function queryCostListPage(data) {
  return request({
    url: "/supply/queryCostListPage",
    method: "post",
    data,
  });
}
export function queryCostInfo(data) {
  return request({
    url: "/cost/get",
    method: "get",
    params: data,
  });
}
export function update(data) {
  return request({
    url: "/cost/update",
    method: "post",
    data,
  });
}
