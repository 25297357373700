// Kyle API 工单管理 -> 工单详情
import request from "@/utils/request";

export function getList(data) {
  return request({
    url: "/plWorkOrder/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}

export function getProcess(data) {
  return request({
    url: "/workOrderDetails/querySchedule",
    method: "post",
    data,
  });
}
