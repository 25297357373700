<template>
  <article class="mod-login">
    <el-alert
      v-if="nodeEnv !== 'development'"
      title="欢迎登录实地平台！"
      type="success"
      :closable="false"
      style="position: fixed"
    ></el-alert>
    <div class="login-body">
      <div class="login-text"><img src="../../assets/login_images/trace_tagline.png" alt=""></div>
      <div class="login-form">
        <div class="login-box">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginFormRules"
            class="inner-login"
            label-position="left"
          >
            <!-- <el-row>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <div class="title">您好！</div>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" align="right">
                <div>
                  <img :src="logoUrl" style="width: 96px; height: 56px" />
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="title-tips">欢迎来到{{ title }}！</div>
              </el-col>
            </el-row> -->
            <div class="logo">
              <img src="../../assets/login_images/logo-TRACE.png" alt="">
            </div>
            <div class="title">登录</div>
            <div class="item">
              <el-form-item style="margin-top: 20px" prop="username">
                <span class="svg-container svg-container-admin">
                  <vab-icon :icon="['fas', 'user']" />
                </span>
                <el-input
                  v-model.trim="loginForm.username"
                  v-focus
                  placeholder="请输入用户名"
                  tabindex="1"
                  type="text"
                >
                  <!-- <template #prefix>
                    <el-icon class="el-input__icon user">
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="user" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
                      </svg>
                    </el-icon>
                  </template> -->
                </el-input>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item prop="password">
                <span class="svg-container">
                  <vab-icon :icon="['fas', 'lock']" />
                </span>
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model.trim="loginForm.password"
                  :type="passwordType"
                  tabindex="2"
                  placeholder="请输入密码"
                  @keyup.enter.native="handleLogin"
                />
                <span
                  v-if="passwordType === 'password'"
                  class="show-password"
                  @click="handlePassword"
                >
                  <vab-icon :icon="['fas', 'eye-slash']"></vab-icon>
                </span>
                <span v-else class="show-password" @click="handlePassword">
                  <vab-icon :icon="['fas', 'eye']"></vab-icon>
                </span>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item>
                <span class="svg-container">
                  <vab-icon :icon="['fas', 'shield-alt']"></vab-icon>
                </span>
                <el-button
                  class="login-btn-ver"
                  style="
                    width: 100%;
                    height: 35px;
                    text-align: left;
                    padding-left: 40px;
                  "
                  :type="isSuccess"
                  @click="showVal"
                >
                  {{ showText }}
                </el-button>
              </el-form-item>
            </div>
            <div class="item login">
              <el-button
                :loading="loading"
                class="login-btn"
                type="primary"
                @click="handleLogin"
              >
                登录
              </el-button>
            </div>
            <!-- <router-link to="/register">
              <div style="margin-top: 20px;">注册</div>
            </router-link> -->
          </el-form>
        </div>
      </div>
    </div>
    <div class="login-footer">
      <div class="foot-nav">
        <!-- href="https://www.cbil.com.hk/" target="_blank" -->
        <img src="../../assets/login_images/logo1.svg" alt="">
        <!-- <a href="https://www.cbil.com.hk/privacy-policy" target="_blank">Privacy Policy</a>
        <a href="" target="_blank">Terms of Use</a>
        <a href="https://www.cbil.com.hk/disclaimer" target="_blank">Disclaimer</a> -->
      </div>
      <div class="foot-version">
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
             target="_blank">京ICP备2020046295号</a>
        <span>© 2023 Central Business Information Limited. All rights reserved.</span>
      </div>

    </div>
    <el-dialog
      title="图片转正完成校验"
      class="myDialog"
      :visible.sync="showValidate"
      width="350px"
      @close="close"
    >
      <el-button
        icon="el-icon-refresh"
        circle
        type="warning"
        style="position: absolute; right: 10px"
        @click="refresh"
      ></el-button>
      <drag-verify-img-rotate
        ref="dragVerify"
        :imgsrc="validateImage"
        :is-passing.sync="isPassing"
        :show-tips="true"
        text="请按住滑块拖动"
        success-text="验证通过"
        fail-tip="验证失败，图片旋转正即可通过校验"
        diff-degree="90"
        handler-icon="el-icon-d-arrow-right"
        success-icon="el-icon-circle-check"
        width="300"
        @passcallback="passCallBack"
      ></drag-verify-img-rotate>
    </el-dialog>
  </article>
</template>

<script>
  import { isPassword } from "@/utils/validate";
  import dragVerifyImgRotate from "vue-drag-verify-img-rotate";
  import { random } from "@/utils";

  export default {
    name: "Login",
    components: {
      dragVerifyImgRotate,
    },
    directives: {
      focus: {
        inserted(el) {
          el.querySelector("input").focus();
        },
      },
    },
    data() {
      const validateusername = (rule, value, callback) => {
        if ("" === value) {
          callback(new Error("用户名不能为空"));
        } else {
          callback();
        }
      };
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value)) {
          callback(new Error("密码不能少于6位"));
        } else {
          callback();
        }
      };
      return {
        logoUrl: require("../../assets/logo.svg"),
        nodeEnv: process.env.NODE_ENV,
        title: this.$baseTitle,
        loginForm: {
          username: "",
          password: "",
        },
        loginFormRules: {
          username: [
            {
              required: true,
              trigger: "blur",
              validator: validateusername,
            },
          ],
          password: [
            {
              required: true,
              trigger: "blur",
              validator: validatePassword,
            },
          ],
        },
        loading: false,
        passwordType: "password",
        redirect: undefined,
        showValidate: false,
        isPassing: false,
        isSuccess: "default",
        isValidate: false,
        showText: "请点击按钮进行验证",
        validateImage: "/static/img/val1.jpg",
      };
    },
    watch: {
      $route: {
        handler(route) {
          this.redirect = (route.query && route.query.redirect) || "/";
        },
        immediate: true,
      },
    },
    created() {
      // this.getCookie();
    },
    mounted() {
      if ("production" !== process.env.NODE_ENV) {
        this.loginForm.username = "";
        this.loginForm.password = "";
      }
    },
    methods: {
      /** 从cookie中获取用户信息 */
      // getCookie() {
      //   const username = Cookies.get("username");
      //   const password = Cookies.get("password");
      //   const rememberMe = Cookies.get("rememberMe");
      // }

      handlePassword() {
        this.passwordType === "password"
          ? (this.passwordType = "")
          : (this.passwordType = "password");
        this.$nextTick(() => {
          this.$refs.password.focus();
        });
      },
      handleLogin() {
        if (this.isValidate) {
          this.$refs.loginForm.validate(async (valid) => {
            if (valid) {
              sessionStorage.setItem("password", this.loginForm.password);
              this.loading = true;
              await this.$store
                .dispatch("user/login", this.loginForm)
                .then(() => {
                  const routerPath =
                    this.redirect === "/404" || this.redirect === "/401"
                      ? "/"
                      : this.redirect;
                  this.$router.push(routerPath).catch(() => {});
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              return false;
            }
          });
        } else {
          this.isSuccess = "warning";
        }

        // setTimeout(() => {
        //   window.open("https://github.com/CBI/cbi-site-front");
        // }, 30000);
      },
      getRandomOneToFive() {
        return random(1, 10);
      },
      showVal() {
        this.isValidate = false;
        if (this.showText !== "验证通过") {
          this.showValidate = !this.showValidate;
          let r = this.getRandomOneToFive();
          this.validateImage = "/static/img/val" + r + ".jpg";
          this.refresh();
        }
      },
      passCallBack() {
        setTimeout(() => {
          this.showValidate = false;
        }, 1000);
        this.isValidate = true;
        this.isSuccess = "success";
        this.showText = "验证通过，请点击登录";
      },
      refresh() {
        this.isPassing = false;
        this.isSuccess = "default";
        this.showText = "请点击按钮进行验证";
        let r = this.getRandomOneToFive();
        this.validateImage = "/static/img/val" + r + ".jpg";
      },
    },
  };
</script>

<style lang="scss" scoped>
.mod-login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important;
  .login-body {
    flex: 1;
    background: url('../../assets/login_images/BG-TRACK.jpg') no-repeat center center;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .login-text {
      width: calc(0.218 * 100%);
      margin-left: 8.33%;
      margin-bottom: 8%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      img {
        width: 100%;
      }
    }
    .login-form {
      // box-sizing: content-box;
      width: 23%;
      max-width: 440px;
      min-width: 350px;
      margin-right: 15%;
      border-radius: 6px;
      display: flex;
      align-items: center;
      box-shadow: 0px 7px 16px 7px #999;
      .login-box {
        // width: 440px;
        flex: 1;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: -4px 0px 0px 0px #E0E0E0;
        .inner-login {
          // width: 100%;
          padding: 60px 80px;
          box-shadow: 4px 0px 0px 0px #E0E0E0;
          border-radius: 6px;
          .logo {
            padding-bottom: 80px;
            img {
              height: 50px;
            }
          }
          .title {
            font-size: 24px;
            font-weight: 700;
            color: #23466E;
            padding-bottom: 20px;
          }
        }
        .item {
          padding-bottom: 15px;
          :deep(.el-input__prefix){
            left: 12px;
          }
          :deep(.el-input--prefix .el-input__inner) {
            border: none;
            border-radius: 2px;
            font-size: 16px;
          }
          :deep(.el-input__inner) {
            height: 35px;
            padding: 0 30px;
          }
          :deep(.el-checkbox) {
            height: 30px!important;
          }
          :deep(.el-input__wrapper) {
            padding: 1px 1px 1px 10px;
          }
          .error {
            color: red;
            text-align: center;
          }
          &.flex {
            display: flex;
            justify-content: space-between;
            .right {
              display: flex;
              align-items: center;
              color: #999;
            }
            :deep(.el-checkbox__label) {
              color: #23466E;
            }
          }
          .color {
            color: #23466e;
            font-weight: 500;
          }
        }
        .login {
          .el-button {
            width: 100%;
            font-size: 16px;
            background-color: #23466e;
            border-radius: 2px;
            padding: 11px 20px;
          }
        }

        .el-input__icon {
          font-size: 14px;
          width: auto;
        }
      }

      .el-form-item {
        margin: 0;
      }
    }
  }
  .login-footer {
    height: 60px;
    line-height: 60px;
    background-color: #333333;
    display: flex;
    padding: 0 8.33%;
    justify-content: space-between;
    color: #fff;
    .foot-nav {
      display: flex;
      align-items: center;
      img {
        width: 50px;
        padding-bottom: 7px;
      }
      & > a {
        margin-left: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        &:first-of-type {
          margin-left: 0
        }
      }
    }
    .foot-version {
      // font-size: 12px;
    }
    .foot-version a{
      color: #FFF;
      margin-right: 80px;
    }
  }
  .svg-container {
    position: absolute;
    top: 2px;
    left: 11px;
    z-index: $base-z-index;
    font-size: 14px;
    color: #d7dee3;
    cursor: pointer;
    user-select: none;
  }
  .show-password {
    position: absolute;
    top: 2px;
    right: 11px;
    font-size: 14px;
    color: #d7dee3;
    cursor: pointer;
    user-select: none;
  }
  .login-btn-ver {
    height: 35px;
  }
}
@media (max-width: 768px) {
  .mod-login {
    .login-body {
      .login-text {
        display: none;
      }
      .login-form {
        margin: 0 auto;
        width: 90%;
        max-width: 100%;
        .login-box {
          width: 100%;
          .inner-login {
            padding: 10% 5%;
          }
        }
      }
    }
    .login-footer {
      display: block;
      height: auto;
      line-height: unset;
    }
  }

}
</style>
