var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        type: "info",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("queryForm")
                        },
                      },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-sort",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.heightQuery },
                    },
                    [_vm._v(" 高级筛选 ")]
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticClass: "demo-form-inline my-form-class",
                      staticStyle: { "margin-top": "15px" },
                      attrs: { model: _vm.queryForm },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "circulationTime" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "流转开始日期",
                                      "end-placeholder": "流转结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.circulationTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "circulationTime",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.circulationTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "workOrderNo" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "工单编号" },
                                    model: {
                                      value: _vm.queryForm.workOrderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "workOrderNo",
                                          _vm._n(
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        )
                                      },
                                      expression: "queryForm.workOrderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "targetSubject" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "目标主体" },
                                    model: {
                                      value: _vm.queryForm.targetSubject,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "targetSubject",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryForm.targetSubject",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "projectId" } },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      value: _vm.queryForm.projectId,
                                      options: _vm.projectInfo,
                                      clearable: false,
                                      filterable: true,
                                      separator: ":",
                                      placeholder: "项目",
                                    },
                                    on: { change: _vm.handleProjectChange },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isShow,
                              expression: "isShow",
                            },
                          ],
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "visitDate" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "预约走访开始日期",
                                      "end-placeholder": "预约走访结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.visitDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "visitDate",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.visitDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "workOrderStatus" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "工单状态",
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.workOrderStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "workOrderStatus",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.workOrderStatus",
                                      },
                                    },
                                    _vm._l(
                                      _vm.orderStatusOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "userInfo" } },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      value: _vm.queryForm.userInfo,
                                      options: _vm.staffInfo,
                                      clearable: true,
                                      filterable: true,
                                      separator: " ",
                                      placeholder: "选择人员",
                                    },
                                    on: { change: _vm.handleSatffChange },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "operStatus" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "操作状态",
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.operStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "operStatus",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.operStatus",
                                      },
                                    },
                                    _vm._l(_vm.operateOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "orderDate" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "下单开始日期",
                                      "end-placeholder": "下单结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.queryForm.orderDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "orderDate",
                                          $$v
                                        )
                                      },
                                      expression: "queryForm.orderDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "address" } },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      value: _vm.queryForm.address,
                                      options: _vm.cityInfo,
                                      "change-on-select": false,
                                      clearable: true,
                                      filterable: true,
                                      placeholder: "地区",
                                      props: { checkStrictly: true },
                                    },
                                    on: { change: _vm.handleBlockChange },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "brWorkNo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "订单编号",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleQuery.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.queryForm.brWorkNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "brWorkNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryForm.brWorkNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "reportType" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "报告类型",
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.reportType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "reportType",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.reportType",
                                      },
                                    },
                                    _vm._l(_vm.reportType, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "projectSource" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择项目来源",
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.queryForm.projectSource,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "projectSource",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.projectSource",
                                      },
                                    },
                                    _vm._l(
                                      _vm.resourceOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.key,
                                          attrs: {
                                            label: item.name,
                                            value: item.key,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.downloadLoading, type: "primary" },
                      on: { click: _vm.handleDownload },
                    },
                    [_vm._v(" 导出 ")]
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tableSort",
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        data: _vm.list,
                        stripe: "",
                        border: "",
                        "element-loading-text": _vm.elementLoadingText,
                      },
                      on: { "selection-change": _vm.setSelectRows },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "40",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "工单编号",
                          prop: "workOrderNo",
                          align: "center",
                          width: "130",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.workOrderNo) + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubject",
                          label: "目标主体",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "目标地址",
                          "min-width": "250",
                          prop: "address",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.address || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "项目",
                          prop: "projectName",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "workOrderStatusValue",
                          label: "工单状态",
                          width: "110",
                          align: "center",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.workOrderStatusValue || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "circulationTime",
                          label: "流转日期",
                          width: "120",
                          align: "center",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.circulationTime || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bookVisitTime",
                          label: "预约走访时间",
                          sortable: "",
                          width: "130",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.bookVisitTime || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "nowUserName",
                          label: "当前操作人",
                          width: "110",
                          align: "center",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.nowUserName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "operStatusValue",
                          label: "操作状态",
                          align: "center",
                          width: "100px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.operStatusValue || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stepAndStranded",
                          align: "center",
                          label: "环节/整体滞留(天)",
                          fit: "true",
                          width: "90",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.stepAndStranded || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "撤回",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    "已提交" === scope.row.operStatusValue &&
                                    "待调查" === scope.row.workOrderStatusValue
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "warning",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleReturn(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("vab-icon", {
                                              attrs: { icon: ["fas", "undo"] },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "warning",
                                              circle: "",
                                              disabled: "",
                                            },
                                          },
                                          [
                                            _c("vab-icon", {
                                              attrs: { icon: ["fas", "undo"] },
                                            }),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c("el-pagination", {
                    attrs: {
                      background: _vm.background,
                      "current-page": _vm.queryForm.pageNum,
                      layout: _vm.layout,
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }