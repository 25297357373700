var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "videoDialog",
      attrs: { visible: _vm.dialogvideoVisible, "append-to-body": "" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogvideoVisible = $event
        },
        close: _vm.closeView,
      },
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", { staticStyle: { "text-align": "center" } }, [
            _c("video", {
              ref: "myVideo",
              staticStyle: { width: "90%", height: "300px" },
              attrs: { src: _vm.videoUrl, controls: "controls" },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }