var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "60%",
            "show-close": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("批量导入")]),
          _c(
            "el-steps",
            {
              staticStyle: { margin: "30px -45%" },
              attrs: { active: 1, "align-center": "" },
            },
            [
              _c(
                "el-step",
                { attrs: { title: "上传工单" } },
                [_c("vab-remix-icon", { attrs: { "icon-class": "number-1" } })],
                1
              ),
              _c(
                "el-step",
                { attrs: { title: "上传附件" } },
                [_c("vab-remix-icon", { attrs: { "icon-class": "number-2" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", type: "flex", gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("i", { staticClass: "el-icon-upload" }),
                  ]),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c("h3", [_vm._v("请上传填好的工单导入模板")]),
                    _c(
                      "div",
                      [
                        _vm.checkImport
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-family": "'微软雅黑'",
                                  "font-weight": "400",
                                  "text-decoration": "none",
                                  color: "#999999",
                                },
                              },
                              [
                                _vm._v(
                                  " 请先上传工单导入列表，格式为Excel的文件后缀名必须为xls、xlsx，文件大小不得大于10M "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "el-upload",
                          {
                            ref: "uploadTemplate",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              headers: _vm.headers,
                              data: _vm.uploadData,
                              "on-remove": _vm.handleShowlist,
                              "on-change": _vm.handleShowlist,
                              "on-success": _vm.handleSuccess,
                              "on-error": _vm.handleError,
                              "before-upload": _vm.beforeUpload,
                              limit: 1,
                              "on-exceed": _vm.handleExceed,
                              "file-list": _vm.fileList,
                            },
                          },
                          [
                            _vm.checkImport
                              ? _c(
                                  "el-link",
                                  {
                                    ref: "uploadBtn",
                                    staticStyle: { "margin-top": "30px" },
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                    },
                                  },
                                  [_vm._v(" 上传文件 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", type: "flex", gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 2 } }),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("h3", [
                      _vm._v(" 本次可导入工单数量 "),
                      _c("span", { staticClass: "unlimitedImportNum" }, [
                        _vm._v(_vm._s(_vm.unlimitedImportNum)),
                      ]),
                    ]),
                    _c("h3", [
                      _vm._v(" 本次不可导入工单数量 "),
                      _c("span", { staticClass: "limitedImportNum" }, [
                        _vm._v(_vm._s(_vm.limitedImportNum)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("不可导入工单列表")]),
          _c(
            "el-table",
            {
              attrs: { data: _vm.importStatisticsData, stripe: "", border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "lineNum",
                  label: "行数",
                  align: "left",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "错误提示", align: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "errorTips" }, [
                          _vm._v(_vm._s(scope.row.detail)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "" },
                          on: { click: _vm.handleClose },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm.reUploadShow
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.reUpload },
                            },
                            [_vm._v(" 重新上传 ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          ref: "nextStep",
                          attrs: { type: "primary", disabled: "" },
                          on: { click: _vm.goImportAnnex },
                        },
                        [_vm._v(" 下一步 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("importAnnex", {
        ref: "importAnnex",
        on: { "annex-todo": _vm.closeAll },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }