<!--Kyle PL工作台 -> 对账ID管理 -> 添加/编辑对账ID-->
<template>
  <el-dialog :title="dialogTitle"
             :class="myDialog"
             :visible.sync="dialogFormVisible"
             :close-on-click-modal="false"
             width="55%"
             :fullscreen="fullFlag"
             @close="close">
    <el-backtop :target="myDialogPoint"></el-backtop>
    <i style="position: absolute; top: 24px; right: 41px; cursor: pointer"
       :class="fullIcon"
       :title="fullTitle"
       @click="triggerFullOrHelf"></i>
    <el-card class="box-card">
      <!--基础信息-->
      <div class="title">基础信息</div>

      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="workOrderNo"
                         label="工单编号"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.workOrderNo || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="brWorkNo"
                         label="订单编号"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.brWorkNo || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="reportType"
                         label="报告类型"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.reportType || "-" }}
            {{ scope.row.subReportType ? '/' : ''}}
            {{ scope.row.subReportType}}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="projectNameNo"
                         label="Project Name"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.projectNameNo || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="projectName"
                         label="项目"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.projectName || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="projectSource"
                         label="项目来源"
                         align="center">
          <template slot-scope="scope">
            {{ getProjectSource(scope.row.projectSource) || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="targetSubject"
                         label="目标主体"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetSubject || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="国家/省份/城市/地域（县级市）"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.address || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="targetAddr1"
                         label="目标地址1"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetAddr1 || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="targetAddr2"
                         label="目标地址2"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetAddr2 || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="targetContact"
                         label="目标联系人"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetContact || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="contactNumber"
                         label="联系电话"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.contactNumber || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="duty"
                         label="职务"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.duty || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="orderTime"
                         label="下单时间"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.orderTime || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="innerDeadline"
                         label="内部截止日期"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.innerDeadline || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="remark1"
                         label="备注1"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.remark1 || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark2"
                         label="备注2"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.remark2 || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark3"
                         label="备注2"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.remark3 || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="clientAbbr"
                         label="委托方简称"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.clientAbbr || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="clientFullName"
                         label="委托方全称"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.clientFullName || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="reportTimeLimitValue"
                         label="报告时限"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.reportTimeLimitValue || "-" }}
          </template>
        </el-table-column>
      </el-table>

      <!--附加信息-->
      <div class="title">附加信息</div>

      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="expectVisitDate"
                         label="预计走访日期"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.expectVisitDate || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="expectServeyDay"
                         label="预计所需调查天数"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.expectServeyDay || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="needUser"
                         label="所需人手"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.needUser || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="expectHour"
                         label="预计需时（小时）"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.expectHour || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="registerAddress"
                         label="注册地址"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.registerAddress || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="targetSubjectRegStatus"
                         label="目标主体注册状态"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetSubjectRegStatus || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="visitFee"
                         label="走访收费"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.visitFee || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="urgentVisitFee"
                         label="加急走访收费"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.urgentVisitFee || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="againVisitFee"
                         label="再次走访收费"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.againVisitFee || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="feeBasis"
                         label="收费依据"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.feeBasis || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="targetOrganization1"
                         label="目标机构1"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetOrganization1 || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="targetOrganization2"
                         label="目标机构2"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetOrganization2 || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="targetResult"
                         label="目标地址预核结果"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.targetResult || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="infeasibleReason"
                         label="不可行原因/待确认项目"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.infeasibleReason || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="mailHeader"
                         label="邮件抬头"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.mailHeader || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <!--特别信息-->
      <div class="title">特别信息</div>

      <el-table :data="verifyIdData"
                stripe
                border>
        <el-table-column prop="plSpecialMsg"
                         label="PL特别留言"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.plSpecialMsg || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="bookSpecialMsg"
                         label="预约特别留言"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.bookSpecialMsg || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="monitorSpecialMsg"
                         label="督导特别留言"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.monitorSpecialMsg || "-" }}
          </template>
        </el-table-column>
      </el-table>

      <!--附件-->
      <div v-show="annexGroup.length !== 0"
           class="title">附件</div>

      <el-row style="text-align: center; margin-top: 20px">
        <div v-for="(item, index) in annexGroup"
             :key="index">
          <el-col :xs="24"
                  :sm="24"
                  :md="12"
                  :lg="8"
                  :xl="6">
            <a @click.stop.prevent="
                handleDownload({ name: item.fileName, path: item.path })
              ">
              <img src="@/icon/annex.svg"
                   alt=""
                   width="80"
                   height="80" />
              <p>{{ item.fileName }}</p>
            </a>
          </el-col>
        </div>
      </el-row>

      <!--操作信息-->
      <div class="title">操作信息</div>
      <el-table :data="workOrderLogs"
                stripe
                border>
        <el-table-column prop="operator"
                         label="操作者"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.operator || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="role"
                         label="角色"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.role || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="operAction"
                         label="操作状态"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.operAction || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime"
                         label="操作时间"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.createTime || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="remark"
                         label="备注"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.remark || "-" }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-divider></el-divider>
    <!--对账ID添加-->
    <div class="title">对账ID添加</div>
    <el-form ref="verifyForm"
             :model="verifyForm"
             :rules="verifyFormRules"
             class="table-class demo-form-inline">
      <el-row :gutter="80">
        <el-col :xs="24"
                :sm="24"
                :md="12"
                :lg="8"
                :xl="8">
          <el-form-item label="内部截止时间"
                        prop="innerDeadline">
            <el-date-picker v-model="verifyForm.innerDeadline"
                            type="datetime"
                            placeholder="选择内部截止时间"
                            format="yyyy-MM-dd HH:mm"
                            style="width: 100%"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="12"
                :lg="8"
                :xl="8">
          <el-form-item label="添加对账ID"
                        prop="reconciliationId">
            <el-input v-model="verifyForm.reconciliationId"
                      placeholder="请添加对账ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="12"
                :lg="8"
                :xl="8">
          <el-form-item label="小记"
                        prop="content">
            <el-input v-model="verifyForm.content"
                      type="textarea"
                      placeholder="请输入小记"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="12"
                :lg="8"
                :xl="8">
          <el-form-item label="添加SV Code"
                        prop="svCode">
            <el-input v-model="verifyForm.svCode"
                      placeholder="请输入SV Code"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="12"
                :lg="8"
                :xl="8">
          <el-form-item label="是否扣罚"
                        prop="deductionFlag">
            <el-select v-model="verifyForm.deductionFlag"
                       placeholder="请选择是否扣罚"
                       filterable>
              <el-option v-for="item in penaltySelect"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="12"
                :lg="8"
                :xl="8"
                class="isQuestion">
          <el-form-item label="是否质疑"
                        prop="question">
            <el-select v-model="verifyForm.question"
                       placeholder="请选择是否质疑"
                       :disabled="true"
                       filterable>
              <el-option v-for="item in questionSelect"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
            <!-- <el-input v-model="verifyForm.question" :disabled="true"></el-input> -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button type="default"
                 @click="cancelDialog">取消</el-button>
      <el-button type="success"
                 :loading="saveLoading"
                 @click="saveVerify('verifyForm')">
        保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getList } from "@/api/workplace/pl/plAddVerify"
import { doEdit } from "@/api/workplace/pl/plVerifyTodo"
import { mapGetters } from "vuex"
// 转化当前时间格式
import {
	returnBitTimestamp,
	getRoleKey,
	downloadFile,
	isExistOSS,
	getProjectSource,
} from "@/utils"
import { searchAttach } from "@/api/workplace/tools"
export default {
	name: "AddVerify",
	components: {},
	data() {
		return {
			// 工单附件集合
			annexGroup: [],
			saveLoading: false,
			dialogTitle: "",
			// 全/半屏提示文本
			fullTitle: "最大化",
			// 全/半屏按钮图标
			fullIcon: "el-icon-full-screen",
			// 是否全屏标识
			fullFlag: false,
			dialogFormVisible: false,
			tab_info: "",
			verifyIdData: [],
			workOrderLogs: [],
			verifyForm: {
				console: "PL",
				content: "",
				deductionFlag: "",
				innerDeadline: "",
				nextUserNo: "",
				nextUserRoleNo: "",
				projectSource: "",
				reconciliationId: "",
				remark: "",
				operAction: "保存小记",
				step: "",
				svCode: "",
				userNo: "",
				userRoleNo: "",
				workOrderNos: [],
				question: "",
			},
			verifyPortForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
			},
			queryForm: {
				body: {
					console: "PL",
					nextUserNo: "",
					nextUserRoleNo: "",
					operAction: "添加对账ID",
					projectSource: "process_hk",
					remark: "",
					userNo: "",
					userRoleNo: "",
					workOrderNo: "",
				},
				header: {
					currentTime: "",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
			},
			verifyFormRules: {
				innerDeadline: [
					{
						required: true,
						message: "请选择内部截止日期",
						trigger: "change",
					},
				],
				reconciliationId: [
					{ required: true, message: "请添加对账ID", trigger: "blur" },
					{
						max: 50,
						message: "对账ID不能超过50个数字",
						trigger: "blur",
					},
				],
				svCode: [
					{ required: true, message: "请添加SV Code", trigger: "blur" },
					{
						max: 50,
						message: "SV Code不能超过50个数字",
						trigger: "blur",
					},
				],
				content: [
					{ required: true, message: "请输入小记", trigger: "blur" },
					{
						max: 500,
						message: "小记不能超过500个汉字",
						trigger: "blur",
					},
				],
				deductionFlag: [
					{ required: true, message: "请选择是否扣罚", trigger: "blur" },
				],
				question: [
					{ required: true, message: "请选择是否质疑", trigger: "blur" },
				],
			},
			penaltySelect: [
				{
					value: 1,
					label: "是",
				},
				{
					value: 0,
					label: "否",
				},
			],
			questionSelect: [
				{
					value: 1,
					label: "是",
				},
				{
					value: 0,
					label: "否",
				},
			],
			// 获取工单详情
			param: {
				body: {
					userNo: "",
					userRoleNo: "",
					workOrderNo: null,
					pageSize: 10,
					pageNum: 1,
				},
				header: {
					currentTime: "",
					requestId: "1",
					sourceType: "site",
				},
			},
			myDialog: "",
			myDialogPoint: "",
		}
	},
	computed: {
		...mapGetters({
			userNo: "user/userNo",
			roles: "user/roles",
			endpoinxBak: "user/endpoinxBak",
			OSSClient: "user/OSSClient",
		}),
	},
	created() {
		this.verifyForm.userNo = this.userNo
		this.verifyForm.userRoleNo = getRoleKey("PL", this.roles)
		this.queryForm.body.userNo = this.userNo
		this.queryForm.body.userRoleNo = getRoleKey("PL", this.roles)
	},
	methods: {
		close() {
			this.verifyIdData = []
			this.workOrderLogs = []
			// this.createForm = this.$options.data().form;
			this.dialogFormVisible = false
			this.fullFlag = false
			this.fullIcon = "el-icon-full-screen"
			this.fullTitle = "最大化"
			// this.$emit("fetchData");
		},
		cancelDialog() {
			this.verifyForm = this.$options.data().verifyForm
			this.$refs["verifyForm"].resetFields()
			this.dialogFormVisible = false
		},
		//
		async openVerify(workOrderNo, tab) {
			this.verifyForm.userNo = this.userNo
			this.verifyForm.userRoleNo = getRoleKey("PL", this.roles)
			// 区分待办 已办 "0","1"
			this.tab_info = tab
			if (this.tab_info === "0") {
				this.myDialog = "myDialogAdd"
				this.myDialogPoint = ".myDialogAdd"
			} else {
				this.myDialog = "myDialogEdit"
				this.myDialogPoint = ".myDialogEdit"
			}
			this.queryForm.body.workOrderNo = workOrderNo
			let _arr = []
			_arr.push(workOrderNo)
			this.verifyForm.workOrderNos = _arr
			this.dialogTitle = "添加对账ID"
			this.dialogFormVisible = true
			this.queryForm.header.currentTime = returnBitTimestamp(new Date())
			// 获取pl工作台对账IDlist
			getList(this.queryForm).then((response) => {
				if ("1" === response.header.retCode) {
					let verifyIdData_info = []
					verifyIdData_info.push(response.body)
					this.verifyIdData = verifyIdData_info
					this.workOrderLogs = response.body.workOrderLogs
					// 内部截止日期
					this.verifyForm.innerDeadline = Date.parse(
						response.body.innerDeadline
					)
					// 对账ID
					this.verifyForm.reconciliationId = response.body.reconciliationId
					// sv code
					this.verifyForm.svCode = response.body.svCode
					// 是否质疑
					this.verifyForm.question = response.body.questionFlag
					// 是否处罚
					this.verifyForm.deductionFlag = response.body.deductionFlag
				} else {
					this.$message.error(
						`错误信息: ${response.header.retMessage}, 获取对账ID失败，请刷新页面重试！`
					)
				}
			})
			/** 查询附件信息 */
			await this.attach(workOrderNo)
		},
		/** 查询附件信息 */
		async attach(workOrderNo) {
			this.param.body.workOrderNo = workOrderNo
			this.param.header.currentTime = returnBitTimestamp(new Date())
			const { body, header } = await searchAttach(this.param)
			if (header.retCode === "1") {
				this.annexGroup = body
			} else {
				this.$baseMessage(header.retMessage, "error")
			}
		},
		// 保存
		saveVerify(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true
					// 内部截止时间
					if (this.verifyForm.innerDeadline) {
						this.verifyForm.innerDeadline = returnBitTimestamp(
							this.verifyForm.innerDeadline
						)
					}
					if (this.tab_info === "0") {
						debugger
						this.verifyForm.step = "PL工作台-对账ID管理-待处理"
					} else {
						this.verifyForm.step = "PL工作台-对账ID管理-已处理"
					}
					this.verifyPortForm.body = this.verifyForm
					this.verifyPortForm.header.currentTime = returnBitTimestamp(
						new Date()
					)
					doEdit(this.verifyPortForm).then((response) => {
						if (response.header.retCode === "1") {
							if (this.tab_info === "0") {
								this.$message({
									message: `${response.header.retMessage}`,
									type: "success",
								})
								this.$emit("refreshData")
							} else {
								this.$message({
									message: `${response.header.retMessage}`,
									type: "success",
								})
								this.$emit("fetchData")
							}
							this.dialogFormVisible = false
							this.$refs["verifyForm"].resetFields()
							// this.verifyForm = this.$options.data().verifyForm;
						} else {
							this.$message.error(
								`错误信息: ${response.header.retMessage},添加失败，请重新提交！`
							)
						}
						this.saveLoading = false
					})
				} else {
					this.$message.error("保存失败，请重新检查对账ID数据")
					return false
				}
			})
			this.fullFlag = false
			this.fullIcon = "el-icon-full-screen"
			this.fullTitle = "最大化"
		},
		/** 全屏/半屏切换 */
		triggerFullOrHelf() {
			this.fullFlag = !this.fullFlag
			if (this.fullFlag) {
				this.fullIcon = "el-icon-minus"
				this.fullTitle = "最小化"
			} else {
				this.fullIcon = "el-icon-full-screen"
				this.fullTitle = "最大化"
			}
		},
		/** 附件下载 */
		handleDownload(item) {
			isExistOSS(this.OSSClient, item.path)
				.then(() => {
					downloadFile(item)
				})
				.catch(() => {
					this.$message.error("当前文件不存在！")
				})
		},
		getProjectSource(code) {
			return getProjectSource(code)
		},
	},
}
</script>

<style scoped>
body {
	font-weight: 700;
	color: #666;
}
.title {
	margin: 10px 0;
}
html body .el-table th .cell {
	font-weight: 700;
	color: #666;
}

.el-form-item__label {
	font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100% !important;
}

.el-select,
.el-cascader {
	width: 100%;
}

.myDiv {
	width: 150px;
	height: 150px;
	text-align: center;
}

.myDiv img {
	width: 130px;
	height: 130px;
	text-align: center;
}
.isQuestion .el-input /deep/input {
	background: rgba(204, 204, 204, 1);
	color: #666;
}
</style>
