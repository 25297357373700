<!-- 会议信息 -->
<template>
  <div>
    <el-table :data="meetingData" stripe border>
      <el-table-column
        prop="startTime"
        label="会议开始时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="endTime"
        label="会议结束时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="录像名称"
        align="center"
      ></el-table-column>
    </el-table>
    <!--分页模块-->
    <el-row v-if="total > 2" :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="meetingParam.pageNum"
            :layout="layout"
            :page-size="meetingParam.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="2">
        <el-button type="primary" style="margin-top: 30px" @click="handleView">
          查看录像
        </el-button>
      </el-col>
    </el-row>
    <!-- 查看会议录像 -->
    <meetingView ref="meetingView"></meetingView>
  </div>
</template>

<script>
  import { detailMediaResource } from "@/api/workplace/workDetail";
  import meetingView from "./meetingView";
  export default {
    name: "Meeting",
    components: {
      meetingView,
    },
    props: {
      // eslint-disable-next-line vue/require-default-prop
      type: {
        type: String,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: null,
      },
      row: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        background: true,
        total: 1,
        layout: "total, sizes, prev, pager, next, jumper",
        meetingData: [],

        parameter: {
          body: {
            pageNum: 1,
            pageSize: 10,
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "111",
            sourceType: "site",
          },
        },
      };
    },
    created() {},
    methods: {
      handleSizeChange(val) {
        this.meetingParam.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.meetingParam.pageNum = val;
        this.fetchData();
      },
      fetchData() {
        this.parameter.body.type = this.type;
        this.parameter.body.userNo = this.row.userNo;
        this.parameter.body.userRoleNo = this.row.userRoleNo;
        this.parameter.body.workOrderNo = this.row.workOrderNo;
        this.parameter.body.console = this.row.workplace;
        detailMediaResource(this.parameter).then((res) => {
          if (res.header.retCode === "1") {
            this.meetingData = res.body.records;
            this.total = res.body.total;
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      },
      // 打开预览
      handleView() {
        if (this.meetingData.length === 0) {
          this.$baseMessage("暂无会议信息", "info");
        } else {
          if (this.meetingData[0].path === null) {
            this.$baseMessage("暂无会议信息录像", "info");
          } else {
            this.$refs["meetingView"].bindData(
              this.row.endpoinxBak + this.meetingData[0].path
            );
            this.$refs["meetingView"].showView();
          }
        }
      },
    },
  };
</script>

<style scoped></style>
