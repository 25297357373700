<template>
  <div>
    <el-table :data="backList" stripe border>
      <el-table-column prop="createTime" label="时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="operator" label="操作人" align="center">
        <template slot-scope="scope">
          {{ scope.row.operator || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="returnee" label="退回至" align="center">
        <template slot-scope="scope">
          {{ scope.row.returnee || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="退回原因" align="center">
        <template slot-scope="scope">
          <!-- 判断退回类型是否为质疑 joinCommentType 为1时，是质疑退回，当查看富文本 -->
          <el-tooltip
            v-if="scope.row.joinCommentType === '1'"
            class="item"
            effect="dark"
            content="查看"
            placement="top-start"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-document"
              circle
              @click="readReason(scope.row.reason)"
            ></el-button>
          </el-tooltip>
          <div v-else>{{ scope.row.reason || "-" }}</div>
        </template>
      </el-table-column>
    </el-table>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNum"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!-- 查看原因对话框  -->
    <el-dialog
      title="退回原因"
      :visible.sync="reasonVisible"
      :close-on-click-modal="false"
      append-to-body
      width="50%"
      @close="reasonClose"
    >
      <div class="htmlContent" v-html="content"></div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button plain @click="reasonClose">取消</el-button> -->
        <el-button type="primary" :loading="loading" @click="reasonClose">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getBackRecords } from "@/api/workplace/tools";
  import { returnBitTimestamp } from "@/utils/index";

  export default {
    name: "Back",

    props: {
      // eslint-disable-next-line vue/require-default-prop
      order: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        loading: false,
        reasonVisible: false,
        background: true,
        content: "",
        total: 7,
        layout: "total, sizes, prev, pager, next, jumper",
        queryForm: {
          pageNum: 1,
          pageSize: 10,
        },
        backList: [],
        param: {
          body: {
            userNo: "",
            userRoleNo: "commonRole",
            workOrderNo: null,
            pageNum: 1,
            pageSize: 10,
          },
          header: {
            currentTime: "",
            requestId: "111",
            sourceType: "site",
          },
        },
      };
    },
    created() {
      // this.param.body.userNo = this.userNo;
    },
    methods: {
      reasonClose() {
        this.loading = true;
        this.reasonVisible = false;
        this.loading = false;
      },
      /**富文本查看 */
      readReason(reason) {
        this.content = reason;
        this.reasonVisible = true;
      },
      async fetchData() {
        this.param.body.workOrderNo = this.order;
        this.param.header.currentTime = returnBitTimestamp(new Date());

        // 获取退回记录
        const { body } = await getBackRecords(this.param);
        this.backList = body.records;
        this.total = body.total;
      },
      handleSizeChange(val) {
        this.param.body.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.param.body.pageNum = val;
        this.fetchData();
      },
    },
  };
</script>

<style scoped>
  .htmlContent {
    max-height: 400px;
    overflow: auto;
  }
  .htmlContent >>> img {
    display: block;
    max-width: 100%;
    max-height: 300px;
  }
</style>

<style lang="scss">
  .vab-quill-content {
    ::v-deep {
      .el-form-item__content {
        line-height: normal;
      }
    }
  }
</style>
