var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "never" } },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { staticClass: "checkAllBox" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { border: "", indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [_vm._v(" 全选 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "small", icon: "el-icon-download" },
                  on: {
                    click: function ($event) {
                      _vm.downloadVisible = true
                    },
                  },
                },
                [_vm._v(" 下载 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { margin: "15px 0" } }),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { ref: "photoGroup" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _c("div", { staticClass: "title" }, [_vm._v("走访开始")]),
                  ]),
                  _c("el-col", { staticStyle: { padding: "10px" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "board-column-content",
                            attrs: {
                              list: _vm.photoVisit,
                              options: { sort: true },
                            },
                            on: { change: _vm.change },
                          },
                          _vm._l(
                            _vm.photoVisit.filter((item) => {
                              return item.visitFlag === 0
                            }),
                            function (subItem, subIndex) {
                              return _c(
                                "div",
                                {
                                  key: subItem.seqId,
                                  staticClass: "class-detail",
                                },
                                [
                                  subItem.visitFlag === 0
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "160px",
                                          height: "160px",
                                          cursor: "pointer",
                                        },
                                        attrs: {
                                          src:
                                            _vm.row.endpoinxBak + subItem.path,
                                          fit: _vm.fit,
                                          title: subItem.name,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleView(
                                              _vm.photoVisit,
                                              "走访开始",
                                              0,
                                              subIndex
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  subItem.visitFlag === 0
                                    ? _c("el-checkbox", {
                                        key: subIndex,
                                        staticClass: "img_checkbox",
                                        on: {
                                          change: function ($event) {
                                            return _vm.handlecheckedChange(
                                              subIndex
                                            )
                                          },
                                        },
                                        model: {
                                          value: subItem.mychecked,
                                          callback: function ($$v) {
                                            _vm.$set(subItem, "mychecked", $$v)
                                          },
                                          expression: "subItem.mychecked",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", [
                    _c("div", { staticClass: "title" }, [_vm._v("走访结束")]),
                  ]),
                  _c("el-col", { staticStyle: { padding: "10px" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "board-column-content",
                            attrs: { list: _vm.photoVisit },
                            on: { change: _vm.change },
                          },
                          _vm._l(
                            _vm.photoVisit.filter((item) => {
                              return item.visitFlag === 1
                            }),
                            function (subItem, subIndex) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: subItem.visitFlag === 1,
                                      expression: "subItem.visitFlag === 1",
                                    },
                                  ],
                                  key: subItem.seqId,
                                  staticClass: "class-detail",
                                },
                                [
                                  subItem.visitFlag === 1
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "160px",
                                          height: "160px",
                                          cursor: "pointer",
                                        },
                                        attrs: {
                                          src:
                                            _vm.row.endpoinxBak + subItem.path,
                                          fit: _vm.fit,
                                          title: subItem.name,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleView(
                                              _vm.photoVisit,
                                              "走访结束",
                                              1,
                                              subIndex
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  subItem.visitFlag === 1
                                    ? _c("el-checkbox", {
                                        staticClass: "img_checkbox",
                                        on: {
                                          change: function ($event) {
                                            return _vm.handlecheckedChange(
                                              subIndex
                                            )
                                          },
                                        },
                                        model: {
                                          value: subItem.mychecked,
                                          callback: function ($$v) {
                                            _vm.$set(subItem, "mychecked", $$v)
                                          },
                                          expression: "subItem.mychecked",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("clockInView", { ref: "clockInView" }),
      _c(
        "el-dialog",
        {
          staticClass: "downloadDialog",
          attrs: {
            title: "下载确认",
            visible: _vm.downloadVisible,
            width: "30%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.downloadVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否要下载所有勾选内容？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.downloadVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.download } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }