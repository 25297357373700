<template>
  <el-dialog :title="title"
             class="myModelDialog"
             :visible.sync="dialogFormVisible"
             :close-on-click-modal="false"
             width="60%"
             @close="close">
    <el-backtop target=".myModelDialog"></el-backtop>
    <!--检索模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-form ref="addSystemTemplateForm"
                 :model="addSystemTemplateForm"
                 :rules="addSystemTemplateFormRules"
                 class="demo-form-inline">
          <!--基础信息-->
          <label style="margin-bottom: 20px; display: block">基础信息</label>
          <el-row :gutter="40">
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="8">
              <el-form-item label="系统模板名称"
                            prop="name">
                <el-input v-model.trim="addSystemTemplateForm.name"
                          placeholder="请填写模板名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="8">
              <el-form-item label="项目"
                            prop="projectNo">
                <el-cascader ref="refProject"
                             :value="addSystemTemplateForm.projectNo"
                             :options="projectInfo"
                             :clearable="true"
                             :filterable="true"
                             separator=":"
                             placeholder="请选择项目"
                             :disabled="showOrNot"
                             @change="handleProjectChange"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="8"
                    :xl="8">
              <el-form-item label="文档模板名称"
                            prop="fileName">
                <el-input v-model="addSystemTemplateForm.fileName"
                          type="textarea"
                          :autosize="{ minRows:1, maxRows: 2}"
                          disabled></el-input>
              </el-form-item>
            </el-col>
            <template v-if="brSource">
              <!-- 委托方 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="委托方"
                              prop="client">
                  <el-select v-model="addSystemTemplateForm.client"
                             placeholder="请选择委托方"
                             clearable
                             filterable
                             @change="filterReport">
                    <el-option v-for="item in clientInfo.clientOptions"
                               :key="item.customer"
                               :label="item.customerName"
                               :value="item.customer"></el-option>
                  </el-select>
                  <!--el-input v-model="addSystemTemplateForm.client"
                            disabled></el-input-->
                </el-form-item>
              </el-col>
              <!-- 报告类型 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="报告类型"
                              prop="reportType">
                  <el-select v-model="addSystemTemplateForm.reportType"
                             placeholder="请选择报告类型"
                             clearable
                             filterable
                             @change="filterSubReport"
                             @focus="filterTip">
                    <el-option v-for="item in clientInfo.reportOptions"
                               :key="item.orderType_After"
                               :label="item.orderType_Title"
                               :value="item.orderType_After"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 二级类型 -->
              <el-col v-if="clientInfo.subReportOptions.length"
                      :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="二级类型"
                              prop="subReportType">
                  <el-select v-model="addSystemTemplateForm.subReportType"
                             placeholder="请选择二级类型"
                             clearable
                             filterable>
                    <el-option v-for="item in clientInfo.subReportOptions"
                               :key="item.id"
                               :label="item.title"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </template>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="16"
                    :xl="16">
              <el-form-item label="系统模板描述"
                            prop="description">
                <el-input v-model="addSystemTemplateForm.description"
                          type="textarea"
                          placeholder="请输入描述信息"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!--配置内容-->
          <vab-query-form>
            <vab-query-form-left-panel>
              <label>配置内容</label>
              <!--              <el-button-->
              <!--                plain-->
              <!--                icon="el-icon-mobile-phone"-->
              <!--                @click="previewPhone"-->
              <!--              >-->
              <!--                预览-->
              <!--              </el-button>-->
              <el-button plain
                         icon="el-icon-mobile-phone"
                         @click="webPhone">
                预览
              </el-button>
            </vab-query-form-left-panel>
            <vab-query-form-right-panel>
              <el-button v-if="templateList.length === 0"
                         type="primary"
                         size="small"
                         icon="el-icon-upload2"
                         @click="uploadFun">
                导入
              </el-button>
              <el-button icon="el-icon-download"
                         plain
                         @click="handleDownload">
                导出
              </el-button>
              <el-button icon="el-icon-plus"
                         type="primary"
                         @click="addContent">
                添加一级目录
              </el-button>
            </vab-query-form-right-panel>
          </vab-query-form>
          <el-row>
            <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="24"
                    :xl="24">
              <el-table v-loading="templateLoading"
                        :data="templateList"
                        row-key="seqId"
                        node-key="seqId"
                        :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }">
                <el-table-column prop="elementTitle"
                                 label="表单及元素名称"
                                 :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="fieldKey"
                                 label="英文名称"
                                 width="140"
                                 :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    {{ scope.row.fieldKey }}
                  </template>
                </el-table-column>
                <!-- 若当前节点level为一级，则展示一级目录，否则显示配置项，（元素类型存在即优先显示元素类型） -->
                <el-table-column prop="fieldType"
                                 label="元素类型"
                                 width="120"
                                 :show-overflow-tooltip="true"
                                 :formatter="judgeElementType"></el-table-column>
                <el-table-column label="排序"
                                 align="center"
                                 width="100"
                                 class-name="small-padding fixed-width">
                  <template slot-scope="scope">
                    <el-tooltip v-if="judgeSort(scope.row)"
                                class="item"
                                effect="dark"
                                content="升序"
                                placement="top-start">
                      <el-button size="mini"
                                 plain
                                 icon="el-icon-top"
                                 circle
                                 @click="order(scope.row, 0)"></el-button>
                    </el-tooltip>

                    <el-tooltip v-if="judgeSort(scope.row)"
                                class="item"
                                effect="dark"
                                content="降序"
                                placement="top-start">
                      <el-button size="mini"
                                 plain
                                 icon="el-icon-bottom"
                                 circle
                                 @click="order(scope.row, 1)"></el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="操作"
                                 align="right"
                                 width="240"
                                 class-name="small-padding fixed-width">
                  <template slot-scope="scope">
                    <el-tooltip v-if="judgeLinkage(scope.row)"
                                class="item"
                                effect="dark"
                                content="联动"
                                placement="top-start">
                      <el-button size="mini"
                                 type="warning"
                                 icon="el-icon-magic-stick"
                                 circle
                                 @click="handleLinkage(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-if="judgeCopy(scope.row)"
                                class="item"
                                effect="dark"
                                content="复制"
                                placement="top-start">
                      <el-button size="mini"
                                 type="primary"
                                 icon="el-icon-document-copy"
                                 circle
                                 @click="handleCopy(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-if="judgeAdd(scope.row)"
                                class="item"
                                effect="dark"
                                content="新增"
                                placement="top-start">
                      <el-button size="mini"
                                 type="primary"
                                 icon="el-icon-plus"
                                 circle
                                 @click="handleAdd(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-if="judgeEdit(scope.row)"
                                class="item"
                                effect="dark"
                                content="编辑"
                                placement="top-start">
                      <el-button size="mini"
                                 type="primary"
                                 icon="el-icon-edit-outline"
                                 circle
                                 @click="handleUpdate(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip v-if="judgeDelete(scope.row)"
                                class="item"
                                effect="dark"
                                content="删除"
                                placement="top-start">
                      <el-button size="mini"
                                 type="danger"
                                 icon="el-icon-delete"
                                 circle
                                 @click="handleDelete(scope.row)"></el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>

              <!--导入模板-->
              <el-dialog :title="uploadTitle"
                         :visible.sync="uploadOpen"
                         width="600px"
                         append-to-body>
                <el-upload class="upload-demo"
                           drag
                           action="https://jsonplaceholder.typicode.com/posts/"
                           multiple
                           align="center">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                  </div>
                  <div slot="tip"
                       class="el-upload__tip">
                    只能上传jpg/png文件，且不超过500kb
                  </div>
                </el-upload>

                <div slot="footer"
                     class="dialog-footer">
                  <el-button @click="getBack('uploadOpen')">返 回</el-button>
                </div>
              </el-dialog>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- 添加/修改类型模板对话框 -->
    <form-option ref="formOption"
                 @fetchData="fetchData"></form-option>
    <!-- 表单节点对话框 -->
    <form-info ref="formInfo"
               @fetchData="fetchData"></form-info>
    <!-- 配置项新增/编辑  对话框-->
    <el-dialog title="配置项"
               :visible.sync="optionVisivle"
               width="56%"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :before-close="handleOptionClose"
               append-to-body>
      <el-form ref="optionForm"
               :model="optionForm"
               :rules="optionFormRules"
               label-width="80px"
               style="padding: 10px">
        <el-form-item prop="elementTitle"
                      label="配置项">
          <el-input v-model.trim="optionForm.elementTitle"
                    placeholder="请输入配置项的字段名称"
                    @keyup.enter.native="handleOptionCertain"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleOptionClose">取 消</el-button>
        <el-button type="primary"
                   @click="handleOptionCertain">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 手机预览 -->
    <phone-preview ref="phonePreview"></phone-preview>
    <web-preview ref="webPreviewOne"></web-preview>
    <!--按钮组-->
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary"
                 :loading="certainLoading"
                 @click="save">
        确定
      </el-button>
    </div>
    <!-- 批量上传资源对话框 -->
    <el-dialog title="上传资源"
               :visible.sync="openUploadSource"
               width="50%"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :before-close="handleImportClose"
               append-to-body>
      <el-card class="box-card">
        <el-row align="middle"
                type="flex"
                :gutter="40">
          <el-col :span="3"><i class="el-icon-upload"></i></el-col>
          <el-col :span="21">
            <h3>请您上传资源</h3>
            <div>
              <p style="
                  font-family: '微软雅黑', serif;
                  font-weight: 400;
                  text-decoration: none;
                  color: #999999;
                ">
                请上传导入文件，文件后缀名必须为txt
              </p>
              <el-upload ref="uploadTemplate"
                         class="upload-demo"
                         :action="uploadUrl"
                         :data="uploadData"
                         :headers="headers"
                         :on-success="handleSuccess"
                         :on-error="handleError"
                         :before-upload="beforeUpload"
                         :limit="1"
                         :on-exceed="handleExceed"
                         :file-list="fileList">
                <el-link ref="uploadBtn"
                         type="primary"
                         :underline="false"
                         style="margin-top: 30px">
                  上传文件
                </el-link>
              </el-upload>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleImportClose">取 消</el-button>
        <el-button ref="nextStep"
                   type="primary"
                   disabled
                   @click="handleImportClose">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import phonePreview from "./phonePreview";
import webPreview from "./webPreview";
// 元素配置及联动对话框
import formOption from "./formOption";
// 表单及
import formInfo from "./formInfo";
import {
  addSystemTemplate,
  previewTemplate,
  webTemplate,
  getEditTemplate,
  eEditTemplateSave,
  searchDocument,
  //退出或取消时清空数据
  emptyElements,
  getClientReportType,
} from "@/api/project/manage";
import {
  getSystemData,
  // 模板配置新增接口
  addSystemData,
  // 模板配置删除接口
  delSystemData,
  // 模板配置修改接口
  updateSystemData,
  // 模板配置复制接口
  copySystemData,
  // 模板配置排序接口
  sortSystemData,
} from "@/api/project/systemTemplate";
import { mapGetters } from "vuex";
// 获取工具API
import { getProjectSource } from "@/api/workplace/tools";
import store from "@/store";
import { returnBitTimestamp, downloadReport } from "@/utils/index";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { lengthValidate } from "@/utils/validate";
export default {
  name: "TemplateOption",
  components: { phonePreview, formOption, formInfo, webPreview },
  data() {
    return {
      templateLoading: false,
      // 配置项对话框
      optionVisivle: false,
      // 配置项新增/编辑 flag
      optionFlag: true,
      optionForm: {
        elementTitle: "",
      },
      optionFormRules: {
        elementTitle: [
          {
            // required: true,
            // trigger: "blur",
            // message: "请务必输入配置项",
            required: true,
            validator: lengthValidate,
          },
        ],
      },
      // 添加配置项
      addForm: {
        body: {
          elementLevel: 1,
          elementTitle: "",
          // 配置项级联 elementType：元素类型(0:目录node 1:字段field 2:配置项linkage)
          elementType: 2,
          fieldKey: null,
          fieldType: null,
          parentId: 0,
          projectId: "",
          userNo: "",
          userRoleNo: "commonRole",
          // 级联标志(0:非级联 1:级联)
          linkageSign: 0,
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 编辑配置项
      updateForm: {
        body: {
          elementLevel: 1,
          elementTitle: "",
          // 配置项级联
          elementType: 2,
          fieldKey: null,
          // 字段类型(input:输入框 select:下拉选择器 radio:单选框 checkbox:多选框 album:相册)
          fieldType: null,
          parentId: 0,
          projectId: "",
          seqId: "",
          userNo: "",
          userRoleNo: "commonRole",
          // 级联标志(0:非级联 1:级联)
          linkageSign: 0,
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 复制
      copyForm: {
        body: {
          projectId: "",
          seqId: "",
          userNo: "",
          userRoleNo: "commonRole",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 排序
      sortForm: {
        body: {
          parentId: "",
          projectId: "",
          seqId: "",
          sortNumber: 0,
          sortSign: "",
          userNo: "",
          userRoleNo: "commonRole",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 删除
      delForm: {
        body: {
          seqId: 1,
          userNo: "",
          userRoleNo: "commonRole",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },

      // 防抖
      certainLoading: false,
      // 新增或编辑 "add"/"edit"
      operaType: "",
      // 是否为表单
      add_children_type: false,
      tmpID: undefined,
      projectInfo: [], // 项目
      /** 导入 */
      fileList: [],
      openUploadSource: false,
      uploadUrl: "/site/form/importTemplate",
      uploadData: {
        userNo: "",
        userRoleNo: "commonRole",
        projectNo: "",
      },
      headers: {
        Authorization: "Bearer " + store.getters["user/accessToken"],
      },
      projectForm: {
        body: {
          source: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      fileName: "",
      /**documentForm */
      documentForm: {
        body: {
          projectNo: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      /** 根据项目获取系统模板 1.0 */
      systemForm: {
        body: {
          projectId: "",
          userNo: "",
          userRoleNo: "commonRole",
        },
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "",
        },
      },
      param: {
        body: {
          seqId: "",
          userNo: "",
          userRoleNo: "commonRole",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      addSystemTemplateForm: {
        config: [],
        description: "",
        fileName: "",
        fileSeqId: "",
        name: "",
        projectNo: "",
        seqId: "",
        nextUserNo: "",
        nextUserRoleNo: "",
        userNo: "",
        userRoleNo: "commonRole",
        client: "",
        reportType: "",
        subReportType: "",
        // projectId: "",
      },
      addSystemTemplatePortForm: {
        body: {
          userRoleNo: "commonRole",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      addSystemTemplateFormRules: {
        name: [{ required: true, trigger: "blur", message: "请输入模板名称" }],
        fileName: [
          {
            required: true,
            trigger: "blur",
            message: "文档模板名称不能为空",
          },
        ],
        projectNo: [
          { required: true, trigger: "change", message: "请选择项目" },
        ],
        client: [
          {
            required: true,
            trigger: "change",
            message: "请选择委托方",
          },
        ],
        reportType: [
          {
            required: true,
            trigger: "change",
            message: "请选择报告类型",
          },
        ],
        subReportType: [
          {
            required: true,
            trigger: "change",
            message: "请选择二级类型",
          },
        ],
      },
      dialogFormVisible: false,
      docTemplateOptions: [],
      flag: null,
      // 菜单表格树数据
      nodeList: [],
      /** 系统模板装结构  1.0*/
      templateList: [],
      del_arr: [],
      // 弹出层标题
      title: "",
      // 手机预览弹出层标题
      phoneTitle: "",
      // 是否显示弹出层
      uploadTitle: "",
      // 是否显示弹出层
      phoneOpen: false,
      // 是否显示弹出层
      uploadOpen: false,
      nodeTitleName: "",
      // 项目编辑下不允许修改
      showOrNot: false,
      brSource: false,
      clientInfo: {
        clientOptions: [],
        reportOptions: [],
        subReportOptions: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
    }),
  },
  created() {
    this.param.body.userNo = this.userNo;
    // 获取项目
    this.getProject();
  },
  methods: {
    // 元素类型格式化展示判断 元素类型(0:目录node 1:字段field 2:配置项linkage)
    judgeElementType(row) {
      // 一级目录
      if (row.elementType === 0) {
        return "一级目录";
      } else if (row.elementType === 1) {
        // 节点判断
        switch (row.fieldType) {
          case "input":
            return "输入框";
          case "select":
            return "下拉选择器";
          case "radio":
            return "单选框";
          case "checkbox":
            return "多选框";
          case "album":
            return "相册";
          default:
            break;
        }
      } else {
        // 配置项
        return `配置项${row.sortNumber}`;
      }
    },
    // 排序列展示判断
    judgeSort(row) {
      if (row.elementType === 2) {
        return false;
      } else {
        return true;
      }
    },
    // 复制展示判断
    judgeCopy(row) {
      if (row.elementType === 2) {
        return false;
      }
      return true;
    },
    // 新增展示判断
    judgeAdd(row) {
      // 一级目录,默认显示
      if (row.elementType === 0 && row.elementLevel === 1) {
        return true;
      }
      // 当节点为五角星时，不可新增
      if (row.elementType === 1 && row.displayChoice === "fivePointedStar") {
        return false;
      }
      // 节点,默认显示
      if (
        row.elementType === 1 &&
        row.fieldType !== "album" &&
        row.fieldType !== "input"
      ) {
        return true;
      }
      return false;
    },
    // 编辑展示判断
    judgeEdit(row) {
      // 当配置项为五角星时，不可编辑删除
      if (row.elementType === 2 && row.displayChoice === "fivePointedStar") {
        return false;
      }
      return true;
    },
    // 删除展示判断
    judgeDelete(row) {
      // 当配置项为五角星时，不可编辑删除
      if (row.elementType === 2 && row.displayChoice === "fivePointedStar") {
        return false;
      }
      return true;
    },
    // 联动展示判断
    judgeLinkage(row) {
      if (row.elementType === 2) {
        return true;
      }
      return false;
    },
    // 复制
    handleCopy(row) {
      this.$confirm(
        row.elementTitle.length > 0
          ? '是否确认复制名称为"' +
              row.elementTitle.substr(0, 100) +
              '..."的节点?'
          : '是否确认复制名称为"' + row.elementTitle + '"的节点?'
      )
        .then((_) => {
          this.copyForm.body.seqId = row.seqId;
          this.copyForm.body.userNo = this.userNo;
          this.copyForm.body.projectId = this.systemForm.body.projectId;
          copySystemData(this.copyForm).then((res) => {
            if (res.header.retCode === "1") {
              this.$message({
                type: "success",
                message: `${res.header.retMessage}`,
              });
              this.fetchData();
            } else {
              this.$message.error(`${res.header.retMessage}`);
            }
          });
        })
        .catch((_) => {});
    },
    // 联动
    handleLinkage(row) {
      // 打开节点配置对话框
      this.$refs.formOption.linkage(
        Object.assign(row, { projectId: this.systemForm.body.projectId })
      );
    },
    // 新增一级目录
    addContent() {
      // 配置前限定用户选择项目操作
      if (this.addSystemTemplateForm.projectNo === "") {
        this.$message.warning(`请选择项目后，添加表单`);
        return false;
      }
      let obj = {
        projectId: this.systemForm.body.projectId,
        userNo: this.userNo,
      };
      // 接受项目id
      this.$refs.formInfo.showInfoAdd(obj);
    },
    // 新增节点 或 配置项
    handleAdd(row) {
      // 新增节点
      if (row.elementType === 0 && row.elementLevel === 1) {
        // 打开节点配置对话框
        this.$refs.formOption.showOptionAdd(
          Object.assign(row, { projectId: this.systemForm.body.projectId })
        );
        return;
      }
      // 新增配置项
      if (row.elementType === 1) {
        // 新增配置项参数
        // 即在行信息为节点时添加，希望添加至此下级，故level+1
        this.addForm.body.elementLevel = row.elementLevel + 1;
        this.addForm.body.parentId = row.seqId;
        this.addForm.body.userNo = this.userNo;
        this.addForm.body.projectId = this.systemForm.body.projectId;
        // 打开配置项配置对话框
        this.optionVisivle = true;
        // 配置项对话框新增flag 如果为true 则为新增，否则为编辑
        this.optionFlag = true;
        return;
      }
    },
    // 修改按钮操作
    handleUpdate(row) {
      // 一级目录编辑
      if (row.elementType === 0) {
        this.$refs.formInfo.showInfoEdit(row, this.systemForm.body.projectId);
      }
      // 节点编辑
      if (row.elementType === 1) {
        this.$refs.formOption.showOptionEdit(
          Object.assign(row, { projectId: this.systemForm.body.projectId })
        );
      }
      // 配置项编辑
      if (row.elementType === 2) {
        // 编辑配置项参数
        this.updateForm.body.elementLevel = row.elementLevel;
        this.optionForm.elementTitle = row.elementTitle;
        this.updateForm.body.parentId = row.parentId;
        this.updateForm.body.seqId = row.seqId;
        this.updateForm.body.userNo = this.userNo;
        this.updateForm.body.projectId = this.systemForm.body.projectId;
        // 打开配置项配置对话框
        this.optionVisivle = true;
        // 配置项对话框新增flag 如果为true 则为新增，否则为编辑
        this.optionFlag = false;
      }
    },
    // 导出按钮操作
    async handleDownload() {
      // 配置前限定用户选择项目操作
      if (this.addSystemTemplateForm.projectNo === "") {
        this.$message.warning(`请选择项目后，再导出`);
        return false;
      }
      // 是否存在模板配置
      if (this.templateList.length <= 0) {
        this.$message.warning(`请先添加表单`);
        return false;
      }
      downloadReport(
        `/site/form/exportTemplate?projectId=${this.systemForm.body.projectId}`
      );
    },
    /** 导入 */
    uploadFun() {
      this.$refs["addSystemTemplateForm"].validate(async (valid) => {
        if (valid) {
          this.uploadData.userNo = this.userNo;
          this.uploadData.projectNo = this.addSystemTemplateForm.projectNo;
          this.openUploadSource = true;
        }
      });
    },
    // 超出限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 导入
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "txt";
      if (!extension) {
        this.$message({
          message: "上传文件只能是 txt格式!",
          type: "warning",
        });
        return false;
      }
      return extension;
    },
    // 导入成功的回调
    handleSuccess(res, file) {
      if (res.header.retCode == "1") {
        /** seqID */
        this.fileList = [];
        this.openUploadSource = false;
        if (res.body === "") {
          this.$message.error(`后端未成功导入`);
        } else {
          this.$message({
            message: `导入成功`,
            type: "success",
          });
          this.fetchData();
        }
      } else {
        this.fileList = [];
        this.$message.error(`上传错误,错误信息: ${res.header.retMessage}`);
      }
    },
    /** 排序 */
    order(row, flag) {
      this.sortForm.body.parentId = row.parentId;
      this.sortForm.body.projectId = this.systemForm.body.projectId;
      this.sortForm.body.seqId = row.seqId;
      this.sortForm.body.sortNumber = row.sortNumber;
      this.sortForm.body.userNo = this.userNo;

      if (row.id && 0 === flag) {
        this.$baseConfirm("你确定要升序吗", null, async () => {
          // 排序标志
          this.sortForm.body.sortSign = "asc";
          const { header } = await sortSystemData(this.sortForm);
          if (header.retCode === "1") {
            this.$baseMessage(header.retMessage, "success");
            await this.fetchData();
          } else {
            this.$message.error(`${header.retMessage}`);
          }
        });
      } else {
        this.$baseConfirm("你确定要降序吗", null, async () => {
          // 排序标志
          this.sortForm.body.sortSign = "desc";
          const { header } = await sortSystemData(this.sortForm);
          if (header.retCode === "1") {
            this.$baseMessage(header.retMessage, "success");
            await this.fetchData();
          } else {
            this.$message.error(`${header.retMessage}`);
          }
        });
      }
    },
    handleError(err) {
      this.$message.error(`上传错误,错误信息 ${err}`);
    },
    // 导入对话框关闭
    handleImportClose() {
      this.fileList = [];
      this.openUploadSource = false;
    },
    // 配置项对话框关闭
    handleOptionClose() {
      this.$refs.optionForm.resetFields();
      this.optionVisivle = false;
    },
    // 配置项对话框确认
    handleOptionCertain() {
      this.$refs["optionForm"].validate((valid) => {
        if (valid) {
          this.addForm.body.elementTitle = this.optionForm.elementTitle;
          this.updateForm.body.elementTitle = this.optionForm.elementTitle;
          // 新增
          if (this.optionFlag) {
            addSystemData(this.addForm).then((res) => {
              if (res.header.retCode === "1") {
                this.$message({
                  type: "success",
                  message: `${res.header.retMessage}`,
                });
                this.optionVisivle = false;
                this.$refs.optionForm.resetFields();
                this.fetchData();
              } else {
                this.$message.error(`${res.header.retMessage}`);
              }
            });
          } else {
            // 配置项编辑
            updateSystemData(this.updateForm).then((res) => {
              if (res.header.retCode === "1") {
                this.$message({
                  type: "success",
                  message: `${res.header.retMessage}`,
                });
                this.optionVisivle = false;
                this.$refs.optionForm.resetFields();
                this.fetchData();
              } else {
                this.$message.error(`${res.header.retMessage}`);
              }
            });
          }
        }
      });
    },
    // 获取项目
    getProject() {
      this.projectForm.header.currentTime = returnBitTimestamp(new Date());
      getProjectSource(this.projectForm).then((response) => {
        if (response.header.retCode == "1") {
          this.projectInfo = response.body;
        }
      });
    },
    // 选择项目
    async handleProjectChange(value) {
      if (value.length == 0) {
        this.addSystemTemplateForm.projectNo = "";
        this.resetSouce();
      } else {
        this.updateSouce();
        this.addSystemTemplateForm.projectNo = value[1];
        this.documentForm.body.projectNo = value[1];
        const { header, body } = await searchDocument(this.documentForm);
        if (header.retCode === "1") {
          this.addSystemTemplateForm.fileName = body.fileName;
          this.addSystemTemplateForm.fileSeqId = body.fileSeqId;
          this.addSystemTemplateForm.projectId = body.projectId;
        } else {
          this.addSystemTemplateForm.fileName = "";
          this.addSystemTemplateForm.fileSeqId = "";
          this.$message.error(`${header.retMessage}`);
        }
        this.systemForm.body.projectId = value[1];
        await this.fetchData();
      }
    },
    resetSouce() {
      this.brSource = "";
      this.addSystemTemplateForm.client = "";
      this.addSystemTemplateForm.reportType = "";
      this.addSystemTemplateForm.subReportType = "";
      this.clientInfo = {
        clientOptions: [],
        reportOptions: [],
        subReportOptions: [],
      };
    },
    updateSouce() {
      this.resetSouce();
      const pathNodes = this.$refs["refProject"].getCheckedNodes()[0].pathNodes;
      const source = pathNodes[0].data.source;
      this.brSource = source === "process_br";
      if (this.brSource) {
        this.getClientInfo();
      }
    },
    // 获取项目系统模板
    async fetchData() {
      // 获取系统模板 1.0
      this.systemForm.body.userNo = this.userNo;
      this.templateLoading = true;
      getSystemData(this.systemForm).then((res) => {
        if (res.header.retCode === "1") {
          this.templateList = res.body;
          this.templateLoading = false;
        } else {
          this.templateLoading = false;
          this.$message.error(`${res.header.retMessage}`);
        }
      });
    },
    async showAdd(seqId) {
      this.title = !seqId ? "新增系统模板" : "编辑系统模板";
      this.showOrNot = !seqId ? false : true;
      this.flag = "add";
      if (seqId) {
        this.flag = "edit";
        this.param.header.currentTime = returnBitTimestamp(new Date());
        this.param.body.seqId = seqId;
        this.addSystemTemplateForm.seqId = seqId;
        getEditTemplate(this.param).then(async (res) => {
          if (res.header.retCode === "1") {
            this.addSystemTemplateForm.config = res.body.config;
            this.addSystemTemplateForm.description = res.body.description;
            this.addSystemTemplateForm.name = res.body.name;
            this.addSystemTemplateForm.fileSeqId = res.body.fileSeqId;
            this.addSystemTemplateForm.fileName = res.body.fileName;
            this.addSystemTemplateForm.projectNo = res.body.projectNo;
            // 增加字段（委托方，报告类型）
            this.addSystemTemplateForm.client = res.body.client;
            // this.addSystemTemplateForm.reportType = res.body.reportType
            // this.addSystemTemplateForm.subReportType = res.body.subReportType

            this.systemForm.body.projectId = res.body.projectNo;
            // 刷新树形结构
            this.fetchData();
            if (res.body.projectSource === "process_br") {
              this.brSource = true;
              getClientReportType().then(async (response) => {
                if (response.code === 200) {
                  this.clientInfo.clientOptions = response.data;
                  if (res.body.client) {
                    this.filterReport(res.body.client);
                    this.addSystemTemplateForm.reportType = res.body.reportType;
                  }
                  if (res.body.reportType) {
                    this.filterSubReport(res.body.reportType);
                    this.addSystemTemplateForm.subReportType =
                      res.body.subReportType;
                  }
                }
              });
            }
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      }
      this.dialogFormVisible = true;
    },
    close() {
      // 调用接口清空元素数据
      if (this.addSystemTemplateForm.projectNo) {
        emptyElements({
          projectId: this.addSystemTemplateForm.projectNo,
        }).then((res) => {
          if (res.header.retCode === "1") {
            this.dialogFormVisible = false;
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      }
      this.$refs["addSystemTemplateForm"].resetFields();
      this.addSystemTemplateForm = this.$options.data().addSystemTemplateForm;
      this.nodeList = [];
      this.templateList = [];
      this.resetSouce();
    },
    // 新增模板
    save() {
      this.$refs["addSystemTemplateForm"].validate(async (valid) => {
        if (valid) {
          if (this.templateList.length !== 0) {
            this.addSystemTemplateForm.userNo = this.userNo;
            this.certainLoading = true;
            // 当前系统时间
            this.addSystemTemplatePortForm.header.currentTime =
              returnBitTimestamp(new Date());
            Object.assign(
              this.addSystemTemplatePortForm.body,
              this.addSystemTemplateForm
            );
            if (this.flag == "add") {
              addSystemTemplate(this.addSystemTemplatePortForm).then((res) => {
                if (res.header.retCode === "1") {
                  this.$message({
                    message: `${res.header.retMessage}`,
                    type: "success",
                  });
                  this.$emit("fetchData");
                } else {
                  this.$message.error(`${res.header.retMessage}`);
                }
                this.dialogFormVisible = false;
                this.certainLoading = false;
              });
            } else {
              if (!this.clientInfo.subReportOptions.length) {
                this.addSystemTemplatePortForm.subReportType = "";
              }
              eEditTemplateSave(this.addSystemTemplatePortForm).then((res) => {
                if (res.header.retCode === "1") {
                  this.$message({
                    message: `${res.header.retMessage}`,
                    type: "success",
                  });
                  this.$emit("fetchData");
                } else {
                  this.$message.error(`${res.header.retMessage}`);
                }
                this.certainLoading = false;
                this.dialogFormVisible = false;
              });
            }
          } else {
            this.$message.error("请配置相应表单");
          }
        } else {
          return false;
        }
      });
    },
    // 取消按钮
    getBack(device) {
      this.uploadOpen = false;
    },
    // 打开手机预览
    previewPhone() {
      if (this.templateList.length !== 0) {
        // 当前系统时间
        this.addSystemTemplatePortForm.header.currentTime = returnBitTimestamp(
          new Date()
        );
        this.addSystemTemplateForm.userNo = this.userNo;
        let _obj = { config: this.addSystemTemplateForm.config };
        Object.assign(this.addSystemTemplatePortForm.body, _obj);
        previewTemplate(this.addSystemTemplatePortForm).then((res) => {
          if (res.header.retCode === "1") {
            this.$refs["phonePreview"].preview(res.body);
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      } else {
        this.$message.error("请先添加表单");
      }
    },
    webPhone() {
      if (this.templateList.length !== 0) {
        // 当前系统时间
        this.addSystemTemplatePortForm.header.currentTime = returnBitTimestamp(
          new Date()
        );

        this.addSystemTemplatePortForm.body.projectId =
          this.addSystemTemplateForm.projectNo;
        this.addSystemTemplatePortForm.body.userNo = this.userNo;
        webTemplate(this.addSystemTemplatePortForm).then((res) => {
          if (res.header.retCode === "1") {
            this.$refs["webPreviewOne"].preview(res.body);
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      } else {
        this.$message.error("请先添加表单");
      }
    },
    /** 删除按钮操作 */
    async handleDelete(row) {
      this.del_arr = [];
      this.$confirm(
        row.elementTitle.length > 100
          ? '是否确认删除名称为"' +
              row.elementTitle.substr(0, 100) +
              '..."的节点?'
          : '是否确认删除名称为"' + row.elementTitle + '"的节点?'
      )
        .then((_) => {
          this.delForm.body.seqId = row.seqId;
          this.delForm.body.userNo = this.userNo;
          delSystemData(this.delForm).then((res) => {
            if (res.header.retCode === "1") {
              this.$message({
                type: "success",
                message: `${res.header.retMessage}`,
              });
              this.fetchData();
            } else {
              this.$message.error(`${res.header.retMessage}`);
            }
          });
        })
        .catch((_) => {});
    },
    // 获取委托方报告类型
    async getClientInfo() {
      getClientReportType().then(async (response) => {
        if (response.code === 200) {
          this.clientInfo.clientOptions = response.data;
        }
      });
    },
    // 委托方联动报告类型
    filterReport(val) {
      this.addSystemTemplateForm.reportType = "";
      this.addSystemTemplateForm.subReportType = "";
      this.clientInfo.reportOptions = [];
      this.clientInfo.subReportOptions = [];
      if (val) {
        this.clientInfo.reportOptions = this.clientInfo.clientOptions.filter(
          (item) => item.customer === val
        )[0].OrderType;
      }
    },
    // 报告类型联动二级类型
    filterSubReport(val) {
      this.addSystemTemplateForm.subReportType = "";
      this.clientInfo.subReportOptions = [];
      if (val) {
        this.clientInfo.subReportOptions = this.clientInfo.reportOptions.filter(
          (item) => item.orderType_After === val
        )[0].subOrderTypeList;
      }
    },
    filterTip() {
      if (this.addSystemTemplateForm.client === "") {
        this.$message({
          type: "warn",
          message: "请先输入委托方",
        });
      }
    },
  },
};
</script>
<style scoped>
.el-col {
  margin-bottom: 10px;
}
html body .el-dialog__body .el-form,
html body .el-message-box__body .el-form {
  padding-right: 0;
}
.phone {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-height: 550px;
}
.phone .el-row {
  border-bottom: 1px solid #dcdfe6;
  padding: 10px 10px 0 10px;
}
.phone .el-row .el-col:last-child {
  text-align: right;
}
.el-icon-upload {
  display: flex;
  justify-content: center;
  font-size: 70px;
  color: #ccc;
}
</style>
