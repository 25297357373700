<template>
  <el-dialog
    :title="title"
    class="myDialog"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="60%"
    @close="close"
  >
    <el-backtop target=".myDialog"></el-backtop>
    <!--检索模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-form
          ref="addSystemTemplateForm"
          :model="addSystemTemplateForm"
          :rules="addSystemTemplateFormRules"
          class="demo-form-inline"
        >
          <!--基础信息-->
          <label style="margin-bottom: 20px; display: block">基础信息</label>
          <el-row :gutter="40">
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="系统模板名称" prop="name">
                <el-input
                  v-model="addSystemTemplateForm.name"
                  placeholder="请填写模板名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="项目" prop="projectNo">
                <el-cascader
                  :value="addSystemTemplateForm.projectNo"
                  :options="projectInfo"
                  :clearable="true"
                  :filterable="true"
                  separator=":"
                  placeholder="请选择项目"
                  @change="handleProjectChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item label="文档模板名称" prop="fileName">
                <el-input
                  v-model="addSystemTemplateForm.fileName"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
              <el-form-item label="系统模板描述" prop="description">
                <el-input
                  v-model="addSystemTemplateForm.description"
                  type="textarea"
                  placeholder="请输入描述信息"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!--配置内容-->
          <vab-query-form>
            <vab-query-form-left-panel>
              <label>配置内容</label>
              <el-button
                plain
                icon="el-icon-mobile-phone"
                @click="previewPhone"
              >
                预览
              </el-button>
            </vab-query-form-left-panel>
            <vab-query-form-right-panel>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-upload2"
                @click="uploadFun"
              >
                导入
              </el-button>
              <el-button
                v-if="flag === 'edit'"
                icon="el-icon-download"
                plain
                @click="handleDownload"
              >
                导出
              </el-button>
              <el-button
                icon="el-icon-plus"
                type="primary"
                @click="handleAdd('content')"
              >
                添加表单
              </el-button>
            </vab-query-form-right-panel>
          </vab-query-form>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <!-- :data="nodeList" -->
              <el-table
                :data="dataList"
                row-key="menuId"
                node-key="menuId"
                :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }"
              >
                <el-table-column
                  prop="nodeTitle"
                  label="表单及元素名称"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                  width="220"
                  class-name="small-padding fixed-width"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-edit"
                      @click="handleUpdate(scope.row)"
                    >
                      编辑
                    </el-button>
                    <el-button
                      v-if="!scope.row.childNodeTitle"
                      size="mini"
                      type="text"
                      icon="el-icon-plus"
                      @click="handleAdd(scope.row)"
                    >
                      新增
                    </el-button>
                    <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-delete"
                      @click="handleDelete(scope.row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <!-- 添加或修改目录对话框 -->
              <el-dialog
                :title="subTitle"
                :visible.sync="open"
                width="55%"
                :close-on-click-modal="false"
                append-to-body
                :before-close="cancel"
              >
                <el-form
                  ref="contentForm"
                  :model="contentForm"
                  :rules="contentFormRules"
                  label-width="80px"
                  size="mini"
                  style="padding: 10px"
                >
                  <el-row>
                    <el-col :span="24">
                      <el-form-item
                        prop="nodeTitle"
                        :label="nodeTitleName"
                        :rules="[
                          {
                            required: true,
                            message: '请输入表单',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="contentForm.nodeTitle"
                          placeholder="请输入表单"
                          :disabled="nodeTitleDisable"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="nodeTitleName === '表单名称'" :span="24">
                      <el-form-item prop="textAlign" label="表单对齐">
                        <el-select
                          v-model="contentForm.textAlign"
                          placeholder="请选择"
                          :disabled="nodeTitleDisable"
                          filterable
                        >
                          <el-option
                            v-for="item in alignOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="nodeTitleName === '表单名称'" :span="24">
                      <el-form-item prop="backgroundColor" label="背景色">
                        <el-color-picker
                          v-model="contentForm.backgroundColor"
                          show-alpha
                          :disabled="nodeTitleDisable"
                        ></el-color-picker>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="nodeTitleName === '表单名称'" :span="24">
                      <el-form-item prop="spanColor" label="字体颜色">
                        <el-color-picker
                          v-model="contentForm.spanColor"
                          show-alpha
                          :disabled="nodeTitleDisable"
                        ></el-color-picker>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="childrenNode_show == true" :span="24">
                      <el-form-item
                        prop="childNodeTitle"
                        label="表单元素"
                        :rules="[
                          {
                            required: true,
                            message: '请输入表单元素',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="contentForm.childNodeTitle"
                          placeholder="请输入表单元素名称"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col v-if="add_children_type == true" :span="24">
                      <el-form-item label="数据类型" prop="type">
                        <el-radio-group
                          v-model="contentForm.type"
                          @change="dataChange"
                        >
                          <el-radio label="input">输入框</el-radio>
                          <el-radio label="select">下拉选择器</el-radio>
                          <el-radio label="radio">单选框</el-radio>
                          <el-radio label="checkbox">多选框</el-radio>
                          <el-radio label="album">相册</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div v-if="add_children_type == true">
                    <!-- 输入框 -->
                    <el-row>
                      <el-col v-if="contentForm.type === 'input'" :span="12">
                        <!-- 是否必填 -->
                        <el-form-item label="是否必填" prop="required">
                          <el-select
                            v-model="contentForm.required"
                            placeholder="请选择"
                            filterable
                          >
                            <el-option
                              v-for="item in requireOption"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type === 'input'" :span="12">
                        <el-form-item label="类型" prop="propType">
                          <el-select
                            v-model="contentForm.propType"
                            placeholder="请选择"
                            filterable
                          >
                            <el-option
                              v-for="item in inputTypeOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type === 'input'" :span="12">
                        <el-form-item label="字段名称" prop="title">
                          <el-input
                            v-model="contentForm.title"
                            placeholder="请输入字段名称"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type === 'input'" :span="12">
                        <el-form-item prop="field" label="英文名称">
                          <el-input
                            v-model.trim="contentForm.field"
                            placeholder="请输入英文名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type === 'input'" :span="24">
                        <el-form-item label="字段值" prop="value">
                          <el-input
                            v-model="contentForm.value"
                            placeholder="请输入字段值"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col
                        v-if="
                          contentForm.type === 'input' &&
                          contentForm.required == '1'
                        "
                        :span="12"
                      >
                        <el-form-item label="触发校验" prop="trigger">
                          <el-select
                            v-model="contentForm.trigger"
                            placeholder="请选择"
                            filterable
                          >
                            <el-option
                              v-for="item in triggerOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col
                        v-if="
                          contentForm.type == 'input' &&
                          contentForm.required == '1'
                        "
                        :span="12"
                      >
                        <el-form-item label="提示语" prop="message">
                          <el-input
                            v-model="contentForm.message"
                            placeholder="请输入提示语"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 选择器 -->
                    <el-row>
                      <el-col v-if="contentForm.type == 'select'" :span="12">
                        <el-form-item prop="title" label="字段名称">
                          <el-input
                            v-model="contentForm.title"
                            placeholder="请输入字段名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'select'" :span="12">
                        <el-form-item prop="field" label="英文名称">
                          <el-input
                            v-model.trim="contentForm.field"
                            placeholder="请输入英文名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'select'" :span="12">
                        <el-form-item label="类型" prop="propType">
                          <el-select
                            v-model="contentForm.propType"
                            placeholder="请选择"
                            filterable
                          >
                            <el-option
                              v-for="item in selectTypeOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'select'" :span="12">
                        <el-form-item label="可清空" prop="clearable">
                          <el-select
                            v-model="contentForm.clearable"
                            placeholder="请选择"
                            filterable
                          >
                            <el-option
                              v-for="item in clearableOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'select'" :span="24">
                        <el-form-item
                          v-for="(
                            selectItem, selectIndex
                          ) in contentForm.options"
                          :key="selectItem.selectIndex"
                          :label="'配置项' + selectIndex"
                          :prop="'options.' + selectIndex + '.label'"
                          :rules="{
                            required: true,
                            message: '配置项不能为空',
                            trigger: 'blur',
                          }"
                        >
                          <!-- :prop="contentForm.options[selectIndex].value" -->
                          <el-input
                            v-model="selectItem.label"
                            placeholder="请输入需要生成的配置项"
                          ></el-input>
                          <el-button
                            v-if="selectIndex != 0"
                            @click.prevent="removeOption(selectItem)"
                          >
                            删除
                          </el-button>
                        </el-form-item>
                        <el-form-item>
                          <el-button @click="addOption">新增配置项</el-button>
                        </el-form-item>
                      </el-col>
                      <el-col
                        v-if="
                          contentForm.type === 'select' &&
                          contentForm.required == '1'
                        "
                        :span="12"
                      >
                        <el-form-item label="触发校验" prop="trigger">
                          <el-select
                            v-model="contentForm.trigger"
                            placeholder="请选择"
                            filterable
                          >
                            <el-option
                              v-for="item in triggerOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col
                        v-if="
                          contentForm.type == 'select' &&
                          contentForm.required == '1'
                        "
                        :span="12"
                      >
                        <el-form-item label="提示语" prop="message">
                          <el-input
                            v-model="contentForm.message"
                            placeholder="请输入提示语"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 单选框 -->
                    <el-row>
                      <el-col v-if="contentForm.type == 'radio'" :span="12">
                        <el-form-item prop="title" label="字段名称">
                          <el-input
                            v-model="contentForm.title"
                            placeholder="请输入字段名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'radio'" :span="12">
                        <el-form-item prop="field" label="英文名称">
                          <el-input
                            v-model.trim="contentForm.field"
                            placeholder="请输入字段英文名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'radio'" :span="24">
                        <el-form-item
                          v-for="(radioItem, radioIndex) in contentForm.options"
                          :key="radioItem.radioIndex"
                          :label="'配置项' + radioIndex"
                          :prop="'options.' + radioIndex + '.label'"
                          :rules="{
                            required: true,
                            message: '配置项不能为空',
                            trigger: 'blur',
                          }"
                        >
                          <el-input
                            v-model="radioItem.label"
                            placeholder="请输入需要生成的配置项"
                          ></el-input>
                          <el-button
                            v-if="radioIndex != 0"
                            @click.prevent="removeOption(radioItem)"
                          >
                            删除
                          </el-button>
                        </el-form-item>
                        <el-form-item>
                          <el-button @click="addOption">新增配置项</el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 多选框 -->
                    <el-row>
                      <el-col v-if="contentForm.type == 'checkbox'" :span="12">
                        <el-form-item prop="title" label="字段名称">
                          <el-input
                            v-model="contentForm.title"
                            placeholder="请输入字段名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'checkbox'" :span="12">
                        <el-form-item prop="field" label="英文名称">
                          <el-input
                            v-model.trim="contentForm.field"
                            placeholder="请输入字段英文名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type == 'checkbox'" :span="24">
                        <el-form-item
                          v-for="(
                            checkboxItem, checkboxIndex
                          ) in contentForm.options"
                          :key="checkboxItem.checkboxIndex"
                          :label="'配置项' + checkboxIndex"
                          :prop="'options.' + checkboxIndex + '.label'"
                          :rules="{
                            required: true,
                            message: '配置项不能为空',
                            trigger: 'blur',
                          }"
                        >
                          <el-input
                            v-model="checkboxItem.label"
                            placeholder="请输入需要生成的配置项"
                          ></el-input>
                          <el-button
                            v-if="checkboxIndex != 0"
                            @click.prevent="removeOption(checkboxItem)"
                          >
                            删除
                          </el-button>
                        </el-form-item>
                        <el-form-item>
                          <el-button @click="addOption">新增配置项</el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 相册 -->
                    <el-row>
                      <el-col v-if="contentForm.type === 'album'" :span="12">
                        <el-form-item prop="title" label="字段名称">
                          <el-input
                            v-model="contentForm.title"
                            placeholder="请输入字段名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col v-if="contentForm.type === 'album'" :span="12">
                        <el-form-item prop="field" label="英文名称">
                          <el-input
                            v-model.trim="contentForm.field"
                            placeholder="请输入英文名称"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="cancel">取 消</el-button>
                  <el-button type="primary" @click="submitForm">确定</el-button>
                </div>
              </el-dialog>
              <!--WEB预览-->
              <el-dialog
                :title="webTitle"
                :visible.sync="webOpen"
                width="1000px"
                append-to-body
              >
                <div class="phone">
                  <el-card>
                    <el-button type="primary" style="width: 100%" size="large">
                      信息核实
                    </el-button>
                    <web-preview></web-preview>
                  </el-card>
                  <el-card>
                    <el-button type="primary" style="width: 100%" size="large">
                      信息核实
                    </el-button>
                    <web-preview></web-preview>
                  </el-card>
                </div>

                <div slot="footer" class="dialog-footer">
                  <el-button @click="getBack('webOpen')">返 回</el-button>
                </div>
              </el-dialog>

              <!--导入模板-->
              <el-dialog
                :title="uploadTitle"
                :visible.sync="uploadOpen"
                width="600px"
                append-to-body
              >
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  multiple
                  align="center"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件，且不超过500kb
                  </div>
                </el-upload>

                <div slot="footer" class="dialog-footer">
                  <el-button @click="getBack('uploadOpen')">返 回</el-button>
                </div>
              </el-dialog>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- 手机预览 -->
    <phone-preview ref="phonePreview"></phone-preview>
    <!--按钮组-->
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="certainLoading" @click="save">
        确定
      </el-button>
    </div>
    <!-- 批量上传资源对话框 -->
    <el-dialog
      title="上传资源"
      :visible.sync="openUploadSource"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      append-to-body
    >
      <el-card class="box-card">
        <el-row align="middle" type="flex" :gutter="40">
          <el-col :span="3"><i class="el-icon-upload"></i></el-col>
          <el-col :span="21">
            <h3>请您上传资源</h3>
            <div>
              <p
                style="
                  font-family: '微软雅黑';
                  font-weight: 400;
                  text-decoration: none;
                  color: #999999;
                "
              >
                请上传导入文件，文件后缀名必须为txt
              </p>
              <el-upload
                ref="uploadTemplate"
                class="upload-demo"
                :action="uploadUrl"
                :data="uploadData"
                :headers="headers"
                :on-success="handleSuccess"
                :on-error="handleError"
                :before-upload="beforeUpload"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <el-link
                  ref="uploadBtn"
                  type="primary"
                  :underline="false"
                  style="margin-top: 30px"
                >
                  上传文件
                </el-link>
              </el-upload>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button ref="nextStep" type="primary" disabled @click="handleClose">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import phonePreview from "./phonePreview";
  import {
    addSystemTemplate,
    getSystemTemplate,
    previewTemplate,
    getTemplateFile,
    getEditTemplate,
    eEditTemplateSave,
    searchDocument,
  } from "@/api/project/manage";
  import { mapGetters } from "vuex";
  // 获取工具API
  import { getProjectSource } from "@/api/workplace/tools";
  import store from "@/store";
  import {
    returnBitTimestamp,
    translateTemplateTree,
    downloadReport,
  } from "@/utils/index";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";

  export default {
    name: "SystemModelAdd",
    components: { phonePreview },
    data() {
      return {
        // 防抖
        certainLoading: false,
        // 新增或编辑 "add"/"edit"
        operaType: "",
        // 是否为表单
        add_children_type: false,
        childrenNode_show: false,
        nodeTitleDisable: false,
        tmpParentNum: 1,
        tmpChildNum: 1,
        tmpID: undefined,
        projectInfo: [], // 项目
        /** 导入 */
        fileList: [],
        openUploadSource: false,
        uploadUrl: "/site/template/import",
        uploadData: {
          userNo: "",
          userRoleNo: "commonRole",
          fileSeqId: "",
          name: "",
          projectNo: "",
          description: "",
        },
        headers: {
          Authorization: "Bearer " + store.getters["user/accessToken"],
        },
        projectForm: {
          body: {
            source: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        fileName: "",
        /**documentForm */
        documentForm: {
          body: {
            projectNo: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        contentForm: {
          menuId: 1,
          nodeTitle: "",
          parentId: 0,
          children: [],
          childNodeTitle: "",
          // dom类型
          backgroundColor: "rgba(0, 93, 202, 1)",
          spanColor: "#fff",
          textAlign: "center",
          type: "",
          // 元素类型
          title: "",
          value: "",
          required: "",
          // 元素属性
          propType: "",
          disabled: "",
          trigger: "",
          // readonly: "",
          message: "",
          field: "",
          clearable: "",
          // 配置项
          options: [
            {
              value: "",
              label: "",
            },
          ],
        },
        param: {
          body: {
            seqId: "",
            userNo: "",
            userRoleNo: "commonRole",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        menuIdArr: [],
        contentFormRules: {
          trigger: [
            {
              required: true,
              trigger: "blur",
              message: "请务必选择需要配置的校验规则",
            },
          ],
          message: [
            {
              required: true,
              trigger: "blur",
              message: "请务必填写校验规则提示信息",
            },
          ],
          type: [
            {
              required: true,
              trigger: "blur",
              message: "请务必选择需要配置的元素",
            },
          ],
          required: [
            {
              required: true,
              trigger: "change",
              message: "请务必选择是否必填",
            },
          ],
          title: [
            {
              required: true,
              trigger: "blur",
              message: "请务必输入字段名称",
            },
          ],
          propType: [
            {
              required: true,
              trigger: "change",
              message: "请务必选择需要的元素类型",
            },
          ],
          field: [
            {
              required: true,
              trigger: "blur",
              message: "请务必输入英文名称",
            },
          ],
        },
        addSystemTemplateForm: {
          config: [],
          description: "",
          fileName: "",
          fileSeqId: "",
          name: "",
          projectNo: "",
          seqId: "",
          nextUserNo: "",
          nextUserRoleNo: "",
          userNo: "",
          userRoleNo: "commonRole",
        },
        addSystemTemplatePortForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        addSystemTemplateFormRules: {
          name: [
            { required: true, trigger: "blur", message: "请输入模板名称" },
          ],
          fileName: [
            {
              required: true,
              trigger: "blur",
              message: "文档模板名称不能为空",
            },
          ],
          projectNo: [
            { required: true, trigger: "change", message: "请选择项目" },
          ],
        },
        alignOption: [
          {
            value: "center",
            label: "居中",
          },
          {
            value: "left",
            label: "居左",
          },
          {
            value: "right",
            label: "居右",
          },
        ],
        dialogFormVisible: false,
        docTemplateOptions: [],
        flag: null,
        // 菜单表格树数据
        nodeList: [],
        dataList: [],
        del_arr: [],
        // 弹出层标题
        title: "",
        // 子弹出层标题
        subTitle: "",
        // 手机预览弹出层标题
        phoneTitle: "",
        // WEB预览弹出层标题
        webTitle: "",
        // 是否显示弹出层
        uploadTitle: "",
        // 是否显示弹出层
        open: false,
        // 是否显示弹出层
        phoneOpen: false,
        // 是否显示弹出层
        webOpen: false,
        // 是否显示弹出层
        uploadOpen: false,
        requireOption: [
          {
            value: "0",
            label: "否",
          },
          {
            value: "1",
            label: "是",
          },
        ],
        disableOption: [
          {
            value: "0",
            label: "否",
          },
          {
            value: "1",
            label: "是",
          },
        ],
        inputTypeOptions: [
          {
            value: "text",
            label: "单行文本框",
          },
          {
            value: "textarea",
            label: "多行文本框",
          },
        ],
        selectTypeOptions: [
          {
            value: false,
            label: "单选选择器",
          },
          {
            value: true,
            label: "多选选择器",
          },
        ],
        clearableOptions: [
          {
            value: "0",
            label: "否",
          },
          {
            value: "1",
            label: "是",
          },
        ],
        readOnlyOptions: [
          {
            value: "0",
            label: "否",
          },
          {
            value: "1",
            label: "是",
          },
        ],
        sizeOptions: [
          {
            value: "mini",
            label: "mini",
          },
          {
            value: "small",
            label: "small",
          },
          {
            value: "medium",
            label: "medium",
          },
        ],
        triggerOptions: [
          {
            value: "blur",
            label: "失去焦点",
          },
          {
            value: "focus",
            label: "获得焦点",
          },
          {
            value: "change",
            label: "内容被改变",
          },
        ],
        nodeTitleName: "",
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.param.body.userNo = this.userNo;
      // 获取项目
      this.getProject();
    },
    methods: {
      // 导出按钮操作
      async handleDownload() {
        downloadReport(
          `/site/template/export?seqId=${this.addSystemTemplateForm.seqId}`
        );
        // window.open(
        //   `http://192.168.11.18:8081/site/template/export?seqId=${this.addSystemTemplateForm.seqId}`,
        //   "_blank"
        // );

        // http://192.168.11.18:8081/site/export?seqId=6716044597312753664
      },
      /** 导入 */
      uploadFun() {
        this.$refs["addSystemTemplateForm"].validate(async (valid) => {
          if (valid) {
            this.uploadData.userNo = this.userNo;
            this.uploadData.fileSeqId = this.addSystemTemplateForm.fileSeqId;
            this.uploadData.name = this.addSystemTemplateForm.name;
            this.uploadData.projectNo = this.addSystemTemplateForm.projectNo;
            this.uploadData.description = this.addSystemTemplateForm.description;
            this.openUploadSource = true;
          }
        });
      },
      // 超出限制时的钩子
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
            files.length + fileList.length
          } 个文件`
        );
      },
      // 导入
      beforeUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extension = testmsg === "txt";
        // const expSize = file.size / 1024 / 1024 < 10;
        if (!extension) {
          this.$message({
            message: "上传文件只能是 txt格式!",
            type: "warning",
          });
          return false;
        }
        return extension;
      },
      /** 上传成功的回调 */
      handleSuccess(res, file) {
        if (res.header.retCode == "1") {
          /** seqID */
          this.fileList = [];
          this.openUploadSource = false;
          if (res.body === "") {
            this.$message.error(`后端未成功导入`);
          } else {
            this.$message({
              message: `导入成功`,
              type: "success",
            });
          }
          this.showAdd(res.body);
        } else {
          this.fileList = [];
          this.$message.error(`上传错误,错误信息: ${res.header.retMessage}`);
        }
      },
      handleError(err, file, fileList) {
        this.$message.error(`上传错误,错误信息 ${err}`);
      },
      handleClose() {
        this.fileList = [];
        this.openUploadSource = false;
      },
      // 获取项目
      getProject() {
        this.projectForm.header.currentTime = returnBitTimestamp(new Date());
        getProjectSource(this.projectForm).then((response) => {
          if (response.header.retCode == "1") {
            this.projectInfo = response.body;
          }
        });
      },
      // 选择项目
      async handleProjectChange(value) {
        if (value.length == 0) {
          this.addSystemTemplateForm.projectNo = "";
        } else {
          this.addSystemTemplateForm.projectNo = value[1];
          this.documentForm.body.projectNo = value[1];
          // this.documentForm.body.projectNo = "6715319770524291072";
          const { header, body } = await searchDocument(this.documentForm);
          if (header.retCode === "1") {
            this.addSystemTemplateForm.fileName = body.fileName;
            this.addSystemTemplateForm.fileSeqId = body.fileSeqId;
          } else {
            this.addSystemTemplateForm.fileName = "";
            this.addSystemTemplateForm.fileSeqId = "";
            this.$message.error(`${header.retMessage}`);
          }
        }
      },
      async showAdd(seqId) {
        this.title = !seqId ? "新增系统模板" : "编辑系统模板";
        this.getFile();
        this.flag = "add";
        if (seqId) {
          this.flag = "edit";
          this.param.header.currentTime = returnBitTimestamp(new Date());
          this.param.body.seqId = seqId;
          this.addSystemTemplateForm.seqId = seqId;
          getEditTemplate(this.param).then((res) => {
            if (res.header.retCode === "1") {
              this.addSystemTemplateForm.config = res.body.config;
              this.nodeList = translateTemplateTree(res.body.config);
              this.dataList = res.body.config;
              this.addSystemTemplateForm.description = res.body.description;
              this.addSystemTemplateForm.name = res.body.name;
              this.addSystemTemplateForm.fileSeqId = res.body.fileSeqId;
              this.addSystemTemplateForm.fileName = res.body.fileName;
              this.addSystemTemplateForm.projectNo = res.body.projectNo;
            } else {
              this.$message.error(`${res.header.retMessage}`);
            }
          });
        }
        this.dialogFormVisible = true;
      },
      getFile() {
        getTemplateFile().then((res) => {
          if (res.header.retCode === "1") {
            this.docTemplateOptions = res.body;
          } else {
            this.$message.error(`${response.header.retMessage}`);
          }
        });
      },
      close() {
        this.$refs["addSystemTemplateForm"].resetFields();
        this.addSystemTemplateForm = this.$options.data().addSystemTemplateForm;
        this.nodeList = [];
        this.dataList = [];
        this.tmpParentNum = 1;
        this.tmpChildNum = 1;
        this.dialogFormVisible = false;
        // this.$emit("fetchData");
      },
      /** radio切换清空数据 */
      dataChange(val) {
        this.contentForm.title = "";
        this.contentForm.value = "";
        this.contentForm.required = "";
        this.contentForm.propType = "";
        this.contentForm.disabled = "";
        this.contentForm.options = [
          {
            label: "",
          },
        ];
        this.contentForm.field = "";
        this.contentForm.clearable = "";
        this.contentForm.message = "";
        this.contentForm.trigger = "";
        // this.contentForm.readonly = "";
      },
      // 去掉配置项
      removeOption(item) {
        var index = this.contentForm.options.indexOf(item);
        if (index !== -1) {
          this.contentForm.options.splice(index, 1);
        }
      },
      // 添加配置项
      addOption() {
        this.contentForm.options.push({
          label: "",
        });
      },
      /** 转换菜单数据结构 */
      normalizer(node) {
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.menuId,
          label: node.menuName,
          children: node.children,
        };
      },
      // 新增模板
      save() {
        this.$refs["addSystemTemplateForm"].validate(async (valid) => {
          if (valid) {
            if (this.dataList.length !== 0) {
              this.addSystemTemplateForm.userNo = this.userNo;
              this.certainLoading = true;
              // 当前系统时间
              this.addSystemTemplatePortForm.header.currentTime = returnBitTimestamp(
                new Date()
              );
              Object.assign(
                this.addSystemTemplatePortForm.body,
                this.addSystemTemplateForm
              );
              if (this.flag == "add") {
                addSystemTemplate(this.addSystemTemplatePortForm).then(
                  (res) => {
                    if (res.header.retCode === "1") {
                      this.$message({
                        message: `${res.header.retMessage}`,
                        type: "success",
                      });
                      this.$emit("fetchData");
                    } else {
                      this.$message.error(`${res.header.retMessage}`);
                    }
                    this.dialogFormVisible = false;
                    this.certainLoading = false;
                  }
                );
              } else {
                eEditTemplateSave(this.addSystemTemplatePortForm).then(
                  (res) => {
                    if (res.header.retCode === "1") {
                      this.$message({
                        message: `${res.header.retMessage}`,
                        type: "success",
                      });
                      this.$emit("fetchData");
                    } else {
                      this.$message.error(`${res.header.retMessage}`);
                    }
                    this.certainLoading = false;
                    this.dialogFormVisible = false;
                  }
                );
              }
            } else {
              this.$message.error("请配置相应表单");
            }
          } else {
            return false;
          }
        });
      },
      // 配置取消按钮
      cancel() {
        this.nodeReset();
        this.open = false;
      },
      // 取消按钮
      getBack(device) {
        if (device === "webOpen") {
          this.webOpen = false;
        } else {
          this.uploadOpen = false;
        }
      },
      // 表单重置
      reset() {
        this.contentForm = this.$options.data().contentForm;
      },
      // 节点表单重置
      nodeReset() {
        // this.$refs["contentForm"].resetFields();
        this.contentForm = this.$options.data().contentForm;
      },
      /** 新增按钮操作 */
      handleAdd(row) {
        this.nodeTitleName = "表单名称";
        // 添加表单元素
        if (row !== "content") {
          this.subTitle = "新增节点";
          this.tmpID = row.menuId;
          this.nodeTitleDisable = true;
          this.add_children_type = true;
          this.childrenNode_show = true;
          this.contentForm.nodeTitle = row.nodeTitle;
          this.contentForm.backgroundColor = row.backgroundColor;
          this.contentForm.spanColor = row.spanColor;
          this.contentForm.textAlign = row.textAlign;
        } else {
          // 表单
          this.subTitle = "新增表单";
          this.add_children_type = false;
          this.childrenNode_show = false;
          this.nodeTitleDisable = false;
          this.contentForm.parentId = 0;
          /** menuId在递加 */
          if (this.flag == "add") {
            this.contentForm.menuId = this.tmpParentNum;
          } else {
            /** 寻找最大值 */
            // Math.max.apply(Math, array.map(function(o) {return o.value}))
            this.contentForm.menuId =
              Math.max.apply(
                Math,
                this.nodeList.map(function (o) {
                  return o.menuId;
                })
              ) + 1;
          }
          this.tmpParentNum++;
        }
        this.operaType = "add";
        this.open = true;
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        // 修改表单元素
        this.contentForm.nodeTitle = row.nodeTitle;
        this.contentForm.backgroundColor = row.backgroundColor;
        this.contentForm.spanColor = row.spanColor;
        this.contentForm.textAlign = row.textAlign;
        this.childrenNode_show = false;
        this.tmpID = row.menuId;
        if (row.parentId !== 0) {
          this.nodeTitleName = "节点名称";
          this.nodeTitleDisable = true;
          this.subTitle = "编辑节点";
          this.add_children_type = true;
          this.contentForm = Object.assign(this.contentForm, row);
        } else {
          this.nodeTitleName = "表单名称";
          this.nodeTitleDisable = false;
          // 表单
          this.subTitle = "编辑表单";
          this.add_children_type = false;
        }
        this.open = true;
        this.operaType = "edit";
        // this.subTitle = "编辑节点";
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) => filterVal.map((j) => v[j]));
      },
      // 打开手机预览
      previewPhone() {
        if (this.dataList.length !== 0) {
          // 当前系统时间
          this.addSystemTemplatePortForm.header.currentTime = returnBitTimestamp(
            new Date()
          );
          this.addSystemTemplateForm.userNo = this.userNo;
          let _obj = { config: this.addSystemTemplateForm.config };
          Object.assign(this.addSystemTemplatePortForm.body, _obj);
          previewTemplate(this.addSystemTemplatePortForm).then((res) => {
            if (res.header.retCode === "1") {
              this.$refs["phonePreview"].preView(res.body);
            } else {
              this.$message.error(`${res.header.retMessage}`);
            }
          });
        } else {
          this.$message.error("请先添加表单");
        }
      },
      // 打开WEB预览
      previewWeb(row) {
        this.webOpen = true;
        this.webTitle = "WEB预览";
      },
      /** 初始化 */
      filterParams(obj) {
        let _newPar = {};
        for (let key in obj) {
          //如果对象属性的值不为空，就保存该属性（这里我做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。）
          if (
            (obj[key] === 0 || obj[key]) &&
            obj[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== ""
          ) {
            //记录属性
            _newPar[key] = obj[key];
          }
        }
        //返回对象
        return _newPar;
      },
      /** 配置 提交按钮 */
      submitForm: function () {
        this.$refs["contentForm"].validate((valid) => {
          if (valid) {
            if (this.flag == "add") {
              /**
               * @description: 新增创建
               */
              // 新增
              if (this.operaType == "add") {
                // 有表单元素
                if (this.add_children_type) {
                  this.contentForm.parentId = this.tmpID;
                  this.contentForm.menuId = this.tmpChildNum + 10 * 10;
                  this.tmpChildNum++;
                  this.contentForm.nodeTitle = this.contentForm.childNodeTitle;
                  this.nodeList.push(this.contentForm);
                  this.open = false;
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                } else {
                  /** 判断回显 */
                  // 表单
                  delete this.contentForm.options;
                  this.nodeList.push(this.contentForm);
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                }
              } else {
                /* 编辑 */
                // 有节点信息
                if (this.add_children_type) {
                  this.nodeList.map((item, index) => {
                    if (item.menuId == this.tmpID) {
                      item.nodeTitle = this.contentForm.nodeTitle;
                      item.type = this.contentForm.type;
                      item.backgroundColor = this.contentForm.backgroundColor;
                      item.spanColor = this.contentForm.spanColor;
                      item.textAlign = this.contentForm.textAlign;
                      /** 子节点 */
                      item.title = this.contentForm.title;
                      item.value = this.contentForm.value;
                      item.required = this.contentForm.required;
                      item.propType = this.contentForm.propType;
                      item.disabled = this.contentForm.disabled;
                      item.options = this.contentForm.options;
                      item.field = this.contentForm.field;
                      item.clearable = this.contentForm.clearable;
                      item.message = this.contentForm.message;
                      item.trigger = this.contentForm.trigger;
                      // item.readonly = this.contentForm.readonly;
                    }
                  });
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                } else {
                  // 表单
                  this.nodeList.map((item, index) => {
                    if (item.menuId == this.tmpID) {
                      item.nodeTitle = this.contentForm.nodeTitle;
                      item.backgroundColor = this.contentForm.backgroundColor;
                      item.spanColor = this.contentForm.spanColor;
                      item.textAlign = this.contentForm.textAlign;
                    }
                  });
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                }
              }
            } else {
              /**
               * @description: 编辑回显
               */
              // 新增 系统模板
              if (this.operaType == "add") {
                // 有表单元素
                if (this.add_children_type) {
                  this.contentForm.parentId = this.tmpID;
                  this.contentForm.menuId =
                    Math.max.apply(
                      Math,
                      this.nodeList.map(function (o) {
                        return o.menuId;
                      })
                    ) + 100;
                  /** new menuId定义 */
                  // this.contentForm.menuId = this.tmpChildNum + 10000 * 10;
                  // this.tmpChildNum++;
                  this.contentForm.nodeTitle = this.contentForm.childNodeTitle;
                  this.nodeList.push(this.contentForm);
                  this.open = false;
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                } else {
                  /** 判断回显 */
                  // 表单
                  // delete this.contentForm.options;
                  /** 编辑回显中的menuId规则按照万位进制 */
                  this.nodeList.push(this.contentForm);
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                }
              } else {
                /* 编辑 */
                // 有节点信息
                if (this.add_children_type) {
                  this.nodeList.map((item, index) => {
                    if (item.menuId == this.tmpID) {
                      item.nodeTitle = this.contentForm.nodeTitle;
                      /** 一级目录赋值 */
                      item.type = this.contentForm.type;
                      item.backgroundColor = this.contentForm.backgroundColor;
                      item.spanColor = this.contentForm.spanColor;
                      item.textAlign = this.contentForm.textAlign;
                      /** 二级节点赋值 */
                      /** 子节点 */
                      item.title = this.contentForm.title;
                      item.value = this.contentForm.value;
                      item.required = this.contentForm.required;
                      item.propType = this.contentForm.propType;
                      item.disabled = this.contentForm.disabled;
                      item.options = this.contentForm.options;
                      item.field = this.contentForm.field;
                      item.clearable = this.contentForm.clearable;
                      item.message = this.contentForm.message;
                      item.trigger = this.contentForm.trigger;
                      // item.readonly = this.contentForm.readonly;
                    }
                  });
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                } else {
                  // 表单
                  this.nodeList.map((item, index) => {
                    if (item.menuId == this.tmpID) {
                      item.nodeTitle = this.contentForm.nodeTitle;
                      item.backgroundColor = this.contentForm.backgroundColor;
                      item.spanColor = this.contentForm.spanColor;
                      item.textAlign = this.contentForm.textAlign;
                    }
                  });
                  // 节点重置
                  this.nodeReset();
                  this.dataList = this.handleTree(this.nodeList, "menuId");
                }
              }
            }
            // 更新树
            let _arr = [];
            this.dataList.map((item) => {
              _arr.push(this.filterParams(item));
            });
            this.addSystemTemplateForm.config = _arr;
            this.open = false;
          }
        });
      },
      // 寻找子代节点
      findNodeChild(child_id) {
        this.nodeList.forEach((item, index) => {
          if (item.parentId == child_id) {
            this.del_arr.push(index);
            this.findNodeChild(item.menuId);
          }
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        this.del_arr = [];
        this.$confirm('是否确认删除目录名称为"' + row.nodeTitle + '"的节点?')
          .then((_) => {
            if (this.nodeList.length != 0) {
              // 删除当前行
              this.nodeList.forEach((item, index) => {
                if (item.menuId === row.menuId) {
                  this.del_arr.push(index);
                }
              });
              // 删除子代行
              this.findNodeChild(row.menuId);
              // 倒序
              this.del_arr.sort((a, b) => {
                return b - a;
              });
              this.del_arr.forEach((item, index) => {
                this.nodeList.splice(item, 1);
              });
              this.dataList = this.handleTree(this.nodeList, "menuId");
              // 更新树
              let _arr = [];
              this.dataList.map((item) => {
                _arr.push(this.filterParams(item));
              });
              this.addSystemTemplateForm.config = _arr;
            }
          })
          .catch((_) => {});
      },
      /**
       * 构造树型结构数据
       * @param {*} data 数据源
       * @param {*} id id字段 默认 'id'
       * @param {*} parentId 父节点字段 默认 'parentId'
       * @param {*} children 孩表单元素字段 默认 'children'
       * @param {*} rootId 根Id 默认 0
       */
      handleTree(data, id, parentId, children, rootId) {
        id = id || "id";
        parentId = parentId || "parentId";
        children = children || "children";
        rootId = rootId || 0;
        //对源数据深度克隆
        const cloneData = JSON.parse(JSON.stringify(data));
        //循环所有项
        const treeData = cloneData.filter((father) => {
          let branchArr = cloneData.filter((child) => {
            //返回每一项的子级数组
            return father[id] === child[parentId];
          });
          branchArr.length > 0 ? (father.children = branchArr) : "";
          //返回第一层
          return father[parentId] === rootId;
        });
        return treeData != "" ? treeData : data;
      },
    },
  };
</script>
<style scoped>
  .el-col {
    margin-bottom: 10px;
  }
  html body .el-dialog__body .el-form,
  html body .el-message-box__body .el-form {
    padding-right: 0;
  }
  .phone {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    min-height: 550px;
  }
  .phone .el-row {
    border-bottom: 1px solid #dcdfe6;
    padding: 10px 10px 0 10px;
  }
  .phone .el-row .el-col:last-child {
    text-align: right;
  }
  .el-icon-upload {
    display: flex;
    justify-content: center;
    font-size: 70px;
    color: #ccc;
  }
</style>
