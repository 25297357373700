var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
        _vm._v("该工单是否确认提交？"),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _vm.isShowRole
            ? _c(
                "el-form-item",
                { attrs: { label: "选择角色", prop: "nextUserRoleNo" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择角色",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.changeRole },
                      model: {
                        value: _vm.form.nextUserRoleNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "nextUserRoleNo", $$v)
                        },
                        expression: "form.nextUserRoleNo",
                      },
                    },
                    _vm._l(_vm.roleOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowOne
            ? _c(
                "el-form-item",
                { attrs: { label: "督导人员", prop: "monitorUser" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择督导人员",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.form.monitorUser,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "monitorUser", $$v)
                        },
                        expression: "form.monitorUser",
                      },
                    },
                    _vm._l(_vm.supervisionOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowTwo
            ? _c(
                "el-form-item",
                { attrs: { label: "调查人员", prop: "surveyUser" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择调查人员",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.form.surveyUser,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "surveyUser", $$v)
                        },
                        expression: "form.surveyUser",
                      },
                    },
                    _vm._l(_vm.surveyUserOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "小记", prop: "note" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", autocomplete: "off" },
                model: {
                  value: _vm.form.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }