var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "myStyle" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "authorForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.authorForm,
                        "status-icon": "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "工单导出权限", prop: "label" } },
                        [
                          _c("el-tree", {
                            ref: "tree",
                            attrs: {
                              data: _vm.data,
                              "show-checkbox": "",
                              "node-key": "menuId",
                              "default-expand-all": "",
                              props: _vm.defaultProps,
                              "default-checked-keys": _vm.checkKeys,
                            },
                            on: { "check-change": _vm.checkChange },
                            model: {
                              value: _vm.authorForm.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.authorForm, "label", $$v)
                              },
                              expression: "authorForm.label",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "myBtn",
                              attrs: { type: "primary", disabled: "" },
                              on: { click: _vm.handleCheckChange },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "myStyle" },
                [
                  _c(
                    "el-form",
                    { attrs: { "status-icon": "", "label-width": "100px" } },
                    [
                      _c("el-form-item", { attrs: { label: "工单导出角色" } }, [
                        _c(
                          "div",
                          { staticClass: "tag-group" },
                          _vm._l(_vm.roles, function (item) {
                            return _c(
                              "el-tag",
                              {
                                key: item,
                                attrs: { type: item, effect: "plain" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getMenu(item)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          }),
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "工单导出菜单", prop: "seqId" } },
                        [
                          _c(
                            "div",
                            { staticClass: "tag-group" },
                            _vm._l(_vm.menus, function (item) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.seqId,
                                  attrs: { type: item.type, effect: "plain" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetail(item.seqId)
                                    },
                                  },
                                  model: {
                                    value: _vm.menuId,
                                    callback: function ($$v) {
                                      _vm.menuId = $$v
                                    },
                                    expression: "menuId",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.menuName) + " ")]
                              )
                            }),
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "工单导出详情", prop: "pass" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { indeterminate: _vm.isIndeterminate },
                              on: { change: _vm.handleCheckAllChange },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            },
                            [_vm._v(" 全选 ")]
                          ),
                          _c("div", { staticStyle: { margin: "15px 0" } }),
                          _c(
                            "el-checkbox-group",
                            {
                              on: { change: _vm.handleCheckedFieldsChange },
                              model: {
                                value: _vm.checkedFields,
                                callback: function ($$v) {
                                  _vm.checkedFields = $$v
                                },
                                expression: "checkedFields",
                              },
                            },
                            _vm._l(_vm.fields, function (field) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: field.configName,
                                  staticStyle: { width: "8em" },
                                  attrs: { label: field.configName },
                                },
                                [_vm._v(" " + _vm._s(field.configName) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "myBtn",
                              attrs: { type: "primary", disabled: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("detailForm")
                                },
                              },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }