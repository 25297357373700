<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    append-to-body
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否确认提交？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item v-if="isShowRole" label="选择角色" prop="nextUserRoleNo">
        <el-select
          v-model="form.nextUserRoleNo"
          placeholder="请选择角色"
          clearable
          filterable
          @change="changeRole"
        >
          <el-option
            v-for="item in roleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isShowOne" label="督导人员" prop="monitorUser">
        <el-select
          v-model="form.monitorUser"
          placeholder="请选择督导人员"
          clearable
          filterable
        >
          <el-option
            v-for="item in supervisionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isShowTwo" label="调查人员" prop="surveyUser">
        <el-select
          v-model="form.surveyUser"
          placeholder="请选择调查人员"
          clearable
          filterable
        >
          <el-option
            v-for="item in surveyUserOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model="form.note"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    submit,
    batchSubmit,
    validateSurveyTimeConflict,
  } from "@/api/workplace/order/order";
  import { returnBitTimestamp, getRoleKey, uuid } from "@/utils/index";
  import { mapGetters } from "vuex";
  import {
    getRole,
    // 退回原因
  } from "@/api/workplace/tools";

  export default {
    name: "Submit",
    data() {
      return {
        // 防抖
        loading: false,
        isShowRole: true,
        isShowOne: false,
        isShowTwo: false,
        form: {
          nextUserRoleNo: null,
          surveyUser: null,
          monitorUser: null,
          note: null,
        },
        roleForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: uuid(),
            sourceType: "site",
          },
        },
        rules: {
          nextUserRoleNo: [
            { required: true, trigger: "blur", message: "请选择角色" },
          ],
          surveyUser: [
            { required: true, trigger: "blur", message: "请选择调查人员" },
          ],
          monitorUser: [
            { required: true, trigger: "blur", message: "请选择督导人员" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
        roleOptions: [
          { value: "supervisor", label: "督导人员" },
          { value: "full_investigator", label: "调查人员" },
        ],
        surveyUserOptions: [],
        supervisionOptions: [],
        // 提交工单调查参数
        parameter: {
          body: {
            console: "book_person",
            nextTaskId: "",
            nextUserNo: "",
            nextUserRoleNo: "",
            note: "",
            operAction: "工单预约",
            procInsId: "",
            projectSource: "",
            remark: "",
            step: "预约工作台-工单预约-待处理",
            taskId: "",
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
            bookVisitTime: "",
          },
          header: {
            currentTime: "",
            requestId: uuid(),
            sourceType: "site",
          },
        },
        // 批量提交的参数
        batchPara: {
          body: [],
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: uuid(),
            sourceType: "site",
          },
        },
        object: [],
        obj: {
          userNo: "",
          bookVisitTime: "",
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.parameter.body.userNo = this.userNo;
      this.parameter.body.userRoleNo = getRoleKey("book_person", this.roles);
    },
    methods: {
      showSubmit(row) {
        this.title = "提交工单";
        this.batchPara.body = [];
        if (11 === row[0].workOrderStatus) {
          this.isShowRole = false;
          this.isShowOne = false;
          this.isShowTwo = true;
          this.form.surveyUser = row[0].surveyUser;
          // 实地兼职调查员[包括经理]
          this.getPartSurveyUser();
        } else {
          this.isShowRole = true;
          // 实地全职调查员[包括经理]
          this.getFullSurveyUser();
          // 督导
          this.getSupervision();
        }

        this.object = row;

        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        this.isShowOne = false;
        this.isShowTwo = false;
        // this.$emit("refreshData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.parameter.header.currentTime = returnBitTimestamp(new Date());
            this.loading = true;
            for (let i = 0; i < this.object.length; i++) {
              this.parameter.body.procInsId = this.object[i].procInsId;
              this.parameter.body.taskId = this.object[i].taskId;
              this.parameter.body.projectSource = this.object[i].source;
              this.parameter.body.workOrderNo = this.object[i].workOrderNo;
              this.parameter.body.bookVisitTime = this.object[i].bookVisitTime;
              if (
                this.form.monitorUser !== null &&
                11 !== this.object[i].workOrderStatus
              ) {
                this.parameter.body.bookFlag = 0;
              } else if (
                this.form.surveyUser !== null &&
                11 !== this.object[i].workOrderStatus
              ) {
                this.parameter.body.bookFlag = 1;
              } else {
                this.parameter.body.bookFlag = 2;
              }

              this.parameter.body.monitorUser = this.form.monitorUser;
              this.parameter.body.surveyUser = this.form.surveyUser;

              this.parameter.body.note = this.form.note;

              this.batchPara.body.push(Object.assign({}, this.parameter.body));
            }

            if (this.form.surveyUser !== null && this.form.surveyUser !== "") {
              const { header } = await validateSurveyTimeConflict(
                this.batchPara
              );
              if (header.retCode !== "1") {
                this.$baseConfirm(
                  header.retMessage,
                  "温馨提示",
                  () => {
                    this.doSubmit(this.batchPara);
                  },
                  () => {
                    this.loading = false;
                    this.batchPara.body = [];
                  }
                );
              } else {
                this.doSubmit(this.batchPara);
              }
            } else {
              this.doSubmit(this.batchPara);
            }
          } else {
            return false;
          }
        });
      },
      async doSubmit(param) {
        const { header } = await batchSubmit(Object.assign({}, param));
        if (header.retCode === "1") {
          this.$baseMessage(header.retMessage, "success");
        } else {
          this.$baseMessage(header.retMessage, "error");
        }

        this.dialogFormVisible = false;
        this.$emit("refreshData");
        this.$emit("close");
        this.form = this.$options.data().form;
        this.isShowOne = false;
        this.isShowTwo = false;
        this.loading = false;
      },
      getFullSurveyUser() {
        let data = Object.assign({}, this.roleForm);
        data.header.currentTime = returnBitTimestamp(new Date());
        // 实地全职调查员[包括经理]
        data.body = [
          {
            roleKey: "full_investigator",
          },
        ];
        getRole(data).then((response) => {
          if (response.header.retCode == "1") {
            this.surveyUserOptions = response.body;
          }
        });
      },
      getPartSurveyUser() {
        let data = Object.assign({}, this.roleForm);
        data.header.currentTime = returnBitTimestamp(new Date());
        // 实地全职调查员[包括经理]
        data.body = [
          {
            roleKey: "part_investigator",
          },
        ];
        getRole(data).then((response) => {
          if (response.header.retCode == "1") {
            this.surveyUserOptions = response.body;
          }
        });
      },
      getSupervision() {
        let data = Object.assign({}, this.roleForm);
        data.header.currentTime = returnBitTimestamp(new Date());
        // 督导
        data.body = [
          {
            roleKey: "supervisor",
          },
        ];
        getRole(data).then((response) => {
          if (response.header.retCode == "1") {
            this.supervisionOptions = response.body;
          }
        });
      },
      /** 角色切换回调 */
      changeRole(value) {
        // 督导
        if ("supervisor" === value) {
          this.isShowOne = true;
          this.form.surveyUser = null;
        } else {
          this.isShowOne = false;
        }
        // 调查
        if ("full_investigator" === value) {
          this.isShowTwo = true;
          this.form.monitorUser = null;
        } else {
          this.isShowTwo = false;
        }
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
