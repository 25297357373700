var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "imgDialog",
      attrs: { visible: _vm.dialogImgVisible, "append-to-body": "" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogImgVisible = $event
        },
      },
    },
    [
      _c(
        "el-carousel",
        {
          ref: "imgCarousel",
          attrs: {
            trigger: "click",
            height: "350px",
            "initial-index": _vm.pictureIndex,
            "indicator-position": "none",
            autoplay: false,
          },
        },
        _vm._l(_vm.photoData, function (subItem, subIndex) {
          return _c(
            "el-carousel-item",
            {
              key: subIndex,
              attrs: { name: subIndex, "initial-index": _vm.pictureIndex },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _vm.judgeFileType(subItem.path)
                        ? _c("viewer", [
                            _c("img", {
                              staticStyle: { "max-height": "270px" },
                              attrs: { src: _vm.ossPath + subItem.path },
                            }),
                          ])
                        : _c("img", {
                            staticStyle: {
                              width: "160px",
                              height: "160px",
                              cursor: "pointer",
                            },
                            attrs: {
                              src: require("@/icon/pic.svg"),
                              fit: _vm.fit,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDownload(subItem)
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }