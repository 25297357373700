<template>
  <el-dialog
    :title="title"
    class="myDialog"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="60%"
    :fullscreen="fullFlag"
    @close="close"
  >
    <el-backtop target=".myDialog"></el-backtop>
    <i
      style="position: absolute; top: 24px; right: 41px; cursor: pointer"
      :class="fullIcon"
      :title="fullTitle"
      @click="triggerFullOrHelf"
    ></i>
    <div style="margin-bottom: 10px">{{ companyName }}</div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="取证资料" name="evidence">
        <evidence ref="evidence" :row="object"></evidence>
      </el-tab-pane>
      <el-tab-pane label="拍摄照片">
        <photo ref="photo" :type="'50'" :row="object"></photo>
      </el-tab-pane>
      <el-tab-pane label="拍摄录像">
        <screenVideo ref="screenVideo" :type="'51'" :row="object"></screenVideo>
      </el-tab-pane>
      <el-tab-pane label="扫描文件" name="scanFile">
        <scanFile ref="scanFile" :type="'52'" :row="object"></scanFile>
      </el-tab-pane>
      <el-tab-pane label="录制音频">
        <screenAudio ref="screenAudio" :type="'53'" :row="object"></screenAudio>
      </el-tab-pane>
      <el-tab-pane label="会议信息">
        <meeting ref="meeting" :type="'57'" :row="object"></meeting>
      </el-tab-pane>
      <el-tab-pane label="标注记录">
        <labelRecord ref="labelRecord" :type="'56'" :row="object"></labelRecord>
      </el-tab-pane>
      <el-tab-pane label="企业信息">
        <company ref="company" :row="object"></company>
      </el-tab-pane>
    </el-tabs>

    <div slot="footer" class="dialog-footer">
      <el-button type="default" plain @click="smallNoteOpen">
        添加小记
      </el-button>
      <el-button type="warning" @click="backShowOpen">退回</el-button>
      <el-button type="primary" @click="submitShowOpen">提交</el-button>
    </div>

    <!-- 提交 对话框 -->
    <el-dialog
      title="提交"
      :visible.sync="submitShow"
      width="500px"
      :before-close="submitShowClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <div style="margin-bottom: 20px">该工单是否确认提交？</div>
      <el-form
        ref="submitForm"
        :model="submitForm"
        :rules="submitFormRules"
        class="demo-form-inline"
        label-width="80px"
      >
        <el-form-item label="小记" prop="note">
          <el-input
            v-model="submitForm.note"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitShow = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="submitSave('submitForm')"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 添加小记 对话框 -->
    <el-dialog
      title="添加小记"
      :visible.sync="openSmallNote"
      width="40%"
      :close-on-click-modal="false"
      :before-close="smallNoteClose"
      append-to-body
    >
      <el-form
        ref="smallNoteForm"
        :model="smallNoteForm"
        :rules="smallNoteFormRules"
        class="my-note"
      >
        <el-form-item label="小记" prop="note">
          <el-input
            v-model="smallNoteForm.note"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openSmallNote = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="noteLoading"
          @click="saveSmallNote('smallNoteForm')"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 退回对话框 -->
    <el-dialog
      title="退回"
      :visible.sync="backShow"
      :close-on-click-modal="false"
      :before-close="backShowClose"
      width="55%"
      append-to-body
    >
      <div style="margin-bottom: 20px">该工单是否退回？</div>
      <el-form
        ref="backForm"
        :model="backForm"
        :rules="backFormRules"
        label-width="120px"
      >
        <el-form-item label="退回类型" prop="fallbackCommentType">
          <el-select
            v-model="backForm.fallbackCommentType"
            placeholder="请选择退回类型"
            clearable
            filterable
            @change="changeType"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="showFlag"
          label="普通退回原因"
          prop="fallbackCommentId"
        >
          <el-select
            v-model="backForm.fallbackCommentId"
            placeholder="普通退回原因"
            :clearable="true"
            filterable
          >
            <el-option
              v-for="item in backOptions"
              :key="item.seqId"
              :label="item.content"
              :value="item.seqId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小记" prop="note">
          <el-input
            v-model="backForm.note"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="showContent"
          label="内容"
          prop="fallbackComment"
          class="vab-quill-content"
        >
          <vab-quill
            v-model="backForm.fallbackComment"
            :min-height="200"
          ></vab-quill>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="backShow = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="withdrawLoading"
          @click="backSave('backForm')"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
  // 取证资料
  import evidence from "@/views/workplace/components/evidence";
  // 拍摄照片
  import photo from "@/views/workplace/components/photo";
  // 拍摄录像
  import screenVideo from "@/views/workplace/components/screenVideo";
  // 扫描文件
  import scanFile from "@/views/workplace/components/scanFile";
  // 录制音频
  import screenAudio from "@/views/workplace/components/screenAudio";
  // 会议信息
  import meeting from "@/views/workplace/components/meeting";
  // 标注记录
  import labelRecord from "@/views/workplace/components/labelRecord";
  // 企业信息
  import company from "@/views/workplace/components/company";

  import {
    getOperationInfo,
    getGeneralReason,
    getOrderDetail,
    getUsersListByRole,
    searchAttach,
  } from "@/api/workplace/tools";
  import orderProcess from "@/views/workplace/components/orderProcess";
  import { saveNote, submit, fallBack } from "@/api/workplace/user/pchUser";
  import {
    returnBitTimestamp,
    getRoleKey,
    returnMinutetamp,
    eighthBitTimestamp,
    downloadFile,
    isExistOSS,
  } from "@/utils";
  import { mapGetters } from "vuex";
  import vabQuill from "@/plugins/vabQuill";
  export default {
    name: "Review",
    components: {
      evidence,
      photo,
      screenVideo,
      scanFile,
      screenAudio,
      meeting,
      labelRecord,
      company,
      vabQuill,
    },
    data() {
      const validateQuillEditor = (rule, value, callback) => {
        if (value == undefined && value == null) {
          callback(new Error("内容不能为空!"));
        } else if (value.length > 15728640) {
          callback(new Error("内容大小不能超过10MB，请重新编辑"));
        } else {
          callback();
        }
      };
      return {
        activeName: "evidence",
        loading: false,
        withdrawLoading: false,
        noteLoading: false,
        annexGroup: [],
        // 添加小记
        openSmallNote: false,
        showFlag: false,
        showContent: false,
        submitShow: false,
        backShow: false,
        title: "",
        // 全/半屏提示文本
        fullTitle: "最大化",
        // 全/半屏按钮图标
        fullIcon: "el-icon-full-screen",
        // 是否全屏标识
        fullFlag: false,
        dialogFormVisible: false,
        tableData: [],

        smallNoteForm: {
          note: "",
        },
        submitForm: {
          user: "",
          note: "",
        },
        backForm: {
          fallbackCommentType: "",
          fallbackCommentId: "",
          note: "",
          fallbackComment: null,
        },
        smallNoteFormRules: {
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        submitFormRules: {
          user: [{ required: true, trigger: "blur", message: "请选择用户" }],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        backFormRules: {
          fallbackCommentType: [
            { required: true, trigger: "blur", message: "请选择退回类型" },
          ],
          fallbackCommentId: [
            { required: true, trigger: "blur", message: "请选择普通退回原因" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
          fallbackComment: [
            {
              required: true,
              validator: validateQuillEditor,
              trigger: "blur",
            },
          ],
        },
        roleOptions: [
          {
            value: "6694249429643431936",
            label: "普通退回",
          },
          {
            value: "6694249455245463552",
            label: "质疑退回",
          },
        ],
        orderNo: null,
        // 获取工单详情
        param: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            pageSize: 10,
            pageNum: 1,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        operationInfo: [],
        // 查询工单的操作日志
        logParam: {
          body: {
            workOrderNo: "",
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 单独记录小记
        noteParam: {
          body: {
            note: "",
            step: "用户工作台-工单二审-待处理",
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 提交参数
        submitParam: {
          body: {
            console: "customer", // 固定写法
            note: null,
            operAction: "工单二审", // 固定写法
            projectSource: "",
            step: "用户工作台-工单二审-待处理", // 固定写法
            pchUser: "",
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            procInsId: null,
            taskId: null,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        userOptions: [],
        // 查寻用户
        userParam: {
          body: [
            {
              roleKey: "manager",
            },
            {
              roleKey: "customer",
            },
          ],
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 退回参数
        backParam: {
          body: {
            console: "customer", // 固定写法
            note: null,
            projectSource: "",
            step: "用户工作台-工单二审-待处理", // 固定写法
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            procInsId: null,
            taskId: null,
            fallbackComment: null,
            fallbackCommentType: null,
            fallbackCommentId: null,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 普通退回原因form
        backOptions: [],
        // 普通退回原因 参数
        backReasonParam: {
          body: {
            templateTypeSeqId: "",
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 给子组件传值
        object: null,
        companyName: null,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        /** 审核 */
        accessKeyId: "user/accessKeyId",
        accessKeySecret: "user/accessKeySecret",
        bucketName: "user/bucketName",
        endpoint: "user/endpoint",
        endpoinxBak: "user/endpoinxBak",
        region: "user/region",
        policy: "user/policy",
        signature: "user/signature",
        OSSClient: "user/OSSClient",
      }),
    },
    created() {
      this.param.body.userNo = this.userNo;
      this.param.body.userRoleNo = getRoleKey("customer", this.roles);
      this.noteParam.body.userNo = this.userNo;
      this.noteParam.body.userRoleNo = getRoleKey("customer", this.roles);
      this.submitParam.body.userNo = this.userNo;
      this.submitParam.body.userRoleNo = getRoleKey("customer", this.roles);
      this.backParam.body.userNo = this.userNo;
      this.backParam.body.userRoleNo = getRoleKey("customer", this.roles);
    },
    methods: {
      // 时分下单日期格式处理
      Minutetamp(row, column) {
        var date = row[column.property];
        if (date === undefined) {
          return "-";
        }
        return returnMinutetamp(Date.parse(date));
      },
      // 时分下单日期格式处理
      eighthBitTime(row, column) {
        var date = row[column.property];
        if (date === undefined) {
          return "-";
        }
        return eighthBitTimestamp(Date.parse(date));
      },
      async showReview(row) {
        this.object = row;
        this.object.workplace = "customer";
        this.object.userNo = this.userNo;
        this.object.userRoleNo = getRoleKey("customer", this.roles);
        this.object.flag = "review";
        /***oss 信息 */
        this.object.accessKeyId = this.accessKeyId;
        this.object.accessKeySecret = this.accessKeySecret;
        this.object.bucketName = this.bucketName;
        this.object.endpoint = this.endpoint;
        this.object.endpoinxBak = this.endpoinxBak;
        this.object.OSSClient = this.OSSClient;
        this.object.region = this.region;
        this.object.policy = this.policy;
        this.object.signature = this.signature;
        this.companyName = row.targetSubject;
        this.title = "审核工单";

        this.smallNoteForm = Object.assign({}, row);

        this.submitForm = Object.assign({}, row);

        this.backForm = Object.assign({}, row);

        this.param.body.workOrderNo = row.workOrderNo;

        this.dialogFormVisible = true;

        this.tableData = [];
        this.orderNo = row.workOrderNo;
        // 获取工单详情
        this.param.body.workOrderNo = row.workOrderNo;
        this.tableData.push(
          Object.assign({}, (await getOrderDetail(this.param)).body)
        );
        // 获取操作工单日志信息
        this.logParam.body.workOrderNo = row.workOrderNo;
        this.operationInfo = (await getOperationInfo(this.logParam)).body;

        // 查新督导人员
        const { body } = await getUsersListByRole(this.userParam);
        this.userOptions = body;

        this.backReasonParam.header.currentTime = returnBitTimestamp(
          new Date()
        );
        this.backReasonParam.body.templateTypeSeqId = "6694249429643431936";
        getGeneralReason(this.backReasonParam).then((response) => {
          if ("1" === response.header.retCode) {
            this.backOptions = response.body;
          }
        });
        /** 查询附件信息 */
        this.attach();
        this.getDetail();
      },
      getDetail() {
        this.$refs["evidence"].fetchData();
        this.$refs["photo"].fetchData();
        this.$refs["screenVideo"].fetchData();
        this.$refs["scanFile"].fetchData();
        this.$refs["screenAudio"].fetchData();
        this.$refs["meeting"].fetchData();
        this.$refs["labelRecord"].fetchData();
        this.$refs.company.init();
      },
      refreshData() {
        this.$emit("refreshData");
      },
      /** 查询附件信息 */
      async attach() {
        this.param.body.workOrderNo = this.orderNo;
        this.param.header.currentTime = returnBitTimestamp(new Date());
        const { body, header } = await searchAttach(this.param);
        if (header.retCode === "1") {
          this.annexGroup = body;
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      },
      smallNoteClose() {
        this.$refs["smallNoteForm"].resetFields();
        this.openSmallNote = false;
      },
      submitShowClose() {
        this.$refs["submitForm"].resetFields();
        this.submitShow = false;
      },
      backShowClose() {
        this.$refs["backForm"].resetFields();
        this.backShow = false;
      },

      smallNoteOpen() {
        this.noteLoading = false;
        this.openSmallNote = true;
      },
      submitShowOpen() {
        this.loading = false;
        this.submitShow = true;
      },
      backShowOpen() {
        this.withdrawLoading = false;
        this.backShow = true;
      },

      // 提交话框
      submitSave(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            this.submitParam.body.workOrderNo = this.submitForm.workOrderNo;
            this.submitParam.body.note = this.submitForm.note;
            this.submitParam.body.procInsId = this.submitForm.procInsId;
            this.submitParam.body.taskId = this.submitForm.taskId;

            const { header } = await submit(
              Object.assign({}, this.submitParam)
            );
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.submitShow = false;
            this.$emit("refreshData");
            this.$refs[formName].resetFields();

            this.submitForm = this.$options.data().submitForm;

            this.dialogFormVisible = false;
            // this.loading = false;
            // 保存
          } else {
            this.$message.error("保存失败，请重新检查输入内容");
            return false;
          }
        });
      },
      // 保存小记对话框
      saveSmallNote(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.noteLoading = true;
            this.noteParam.body.workOrderNo = this.smallNoteForm.workOrderNo;
            this.noteParam.body.note = this.smallNoteForm.note;
            this.noteParam.body.operAction = "保存小记";

            const { header } = await saveNote(
              Object.assign({}, this.noteParam)
            );
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.$emit("refreshData");
            this.openSmallNote = false;
            // 保存
          } else {
            this.$message.error("保存失败，请重新检查输入内容");
            return false;
          }
        });
      },
      // 退回对话框
      backSave(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.withdrawLoading = true;
            this.backParam.body.workOrderNo = this.backForm.workOrderNo;
            this.backParam.body.note = this.backForm.note;
            this.backParam.body.procInsId = this.backForm.procInsId;
            this.backParam.body.taskId = this.backForm.taskId;
            this.backParam.body.nextUserRoleNo = this.backForm.nextUserRoleNo;
            this.backParam.body.fallbackCommentType = this.backForm.fallbackCommentType;
            this.backParam.body.fallbackCommentId = this.backForm.fallbackCommentId;
            this.backParam.body.fallbackComment = this.backForm.fallbackComment;

            const { header } = await fallBack(
              Object.assign({}, this.backParam)
            );
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.backShow = false;
            this.$emit("refreshData");
            this.$refs[formName].resetFields();

            this.backForm = this.$options.data().backForm;

            this.dialogFormVisible = false;
            // this.withdrawLoading = false;
            // 保存
          } else {
            this.$message.error("保存失败，请重新检查输入内容");
            return false;
          }
        });
      },
      close() {
        this.dialogFormVisible = false;
        this.$refs["evidence"].reset();
        this.fullFlag = false;
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      },
      handleClick(tab, event) {
        if (tab.name === "evidence") {
          this.$refs["evidence"].reset();
          this.$refs["evidence"].fetchData();
        } else if (tab.name === "scanFile") {
          this.$refs["scanFile"].fetchData();
        }
      },
      changeType(val) {
        if ("6694249455245463552" === val) {
          this.showFlag = false;
          this.showContent = true;
          this.backForm.fallbackCommentId = null;
          this.backParam.body.operAction = "工单质疑";
        } else {
          this.showFlag = true;
          this.showContent = false;
          this.backForm.fallbackComment = null;
          this.backParam.body.operAction = "工单退回";
        }
      },
      /** 全屏/半屏切换 */
      triggerFullOrHelf() {
        this.fullFlag = !this.fullFlag;
        if (this.fullFlag) {
          this.fullIcon = "el-icon-minus";
          this.fullTitle = "最小化";
        } else {
          this.fullIcon = "el-icon-full-screen";
          this.fullTitle = "最大化";
        }
      },
      /** 附件下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
    },
  };
</script>

<style>
  body {
    font-weight: 700;
    color: #666;
  }
  .edit_title .title {
    margin: 10px 0;
  }
  .el-step__title {
    font-size: 14px;
  }
  .el-step__title.is-process {
    font-weight: bold;
    color: #666;
  }
  html body .el-table th .cell {
    font-weight: 700;
    color: #666;
  }
  /* .el-table--enable-row-hover .el-table__body tr:hover > td { */
  /* background-color: #f5f7fa; */
  /* } */

  .el-form-item__label {
    font-weight: normal;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }

  .my-note {
    padding-right: 0 !important;
  }
  .el-dialog__body {
    padding: 10px 20px 20px 20px;
  }
</style>
