<template>
  <div>
    <el-dialog :title="title"
               class="myDialog"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="60%"
               :fullscreen="fullFlag"
               @close="handleClose">
      <el-backtop target=".myDialog"></el-backtop>
      <i style="position: absolute; top: 24px; right: 41px; cursor: pointer"
         :class="fullIcon"
         :title="fullTitle"
         @click="triggerFullOrHelf"></i>
      <div style="margin-bottom: 10px">{{ companyName }}</div>
      <el-tabs v-model="activeName"
               type="border-card"
               @tab-click="handleClick">
        <el-tab-pane label="工单详情"
                     class="edit_title"
                     name="detail">
          <!--工单进度-->
          <order-process :param="param"></order-process>
          <!--基础信息>-->
          <div class="title">基础信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="workOrderNo"
                             label="工单编号"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.workOrderNo || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="brWorkNo"
                             label="订单编号"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.brWorkNo || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="reportType"
                             label="报告类型"
                             :class-name="brChange('reportTypeFlag') + ' '+ brChange('subReportTypeFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportType || "-" }}
                {{ scope.row.subReportType ? '/' : ''}}
                {{ scope.row.subReportType}}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="projectSource"
                             label="项目来源"
                             align="center">
              <template slot-scope="scope">
                {{ getProjectSource(scope.row.projectSource) || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             :class-name="brChange('subjectFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetSubject || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetAddr1"
                             label="目标地址1"
                             :class-name="brChange('subjectAddressFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr1 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="targetAddr2"
                             label="目标地址2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetContact"
                             label="目标联系人"
                             :class-name="brChange('subjectLinkNameFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetContact || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="contactNumber"
                             label="联系电话"
                             :class-name="brChange('subjectMobileFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.contactNumber || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="duty"
                             label="职务"
                             :class-name="brChange('dutyFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.duty || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderTime"
                             label="下单时间"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.orderTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             align="center"
                             :formatter="eighthBitTime"></el-table-column>
          </el-table>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="clientAbbr"
                             label="委托方简称"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.clientAbbr || "-" }}
              </template>
            </el-table-column>

            <el-table-column prop="reportTimeLimit"
                             label="报告时限"
                             :class-name="brChange('reportTimeLimitFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportTimeLimit || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark1"
                             label="备注1"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark1 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="remark2"
                             label="备注2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark3"
                             label="备注2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark3 || "-" }}
              </template>
            </el-table-column>
            <el-table-column></el-table-column>
          </el-table>
          <!--附加信息>-->
          <div class="title">附加信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectVisitDate"
                             label="预计走访日期"
                             align="center"
                             :formatter="eighthBitTime"></el-table-column>
            <el-table-column prop="expectServeyDay"
                             label="预计所需调查天数"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectServeyDay || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="needUser"
                             label="所需人手"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.needUser || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectHour"
                             label="预计需时（小时）"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectHour || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="registerAddress"
                             label="注册地址"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.registerAddress || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubjectRegStatus"
                             label="目标主体注册状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetSubjectRegStatus || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--特别信息>-->
          <div class="title">特别信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="plSpecialMsg"
                             label="PL特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.plSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="bookSpecialMsg"
                             label="预约特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.bookSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="monitorSpecialMsg"
                             label="督导特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.monitorSpecialMsg || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--附件-->
          <div v-show="annexGroup.length !== 0"
               class="title">附件</div>

          <el-row style="text-align: center; margin-top: 20px">
            <div v-for="(item, index) in annexGroup"
                 :key="index">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="6">
                <a @click.stop.prevent="
                    handleDownload({ name: item.fileName, path: item.path })
                  ">
                  <img src="@/icon/annex.svg"
                       alt=""
                       width="80"
                       height="80" />
                  <p>{{ item.fileName }}</p>
                </a>
              </el-col>
            </div>
          </el-row>

          <!--操作信息>-->
          <div class="title">操作信息</div>

          <el-table :data="operationInfo"
                    stripe
                    border>
            <el-table-column prop="operator"
                             label="操作者"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operator || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="role"
                             label="角色"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.role || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="operAction"
                             label="操作状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operAction || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime"
                             label="操作时间"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.createTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark"
                             label="备注"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--补充工单>-->
          <div class="title">补充工单</div>
          <el-form ref="orderForm"
                   :model="orderForm"
                   :rules="rules"
                   class="table-class demo-form-inline">
            <el-row :gutter="80">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="会议开始时间"
                              prop="startTime">
                  <el-date-picker v-model="orderForm.startTime"
                                  type="datetime"
                                  placeholder="选择开始时间"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="会议结束时间"
                              prop="endTime">
                  <el-date-picker v-model="orderForm.endTime"
                                  type="datetime"
                                  placeholder="选择结束时间"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="录像名称"
                              prop="videoName">
                  <el-input v-model="orderForm.videoName"
                            placeholder="请输入录像名称"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="录像路径"
                              prop="videoPath">
                  <el-input v-model="orderForm.videoPath"
                            placeholder="请输入录像路径"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="小记"
                              prop="note">
                  <el-input v-model="orderForm.note"
                            type="textarea"
                            class="my-textarea"
                            placeholder="请输入小记"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="取证资料"
                     name="evidence">
          <evidence ref="evidence"
                    :row="object"></evidence>
        </el-tab-pane>
        <el-tab-pane label="拍摄照片">
          <photo ref="photo"
                 :type="'50'"
                 :row="object"></photo>
        </el-tab-pane>
        <el-tab-pane label="拍摄录像">
          <screenVideo ref="screenVideo"
                       :type="'51'"
                       :row="object"></screenVideo>
        </el-tab-pane>
        <el-tab-pane label="扫描文件"
                     name="scanFile">
          <scanFile ref="scanFile"
                    :type="'52'"
                    :row="object"></scanFile>
        </el-tab-pane>
        <el-tab-pane label="录制音频">
          <screenAudio ref="screenAudio"
                       :type="'53'"
                       :row="object"></screenAudio>
        </el-tab-pane>
        <el-tab-pane label="其他文件">
          <telRecord ref="telRecord"
                     :type="'54'"
                     :row="object"></telRecord>
        </el-tab-pane>
        <el-tab-pane label="打卡记录">
          <clockIn ref="clockIn"
                   :type="'55'"
                   :row="object"></clockIn>
        </el-tab-pane>
        <el-tab-pane label="查看小记">
          <remark ref="remark"
                  :param="param"></remark>
        </el-tab-pane>
        <el-tab-pane label="退回记录">
          <back ref="back"
                :order="orderNo"></back>
        </el-tab-pane>
        <el-tab-pane label="企业信息">
          <company ref="company"
                   :row="object"></company>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer"
           class="dialog-footer">
        <el-button v-if="activeName === 'detail'"
                   @click="close">
          取消
        </el-button>
        <el-button type="warning"
                   @click="withdraw">退回</el-button>
        <el-button v-if="activeName === 'evidence'"
                   type="primary"
                   @click="handleReport">
          生成报告
        </el-button>
        <el-button v-if="activeName === 'detail'"
                   type="success"
                   @click="save">
          保存
        </el-button>
        <el-button v-if="activeName === 'evidence' && isSurveyUser === 'Y'"
                   type="success"
                   @click="saveEnvidence">
          保存
        </el-button>
        <el-button type="primary"
                   @click="submit">提交</el-button>
      </div>
    </el-dialog>
    <!--退囘工單-->
    <withdraw ref="withdraw"
              @close="close"
              @refreshData="refreshData"></withdraw>
    <!--提交工单-->
    <submit ref="submit"
            @close="close"
            @refreshData="refreshData"></submit>
  </div>
</template>

<script>
import orderProcess from "@/views/workplace/components/orderProcess";
// 取证资料
import evidence from "@/views/workplace/components/evidence";
// 拍摄照片
import photo from "@/views/workplace/components/photo";
// 拍摄录像
import screenVideo from "@/views/workplace/components/screenVideo";
// 扫描文件
import scanFile from "@/views/workplace/components/scanFile";
// 录制音频
import screenAudio from "@/views/workplace/components/screenAudio";
// 其他文件
import telRecord from "@/views/workplace/components/telRecord";
// 打卡记录
import clockIn from "@/views/workplace/components/clockIn";

// 退回记录
import back from "@/views/workplace/components/back";

// 企业信息
import company from "@/views/workplace/components/company";
// 查看小记
import remark from "@/views/workplace/components/remark";
import withdraw from "./withdraw";
import submit from "./submit";
import {
  edit,
  getOperationInfo,
  getOrderDetail,
  getMeeting,
} from "@/api/workplace/supervison/orderRepair";
import {
  getRoleKey,
  returnBitTimestamp,
  eighthBitTime,
  downloadFile,
  isExistOSS,
  parseTime,
  downloadReport,
  getProjectSource,
} from "@/utils";
import { mapGetters } from "vuex";
import { searchAttach } from "@/api/workplace/tools";
import { generateReport } from "@/api/workplace/commonOperation";
export default {
  name: "Edit",
  components: {
    orderProcess,
    evidence,
    photo,
    screenVideo,
    scanFile,
    screenAudio,
    telRecord,
    clockIn,
    remark,
    back,
    company,
    withdraw,
    submit,
  },
  data() {
    return {
      // 工单附件集合
      annexGroup: [],
      title: "",
      // 全/半屏提示文本
      fullTitle: "最大化",
      // 全/半屏按钮图标
      fullIcon: "el-icon-full-screen",
      // 是否全屏标识
      fullFlag: false,
      dialogFormVisible: false,
      tableData: [],
      activeName: "detail",
      /** 根据判断调查人员是否为兼职调查人员，如果是的话高亮显示取证资料的保存*/
      isSurveyUser: "",
      rules: {
        videoName: [
          {
            max: 50,
            message: "录像名称不能超过50个汉字",
            trigger: "blur",
          },
        ],
        videoPath: [
          {
            max: 100,
            message: "录像路径不能超过100个汉字",
            trigger: "blur",
          },
        ],
        note: [
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
      },
      orderForm: {
        workOrderNo: null,
        startTime: null,
        endTime: null,
        videoName: null,
        videoPath: null,
        note: null,
      },

      // 获取工单详情
      param: {
        body: {
          pageNum: 1,
          pageSize: 10,
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
          step: "督导工作台-工单补充-待处理", // 固定写法
          operAction: "工单补充", // 固定写法
          console: "supervisor", // 固定写法
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      operationInfo: [],
      // 查询工单的操作日志
      logParam: {
        body: {
          workOrderNo: "",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 修改成功之后返显推荐人员信息
      editReturnParam: {
        body: {
          workOrderNo: null,
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 编辑给后端的接口参数
      editParam: {
        body: {
          startTime: "",
          endTime: "",
          name: "",
          path: "",
          workOrderNo: "",
          note: "",
          step: "督导工作台-工单补充-待处理", // 固定写法
          console: "",
          operAction: "",
          userRoleNo: "",
          userNo: "",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 给子组件photo传值
      object: null,
      orderNo: null,
      reportForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      companyName: null,
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
      /** 编辑 */
      accessKeyId: "user/accessKeyId",
      accessKeySecret: "user/accessKeySecret",
      bucketName: "user/bucketName",
      endpoint: "user/endpoint",
      endpoinxBak: "user/endpoinxBak",
      region: "user/region",
      policy: "user/policy",
      signature: "user/signature",
      OSSClient: "user/OSSClient",
    }),
  },
  created() {
    this.param.body.userNo = this.userNo;
    this.param.body.userRoleNo = getRoleKey("supervisor", this.roles);
  },
  methods: {
    async showEdit(row) {
      this.tableData = [];
      this.title = "工单补充";
      this.orderForm.workOrderNo = row.workOrderNo;
      this.orderNo = row.workOrderNo;
      this.dialogFormVisible = true;
      /** 根据判断从列表页传来调查人员是否为兼职调查人员，如果是的话高亮显示取证资料的保存*/
      this.isSurveyUser = row.isSurveyUser;

      this.param.body.workOrderNo = row.workOrderNo;
      this.logParam.body.workOrderNo = row.workOrderNo;
      /** 给子组件的传参 */
      this.object = Object.assign(row, {
        workplace: "supervisor",
        flag: "edit",
        /**是否为兼职调查人员 */
        isSurveyUser: this.isSurveyUser,
      });
      this.object.userNo = this.userNo;
      this.object.userRoleNo = getRoleKey("supervisor", this.roles);
      this.object.accessKeyId = this.accessKeyId;
      this.object.accessKeySecret = this.accessKeySecret;
      this.object.bucketName = this.bucketName;
      this.object.endpoint = this.endpoint;
      this.object.endpoinxBak = this.endpoinxBak;
      this.object.OSSClient = this.OSSClient;
      this.object.region = this.region;
      this.object.policy = this.policy;
      this.object.signature = this.signature;
      // 获取工单详情
      this.tableData.push(
        Object.assign({}, (await getOrderDetail(this.param)).body)
      );
      this.companyName = this.tableData[0].targetSubject;
      // 获取操作工单日志信息
      this.operationInfo = (await getOperationInfo(this.logParam)).body;

      // 修改成功之后返显推荐人员信息
      this.editReturnParam.body.workOrderNo = row.workOrderNo;
      let result = Object.assign(
        {},
        (await getMeeting(this.editReturnParam)).body
      );

      this.orderForm.startTime = result.startTime;
      this.orderForm.endTime = result.endTime;
      this.orderForm.videoName = result.name;
      this.orderForm.videoPath = result.path;
      /** 查询附件信息 */
      await this.attach();
      this.dialogFormVisible = true;

      this.getDetail();
    },
    getDetail() {
      this.$refs["photo"].fetchData();
      this.$refs["screenVideo"].fetchData();
      this.$refs["scanFile"].fetchData();
      this.$refs["screenAudio"].fetchData();
      this.$refs["telRecord"].fetchData();
      this.$refs["clockIn"].fetchData();

      this.$refs["remark"].fetchData();
      this.$refs["back"].fetchData();

      this.$refs.company.init();
      this.activeName = "detail";
    },
    handleClick(tab) {
      this.$refs["evidence"].clearInterval();
      if (tab.name === "evidence") {
        this.$refs["evidence"].reset();
        this.$refs["evidence"].fetchData();
        this.$refs["evidence"].createTimer();
      } else if (tab.name === "scanFile") {
        this.$refs["scanFile"].fetchData();
      }
      this.$refs["photo"].fetchData();
      this.$refs["screenVideo"].fetchData();
      this.$refs["screenAudio"].fetchData();
      this.$refs["telRecord"].fetchData();
    },
    /**取证资料保存 */
    saveEnvidence() {
      this.$refs["evidence"].save();
    },
    /** 查询附件信息 */
    async attach() {
      this.param.body.workOrderNo = this.orderNo;
      this.param.header.currentTime = returnBitTimestamp(new Date());
      const { body, header } = await searchAttach(this.param);
      if (header.retCode === "1") {
        this.annexGroup = body;
      } else {
        this.$baseMessage(header.retMessage, "error");
      }
    },
    close() {
      this.$refs["orderForm"].resetFields();
      // this.orderForm = this.$options.data().orderForm;
      this.$refs["evidence"].reset();
      this.dialogFormVisible = false;
    },
    refreshData() {
      this.$emit("refreshData");
    },
    async save() {
      this.editParam.body.startTime =
        null !== this.orderForm.startTime
          ? parseTime(this.orderForm.startTime)
          : null;
      this.editParam.body.endTime =
        null !== this.orderForm.endTime
          ? parseTime(this.orderForm.endTime)
          : null;
      this.editParam.body.name = this.orderForm.videoName;
      this.editParam.body.path = this.orderForm.videoPath;
      this.editParam.body.workOrderNo = this.orderForm.workOrderNo;
      this.editParam.body.note = this.orderForm.note;
      /**接口新增参数 */
      this.editParam.body.userNo = this.userNo;
      this.editParam.body.userRoleNo = getRoleKey("supervisor", this.roles);
      this.editParam.body.console = "supervisor";
      // 操作记录
      this.editParam.body.operAction = "保存小记";

      const { header } = await edit(this.editParam);
      if ("1" === header.retCode) {
        this.$emit("refreshData");
        this.$baseMessage(header.retMessage, "success");
      } else {
        this.$baseMessage(header.retMessage, "error");
      }
      this.dialogFormVisible = false;
    },
    async submit() {
      this.editParam.body.startTime =
        null !== this.orderForm.startTime
          ? parseTime(this.orderForm.startTime)
          : null;
      this.editParam.body.endTime =
        null !== this.orderForm.endTime
          ? parseTime(this.orderForm.endTime)
          : null;
      this.editParam.body.name = this.orderForm.videoName;
      this.editParam.body.path = this.orderForm.videoPath;
      this.editParam.body.workOrderNo = this.orderForm.workOrderNo;
      this.editParam.body.operAction = "工单补充";
      /**接口新增参数 */
      this.editParam.body.userNo = this.userNo;
      this.editParam.body.userRoleNo = getRoleKey("supervisor", this.roles);
      this.editParam.body.console = "supervisor";

      // if (this.isSurveyUser === "Y") {
      //   this.$refs["evidence"]
      //     .save()
      //     .then(async () => {
      //       const { header } = await edit(this.editParam);
      //       if ("1" === header.retCode) {
      //         let rows = [];
      //         rows.push(this.object);
      //         this.$refs["submit"].setOpenLevel(1);
      //         this.$refs["submit"].showSubmit(rows);
      //       }
      //     })
      //     .catch((error) => {
      //       this.$message.error(
      //         "“取证资料”页面有必填内容暂未填写，请补充后再次提交"
      //       );
      //     });
      // } else {
      //   const { header } = await edit(this.editParam);
      //   if ("1" === header.retCode) {
      //     let rows = [];
      //     rows.push(this.object);
      //     this.$refs["submit"].setOpenLevel(1);
      //     this.$refs["submit"].showSubmit(rows);
      //   }
      // }
      const { header } = await edit(this.editParam);
      if ("1" === header.retCode) {
        let rows = [];
        rows.push(this.object);
        this.$refs["submit"].setOpenLevel(1);
        this.$refs["submit"].showSubmit(rows);
      }
    },
    async withdraw() {
      this.editParam.body.startTime =
        null !== this.orderForm.startTime
          ? parseTime(this.orderForm.startTime)
          : null;
      this.editParam.body.endTime =
        null !== this.orderForm.endTime
          ? parseTime(this.orderForm.endTime)
          : null;
      this.editParam.body.name = this.orderForm.videoName;
      this.editParam.body.path = this.orderForm.videoPath;
      this.editParam.body.workOrderNo = this.orderForm.workOrderNo;
      this.editParam.body.operAction = "工单退回";
      /**接口新增参数 */
      this.editParam.body.userNo = this.userNo;
      this.editParam.body.userRoleNo = getRoleKey("supervisor", this.roles);
      this.editParam.body.console = "supervisor";

      const { header } = await edit(this.editParam);
      if ("1" === header.retCode) {
        let rows = [];
        rows.push(this.object);
        this.$refs["withdraw"].showWithdraw(rows, "editView");
      }
      // this.$refs["orderForm"].resetFields();

      // this.$emit("fetchData");
      // this.orderForm = this.$options.data().orderForm;
    },
    eighthBitTime(row, column) {
      return eighthBitTime(row, column);
    },
    /** 当前dialog关闭 */
    handleClose() {
      this.fullFlag = false;
      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
      this.$refs["orderForm"].resetFields();
      this.$refs["evidence"].reset();
      this.dialogFormVisible = false;
    },
    /** 全屏/半屏切换 */
    triggerFullOrHelf() {
      this.fullFlag = !this.fullFlag;
      if (this.fullFlag) {
        this.fullIcon = "el-icon-minus";
        this.fullTitle = "最小化";
      } else {
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      }
    },
    /** 附件下载 */
    handleDownload(item) {
      isExistOSS(this.OSSClient, item.path)
        .then(() => {
          downloadFile(item);
        })
        .catch(() => {
          this.$message.error("当前文件不存在！");
        });
    },
    // 生成报告
    async handleReport(row) {
      this.reportForm.header.currentTime = returnBitTimestamp(new Date());
      this.reportForm.body.workOrderNo = this.orderNo;
      const { header } = await generateReport(this.reportForm);
      if (header.retCode === "1") {
        downloadReport(`/site/downOssAndTemp?workOrderNo=${this.orderNo}`);
      } else {
        this.$baseMessage(`${header.retMessage}`, "error");
      }
    },
    getProjectSource(code) {
      return getProjectSource(code);
    },
    // 修改内容标黄
    brChange(data) {
      if (
        this.tableData.length &&
        this.tableData[0].siteBrUpdateColumnRes &&
        this.tableData[0].siteBrUpdateColumnRes[data]
      ) {
        return "is-change";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
body {
  font-weight: 700;
  color: #666;
}
.edit_title .title {
  margin: 10px 0;
}
.el-step__title {
  font-size: 14px;
}
.el-step__title.is-process {
  font-weight: bold;
  color: #666;
}
html body .el-table th .cell {
  font-weight: 700;
  color: #666;
}

.el-form-item__label {
  font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100% !important;
}

.el-select,
.el-cascader {
  width: 100%;
}
.my-textarea > textarea {
  height: 32px;
}
.el-table .el-table__body td.is-change {
  background: #ffff9f;
}
</style>
