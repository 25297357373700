<!--Kyle 质控工作台 ->  工单列表-->
<template>
  <el-card class="box-card">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            icon="el-icon-search"
            plain
            native-type="submit"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            type="info"
            plain
            native-type="submit"
            @click="resetForm('queryForm')"
          >
            重置
          </el-button>
          <el-button
            icon="el-icon-sort"
            plain
            native-type="submit"
            @click="heightQuery"
          >
            高级筛选
          </el-button>

          <el-form
            ref="queryForm"
            :model="queryForm"
            style="margin-top: 15px"
            class="demo-form-inline my-form-class"
          >
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="orderDate">
                  <el-date-picker
                    v-model="queryForm.orderDate"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="下单开始日期"
                    end-placeholder="下单结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input
                    v-model.number="queryForm.workOrderNo"
                    clearable
                    placeholder="工单编号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input
                    v-model.trim="queryForm.targetSubject"
                    clearable
                    placeholder="目标主体"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader
                    :value="queryForm.projectId"
                    :options="projectInfo"
                    clearable
                    :filterable="true"
                    separator=":"
                    placeholder="项目"
                    @change="handleProjectChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <transition name="fade">
              <el-row v-show="isShow" transiton="fade" :gutter="20">
                <!-- 预约走访时间 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="expectVisitDate">
                    <el-date-picker
                      v-model="queryForm.expectVisitDate"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="预约走访开始日期"
                      end-placeholder="预约走访结束日期"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 选择地区 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop=" address">
                    <el-form-item prop="selectedOptions">
                      <el-cascader
                        v-model="queryForm.address"
                        :options="cityInfo"
                        :filterable="true"
                        clearable
                        separator="/"
                        placeholder="地区"
                        :props="{ checkStrictly: true }"
                        @change="handleBlockChange"
                      ></el-cascader>
                    </el-form-item>
                  </el-form-item>
                </el-col>
                <!-- 工单状态 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="workOrderStatus">
                    <el-select
                      v-model="queryForm.workOrderStatus"
                      placeholder="工单状态"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in orderStatusInfo"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- PL人员 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="plUser">
                    <el-select
                      v-model="queryForm.plUser"
                      placeholder="PL人员"
                      clearable
                      filterable
                      @focus="getRoleOption('pL')"
                    >
                      <el-option
                        v-for="item in roleOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <!-- 走访时间 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="visitDate">
                    <el-date-picker
                      v-model="queryForm.visitDate"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="走访开始日期"
                      end-placeholder="走访结束日期"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 内审人员 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="auditUser">
                    <el-select
                      v-model="queryForm.auditUser"
                      placeholder="内审人员"
                      clearable
                      filterable
                      @focus="getRoleOption('audit')"
                    >
                      <el-option
                        v-for="item in roleOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="surveyUser">
                    <el-select
                      v-model="queryForm.surveyUser"
                      placeholder="调查人员"
                      clearable
                      filterable
                      @focus="getRoleOption('surveyUser')"
                    >
                      <el-option
                        v-for="item in roleOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 督导人员 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="monitorUser">
                    <el-select
                      v-model="queryForm.monitorUser"
                      placeholder="督导人员"
                      clearable
                      filterable
                      @focus="getRoleOption('supervisor')"
                    >
                      <el-option
                        v-for="item in roleOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 内部截止 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="innerDeadDate">
                    <el-date-picker
                      v-model="queryForm.innerDeadDate"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="内部截止开始日期"
                      end-placeholder="内部截止结束日期"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>

                <!-- 会议日期 -->
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item prop="preliminaryDate">
                    <el-date-picker
                      v-model="queryForm.preliminaryDate"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="初审开始日期"
                      end-placeholder="初审结束日期"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </transition>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            :loading="downloadLoading"
            type="primary"
            @click="handleDownload"
          >
            导出
          </el-button>
          <el-table
            ref="tableSort"
            v-loading="listLoading"
            :data="list"
            :element-loading-text="elementLoadingText"
            style="margin-top: 15px"
            border
            @selection-change="setSelectRows"
          >
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column
              label="工单编号"
              width="130"
              prop="workOrderNo"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="targetSubject"
              label="目标主体"
              width="150"
            ></el-table-column>
            <el-table-column label="目标地址" min-width="250" prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目" prop="projectName" width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="visitTime"
              label="走访时间"
              align="center"
              width="130"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.visitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="innerDeadline"
              label="内部截止日期"
              align="center"
              width="130"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.innerDeadline || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="nowUser"
              label="当前操作人"
              width="120"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.nowUser || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="workOrderStatusValue"
              label="工单状态"
              width="110"
              align="center"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.workOrderStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="stepAndStranded"
              align="center"
              label="环节/整体滞留(天)"
              fit="true"
              width="90"
            >
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100px"
              fixed="right"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="详情"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-document"
                    circle
                    @click="goDetailPage(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNum"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
  import { getOrderList } from "@/api/workplace/qualityControl/qualityControlIndex";
  import { returnBitTimestamp, returnDeadtimestamp, getRoleKey } from "@/utils";
  import { mapGetters } from "vuex";
  import {
    getAllReasonOption,
    getBlock,
    getProjectSource,
    getRole,
    getWorkOrderStatus,
  } from "@/api/workplace/tools";

  export default {
    name: "IndexVue",
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: "success",
          draft: "gray",
          deleted: "danger",
        };
        return statusMap[status];
      },
    },
    data() {
      return {
        isShow: false,
        imgShow: true,
        // 批量上传附件
        commitAnnex: false,
        title: [],
        list: [],
        imageList: [],
        listLoading: true,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
        background: true,
        selectRows: "",
        elementLoadingText: "正在加载...",
        pickerOptions: {
          shortcuts: [
            {
              text: "最近一周",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近三个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              },
            },
          ],
        },
        queryForm: {
          orderDate: [], // 表单数据，不传递到后端
          orderTimeEnd: null,
          orderTimeStart: null,
          workOrderNo: "",
          targetSubject: "",
          projectId: "",

          expectVisitDate: [], // 表单数据，不传递到后端
          bookVisitTimeStart: null,
          bookVisitTimeEnd: null,
          address: "",
          workOrderStatus: null,
          rejectReasonId: null,

          visitDate: [], // 表单数据，不传递到后端
          clockEndTime: null,
          clockStartTime: null,
          plUser: null,
          auditUser: null,
          abandonContentId: null,

          innerDeadDate: [], // 表单数据，不传递到后端
          innerDeadlineEnd: null,
          innerDeadlineStart: null,
          preliminaryDate: null, // 表单数据，不传递到后端
          firstAuditDateEnd: null,
          firstAuditDateStart: null,
          surveyUser: null,
          monitorUser: null,

          pageNum: 1,
          pageSize: 10,
          nextUserNo: "",
          nextUserRoleNo: "",
          userNo: "",
          userRoleNo: "",
        },
        // 质控工作台查询工单列表参数
        param: {
          body: {},
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "21122",
            sourceType: "site",
          },
        },

        // 项目
        projectInfo: [],
        projectForm: {
          body: {
            source: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },

        cityInfo: [],
        cityForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },

        // 工单状态
        orderStatusInfo: [],
        orderStatusForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        // 退回原因form
        allBackInfo: [],
        allBackForm: {
          body: {
            templateType: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        allAbandonInfo: [],
        // 退回原因form
        allAbandonForm: {
          body: {
            templateType: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },

        // 操作状态
        operStatusInfo: [],
        operStatusForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },

        // 角色人员
        roleOptions: [],
        roleForm: {
          body: {},
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        downloadLoading: false,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.queryForm.userNo = this.userNo;
      this.queryForm.userRoleNo = getRoleKey("qc", this.roles);
      this.fetchData();
      // 获取项目
      this.getProject();
    },
    methods: {
      /** 导出Excel */
      handleDownload() {
        if (this.selectRows.length > 0) {
          this.downloadLoading = true;
          import("@/vendor/ExportExcel").then((excel) => {
            const tHeader = [
              "工单编号",
              "目标主体",
              "目标地址",
              "项目",
              "走访时间",
              "内部截止日期",
              "当前操作人",
              "工单状态",
              "环节/整体滞留(天)",
            ];
            const filterVal = [
              "workOrderNo",
              "targetSubject",
              "address",
              "projectName",
              "visitTime",
              "innerDeadline",
              "nowUserName",
              "workOrderStatusValue",
              "stepAndStranded",
            ];
            const list = this.selectRows;
            const data = this.formatJson(filterVal, list);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: "工单列表",
              autoWidth: true,
              bookType: "xlsx",
            });
            this.downloadLoading = false;
          });
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      },
      /** 组装导出数据 */
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if (!v[j]) {
              return "——";
            }
            return v[j];
          })
        );
      },
      // 质控工作台查询工单列表
      async fetchData() {
        this.listLoading = true;
        // 下单日期范围
        if (null !== this.queryForm.orderDate) {
          if (0 < this.queryForm.orderDate.length) {
            this.queryForm.orderTimeStart = returnBitTimestamp(
              this.queryForm.orderDate[0]
            );
            this.queryForm.orderTimeEnd = returnDeadtimestamp(
              this.queryForm.orderDate[1]
            );
          }
        } else {
          this.queryForm.orderTimeStart = null;
          this.queryForm.orderTimeEnd = null;
        }
        // 预约走访时间范围
        if (null !== this.queryForm.expectVisitDate) {
          if (0 < this.queryForm.expectVisitDate.length) {
            this.queryForm.bookVisitTimeStart = returnBitTimestamp(
              this.queryForm.expectVisitDate[0]
            );
            this.queryForm.bookVisitTimeEnd = returnDeadtimestamp(
              this.queryForm.expectVisitDate[1]
            );
          }
        } else {
          this.queryForm.bookVisitTimeStart = null;
          this.queryForm.bookVisitTimeEnd = null;
        }
        // 走访日期范围
        if (null !== this.queryForm.visitDate) {
          if (0 < this.queryForm.visitDate.length) {
            this.queryForm.clockStartTime = returnBitTimestamp(
              this.queryForm.visitDate[0]
            );
            this.queryForm.clockEndTime = returnDeadtimestamp(
              this.queryForm.visitDate[1]
            );
          }
        } else {
          this.queryForm.clockStartTime = null;
          this.queryForm.clockEndTime = null;
        }
        // 内部截止日期范围
        if (null !== this.queryForm.innerDeadDate) {
          if (0 < this.queryForm.innerDeadDate.length) {
            this.queryForm.innerDeadlineStart = returnBitTimestamp(
              this.queryForm.innerDeadDate[0]
            );

            this.queryForm.innerDeadlineEnd = returnDeadtimestamp(
              this.queryForm.innerDeadDate[1]
            );
          }
        } else {
          this.queryForm.innerDeadlineStart = null;
          this.queryForm.innerDeadlineEnd = null;
        }
        // 初审开始日期范围
        if (null !== this.queryForm.preliminaryDate) {
          if (0 < this.queryForm.preliminaryDate.length) {
            this.queryForm.firstAuditDateStart = returnBitTimestamp(
              this.queryForm.preliminaryDate[0]
            );

            this.queryForm.firstAuditDateEnd = returnDeadtimestamp(
              this.queryForm.preliminaryDate[1]
            );
          }
        } else {
          this.queryForm.firstAuditDateStart = null;
          this.queryForm.firstAuditDateEnd = null;
        }
        this.param.body = Object.assign({}, this.queryForm);
        const { body } = await getOrderList(this.param);
        this.list = body.records;
        this.total = body.total;
        this.$emit("onChange", this.total);
        setTimeout(() => {
          this.listLoading = false;
        }, 500);
      },

      setSelectRows(val) {
        this.selectRows = val;
      },
      // 重置
      resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.fetchData();
      },
      heightQuery() {
        this.isShow = !this.isShow;
        // 获取角色列表
        this.getRoleList();
        // 获取地区
        this.getAddress();
        // 获取工单状态
        this.getOrderStatus();
        // 退回原因
        this.getAllBack();
        // 放弃原因
        this.getAllAbandon();
      },
      handleQuery() {
        this.queryForm.pageNum = 1;
        this.fetchData();
      },
      // 打开工单单详情页面
      goDetailPage(row) {
        this.$router.push({
          path: "orderList/detail",
          query: {
            target: row.targetSubject,
            workOrderNo: row.workOrderNo,
            address: row.address,
            projectName: row.projectName,
            projectId: row.projectId,
            seqId: row.seqId,
            userNo: this.userNo,
            userRoleNo: getRoleKey("qc", this.roles),
          },
        });
      },

      // 获取项目
      getProject() {
        this.projectForm.header.currentTime = returnBitTimestamp(new Date());
        getProjectSource(this.projectForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.projectInfo = response.body;
          }
        });
      },
      // 选择项目
      handleProjectChange(value) {
        this.queryForm.projectId = value.length > 0 ? value[1] : null;
      },
      // 获取地区
      getAddress() {
        this.cityForm.header.currentTime = returnBitTimestamp(new Date());
        getBlock(this.cityForm).then((response) => {
          if ("1" === response.header.retCode) {
            let block_info = [];
            block_info.push(response.body[0]);
            this.cityInfo = block_info;
          } else {
            this.$message.error(
              `${response.header.retMessage},获取地区失败，请重新刷新页面`
            );
          }
        });
      },
      // 选择地区
      handleBlockChange(value) {
        this.queryForm.address = value.join(",");
      },
      // 获取工单状态
      getOrderStatus() {
        this.orderStatusForm.header.currentTime = returnBitTimestamp(
          new Date()
        );
        getWorkOrderStatus(this.orderStatusForm).then((response) => {
          if (response.header.retCode === "1") {
            this.orderStatusInfo = response.body;
          } else {
            this.$message.error(
              `${response.header.retMessage},获取工单状态失败，请重新刷新页面`
            );
          }
        });
      },
      // 获取退回原因 普通退回+待定退回
      getAllBack() {
        this.allBackForm.header.currentTime = returnBitTimestamp(new Date());
        this.allBackForm.body.templateType = "81";
        getAllReasonOption(this.allBackForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.allBackInfo = response.body;
          }
        });
      },
      // 获取放弃原因 上门前放弃&上门后
      getAllAbandon() {
        this.allAbandonForm.header.currentTime = returnBitTimestamp(new Date());
        this.allAbandonForm.body.templateType = "80";
        getAllReasonOption(this.allAbandonForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.allAbandonInfo = response.body;
          }
        });
      },
      // 获取角色配置项
      getRoleList() {
        let role_arr = [];
        let roleForm = {
          body: [],
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        };
        let roleOptions_arr = [];
        // PL
        const pL = [
          {
            roleKey: "PL",
          },
        ];
        // 预约人员
        const bookUser = [
          {
            roleKey: "book_person",
          },
        ];
        // 调查人员
        const surveyUser = [
          {
            roleKey: "full_investigator",
          },
          {
            roleKey: "part_investigator",
          },
        ];
        // 督导人员
        const supervisor = [
          {
            roleKey: "supervisor",
          },
        ];
        // 内审人员
        const audit = [
          {
            roleKey: "verify_people",
          },
        ];
        // 用户
        const user = [
          {
            roleKey: "customer",
          },
        ];
        role_arr.push(pL, bookUser, surveyUser, supervisor, audit, user);
        // 异步调用接口，使其按顺序执行
        async function getAsyncRole() {
          roleForm.header.currentTime = returnBitTimestamp(new Date());
          for (let index = 0; index < role_arr.length; index++) {
            roleForm.body = role_arr[index];
            await getRole(roleForm).then((response) => {
              if (response.header.retCode === "1") {
                roleOptions_arr.push(response.body);
              } else {
                if (index === 1) {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},请重新刷新页面，获取预约人员选择信息`
                  );
                } else if (index === 2) {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},请重新刷新页面，获取调查人员选择信息`
                  );
                } else if (index === 3) {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},请重新刷新页面，获取调查人员选择信息`
                  );
                } else if (index === 4) {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},请重新刷新页面，获取内审人员选择信息`
                  );
                } else if (index === 5) {
                  this.$message.error(
                    `错误信息: ${response.header.retMessage},请重新刷新页面，获取用户选择信息`
                  );
                }
                roleOptions_arr.push([]);
              }
            });
            if (index === role_arr.length - 1) {
              return roleOptions_arr;
            }
          }
        }
        getAsyncRole().then((res) => {
          this.roleOptions_arr = res;
        });
      },
      // 根据角色匹配相关配置项
      getRoleOption(role) {
        switch (role) {
          case "pL":
            this.roleOptions = this.roleOptions_arr[0];
            break;
          case "bookUser":
            this.roleOptions = this.roleOptions_arr[1];
            break;
          case "surveyUser":
            this.roleOptions = this.roleOptions_arr[2];
            break;
          case "supervisor":
            this.roleOptions = this.roleOptions_arr[3];
            break;
          case "audit":
            this.roleOptions = this.roleOptions_arr[4];
            break;
          case "user":
            this.roleOptions = this.roleOptions_arr[5];
            break;
          default:
            break;
        }
      },
    },
  };
</script>
<style scoped>
  .el-col {
    margin-bottom: 10px;
  }
  .el-col :last-child {
    margin-bottom: 0;
  }

  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100%;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }
  .el-table /deep/.warning-row {
    background: #f8b7bc;
  }
  .my-form-class {
    margin-bottom: -10px !important;
  }
</style>
