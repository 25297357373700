<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class"
                   @submit.native.prevent>
            <el-row :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 请选择流转日期范围 -->
                <el-form-item prop="circulationTime">
                  <el-date-picker v-model="queryForm.circulationTime"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="流转开始日期"
                                  end-placeholder="流转结束日期"
                                  :picker-options="pickerOptions"
                                  clearable></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number="queryForm.workOrderNo"
                            placeholder="工单编号"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            placeholder="目标主体"></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               clearable
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="isShow"
                    transiton="fade"
                    :gutter="20">
              <!-- 走访日期范围 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="expectVisitDate">
                  <el-date-picker v-model="queryForm.expectVisitDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="走访开始日期"
                                  end-placeholder="走访结束日期"
                                  :picker-options="pickerOptions"
                                  clearable></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 选择地区 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="selectedOptions">
                  <el-cascader v-model="queryForm.address"
                               :options="cityInfo"
                               :filterable="true"
                               :clearable="true"
                               separator="/"
                               placeholder="选择地区"
                               :props="{ checkStrictly: true }"
                               @change="handleBlockChange"></el-cascader>
                </el-form-item>
              </el-col>
              <!-- 是否普通退回 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="normalFallbackFlag">
                  <el-select v-model="queryForm.normalFallbackFlag"
                             placeholder="是否普通退回"
                             clearable
                             filterable>
                    <el-option v-for="item in isGiveBacks"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <!--审核状态-->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="auditStatus">
                  <el-select v-model="queryForm.auditStatus"
                             placeholder="审核状态"
                             clearable
                             filterable>
                    <el-option v-for="item in auditStatusOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-show="isShow"
                    transiton="fade"
                    :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="innerDeadDate">
                  <el-date-picker v-model="queryForm.innerDeadDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="内部截止开始日期"
                                  end-placeholder="内部截止结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="orderDate">
                  <el-date-picker v-model="queryForm.orderDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"
                                  clearable></el-date-picker>
                </el-form-item>
              </el-col>

              <!-- 选择人员 所有角色+名字（二级联动） -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="userInfo">
                  <el-cascader :value="queryForm.userInfo"
                               :options="staffInfo"
                               clearable
                               :filterable="true"
                               separator=" "
                               placeholder="选择人员"
                               @change="handleSatffChange"></el-cascader>
                </el-form-item>
              </el-col>

              <!-- 到期时间 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="reachTime">
                  <el-select v-model="queryForm.reachTime"
                             placeholder="到期时间"
                             clearable
                             filterable>
                    <el-option v-for="item in reachTimeOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 订单编号 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="brWorkNo">
                  <el-input v-model.trim="queryForm.brWorkNo"
                            placeholder="订单编号"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 报告类型 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="reportType">
                  <el-select v-model="queryForm.reportType"
                             placeholder="报告类型"
                             clearable
                             filterable>
                    <el-option v-for="item in reportType"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 项目来源 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectSource">
                  <el-select v-model="queryForm.projectSource"
                             placeholder="请选择项目来源"
                             clearable
                             filterable>
                    <el-option v-for="item in resourceOptions"
                               :key="item.key"
                               :label="item.name"
                               :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button :loading="downloadLoading"
                     type="primary"
                     @click="handleDownload">
            导出
          </el-button>
          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    style="margin-top: 15px"
                    :element-loading-text="elementLoadingText"
                    :row-class-name="tableRowClassName"
                    border
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"></el-table-column>
            <el-table-column label="工单编号"
                             prop="OrderNum"
                             align="center"
                             width="130">
              <template slot-scope="scope">
                <div class="img-icon">
                  <img v-if="scope.row.operStatus === 31"
                       src="@/icon/back.svg"
                       alt="退"
                       class="my-back-img" />
                  <img v-if="scope.row.brChangeFlag"
                       src="@/icon/edit.svg"
                       alt="修"
                       class="my-edit-img" />
                </div>
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             width="150"
                             label="目标主体"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="审核状态"
                             prop="auditStatusValue"
                             width="90"
                             align="center"
                             fit>
              <template slot-scope="scope">
                {{ scope.row.auditStatusValue || "-" }}
              </template>
            </el-table-column>
            <!-- 预约走访时间 -->
            <el-table-column prop="bookVisitTime"
                             label="预约走访时间"
                             width="130"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="visitTime"
                             label="走访时间"
                             align="center"
                             width="160">
              <template slot-scope="scope">
                {{ scope.row.visitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="circulationTime"
                             label="流转日期"
                             align="center"
                             width="120">
              <template slot-scope="scope">
                {{ scope.row.circulationTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             align="center"
                             width="120">
              <template slot-scope="scope">
                {{ scope.row.innerDeadline || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="maturityDate"
                             label="到期时间(天)"
                             align="center"
                             width="70">
              <template slot-scope="scope">
                {{ scope.row.maturityDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="monitorUserName"
                             label="督导人员"
                             width="110"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.monitorUserName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             label="环节/整体滞留(天)"
                             width="90"
                             fit="true">
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="80px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="审核"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-s-check"
                             :disabled="scope.row.fallbackFlag === '6694249455245463552'"
                             circle
                             @click="handleReview(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!--审核工单-->
    <review ref="review"
            @refreshData="refreshData"></review>
  </div>
</template>

<script>
import { getTodo } from "@/api/workplace/internalAudit/internalAudit";
import review from "./review";
import { mapGetters } from "vuex";
import { querySystemUsers } from "@/api/workplace/tools";
import { returnBitTimestamp, returnDeadtimestamp, getRoleKey } from "@/utils";
import {
  getBlock,
  getGeneralReason,
  getProjectSource,
  getRole,
  // 放弃原因
  getAllReasonOption,
  getReportTypeList,
} from "@/api/workplace/tools";

export default {
  name: "ComprehensiveTable",
  components: {
    review,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      isShow: false,
      list: [],

      listLoading: false,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      background: true,
      selectRows: "",
      elementLoadingText: "正在加载...",
      auditStatusOptions: [
        {
          value: 1,
          label: "正常",
        },
        {
          value: 0,
          label: "放弃",
        },
      ],
      giveUpOptions: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      queryForm: {
        circulationTime: [], // 表单数据，不传递到后端 流转日期范围
        circulationTimeEnd: null,
        circulationTimeStart: null,
        workOrderNo: "",
        targetSubject: "",
        projectId: "",

        expectVisitDate: [], //走访日期 // 表单数据，不传递到后端
        clockStartTime: null,
        clockEndTime: null,
        address: "",
        normalFallbackFlag: "",
        auditStatus: "",

        innerDeadDate: [], // 表单数据，不传递到后端
        innerDeadlineEnd: null,
        innerDeadlineStart: null,
        orderDate: [], // 表单数据，不传递到后端
        orderTimeEnd: null,
        orderTimeStart: null,
        userInfo: null, // 人员信息
        roleUser: null, // 选择人员参数
        surveyUser: "",
        reachTime: "",

        pageNum: 1,
        pageSize: 10,
        nextUserNo: "",
        nextUserRoleNo: "",
        userNo: "",
        userRoleNo: "",
      },
      // 查询工单内审待处理的工单参数
      param: {
        body: {},
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 项目
      projectInfo: [],
      projectForm: {
        body: {
          source: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      //   角色人员
      roleOptions: [],
      roleOptions_arr: [],
      roleForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },

      cityInfo: [],
      cityForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      // 选择到期时间
      reachTimeOptions: [
        {
          value: 0.5,
          label: "0.5天",
        },
        {
          value: 1,
          label: "1天",
        },
        {
          value: 2,
          label: "2天",
        },
        {
          value: 3,
          label: "3天",
        },
        {
          value: 5,
          label: "5天",
        },
        {
          value: 7,
          label: "7天",
        },
        {
          value: 10,
          label: "10天",
        },
        {
          value: 15,
          label: "15天",
        },
        {
          value: 20,
          label: "20天",
        },
        {
          value: 30,
          label: "30天",
        },
      ],
      allAbandonInfo: [],
      allAbandonForm: {
        body: {
          templateType: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      quitReasonOptions: [],
      // 获取放弃原因 上门前放弃&上门后
      getAllAbandon() {
        this.allAbandonForm.header.currentTime = returnBitTimestamp(new Date());
        this.allAbandonForm.body.templateType = "80";
        getAllReasonOption(this.allAbandonForm).then((response) => {
          if ("1" === response.header.retCode) {
            this.allAbandonInfo = response.body;
          }
        });
      },
      isGiveBacks: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      //  退回原因参数
      backReasonParam: {
        body: {
          templateTypeSeqId: "6694249429643431936",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      reasonOptions: [],
      downloadLoading: false,
      staffInfo: [],
      staffForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      reportType: [], // 报告类型
      // 项目来源
      resourceOptions: [
        {
          name: "香港业务流程",
          key: "process_hk",
        },
        {
          name: "本地业务流程",
          key: "process_nd",
        },
        {
          name: "BR业务流程",
          key: "process_br",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
    }),
  },
  created() {
    this.queryForm.userNo = this.userNo;
    this.queryForm.userRoleNo = getRoleKey("verify_people", this.roles);
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    /** 数据初始化 */
    handleInitData() {
      this.fetchData();
      // 获取项目
      this.getProject();

      // 获取人员选项
      this.getStaffInfo();
      // 获取报告类型
      this.getReportType();
    },
    /** 导出Excel */
    handleDownload() {
      if (this.selectRows.length > 0) {
        this.downloadLoading = true;
        import("@/vendor/ExportExcel").then((excel) => {
          const tHeader = [
            "工单编号",
            "订单编号",
            "目标主体",
            "目标地址",
            "项目",
            "报告类型",
            "二级类型",
            "审核状态",
            "预约走访时间",
            "走访时间",
            "流转日期",
            "内部截止日期",
            "到期时间(天)",
            "督导人员",
            "环节/整体滞留(天)",
          ];
          const filterVal = [
            "workOrderNo",
            "brWorkNo",
            "targetSubject",
            "address",
            "projectName",
            "reportType",
            "subReportType",
            "auditStatusValue",
            "bookVisitTime",
            "visitTime",
            "circulationTime",
            "innerDeadline",
            "maturityDate",
            "monitorUserName",
            "stepAndStranded",
          ];
          const list = this.selectRows;
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: "工单列表",
            autoWidth: true,
            bookType: "xlsx",
          });
          this.downloadLoading = false;
        });
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },
    /** 组装导出数据 */
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (!v[j]) {
            return "——";
          }
          return v[j];
        })
      );
    },
    // 刷新数据
    async refreshData() {
      /* 添加tab标签  传参 */
      let refresh_data = {
        todoVal: this.total,
        isDone: true,
        isQuery: false,
        isTodo: false,
      };
      this.$emit("child-todo", refresh_data);
      this.fetchData();
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    // 重置
    resetForm(formName) {
      if (this.$refs[formName] !== undefined) {
        // this.$refs[formName].resetFields();
        this.queryForm = this.$options.data().queryForm;
        this.reset = this.$options.data().reset;
      }
    },

    // 单个提交及批量提交
    handleSubmit(row) {
      // 单个提交
      this.$refs["submit"].showSubmit(row);
    },

    handleReview(row) {
      row.userNo = this.queryForm.userNo;
      row.userRoleNo = this.queryForm.userRoleNo;
      this.$refs["review"].showReview(row);
    },
    // 单个退回及批量退回
    handleWithdraw(row) {
      this.$refs["withdraw"].showWithdraw(row);
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    heightQuery() {
      this.isShow = !this.isShow;
      // 获取角色列表
      this.getRoleList();
      // 获取地区
      this.getAddress();
      // 获取普通退回原因
      this.getNormalReason();
      // 放弃原因
      this.getAllAbandon();
    },
    async getNormalReason() {
      this.reasonOptions = Object.assign(
        {},
        (await getGeneralReason(this.backReasonParam)).body
      );
    },
    //add by peter.wei 20200911
    handleChange(value) {
      this.queryForm.addressProvince = this.queryForm.selectedOptions[0];
      this.queryForm.addressCity = this.queryForm.selectedOptions[1];
      this.queryForm.addressDistrict = this.queryForm.selectedOptions[2];
    },
    handleQuery() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },
    // 获取用户工作台待办列表分页信息
    async fetchData() {
      this.listLoading = true;
      // 选择人员数据
      if (this.queryForm.userInfo && this.queryForm.userInfo.length > 0) {
        this.queryForm.roleUser = `${this.queryForm.userInfo[0]}:${this.queryForm.userInfo[1]}`;
      } else {
        this.queryForm.roleUser = "";
      }
      // 流转日期范围
      if (null !== this.queryForm.circulationTime) {
        if (0 < this.queryForm.circulationTime.length) {
          this.queryForm.circulationTimeStart = returnBitTimestamp(
            this.queryForm.circulationTime[0]
          );
          this.queryForm.circulationTimeEnd = returnDeadtimestamp(
            this.queryForm.circulationTime[1]
          );
        }
      } else {
        this.queryForm.circulationTimeStart = null;
        this.queryForm.circulationTimeEnd = null;
      }
      // 下单日期范围
      if (null !== this.queryForm.orderDate) {
        if (0 < this.queryForm.orderDate.length) {
          this.queryForm.orderTimeStart = returnBitTimestamp(
            this.queryForm.orderDate[0]
          );
          this.queryForm.orderTimeEnd = returnDeadtimestamp(
            this.queryForm.orderDate[1]
          );
        }
      } else {
        this.queryForm.orderTimeStart = null;
        this.queryForm.orderTimeEnd = null;
      }
      // 走访日期范围
      if (null !== this.queryForm.expectVisitDate) {
        if (0 < this.queryForm.expectVisitDate.length) {
          this.queryForm.clockStartTime = returnBitTimestamp(
            this.queryForm.expectVisitDate[0]
          );
          this.queryForm.clockEndTime = returnDeadtimestamp(
            this.queryForm.expectVisitDate[1]
          );
        }
      } else {
        this.queryForm.clockStartTime = null;
        this.queryForm.clockEndTime = null;
      }
      // 内部截止日期范围
      if (null !== this.queryForm.innerDeadDate) {
        if (0 < this.queryForm.innerDeadDate.length) {
          this.queryForm.innerDeadlineStart = returnBitTimestamp(
            this.queryForm.innerDeadDate[0]
          );

          this.queryForm.innerDeadlineEnd = returnDeadtimestamp(
            this.queryForm.innerDeadDate[1]
          );
        }
      } else {
        this.queryForm.innerDeadlineStart = null;
        this.queryForm.innerDeadlineEnd = null;
      }

      this.queryForm.userNo = this.userNo;
      this.queryForm.userRoleNo = getRoleKey("verify_people", this.roles);
      this.param.header.currentTime = returnBitTimestamp(new Date());
      this.param.body = Object.assign({}, this.queryForm);
      const { body } = await getTodo(this.param);
      this.list = body.records;
      this.total = body.total;

      let refresh_data = {
        todoVal: this.total,
        isDone: false,
        isQuery: false,
        isTodo: false,
      };
      this.$emit("child-todo", refresh_data);
      this.listLoading = false;
    },

    // 表格显色
    tableRowClassName({ row }) {
      // 到期时间小于3，行内高亮
      if (row.maturityDate <= 3) {
        return "warning-row";
      } else return "";
    },

    // 获取地区
    getAddress() {
      this.cityForm.header.currentTime = returnBitTimestamp(new Date());
      getBlock(this.cityForm).then((response) => {
        if ("1" === response.header.retCode) {
          let block_info = [];
          block_info.push(response.body[0]);
          this.cityInfo = block_info;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取地区失败，请重新刷新页面`
          );
        }
      });
    },
    // 选择地区
    handleBlockChange(value) {
      this.queryForm.address = value.join(",");
    },
    // 获取项目
    getProject() {
      this.projectForm.header.currentTime = returnBitTimestamp(new Date());
      getProjectSource(this.projectForm).then((response) => {
        if ("1" === response.header.retCode) {
          this.projectInfo = response.body;
        }
      });
    },
    // 选择项目
    handleProjectChange(value) {
      if (value) {
        this.queryForm.projectId = value[1];
      }
    },
    getRoleList() {
      let role_arr = [];
      let roleForm = {
        body: [],
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      };
      let roleOptions_arr = [];
      // 预约人员
      const bookUser = [
        {
          roleKey: "book_person",
        },
      ];

      // 调查人员
      const surveyUser = [
        {
          roleKey: "full_investigator",
        },
        {
          roleKey: "part_investigator",
        },
      ];

      // 督导人员
      const supervisor = [
        {
          roleKey: "supervisor",
        },
      ];

      role_arr.push(bookUser);
      role_arr.push(surveyUser);
      role_arr.push(supervisor);
      // 异步调用接口，使其按顺序执行
      async function getAsyncRole() {
        roleForm.header.currentTime = returnBitTimestamp(new Date());
        for (let index = 0; index < role_arr.length; index++) {
          roleForm.body = role_arr[index];
          await getRole(roleForm).then((response) => {
            if ("1" === response.header.retCode) {
              roleOptions_arr.push(response.body);
            } else {
              roleOptions_arr.push([]);
            }
          });
          if (index === role_arr.length - 1) {
            return roleOptions_arr;
          }
        }
      }
      getAsyncRole().then((res) => {
        this.roleOptions_arr = res;
      });
    },
    // 获取角色配置项
    getRoleOption(role) {
      switch (role) {
        case "bookUser":
          this.roleOptions = this.roleOptions_arr[0];
          break;
        case "surveyUser":
          this.roleOptions = this.roleOptions_arr[1];
          break;
        case "supervisor":
          this.roleOptions = this.roleOptions_arr[2];
          break;
        default:
          break;
      }
    },
    // 获取人员信息
    async getStaffInfo() {
      this.staffForm.header.currentTime = returnBitTimestamp(new Date());
      const { header, body } = await querySystemUsers(this.staffForm);
      if (header.retCode === "1") {
        this.staffInfo = body;
      }
    },
    // 选择人员数据框改变值
    handleSatffChange(value) {
      if (value) this.queryForm.userInfo = value;
    },
    // 获取报告分类
    getReportType() {
      getReportTypeList(this.projectForm).then((response) => {
        if (response.header.retCode === "1") {
          this.reportType = response.body;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-col {
  margin-bottom: 10px;
}
.el-col :last-child {
  margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}

.el-select,
.el-cascader {
  width: 100%;
}

.el-dropdown-menu__item {
  padding: 0;
}
.el-table /deep/.warning-row {
  background: #f8b7bc;
}
.el-table /deep/.change-row {
  background: #ffff9f;
}
.my-form-class {
  margin-bottom: -10px !important;
}
</style>
<style>
.my-back-img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 2px;
}
</style>
