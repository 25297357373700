var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.createFormVisible,
        width: "62%",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.createFormVisible = $event
        },
        close: _vm.createFormClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "createForm",
          attrs: {
            model: _vm.createForm,
            rules: _vm.createFormRules,
            "label-width": "80px",
            "label-position": "top",
          },
        },
        [
          _c("h3", [_vm._v("基础信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Project Name", prop: "projectNameNo" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.projectNameNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "projectNameNo", $$v)
                          },
                          expression: "createForm.projectNameNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目", prop: "project" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.projectOptions,
                          props: { expandTrigger: "hover" },
                          separator: ":",
                        },
                        model: {
                          value: _vm.createForm.project,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "project", $$v)
                          },
                          expression: "createForm.project",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目标主体", prop: "targetSubject" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.targetSubject,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "targetSubject", $$v)
                          },
                          expression: "createForm.targetSubject",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "国家/省份/城市/地域（县级市）",
                        prop: "block",
                      },
                    },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.cityInfo,
                          "change-on-select": false,
                          clearable: true,
                          filterable: true,
                          placeholder: "请选择地区",
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.createForm.block,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "block", $$v)
                          },
                          expression: "createForm.block",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目标地址1", prop: "targetAddress1" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.targetAddress1,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "targetAddress1", $$v)
                          },
                          expression: "createForm.targetAddress1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目标地址2", prop: "targetAddress2" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.targetAddress2,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "targetAddress2", $$v)
                          },
                          expression: "createForm.targetAddress2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目标联系人", prop: "targetPerson" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.targetPerson,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "targetPerson", $$v)
                          },
                          expression: "createForm.targetPerson",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "contactTel" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.contactTel,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "contactTel", $$v)
                          },
                          expression: "createForm.contactTel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "职务", prop: "position" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.position,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "position", $$v)
                          },
                          expression: "createForm.position",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单时间", prop: "placeOrderTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "请选择日期",
                          required: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.placeOrderTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "placeOrderTime", $$v)
                          },
                          expression: "createForm.placeOrderTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "内部截止日期",
                        prop: "internalDeadline",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "请选择日期",
                          required: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.internalDeadline,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "internalDeadline", $$v)
                          },
                          expression: "createForm.internalDeadline",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "对账ID", prop: "verifyID" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.verifyID,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "verifyID", $$v)
                          },
                          expression: "createForm.verifyID",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "SV Code", prop: "svCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.svCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "svCode", $$v)
                          },
                          expression: "createForm.svCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注1", prop: "remark1" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.remark1,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "remark1", $$v)
                          },
                          expression: "createForm.remark1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注2", prop: "remark2" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.remark2,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "remark2", $$v)
                          },
                          expression: "createForm.remark2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注3", prop: "remark3" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.remark3,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "remark3", $$v)
                          },
                          expression: "createForm.remark3",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("附加信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "预计走访日期",
                        prop: "expectedVisitDate",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "请选择日期",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.expectedVisitDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "expectedVisitDate", $$v)
                          },
                          expression: "createForm.expectedVisitDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "预计所需调查天数",
                        prop: "expectedInvestigateDay",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.expectedInvestigateDay,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.createForm,
                              "expectedInvestigateDay",
                              $$v
                            )
                          },
                          expression: "createForm.expectedInvestigateDay",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所需人手", prop: "requiredPersonNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.requiredPersonNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "requiredPersonNum", $$v)
                          },
                          expression: "createForm.requiredPersonNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "预计需时（小时）",
                        prop: "expectedHour",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.expectedHour,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "expectedHour", $$v)
                          },
                          expression: "createForm.expectedHour",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册地址", prop: "registAddress" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.registAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "registAddress", $$v)
                          },
                          expression: "createForm.registAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "目标主体注册状态",
                        prop: "targetNameStatus",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.targetNameStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "targetNameStatus", $$v)
                          },
                          expression: "createForm.targetNameStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "走访收费", prop: "visitPrice" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          autocomplete: "off",
                          "show-password": "",
                          type: "password",
                        },
                        model: {
                          value: _vm.createForm.visitPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "visitPrice", $$v)
                          },
                          expression: "createForm.visitPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "加急走访收费",
                        prop: "expeditedVisitPrice",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          autocomplete: "off",
                          "show-password": "",
                          type: "password",
                        },
                        model: {
                          value: _vm.createForm.expeditedVisitPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "expeditedVisitPrice", $$v)
                          },
                          expression: "createForm.expeditedVisitPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "再次走访收费", prop: "againVisitPrice" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          autocomplete: "off",
                          "show-password": "",
                          type: "password",
                        },
                        model: {
                          value: _vm.createForm.againVisitPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "againVisitPrice", $$v)
                          },
                          expression: "createForm.againVisitPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收费依据", prop: "chargeEvidence" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.chargeEvidence,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "chargeEvidence", $$v)
                          },
                          expression: "createForm.chargeEvidence",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目标机构1", prop: "targetAgency1" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.targetAgency1,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "targetAgency1", $$v)
                          },
                          expression: "createForm.targetAgency1",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目标机构2", prop: "targetAgency2" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.targetAgency2,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "targetAgency2", $$v)
                          },
                          expression: "createForm.targetAgency2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "目标地址预核结果",
                        prop: "targetAddressResult",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.createForm.targetAddressResult,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.createForm,
                                "targetAddressResult",
                                $$v
                              )
                            },
                            expression: "createForm.targetAddressResult",
                          },
                        },
                        _vm._l(_vm.addressPreVerify, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "不可行原因/待确认项目",
                        prop: "unableReason",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.unableReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "unableReason", $$v)
                          },
                          expression: "createForm.unableReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮件抬头", prop: "emailHeader" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.createForm.emailHeader,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "emailHeader", $$v)
                          },
                          expression: "createForm.emailHeader",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "特别留言", prop: "especialMsg" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入特别留言",
                          rows: 7,
                          maxlength: "300",
                          "show-word-limit": "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.especialMsg,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "especialMsg", $$v)
                          },
                          expression: "createForm.especialMsg",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传附件" } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: "#",
                            "list-type": "picture-card",
                            "auto-upload": false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "file",
                              fn: function ({ file }) {
                                return _c("div", {}, [
                                  _c("img", {
                                    staticClass:
                                      "el-upload-list__item-thumbnail",
                                    attrs: { src: file.url, alt: "" },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-actions",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-preview",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePictureCardPreview(
                                                file
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-zoom-in",
                                          }),
                                        ]
                                      ),
                                      !_vm.disabled
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-delete",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDownload(
                                                    file
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-download",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.disabled
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-delete",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleRemove(file)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ])
                              },
                            },
                          ]),
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            attrs: { slot: "default" },
                            slot: "default",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: { visible: _vm.AnnexDialog },
                      on: {
                        "update:visible": function ($event) {
                          _vm.AnnexDialog = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("派单信息")]),
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "预约走访时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择预约走访时间",
                        },
                        model: {
                          value: _vm.createForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "title", $$v)
                          },
                          expression: "createForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "调查人员", prop: "inspector" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "suffix-icon": "el-icon-user",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        on: {
                          focus: function ($event) {
                            _vm.inspectorDialog = true
                          },
                        },
                        model: {
                          value: _vm.createForm.inspector,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "inspector", $$v)
                          },
                          expression: "createForm.inspector",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "选择人员",
                        visible: _vm.inspectorDialog,
                        width: "40%",
                        "before-close": _vm.handleClose,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.inspectorDialog = $event
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.inspectorDialog = false
                                },
                              },
                            },
                            [_vm._v("取 消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.inspectorDialog = false
                                },
                              },
                            },
                            [_vm._v(" 确 定 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "是否联系目标", prop: "isContactTarget" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.createForm.isContactTarget,
                            callback: function ($$v) {
                              _vm.$set(_vm.createForm, "isContactTarget", $$v)
                            },
                            expression: "createForm.isContactTarget",
                          },
                        },
                        _vm._l(_vm.isContactTarget, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "督导人员", prop: "supervisor" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "suffix-icon": "el-icon-user",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        on: {
                          focus: function ($event) {
                            _vm.supervisorDialog = true
                          },
                        },
                        model: {
                          value: _vm.createForm.supervisor,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "supervisor", $$v)
                          },
                          expression: "createForm.supervisor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "选择人员",
                        visible: _vm.supervisorDialog,
                        width: "40%",
                        "before-close": _vm.handleClose,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.supervisorDialog = $event
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.supervisorDialog = false
                                },
                              },
                            },
                            [_vm._v("取 消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.supervisorDialog = false
                                },
                              },
                            },
                            [_vm._v(" 确 定 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "小记", prop: "smallNote" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 1,
                          "show-word-limit": "",
                          placeholder: "请输入",
                          clearable: "",
                        },
                        model: {
                          value: _vm.createForm.smallNote,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "smallNote", $$v)
                          },
                          expression: "createForm.smallNote",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "large" },
              on: { click: _vm.createFormClose },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "large" },
              on: {
                click: function ($event) {
                  return _vm.saveOrder("createForm")
                },
              },
            },
            [_vm._v(" 保存 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "large" },
              on: {
                click: function ($event) {
                  return _vm.submitOrder("createForm")
                },
              },
            },
            [_vm._v(" 提交 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }