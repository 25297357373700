<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class">
            <el-row :gutter="20">
              <!-- 流转日期 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="circulationTime">
                  <el-date-picker v-model="queryForm.circulationTime"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="流转开始日期"
                                  end-placeholder="流转结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number.trim="queryForm.workOrderNo"
                            placeholder="工单编号"></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            placeholder="目标主体"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               :clearable="false"
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-show="isShow"
                    :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 原型图为预约走访日期  11/12 改 -->
                <el-form-item prop="visitDate">
                  <el-date-picker v-model="queryForm.visitDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="预约走访开始日期"
                                  end-placeholder="预约走访结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 工单状态 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderStatus">
                  <el-select v-model="queryForm.workOrderStatus"
                             placeholder="工单状态"
                             clearable
                             filterable>
                    <el-option v-for="item in orderStatusOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 人员选择 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="userInfo">
                  <el-cascader :value="queryForm.userInfo"
                               :options="staffInfo"
                               :clearable="true"
                               :filterable="true"
                               separator=" "
                               placeholder="选择人员"
                               @change="handleSatffChange"></el-cascader>
                </el-form-item>
              </el-col>
              <!-- 操作状态 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="operStatus">
                  <el-select v-model="queryForm.operStatus"
                             placeholder="操作状态"
                             clearable
                             filterable>
                    <el-option v-for="item in operateOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 下单日期范围  -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="orderDate">
                  <el-date-picker v-model="queryForm.orderDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 选择地区 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="address">
                  <el-cascader :value="queryForm.address"
                               :options="cityInfo"
                               :change-on-select="false"
                               :clearable="true"
                               :filterable="true"
                               placeholder="地区"
                               :props="{ checkStrictly: true }"
                               @change="handleBlockChange"></el-cascader>
                </el-form-item>
              </el-col>
              <!-- 订单编号 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="brWorkNo">
                  <el-input v-model.trim="queryForm.brWorkNo"
                            placeholder="订单编号"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 报告类型 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="reportType">
                  <el-select v-model="queryForm.reportType"
                             placeholder="报告类型"
                             clearable
                             filterable>
                    <el-option v-for="item in reportType"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 项目来源 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectSource">
                  <el-select v-model="queryForm.projectSource"
                             placeholder="请选择项目来源"
                             clearable
                             filterable>
                    <el-option v-for="item in resourceOptions"
                               :key="item.key"
                               :label="item.name"
                               :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button :loading="downloadLoading"
                     type="primary"
                     @click="handleDownload">
            导出
          </el-button>
          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    stripe
                    border
                    style="margin-top: 15px"
                    :element-loading-text="elementLoadingText"
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"
                             align="center"></el-table-column>
            <el-table-column label="工单编号"
                             prop="workOrderNo"
                             align="center"
                             width="130">
              <template slot-scope="scope">
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="workOrderStatusValue"
                             label="工单状态"
                             width="110"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.workOrderStatusValue || "-" }}
              </template>
            </el-table-column>
            <!-- 流转日期 -->
            <el-table-column prop="circulationTime"
                             label="流转日期"
                             width="120"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.circulationTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="bookVisitTime"
                             label="预约走访时间"
                             sortable
                             width="130">
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <!-- 当前操作人 -->
            <el-table-column prop="nowUserName"
                             label="当前操作人"
                             width="110"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.nowUserName || "-" }}
              </template>
            </el-table-column>

            <el-table-column prop="operStatusValue"
                             label="操作状态"
                             align="center"
                             width="100px">
              <template slot-scope="scope">
                {{ scope.row.operStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             label="环节/整体滞留(天)"
                             fit="true"
                             width="90">
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="撤回"
                            placement="top-start">
                  <el-button v-if="
                      '已提交' === scope.row.operStatusValue &&
                      '待调查' === scope.row.workOrderStatusValue
                    "
                             size="mini"
                             type="warning"
                             circle
                             @click="handleReturn(scope.row)">
                    <vab-icon :icon="['fas', 'undo']"></vab-icon>
                  </el-button>
                  <el-button v-else
                             size="mini"
                             type="warning"
                             circle
                             disabled>
                    <vab-icon :icon="['fas', 'undo']"></vab-icon>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { doneWithdraw, getDoneList } from "@/api/workplace/order/order"
// 获取工具API
import {
	getAllReasonOption,
	getBlock,
	getGeneralReason,
	getProjectSource,
	getReason,
	getRole,
	getWorkOperStatus,
	getWorkOrderStatus,
	querySystemUsers,
	getReportTypeList,
} from "@/api/workplace/tools"
import { returnBitTimestamp, returnDeadtimestamp, getRoleKey } from "@/utils"
import { mapGetters } from "vuex"

export default {
	name: "Done",
	components: {},
	filters: {
		statusFilter(status) {
			const statusMap = {
				published: "success",
				draft: "gray",
				deleted: "danger",
			}
			return statusMap[status]
		},
	},
	data() {
		return {
			isShow: false,
			imgShow: true,
			list: [],
			listLoading: true,
			layout: "total, sizes, prev, pager, next, jumper",
			total: 0,
			background: true,
			selectRows: "",
			elementLoadingText: "正在加载...",
			staffInfo: [],
			staffForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			// 列表
			queryForm: {
				orderDate: [], // 表单数据，不传递到后端
				orderTimeEnd: "",
				orderTimeStart: "",
				workOrderNo: "",
				targetSubject: "",
				projectId: "",

				circulationTime: [], // 表单数据，不传递到后端
				circulationTimeStart: "",
				circulationTimeEnd: "",

				userInfo: null, // 人员信息
				roleUser: null, // 选择人员参数

				visitDate: [], // 表单数据，不传递到后端
				bookVisitTimeEnd: "",
				bookVisitTimeStart: "",
				workOrderStatus: "",
				surveyUser: "",
				address: "",

				plUser: "",
				monitorUser: "",
				operStatus: "",

				fallbackCommentId: "",
				abandonCommentId: "",

				pageNum: 1,
				pageSize: 10,
				nextUserNo: "",
				nextUserRoleNo: "",
				userNo: "",
				userRoleNo: "",
			},
			projectInfo: [], // 项目
			projectForm: {
				body: {
					source: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			//   角色人员
			roleOptions: [],
			roleForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			cityInfo: [],
			cityForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			// 退回原因
			reasonOptions: [],
			// 放弃原因
			quitReasonOptions: [],
			reasonForm: {
				body: {
					templateType: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			// 处理级联及日期范围控件表单重置
			reset: {
				projectId: [],
				block: [],
				orderScopeDate: [],
				innerDeadline: [],
			},
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit("pick", [start, end])
						},
					},
				],
			},
			operateOptions: [],
			//  工单状态
			orderStatusOptions: [],
			// cfe start
			// 列表参数
			listParam: {
				body: {},
				header: {
					currentTime: "2020-02-02 00:00:00",
					requestId: "1",
					sourceType: "site",
				},
			},

			// 撤回参数
			withdrawPara: {
				body: {
					console: "book_person",
					nextTaskId: null,
					nextUserNo: "",
					nextUserRoleNo: "",
					note: "预约专属，固定写法",
					operAction: "工单撤回",
					procInsId: null,
					projectSource: "",
					remark: "",
					revokeCommentId: "xxxxxx",
					step: "预约工作台-工单预约-已处理",
					taskId: null,
					userNo: "",
					userRoleNo: "",
					workOrderNo: null,
				},
				header: {
					currentTime: "",
					requestId: "1",
					sourceType: "site",
				},
			},
			downloadLoading: false,
			reportType: [], // 报告类型
			// 项目来源
			resourceOptions: [
				{
					name: "香港业务流程",
					key: "process_hk",
				},
				{
					name: "本地业务流程",
					key: "process_nd",
				},
				{
					name: "BR业务流程",
					key: "process_br",
				},
			],
		}
	},
	computed: {
		...mapGetters({
			userNo: "user/userNo",
			roles: "user/roles",
		}),
	},
	created() {
		this.queryForm.userNo = this.userNo
		this.queryForm.userRoleNo = getRoleKey("book_person", this.roles)
		this.withdrawPara.body.userNo = this.userNo
		this.withdrawPara.body.userRoleNo = getRoleKey("book_person", this.roles)
	},
	beforeDestroy() {},
	mounted() {},
	methods: {
		/** 数据初始化 */
		handleInitData() {
			// 获取项目
			this.getProject()

			// 列表查询
			this.fetchData()

			// 获取人员选项
			this.getStaffInfo()
			// 获取报告类型
			this.getReportType()
		},
		/** 获取人员信息 */
		async getStaffInfo() {
			this.staffForm.header.currentTime = returnBitTimestamp(new Date())
			const { header, body } = await querySystemUsers(this.staffForm)
			if (header.retCode === "1") {
				this.staffInfo = body
			}
		},
		/** 选择人员数据框改变值 */
		handleSatffChange(value) {
			if (value) this.queryForm.userInfo = value
		},
		/** 导出Excel */
		handleDownload() {
			if (this.selectRows.length > 0) {
				this.downloadLoading = true
				import("@/vendor/ExportExcel").then((excel) => {
					const tHeader = [
						"工单编号",
						"订单编号",
						"目标主体",
						"目标地址",
						"项目",
						"报告类型",
						"二级类型",
						"工单状态",
						"流转日期",
						"预约走访时间",
						"当前操作人",
						"操作状态",
						"环节/整体滞留(天)",
					]
					const filterVal = [
						"workOrderNo",
						"brWorkNo",
						"targetSubject",
						"address",
						"projectName",
						"reportType",
						"subReportType",
						"workOrderStatusValue",
						"circulationTime",
						"bookVisitTime",
						"nowUserName",
						"operStatusValue",
						"stepAndStranded",
					]
					const list = this.selectRows
					const data = this.formatJson(filterVal, list)
					excel.export_json_to_excel({
						header: tHeader,
						data,
						filename: "工单列表",
						autoWidth: true,
						bookType: "xlsx",
					})
					this.downloadLoading = false
				})
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},
		/** 组装导出数据 */
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					if (!v[j]) {
						return "——"
					}
					return v[j]
				})
			)
		},
		// 获取地区
		getAddress() {
			this.cityForm.header.currentTime = returnBitTimestamp(new Date())
			getBlock(this.cityForm).then((response) => {
				if ("1" === response.header.retCode) {
					let block_info = []
					block_info.push(response.body[0])
					this.cityInfo = block_info
				} else {
					this.$message.error(
						`${response.header.retMessage},获取地区失败，请重新刷新页面`
					)
				}
			})
		},
		// 选择地区
		handleBlockChange(value) {
			this.queryForm.address = value.join(",")
		},
		// 获取项目
		getProject() {
			this.projectForm.header.currentTime = returnBitTimestamp(new Date())
			getProjectSource(this.projectForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.projectInfo = response.body
				}
			})
		},
		// 选择项目
		handleProjectChange(value) {
			this.queryForm.projectId = value[1]
		},

		setSelectRows(val) {
			this.selectRows = val
		},
		handleAdd() {
			this.$refs["edit"].showEdit()
		},
		handleEdit(row) {
			this.$refs["edit"].showEdit(row)
		},
		handleReturn(row) {
			this.$baseConfirm("你确定要撤回当前项吗", null, async () => {
				this.withdrawPara.header.currentTime = returnBitTimestamp(new Date())
				this.withdrawPara.body.procInsId = row.procInsId
				this.withdrawPara.body.nextTaskId = row.nextTaskId
				this.withdrawPara.body.taskId = row.taskId
				this.withdrawPara.body.projectSource = row.source
				this.withdrawPara.body.workOrderNo = row.workOrderNo
				const { header } = await doneWithdraw(
					Object.assign({}, this.withdrawPara)
				)
				if (header.retCode === "1") {
					this.$baseMessage(header.retMessage, "success")
				} else {
					this.$baseMessage(header.retMessage, "error")
				}
				let refresh_data = {
					done_total: this.total,
					isTodo: true,
				}
				this.$emit("child-done", refresh_data)
				this.fetchData()
			})
		},
		handleSizeChange(val) {
			this.queryForm.pageSize = val
			this.fetchData()
		},
		handleCurrentChange(val) {
			this.queryForm.pageNum = val
			this.fetchData()
		},
		heightQuery() {
			this.isShow = !this.isShow
			this.getAddress()
			this.getWithdrawReasonOption()
			// 获取工单状态
			this.getOrderStatus()
			// 获取操作状态
			this.getOperStatus()
			// 放弃原因
			this.getAllAbandon()
		},
		handleQuery() {
			this.queryForm.pageNum = 1
			this.fetchData()
		},
		// 重置
		resetForm(formName) {
			if (this.$refs[formName] !== undefined) {
				this.$refs[formName].resetFields()
			}
			this.queryForm = this.$options.data().queryForm
		},

		// 获取工单状态
		getOrderStatus() {
			this.listParam.header.currentTime = returnBitTimestamp(new Date())
			getWorkOrderStatus(this.listParam).then((response) => {
				if ("1" === response.header.retCode) {
					this.orderStatusOptions = response.body
				} else {
					this.$message.error(
						`${response.header.retMessage},获取工单状态失败，请重新刷新页面`
					)
				}
			})
		},
		// 获取操作状态
		getOperStatus() {
			this.listParam.header.currentTime = returnBitTimestamp(new Date())
			getWorkOperStatus(this.listParam).then((response) => {
				if ("1" === response.header.retCode) {
					this.operateOptions = response.body
				} else {
					this.$message.error(
						`${response.header.retMessage},获取操作状态失败，请重新刷新页面`
					)
				}
			})
		},
		// 获取退回原因配置项
		getWithdrawReasonOption() {
			this.listParam.header.currentTime = returnBitTimestamp(new Date())
			// this.withdrawReasonForm.body.templateType = "91";
			getGeneralReason(this.listParam).then((response) => {
				if ("1" === response.header.retCode) {
					this.reasonOptions = response.body
				} else {
					this.$message.error(
						`错误信息: ${response.header.retMessage},请重新刷新页面，获取退回原因选择信息`
					)
				}
			})
		},
		// 获取放弃原因 上门前放弃&上门后
		getAllAbandon() {
			this.listParam.header.currentTime = returnBitTimestamp(new Date())
			this.listParam.body.templateType = "80"
			getAllReasonOption(this.listParam).then((response) => {
				if ("1" === response.header.retCode) {
					this.quitReasonOptions = response.body
				}
			})
		},
		// 获取报告分类
		getReportType() {
			getReportTypeList(this.projectForm).then((response) => {
				if (response.header.retCode === "1") {
					this.reportType = response.body
				}
			})
		},
		async fetchData() {
			this.queryForm.userNo = this.userNo
			this.queryForm.userRoleNo = getRoleKey("book_person", this.roles)
			this.listLoading = true

			// 选择人员数据
			if (this.queryForm.userInfo && this.queryForm.userInfo.length > 0) {
				this.queryForm.roleUser = `${this.queryForm.userInfo[0]}:${this.queryForm.userInfo[1]}`
			} else {
				this.queryForm.roleUser = ""
			}

			// 流转日期范围
			if (null !== this.queryForm.circulationTime) {
				if (0 < this.queryForm.circulationTime.length) {
					this.queryForm.circulationTimeStart = returnBitTimestamp(
						this.queryForm.circulationTime[0]
					)
					this.queryForm.circulationTimeEnd = returnDeadtimestamp(
						this.queryForm.circulationTime[1]
					)
				}
			} else {
				this.queryForm.circulationTimeStart = null
				this.queryForm.circulationTimeEnd = null
			}

			// 下单日期范围
			if (null !== this.queryForm.orderDate) {
				if (0 < this.queryForm.orderDate.length) {
					this.queryForm.orderTimeStart = returnBitTimestamp(
						this.queryForm.orderDate[0]
					)
					this.queryForm.orderTimeEnd = returnDeadtimestamp(
						this.queryForm.orderDate[1]
					)
				}
			} else {
				this.queryForm.orderTimeStart = null
				this.queryForm.orderTimeEnd = null
			}
			// 走访日期范围
			if (null !== this.queryForm.visitDate) {
				if (0 < this.queryForm.visitDate.length) {
					this.queryForm.bookVisitTimeStart = returnBitTimestamp(
						this.queryForm.visitDate[0]
					)
					this.queryForm.bookVisitTimeEnd = returnDeadtimestamp(
						this.queryForm.visitDate[1]
					)
				}
			} else {
				this.queryForm.bookVisitTimeStart = null
				this.queryForm.bookVisitTimeEnd = null
			}

			// 获取预约工作台订单list
			this.listParam.body = Object.assign({}, this.queryForm)
			const { body } = await getDoneList(this.listParam)
			this.list = body.records
			this.total = body.total
			let refresh_data = {
				done_total: this.total,
				isTodo: false,
			}
			this.$emit("child-done", refresh_data)
			this.listLoading = false
		},
	},
}
</script>
<style scoped>
.el-col {
	margin-bottom: 10px;
}
.el-col :last-child {
	margin-bottom: 10px;
	margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100%;
}

.el-select,
.el-cascader {
	width: 100%;
}
.my-form-class {
	margin-bottom: -10px !important;
}
</style>
