var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "myDialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("el-backtop", { attrs: { target: ".myDialog" } }),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "addSystemTemplateForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    model: _vm.addSystemTemplateForm,
                    rules: _vm.addSystemTemplateFormRules,
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        display: "block",
                      },
                    },
                    [_vm._v("基础信息")]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "系统模板名称", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写模板名称" },
                                model: {
                                  value: _vm.addSystemTemplateForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addSystemTemplateForm,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression: "addSystemTemplateForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目", prop: "projectNo" } },
                            [
                              _c("el-cascader", {
                                attrs: {
                                  value: _vm.addSystemTemplateForm.projectNo,
                                  options: _vm.projectInfo,
                                  clearable: true,
                                  filterable: true,
                                  separator: ":",
                                  placeholder: "请选择项目",
                                },
                                on: { change: _vm.handleProjectChange },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "文档模板名称",
                                prop: "fileName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.addSystemTemplateForm.fileName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addSystemTemplateForm,
                                      "fileName",
                                      $$v
                                    )
                                  },
                                  expression: "addSystemTemplateForm.fileName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 16, xl: 16 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "系统模板描述",
                                prop: "description",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入描述信息",
                                },
                                model: {
                                  value: _vm.addSystemTemplateForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addSystemTemplateForm,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "addSystemTemplateForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vab-query-form",
                    [
                      _c(
                        "vab-query-form-left-panel",
                        [
                          _c("label", [_vm._v("配置内容")]),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                plain: "",
                                icon: "el-icon-mobile-phone",
                              },
                              on: { click: _vm.previewPhone },
                            },
                            [_vm._v(" 预览 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vab-query-form-right-panel",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-upload2",
                              },
                              on: { click: _vm.uploadFun },
                            },
                            [_vm._v(" 导入 ")]
                          ),
                          _vm.flag === "edit"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-download",
                                    plain: "",
                                  },
                                  on: { click: _vm.handleDownload },
                                },
                                [_vm._v(" 导出 ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-plus", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAdd("content")
                                },
                              },
                            },
                            [_vm._v(" 添加表单 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.dataList,
                                "row-key": "menuId",
                                "node-key": "menuId",
                                "tree-props": {
                                  children: "children",
                                  hasChildren: "hasChildren",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "nodeTitle",
                                  label: "表单及元素名称",
                                  "show-overflow-tooltip": true,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "220",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                              icon: "el-icon-edit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 编辑 ")]
                                        ),
                                        !scope.row.childNodeTitle
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "text",
                                                  icon: "el-icon-plus",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAdd(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 新增 ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                              icon: "el-icon-delete",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: _vm.subTitle,
                                visible: _vm.open,
                                width: "55%",
                                "close-on-click-modal": false,
                                "append-to-body": "",
                                "before-close": _vm.cancel,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.open = $event
                                },
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "contentForm",
                                  staticStyle: { padding: "10px" },
                                  attrs: {
                                    model: _vm.contentForm,
                                    rules: _vm.contentFormRules,
                                    "label-width": "80px",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "nodeTitle",
                                                label: _vm.nodeTitleName,
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: "请输入表单",
                                                    trigger: "blur",
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入表单",
                                                  disabled:
                                                    _vm.nodeTitleDisable,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contentForm.nodeTitle,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contentForm,
                                                      "nodeTitle",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contentForm.nodeTitle",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.nodeTitleName === "表单名称"
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "textAlign",
                                                    label: "表单对齐",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder: "请选择",
                                                        disabled:
                                                          _vm.nodeTitleDisable,
                                                        filterable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contentForm
                                                            .textAlign,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contentForm,
                                                            "textAlign",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contentForm.textAlign",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.alignOption,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.nodeTitleName === "表单名称"
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "backgroundColor",
                                                    label: "背景色",
                                                  },
                                                },
                                                [
                                                  _c("el-color-picker", {
                                                    attrs: {
                                                      "show-alpha": "",
                                                      disabled:
                                                        _vm.nodeTitleDisable,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contentForm
                                                          .backgroundColor,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contentForm,
                                                          "backgroundColor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contentForm.backgroundColor",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.nodeTitleName === "表单名称"
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "spanColor",
                                                    label: "字体颜色",
                                                  },
                                                },
                                                [
                                                  _c("el-color-picker", {
                                                    attrs: {
                                                      "show-alpha": "",
                                                      disabled:
                                                        _vm.nodeTitleDisable,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contentForm
                                                          .spanColor,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contentForm,
                                                          "spanColor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contentForm.spanColor",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.childrenNode_show == true
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "childNodeTitle",
                                                    label: "表单元素",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message:
                                                          "请输入表单元素",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入表单元素名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.contentForm
                                                          .childNodeTitle,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.contentForm,
                                                          "childNodeTitle",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contentForm.childNodeTitle",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.add_children_type == true
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "数据类型",
                                                    prop: "type",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      on: {
                                                        change: _vm.dataChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.contentForm.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.contentForm,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contentForm.type",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "input",
                                                          },
                                                        },
                                                        [_vm._v("输入框")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "select",
                                                          },
                                                        },
                                                        [_vm._v("下拉选择器")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "radio",
                                                          },
                                                        },
                                                        [_vm._v("单选框")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "checkbox",
                                                          },
                                                        },
                                                        [_vm._v("多选框")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "album",
                                                          },
                                                        },
                                                        [_vm._v("相册")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.add_children_type == true
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              _vm.contentForm.type === "input"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "是否必填",
                                                            prop: "required",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .contentForm
                                                                    .required,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.contentForm,
                                                                      "required",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contentForm.required",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.requireOption,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type === "input"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "类型",
                                                            prop: "propType",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .contentForm
                                                                    .propType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.contentForm,
                                                                      "propType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contentForm.propType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.inputTypeOptions,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type === "input"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "字段名称",
                                                            prop: "title",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .title,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "title",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.title",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type === "input"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "field",
                                                            label: "英文名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入英文名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .field,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "field",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.field",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type === "input"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "字段值",
                                                            prop: "value",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段值",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.value",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type ===
                                                "input" &&
                                              _vm.contentForm.required == "1"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "触发校验",
                                                            prop: "trigger",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .contentForm
                                                                    .trigger,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.contentForm,
                                                                      "trigger",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contentForm.trigger",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.triggerOptions,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "input" &&
                                              _vm.contentForm.required == "1"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "提示语",
                                                            prop: "message",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入提示语",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .message,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "message",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.message",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _vm.contentForm.type == "select"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "title",
                                                            label: "字段名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .title,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "title",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.title",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "select"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "field",
                                                            label: "英文名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入英文名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .field,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "field",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.field",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "select"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "类型",
                                                            prop: "propType",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .contentForm
                                                                    .propType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.contentForm,
                                                                      "propType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contentForm.propType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.selectTypeOptions,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "select"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "可清空",
                                                            prop: "clearable",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .contentForm
                                                                    .clearable,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.contentForm,
                                                                      "clearable",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contentForm.clearable",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.clearableOptions,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "select"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm._l(
                                                        _vm.contentForm.options,
                                                        function (
                                                          selectItem,
                                                          selectIndex
                                                        ) {
                                                          return _c(
                                                            "el-form-item",
                                                            {
                                                              key: selectItem.selectIndex,
                                                              attrs: {
                                                                label:
                                                                  "配置项" +
                                                                  selectIndex,
                                                                prop:
                                                                  "options." +
                                                                  selectIndex +
                                                                  ".label",
                                                                rules: {
                                                                  required: true,
                                                                  message:
                                                                    "配置项不能为空",
                                                                  trigger:
                                                                    "blur",
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入需要生成的配置项",
                                                                },
                                                                model: {
                                                                  value:
                                                                    selectItem.label,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        selectItem,
                                                                        "label",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "selectItem.label",
                                                                },
                                                              }),
                                                              selectIndex != 0
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.removeOption(
                                                                              selectItem
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " 删除 "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.addOption,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "新增配置项"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type ===
                                                "select" &&
                                              _vm.contentForm.required == "1"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "触发校验",
                                                            prop: "trigger",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .contentForm
                                                                    .trigger,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.contentForm,
                                                                      "trigger",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contentForm.trigger",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.triggerOptions,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type ==
                                                "select" &&
                                              _vm.contentForm.required == "1"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "提示语",
                                                            prop: "message",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入提示语",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .message,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "message",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.message",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _vm.contentForm.type == "radio"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "title",
                                                            label: "字段名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .title,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "title",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.title",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "radio"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "field",
                                                            label: "英文名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段英文名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .field,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "field",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.field",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "radio"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm._l(
                                                        _vm.contentForm.options,
                                                        function (
                                                          radioItem,
                                                          radioIndex
                                                        ) {
                                                          return _c(
                                                            "el-form-item",
                                                            {
                                                              key: radioItem.radioIndex,
                                                              attrs: {
                                                                label:
                                                                  "配置项" +
                                                                  radioIndex,
                                                                prop:
                                                                  "options." +
                                                                  radioIndex +
                                                                  ".label",
                                                                rules: {
                                                                  required: true,
                                                                  message:
                                                                    "配置项不能为空",
                                                                  trigger:
                                                                    "blur",
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入需要生成的配置项",
                                                                },
                                                                model: {
                                                                  value:
                                                                    radioItem.label,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        radioItem,
                                                                        "label",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "radioItem.label",
                                                                },
                                                              }),
                                                              radioIndex != 0
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.removeOption(
                                                                              radioItem
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " 删除 "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.addOption,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "新增配置项"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _vm.contentForm.type == "checkbox"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "title",
                                                            label: "字段名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .title,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "title",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.title",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "checkbox"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "field",
                                                            label: "英文名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段英文名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .field,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "field",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.field",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type == "checkbox"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm._l(
                                                        _vm.contentForm.options,
                                                        function (
                                                          checkboxItem,
                                                          checkboxIndex
                                                        ) {
                                                          return _c(
                                                            "el-form-item",
                                                            {
                                                              key: checkboxItem.checkboxIndex,
                                                              attrs: {
                                                                label:
                                                                  "配置项" +
                                                                  checkboxIndex,
                                                                prop:
                                                                  "options." +
                                                                  checkboxIndex +
                                                                  ".label",
                                                                rules: {
                                                                  required: true,
                                                                  message:
                                                                    "配置项不能为空",
                                                                  trigger:
                                                                    "blur",
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入需要生成的配置项",
                                                                },
                                                                model: {
                                                                  value:
                                                                    checkboxItem.label,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        checkboxItem,
                                                                        "label",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "checkboxItem.label",
                                                                },
                                                              }),
                                                              checkboxIndex != 0
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.removeOption(
                                                                              checkboxItem
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " 删除 "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.addOption,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "新增配置项"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _vm.contentForm.type === "album"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "title",
                                                            label: "字段名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入字段名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .title,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "title",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.title",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.contentForm.type === "album"
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 12 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "field",
                                                            label: "英文名称",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入英文名称",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.contentForm
                                                                  .field,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contentForm,
                                                                    "field",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contentForm.field",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.cancel } },
                                    [_vm._v("取 消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.submitForm },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: _vm.webTitle,
                                visible: _vm.webOpen,
                                width: "1000px",
                                "append-to-body": "",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.webOpen = $event
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "phone" },
                                [
                                  _c(
                                    "el-card",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "primary",
                                            size: "large",
                                          },
                                        },
                                        [_vm._v(" 信息核实 ")]
                                      ),
                                      _c("web-preview"),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-card",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "primary",
                                            size: "large",
                                          },
                                        },
                                        [_vm._v(" 信息核实 ")]
                                      ),
                                      _c("web-preview"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.getBack("webOpen")
                                        },
                                      },
                                    },
                                    [_vm._v("返 回")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: _vm.uploadTitle,
                                visible: _vm.uploadOpen,
                                width: "600px",
                                "append-to-body": "",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.uploadOpen = $event
                                },
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "upload-demo",
                                  attrs: {
                                    drag: "",
                                    action:
                                      "https://jsonplaceholder.typicode.com/posts/",
                                    multiple: "",
                                    align: "center",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v(" 将文件拖到此处，或 "),
                                      _c("em", [_vm._v("点击上传")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " 只能上传jpg/png文件，且不超过500kb "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.getBack("uploadOpen")
                                        },
                                      },
                                    },
                                    [_vm._v("返 回")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("phone-preview", { ref: "phonePreview" }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.certainLoading },
              on: { click: _vm.save },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传资源",
            visible: _vm.openUploadSource,
            width: "50%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openUploadSource = $event
            },
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", type: "flex", gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("i", { staticClass: "el-icon-upload" }),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c("h3", [_vm._v("请您上传资源")]),
                    _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-family": "'微软雅黑'",
                              "font-weight": "400",
                              "text-decoration": "none",
                              color: "#999999",
                            },
                          },
                          [_vm._v(" 请上传导入文件，文件后缀名必须为txt ")]
                        ),
                        _c(
                          "el-upload",
                          {
                            ref: "uploadTemplate",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              data: _vm.uploadData,
                              headers: _vm.headers,
                              "on-success": _vm.handleSuccess,
                              "on-error": _vm.handleError,
                              "before-upload": _vm.beforeUpload,
                              limit: 1,
                              "on-exceed": _vm.handleExceed,
                              "file-list": _vm.fileList,
                            },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                ref: "uploadBtn",
                                staticStyle: { "margin-top": "30px" },
                                attrs: { type: "primary", underline: false },
                              },
                              [_vm._v(" 上传文件 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  ref: "nextStep",
                  attrs: { type: "primary", disabled: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }