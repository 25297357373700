/**
 * @copyright devin cbi@chinacbi.com
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，vip文档中已提供路由的基础图标与小清新图标的配置方案，请仔细阅读
 */

import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layouts";
import EmptyLayout from "@/layouts/EmptyLayout";
import { routerMode } from "@/config/settings";
// 保存 公共封装 组件
import saveOrder from "../views/workplace/components/save";
import plDetail from "../views/workplace/PL/components/detail";
import doneDetail from "../views/workplace/supervision/recommend/components/detail";
import auditDetail from "../views/workplace/internalAudit/components/details/detail";
import investigateDetail from "../views/workplace/investigate/components/detail";
import qualityControlDetail from "../views/workplace/qualityControl/components/detail";
import managerDetail from "../views/workplace/manager/components/detail";

export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/401"),
    hidden: true,
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home/index"),
        meta: {
          title: "首页",
          icon: "home",
          affix: true,
        },
      },
    ],
  },
  {
    path: "/personalCenter",
    component: Layout,
    hidden: true,
    redirect: "personalCenter",
    children: [
      {
        path: "personalCenter",
        name: "PersonalCenter",
        component: () => import("@/views/personalCenter/index"),
        meta: {
          title: "个人中心",
        },
      },
    ],
  },
];

export const asyncRoutes = [
  {
    path: "/project",
    component: Layout,
    redirect: "noRedirect",
    name: "Project",
    meta: { title: "项目", icon: "layer-group", permissions: ["admin"] },
    children: [
      {
        path: "manage",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "Manage",
        meta: {
          title: "项目管理",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          {
            path: "name",
            name: "Name",
            component: () => import("@/views/project/manage/name"),
            meta: { title: "项目名称" },
          },
          {
            path: "source",
            name: "Source",
            component: () => import("@/views/project/manage/source"),
            meta: { title: "项目来源配置" },
          },
          {
            path: "documentModel",
            name: "DocumentModel",
            component: () => import("@/views/project/manage/documentModel"),
            meta: { title: "文档模板管理" },
          },
          {
            path: "systemModel",
            name: "SystemModel",
            component: () => import("@/views/project/manage/systemModel"),
            meta: { title: "系统模板管理" },
          },
        ],
      },
      {
        path: "setting",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "Setting",
        meta: {
          title: "配置管理",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          {
            path: "orderExport",
            name: "OrderExport",
            component: () =>
              import("@/views/project/setting/orderExportManage"),
            meta: { title: "工单导出管理" },
          },
          {
            path: "fieldManage",
            name: "FieldManage",
            component: () => import("@/views/project/setting/fieldManage"),
            meta: { title: "字段管理" },
          },
        ],
      },
    ],
  },
  {
    path: "/workplace",
    component: Layout,
    redirect: "noRedirect",
    name: "Workplace",
    meta: { title: "工作台", icon: "users-cog", permissions: ["admin"] },
    children: [
      // 公共 保存
      {
        path: "save",
        name: "save",
        component: saveOrder,
        hidden: true,
        meta: { title: "提交/保存成功" },
      },
      // PL工作臺
      {
        path: "pl",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "PL",
        meta: {
          title: "PL工作台",
          permissions: ["admin"],
        },
        children: [
          // pl -> 工单管理
          {
            path: "orderManage",
            name: "OrderManage",
            component: () => import("@/views/workplace/PL/index"),
            meta: { title: "工单管理" },
          },
          // pl -> 工单管理 -> 已处理 ->工单详情
          {
            path: "orderManage/detail",
            name: "Components",
            component: plDetail,
            hidden: true,
            meta: { title: "工单详情" },
          },
          // pl -> 对账ID管理
          {
            path: "verifyBill",
            name: "verifyBill",
            component: () => import("@/views/workplace/PL/verifyBill"),
            meta: { title: "对账ID管理" },
          },
        ],
      },
      // 預約工作臺
      {
        path: "order",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "Order",
        meta: {
          title: "预约工作台",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          {
            path: "book",
            name: "Book",
            component: () => import("@/views/workplace/order/index"),
            meta: { title: "工单预约" },
          },
        ],
      },
      // 调查工作台
      {
        path: "investigate",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "Investigate",
        meta: {
          title: "调查工作台",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          {
            path: "enter",
            name: "Entry",
            component: () => import("@/views/workplace/investigate/index"),
            meta: { title: "工单录入" },
          },
          {
            path: "order/detail",
            name: "Components",
            component: investigateDetail,
            hidden: true,
            meta: { title: "工单详情" },
          },
        ],
      },

      // 督导工作台
      {
        path: "supervision",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "Supervision",
        meta: {
          title: "督导工作台",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          // 人员推荐
          {
            path: "recommend",
            name: "Recommend",
            component: () =>
              import("@/views/workplace/supervision/recommend/index"),
            meta: { title: "人员推荐" },
          },
          {
            path: "recommend/detail",
            name: "Components",
            component: doneDetail,
            hidden: true,
            meta: { title: "推荐人员详情" },
          },
          // 工单补充
          {
            path: "orderRepair",
            name: "OrderRepair",
            component: () =>
              import("@/views/workplace/supervision/orderRepair/index"),
            meta: { title: "工单补充" },
          },
          // 成本录入
          {
            path: "costentry",
            name: "Costentry",
            component: () =>
              import("@/views/workplace/supervision/costentry/index"),
            meta: { title: "成本录入" },
          },
        ],
      },
      // 内审工作臺
      {
        path: "audit",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "Audit",
        meta: {
          title: "内审工作台",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          {
            path: "first",
            name: "First",
            component: () => import("@/views/workplace/internalAudit/index"),
            meta: { title: "工单初审" },
          },
          {
            path: "first/detail",
            name: "Components",
            component: auditDetail,
            hidden: true,
            meta: { title: "工单详情" },
          },
        ],
      },
      // 用户工作台
      {
        path: "user",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "User",
        meta: {
          title: "用户工作台",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          {
            path: "second",
            name: "Second",
            component: () => import("@/views/workplace/user/index"),
            meta: { title: "工单二审" },
          },
          {
            path: "summary",
            name: "Summary",
            component: () => import("@/views/workplace/user/summary"),
            meta: { title: "Summary" },
          },
        ],
      },
      // 经理工作台
      {
        path: "manager",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "Manager",
        meta: {
          title: "经理工作台",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          // 经理工作台 -> 工单列表
          {
            path: "orderList",
            name: "OrderList",
            component: () => import("@/views/workplace/manager/index"),
            meta: { title: "工单列表" },
          },
          // 工单详情
          {
            path: "orderList/detail",
            name: "Components",
            component: managerDetail,
            hidden: true,
            meta: { title: "工单详情" },
          },
        ],
      },
      // 质控工作台
      {
        path: "qualityControl",
        component: EmptyLayout,
        redirect: "noRedirect",
        name: "QualityControl",
        meta: {
          title: "质控工作台",
          permissions: ["admin"],
        },
        alwaysShow: true,
        children: [
          // 质控工作台 -> 工单列表
          {
            path: "orderList",
            name: "OrderList",
            component: () => import("@/views/workplace/qualityControl/index"),
            meta: { title: "工单列表" },
          },
          // 工单详情
          {
            path: "orderList/detail",
            name: "Components",
            component: qualityControlDetail,
            hidden: true,
            meta: { title: "工单详情" },
          },
        ],
      },
    ],
  },

  // 组织架构
  {
    path: "/mall",
    component: Layout,
    redirect: "index",
    name: "Mall",
    meta: {
      title: "组织架构",
      icon: "sitemap",
      permissions: ["admin"],
    },
  },

  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

const router = new VueRouter({
  base: "/site", // 主要添加这个 /site 注意这个 和上面的一致，为nginx location路径， 也就是请求路径
  mode: routerMode,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRoutes,
});
//注释的地方是允许路由重复点击，如果你觉得框架路由跳转规范太过严格可选择放开
/*const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};*/

export function resetRouter() {
  router.matcher = new VueRouter({
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  }).matcher;
}

export default router;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
