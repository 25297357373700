// Kyle API 对账ID管理 -> 已处理
import request from "@/utils/request";

// 获取已处理 列表
export function getList(data) {
  return request({
    url: "/plWorkOrder/queryReconciliations",
    method: "post",
    data,
  });
}

// 添加/编辑对账ID
export function doEdit(data) {
  return request({
    url: "/plWorkOrder/eidtReconciliationByWorkOrderNo",
    method: "post",
    data,
  });
}
