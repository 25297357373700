<template>
  <div>
    <!--检索模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     style="margin-bottom: 15px"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     plain
                     native-type="submit"
                     @click="resetForm('query')">
            重置
          </el-button>
          <el-form ref="query"
                   :inline="true"
                   :model="query"
                   class="demo-form-inline">
            <el-row :gutter="20"
                    class="myQuery">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="updateTime">
                  <el-date-picker v-model="query.updateTime"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="项目更新开始日期"
                                  end-placeholder="项目更新结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="name">
                  <el-select v-model="query.name"
                             placeholder="请选择项目名称"
                             clearable
                             filterable>
                    <el-option v-for="item in project"
                               :key="item.id"
                               :label="item.name"
                               :value="item.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="type">
                  <el-select v-model="query.type"
                             prop="type"
                             placeholder="请选择项目类型"
                             clearable
                             filterable>
                    <el-option v-for="item in projectType"
                               :key="item.id"
                               :label="item.type"
                               :value="item.type"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="source">
                  <el-select v-model="query.source"
                             prop="source"
                             placeholder="请选择项目来源"
                             clearable
                             filterable>
                    <el-option v-for="item in resource"
                               :key="item.key"
                               :label="item.name"
                               :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-plus"
                     style="margin-bottom: 15px"
                     type="primary"
                     @click="handleAdd">
            创建项目
          </el-button>
          <el-button icon="el-icon-delete"
                     type="danger"
                     @click="handleDelete">
            批量删除
          </el-button>

          <el-table ref="tableSort"
                    v-loading="listLoading"
                    border
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"></el-table-column>
            <el-table-column label="项目名称"
                             prop="title">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="type"
                             label="项目类型">
              <template slot-scope="scope">
                {{ scope.row.type }}
              </template>
            </el-table-column>
            <el-table-column label="项目来源"
                             prop="resource">
              <template slot-scope="scope">
                {{ scope.row.source | projectFilter }}
              </template>
            </el-table-column>
            <el-table-column label="更新时间"
                             sortable
                             width="180"
                             align="center"
                             prop="updateTime">
              <template slot-scope="scope">
                {{ scope.row.updateTime | parseTime }}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="140px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="编辑"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-edit-outline"
                             circle
                             @click="handleEdit(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="删除"
                            placement="top-start">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             circle
                             @click="handleDelete(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!--新增项目-->
    <name-add ref="add"
              @fetchData="fetchData"></name-add>

    <!--编辑项目-->
    <name-edit ref="edit"
               @fetchData="fetchData"></name-edit>
  </div>
</template>

<script>
import {
	queryProjectPage,
	queryProjectName,
	queryProjectType,
	deleteProject,
} from "@/api/project/manage"
import nameAdd from "./components/nameAdd"
import nameEdit from "./components/nameEdit"
import { parseTime, returnBitTimestamp, returnDeadtimestamp } from "@/utils"
export default {
	name: "ComprehensiveTable",
	components: {
		nameAdd,
		nameEdit,
	},
	filters: {
		projectFilter(status) {
			const statusMap = {
				process_hk: "香港业务流程",
				process_nd: "本地业务流程",
				process_br: "BR业务流程",
			}
			return statusMap[status]
		},
		parseTime(date) {
			return parseTime(date)
		},
	},
	data() {
		return {
			isShow: false,
			imgShow: true,
			list: [],
			listLoading: true,
			layout: "total, sizes, prev, pager, next, jumper",
			total: 0,
			background: true,
			selectRows: "",
			elementLoadingText: "正在加载...",
			query: {
				name: null,
				source: null,
				type: null,
				updateTime: null,
			},
			queryForm: {
				header: {
					currentTime: "2020-07-16 10:01:30",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
				body: {
					pageNum: 1,
					pageSize: 10,
					name: null,
					source: null,
					type: null,
					updateTime: null,
					updateTimeEnd: null,
					updateTimeStart: null,
				},
			},
			param: {
				header: {
					currentTime: "2020-07-16 10:01:30",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
				body: {},
			},
			// 批量删除，禁用，启用，单个删除，单个启用，禁用使用的参数
			parameter: {
				header: {
					currentTime: "2020-07-16 10:01:30",
					requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
					sourceType: "site",
				},
				body: [],
			},
			obj: {
				seqId: null,
				enabled: 0,
			},
			objArray: [],
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit("pick", [start, end])
						},
					},
				],
			},
			project: [],
			projectType: [],
			resource: [
				{
					name: "香港业务流程",
					key: "process_hk",
				},
				{
					name: "本地业务流程",
					key: "process_nd",
				},
				{
					name: "BR业务流程",
					key: "process_br",
				},
			],
		}
	},

	created() {
		this.fetchData()
		this.fetchProjectName()
		this.fetchProjectType()
	},
	beforeDestroy() {},
	mounted() {},
	methods: {
		setSelectRows(val) {
			this.selectRows = val
		},
		handleAdd() {
			this.$refs["add"].showAdd()
		},
		handleEdit(row) {
			this.$refs["edit"].showEdit(row)
		},

		// 单个删除 & 批量删除
		handleDelete(row) {
			if (row.seqId) {
				this.obj.seqId = row.seqId
				this.parameter.body.push(this.obj)
				this.$baseConfirm("你确定要删除当前项吗", null, async () => {
					const { header } = await deleteProject(this.parameter)
					if (header.retCode === "1") {
						this.$baseMessage(header.retMessage, "success")
					} else {
						this.$baseMessage(header.retMessage, "error")
					}
					this.parameter.body = []
					this.fetchData()
				})
			} else {
				if (this.selectRows.length > 0) {
					this.selectRows.map((item) => {
						;(this.obj.seqId = item.seqId),
							this.parameter.body.push(Object.assign({}, this.obj))
					})
					this.$baseConfirm("你确定要删除选中项吗", null, async () => {
						const { header } = await deleteProject(this.parameter)
						if (header.retCode === "1") {
							this.$baseMessage(header.retMessage, "success")
						} else {
							this.$baseMessage(header.retMessage, "error")
						}
						this.parameter.body = []
						this.fetchData()
					})
				} else {
					this.$baseMessage("未选中任何行", "error")
					return false
				}
			}
		},
		handleSizeChange(val) {
			this.queryForm.body.pageSize = val
			this.fetchData()
		},
		handleCurrentChange(val) {
			this.queryForm.body.pageNum = val
			this.fetchData()
		},
		handleQuery() {
			if (null !== this.query.updateTime) {
				this.queryForm.body.updateTimeStart = returnBitTimestamp(
					this.query.updateTime[0]
				)
				this.queryForm.body.updateTimeEnd = returnDeadtimestamp(
					this.query.updateTime[1]
				)
			} else {
				this.queryForm.body.updateTimeEnd = null
				this.queryForm.body.updateTimeStart = null
			}
			this.queryForm.body.pageNum = 1
			this.queryForm.body.name = this.query.name
			this.queryForm.body.type = this.query.type
			this.queryForm.body.source = this.query.source
			this.fetchData()
		},
		resetForm(formName) {
			this.query.updateTime = null
			this.$refs[formName].resetFields()
		},
		async fetchData() {
			this.listLoading = true
			const { body } = await queryProjectPage(this.queryForm).catch(() => {})
			this.list = body.records
			this.total = body.total
			setTimeout(() => {
				this.listLoading = false
			}, 500)
		},

		// 查询项目信息
		async fetchProjectName() {
			const { body } = await queryProjectName(this.queryForm).catch(() => {})
			this.project = body
		},

		// 查询项目类型
		async fetchProjectType() {
			const { body } = await queryProjectType(this.param).catch(() => {})
			this.projectType = body
		},
	},
}
</script>
<style scoped>
.el-form-item--small.el-form-item {
	margin-bottom: 1px;
	width: 100%;
}
</style>
<style>
.myQuery .el-form-item__content {
	line-height: 32px;
	width: 100% !important;
}
</style>
