// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/**\n * @copyright CBI cbi@chinacbi.com\n * @description 全局主题变量配置，VIP文档内提供多种好看的配色方案（ant-design风格、layui风格、iview风格），请查看VIP文档主题配置篇\n */\n/* stylelint-disable */\n\n.gauge-loader:not(:required) {\n  position: relative;\n  display: inline-block;\n  width: 64px;\n  height: 32px;\n  margin-bottom: 10px;\n  overflow: hidden;\n  text-indent: -9999px;\n  background: #6ca;\n  border-top-left-radius: 32px;\n  border-top-right-radius: 32px;\n}\n\n.gauge-loader:not(:required)::before {\n  position: absolute;\n  top: 5px;\n  left: 30px;\n  width: 4px;\n  height: 27px;\n  content: \"\";\n  background: white;\n  border-radius: 2px;\n  transform-origin: 50% 100%;\n  animation: gauge-loader 4000ms infinite ease;\n}\n\n.gauge-loader:not(:required)::after {\n  position: absolute;\n  top: 26px;\n  left: 26px;\n  width: 13px;\n  height: 13px;\n  content: \"\";\n  background: white;\n  border-radius: 8px;\n}\n\n@keyframes gauge-loader {\n  0% {\n    transform: rotate(-50deg);\n  }\n  10% {\n    transform: rotate(20deg);\n  }\n  20% {\n    transform: rotate(60deg);\n  }\n  24% {\n    transform: rotate(60deg);\n  }\n  40% {\n    transform: rotate(-20deg);\n  }\n  54% {\n    transform: rotate(70deg);\n  }\n  56% {\n    transform: rotate(78deg);\n  }\n  58% {\n    transform: rotate(73deg);\n  }\n  60% {\n    transform: rotate(75deg);\n  }\n  62% {\n    transform: rotate(70deg);\n  }\n  70% {\n    transform: rotate(-20deg);\n  }\n  80% {\n    transform: rotate(20deg);\n  }\n  83% {\n    transform: rotate(25deg);\n  }\n  86% {\n    transform: rotate(20deg);\n  }\n  89% {\n    transform: rotate(25deg);\n  }\n  100% {\n    transform: rotate(-50deg);\n  }\n}", ""]);
// Exports
exports.locals = {
	"menu-color": "hsla(0deg, 20%, 98%, 0.95)",
	"menu-color-active": "hsla(0deg, 20%, 98%, 0.95)",
	"menu-background": "#869ac2"
};
module.exports = exports;
