<!--Kyle 用户工作台 -> summary -->
<template>
  <el-card class="box-card">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            icon="el-icon-search"
            plain
            native-type="submit"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            type="info"
            plain
            native-type="submit"
            @click="resetForm('queryForm')"
          >
            重置
          </el-button>

          <el-form
            ref="queryForm"
            :model="queryForm"
            style="margin-top: 15px"
            class="demo-form-inline"
          >
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="siteSvCode">
                  <el-input
                    v-model="queryForm.siteSvCode"
                    placeholder="Site Visit Code"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-table
            ref="tableSort"
            v-loading="listLoading"
            :data="list"
            :element-loading-text="elementLoadingText"
            :row-key="handleGetSiteSvCode"
            style="margin-top: 15px; width: 100%"
            border
            @selection-change="setSelectRows"
            @sort-change="tableSortChange"
            @cell-click="clickCellHandle"
          >
            <el-table-column type="expand" width="1">
              <template slot-scope="props">
                <el-table
                  v-loading="props.row.expandListLoading"
                  :data="props.row.workOrderlist"
                  :element-loading-text="elementLoadingText"
                  border
                  @sort-change="tableSortChange"
                >
                  <el-table-column
                    label="工单编号"
                    width="130"
                    prop="workOrderNo"
                    align="left"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.workOrderNo || "-" }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="targetSubject" label="目标主体">
                    <template slot-scope="scope">
                      {{ scope.row.targetSubject || "-" }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="目标地址"
                    min-width="250"
                    prop="address"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.address || "-" }}
                    </template>
                  </el-table-column>
                  <el-table-column label="项目" prop="projectName" width="150">
                    <template slot-scope="scope">
                      {{ scope.row.projectName || "-" }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="workOrderStatusValue"
                    label="工单状态"
                    width="120"
                    align="center"
                    sortable
                  >
                    <template slot-scope="scope">
                      {{ scope.row.workOrderStatusValue || "-" }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="visitTime"
                    label="走访时间"
                    align="center"
                    width="100"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.visitTime || "-" }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="innerDeadline"
                    label="内部截止日期"
                    align="center"
                    sortable
                  >
                    <template slot-scope="scope">
                      {{ scope.row.innerDeadline || "-" }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    width="100px"
                    fixed="right"
                    align="center"
                    fit="true"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="详情"
                        placement="top-start"
                      >
                        <el-button
                          size="mini"
                          type="primary"
                          icon="el-icon-document"
                          circle
                          @click="goDetailPage(scope.row)"
                        ></el-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
                <!--展开行分页模块-->
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
                    <el-card>
                      <el-pagination
                        :background="background"
                        :current-page="queryWorkorderForm.pageNum"
                        :layout="layout"
                        :page-size="10"
                        :total="props.row.workOrdertotal"
                        @current-change="handleSonCurrentChange"
                        @size-change="handleSonSizeChange"
                      ></el-pagination>
                    </el-card>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              label="Site Visit Code"
              prop="siteSvCode"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              prop="workOrderNum"
              label="No. of Site"
              align="center"
            ></el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNum"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getRoleKey } from "@/utils";
  import { summaryInfo, workOrderInfo } from "@/api/workplace/user/pchUser";
  export default {
    name: "Summary",
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: "success",
          draft: "gray",
          deleted: "danger",
        };
        return statusMap[status];
      },
    },
    data() {
      return {
        expandKeys: ["workOrderlist", "expandListLoading"],
        imgShow: true,
        // 批量上传附件
        commitAnnex: false,
        title: [],
        list: [],
        workOrderlist: [],
        imageList: [],
        listLoading: true,
        expandListLoading: true,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
        workOrdertotal: 0,
        background: true,
        selectRows: "",
        elementLoadingText: "正在加载...",
        projectForm: {
          body: {
            source: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        queryForm: {
          pageNum: 1,
          pageSize: 10,
          siteSvCode: "",
          userNo: "",
          userRoleNo: "",
        },
        queryWorkorderForm: {
          pageNum: 1,
          pageSize: 10,
          siteSvCode: "",
          index: 0,
        },
        param: {
          body: {},
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 添加对话框Form
        openBatchAdd: false,
        batchAddFrom: {
          name: "12",
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.queryForm.userNo = this.userNo;
      this.queryForm.userRoleNo = getRoleKey("customer", this.roles);
      this.fetchData();
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      tableSortChange() {
        const imageList = [];
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img);
        });
        this.imageList = imageList;
      },
      getRowKeys(row) {
        return row.id;
      },
      clickCellHandle(row, column, event) {
        if (event.cellIndex != 0) {
          this.clickRowHandle(row);
        }
      },
      clickRowHandle(row) {
        this.queryWorkorderForm.siteSvCode = row.siteSvCode;
        let index = this.list.findIndex((elem) => {
          return row.siteSvCode === elem.siteSvCode;
        });
        this.queryWorkorderForm.index = index;

        this.param.body = Object.assign({}, this.queryWorkorderForm);
        this.$refs.tableSort.toggleRowExpansion(row);
        this.$set(this.list[index], "expandListLoading", true);
        workOrderInfo(this.param).then((res) => {
          if (res.header.retCode === "1") {
            this.$set(this.list[index], "workOrderlist", res.body.records);
            this.$set(this.list[index], "workOrdertotal", res.body.total);
          }
          this.$set(this.list[index], "expandListLoading", false);
        });
      },
      setSelectRows(val) {
        this.selectRows = val;
      },
      async handleDownload() {
        if (this.selectRows.length) {
          this.downloadLoading = true;
          const { export_json_to_excel } = await import("@/vendor/ExportExcel");
          const tHeader = ["Site Visit Code", "No. of Site"];
          const filterVal = ["siteSvCode", "workOrderNum"];
          const list = this.selectRows;
          const data = this.formatJson(filterVal, list);
          export_json_to_excel({
            header: tHeader,
            data,
            filename: this.filename,
          });
          this.$refs.tableSort.clearSelection();
          this.downloadLoading = false;
        } else {
          this.$baseMessage("请至少选择一行", "error");
        }
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) => filterVal.map((j) => v[j]));
      },
      // 打开工单单详情页面
      goDetailPage(row) {
        let routeData = this.$router.resolve({
          path: "/workplace/user/summary/detail",
          name: "Detail",
          query: {
            target: row.targetSubject,
            workOrderNo: row.workOrderNo,
            projectId: row.projectId,
            address: row.address,
            projectName: row.projectName,
            seqId: row.seqId,
            userNo: this.userNo,
            procInsId: row.procInsId,
            taskId: row.taskId,
            userRoleNo: getRoleKey("customer", this.roles),
            projectSign: row.projectSign,
          },
        });
        /*** 截掉site字符 */
        routeData.href = routeData.href.slice(5);
        window.open(routeData.href, "_blank");
        // this.$router.push("/workplace/user/summary/detail");
      },
      // 重置
      resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.fetchData();
      },
      handleSonSizeChange(val) {
        this.queryWorkorderForm.pageSize = val;
        this.fetchWorkOrderData();
      },
      handleSonCurrentChange(val) {
        this.queryWorkorderForm.pageNum = val;
        this.fetchWorkOrderData();
      },
      handleQuery() {
        this.queryForm.pageNum = 1;
        this.fetchData();
      },
      async fetchData() {
        this.param.body = Object.assign({}, this.queryForm);
        const { body } = await summaryInfo(this.param);
        this.list = body.records;
        this.total = body.total;
        let refresh_data = {
          todo_total: this.total,
          isDone: false,
        };
        this.$emit("child-todo", refresh_data);

        this.listLoading = false;

        // if (body.records.length === 1) {
        //   this.clickRowHandle(this.list[0]);
        // }
      },
      async fetchWorkOrderData(row) {
        this.param.body = Object.assign({}, this.queryWorkorderForm);
        this.list[this.queryWorkorderForm.index].expandListLoading = true;
        const { header, body } = await workOrderInfo(this.param);
        if (header.retCode === "1") {
          this.list[this.queryWorkorderForm.index].workOrderlist = body.records;
          this.list[this.queryWorkorderForm.index].workOrdertotal = body.total;
          this.list[this.queryWorkorderForm.index].expandListLoading = false;
        }
        let refresh_data = {
          todo_total: this.list[this.queryWorkorderForm.index].workOrdertotal,
          isDone: false,
        };
        this.$emit("child-todo", refresh_data);
        // row.expandListLoading = false;
      },
      /** 指定row的row-key属性 */
      handleGetSiteSvCode(row) {
        // 优化渲染，table为树形数据时，row-key为必填属性
        return row.siteSvCode;
      },
    },
  };
</script>
<style scoped>
  .el-col {
    margin-bottom: 10px;
  }
  .el-col :last-child {
    margin-bottom: 0;
  }

  .demo-form-inline {
    min-height: 50px;
  }

  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100%;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }
  .el-table /deep/.warning-row {
    background: #f8b7bc;
  }
  /* 展开表格的间距 */
  .el-table >>> .el-table__expanded-cell[class*="cell"] {
    padding: 0;
  }
</style>
