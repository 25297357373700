<template>
  <div>
    <el-dialog :title="title"
               class="myDialog"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="60%"
               :fullscreen="flag"
               @close="close(0)">
      <el-backtop target=".myDialog"></el-backtop>
      <i style="position: absolute; top: 24px; right: 41px; cursor: pointer"
         :class="fullIcon"
         :title="fullTitle"
         @click="fullOrClose"></i>
      <div style="margin-bottom: 10px">{{ companyName }}</div>
      <el-tabs v-model="activeName"
               type="border-card"
               @tab-click="handleClick">
        <el-tab-pane label="编辑预约"
                     class="edit_title"
                     name="detail">
          <!--工单进度-->
          <order-process :param="param"></order-process>

          <!--基础信息>-->
          <div class="title">基础信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="workOrderNo"
                             label="工单编号"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.workOrderNo || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="brWorkNo"
                             label="订单编号"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.brWorkNo || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="reportType"
                             label="报告类型"
                             :class-name="brChange('reportTypeFlag') + ' '+ brChange('subReportTypeFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportType || "-" }}
                {{ scope.row.subReportType ? '/' : ''}}
                {{ scope.row.subReportType}}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="projectSource"
                             label="项目来源"
                             align="center">
              <template slot-scope="scope">
                {{ getProjectSource(scope.row.projectSource) || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             :class-name="brChange('subjectFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetSubject || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetAddr1"
                             label="目标地址1"
                             :class-name="brChange('subjectAddressFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr1 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="targetAddr2"
                             label="目标地址2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetAddr2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetContact"
                             label="目标联系人"
                             :class-name="brChange('subjectLinkNameFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetContact || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="contactNumber"
                             label="联系电话"
                             :class-name="brChange('subjectMobileFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.contactNumber || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="duty"
                             label="职务"
                             :class-name="brChange('dutyFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.duty || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderTime"
                             label="下单时间"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.orderTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             align="center"
                             :formatter="eighthBitTime"></el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="clientAbbr"
                             label="委托方简称"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.clientAbbr || "-" }}
              </template>
            </el-table-column>

            <el-table-column prop="reportTimeLimit"
                             label="报告时限"
                             :class-name="brChange('reportTimeLimitFlag')"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.reportTimeLimit || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark1"
                             label="备注1"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark1 || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="remark2"
                             label="备注2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark2 || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark3"
                             label="备注2"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark3 || "-" }}
              </template>
            </el-table-column>
            <el-table-column></el-table-column>
          </el-table>

          <!--附加信息>-->
          <div class="title">附加信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectVisitDate"
                             label="预计走访日期"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectVisitDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="expectServeyDay"
                             label="预计所需调查天数"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectServeyDay || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="needUser"
                             label="所需人手"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.needUser || "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="expectHour"
                             label="预计需时（小时）"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.expectHour || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="registerAddress"
                             label="注册地址"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.registerAddress || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubjectRegStatus"
                             label="目标主体注册状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.targetSubjectRegStatus || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--特别信息>-->
          <div class="title">特别信息</div>

          <el-table :data="tableData"
                    stripe
                    border>
            <el-table-column prop="plSpecialMsg"
                             label="PL特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.plSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="bookSpecialMsg"
                             label="预约特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.bookSpecialMsg || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="monitorSpecialMsg"
                             label="督导特别留言"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.monitorSpecialMsg || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--操作信息-->
          <div class="title">操作信息</div>
          <el-table :data="operationInfo"
                    stripe
                    border>
            <el-table-column prop="operator"
                             label="操作者"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operator || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="role"
                             label="角色"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.role || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="operAction"
                             label="操作状态"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.operAction || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime"
                             label="操作时间"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.createTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="remark"
                             label="备注"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.remark || "-" }}
              </template>
            </el-table-column>
          </el-table>

          <!--预约工单>-->
          <div class="title">预约工单</div>
          <el-form ref="orderForm"
                   :model="orderForm"
                   :rules="rules">
            <el-row :gutter="60">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item v-if="tableData.length && tableData[0].projectSource && tableData[0].projectSource === 'process_br'"
                              label="预约走访时间"
                              prop="bookVisitTimeTmp">
                  <el-date-picker v-model="orderForm.bookVisitTimeTmp"
                                  type="datetime"
                                  placeholder="选择预约走访时间"
                                  format="yyyy-MM-dd HH:mm"
                                  style="width: 100%"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
                <el-form-item v-else
                              label="预约走访时间">
                  <el-date-picker v-model="orderForm.bookVisitTimeTmp"
                                  type="datetime"
                                  placeholder="选择预约走访时间"
                                  format="yyyy-MM-dd HH:mm"
                                  style="width: 100%"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="是否联系目标"
                              prop="contactTargetFlag">
                  <el-select v-model="orderForm.contactTargetFlag"
                             placeholder="请选择是否联系目标"
                             filterable>
                    <el-option v-for="item in contactTargetFlagOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="是否待定"
                              prop="pendingFlag">
                  <el-select v-model="orderForm.pendingFlag"
                             placeholder="请选择是否待定"
                             clearable
                             filterable>
                    <el-option v-for="item in pendingFlagOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="兼职调查人员"
                              prop="partSurveyUser">
                  <el-input v-model="orderForm.partSurveyUser"
                            disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="小记"
                              prop="note">
                  <el-input v-model="orderForm.note"
                            class="my-textarea"
                            type="textarea"
                            placeholder="请输入小记"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="8"
                      :xl="8">
                <el-form-item label="特别留言"
                              prop="bookSpecialMsg">
                  <el-input v-model="orderForm.bookSpecialMsg"
                            class="my-textarea"
                            type="textarea"
                            rows="2"
                            placeholder="请输入特别留言"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="取证资料"
                     name="evidence">
          <evidence ref="evidence"
                    :row="row"></evidence>
        </el-tab-pane>
        <el-tab-pane label="拍摄照片">
          <photo ref="photo"
                 :type="'50'"
                 :row="row"></photo>
        </el-tab-pane>
        <el-tab-pane label="拍摄录像">
          <screenVideo ref="screenVideo"
                       :type="'51'"
                       :row="row"></screenVideo>
        </el-tab-pane>
        <el-tab-pane label="扫描文件"
                     name="scanFile">
          <scanFile ref="scanFile"
                    :type="'52'"
                    :row="row"></scanFile>
        </el-tab-pane>
        <el-tab-pane label="录制音频">
          <screenAudio ref="screenAudio"
                       :type="'53'"
                       :row="row"></screenAudio>
        </el-tab-pane>
        <el-tab-pane label="其他文件"
                     name="telRecord">
          <telRecord ref="telRecord"
                     :type="'54'"
                     :row="row"></telRecord>
        </el-tab-pane>
        <el-tab-pane label="打卡记录">
          <clockIn ref="clockIn"
                   :type="'55'"
                   :row="row"></clockIn>
        </el-tab-pane>
        <el-tab-pane label="会议信息">
          <meeting ref="meeting"
                   :type="'57'"
                   :row="row"></meeting>
        </el-tab-pane>
        <el-tab-pane label="查看小记"
                     name="remark">
          <remark ref="remark"
                  :param="param"></remark>
        </el-tab-pane>
        <el-tab-pane label="退回记录"
                     name="back">
          <back ref="back"
                :order="workOrderNo"></back>
        </el-tab-pane>
        <el-tab-pane label="企业信息"
                     name="company">
          <company ref="company"
                   :row="row"></company>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer"
           class="dialog-footer">
        <el-button v-if="activeName === 'detail'"
                   @click="close">
          取 消
        </el-button>
        <el-button v-if="activeName === 'evidence'"
                   type="success"
                   @click="saveEnvidence">
          保存
        </el-button>
        <!-- 编辑预约中的保存 非取证资料保存 -->
        <el-button v-if="activeName === 'detail'"
                   type="success"
                   :loading="loading"
                   @click="save">
          保 存
        </el-button>
        <el-button type="primary"
                   :loading="submitLoading"
                   @click="submit">
          提 交
        </el-button>
      </div>
    </el-dialog>
    <!--提交工单-->
    <submit ref="submit"
            @close="close"></submit>
  </div>
</template>

<script>
import orderProcess from "@/views/workplace/components/orderProcess";
// 取证资料
import evidence from "@/views/workplace/components/evidence";
// 拍摄照片
import photo from "@/views/workplace/components/photo";
// 拍摄录像
import screenVideo from "@/views/workplace/components/screenVideo";
// 扫描文件
import scanFile from "@/views/workplace/components/scanFile";
// 录制音频
import screenAudio from "@/views/workplace/components/screenAudio";
// 其他文件
import telRecord from "@/views/workplace/components/telRecord";
// 打卡记录
import clockIn from "@/views/workplace/components/clockIn";
// 会议信息
import meeting from "@/views/workplace/components/meeting";
import remark from "@/views/workplace/components/remark";
import back from "@/views/workplace/components/back";
import company from "@/views/workplace/components/company";
import submit from "./submit";
import { edit, getOrderDetail, editDetail } from "@/api/workplace/order/order";
import { mapGetters } from "vuex";
import {
  returnBitTimestamp,
  getRoleKey,
  uuid,
  eighthBitTime,
  getProjectSource,
} from "@/utils";
import { disabledDate } from "@/utils/date";
import { getRole } from "@/api/workplace/tools";
import { getOperationInfo } from "@/api/workplace/investigate/investigate";
export default {
  name: "Edit",
  components: {
    evidence,
    photo,
    screenVideo,
    scanFile,
    screenAudio,
    clockIn,
    meeting,
    remark,
    back,
    company,
    orderProcess,
    submit,
    telRecord,
  },
  data() {
    return {
      activeName: "detail",
      flag: false,
      fullTitle: "最大化",
      fullIcon: "el-icon-full-screen",
      // 防抖
      loading: false,
      submitLoading: false,
      isShowOne: true,
      isShowTwo: false,
      title: "",
      dialogFormVisible: false,
      workOrderNo: "",
      tableData: [],
      orderForm: {
        bookVisitTimeTmp: "",
        bookVisitTime: "",
        nextUserRoleNo: "",
        monitorUser: "",
        investigate: "",
        contactTargetFlag: 1,
        pendingFlag: "",
        partSurveyUser: "",
        bookSpecialMsg: "",
        note: "",
      },
      contactTargetFlagOptions: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      pendingFlagOptions: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],

      // cfe add
      roleOptions: [
        { value: "supervisor", label: "督导人员" },
        { value: "full_investigator", label: "调查人员" },
      ],
      investigateOptions: [],
      supervisionOptions: [],
      partInvestigateOptions: [],
      roleForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      // 编辑接口所需参数
      parameter: {
        body: {
          bookSpecialMsg: null,
          bookVisitTime: "",
          console: "book_person",
          contactTargetFlag: null,
          monitorUser: null,
          nextTaskId: "",
          nextUserNo: "",
          nextUserRoleNo: null,
          note: null,
          operAction: "工单预约",
          pendingFlag: null,
          procInsId: null,
          projectSource: null,
          remark: "",
          step: "预约工作台-工单预约-待处理",
          surveyUser: null,
          taskId: null,
          userNo: "",
          userRoleNo: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      pickerOptions: { disabledDate },
      // 提交工单调查参数
      param: {
        body: {
          pageNum: 1,
          pageSize: 10,
          console: "book_person",
          nextTaskId: "",
          nextUserNo: "",
          nextUserRoleNo: "",
          note: "",
          operAction: "工单预约",
          procInsId: "",
          projectSource: "",
          remark: "",
          step: "预约工作台-工单预约-待处理",
          taskId: "",
          workOrderNo: "",
        },
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 批量提交的参数
      batchPara: {
        body: [],
        header: {
          currentTime: "2020-09-09 12:12:12",
          requestId: uuid(),
          sourceType: "site",
        },
      },
      object: [],
      // 获取工单详情
      detailParam: {
        body: {
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      // 获取工单预约详情
      orderParam: {
        body: {
          workOrderNo: null,
        },
        header: {
          currentTime: "",
          requestId: "1",
          sourceType: "site",
        },
      },
      rules: {
        note: [
          {
            max: 500,
            message: "小记不能超过500个汉字",
            trigger: "blur",
          },
        ],
        bookSpecialMsg: [
          {
            max: 2000,
            message: "特别留言不能超过2000个汉字",
            trigger: "blur",
          },
        ],
        bookVisitTimeTmp: [
          {
            required: true,
            message: "请选择预约走访时间",
            trigger: ["change", "blur"],
          },
        ],
      },
      // 给子组件传值
      row: null,
      // 操作工单日志信息
      operationInfo: [],
      companyName: null,
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
    }),
  },
  created() {
    this.param.body.userNo = this.userNo;
    this.param.body.userRoleNo = getRoleKey("book_person", this.roles);
    this.parameter.body.userNo = this.userNo;
    this.parameter.body.userRoleNo = getRoleKey("book_person", this.roles);
    this.detailParam.body.userNo = this.userNo;
    this.detailParam.body.userRoleNo = getRoleKey("book_person", this.roles);
    this.detailParam.header.currentTime = returnBitTimestamp(new Date());
  },
  methods: {
    async showEdit(row) {
      this.object = [];
      this.batchPara.body = [];
      this.row = Object.assign(row, {
        workplace: "book_person",
        flag: "edit",
      });
      this.row.userNo = this.userNo;
      this.row.userRoleNo = getRoleKey("book_person", this.roles);
      this.title = "编辑预约";

      this.getInvestigateOption();
      this.getSupervisionOption();
      // 获取兼职调查人员
      this.getPartInvestigatorOption();

      this.tableData = [];
      this.workOrderNo = row.workOrderNo;
      this.detailParam.body.workOrderNo = row.workOrderNo;
      this.param.body.workOrderNo = row.workOrderNo;
      // 获取工单详情
      this.tableData.push(
        Object.assign({}, (await getOrderDetail(this.detailParam)).body)
      );
      this.companyName = this.tableData[0].targetSubject;
      // 获取操作工单日志信息
      this.operationInfo = (await getOperationInfo(this.param)).body;

      this.object.push(this.row);
      // 获取工单预约信息
      this.orderParam.body.workOrderNo = row.workOrderNo;
      this.orderParam.header.currentTime = returnBitTimestamp(new Date());

      editDetail(this.orderParam).then((response) => {
        if (response.header.retCode == "1") {
          this.orderForm = Object.assign(this.orderForm, response.body);
          for (let i = 0; i < this.partInvestigateOptions.length; i++) {
            if (
              response.body.partSurveyUser ===
              this.partInvestigateOptions[i].label
            ) {
              this.orderForm.partSurveyUser = response.body.partSurveyUser;
              break;
            } else {
              this.orderForm.partSurveyUser = "";
            }
          }

          // 下单时间
          if (
            response.body.bookVisitTime &&
            response.body.bookVisitTime.length
          ) {
            this.orderForm.bookVisitTimeTmp = Date.parse(
              response.body.bookVisitTime
            );
          }

          /** 是否联系目标 */
          if (this.orderForm.contactTargetFlag === null) {
            this.orderForm.contactTargetFlag = 1;
          }
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      });

      this.dialogFormVisible = true;
      setTimeout(() => {
        this.getDetail();
      }, 10);
    },
    getDetail() {
      this.$refs["photo"].fetchData();
      this.$refs["screenVideo"].fetchData();
      this.$refs["scanFile"].fetchData();
      this.$refs["screenAudio"].fetchData();
      this.$refs["clockIn"].fetchData();
      this.$refs["meeting"].fetchData();
      this.$refs.telRecord.fetchData();
      this.$refs.remark.fetchData();
      this.$refs.back.fetchData();
      this.$refs.company.init();
      this.activeName = "detail";
    },
    // 详情页编辑
    handleClick(tab, event) {
      this.$refs["evidence"].clearInterval();
      if (tab.name === "evidence") {
        this.$refs["evidence"].reset();
        this.$refs["evidence"].fetchData();
        this.$refs["evidence"].createTimer();
      } else if (tab.name === "scanFile") {
        this.$refs["scanFile"].fetchData();
      }
    },
    close(levelPage) {
      this.$refs["orderForm"].resetFields();
      this.orderForm = this.$options.data().orderForm;
      this.orderForm.contactTargetFlag = 1;
      this.dialogFormVisible = false;
      if (levelPage == undefined) {
        this.$emit("refreshData");
      }
      this.flag = false;
      this.fullIcon = "el-icon-full-screen";
      this.fullTitle = "最大化";
      this.$refs.evidence.reset();
    },
    save() {
      this.$refs["orderForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.parameter.body.procInsId = this.orderForm.procInsId;
          this.parameter.body.bookSpecialMsg = this.orderForm.bookSpecialMsg;

          if (this.orderForm.bookVisitTimeTmp) {
            this.orderForm.bookVisitTime = returnBitTimestamp(
              this.orderForm.bookVisitTimeTmp
            );
            this.parameter.body.bookVisitTime = this.orderForm.bookVisitTime;
          } else {
            this.parameter.body.bookVisitTime = "";
          }

          this.parameter.body.contactTargetFlag =
            this.orderForm.contactTargetFlag;
          this.parameter.body.pendingFlag = this.orderForm.pendingFlag;
          if (null !== this.orderForm.monitorUser) {
            this.parameter.body.nextUserNo = this.orderForm.monitorUser;
          }
          if (null !== this.orderForm.investigate) {
            this.parameter.body.nextUserNo = this.orderForm.investigate;
          }
          this.parameter.body.nextUserRoleNo = this.orderForm.nextUserRoleNo;
          this.parameter.body.note = this.orderForm.note;
          this.parameter.body.workOrderNo = this.orderForm.workOrderNo;
          // 操作记录
          this.parameter.body.operAction = "保存小记";

          const { header } = await edit(Object.assign({}, this.parameter));
          if (header.retCode === "1") {
            this.$baseMessage(header.retMessage, "success");
          } else {
            this.$baseMessage(header.retMessage, "error");
          }

          this.dialogFormVisible = false;
          this.$emit("refreshData");
          this.form = this.$options.data().form;
          this.isShowOne = false;
          this.isShowTwo = false;
          this.loading = false;
          return true;
        } else {
          return false;
        }
      });
    },
    /**取证资料保存 */
    saveEnvidence() {
      this.$refs["evidence"].save();
    },
    async submit() {
      this.$refs["orderForm"].validate(async (valid) => {
        if (valid) {
          if (this.orderForm.bookVisitTimeTmp) {
            this.orderForm.bookVisitTime = returnBitTimestamp(
              this.orderForm.bookVisitTimeTmp
            );
            this.parameter.body.bookVisitTime = this.orderForm.bookVisitTime;
          } else {
            this.parameter.body.bookVisitTime = "";
          }

          this.parameter.body.procInsId = this.orderForm.procInsId;
          this.parameter.body.bookSpecialMsg = this.orderForm.bookSpecialMsg;

          this.parameter.body.contactTargetFlag =
            this.orderForm.contactTargetFlag;
          this.parameter.body.pendingFlag = this.orderForm.pendingFlag;
          if (null !== this.orderForm.monitorUser) {
            this.parameter.body.nextUserNo = this.orderForm.monitorUser;
          }
          if (null !== this.orderForm.investigate) {
            this.parameter.body.nextUserNo = this.orderForm.investigate;
          }
          this.parameter.body.nextUserRoleNo = this.orderForm.nextUserRoleNo;
          this.parameter.body.note = this.orderForm.note;
          this.parameter.body.workOrderNo = this.orderForm.workOrderNo;

          const { header } = await edit(Object.assign({}, this.parameter));
          if (header.retCode === "1") {
            this.object[0].bookVisitTime = this.parameter.body.bookVisitTime;
            this.$refs["submit"].showSubmit(this.object);
          }
          this.$emit("refreshData");
          this.$refs["orderForm"].resetFields();
          this.orderForm = this.$options.data().orderForm;
          /**默认值 */
          this.orderForm.contactTargetFlag = 1;
        }
      });
    },
    changeRole(value) {
      "supervisor" === value
        ? (this.isShowOne = true)
        : (this.isShowOne = false);
      "full_investigator" === value
        ? (this.isShowTwo = true)
        : (this.isShowTwo = false);
    },
    getInvestigateOption() {
      let data = Object.assign({}, this.roleForm);

      //实地全职调查员[包括经理]
      data.body = [
        {
          roleKey: "supervisor",
        },
      ];
      getRole(data).then((response) => {
        if (response.header.retCode == "1") {
          this.investigateOptions = response.body;
        }
      });
    },
    getPartInvestigatorOption() {
      let data = Object.assign({}, this.roleForm);

      // 兼职调查员
      data.body = [
        {
          roleKey: "part_investigator",
        },
      ];
      getRole(data).then((response) => {
        if (response.header.retCode == "1") {
          this.partInvestigateOptions = response.body;
        }
      });
    },
    getSupervisionOption() {
      let data = Object.assign({}, this.roleForm);

      //实地全职调查员[包括经理]
      data.body = [
        {
          roleKey: "full_investigator",
        },
      ];
      getRole(data).then((response) => {
        if (response.header.retCode == "1") {
          this.supervisionOptions = response.body;
        }
      });
    },
    fullOrClose() {
      this.flag = !this.flag;
      if (this.flag) {
        this.fullIcon = "el-icon-minus";
        this.fullTitle = "最小化";
      } else {
        this.fullIcon = "el-icon-full-screen";
        this.fullTitle = "最大化";
      }
    },
    eighthBitTime(row, column) {
      return eighthBitTime(row, column);
    },
    getProjectSource(code) {
      return getProjectSource(code);
    },
    // 修改内容标黄
    brChange(data) {
      if (
        this.tableData.length &&
        this.tableData[0].siteBrUpdateColumnRes &&
        this.tableData[0].siteBrUpdateColumnRes[data]
      ) {
        return "is-change";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
body {
  font-weight: 700;
  color: #666;
}
.edit_title .title {
  margin: 10px 0;
}
.el-step__title {
  font-size: 14px;
}
.el-step__title.is-process {
  font-weight: bold;
  color: #666;
}
html body .el-table th .cell {
  font-weight: 700;
  color: #666;
}

.el-form-item__label {
  font-weight: normal;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner,
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100% !important;
}

.el-select,
.el-cascader {
  width: 100%;
}
.my-textarea > textarea {
  height: 32px;
}
.el-dialog__body {
  padding: 10px 20px 20px 20px;
}

.el-table .el-table__body td.is-change {
  background: #ffff9f;
}
</style>
