// Kyle API 工作台 -> 上传附件
import request from "@/utils/request";
// import { raw } from "core-js/fn/string";

// 批量上传模板
export function uploadAttachment(data) {
  return request({
    url: "/plWorkOrder/uploadAttachment",
    method: "post",
    data,
  });
}
