<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    append-to-body
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否确认放弃？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="内审人员" prop="auditUser">
        <el-select
          v-model="form.auditUser"
          placeholder="请选择内审人员"
          clearable
          filterable
        >
          <el-option
            v-for="item in nsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="放弃类型" prop="abandonTypeId">
        <el-select
          v-model="form.abandonTypeId"
          placeholder="请选择放弃类型"
          clearable
          filterable
          @click.native="clearQuitReason"
          @change="showReason"
        >
          <el-option
            v-for="item in quitOptions"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="放弃原因" prop="abandonCommentId">
        <el-select
          v-model="form.abandonCommentId"
          placeholder="请选择放弃原因"
          clearable
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model.trim="form.note"
          type="textarea"
          placeholder="请输入小记"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    getReturnReason,
    getInternalAudit,
    getQuitType,
    quit,
  } from "@/api/workplace/investigate/investigate";
  import { mapGetters } from "vuex";
  import { returnBitTimestamp, getRoleKey } from "@/utils";

  export default {
    name: "Edit",
    data() {
      return {
        loading: false,
        openLevel: 0,
        form: {
          auditUser: null,
          abandonTypeId: null,
          abandonCommentId: null,
          note: null,
        },
        rules: {
          auditUser: [
            { required: true, trigger: "blur", message: "请选择内审人员" },
          ],
          abandonTypeId: [
            { required: true, trigger: "blur", message: "请选择放弃类型" },
          ],
          abandonCommentId: [
            { required: true, trigger: "blur", message: "请选择放弃原因" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
        nsOptions: [],
        quitOptions: [],

        // 查询内审人员参数
        nsParam: {
          body: [
            {
              roleKey: "verify_people",
            },
          ],
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        // 查询放弃类型参数
        quitParam: {
          body: {
            templateType: 80,
          },
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        // 查询放弃原因参数
        quitReasonParam: {
          body: {
            templateTypeSeqId: null,
          },
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        options: [],
        // 提交放弃需要的参数
        quitSubmitParam: {
          body: [],
          header: {
            currentTime: "2020-08-04 00:00:00",
            requestId: "111",
            sourceType: "site",
          },
        },
        obj: {
          abandonCommentId: null,
          abandonTypeId: null,
          auditUser: null,
          console: "investigator",
          note: null,
          operAction: "工单放弃",
          procInsId: null,
          step: "调查工作台-工单录入-待处理",
          taskId: null,
          userNo: "",
          userRoleNo: "",
          workOrderNo: null,
          auditFlag: null,
          // 异步生成报告5个触发点参数
          triggerPoint: "",
        },
        object: null,
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.obj.userNo = this.userNo;
      this.obj.userRoleNo = getRoleKey("investigate", this.roles);
    },
    methods: {
      async showQuit(row) {
        this.title = "放弃工单";
        this.form = Object.assign({}, row[0]);
        this.object = row;
        // 获取内审人员
        this.nsOptions = Object.assign(
          {},
          (await getInternalAudit(this.nsParam)).body
        );

        // 获取放弃类型
        this.quitOptions = Object.assign(
          {},
          (await getQuitType(this.quitParam)).body
        );

        this.dialogFormVisible = true;

        this.quitSubmitParam.body = [];
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("refreshData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            for (let i = 0; i < this.object.length; i++) {
              this.obj.auditUser = this.form.auditUser;
              this.obj.abandonTypeId = this.form.abandonTypeId;
              this.obj.abandonCommentId = this.form.abandonCommentId;
              this.obj.note = this.form.note;

              this.obj.procInsId = this.object[i].procInsId;
              this.obj.taskId = this.object[i].taskId;
              this.obj.workOrderNo = this.object[i].workOrderNo;
              this.obj.auditFlag = this.object[i].auditFlag;
              // 6694249168413790208  content: "上门前放弃"
              // 6694249259958669312  content: "上门后放弃"
              // 异步生成报告5个触发点，参数名称triggerPoint
              if ("6694249259958669312" === this.form.abandonTypeId) {
                this.obj.triggerPoint = "survey_abandon";
              } else {
                this.obj.triggerPoint = "";
              }
              this.quitSubmitParam.body.push(Object.assign({}, this.obj));
            }
            const { header } = await quit(this.quitSubmitParam);
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }

            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            if (this.openLevel === 0) {
            } else {
              //编辑页打开
              this.$emit("close");
            }
            this.$emit("refreshData");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
      async showReason(value) {
        // 查询放弃原因参数
        this.quitReasonParam.body.templateTypeSeqId = value;
        this.options = Object.assign(
          {},
          (await getReturnReason(this.quitReasonParam)).body
        );
      },
      clearQuitReason() {
        if (
          this.form.abandonCommentId !== undefined &&
          this.form.abandonCommentId !== null
        ) {
          this.form.abandonCommentId = null;
        }
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
