var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      class: _vm.myDialog,
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "55%",
        fullscreen: _vm.fullFlag,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("el-backtop", { attrs: { target: _vm.myDialogPoint } }),
      _c("i", {
        class: _vm.fullIcon,
        staticStyle: {
          position: "absolute",
          top: "24px",
          right: "41px",
          cursor: "pointer",
        },
        attrs: { title: _vm.fullTitle },
        on: { click: _vm.triggerFullOrHelf },
      }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "workOrderNo",
                  label: "工单编号",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.workOrderNo || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "brWorkNo", label: "订单编号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.brWorkNo || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reportType",
                  label: "报告类型",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.reportType || "-") +
                            " " +
                            _vm._s(scope.row.subReportType ? "/" : "") +
                            " " +
                            _vm._s(scope.row.subReportType) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "projectNameNo",
                  label: "Project Name",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.projectNameNo || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "projectName", label: "项目", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.projectName || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "projectSource",
                  label: "项目来源",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getProjectSource(scope.row.projectSource) ||
                                "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "targetSubject",
                  label: "目标主体",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.targetSubject || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "国家/省份/城市/地域（县级市）",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.address || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetAddr1",
                  label: "目标地址1",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.targetAddr1 || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "targetAddr2",
                  label: "目标地址2",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.targetAddr2 || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetContact",
                  label: "目标联系人",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.targetContact || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contactNumber",
                  label: "联系电话",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.contactNumber || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "duty", label: "职务", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.duty || "-") + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderTime",
                  label: "下单时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.orderTime || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "innerDeadline",
                  label: "内部截止日期",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.innerDeadline || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "remark1", label: "备注1", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark1 || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remark2", label: "备注2", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark2 || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remark3", label: "备注2", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark3 || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "clientAbbr",
                  label: "委托方简称",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.clientAbbr || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "clientFullName",
                  label: "委托方全称",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.clientFullName || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reportTimeLimitValue",
                  label: "报告时限",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.reportTimeLimitValue || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("附加信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "expectVisitDate",
                  label: "预计走访日期",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.expectVisitDate || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "expectServeyDay",
                  label: "预计所需调查天数",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.expectServeyDay || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "needUser", label: "所需人手", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.needUser || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "expectHour",
                  label: "预计需时（小时）",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.expectHour || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "registerAddress",
                  label: "注册地址",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.registerAddress || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetSubjectRegStatus",
                  label: "目标主体注册状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.targetSubjectRegStatus || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "visitFee", label: "走访收费", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.visitFee || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "urgentVisitFee",
                  label: "加急走访收费",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.urgentVisitFee || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "againVisitFee",
                  label: "再次走访收费",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.againVisitFee || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "feeBasis", label: "收费依据", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.feeBasis || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetOrganization1",
                  label: "目标机构1",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.targetOrganization1 || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "targetOrganization2",
                  label: "目标机构2",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.targetOrganization2 || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "targetResult",
                  label: "目标地址预核结果",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.targetResult || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "infeasibleReason",
                  label: "不可行原因/待确认项目",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.infeasibleReason || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mailHeader",
                  label: "邮件抬头",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.mailHeader || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("特别信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.verifyIdData, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "plSpecialMsg",
                  label: "PL特别留言",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.plSpecialMsg || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bookSpecialMsg",
                  label: "预约特别留言",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.bookSpecialMsg || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "monitorSpecialMsg",
                  label: "督导特别留言",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.monitorSpecialMsg || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.annexGroup.length !== 0,
                  expression: "annexGroup.length !== 0",
                },
              ],
              staticClass: "title",
            },
            [_vm._v("附件")]
          ),
          _c(
            "el-row",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            _vm._l(_vm.annexGroup, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.handleDownload({
                                name: item.fileName,
                                path: item.path,
                              })
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/icon/annex.svg"),
                              alt: "",
                              width: "80",
                              height: "80",
                            },
                          }),
                          _c("p", [_vm._v(_vm._s(item.fileName))]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "title" }, [_vm._v("操作信息")]),
          _c(
            "el-table",
            { attrs: { data: _vm.workOrderLogs, stripe: "", border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "operator", label: "操作者", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.operator || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "role", label: "角色", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.role || "-") + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operAction",
                  label: "操作状态",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.operAction || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.createTime || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.remark || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c("div", { staticClass: "title" }, [_vm._v("对账ID添加")]),
      _c(
        "el-form",
        {
          ref: "verifyForm",
          staticClass: "table-class demo-form-inline",
          attrs: { model: _vm.verifyForm, rules: _vm.verifyFormRules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "内部截止时间", prop: "innerDeadline" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          placeholder: "选择内部截止时间",
                          format: "yyyy-MM-dd HH:mm",
                        },
                        model: {
                          value: _vm.verifyForm.innerDeadline,
                          callback: function ($$v) {
                            _vm.$set(_vm.verifyForm, "innerDeadline", $$v)
                          },
                          expression: "verifyForm.innerDeadline",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "添加对账ID", prop: "reconciliationId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请添加对账ID" },
                        model: {
                          value: _vm.verifyForm.reconciliationId,
                          callback: function ($$v) {
                            _vm.$set(_vm.verifyForm, "reconciliationId", $$v)
                          },
                          expression: "verifyForm.reconciliationId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "小记", prop: "content" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入小记" },
                        model: {
                          value: _vm.verifyForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.verifyForm, "content", $$v)
                          },
                          expression: "verifyForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "添加SV Code", prop: "svCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入SV Code" },
                        model: {
                          value: _vm.verifyForm.svCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.verifyForm, "svCode", $$v)
                          },
                          expression: "verifyForm.svCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否扣罚", prop: "deductionFlag" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择是否扣罚",
                            filterable: "",
                          },
                          model: {
                            value: _vm.verifyForm.deductionFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.verifyForm, "deductionFlag", $$v)
                            },
                            expression: "verifyForm.deductionFlag",
                          },
                        },
                        _vm._l(_vm.penaltySelect, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticClass: "isQuestion",
                  attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否质疑", prop: "question" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择是否质疑",
                            disabled: true,
                            filterable: "",
                          },
                          model: {
                            value: _vm.verifyForm.question,
                            callback: function ($$v) {
                              _vm.$set(_vm.verifyForm, "question", $$v)
                            },
                            expression: "verifyForm.question",
                          },
                        },
                        _vm._l(_vm.questionSelect, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "default" }, on: { click: _vm.cancelDialog } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", loading: _vm.saveLoading },
              on: {
                click: function ($event) {
                  return _vm.saveVerify("verifyForm")
                },
              },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }