<!-- kyle 答复 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="50%"
    @close="close"
  >
    <div>
      <el-table v-loading="listLoading" :data="recordList" stripe border>
        <el-table-column prop="createTime" label="时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.createTime || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="操作人员" align="center">
          <template slot-scope="scope">
            {{ scope.row.operator || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="operAction" label="操作" align="center">
          <template slot-scope="scope">
            {{ scope.row.operAction || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          label="原因"
          width="120px"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.reason !== null"
              class="item"
              effect="dark"
              content="查看"
              placement="top-start"
            >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-document"
                circle
                @click="readReason(scope.row.reason)"
              ></el-button>
            </el-tooltip>
            <div v-else>{{ "-" }}</div>
          </template>
        </el-table-column>
      </el-table>

      <!--分页模块-->
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
          <el-card>
            <el-pagination
              :background="background"
              :current-page="queryForm.pageNum"
              :layout="layout"
              :page-size="queryForm.pageSize"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button plain @click="close">取消</el-button>
      <el-button type="primary" :loading="loading" @click="confirm">
        确定
      </el-button>
    </div>
    <!-- 查看原因对话框  -->
    <el-dialog
      title="原因"
      :visible.sync="reasonVisible"
      :close-on-click-modal="false"
      append-to-body
      width="50%"
      @close="reasonClose"
    >
      <div class="htmlContent" v-html="content"></div>
      <div slot="footer" class="dialog-footer">
        <el-button plain @click="reasonClose">取消</el-button>
        <el-button type="primary" :loading="loading" @click="reasonClose">
          确定
        </el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import { getRecordTable } from "@/api/workplace/internalAudit/internalAudit";
  export default {
    name: "Submit",
    html: "",
    data() {
      return {
        loading: false,
        content: "",
        value: "",
        listLoading: true,
        userNo: "",
        userRoleNo: "",
        title: "",
        dialogFormVisible: false,
        reasonVisible: false,
        background: true,
        total: 0,
        layout: "total, sizes, prev, pager, next, jumper",
        queryForm: {
          pageNum: 1,
          pageSize: 10,
        },
        recordList: [],
        param: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            pageNum: 1,
            pageSize: 10,
          },
          header: {
            currentTime: "",
            requestId: "111",
            sourceType: "site",
          },
        },
      };
    },
    created() {},
    methods: {
      handleShowHtml(html) {
        this.html = html;
      },
      showRecord(row, userNo, userRoleNo) {
        this.title = "审核工单-质疑记录";
        this.param.body.workOrderNo = row.workOrderNo;
        this.param.body.userNo = userNo;
        this.param.body.userRoleNo = userRoleNo;
        this.dialogFormVisible = true;
        this.fetchData();
      },
      async fetchData() {
        this.listLoading = true;
        // 获取查看记录
        const { header, body } = await getRecordTable(this.param);
        if (header.retCode === "1") {
          this.recordList = body.records;
          this.total = body.total;
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
        setTimeout(() => {
          this.listLoading = false;
        }, 500);
      },
      readReason(reason) {
        this.content = reason;
        this.reasonVisible = true;
      },
      handleSizeChange(val) {
        this.param.body.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.param.body.pageNum = val;
        this.fetchData();
      },
      close() {
        /**初始化 */
        this.param.body.pageNum = 1;
        this.param.body.pageSize = 10;
        this.recordList = [];
        this.dialogFormVisible = false;
      },
      reasonClose() {
        this.loading = true;
        this.reasonVisible = false;
        this.loading = false;
      },
      reasonConfirm() {
        this.reasonVisible = false;
      },
      confirm() {
        this.loading = true;
        /**初始化 */
        this.param.body.pageNum = 1;
        this.param.body.pageSize = 10;
        this.recordList = [];
        this.dialogFormVisible = false;
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
  .htmlContent {
    max-height: 400px;
    overflow: auto;
  }
  .htmlContent >>> img {
    display: block;
    max-width: 100%;
    max-height: 300px;
  }
</style>

<style lang="scss">
  .vab-quill-content {
    ::v-deep {
      .el-form-item__content {
        line-height: normal;
      }
    }
  }
</style>
