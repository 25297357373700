var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "imgDialog",
      attrs: { visible: _vm.dialogimgVisible, "append-to-body": "" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogimgVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { height: "350px" },
          attrs: { "tab-position": _vm.tabPosition },
          model: {
            value: _vm.activeTabsValue,
            callback: function ($$v) {
              _vm.activeTabsValue = $$v
            },
            expression: "activeTabsValue",
          },
        },
        _vm._l(_vm.imgData, function (imgItem, imgIndex) {
          return _c(
            "el-tab-pane",
            {
              key: imgIndex,
              attrs: {
                name: imgItem.name,
                stretch: "true",
                label: imgItem.name,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: imgItem.name,
                                    placement: "top",
                                    effect: "light",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        width: "95%",
                                        margin: "0 auto",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            imgItem.name.length > 30
                                              ? imgItem.name.substring(0, 30) +
                                                  "..."
                                              : imgItem.name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.judgeFileType(imgItem.path)
                                ? _c("viewer", [
                                    _c("img", {
                                      staticStyle: { "max-height": "300px" },
                                      attrs: {
                                        src: _vm.ossPath + imgItem.path,
                                      },
                                    }),
                                  ])
                                : _c("img", {
                                    staticStyle: {
                                      width: "160px",
                                      height: "160px",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      src: require("@/icon/pic.svg"),
                                      fit: _vm.fit,
                                      title: imgItem.name,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownload(imgItem)
                                      },
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }