var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "mod-login" },
    [
      _vm.nodeEnv !== "development"
        ? _c("el-alert", {
            staticStyle: { position: "fixed" },
            attrs: {
              title: "欢迎登录实地平台！",
              type: "success",
              closable: false,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "login-body" }, [
        _vm._m(0),
        _c("div", { staticClass: "login-form" }, [
          _c(
            "div",
            { staticClass: "login-box" },
            [
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "inner-login",
                  attrs: {
                    model: _vm.loginForm,
                    rules: _vm.loginFormRules,
                    "label-position": "left",
                  },
                },
                [
                  _c("div", { staticClass: "logo" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/login_images/logo-TRACE.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "title" }, [_vm._v("登录")]),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { prop: "username" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "svg-container svg-container-admin",
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: ["fas", "user"] },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            attrs: {
                              placeholder: "请输入用户名",
                              tabindex: "1",
                              type: "text",
                            },
                            model: {
                              value: _vm.loginForm.username,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.loginForm,
                                  "username",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "loginForm.username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("vab-icon", {
                                attrs: { icon: ["fas", "lock"] },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            key: _vm.passwordType,
                            ref: "password",
                            attrs: {
                              type: _vm.passwordType,
                              tabindex: "2",
                              placeholder: "请输入密码",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleLogin.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.loginForm.password,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.loginForm,
                                  "password",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "loginForm.password",
                            },
                          }),
                          _vm.passwordType === "password"
                            ? _c(
                                "span",
                                {
                                  staticClass: "show-password",
                                  on: { click: _vm.handlePassword },
                                },
                                [
                                  _c("vab-icon", {
                                    attrs: { icon: ["fas", "eye-slash"] },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "show-password",
                                  on: { click: _vm.handlePassword },
                                },
                                [
                                  _c("vab-icon", {
                                    attrs: { icon: ["fas", "eye"] },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("vab-icon", {
                                attrs: { icon: ["fas", "shield-alt"] },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "login-btn-ver",
                              staticStyle: {
                                width: "100%",
                                height: "35px",
                                "text-align": "left",
                                "padding-left": "40px",
                              },
                              attrs: { type: _vm.isSuccess },
                              on: { click: _vm.showVal },
                            },
                            [_vm._v(" " + _vm._s(_vm.showText) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item login" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "login-btn",
                          attrs: { loading: _vm.loading, type: "primary" },
                          on: { click: _vm.handleLogin },
                        },
                        [_vm._v(" 登录 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._m(1),
      _c(
        "el-dialog",
        {
          staticClass: "myDialog",
          attrs: {
            title: "图片转正完成校验",
            visible: _vm.showValidate,
            width: "350px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showValidate = $event
            },
            close: _vm.close,
          },
        },
        [
          _c("el-button", {
            staticStyle: { position: "absolute", right: "10px" },
            attrs: { icon: "el-icon-refresh", circle: "", type: "warning" },
            on: { click: _vm.refresh },
          }),
          _c("drag-verify-img-rotate", {
            ref: "dragVerify",
            attrs: {
              imgsrc: _vm.validateImage,
              "is-passing": _vm.isPassing,
              "show-tips": true,
              text: "请按住滑块拖动",
              "success-text": "验证通过",
              "fail-tip": "验证失败，图片旋转正即可通过校验",
              "diff-degree": "90",
              "handler-icon": "el-icon-d-arrow-right",
              "success-icon": "el-icon-circle-check",
              width: "300",
            },
            on: {
              "update:isPassing": function ($event) {
                _vm.isPassing = $event
              },
              "update:is-passing": function ($event) {
                _vm.isPassing = $event
              },
              passcallback: _vm.passCallBack,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-text" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/login_images/trace_tagline.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-footer" }, [
      _c("div", { staticClass: "foot-nav" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/login_images/logo1.svg"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "foot-version" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://beian.miit.gov.cn/#/Integrated/index",
              target: "_blank",
            },
          },
          [_vm._v("京ICP备2020046295号")]
        ),
        _c("span", [
          _vm._v(
            "© 2023 Central Business Information Limited. All rights reserved."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }