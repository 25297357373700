var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass: "info-table",
      staticStyle: { width: "100%", height: "100%" },
      attrs: { cellspacing: "0" },
    },
    [
      _c("thead"),
      _c(
        "tbody",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("tr", [
            _c("td", [_vm._v("企业名称")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.name || "-"))]),
            _c("td", [_vm._v("组织机构代码")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.org_no || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("统一社会信用代码")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.credit_no || "-"))]),
            _c("td", [_vm._v("经营状态")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.status || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("企业注册号")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.reg_no || "-"))]),
            _c("td", [_vm._v("成立日期")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.start_date || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("营业开始日期")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.term_start || "-"))]),
            _c("td", [_vm._v("营业结束日期")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.term_end || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("公司类型")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.econ_kind || "-"))]),
            _c("td", [_vm._v("核准日期")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.check_date || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("法定代表人")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.oper_name || "-"))]),
            _c("td", [_vm._v("登记机关")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.belong_org || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("注册资本")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.regist_capi || "-"))]),
            _c("td", [_vm._v("注销日期")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.end_date || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("邮箱")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.email || "-"))]),
            _c("td", [_vm._v("电话")]),
            _c("td", [_vm._v(_vm._s(_vm.companyBaseData.phone || "-"))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("网址")]),
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v(_vm._s(_vm.companyBaseData.site || "-")),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("企业地址")]),
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v(_vm._s(_vm.companyBaseData.address || "-")),
            ]),
          ]),
          _c("tr", [
            _c("td", { attrs: { rowspan: "3" } }, [_vm._v("经营范围")]),
            _c("td", { attrs: { rowspan: "3", colspan: "3" } }, [
              _vm._v(_vm._s(_vm.companyBaseData.scope || "-")),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }