/**
 * @copyright CBI cbi@chinacbi.com
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, " ") +
    '"}'
  );
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
  const parent = data.filter(
    (value) => value.parentId === "undefined" || value.parentId == null
  );
  const children = data.filter(
    (value) => value.parentId !== "undefined" && value.parentId != null
  );
  const translator = (parent, children) => {
    parent.forEach((parent) => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children));
          temp.splice(index, 1);
          translator([current], temp);
          typeof parent.children !== "undefined"
            ? parent.children.push(current)
            : (parent.children = [current]);
        }
      });
    });
  };
  translator(parent, children);
  return parent;
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
  const result = [];
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId,
      });
      const child = data.children;
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i]);
        }
      }
    };
    loop(item);
  });
  return result;
}

export function translateTemplateTree(data) {
  const result = [];
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        menuId: data.menuId,
        name: data.name,
        nodeTitle: data.nodeTitle,
        parentId: data.parentId,
        children: [],
        childNodeTitle: data.childNodeTitle,
        // dom类型
        backgroundColor: data.backgroundColor,
        spanColor: data.spanColor,
        textAlign: data.textAlign,
        type: data.type,
        // 元素类型
        title: data.title,
        value: data.value,
        required: data.required,
        // 元素属性
        propType: data.propType,
        disabled: data.disabled,
        trigger: data.trigger,
        readonly: data.readonly,
        message: data.message,
        field: data.field,
        size: data.size,
        clearable: data.clearable,
        // 配置项
        options: data.options,
      });
      const child = data.children;
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i]);
        }
      }
    };
    loop(item);
  });
  return result;
}
/** roleKey */
export function getRoleKey(param, roles) {
  const roleKeyArr = roles.filter((item) => {
    return param === item.remark;
  });
  if (roleKeyArr.length === 0) {
    return "null";
  } else {
    return roleKeyArr[0].roleKey;
  }
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
  const date = new Date(time * 1000);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "" + m : m;
  let d = date.getDate();
  d = d < 10 ? "" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //组合
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "" + m : m;
  let d = date.getDate();
  d = d < 10 ? "" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //组合
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 13位时间戳转换，返回当前时间给后端
 * @param time
 * @returns {string}
 */
export function returnBitTimestamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second; //组合
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 13位时间戳转换，返回当前时间给后端
 * @param time
 * @returns {string}
 */
export function returnMinutetamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute; //组合
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 13位时间戳转换，返回当天最后时间给后端
 * @param time
 * @returns {string}
 */
export function returnStarttimestamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + "00" + ":" + "00" + ":" + "00"; //组合
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 13位时间戳转换，返回当天最后时间给后端
 * @param time
 * @returns {string}
 */
export function returnDeadtimestamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + "23" + ":" + "59" + ":" + "59"; //组合
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 8位时间戳转换，返回当前时间给后端
 * @author Kyle
 * @param time
 * @returns {string}
 */
export function eighthBitTimestamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  return y + "-" + m + "-" + d; //组合
}

// 时分日期格式处理
export function eighthBitTime(row, column) {
  var date = row[column.property];
  if (date === undefined || date == null) {
    return "-";
  }
  return eighthBitTimestamp(Date.parse(date));
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
  const num = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  let str = "";
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length));
  }
  return str;
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n);
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function() {
  return function(element, event, handler, useCapture = false) {
    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture);
    }
  };
})();

/**
 * @copyright CBI cbi@chinacbi.com
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function() {
  return function(element, event, handler, useCapture = false) {
    if (element && event) {
      element.removeEventListener(event, handler, useCapture);
    }
  };
})();

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @description 新增批量下载
 * @author Kyle
 * @param {string} url
 */
export function downloadFile(obj) {
  let objName = obj.name;
  if (obj.name.trim() != "" && obj.name.indexOf(".") <= -1) {
    var urlArr = obj.path.split("/");
    var extName = urlArr[urlArr.length - 1].split(".")[1];
    objName = `${obj.name}.${extName}`;
  }
  var url = `/site/downOss?path=${obj.path}&name=${objName}`;
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  // 防止影响页面
  iframe.style.height = 0;
  // 防止影响页面
  iframe.src = url;
  document.body.appendChild(iframe);
  // 这一行必须，iframe挂在到dom树上才会发请求
  // 5分钟之后删除（onload方法对于下载链接不起作用）
  setTimeout(() => {
    iframe.remove();
  }, 10 * 60 * 1000);
}

/**
 * @extends { downloadFile } 继承上述downloadFile
 * @description 用于拍摄图片特殊下载处理（需带有相册名称）
 * @export
 * @param {*} obj 图片对象
 * @param {*} groupName 相册名称
 */
export function downloadPhoto(obj, groupName) {
  let objName = obj.name;
  if (obj.name.trim() != "" && obj.name.indexOf(".") <= -1) {
    var urlArr = obj.path.split("/");
    var extName = urlArr[urlArr.length - 1].split(".")[1];
    objName = `${obj.name}.${extName}`;
  }
  var url = `/site/downOss?path=${obj.path}&name=${groupName}-${objName}`;
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  // 防止影响页面
  iframe.style.height = 0;
  // 防止影响页面
  iframe.src = url;
  document.body.appendChild(iframe);
  // 这一行必须，iframe挂在到dom树上才会发请求
  // 5分钟之后删除（onload方法对于下载链接不起作用）
  setTimeout(() => {
    iframe.remove();
  }, 10 * 60 * 1000);
}

/**
 * @description 下载报告
 * @author Kyle
 * @param {string} url
 */
export function downloadReport(url) {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  // 防止影响页面
  iframe.style.height = 0;
  // 防止影响页面
  iframe.src = url;
  document.body.appendChild(iframe);
  // 这一行必须，iframe挂在到dom树上才会发请求
  // 5分钟之后删除（onload方法对于下载链接不起作用）
  setTimeout(() => {
    iframe.remove();
  }, 10 * 60 * 1000);
}

/**
 * @description 下载OSS
 * @author Kyle
 * @param {string} url
 */
export function downloadFile2(url, filename) {
  /**
   * 下载文件
   * 原理：通过获取文件内容转译为二进制，传给创建a标签下载
   * @param url  文件地址url
   * @param filename 下载保存的名字
   */
  var downloadFileBya = function(fileName, content) {
    var aLink = document.createElement("a");
    var blob = new Blob([content]);
    var evt = document.createEvent("MouseEvents");
    evt.initEvent("click", true, true);
    if (fileName) {
      aLink.download = fileName;
    }
    aLink.target = "_blank";
    aLink.href = URL.createObjectURL(blob);
    aLink.dispatchEvent(evt);
  };
  // 拿文件的名字
  var fileNameFromHeader = function(disposition) {
    if (disposition) {
      let index = disposition.lastIndexOf(`\/`);
      return decodeURIComponent(
        disposition.substring(index + 1, disposition.length)
      );
    }
    return "undefine_file";
  };

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.responseType = "blob";
  xhr.open("GET", url, true);
  xhr.onload = function() {
    if (this.status == 200) {
      // var blob = this.response;
      var donwloadName =
        filename != undefined ? filename : fileNameFromHeader(xhr.responseURL);
      downloadFileBya(donwloadName, xhr.response);
    } else {
      this.$message.error(`请求下载文件错误，请求错误码： ${this.status}`);
      return false;
    }
  };
  xhr.send();
}

export function dateFormatHM(row, column) {
  let date = row[column.property];
  if (date === undefined || date == null) {
    return "-";
  }
  return date.length > 16 ? date.substring(0, date.length - 3) : date;
}

/**
 * @description OSS文件验证
 * @author Burton
 * @param {*} ossObj OSS实例
 * @param {string} name OSS文件路径,不含bucket
 * @param {*} options 其他参数
 */
export function isExistOSS(ossObj, name, options = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      await ossObj.head(name, options);
      resolve();
    } catch (error) {
      if (error.code === "NoSuchKey") {
        reject(error);
      }
    }
  });
}

/**
 * @export
 * @description 文件命名非法字符验证
 * @author Kyle
 * @param {String} fileName 文件名称 string
 * @return {true}
 * @return {false}
 */
export function fileFormatValid(fileName) {
  let filenameReg = new RegExp(/[`~·~{}.\/;\\'\\[\]\\\\/:*?\"<>|#^%……&+]/im);
  // 判断是否满足非法文件命名格式，如果满足则返回 false;
  return filenameReg.test(fileName) ? false : true;
}

export function exportExcelFile(exportData, listName) {
  let link = document.createElement("a");
  //  type就是blob的type,是MIME类型的，可以自己查看MIME类型都有哪些
  let blog = new Blob([exportData], {
    type: "application/vnd.ms-excel;charset=utf-8",
  });
  let objectUrl = window.URL.createObjectURL(blog); //创建一个新的url对象
  link.href = objectUrl;
  let file_name = listName + "列表.xlsx";
  link.download = file_name; //  下载的时候自定义的文件名
  link.click();
  window.URL.revokeObjectURL(objectUrl); //为了更好地性能和内存使用状况，应该在适当的时候释放url.
}


export function getProjectSource(code) {
  if (code) {
    const options = [
      {
        name: "香港业务流程",
        key: "process_hk",
      },
      {
        name: "本地业务流程",
        key: "process_nd",
      },
      {
        name: "BR业务流程",
        key: "process_br",
      },
    ];
    const option = options.filter(item => item.key === code)
    if (option.length) {
      return option[0].name
    }
  } else {
    return ''
  }
}


/**
 * [download 压缩包单个下载或者批量下载]
 *  @param {[string | null]}
 *  @res {any}
 */
export const download = (url) => {
  if (url) {
    const elink = document.createElement("a");
    // 设置下载文件名
    // elink.download = 'param';
    elink.style.display = "none";
    elink.href = url;
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
  } else {
    return;
  }
};

export const apiParams = (body = {}) => {
  return {
    body: body,
    header: {
      currentTime: new Date().getTime(),
      requestId: uuid(),
      sourceType: "site",
    },
  }

}