<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-table ref="tableSort" v-loading="loading" :data="list" border>
          <el-table-column label="职位">
            <template slot-scope="scope">
              {{ scope.row.jobTitle || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="姓名">
            <template slot-scope="scope">
              {{ scope.row.name || "-" }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :current-page="queryForm.pageNum"
            :page-size="queryForm.pageSize"
            :total="total"
            :background="background"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getMainPerson } from "@/api/workplace/companyInfo";

  export default {
    name: "ChangeRecord",

    data() {
      return {
        list: [],
        loading: false,
        total: 0,
        background: true,
        layout: "total, sizes, prev, pager, next, jumper",
        queryForm: {
          body: {
            keyword: "",
            seqId: "",
            skip: 0,
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          pageNum: 1,
          pageSize: 20,
        },
        obj: null,
      };
    },

    methods: {
      getMainPerson(param) {
        this.loading = true;
        this.obj = param;
        this.queryForm.body.keyword = param.keyword;
        this.queryForm.body.seqId = param.seqId;
        this.queryForm.body.skip = (this.queryForm.pageNum - 1) * 20;
        getMainPerson(this.queryForm)
          .then((response) => {
            if (response.header.retCode === "1") {
              this.list = response.body.items;
              this.total = response.body.total;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.getMainPerson(this.obj);
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.getMainPerson(this.obj);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .info-table {
    border-collapse: collapse;
    font-size: 14px;
    color: #333;

    tr td {
      border: 1px solid #ccc;
      padding: 10px;

      &:nth-of-type(even) {
        width: 35%;
        font-weight: normal;
      }

      &:nth-of-type(odd) {
        width: 15%;
        background: #f5f7fa;
      }
    }
  }
</style>
