// Kyle API 预约工作台 -> 工单预约 -> 待处理
import request from "@/utils/request";

// 获取预约工作台 待处理列表
export function getTodoList(data) {
  return request({
    url: "/workOrderBook/queryBookPendingPage",
    method: "post",
    data,
  });
}
// 获取预约工作台 已处理列表
export function getDoneList(data) {
  return request({
    url: "/workOrderBook/queryBookProcessedPage",
    method: "post",
    data,
  });
}

// 预约工作台编辑
export function edit(data) {
  return request({
    url: "/workOrderBook/edit",
    method: "post",
    data,
  });
}
// 预约工作台批量提交
export function batchSubmit(data) {
  return request({
    url: "/workOrderBook/batchCommit",
    method: "post",
    data,
  });
}
// 预约工作台批量退回
export function withdraw(data) {
  return request({
    url: "/workOrderBook/batchFallback",
    method: "post",
    data,
  });
}
// 预约工作台批量放弃
export function quit(data) {
  return request({
    url: "/workOrderBook/batchAbandon",
    method: "post",
    data,
  });
}

//  查询内审人员
export function getQuitReason(data) {
  return request({
    url: "/queryCommentTemplateContent",
    method: "post",
    data,
  });
}
//  已处理 撤回操作
export function doneWithdraw(data) {
  return request({
    url: "/workOrderBook/revoke",
    method: "post",
    data,
  });
}
// 调查工作台获取详情
export function getOrderDetail(data) {
  return request({
    url: "/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}

// 预约工作台工单编辑详情回显
export function editDetail(data) {
  return request({
    url: "/workOrderBook/queryEditInfo",
    method: "post",
    data,
  });
}

// 校验调查时间是否冲突
export function validateSurveyTimeConflict(data) {
  return request({
    url: "/workOrderBook/validateSurveyTimeConflict",
    method: "post",
    data,
  });
}
