<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="标题" prop="content">
        <el-input v-model="form.content" placeholder="请输入标题"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { updateField } from "@/api/project/setting";
  import { mapGetters } from "vuex";

  export default {
    name: "Edit",
    data() {
      return {
        loading: false,
        form: {
          seqId: "",
          content: "",
        },
        queryForm: {
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          body: {},
        },
        rules: {
          content: [{ required: true, trigger: "blur", message: "请输入标题" }],
        },
        title: "",
        dialogFormVisible: false,
        param: {
          body: {
            content: null,
            seqId: null,
            userNo: null,
            userRoleNo: null,
          },
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {},
    mounted() {
      this.param.body.userNo = this.userNo;
    },
    methods: {
      showEdit(row) {
        this.title = "编辑字段";
        this.form = Object.assign({}, row);
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            this.param.body.content = this.form.content;
            this.param.body.seqId = this.form.seqId;
            this.param.body.userNo = this.userNo;
            this.param.body.userRoleNo = "commonRole";

            const { header } = await updateField(this.param);
            if (header.retCode === "1") {
              this.$baseMessage(header.retMessage, "success");
              this.$emit("fetchData");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }

            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
