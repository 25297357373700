<!--Kyle PL工作台 -> 工单管理 -> 待处理-->
<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>
          <el-form ref="queryForm"
                   :model="queryForm"
                   class="demo-form-inline my-form-class"
                   style="margin-top: 15px">
            <el-row :gutter="20">
              <!-- 下单日期范围  -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item>
                  <el-date-picker v-model="reset.orderScopeDate"
                                  type="daterange"
                                  unlink-panels
                                  clearable
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 工单编号 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number="queryForm.workOrderNo"
                            placeholder="工单编号"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            placeholder="目标主体"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               clearable
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <transition name="fade">
              <el-row v-show="isShow"
                      transiton="fade"
                      :gutter="20">
                <!-- 内部截止 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="innerDeadline">
                    <el-date-picker v-model="reset.innerDeadline"
                                    type="daterange"
                                    unlink-panels
                                    clearable
                                    range-separator="至"
                                    start-placeholder="内部截止开始日期"
                                    end-placeholder="内部截止结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 是否普通退回 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="normalFallbackFlag">
                    <el-select v-model="queryForm.normalFallbackFlag"
                               placeholder="是否普通退回"
                               :clearable="true"
                               filterable>
                      <el-option v-for="item in isGiveBacks"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 到期时间 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reachTime">
                    <el-select v-model="queryForm.reachTime"
                               placeholder="到期时间"
                               :clearable="true"
                               filterable>
                      <el-option v-for="item in reachTimeOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 选择地区 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="address">
                    <el-cascader v-model="queryForm.address"
                                 :options="cityInfo"
                                 :filterable="true"
                                 :clearable="true"
                                 separator="/"
                                 placeholder="地区"
                                 :props="{ checkStrictly: true }"
                                 @change="handleBlockChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 选择地区 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="address">
                    <el-cascader v-model="queryForm.address"
                                 :options="cityInfo"
                                 :filterable="true"
                                 :clearable="true"
                                 separator="/"
                                 placeholder="地区"
                                 :props="{ checkStrictly: true }"
                                 @change="handleBlockChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 创建日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="createTimeTmp">
                    <el-date-picker v-model="reset.createTimeTmp"
                                    type="daterange"
                                    unlink-panels
                                    clearable
                                    range-separator="至"
                                    start-placeholder="创建开始日期"
                                    end-placeholder="创建结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 订单编号 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="brWorkNo">
                    <el-input v-model.trim="queryForm.brWorkNo"
                              placeholder="订单编号"
                              clearable
                              @keyup.enter.native="handleQuery"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 报告类型 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reportType">
                    <el-select v-model="queryForm.reportType"
                               placeholder="报告类型"
                               clearable
                               filterable>
                      <el-option v-for="item in reportType"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 项目来源 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="projectSource">
                    <el-select v-model="queryForm.projectSource"
                               placeholder="请选择项目来源"
                               clearable
                               filterable>
                      <el-option v-for="item in resourceOptions"
                                 :key="item.key"
                                 :label="item.name"
                                 :value="item.key"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </transition>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="goCreateOrder">
            创建工单
          </el-button>
          <el-button type="primary"
                     icon="el-icon-download"
                     @click="batchImport">
            导入
          </el-button>
          <el-button icon="el-icon-finished"
                     type="primary"
                     @click="batchCommit">
            批量提交
          </el-button>
          <el-button icon="el-icon-paperclip"
                     type="primary"
                     @click="commitAnnex">
            批量上传附件
          </el-button>
          <!-- <el-button icon="el-icon-back" type="warning" @click="batchReturn">
            批量退回
          </el-button> -->
          <el-button icon="el-icon-delete"
                     type="danger"
                     @click="handleDelete">
            批量删除
          </el-button>
          <el-button :loading="downloadLoading"
                     type="primary"
                     @click="handleDownload">
            导出
          </el-button>

          <el-table v-loading="listLoading"
                    style="margin-top: 15px"
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    :row-class-name="tableRowClassName"
                    border
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"
                             align="center"></el-table-column>
            <el-table-column label="工单编号"
                             prop="workOrderNo"
                             align="center"
                             width="130">
              <template slot-scope="scope">
                <div class="img-icon">
                  <img v-if="scope.row.operStatus === 31"
                       src="@/icon/back.svg"
                       alt="退"
                       class="my-back-img" />
                  <img v-if="scope.row.brChangeFlag"
                       src="@/icon/edit.svg"
                       alt="修"
                       class="my-edit-img" />
                </div>
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="maturityDate"
                             label="到期时间(天)"
                             width="70"
                             align="center"></el-table-column>
            <el-table-column prop="orderTime"
                             label="下单日期"
                             :formatter="dateFormat"
                             width="120"
                             align="center"
                             sortable></el-table-column>
            <!-- 内部截止日期 -->
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             :formatter="dateFormat"
                             width="130"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.innerDeadline || "-" }}
              </template>
            </el-table-column>
            <!-- 创建日期 -->
            <el-table-column prop="createTime"
                             label="创建日期"
                             :formatter="dateFormat"
                             width="120"
                             align="center"
                             sortable></el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             label="环节/整体滞留(天)"
                             width="90"
                             fit="true">
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             fixed="right"
                             fit
                             align="center"
                             width="140px">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="提交"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-top"
                             circle
                             @click="handleCommit(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="编辑"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-edit-outline"
                             circle
                             @click="handleEdit(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip v-if="true"
                            class="item"
                            effect="dark"
                            content="删除"
                            placement="top-start">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             circle
                             @click="handleDelete(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip v-else
                            class="item"
                            effect="dark"
                            content="退回"
                            placement="top-start">
                  <el-button size="mini"
                             icon="el-icon-back"
                             type="warning"
                             circle
                             @click="batchReturn(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!-- 批量上传附件对话框 -->
    <el-dialog title="上传附件"
               :visible.sync="openCommitAnnex"
               width="573px"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :before-close="handleClose">
      <el-steps :active="2"
                style="margin: 30px -40%"
                align-center>
        <el-step title="上传工单"
                 icon="el-icon-success"></el-step>
        <el-step title="上传附件">
          <vab-remix-icon icon-class="number-2" />
        </el-step>
      </el-steps>
      <el-card class="box-card">
        <el-row align="middle"
                type="flex"
                :gutter="40">
          <el-col :span="24">
            <div style="font-weight: 600; font-size: 15px; margin-bottom: 10px">
              请您上传资源
            </div>
            <div>
              <el-upload ref="upload"
                         class="upload-demo"
                         :action="endpoinxBak"
                         :on-error="handleError"
                         :on-remove="handleRemove"
                         :on-change="handleFileChange"
                         :http-request="batch"
                         :auto-upload="false"
                         multiple
                         :file-list="fileList"
                         drag>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
                <div slot="tip"
                     class="el-upload__tip">附件大小不得大于 1G</div>
              </el-upload>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button ref="$complete"
                   :loading="subLoading"
                   type="primary"
                   :disabled="submitDisabled"
                   @click="attachmentSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 创建工单 -->
    <create ref="create"
            @fetchData="fetchData"
            @refreshData="refreshData"></create>
    <!-- 合规工单 -->
    <localCreate ref="localCreate"></localCreate>
    <!-- 批量导入 -->
    <importTemplate ref="importTemplate"
                    @fetchData="fetchData"></importTemplate>
    <!--提交工单-->
    <submit ref="submit"
            @refreshData="refreshData"></submit>
    <!--退囘工單-->
    <withdraw ref="withdraw"></withdraw>

    <!--编辑工单-->
    <edit ref="edit"
          @refreshData="refreshData"></edit>
  </div>
</template>

<script>
const OSS = require("ali-oss");
const attachmentPath = "cbi-site-front/workplace/attachment/";
import axios from "axios";
// 列表页查询接口
import { getList, doDelete } from "@/api/workplace/pl/plOrderTodo";
// 获取工具API
import {
  getBlock,
  getProjectSource,
  // 退回普通原因
  getGeneralReason,
  getReportTypeList,
} from "@/api/workplace/tools";
import edit from "./edit";
// 上传附件接口
import { uploadAttachment } from "@/api/workplace/importAnnex";
import {
  returnBitTimestamp,
  eighthBitTimestamp,
  returnDeadtimestamp,
  getRoleKey,
  /** 文件名称格式校验 */
  fileFormatValid,
  apiParams,
} from "@/utils/index";
/* 附件名称唯一性校验 */
import { checkAttachNameList } from "@/api/workplace/workDetail";
import { mapGetters } from "vuex";
import create from "../../components/create";
import localCreate from "../../components/localCreate";
import importTemplate from "../../components/importTemplate";
import submit from "./submit";
import withdraw from "./withdraw";
export default {
  name: "PLTodo",
  components: {
    submit,
    withdraw,
    create,
    localCreate,
    importTemplate,
    edit,
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      /**添加文件任务数组 */
      fileArr: [],
      /**添加异步任务数组 */
      promiseArr: [],
      subLoading: false,
      isShow: false,
      // 列表数据
      list: [],
      total: 0,
      listLoading: true,
      // 提交按钮禁用标识
      submitDisabled: false,
      layout: "total, sizes, prev, pager, next, jumper",
      background: true,
      selectRows: "",
      elementLoadingText: "正在加载...",
      // 查询列表
      queryForm: {
        auditUser: "",
        bookUser: "",
        projectId: "",
        innerDeadlineEnd: "",
        innerDeadlineStart: "",
        /** 创建日期 */
        createTimeStart: "",
        createTimeEnd: "",
        address: "",
        monitorUser: "",
        nextUserNo: "",
        nextUserRoleNo: "",
        operType: "",
        orderTimeEnd: "",
        orderTimeStart: "",
        pageNum: 1,
        pageSize: 10,
        reachTime: "",
        // 退回原因
        rejectReasonId: "",
        targetSubject: "",
        normalFallbackFlag: "",
        surveyUser: "",
        normalFallbackReasonId: "",
        workOrderNo: "",
      },
      queryPortForm: {
        body: {
          userNo: "",
          userRoleNo: "",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      // 查询编辑接口
      editPortForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      // 处理级联及日期范围控件表单重置
      reset: {
        block: "",
        orderScopeDate: [],
        innerDeadline: [],
        createTimeTmp: [],
      },
      uploadHeader: {
        "Content-Type": "multipart/form-data",
      },
      // 选择到期时间
      reachTimeOptions: [
        {
          value: 0.5,
          label: "0.5天",
        },
        {
          value: 1,
          label: "1天",
        },
        {
          value: 2,
          label: "2天",
        },
        {
          value: 3,
          label: "3天",
        },
        {
          value: 5,
          label: "5天",
        },
        {
          value: 7,
          label: "7天",
        },
        {
          value: 10,
          label: "10天",
        },
        {
          value: 15,
          label: "15天",
        },
        {
          value: 20,
          label: "20天",
        },
        {
          value: 30,
          label: "30天",
        },
      ],
      // 批量上传附件
      openCommitAnnex: false,
      // 上传附件请求
      uploadAttachmentForm: {
        body: {
          files: [],
          workOrderNos: [],
          userNo: "",
          userRoleNo: "",
        },
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      /** 检验名称批量接口 */
      checkNameListForm: {
        body: {
          files: [],
          workOrderNos: [],
          userNo: "",
          userRoleNo: "",
        },
        header: {
          currentTime: "",
          requestId: "",
          sourceType: "site",
        },
      },
      // 删除请求
      deleteForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
      fileList: [],
      fileNameList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      // 是否退回
      isGiveBacks: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      generalBackInfo: [],
      // 退回原因form
      generalBackForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      projectInfo: [], // 项目
      projectForm: {
        body: {
          source: "",
        },
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      cityInfo: [],
      cityForm: {
        body: {},
        header: {
          currentTime: "",
          requestId: "123",
          sourceType: "site",
        },
      },
      downloadLoading: false,
      reportType: [], // 报告类型
      // 项目来源
      resourceOptions: [
        {
          name: "香港业务流程",
          key: "process_hk",
        },
        {
          name: "本地业务流程",
          key: "process_nd",
        },
        {
          name: "BR业务流程",
          key: "process_br",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userNo: "user/userNo",
      roles: "user/roles",
      accessKeyId: "user/accessKeyId",
      accessKeySecret: "user/accessKeySecret",
      bucketName: "user/bucketName",
      endpoint: "user/endpoint",
      endpoinxBak: "user/endpoinxBak",
      OSSClient: "user/OSSClient",
      region: "user/region",
      policy: "user/policy",
      signature: "user/signature",
    }),
  },
  created() {
    this.queryPortForm.body.userNo = this.userNo;
    this.queryPortForm.body.userRoleNo = getRoleKey("PL", this.roles);
    this.uploadAttachmentForm.body.userNo = this.userNo;
    this.uploadAttachmentForm.body.userRoleNo = getRoleKey("PL", this.roles);
  },
  methods: {
    /** 数据初始化 */
    handleInitData() {
      // 列表查询
      this.fetchData();
      // 获取项目
      this.getProject();
      // 获取地区
      this.getAddress();
      // 获取报告类型
      this.getReportType();
    },
    /** 导出Excel */
    handleDownload() {
      if (this.selectRows.length > 0) {
        this.downloadLoading = true;
        import("@/vendor/ExportExcel").then((excel) => {
          const tHeader = [
            "工单编号",
            "订单编号",
            "目标主体",
            "目标地址",
            "项目",
            "报告类型",
            "二级类型",
            "到期时间(天)",
            "下单日期",
            "内部截止日期",
            "创建日期",
            "环节/整体滞留(天)",
          ];
          const filterVal = [
            "workOrderNo",
            "brWorkNo",
            "targetSubject",
            "address",
            "projectName",
            "reportType",
            "subReportType",
            "maturityDate",
            "orderTime",
            "innerDeadline",
            "createTime",
            "stepAndStranded",
          ];
          const list = this.selectRows;
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: "工单列表",
            autoWidth: true,
            bookType: "xlsx",
          });
          this.downloadLoading = false;
        });
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },
    /** 组装导出数据 */
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (!v[j]) {
            return "—";
          }
          if ("orderTime" === j && v["orderTime"]) {
            //格式化下单日期
            return eighthBitTimestamp(Date.parse(v["orderTime"]));
          }
          //格式化创建 日期
          if ("createTime" === j && v["createTime"]) {
            return eighthBitTimestamp(Date.parse(v["createTime"]));
          }
          return v[j];
        })
      );
    },
    // 8位下单日期格式处理
    dateFormat(row, column) {
      var date = row[column.property];
      if (date === undefined) {
        return "-";
      }
      return eighthBitTimestamp(Date.parse(date));
    },
    // 选择行数
    setSelectRows(val) {
      this.selectRows = val;
    },
    // 高级筛选重置
    resetForm(formName) {
      if (this.$refs[formName] !== undefined) {
        // this.$refs[formName].resetFields();
        this.queryForm = this.$options.data().queryForm;
        this.reset = this.$options.data().reset;
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    heightQuery() {
      this.isShow = !this.isShow;
      if (this.isShow) {
        this.getGeneralReasonOption();
      }
    },
    handleQuery() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },
    // 刷新数据
    refreshData() {
      /* 添加tab标签  传参 */
      let refresh_data = {
        todo_total: this.total,
        isDone: true,
      };
      this.$emit("child-todo", refresh_data);
      this.fetchData();
    },
    // 创建工单
    goCreateOrder() {
      let configInfo = {
        step: "PL工作台-工单管理-待处理",
        // operAction: "工单创建",
        console_info: "PL",
        /** 优化接口 */
        // 地区信息
        blockInfo: this.cityInfo,
        // 项目信息
        projectInfo: this.projectInfo,
      };
      let flag = "create";
      this.$refs["create"].showCreate(configInfo, flag);
    },
    handleEdit(row) {
      let configInfo = {
        step: "PL工作台-工单管理-待处理",
        // operAction: "工单派单",
        console_info: "PL",
        /** 优化接口 */
        // 地区信息
        blockInfo: this.cityInfo,
        // 项目信息
        projectInfo: this.projectInfo,
        /** 工单号 */
        workOrderNo: row.workOrderNo,
      };
      let flag = "edit";
      row.userNo = this.queryForm.userNo;
      row.userRoleNo = this.queryForm.userRoleNo;
      this.$refs["edit"].showEdit(row, configInfo, flag);
    },

    // 批量导入
    batchImport() {
      this.$refs["importTemplate"].showImport();
    },

    // 行内提交
    handleCommit(row) {
      if (!row.address || !row.addressCode || !row.innerDeadline) {
        this.$message({
          type: "error",
          message: "该工单必填项尚未完成，请填写后提交",
        });
        return;
      }
      let row_info = [];
      row_info.push(row);
      this.$refs["submit"].showCommit(row_info, row.bookUser);
    },
    // 批量提交
    batchCommit() {
      if (this.selectRows.length > 0) {
        let _arr = [];
        let valid = [];
        this.selectRows.map((item) => {
          if (!item.address || !item.addressCode || !item.innerDeadline) {
            valid.push(item.workOrderNo);
          }
          if (_arr.indexOf(item.bookUser) === -1) {
            _arr.push(item.bookUser);
          }
        });
        if (!valid.length) {
          if (_arr.length !== 1) {
            this.$baseConfirm(
              "当前选择工单预约人员不一致，确定继续提交吗",
              null,
              async () => {
                this.$refs["submit"].showCommit(this.selectRows, null);
              }
            );
          } else {
            this.$refs["submit"].showCommit(this.selectRows, _arr.join());
          }
        } else {
          this.$message({
            type: "error",
            message:
              "工单(" + valid.join(",") + ")必填项尚未完成，请填写后提交",
          });
          return;
        }
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },

    // 批量退回
    batchReturn(row) {
      if (row.id) {
        this.$refs["withdraw"].showWithdraw(row);
      } else {
        if (this.selectRows.length > 0) {
          this.$refs["withdraw"].showWithdraw(row);
        } else {
          this.$baseMessage("未选中任何行", "error");
          return false;
        }
      }
    },

    // 批量删除 及 单行删除
    handleDelete(row) {
      if (row.workOrderNo || this.selectRows.length > 0) {
        this.$baseConfirm("你确定要删除当前项吗", null, async () => {
          // 接口所需其他参数对象
          const extra_obj = {
            console: "PL",
            userNo: this.userNo,
            userRoleNo: getRoleKey("PL", this.roles),
          };
          // 获取系统当前时间
          this.deleteForm.header.currentTime = returnBitTimestamp(new Date());
          // 单行删除
          if (row.workOrderNo) {
            let detele_info = [];
            detele_info.push(Object.assign(extra_obj, row));
            this.deleteForm.body = detele_info;
            doDelete(this.deleteForm).then((response) => {
              if (response.header.retCode === "1") {
                this.$message({
                  message: `${response.header.retMessage}`,
                  type: "success",
                });
                this.fetchData();
              } else if (response.header.retCode === "0") {
                this.$message({
                  dangerouslyUseHTMLString: true,
                  showClose: true,
                  duration: 10000,
                  message: `<br>以下工单无法删除:</br>
                            ${
                              response.body.inProcess == null ||
                              response.body.inProcess.length === 0
                                ? ""
                                : `<br>在流程中无法删除的工单: ${response.body.inProcess.join()};</br>`
                            }
                            ${
                              response.body.fromCompliance == null ||
                              response.body.fromCompliance.length === 0
                                ? ""
                                : `<br>来自合规系统无法删除的工单：${response.body.fromCompliance.join()};</br>`
                            }
                            ${
                              response.body.deleted == null ||
                              response.body.deleted.length === 0
                                ? ""
                                : `<br>已经被删除无法删除的工单: ${response.body.deleted.join()};</br>`
                            }
                              `,
                  type: "warning",
                });
                this.fetchData();
              } else {
                this.$message.error(
                  `${response.header.retMessage}, 异常删除失败，请刷新页面后重试`
                );
              }
            });
          } else {
            // 多行删除
            this.deleteForm.body = this.selectRows.map((item) => {
              return Object.assign(item, extra_obj);
            });
            doDelete(this.deleteForm).then((response) => {
              if (response.header.retCode === "1") {
                this.$message({
                  message: `${response.header.retMessage}`,
                  type: "success",
                });
                this.fetchData();
              } else if (response.header.retCode === "0") {
                this.$message({
                  dangerouslyUseHTMLString: true,
                  showClose: true,
                  duration: 10000,
                  message: `<br>以下工单无法删除:</br>
                            ${
                              response.body.inProcess.length === 0
                                ? ""
                                : `<br>在流程中无法删除的工单: ${response.body.inProcess.join()};</br>`
                            }
                            ${
                              response.body.fromCompliance.length === 0
                                ? ""
                                : `<br>来自合规系统无法删除的工单：${response.body.fromCompliance.join()};</br>`
                            }
                            ${
                              response.body.deleted.length === 0
                                ? ""
                                : `<br>已经被删除无法删除的工单: ${response.body.deleted.join()};</br>`
                            }
                              `,
                  type: "warning",
                });
                this.fetchData();
              } else {
                this.$message.error(
                  `${response.header.retMessage}, 异常删除失败，请刷新页面后重试`
                );
              }
            });
          }
        });
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },

    handleFileChange(file, filelist) {
      let exitedObj = {};
      /*** 判断文件大小 */
      if (filelist.length > 0) {
        for (let item of filelist) {
          const expSize = item.size / 1024 / 1024 < 1024;
          if (!expSize) {
            this.$message.error(`文件大小不能超过 1G, 请检查`);
            this.submitDisabled = true;
            return;
          }
        }
      }
      // 判断文件命名非法字符校验
      if (filelist.length > 0) {
        for (let item of filelist) {
          const filename = item.name.substring(0, item.name.lastIndexOf("."));
          if (!fileFormatValid(filename)) {
            this.$message.error("文件名称中不能包含非法特殊字符, 请检查");
            this.submitDisabled = true;
            return;
          }
        }
      }
      /** 判断文件同名 */
      if (filelist.length > 1) {
        for (let i in filelist) {
          if (exitedObj[filelist[i].name]) {
            this.$message.error("请勿上传同名文件！");
            this.submitDisabled = true;
            return;
          }
          exitedObj[filelist[i].name] = true;
        }
      }
      this.submitDisabled = false;
    },
    /**
     * @description [fnUploadRequest 覆盖默认的上传行为，实现自定义上传]
     * @author   shanshuizinong
     * @param    {object}   option [上传选项]
     * @param index
     * @return   {null}   [没有返回]
     */
    async fnUploadRequest(option, index) {
      return new Promise((resolve, reject) => {
        /** 带有时间戳的文件名 */
        const fileName =
          new Date().getTime() +
          index +
          "." +
          option.file.name.match(/^(.*)(\.)(.{1,8})$/)[3];
        // 获取OSS配置信息
        const uploadConfig = new FormData();
        uploadConfig.append("key", attachmentPath + fileName);
        uploadConfig.append("OSSAccessKeyId", this.accessKeyId);
        uploadConfig.append("policy", this.policy);
        uploadConfig.append("Signature", this.signature);
        uploadConfig.append("success_action_status", 200);
        uploadConfig.append("name", option.file.name);
        /** 表单域 file 必须为最后一个 */
        uploadConfig.append("file", option.file);
        axios
          .post(this.endpoinxBak, uploadConfig, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status === 200) {
              //上传成功 上传的路径就是要使用的路径
              this.uploadAttachmentForm.body.files.push({
                url: attachmentPath + fileName,
                name: option.file.name,
              });
              this.fileNameList.push(option.file.name);
            }
            resolve(res);
          })
          .catch((error) => {
            const index = this.indexOf(
              option.file.name,
              this.uploadAttachmentForm.body.files
            );
            this.uploadAttachmentForm.body.files.splice(index, 1);
            this.$message.error(`${option.file.name}上传错误,请重新上传`);
            reject(error);
          });
      });
    },
    /** 自定义上传方法，使其多次添加资源 */
    batch(option) {
      this.fileArr.push(option);
    },
    /*** 匹配返回数组索引 */
    indexOf(val, arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].name === val) {
          return i;
        }
      }
      return -1;
    },
    /*** 删除附件 */
    async handleRemove(file, filelist) {
      // 重复文件计数
      let count = 0;
      let exitedObj = {};
      if (filelist.length > 1) {
        for (let i in filelist) {
          if (exitedObj[filelist[i].name]) {
            this.$message.error(`请勿上传同名文件！`);
            count++;
            break;
          }
          exitedObj[filelist[i].name] = true;
        }
      }
      /*** 判断文件大小 */
      if (filelist.length > 0) {
        for (let item of filelist) {
          const expSize = item.size / 1024 / 1024 < 1024;
          if (!expSize) {
            this.$message.error(`文件大小不能超过 1G, 请检查`);
            count++;
            break;
          }
        }
      }
      // 判断文件命名非法字符校验
      if (filelist.length > 0) {
        for (let item of filelist) {
          const filename = item.name.substring(0, item.name.lastIndexOf("."));
          if (!fileFormatValid(filename)) {
            this.$message.error("文件名称中不能包含非法特殊字符, 请检查");
            count++;
            break;
          }
        }
      }
      this.submitDisabled = count >= 1;
      if (this.fileNameList.indexOf(file.name) > -1) {
        var index = this.indexOf(
          file.name,
          this.uploadAttachmentForm.body.files
        );
        if (index > -1) {
          this.uploadAttachmentForm.body.files.splice(index, 1);
        }
      }
    },

    /** 上传失败的回调 */
    handleError(err) {
      this.$message.error(`上传错误,错误信息 ${err}`);
    },
    // 通过oss的数据发送给后端
    /**
     * @method: 自定义上传方法 本方法包括：elment组件上传(实为添加文件);验证文件名称唯一性;上传oss;将结果回调site后端;
     * @description: 调用执行完element内部的文件上传生命周期，其次执行自定义上传
     */
    async attachmentSubmit() {
      this.subLoading = true;
      await this.$refs.upload.submit();
      /* 置空 */
      this.checkNameListForm.body.files = [];
      // 判空
      if (this.fileArr.length === 0) {
        this.subLoading = false;
        this.$message({
          message: "请添加需要上传的文件",
          type: "warning",
        });
        return;
      }
      /**批量检验名称  */
      this.fileArr.map((el) => {
        this.checkNameListForm.body.files.push({
          name: el.file.name.substring(0, el.file.name.lastIndexOf(".")),
        });
      });
      this.checkNameListForm.body.userNo = this.userNo;
      this.checkNameListForm.body.userRoleNo = getRoleKey("PL", this.roles);
      const { header, body } = await checkAttachNameList(
        this.checkNameListForm
      );
      if (header.retCode === "1") {
        this.submitDisabled = false;
      } else {
        this.fileArr = [];
        this.$message({
          duration: 10000,
          showClose: true,
          message: `${header.retMessage}`,
          type: "error",
        });
        this.subLoading = false;
        return;
      }
      this.promiseArr = this.fileArr.map((el, index) => {
        return this.fnUploadRequest(el, index);
      });
      Promise.all(this.promiseArr)
        .then(() => {
          this.promiseArr = [];
          this.fileArr = [];
          // 获取系统当前时间
          this.uploadAttachmentForm.header.currentTime = returnBitTimestamp(
            new Date()
          );
          if (this.uploadAttachmentForm.body.files.length === 0) {
            this.subLoading = false;
            this.$message({
              message: "请添加需要上传的文件",
              type: "warning",
            });
            return;
          }
          uploadAttachment(this.uploadAttachmentForm).then((response) => {
            this.uploadAttachmentForm.body.files = [];
            if (response.header.retCode === "1") {
              this.$message({
                message: `${response.header.retMessage}`,
                type: "success",
              });
              this.handleClose();
            } else {
              this.$message.error(
                `错误信息: ${response.header.retMessage},附件上传失败，请重新上传！`
              );
            }
            this.subLoading = false;
          });
        })
        .catch((error) => {
          this.promiseArr = [];
          this.fileArr = [];
          this.uploadAttachmentForm.body.files = [];
          this.subLoading = false;
          reject(error);
        });
    },
    // 批量上传附件对话框
    commitAnnex() {
      if (this.selectRows.length > 0) {
        this.uploadAttachmentForm.body.workOrderNos = this.selectRows.map(
          (item) => item.workOrderNo
        );
        this.checkNameListForm.body.workOrderNos = this.selectRows.map(
          (item) => item.workOrderNo
        );
        this.openCommitAnnex = true;
      } else {
        this.$baseMessage("未选中任何行", "error");
        return false;
      }
    },
    // 批量上传窗口关闭
    handleClose() {
      this.fileList = [];
      this.uploadAttachmentForm.body.files = [];
      this.fileNameList = [];
      this.openCommitAnnex = false;
      this.submitDisabled = false;
    },
    // 获取地区
    getAddress() {
      this.cityForm.header.currentTime = returnBitTimestamp(new Date());
      getBlock(this.cityForm).then((response) => {
        if (response.header.retCode === "1") {
          let block_info = [];
          block_info.push(response.body[0]);
          this.cityInfo = block_info;
        } else {
          this.$message.error(
            `${response.header.retMessage},获取地区失败，请重新刷新页面`
          );
        }
      });
    },
    // 选择地区
    handleBlockChange(value) {
      this.queryForm.address = value.join(",");
    },
    // 获取项目
    getProject() {
      this.projectForm.header.currentTime = returnBitTimestamp(new Date());
      getProjectSource(this.projectForm).then((response) => {
        if ("1" === response.header.retCode) {
          this.projectInfo = response.body;
        }
      });
    },
    // 选择项目
    handleProjectChange(value) {
      if (value) {
        this.queryForm.projectId = value[1];
      }
    },
    // 获取退回原因配置项
    getGeneralReasonOption() {
      this.generalBackForm.header.currentTime = returnBitTimestamp(new Date());
      getGeneralReason(this.generalBackForm).then((response) => {
        if (response.header.retCode === "1") {
          this.generalBackInfo = response.body;
        } else {
          this.$message.error(
            `错误信息: ${response.header.retMessage},请重新刷新页面，获取退回原因选择信息`
          );
        }
      });
    },

    // 获取报告分类
    getReportType() {
      getReportTypeList(apiParams()).then((response) => {
        if (response.header.retCode === "1") {
          this.reportType = response.body;
        }
      });
    },
    // 获取todo列表页数据
    fetchData() {
      this.listLoading = true;
      // 当前系统时间
      this.queryPortForm.header.currentTime = returnBitTimestamp(new Date());
      // 下单日期范围
      if (this.reset.orderScopeDate == null) {
        this.queryForm.orderTimeStart = "";
        this.queryForm.orderTimeEnd = "";
      } else if (this.reset.orderScopeDate.length !== 0) {
        this.queryForm.orderTimeStart =
          this.reset.orderScopeDate.length === 2
            ? returnBitTimestamp(this.reset.orderScopeDate[0])
            : "";
        this.queryForm.orderTimeEnd =
          this.reset.orderScopeDate.length === 2
            ? returnDeadtimestamp(this.reset.orderScopeDate[1])
            : "";
      }
      // 创建日期范围
      if (this.reset.createTimeTmp == null) {
        this.queryForm.createTimeStart = "";
        this.queryForm.createTimeEnd = "";
      } else if (this.reset.createTimeTmp.length !== 0) {
        this.queryForm.createTimeStart =
          this.reset.createTimeTmp.length === 2
            ? returnBitTimestamp(this.reset.createTimeTmp[0])
            : "";
        this.queryForm.createTimeEnd =
          this.reset.createTimeTmp.length === 2
            ? returnDeadtimestamp(this.reset.createTimeTmp[1])
            : "";
      }
      // 内部截止日期范围
      if (this.reset.innerDeadline == null) {
        this.queryForm.innerDeadlineStart = "";
        this.queryForm.innerDeadlineEnd = "";
      } else if (this.reset.innerDeadline.length !== 0) {
        this.queryForm.innerDeadlineStart =
          this.reset.innerDeadline.length === 2
            ? returnBitTimestamp(this.reset.innerDeadline[0])
            : "";
        this.queryForm.innerDeadlineEnd =
          this.reset.innerDeadline.length === 2
            ? returnDeadtimestamp(this.reset.innerDeadline[1])
            : "";
      }
      Object.assign(this.queryPortForm.body, this.queryForm);
      // 查询pl工作台订单list
      getList(this.queryPortForm)
        .then((response) => {
          if (response.header.retCode === "1") {
            this.list = response.body.records;
            this.total = response.body.total;
            let refresh_data = {
              todo_total: response.body.total,
              isDone: false,
            };
            this.$emit("child-todo", refresh_data);
          }
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 表格显色
    tableRowClassName({ row }) {
      // 到期时间小于3，行内高亮
      if (row.maturityDate <= 3) {
        return "warning-row";
      } else return "";
    },
  },
};
</script>
<style scoped>
.el-date-editor .el-range-separator {
  font-weight: 100;
}
.el-row {
  justify-content: flex-start;
}
.el-col {
  margin-bottom: 10px;
}
.el-col :last-child {
  margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}

.el-select,
.el-cascader {
  width: 100%;
}
.el-table /deep/.warning-row {
  background: #f8b7bc;
}
.el-table /deep/.warning-row:hover {
  background: #f8b7bc;
}

.el-table /deep/.change-row {
  background: #ffff9f;
}
.el-icon-upload {
  display: flex;
  justify-content: center;
  font-size: 70px;
  color: #ccc;
}
.my-form-class {
  margin-bottom: -10px !important;
}
.my-back-img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 2px;
}
</style>
<style>
.el-upload-dragger {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 490px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
</style>
