<!--Kyle 首页 -->
<template>
  <div class="index-container">
    <el-row :gutter="20">
      <!-- 三期 -->
      <!-- <el-col :span="24">
        <el-card shadow="never" body-style="display:flex;align">
          <vab-icon :icon="['fas', 'volume-down']"></vab-icon>
          <el-link :underline="false" style="">
            <span>{{ headerTips }}</span>
          </el-link>
        </el-card>
      </el-col> -->
      <el-col :span="24">
        <el-card shadow="never">
          <div slot="header" class="clearfix">
            <span class="card-title">待处理事务</span>
          </div>
          <el-row :gutter="2">
            <div v-for="(item, index) in todoArr" :key="index">
              <el-col :span="2">
                <el-link
                  :underline="false"
                  @click="handleTrigger(item.workOderStatus)"
                >
                  {{ item.workOderStatusValue }}
                </el-link>
                <span class="tips">{{ item.total }}</span>
              </el-col>
            </div>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="24">
        <el-card shadow="never" class="box-card card-top-tools">
          <el-row :gutter="30">
            <vab-query-form-left-panel :span="3">
              <span class="card-title">工单统计</span>
            </vab-query-form-left-panel>
            <vab-query-form-right-panel :span="21">
              <div style="text-align: right">
                <div style="display: inline-block">
                  <el-radio-group
                    v-model="tabPosition"
                    style="margin-right: 10px"
                    @change="handleChart"
                  >
                    <el-radio-button label="day">本日</el-radio-button>
                    <el-radio-button label="week">本周</el-radio-button>
                    <el-radio-button label="month">本月</el-radio-button>
                  </el-radio-group>
                </div>
                <div style="display: inline-block; width: 300px">
                  <el-date-picker
                    v-model="listQuery.correctDate"
                    align="right"
                    type="daterange"
                    unlink-panels
                    clearable
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    @change="dateChange"
                  ></el-date-picker>
                </div>
              </div>
            </vab-query-form-right-panel>
          </el-row>
          <el-row align="middle" type="flex" style="margin-top: 20px">
            <!-- tooltip -->
            <el-col :span="3">
              <el-col>
                <div class="time-record">
                  <p>本月工单总数</p>
                  <h3>{{ monthTotal }}</h3>
                  <div v-if="contrastMonth !== '上月没有工单'">
                    <!-- 同比下降 -->
                    <div v-if="contrastMonth[0] === '-'">
                      <vab-icon :icon="['fas', 'caret-down']"></vab-icon>
                      <a class="order-down">{{ contrastMonth.substring(1) }}</a>
                      <span>&nbsp;同比上月</span>
                    </div>
                    <!-- 同比增加 -->
                    <div v-else>
                      <vab-icon :icon="['fas', 'caret-up']"></vab-icon>
                      <a class="order-up">{{ contrastMonth }}</a>
                      <span>&nbsp;同比上月</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>&nbsp;上月没有工单</span>
                  </div>
                </div>
              </el-col>
              <el-col>
                <div class="time-record">
                  <p>本周工单总数</p>
                  <h3>{{ weekTotal }}</h3>
                  <div v-if="contrastWeek !== '上周没有工单'">
                    <!-- 同比下降 -->
                    <div v-if="contrastWeek[0] === '-'">
                      <vab-icon :icon="['fas', 'caret-down']"></vab-icon>
                      <a class="order-down">{{ contrastWeek.substring(1) }}</a>
                      <span>&nbsp;同比上周</span>
                    </div>
                    <!-- 同比增加 -->
                    <div v-else>
                      <vab-icon :icon="['fas', 'caret-up']"></vab-icon>
                      <a class="order-up">{{ contrastWeek }}</a>
                      <span>&nbsp;同比上周</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>&nbsp;上周没有工单</span>
                  </div>
                </div>
              </el-col>
            </el-col>
            <!-- chart -->
            <el-col :span="21"><div id="container" /></el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { StackedArea } from "@antv/g2plot";
  // 转化当前时间格式
  import {
    returnBitTimestamp,
    returnStarttimestamp,
    returnDeadtimestamp,
  } from "@/utils/index";
  import {
    todoSearch,
    searchTotal,
    searchStatistic,
  } from "@/api/workplace/tools";
  import { mapGetters } from "vuex";
  let chart = null;
  export default {
    name: "Index",
    data() {
      return {
        param: {
          body: {
            userNo: "",
            roleKey: [],
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        totalParam: {
          body: {
            endTime: "",
            startTime: "",
            userNo: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        statisticParam: {
          body: {
            endTime: "",
            startTime: "",
            userNo: "",
          },
          header: {
            currentTime: "",
            requestId: "123",
            sourceType: "site",
          },
        },
        todoArr: [],
        pickerOptions: {
          shortcuts: [
            {
              text: "最近一周",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近三个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              },
            },
          ],
        },
        chartData: [],
        tabPosition: "day",
        // 头部通知信息
        headerTips:
          "重要通知，系统于今日15:30~16:00更新，更新时间范围内请暂停使用",
        listQuery: {
          correctDate: [],
          limit: 20,
          sort: "+id",
          startTime: "",
        },
        /* 本周总数 */
        weekTotal: "",
        /* 本月总数 */
        monthTotal: "",
        /** 同比上月 */
        contrastMonth: "",
        /** 同比上周 */
        contrastWeek: "",
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.statisticParam.body.userNo = this.userNo;
      this.todoEvent();
      this.sysTotal();
    },
    mounted() {
      // this.sysStatistic();
      this.$nextTick(() => {
        this.handleChart();
        this.initChart();
      });
    },
    methods: {
      /** 待处理事务 */
      async todoEvent() {
        this.param.body.roleKey = this.roles.map((item) => {
          return item.roleKey;
        });
        this.param.body.userNo = this.userNo;
        const { body, header } = await todoSearch(this.param);
        if (header.retCode === "1") {
          this.todoArr = body;
        } else {
          this.$baseMessage(`${header.retMessage}`, "error");
        }
      },
      /** 工单统计总数 */
      async sysTotal() {
        this.totalParam.body.userNo = this.userNo;
        // this.statisticParam.body.userNo = "367";
        const { body, header } = await searchTotal(this.totalParam);
        if (header.retCode === "1") {
          this.weekTotal = body.weekTotal;
          this.monthTotal = body.monthTotal;
          this.contrastMonth = body.contrastMonth;
          this.contrastWeek = body.contrastWeek;
        } else {
          this.$baseMessage(`${header.retMessage}`, "error");
        }
      },
      /** 统计图表change tool-tips */
      handleChart() {
        switch (this.tabPosition) {
          /** 当日 */
          case "day":
            this.statisticParam.body.startTime = returnStarttimestamp(
              new Date()
            );
            this.statisticParam.body.endTime = returnBitTimestamp(new Date());
            this.sysStatistic();
            break;
          /** 本周 */
          case "week":
            const end = new Date();
            const start = new Date(
              new Date() - (new Date().getDay() - 1) * 86400000
            );
            this.statisticParam.body.startTime = returnStarttimestamp(start);
            this.statisticParam.body.endTime = returnBitTimestamp(end);
            this.sysStatistic();
            break;
          /** 当月 */
          case "month":
            const nowdate = new Date();
            const monthFirstDay = new Date(
              nowdate.getFullYear(),
              nowdate.getMonth(),
              1
            );
            const monthNextFirstDay = new Date(
              nowdate.getFullYear(),
              nowdate.getMonth() + 1,
              1
            );
            const monthLastDay = new Date(monthNextFirstDay - 86400000);
            this.statisticParam.body.startTime = returnStarttimestamp(
              monthFirstDay
            );
            this.statisticParam.body.endTime = returnBitTimestamp(nowdate);
            this.sysStatistic();
            break;
          default:
            break;
        }
      },
      /** 统计数据 */
      async sysStatistic() {
        this.statisticParam.body.userNo = this.userNo;
        const { body, header } = await searchStatistic(this.statisticParam);
        if (header.retCode === "1") {
          let _arr = [];
          const accomplish = body.accomplish.map((item) => {
            return {
              orderStatus: "完成订单",
              time: item.time,
              count: item.count,
            };
          });
          const fallback = body.fallback.map((item) => {
            return {
              orderStatus: "被退回订单",
              time: item.time,
              count: item.count,
            };
          });
          this.chartData = accomplish.concat(fallback);
          /** 时间按升序排序 */
          this.chartData.sort((a, b) => {
            return b.time < a.time ? 1 : -1;
          });
          chart.changeData(this.chartData);
        } else {
          this.$baseMessage(`${header.retMessage}`, "error");
        }
      },
      dateChange(val) {
        if (val !== null) {
          this.statisticParam.body.startTime = returnStarttimestamp(val[0]);
          this.statisticParam.body.endTime = returnDeadtimestamp(val[1]);
          this.sysStatistic();
        }
      },
      /** 页面快捷跳转 */
      handleTrigger(target) {
        switch (target) {
          // 待派单
          case 1:
            this.$router.push({ path: "workplace/pl/orderManage" });
            break;
          // 待预约
          case 2:
            this.$router.push({ path: "workplace/order/book" });
            break;
          // 待推荐
          case 3:
            this.$router.push({ path: "workplace/supervision/recommend" });
            break;
          // 待调查
          case 4:
            this.$router.push({ path: "workplace/investigate/orderEntry" });
            break;
          // 调查中
          case 5:
            this.$router.push({ path: "workplace/investigate/orderEntry" });
            break;
          // 待补充
          case 6:
            this.$router.push({ path: "workplace/supervision/orderRepair" });
            break;
          // 待初审
          case 7:
            this.$router.push({ path: "workplace/audit/first" });
            break;
          // 待二审
          case 8:
            this.$router.push({ path: "workplace/user/orderSecondReview" });
            break;
          // 兼职待预约
          case 11:
            this.$router.push({ path: "workplace/supervision/recommend" });
            break;
          default:
            break;
        }
      },
      /** 实例图表 */
      initChart() {
        chart = new StackedArea(document.getElementById("container"), {
          data: this.chartData,
          meta: {
            time: {
              alias: "时间",
            },
            count: {
              alias: "数量",
            },
          },
          forceFit: true,
          // 是否使用曲线形态描绘
          smooth: true,
          xField: "time",
          yField: "count",
          stackField: "orderStatus",
          color: ["#8599c1", "#6079af"],
          // 填充点
          point: {
            visible: true,
            shape: "circle",
            size: 3,
            style: {
              stroke: "#fff",
            },
          },
          xAxis: {
            type: "dateTime",
            tickCount: 12,
          },
          legend: {
            visible: true,
            position: "right-top",
          },
          responsive: true,
        });
        chart.render();
        // areaPlot.changeData(this.chartData);
      },
    },
  };
</script>
<style scoped>
  .el-card /deep/.el-card__header {
    padding: 6px 20px;
    background-color: #f3f3f3;
  }
</style>
<style lang="scss" scoped>
  .index-container {
    padding: 0 !important;
    margin: 0 !important;
    background: #f0f2f5;

    ::v-deep {
      .el-alert {
        padding: $base-padding;

        &--info.is-light {
          min-height: 82px;
          padding: $base-padding;
          margin-bottom: 15px;
          color: #909399;
          background-color: $base-color-white;
          border: 1px solid #ebeef5;
        }
      }

      .el-card__body {
        .echarts {
          width: 100%;
          height: 140px;
        }
      }
    }

    .card {
      min-height: 420px;

      ::v-deep {
        .el-card__body {
          .echarts {
            width: 100%;
            height: 305px;
          }
        }
      }
    }

    .bottom {
      height: 40px;
      padding-top: 20px;
      margin-top: 5px;
      color: #595959;
      text-align: left;
      border-top: 1px solid $base-border-color;
    }

    .table {
      width: 100%;
      color: #666;
      border-collapse: collapse;
      background-color: #fff;

      td {
        position: relative;
        min-height: 20px;
        padding: 9px 15px;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid #e6e6e6;

        &:nth-child(odd) {
          width: 20%;
          text-align: right;
          background-color: #f7f7f7;
        }
      }
    }

    .icon-panel {
      height: 100px;
      text-align: center;
      cursor: pointer;

      svg {
        font-size: 40px;
      }

      p {
        margin-top: 10px;
      }
    }

    .bottom-btn {
      margin-top: 5px;

      button {
        margin: 5px 10px 5px 0;
      }
    }
  }

  .el-col.el-col-8 {
    .el-date-editor {
      width: 220px !important;
    }
  }
  .tips {
    margin-left: 10px;
    color: orangered;
  }

  .time-record {
    // margin-bottom: 10px;
    margin-top: 20px;
    font-family: "微软雅黑";

    h3 {
      font-family: "微软雅黑 Regular", "微软雅黑";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      color: #666;
      text-align: left;
    }

    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #989898;
    }

    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #ccc;
      text-align: left;
    }
  }

  .card-title {
    font: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: #666;
    text-align: left;
  }
  // 首页通知按钮
  .fa-volume-down {
    color: orangered;
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .order-up {
    color: #00b468;
  }

  .order-down {
    color: red;
  }

  .fa-caret-up {
    color: #00b468;
  }

  .fa-caret-down {
    color: red;
  }
</style>
