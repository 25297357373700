<template>
  <div v-if="orderProcess !== 32 && orderProcess !== {}">
    <div class="title">工单进度</div>
    <el-steps
      :active="orderProcess.currentIndex"
      process-status="success"
      align-center
    >
      <el-step
        v-for="(item, index) in orderProcess.scheduleAndTime"
        :key="index"
        :title="item.schedule"
        :description="dateFormat(item.time)"
      ></el-step>
    </el-steps>
  </div>
</template>

<script>
  import { returnBitTimestamp } from "@/utils";
  import { getProcess } from "@/api/workplace/pl/plDetail";

  export default {
    name: "OrderProcess",
    props: {
      // eslint-disable-next-line vue/require-default-prop
      param: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: {},
      },
    },
    data() {
      return {
        // 工单进度
        orderProcess: {},
      };
    },
    created() {
      this.getProcessList();
    },
    methods: {
      // 8位下单日期格式处理
      dateFormat(time) {
        if (time === undefined) {
          return "";
        }
        return returnBitTimestamp(Date.parse(time));
      },
      /**  工单进度  */
      getProcessList() {
        getProcess(this.param).then((response) => {
          if (response.header.retCode === "1") {
            this.orderProcess = response.body;
          } else {
            this.$message.error(
              `${response.header.retMessage},获取工单进度异常失败，请重新刷新页面`
            );
          }
        });
      },
    },
  };
</script>

<style scoped></style>
