import request from "@/utils/request";

// 登录接口 Auth
export async function login(params) {
  return request({
    url: "/cbi-auth-provider/oauth/token",
    method: "post",
    params,
  });
}
// 用户信息
export function getInfo(systemId) {
  return request({
    url: "/cbi-auth-provider/getInfo",
    method: "get",
    params: {
      systemId,
    },
  });
}

// 更新用户信息
export function updateUserInfo(data) {
  return request({
    url: "/userinfo/updateUserInfo",
    method: "post",
    data,
  });
}

export function logout(query) {
  return request({
    url: "/cbi-auth-provider/revokeToken",
    method: "get",
    params: query,
  });
}
export function revokeTokenBatch(query) {
  return request({
    url: "/cbi-auth-provider/revokeTokenBatch",
    method: "get",
    params: query,
  });
}
export function getUser(data) {
  return request({
    url: "/userinfo/querySysUser",
    method: "post",
    data,
  });
}
export function submit(data) {
  return request({
    url: "/userinfo/updateUserInfo",
    method: "post",
    data,
  });
}
export function update(data) {
  return request({
    url: "/userinfo/updatePassword",
    method: "post",
    data,
  });
}
