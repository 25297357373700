<!-- kyle 答复 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="50%"
    @close="close"
  >
    <div class="htmlContent" v-html="content"></div>
    <div slot="footer" class="dialog-footer">
      <el-button type="warning" @click="handleReject">驳回</el-button>
      <el-button type="primary" @click="agree">同意</el-button>
    </div>
    <reject ref="reject" @close="close" @refreshData="refreshData"></reject>
    <review ref="review" @close="close" @refreshData="refreshData"></review>
  </el-dialog>
</template>

<script>
  import { queryContent } from "@/api/workplace/internalAudit/internalAudit";
  import reject from "./reject";
  import review from "./review";
  export default {
    name: "Submit",
    components: {
      reject,
      review,
    },
    html: "",
    data() {
      return {
        value: "",
        form: {},
        content: "",
        /**contentForm 请求富文本 */
        contentForm: {
          body: {
            commentId: "",
            joinCommentType: "",
          },
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "",
          },
        },
        userNo: "",
        userRoleNo: "",
        title: "",
        dialogFormVisible: false,
      };
    },
    created() {},
    methods: {
      handleShowHtml(html) {
        this.html = html;
      },
      refreshData() {
        this.$emit("refreshData");
        this.dialogFormVisible = false;
      },
      /** 打开答复对话框 */
      async showReply(row, userNo, userRoleNo) {
        this.title = "质疑原因内容";
        this.userNo = userNo;
        this.userRoleNo = userRoleNo;
        this.form = Object.assign({}, row);
        [
          this.contentForm.body.commentId,
          this.contentForm.body.joinCommentType,
        ] = [this.form.commentId, this.form.joinCommentType];
        /**获取富文本 */
        const { header, body } = await queryContent(this.contentForm);
        if (header.retCode === "1") {
          this.content = body.content;
        } else {
          this.$message.error(`${header.retMessage},获取内容失败，请重新操作`);
        }
        this.dialogFormVisible = true;
      },
      handleReject() {
        this.$refs["reject"].showReject(
          this.form,
          this.userNo,
          this.userRoleNo
        );
      },
      quillEnable(event) {},
      close() {
        this.dialogFormVisible = false;
      },
      agree() {
        this.$refs["review"].showReview(this.form, "reply");
        this.dialogFormVisible = false;
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
  .replyForm /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
  .htmlContent {
    max-height: 400px;
    overflow: auto;
  }
  .htmlContent >>> img {
    display: block;
    max-width: 100%;
    max-height: 300px;
  }
</style>

<style lang="scss">
  .vab-quill-content {
    ::v-deep {
      .el-form-item__content {
        line-height: normal;
      }
    }
  }
</style>
