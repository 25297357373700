var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tableSort",
                  attrs: { data: _vm.list, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      width: "80",
                      type: "index",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "发生时间", width: "120", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.time || "-") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "直接责任财务人员" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.financialPeople || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "直接责任中介机构" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.agencyPeople || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "案件性质" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.caseType || "-") + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "100px",
                      fixed: "right",
                      align: "center",
                      fit: "true",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "查看详情",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-document",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDetail(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.total > 0
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
                [
                  _c(
                    "el-card",
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.queryForm.pageNum,
                          "page-size": _vm.queryForm.pageSize,
                          total: _vm.total,
                          background: _vm.background,
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange,
                          "size-change": _vm.handleSizeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "重大税收违法信息详情",
            visible: _vm.flag,
            "close-on-click-modal": false,
            width: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.flag = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "table",
            {
              staticClass: "info-table",
              staticStyle: { width: "100%", height: "100%" },
              attrs: { cellspacing: "0" },
            },
            [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("主要违法事实")]),
                  _c("td", [_vm._v(_vm._s(_vm.report.truth || "-"))]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("相关法律依据及,税务处理处罚情况")]),
                  _c("td", [_vm._v(_vm._s(_vm.report.lawPunishment || "-"))]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }