<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否退回？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="退回类型" prop="type">
        <el-select
          v-model="form.type"
          placeholder="请选择退回类型"
          clearable
          @click.native="clearQuitReason"
          @change="changeValue"
        >
          <el-option
            v-for="item in backParamOptions"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="退回原因" prop="reason">
        <el-select v-model="form.reason" placeholder="请选择退回原因" clearable>
          <el-option
            v-for="item in backReasonParamOptions"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model.trim="form.note"
          type="textarea"
          autocomplete="off"
          placeholder="请输入小记"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { withdraw } from "@/api/workplace/supervison/recommend";
  import { getBackType, getGeneralReason } from "@/api/workplace/tools";
  import { getRoleKey } from "@/utils/index";
  import { mapGetters } from "vuex";

  export default {
    name: "TableEdit",
    data() {
      return {
        loading: false,
        form: {
          type: "",
          reason: "",
          note: "",
        },
        rules: {
          type: [
            { required: true, trigger: "blur", message: "请选择退回类型" },
          ],
          reason: [
            { required: true, trigger: "blur", message: "请选择退回原因" },
          ],
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,

        parameter: {
          body: [],
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        obj: {
          console: "supervisor", // 固定写法
          fallbackComment: "",
          fallbackCommentId: "",
          fallbackCommentType: "",
          nextTaskId: "",
          nextUserNo: "",
          nextUserRoleNo: "",
          note: "",
          operAction: "工单退回", // 固定写法
          procInsId: null,
          projectSource: "",
          remark: "",
          step: "督导工作台-人员推荐-待处理", // 固定写法
          taskId: null,
          userNo: "",
          userRoleNo: "",
          workOrderNo: "202008241111",
        },
        object: null,
        backParamOptions: [],
        // 退回类型参数
        backParam: {
          body: {
            templateType: 81,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        backReasonParamOptions: [],
        //  退回原因参数
        backReasonParam: {
          body: {
            templateTypeSeqId: "",
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.obj.userNo = this.userNo;
      this.obj.userRoleNo = getRoleKey("supervisor", this.roles);
    },
    methods: {
      async showWithdraw(row) {
        this.title = "退回工单";
        this.form = Object.assign({}, row[0]);
        this.dialogFormVisible = true;
        // 退回类型
        this.backParamOptions = Object.assign(
          {},
          (await getBackType(this.backParam)).body
        );
        this.parameter.body = [];
        this.object = row;
      },

      async changeValue(value) {
        this.backReasonParam.body.templateTypeSeqId = value;
        // 退回原因
        this.backReasonParamOptions = Object.assign(
          {},
          (await getGeneralReason(this.backReasonParam)).body
        );
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("refreshData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            for (let i = 0; i < this.object.length; i++) {
              this.obj.fallbackCommentType = this.form.type;
              this.obj.fallbackCommentId = this.form.reason; //this.form.type;
              this.obj.fallbackComment = null; //this.form.reason;
              this.obj.note = this.form.note;
              this.obj.procInsId = this.object[i].procInsId;
              this.obj.taskId = this.object[i].taskId;
              this.obj.projectSource = this.object[i].source;
              this.obj.workOrderNo = this.object[i].workOrderNo;
              this.obj.userNo = this.object[i].monitorUser;
              this.obj.bookUser = this.object[i].bookUser;
              this.parameter.body.push(Object.assign({}, this.obj));
            }
            const { header } = await withdraw(this.parameter);
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
            } else {
              this.$baseMessage(header.retMessage, "error");
            }

            this.$refs["form"].resetFields();
            this.dialogFormVisible = false;
            this.$emit("refreshData");
            this.form = this.$options.data().form;
            this.loading = false;
          } else {
            return false;
          }
        });
      },
      clearQuitReason() {
        if (this.form.reason !== undefined && this.form.reason !== null) {
          this.form.reason = null;
        }
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
