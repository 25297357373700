<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="字段类型" prop="type">
        <el-select
          v-model="form.type"
          placeholder="请选择字段类型"
          clearable
          filterable
        >
          <el-option
            v-for="item in fieldType"
            :key="item.seqId"
            :label="item.content"
            :value="item.seqId"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getFieldType, createField } from "@/api/project/setting";
  import { mapGetters } from "vuex";

  export default {
    name: "Add",
    data() {
      return {
        loading: false,
        form: {
          title: "",
          type: "",
        },
        queryForm: {
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          body: {},
        },

        rules: {
          title: [
            { required: true, trigger: "blur", message: "请输入项目名称" },
          ],
          type: [
            { required: true, trigger: "blur", message: "请选择项目类型" },
          ],
          resource: [
            { required: true, trigger: "blur", message: "请选择项目来源" },
          ],
        },
        title: "",
        dialogFormVisible: false,
        fieldType: [],
        param: {
          body: {
            content: "",
            templateType: 0,
            templateTypeSeqId: "",
            userNo: "",
            userRoleNo: "commonRole",
          },
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {},
    mounted() {
      this.param.body.userNo = this.userNo;
    },
    methods: {
      showAdd(row) {
        this.title = "新增字段";
        this.fieldType = row;
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("fetchData");
      },
      save() {
        let type = null;
        this.fieldType.forEach((field) => {
          if (field.seqId === this.form.type) {
            type = field.templateType;
          }
        }),
          this.$refs["form"].validate(async (valid) => {
            if (valid) {
              this.loading = true;
              this.param.body.content = this.form.title;
              this.param.body.templateType = type;
              this.param.body.templateTypeSeqId = this.form.type;
              this.param.body.userNo = this.userNo;
              this.param.body.userRoleNo = "commonRole";

              const { header } = await createField(this.param);
              if (header.retCode === "1") {
                this.$baseMessage(header.retMessage, "success");
                this.$emit("fetchData");
              } else {
                this.$baseMessage(header.retMessage, "error");
              }

              this.$refs["form"].resetFields();
              this.dialogFormVisible = false;
              this.form = this.$options.data().form;
              this.loading = false;
            } else {
              return false;
            }
          });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
