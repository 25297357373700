import Vue from "vue";
import App from "./App";
import store from "./store";
import router from "./router";
import "./plugins";
// 导入组件库
import VueDragFormdesign from "vue-drag-formdesign";
/**
 * @copyright CBI cbi@chinacbi.com
 * @description 生产环境与演示环境默认都使用mock，如果正式用于生产环境时，记得去掉
 */
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import formCreate, { maker } from "@form-create/element-ui";

Vue.use(Viewer);
Vue.use(VXETable);
// formCreate
Vue.use(formCreate);
// 注册组件库
Vue.use(VueDragFormdesign);
// 这里是插件的默认设置
Viewer.setDefaults({
  zIndexInline: 9999,
});

// if (
//   process.env.NODE_ENV === "preview" ||
//   process.env.NODE_ENV === "production"
// ) {
//   const { mockXHR } = require("@/config/static");
//   mockXHR();
// }

Vue.config.productionTip = false;

new Vue({
  el: "#cbi-site-front",
  router,
  store,
  render: (h) => h(App),
});
