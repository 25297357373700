var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "videoDialog",
      attrs: { visible: _vm.dialogvideoVisible, "append-to-body": "" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogvideoVisible = $event
        },
        close: _vm.closeView,
      },
    },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { height: "350px" },
          attrs: { "tab-position": _vm.tabPosition },
          on: { "tab-click": _vm.handleTabsClick },
          model: {
            value: _vm.activeTabsValue,
            callback: function ($$v) {
              _vm.activeTabsValue = $$v
            },
            expression: "activeTabsValue",
          },
        },
        _vm._l(_vm.videoData, function (videoItem, videoIndex) {
          return _c(
            "el-tab-pane",
            {
              key: videoIndex,
              attrs: {
                name: videoItem.name,
                stretch: true,
                label:
                  videoItem.name.length > 12
                    ? videoItem.name.substring(0, 12) + "..."
                    : videoItem.name,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: videoItem.name,
                                    placement: "top",
                                    effect: "light",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        width: "95%",
                                        margin: "0 auto",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            videoItem.name +
                                              "." +
                                              videoItem.path.substring(
                                                videoItem.path.lastIndexOf(
                                                  "."
                                                ) + 1
                                              )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.judgeFileType(videoItem.path)
                                ? _c("video", {
                                    ref: "myVideo",
                                    refInFor: true,
                                    staticStyle: {
                                      width: "95%",
                                      height: "300px",
                                    },
                                    attrs: {
                                      src: _vm.ossPath + videoItem.path,
                                      controls: "controls",
                                    },
                                  })
                                : _c("img", {
                                    staticStyle: {
                                      width: "160px",
                                      height: "160px",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      src: require("@/icon/luxiang.svg"),
                                      fit: _vm.fit,
                                      title: videoItem.name,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownload(videoItem)
                                      },
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }