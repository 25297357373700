var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "项目来源", prop: "projectSource" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择项目来源",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.form.projectSource,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "projectSource", $$v)
                    },
                    expression: "form.projectSource",
                  },
                },
                _vm._l(_vm.resourceOptions, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.name, value: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }