var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.form, rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "原密码", prop: "oldPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: "请输入原密码",
              autocomplete: "new-password",
              onfocus: "this.type='password'",
            },
            model: {
              value: _vm.form.oldPassword,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "oldPassword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.oldPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "新密码", prop: "newPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: "请输入新密码",
              "auto-complete": "new-password",
              onfocus: "this.type='password'",
            },
            model: {
              value: _vm.form.newPassword,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "newPassword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.newPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "确认密码", prop: "confirmPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: "请确认密码",
              "auto-complete": "new-password",
              onfocus: "this.type='password'",
            },
            model: {
              value: _vm.form.confirmPassword,
              callback: function ($$v) {
                _vm.$set(_vm.form, "confirmPassword", _vm._n($$v))
              },
              expression: "form.confirmPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.submit },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }