<template>
  <div class="cbi-site-front-wrapper" :class="classObj">
    <div
      v-if="'horizontal' === layout"
      class="layout-container-horizontal"
      :class="{
        fixed: header === 'fixed',
        'no-tags-bar': tagsBar === 'false' || tagsBar === false,
      }"
    >
      <div :class="header === 'fixed' ? 'fixed-header' : ''">
        <top-bar></top-bar>
        <div
          v-if="tagsBar === 'true' || tagsBar === true"
          :class="{ 'tag-view-show': tagsBar }"
        >
          <div class="vab-main">
            <tags-bar></tags-bar>
          </div>
        </div>
      </div>
      <div class="vab-main main-padding">
        <ad></ad>
        <app-main></app-main>
      </div>
    </div>
    <div
      v-else
      class="layout-container-vertical"
      :class="{
        fixed: header === 'fixed',
        'no-tags-bar': tagsBar === 'false' || tagsBar === false,
      }"
    >
      <div
        v-if="device === 'mobile' && collapse === false"
        class="mask"
        @click="handleFoldSideBar"
      />
      <div v-if="false" class="roleSwitch">
        <el-select
          v-model="roleVal"
          placeholder="角色选择"
          size="mini"
          filterable
          @change="handleRoleChange"
        >
          <el-option
            v-for="item in roleOption"
            :key="item.roleKey"
            :label="item.roleName"
            :value="item.roleId"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
      </div>
      <div v-if="organizeShow" class="organize">
        <el-button type="primary" @click="organize">组织架构</el-button>
      </div>
      <side-bar></side-bar>
      <div class="vab-main" :class="collapse ? 'is-collapse-main' : ''">
        <div :class="header === 'fixed' ? 'fixed-header' : ''">
          <nav-bar></nav-bar>
          <tags-bar v-if="tagsBar === 'true' || tagsBar === true" />
        </div>
        <ad></ad>
        <app-main></app-main>
      </div>
    </div>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
  import { Ad, AppMain, NavBar, SideBar, TagsBar, TopBar } from "./components";
  import { mapActions, mapGetters } from "vuex";
  import store from "@/store";
  import router from "@/router";
  import path from "path";
  import { tokenName } from "@/config/settings";
  export default {
    name: "Layout",
    components: {
      Ad,
      TopBar,
      NavBar,
      SideBar,
      AppMain,
      TagsBar,
    },
    data() {
      return {
        organizeShow: false,
        oldLayout: "",
        roleOption: [],
        roleVal: "",
      };
    },
    computed: {
      ...mapGetters({
        layout: "settings/layout",
        tagsBar: "settings/tagsBar",
        collapse: "settings/collapse",
        header: "settings/header",
        device: "settings/device",
        visitedRoutes: "tagsBar/visitedRoutes",
        routes: "routes/routes",
        roles: "user/roles",
      }),
      classObj() {
        return {
          mobile: this.device === "mobile",
        };
      },
    },
    beforeMount() {
      window.addEventListener("resize", this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize);
    },
    created() {
      this.routes.forEach((_item) => {
        if (_item.hidden === true && _item.meta !== undefined) {
          if (_item.meta.title === "组织架构") {
            this.organizeShow = true;
          }
        }
      });
    },
    mounted() {
      this.roleOption = this.roles;
      let roleId = sessionStorage.getItem("roleId");
      if (roleId) {
        this.roleVal = parseInt(roleId);
      } else {
        this.roleVal = this.roleOption[0].roleId;
      }
      this.oldLayout = this.layout;
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Juejin")) {
        this.$baseAlert(
          "cbi-site-front不支持在掘金内置浏览器演示，请手动复制以下地址到浏览器中查看http://mpfhrd48.sanxing.uz7.cn/cbi-site-front"
        );
      }
      const isMobile = this.handleIsMobile();
      if (isMobile) {
        if (isMobile) {
          //横向布局时如果是手机端访问那么改成纵向版
          this.$store.dispatch("settings/changeLayout", "vertical");
        } else {
          this.$store.dispatch("settings/changeLayout", this.oldLayout);
        }
        this.$store.dispatch("settings/toggleDevice", "mobile");
        setTimeout(() => {
          this.$store.dispatch("settings/foldSideBar");
        }, 2000);
      } else {
        this.$store.dispatch("settings/openSideBar");
      }
      this.$nextTick(() => {
        window.addEventListener(
          "storage",
          (e) => {
            if (e.key === tokenName || e.key === null) window.location.reload();
            if (e.key === tokenName && e.value === null)
              window.location.reload();
          },
          false
        );
      });
    },
    methods: {
      organize() {
        /** 28 */
        window.open(
          `${process.env.VUE_APP_AUTH_FRONT_BATH_URL}/doLogin/${store.getters["user/accessToken"]}`,
          "_blank"
        );
        // window.open(
        //   `http://192.168.4.205:81/doLogin/${store.getters["user/accessToken"]}`,
        //   "_blank"
        // );
      },
      async closeAllTags() {
        const view = await this.toThisTag();
        const { visitedRoutes } = await this.$store.dispatch(
          "tagsBar/delAllRoutes"
        );
        try {
          if (this.affixTags.some((tag) => tag.path === view.path)) {
            return;
          }
        } catch (err) {}
        this.toLastTag(visitedRoutes, view);
      },
      toLastTag(visitedRoutes, view) {
        const latestView = visitedRoutes.slice(-1)[0];
        this.$router.push("/");
        // if (latestView) {
        //   this.$router.push(latestView);
        // } else {
        //   this.$router.push("/");
        // }
      },
      async toThisTag() {
        const view = this.visitedRoutes.filter((item, index) => {
          if (item.path === this.$route.fullPath) {
            return item;
          }
        })[0];
        this.$router.push("/");
        // if (this.$route.path !== view.path) this.$router.push(view);
        return view;
      },
      handleRoleChange(roleId) {
        // 关闭所有页签
        try {
          this.closeAllTags();
        } catch (err) {
          console.log(err);
        }
        // 判断用户信息是否已获取完成，用户信息不存在时开始获取
        sessionStorage.setItem("roleId", roleId);
        store.dispatch("user/setRoleId", roleId);
        // 成功获取生成路由
        store.dispatch("routes/setRoute", { roleId }).then((accessRoutes) => {
          // 根据roles权限生成可访问的路由表
          router.addRoutes(accessRoutes); // 动态添加可访问路由表
          // next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
        });
      },
      ...mapActions({
        handleFoldSideBar: "settings/foldSideBar",
      }),
      handleIsMobile() {
        return document.body.getBoundingClientRect().width - 1 < 992;
      },
      handleResize() {
        if (!document.hidden) {
          const isMobile = this.handleIsMobile();
          if (isMobile) {
            //横向布局时如果是手机端访问那么改成纵向版
            this.$store.dispatch("settings/changeLayout", "vertical");
          } else {
            this.$store.dispatch("settings/changeLayout", this.oldLayout);
          }

          this.$store.dispatch(
            "settings/toggleDevice",
            isMobile ? "mobile" : "desktop"
          );
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .roleSwitch {
    position: fixed;
    width: 130px;
    top: 16px;
    right: 160px;
    z-index: 999;
    .el-select {
      width: 130px;
    }
  }
  .organize {
    position: fixed;
    top: 16px;
    right: 190px;
    z-index: 999;
    .el-button {
      width: 100px;
    }
  }
  @mixin fix-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $base-z-index - 2;
    width: 100%;
    overflow: hidden;
  }

  .cbi-site-front-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .layout-container-horizontal {
      position: relative;

      &.fixed {
        padding-top: calc(#{$base-top-bar-height} + #{$base-tags-bar-height});
      }

      &.fixed.no-tags-bar {
        padding-top: $base-top-bar-height;
      }

      ::v-deep {
        .vab-main {
          width: 88%;
          margin: auto;
        }

        .fixed-header {
          @include fix-header;
        }

        .tag-view-show {
          background: $base-color-white;
          box-shadow: $base-box-shadow;
        }

        .nav-bar-container {
          .fold-unfold {
            display: none;
          }
        }

        .main-padding {
          .app-main-container {
            margin-top: $base-padding;
            margin-bottom: $base-padding;
            background: $base-color-white;
          }
        }
      }
    }

    .layout-container-vertical {
      position: relative;

      .mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $base-z-index - 1;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background: #000;
        opacity: 0.5;
      }

      &.fixed {
        padding-top: calc(#{$base-nav-bar-height} + #{$base-tags-bar-height});
      }

      &.fixed.no-tags-bar {
        padding-top: $base-nav-bar-height;
      }

      .vab-main {
        position: relative;
        min-height: 100%;
        margin-left: $base-left-menu-width;
        background: #f6f8f9;
        transition: $base-transition;

        ::v-deep {
          .fixed-header {
            @include fix-header;

            left: $base-left-menu-width;
            width: $base-right-content-width;
            box-shadow: $base-box-shadow;
            transition: $base-transition;
          }

          .nav-bar-container {
            position: relative;
            box-sizing: border-box;
          }

          .tags-bar-container {
            box-sizing: border-box;
          }

          .app-main-container {
            width: calc(100% - #{$base-padding} - #{$base-padding});
            margin: $base-padding auto;
            background: $base-color-white;
            border-radius: $base-border-radius;
          }
        }

        &.is-collapse-main {
          margin-left: $base-left-menu-width-min;

          ::v-deep {
            .fixed-header {
              left: $base-left-menu-width-min;
              width: calc(100% - 65px);
            }
          }
        }
      }
    }

    /* 手机端开始 */
    &.mobile {
      ::v-deep {
        .el-pager,
        .el-pagination__jump {
          display: none;
        }

        .layout-container-vertical {
          .el-scrollbar.side-bar-container.is-collapse {
            width: 0;
          }

          .vab-main {
            width: 100%;
            margin-left: 0;
          }
        }

        .vab-main {
          .fixed-header {
            left: 0 !important;
            width: 100% !important;
          }
        }
      }
    }

    /* 手机端结束 */
  }
</style>
