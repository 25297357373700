// Kyle API 工单管理 -> 待处理
import request from "@/utils/request";

// 获取pl工作台 待处理列表
export function getList(data) {
  return request({
    url: "/plWorkOrder/queryPLPendingPage",
    method: "post",
    data,
  });
}

// pl工作台列表提交
export function doSubmit(data) {
  return request({
    url: "/plWorkOrder/commit",
    method: "post",
    data,
  });
}

// pl工作台列表删除
export function doDelete(data) {
  return request({
    url: "/plWorkOrder/delete",
    method: "post",
    data,
  });
}

export function doEdit(data) {
  return request({
    url: "/plOrderTodo/doEdit",
    method: "post",
    data,
  });
}

export function doReturn(data) {
  return request({
    url: "/plOrderTodo/doReturn",
    method: "post",
    data,
  });
}
