var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
        _vm._v("该工单是否退回？"),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "退回原因", prop: "fallbackCommentId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择退回原因",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.form.fallbackCommentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "fallbackCommentId", $$v)
                    },
                    expression: "form.fallbackCommentId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.seqId,
                    attrs: { label: item.content, value: item.seqId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "小记", prop: "note" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", autocomplete: "off" },
                model: {
                  value: _vm.form.note,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "note",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }