var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vlabeledit" },
    [
      !_vm.edit
        ? _c(
            "div",
            {
              staticClass: "vlabelLabel",
              on: {
                dblclick: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onLabelClick.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a",
                { staticClass: "mediaLabel", attrs: { title: _vm.label } },
                [_vm._v(_vm._s(_vm.vlabel))]
              ),
            ]
          )
        : _vm._e(),
      _vm.edit
        ? _c("el-input", {
            ref: "labeledit",
            staticClass: "vlabelInput",
            attrs: {
              type: "text",
              placeholder: _vm.vplaceholder,
              maxlength: "50",
            },
            on: { blur: _vm.updateTextBlur },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.updateTextEnter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.label,
              callback: function ($$v) {
                _vm.label = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "label",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }