<template>
  <table class="info-table" cellspacing="0" style="width: 100%; height: 100%">
    <thead></thead>
    <tbody v-loading="loading">
      <tr>
        <td>企业名称</td>
        <td>{{ companyBaseData.name || "-" }}</td>
        <td>组织机构代码</td>
        <td>{{ companyBaseData.org_no || "-" }}</td>
      </tr>
      <tr>
        <td>统一社会信用代码</td>
        <td>{{ companyBaseData.credit_no || "-" }}</td>
        <td>经营状态</td>
        <td>{{ companyBaseData.status || "-" }}</td>
      </tr>
      <tr>
        <td>企业注册号</td>
        <td>{{ companyBaseData.reg_no || "-" }}</td>
        <td>成立日期</td>
        <td>{{ companyBaseData.start_date || "-" }}</td>
      </tr>
      <tr>
        <td>营业开始日期</td>
        <td>{{ companyBaseData.term_start || "-" }}</td>
        <td>营业结束日期</td>
        <td>{{ companyBaseData.term_end || "-" }}</td>
      </tr>
      <tr>
        <td>公司类型</td>
        <td>{{ companyBaseData.econ_kind || "-" }}</td>
        <td>核准日期</td>
        <td>{{ companyBaseData.check_date || "-" }}</td>
      </tr>
      <tr>
        <td>法定代表人</td>
        <td>{{ companyBaseData.oper_name || "-" }}</td>
        <td>登记机关</td>
        <td>{{ companyBaseData.belong_org || "-" }}</td>
      </tr>
      <tr>
        <td>注册资本</td>
        <td>{{ companyBaseData.regist_capi || "-" }}</td>
        <td>注销日期</td>
        <td>{{ companyBaseData.end_date || "-" }}</td>
      </tr>
      <tr>
        <td>邮箱</td>
        <td>{{ companyBaseData.email || "-" }}</td>
        <td>电话</td>
        <td>{{ companyBaseData.phone || "-" }}</td>
      </tr>
      <tr>
        <td>网址</td>
        <td colspan="3">{{ companyBaseData.site || "-" }}</td>
      </tr>
      <tr>
        <td>企业地址</td>
        <td colspan="3">{{ companyBaseData.address || "-" }}</td>
      </tr>
      <tr>
        <td rowspan="3">经营范围</td>
        <td rowspan="3" colspan="3">{{ companyBaseData.scope || "-" }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { getCompanyDetailInfo } from "@/api/workplace/companyInfo";

  export default {
    name: "BaseInfo",

    data() {
      return {
        // loading
        loading: false,
        // 查询参数
        queryForm: {
          body: {
            keyword: "",
            seqId: "",
          },
          header: {
            currentTime: "",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          pageNum: 1,
          pageSize: 20,
        },
        // 企业基本信息数据
        companyBaseData: {},
      };
    },

    methods: {
      getBaseInfo(param) {
        this.loading = true;
        this.queryForm.body.keyword = param.keyword;
        this.queryForm.body.seqId = param.seqId;

        // this.queryBaseForm.body.keyword = "西安汇华企业征信管理有限公司";
        getCompanyDetailInfo(this.queryForm)
          .then((response) => {
            if (response.header.retCode === "1") {
              this.companyBaseData = response.body;
            } else {
              this.companyBaseData = {}
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .info-table {
    border-collapse: collapse;
    font-size: 14px;
    color: #333;

    tr td {
      border: 1px solid #ccc;
      padding: 10px;

      &:nth-of-type(even) {
        width: 35%;
        font-weight: normal;
      }

      &:nth-of-type(odd) {
        width: 15%;
        background: #f5f7fa;
      }
    }
  }
</style>
