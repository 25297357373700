<!-- kyle 驳回 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="500px"
    append-to-body
    @close="close"
  >
    <div style="margin-bottom: 20px">该工单是否确认驳回？</div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="用户" prop="pchUserName">
        <el-input
          v-model="form.pchUserName"
          type="text"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="小记" prop="note">
        <el-input
          v-model="form.note"
          type="textarea"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="rejectLoading" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { reject } from "@/api/workplace/internalAudit/internalAudit";
  import { returnBitTimestamp, returnDeadtimestamp, getRoleKey } from "@/utils";
  export default {
    name: "Submit",
    data() {
      return {
        rejectLoading: false,
        form: {
          pchUserName: "",
          note: "",
        },
        formPort: {
          body: {
            console: "verify_people",
            nextTaskId: "",
            note: "",
            operAction: "质疑驳回",
            step: "内审工作台-工单初审-质疑",
            procInsId: "",
            projectSource: "",
            remark: "",
            taskId: "",
            /** 需要驳回的用户 */
            pchUser: "",
            workOrderNo: "",
            userNo: "",
            userRoleNo: "",
          },
          header: {
            currentTime: "",
            requestId: "sad as",
            sourceType: "site",
          },
        },
        rules: {
          note: [
            { required: true, message: "请输入小记", trigger: "blur" },
            {
              max: 500,
              message: "小记不能超过500个汉字",
              trigger: "blur",
            },
          ],
        },
        title: "",
        dialogFormVisible: false,
      };
    },
    created() {},
    methods: {
      showReject(formData, userNo, userRoleNo) {
        this.rejectLoading = false;
        this.title = "驳回";
        this.form = Object.assign({}, formData);
        this.formPort.body.userNo = userNo;
        this.formPort.body.userRoleNo = userRoleNo;
        this.dialogFormVisible = true;
      },
      close() {
        this.$refs["form"].resetFields();
        this.form = this.$options.data().form;
        this.dialogFormVisible = false;
        // this.$emit("fetchData");
      },
      save() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.rejectLoading = true;
            this.formPort.header.currentTime = returnBitTimestamp(new Date());
            this.formPort.body.workOrderNo = this.form.workOrderNo;
            this.formPort.body.pchUser = this.form.pchUser;
            this.formPort.body.taskId = this.form.taskId;
            this.formPort.body.projectSource = this.form.source;
            this.formPort.body.procInsId = this.form.procInsId;
            this.formPort.body.nextTaskId = this.form.procInsId;
            this.formPort.body.note = this.form.note;

            const { header } = await reject(this.formPort);
            this.$baseMessage(header.retMessage, "success");
            this.$refs["form"].resetFields();
            /** 刷新待处理，已处理 */
            this.$emit("refreshData");
            this.dialogFormVisible = false;
            this.form = this.$options.data().form;
            // this.rejectLoading = false;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .el-select,
  .el-cascader {
    width: 100%;
  }
</style>
