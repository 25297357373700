// Kyle API 质控工作台 -> 工单列表
import request from "@/utils/request";

// 质控工作台查询工单列表
export function getOrderList(data) {
  return request({
    url: "/queryQualityControlList",
    method: "post",
    data,
  });
}
// 督导-人员推荐获取详情
export function getOrderDetail(data) {
  return request({
    url: "/plWorkOrder/queryDetails",
    method: "post",
    data,
  });
}
// 督导-工单补充-获取操作信息
export function getOperationInfo(data) {
  return request({
    url: "/log/query",
    method: "post",
    data,
  });
}
