var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-left",
                        type: "info",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("queryForm")
                        },
                      },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-sort",
                        plain: "",
                        "native-type": "submit",
                      },
                      on: { click: _vm.heightQuery },
                    },
                    [_vm._v(" 高级筛选 ")]
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticClass: "demo-form-inline my-form-class",
                      staticStyle: { "margin-top": "15px" },
                      attrs: { model: _vm.queryForm },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "daterange",
                                      "unlink-panels": "",
                                      clearable: "",
                                      "range-separator": "至",
                                      "start-placeholder": "下单开始日期",
                                      "end-placeholder": "下单结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.reset.orderScopeDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.reset,
                                          "orderScopeDate",
                                          $$v
                                        )
                                      },
                                      expression: "reset.orderScopeDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "workOrderNo" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "工单编号",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleQuery.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.queryForm.workOrderNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "workOrderNo",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "queryForm.workOrderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "targetSubject" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "目标主体",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleQuery.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.queryForm.targetSubject,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "targetSubject",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryForm.targetSubject",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "projectId" } },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      value: _vm.queryForm.projectId,
                                      options: _vm.projectInfo,
                                      clearable: "",
                                      filterable: true,
                                      separator: ":",
                                      placeholder: "项目",
                                    },
                                    on: { change: _vm.handleProjectChange },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _c(
                            "el-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isShow,
                                  expression: "isShow",
                                },
                              ],
                              attrs: { transiton: "fade", gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "innerDeadline" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "unlink-panels": "",
                                          clearable: "",
                                          "range-separator": "至",
                                          "start-placeholder":
                                            "内部截止开始日期",
                                          "end-placeholder": "内部截止结束日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.reset.innerDeadline,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reset,
                                              "innerDeadline",
                                              $$v
                                            )
                                          },
                                          expression: "reset.innerDeadline",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "normalFallbackFlag" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "是否普通退回",
                                            clearable: true,
                                            filterable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.queryForm.normalFallbackFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "normalFallbackFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.normalFallbackFlag",
                                          },
                                        },
                                        _vm._l(
                                          _vm.isGiveBacks,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "reachTime" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "到期时间",
                                            clearable: true,
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.queryForm.reachTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "reachTime",
                                                $$v
                                              )
                                            },
                                            expression: "queryForm.reachTime",
                                          },
                                        },
                                        _vm._l(
                                          _vm.reachTimeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "address" } },
                                    [
                                      _c("el-cascader", {
                                        attrs: {
                                          options: _vm.cityInfo,
                                          filterable: true,
                                          clearable: true,
                                          separator: "/",
                                          placeholder: "地区",
                                          props: { checkStrictly: true },
                                        },
                                        on: { change: _vm.handleBlockChange },
                                        model: {
                                          value: _vm.queryForm.address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression: "queryForm.address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "address" } },
                                    [
                                      _c("el-cascader", {
                                        attrs: {
                                          options: _vm.cityInfo,
                                          filterable: true,
                                          clearable: true,
                                          separator: "/",
                                          placeholder: "地区",
                                          props: { checkStrictly: true },
                                        },
                                        on: { change: _vm.handleBlockChange },
                                        model: {
                                          value: _vm.queryForm.address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression: "queryForm.address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "createTimeTmp" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "unlink-panels": "",
                                          clearable: "",
                                          "range-separator": "至",
                                          "start-placeholder": "创建开始日期",
                                          "end-placeholder": "创建结束日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.reset.createTimeTmp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reset,
                                              "createTimeTmp",
                                              $$v
                                            )
                                          },
                                          expression: "reset.createTimeTmp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "brWorkNo" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "订单编号",
                                          clearable: "",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.handleQuery.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.queryForm.brWorkNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryForm,
                                              "brWorkNo",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "queryForm.brWorkNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "reportType" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "报告类型",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.queryForm.reportType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "reportType",
                                                $$v
                                              )
                                            },
                                            expression: "queryForm.reportType",
                                          },
                                        },
                                        _vm._l(_vm.reportType, function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 6,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "projectSource" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择项目来源",
                                            clearable: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.queryForm.projectSource,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryForm,
                                                "projectSource",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryForm.projectSource",
                                          },
                                        },
                                        _vm._l(
                                          _vm.resourceOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.key,
                                              attrs: {
                                                label: item.name,
                                                value: item.key,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.goCreateOrder },
                    },
                    [_vm._v(" 创建工单 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-download" },
                      on: { click: _vm.batchImport },
                    },
                    [_vm._v(" 导入 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-finished", type: "primary" },
                      on: { click: _vm.batchCommit },
                    },
                    [_vm._v(" 批量提交 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-paperclip", type: "primary" },
                      on: { click: _vm.commitAnnex },
                    },
                    [_vm._v(" 批量上传附件 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-delete", type: "danger" },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v(" 批量删除 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.downloadLoading, type: "primary" },
                      on: { click: _vm.handleDownload },
                    },
                    [_vm._v(" 导出 ")]
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        data: _vm.list,
                        "element-loading-text": _vm.elementLoadingText,
                        "row-class-name": _vm.tableRowClassName,
                        border: "",
                      },
                      on: { "selection-change": _vm.setSelectRows },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "40",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "工单编号",
                          prop: "workOrderNo",
                          align: "center",
                          width: "130",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "img-icon" }, [
                                  scope.row.operStatus === 31
                                    ? _c("img", {
                                        staticClass: "my-back-img",
                                        attrs: {
                                          src: require("@/icon/back.svg"),
                                          alt: "退",
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.brChangeFlag
                                    ? _c("img", {
                                        staticClass: "my-edit-img",
                                        attrs: {
                                          src: require("@/icon/edit.svg"),
                                          alt: "修",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _vm._v(
                                  " " + _vm._s(scope.row.workOrderNo) + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubject",
                          label: "目标主体",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "目标地址",
                          "min-width": "250",
                          prop: "address",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.address || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "项目",
                          prop: "projectName",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.projectName || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "maturityDate",
                          label: "到期时间(天)",
                          width: "70",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderTime",
                          label: "下单日期",
                          formatter: _vm.dateFormat,
                          width: "120",
                          align: "center",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "innerDeadline",
                          label: "内部截止日期",
                          formatter: _vm.dateFormat,
                          width: "130",
                          align: "center",
                          sortable: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.innerDeadline || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "创建日期",
                          formatter: _vm.dateFormat,
                          width: "120",
                          align: "center",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stepAndStranded",
                          align: "center",
                          label: "环节/整体滞留(天)",
                          width: "90",
                          fit: "true",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.stepAndStranded || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          fit: "",
                          align: "center",
                          width: "140px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "提交",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-top",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCommit(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "编辑",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-edit-outline",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                true
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "删除",
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                            icon: "el-icon-delete",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(scope.row)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "退回",
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            icon: "el-icon-back",
                                            type: "warning",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.batchReturn(scope.row)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 24, xl: 24 } },
            [
              _c(
                "el-card",
                [
                  _c("el-pagination", {
                    attrs: {
                      background: _vm.background,
                      "current-page": _vm.queryForm.pageNum,
                      layout: _vm.layout,
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传附件",
            visible: _vm.openCommitAnnex,
            width: "573px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openCommitAnnex = $event
            },
          },
        },
        [
          _c(
            "el-steps",
            {
              staticStyle: { margin: "30px -40%" },
              attrs: { active: 2, "align-center": "" },
            },
            [
              _c("el-step", {
                attrs: { title: "上传工单", icon: "el-icon-success" },
              }),
              _c(
                "el-step",
                { attrs: { title: "上传附件" } },
                [_c("vab-remix-icon", { attrs: { "icon-class": "number-2" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                { attrs: { align: "middle", type: "flex", gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "600",
                          "font-size": "15px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v(" 请您上传资源 ")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.endpoinxBak,
                              "on-error": _vm.handleError,
                              "on-remove": _vm.handleRemove,
                              "on-change": _vm.handleFileChange,
                              "http-request": _vm.batch,
                              "auto-upload": false,
                              multiple: "",
                              "file-list": _vm.fileList,
                              drag: "",
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v(" 将文件拖到此处，或 "),
                              _c("em", [_vm._v("点击上传")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [_vm._v("附件大小不得大于 1G")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  ref: "$complete",
                  attrs: {
                    loading: _vm.subLoading,
                    type: "primary",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.attachmentSubmit },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("create", {
        ref: "create",
        on: { fetchData: _vm.fetchData, refreshData: _vm.refreshData },
      }),
      _c("localCreate", { ref: "localCreate" }),
      _c("importTemplate", {
        ref: "importTemplate",
        on: { fetchData: _vm.fetchData },
      }),
      _c("submit", { ref: "submit", on: { refreshData: _vm.refreshData } }),
      _c("withdraw", { ref: "withdraw" }),
      _c("edit", { ref: "edit", on: { refreshData: _vm.refreshData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }