<!--经理工作天 ->  工单列表-->
<template>
  <el-card class="box-card">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class">
            <el-row :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="orderDate">
                  <el-date-picker v-model="queryForm.orderDate"
                                  type="daterange"
                                  unlink-panels
                                  clearable
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number="queryForm.workOrderNo"
                            clearable
                            placeholder="工单编号"
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            clearable
                            placeholder="目标主体"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               clearable
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <transition name="fade">
              <el-row v-show="isShow"
                      transiton="fade"
                      :gutter="20">
                <!-- 预约走访日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="expectVisitDate">
                    <el-date-picker v-model="queryForm.expectVisitDate"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="预约走访开始日期"
                                    end-placeholder="预约走访结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 选择地区 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop=" address">
                    <el-form-item prop="selectedOptions">
                      <el-cascader v-model="queryForm.address"
                                   :options="cityInfo"
                                   :filterable="true"
                                   clearable
                                   separator="/"
                                   placeholder="选择地区"
                                   :props="{ checkStrictly: true }"
                                   @change="handleBlockChange"></el-cascader>
                    </el-form-item>
                  </el-form-item>
                </el-col>
                <!-- 工单状态 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="workOrderStatus">
                    <el-select v-model="queryForm.workOrderStatus"
                               placeholder="工单状态"
                               clearable
                               filterable>
                      <el-option v-for="item in orderStatusInfo"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <!-- 到期时间 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reachTime">
                    <el-select v-model="queryForm.reachTime"
                               placeholder="到期时间"
                               :clearable="true"
                               filterable>
                      <el-option v-for="item in reachTimeOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 内部截止 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="innerDeadDate">
                    <el-date-picker v-model="queryForm.innerDeadDate"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="内部截止开始日期"
                                    end-placeholder="内部截止结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>

                <!-- 会议日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="preliminaryDate">
                    <el-date-picker v-model="queryForm.preliminaryDate"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="初审开始日期"
                                    end-placeholder="初审结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- 走访日期 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="orderFinishDate">
                    <el-date-picker v-model="queryForm.orderFinishDate"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="工单结束起始日期"
                                    end-placeholder="工单结束终止日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>

                <!-- 选择人员 所有角色+名字（二级联动） -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="userInfo">
                    <el-cascader :value="queryForm.userInfo"
                                 :options="staffInfo"
                                 clearable
                                 :filterable="true"
                                 separator=" "
                                 placeholder="选择人员"
                                 collapse-tags
                                 @change="handleSatffChange"></el-cascader>
                  </el-form-item>
                </el-col>
                <!-- 订单编号 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="brWorkNo">
                    <el-input v-model.trim="queryForm.brWorkNo"
                              placeholder="订单编号"
                              clearable
                              @keyup.enter.native="handleQuery"></el-input>
                  </el-form-item>
                </el-col>
                <!-- 报告类型 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="reportType">
                    <el-select v-model="queryForm.reportType"
                               placeholder="报告类型"
                               clearable
                               filterable>
                      <el-option v-for="item in reportType"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- 项目来源 -->
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <el-form-item prop="projectSource">
                    <el-select v-model="queryForm.projectSource"
                               placeholder="请选择项目来源"
                               clearable
                               filterable>
                      <el-option v-for="item in resourceOptions"
                                 :key="item.key"
                                 :label="item.name"
                                 :value="item.key"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </transition>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-dropdown trigger="click"
                       @command="adjustStaff">
            <el-button plain
                       icon="el-icon-s-custom">
              调整人员
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="bookUser">调整预约</el-dropdown-item>
              <el-dropdown-item command="pl">调整PL</el-dropdown-item>
              <el-dropdown-item command="part_surveyUser">
                调整兼职调查员
              </el-dropdown-item>
              <el-dropdown-item command="full_surveyUser">
                调整全职调查员
              </el-dropdown-item>
              <el-dropdown-item command="supervisor">调整督导</el-dropdown-item>
              <el-dropdown-item command="audit">调整内审</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button :loading="downloadLoading"
                     type="primary"
                     style="margin-left: 10px"
                     @click="handleDownload">
            导出
          </el-button>

          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    style="margin-top: 15px"
                    border
                    :default-sort="{ prop: 'workOrderNo', order: 'descending' }"
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"></el-table-column>
            <el-table-column label="工单编号"
                             width="130"
                             prop="workOrderNo"
                             align="center"></el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <!-- 预约走访时间 -->
            <el-table-column prop="bookVisitTime"
                             label="预约走访时间"
                             width="130"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="visitTime"
                             label="走访时间"
                             align="center"
                             width="110"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.visitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="innerDeadline"
                             label="内部截止日期"
                             align="center"
                             width="130"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.innerDeadline || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="firstAuditDateEnd"
                             label="初审结束日期"
                             align="center"
                             width="130"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.firstAuditDateEnd || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderFinishDate"
                             label="工单结束日期"
                             align="center"
                             width="130"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.orderFinishDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="maturityDate"
                             label="到期时间"
                             align="center"
                             width="130"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.maturityDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="nowUserName"
                             label="当前操作人"
                             width="120"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.nowUserName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="workOrderStatusValue"
                             label="工单状态"
                             width="110"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.workOrderStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             label="环节/整体滞留(天)"
                             fit="true"
                             width="90">
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="120px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="详情"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-document"
                             circle
                             @click="goDetailPage(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="生成报告"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-document-add"
                             circle
                             @click="handleReport(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!-- 调整人员 -->
    <el-dialog :title="staffTitle"
               :visible.sync="openAdjustStaff"
               width="40%"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :close="adjustStaffClose">
      <!-- <div style="text-align: center;"> -->
      <el-form ref="adjustFrom"
               :model="adjustFrom"
               :rules="adjustFromRules"
               style="text-align: center">
        <el-form-item label="调整人员"
                      prop="staffValue">
          <el-select v-model="adjustFrom.staffValue"
                     :multiple="false"
                     :multiple-limit="multipleLimit"
                     placeholder="请选择"
                     filterable
                     style="width: 260px">
            <el-option v-for="item in staffData"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- </div> -->
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="adjustStaffClose">取 消</el-button>
        <el-button type="primary"
                   :loading="adjustLoading"
                   @click="staffConfirm('adjustFrom')">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { getOrderList } from "@/api/workplace/manager/index"
import {
	returnBitTimestamp,
	returnDeadtimestamp,
	getRoleKey,
	downloadReport,
} from "@/utils"
import { deBackcrypt } from "@/utils/encrypt"
import { adjustPerson, generateReport } from "@/api/workplace/commonOperation"
import {
	getAllReasonOption,
	getBlock,
	getProjectSource,
	getRole,
	getWorkOrderStatus,
	queryRolesByUserNo,
	querySystemUsers,
	getReportTypeList,
} from "@/api/workplace/tools"
import { mapGetters } from "vuex"
import { getList } from "@/api/workplace/pl/plOrderDone"
export default {
	name: "IndexVue",
	filters: {
		statusFilter(status) {
			const statusMap = {
				published: "success",
				draft: "gray",
				deleted: "danger",
			}
			return statusMap[status]
		},
	},
	data() {
		return {
			isShow: false,
			imgShow: true,
			// 批量上传附件
			commitAnnex: false,
			adjustLoading: false,
			title: [],
			list: [],
			imageList: [],
			listLoading: true,
			layout: "total, sizes, prev, pager, next, jumper",
			total: 0,
			background: true,
			selectRows: "",
			elementLoadingText: "正在加载...",
			reportForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "1",
					sourceType: "site",
				},
			},
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit("pick", [start, end])
						},
					},
				],
			},
			queryForm: {
				orderDate: [], // 表单数据，不传递到后端
				orderTimeEnd: null,
				orderTimeStart: null,
				workOrderNo: "",
				targetSubject: "",
				projectId: "",

				expectVisitDate: [], // 表单数据，不传递到后端
				bookVisitTimeStart: null,
				bookVisitTimeEnd: null,
				address: "",
				workOrderStatus: null,
				rejectReasonId: null,

				orderFinishDate: [], // 表单数据，不传递到后端
				orderFinishDateStart: null,
				orderFinishDateEnd: null,

				abandonContentId: null,

				innerDeadDate: [], // 表单数据，不传递到后端
				innerDeadlineEnd: null,
				innerDeadlineStart: null,
				preliminaryDate: [], // 表单数据，不传递到后端
				preliminaryReviewEnd: null,
				preliminaryReviewStart: null,

				// 到期日期
				reachTime: "",
				userInfo: null, // 人员信息
				roleUser: null, // 选择人员参数

				pageNum: 1,
				pageSize: 10,
				nextUserNo: "",
				nextUserRoleNo: "",
				// userNo: "",
				// userRoleNo: "",
			},
			// 质控工作台查询工单列表参数
			param: {
				body: {},
				header: {
					currentTime: "2020-08-04 00:00:00",
					requestId: "21122",
					sourceType: "site",
				},
			},

			// 项目
			projectInfo: [],
			projectForm: {
				body: {
					source: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			adjustFrom: {
				staffValue: "",
			},
			adjustFromRules: {
				staffValue: [
					{ required: true, message: "请选择调整人员", trigger: "change" },
				],
			},
			multipleLimit: 1,
			// 调整人员
			adjustOk: [],
			adjustNo: [],
			staffTitle: "",
			staffData: [],
			openAdjustStaff: false,
			/**调整人员 Form */
			staffPortForm: {
				body: {
					userAfter: "",
					userAfterRole: "",
					workflowInfoReqList: [
						{
							console: "",
							nextTaskId: "",
							nextUserNo: "",
							nextUserRoleNo: "",
							note: "",
							operAction: "",
							procInsId: "",
							projectSource: "",
							remark: "",
							step: "",
							taskId: "",
							userNo: "",
							userRoleNo: "",
							workOrderNo: "",
						},
					],
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			cityInfo: [],
			cityForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},

			// 工单状态
			orderStatusInfo: [],
			orderStatusForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			// 退回原因form
			allBackInfo: [],
			allBackForm: {
				body: {
					templateType: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			allAbandonInfo: [],
			// 退回原因form
			allAbandonForm: {
				body: {
					templateType: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},

			// 操作状态
			operStatusInfo: [],
			operStatusForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},

			// 角色人员
			roleOptions: [],

			userOptions: [],
			roleOptions_arr: [],
			roleForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			downloadLoading: false,
			// 选择到期时间
			reachTimeOptions: [
				{
					value: 0.5,
					label: "0.5天",
				},
				{
					value: 1,
					label: "1天",
				},
				{
					value: 2,
					label: "2天",
				},
				{
					value: 3,
					label: "3天",
				},
				{
					value: 5,
					label: "5天",
				},
				{
					value: 7,
					label: "7天",
				},
				{
					value: 10,
					label: "10天",
				},
				{
					value: 15,
					label: "15天",
				},
				{
					value: 20,
					label: "20天",
				},
				{
					value: 30,
					label: "30天",
				},
			],
			// 选择人员
			// props: { multiple: true },
			staffInfo: [],
			staffForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			reportType: [], // 报告类型
			// 项目来源
			resourceOptions: [
				{
					name: "香港业务流程",
					key: "process_hk",
				},
				{
					name: "本地业务流程",
					key: "process_nd",
				},
				{
					name: "BR业务流程",
					key: "process_br",
				},
			],
		}
	},
	computed: {
		...mapGetters({
			userNo: "user/userNo",
			roles: "user/roles",
		}),
	},
	created() {
		this.param.body.userNo = this.userNo
		this.param.body.userRoleNo = getRoleKey("manager", this.roles)
		this.fetchData()
		// 获取项目
		this.getProject()
		// 获取人员选项
		this.getStaffInfo()
		// 获取报告类型
		this.getReportType()
	},
	methods: {
		/** 导出Excel */
		handleDownload() {
			if (this.selectRows.length > 0) {
				this.downloadLoading = true
				import("@/vendor/ExportExcel").then((excel) => {
					const tHeader = [
						"工单编号",
						"订单编号",
						"目标主体",
						"目标地址",
						"项目",
						"报告类型",
						"二级类型",
						"预约走访时间",
						"走访时间",
						"内部截止日期",
						"初审结束日期",
						"工单结束日期",
						"到期时间",
						"当前操作人",
						"工单状态",
						"环节/整体滞留(天)",
					]
					const filterVal = [
						"workOrderNo",
						"brWorkNo",

						"targetSubject",
						"address",
						"projectName",
						"reportType",
						"subReportType",
						"bookVisitTime",
						"visitTime",
						"innerDeadline",
						"firstAuditDateEnd",
						"orderFinishDate",
						"maturityDate",
						"nowUserName",
						"workOrderStatusValue",
						"stepAndStranded",
					]
					const list = this.selectRows
					const data = this.formatJson(filterVal, list)
					excel.export_json_to_excel({
						header: tHeader,
						data,
						filename: "工单列表",
						autoWidth: true,
						bookType: "xlsx",
					})
					this.downloadLoading = false
				})
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},
		/** 组装导出数据 */
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					if (!v[j]) {
						return "——"
					}
					return v[j]
				})
			)
		},
		// 经理工作台查询工单列表
		async fetchData() {
			this.listLoading = true
			// 选择人员数据
			if (this.queryForm.userInfo && this.queryForm.userInfo.length > 0) {
				this.queryForm.roleUser = `${this.queryForm.userInfo[0]}:${this.queryForm.userInfo[1]}`
			} else {
				this.queryForm.roleUser = ""
			}
			// 下单日期范围
			if (null !== this.queryForm.orderDate) {
				if (0 < this.queryForm.orderDate.length) {
					this.queryForm.orderTimeStart = returnBitTimestamp(
						this.queryForm.orderDate[0]
					)
					this.queryForm.orderTimeEnd = returnDeadtimestamp(
						this.queryForm.orderDate[1]
					)
				}
			} else {
				this.queryForm.orderTimeStart = null
				this.queryForm.orderTimeEnd = null
			}
			// 预约走访日期范围
			if (null !== this.queryForm.expectVisitDate) {
				if (0 < this.queryForm.expectVisitDate.length) {
					this.queryForm.bookVisitTimeStart = returnBitTimestamp(
						this.queryForm.expectVisitDate[0]
					)
					this.queryForm.bookVisitTimeEnd = returnDeadtimestamp(
						this.queryForm.expectVisitDate[1]
					)
				}
			} else {
				this.queryForm.bookVisitTimeStart = null
				this.queryForm.bookVisitTimeEnd = null
			}
			// 工单结束日期
			if (null !== this.queryForm.orderFinishDate) {
				if (0 < this.queryForm.orderFinishDate.length) {
					this.queryForm.orderFinishDateStart = returnBitTimestamp(
						this.queryForm.orderFinishDate[0]
					)
					this.queryForm.orderFinishDateEnd = returnDeadtimestamp(
						this.queryForm.orderFinishDate[1]
					)
				}
			} else {
				this.queryForm.orderFinishDateStart = null
				this.queryForm.orderFinishDateEnd = null
			}
			// 内部截止日期范围
			if (null !== this.queryForm.innerDeadDate) {
				if (0 < this.queryForm.innerDeadDate.length) {
					this.queryForm.innerDeadlineStart = returnBitTimestamp(
						this.queryForm.innerDeadDate[0]
					)

					this.queryForm.innerDeadlineEnd = returnDeadtimestamp(
						this.queryForm.innerDeadDate[1]
					)
				}
			} else {
				this.queryForm.innerDeadlineStart = null
				this.queryForm.innerDeadlineEnd = null
			}
			// 初审开始日期范围
			if (null !== this.queryForm.preliminaryDate) {
				if (0 < this.queryForm.preliminaryDate.length) {
					this.queryForm.preliminaryReviewStart = returnBitTimestamp(
						this.queryForm.preliminaryDate[0]
					)

					this.queryForm.preliminaryReviewEnd = returnDeadtimestamp(
						this.queryForm.preliminaryDate[1]
					)
				}
			} else {
				this.queryForm.preliminaryReviewStart = null
				this.queryForm.preliminaryReviewEnd = null
			}

			this.param.body = Object.assign(this.param.body, this.queryForm)
			const { body } = await getOrderList(this.param)
			this.list = body.records
			let listStr = []
			let listStrA = []
			if (this.list.length !== 0) {
				for (let i = 0; i < this.list.length; i++) {
					if (this.list[i].visitTime !== null) {
						listStrA.push(this.list[i])
					} else {
						listStr.push(this.list[i])
					}
				}
			}
			this.list = []
			this.list = this.list.concat(listStrA, listStr)
			this.total = body.total
			this.$emit("onChange", this.total)
			this.listLoading = false
			Object.assign(this.queryPortForm.body, this.queryForm)
		},
		//=============
		//调整人员对话框
		adjustStaff(command) {
			if (this.selectRows.length > 0) {
				/** 经Ivan与产品经理bunny确认 调整人员不可超过50个工单 */
				if (this.selectRows.length > 50) {
					this.$baseMessage("建议调整人员不要超过50条工单", "error")
					return false
				}
				this.adjustLoading = false
				if ("bookUser" === command) {
					this.staffTitle = "调整预约"
					this.getRoleAdjustOption("bookUser")
					this.multipleLimit = 1
					this.judgeAdjust("bookUser")
				} else if ("pl" === command) {
					this.staffTitle = "调整PL"
					this.getRoleAdjustOption("pl")
					this.multipleLimit = 1
					this.judgeAdjust("pl")
				} else if ("full_surveyUser" === command) {
					this.staffTitle = "调整全职调查员"
					this.getRoleAdjustOption("full_surveyUser")
					this.multipleLimit = 100
					this.judgeAdjust("full_surveyUser")
				} else if ("part_surveyUser" === command) {
					this.staffTitle = "调整兼职调查员"
					this.getRoleAdjustOption("part_surveyUser")
					this.multipleLimit = 100
					this.judgeAdjust("part_surveyUser")
				} else if ("supervisor" === command) {
					this.staffTitle = "调整督导"
					this.getRoleAdjustOption("supervisor")
					this.multipleLimit = 1
					this.judgeAdjust("supervisor")
				} else if ("audit" === command) {
					this.staffTitle = "调整内审"
					this.getRoleAdjustOption("audit")
					this.multipleLimit = 1
					this.judgeAdjust("audit")
				}
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},
		// 调整人员窗口关闭
		adjustStaffClose() {
			// this.staffValue = [];
			/** 避免重置后出现选择器不期望的数据格式 */
			this.openAdjustStaff = false
			this.$refs["adjustFrom"].resetFields()
			this.adjustLoading = false
		},
		// 获取角色配置项 [不包括经理]
		getRoleAdjustOption(role) {
			this.roleForm.header.currentTime = returnBitTimestamp(new Date())
			switch (role) {
				// 不包括经理
				// 预约人员
				case "bookUser":
					this.roleForm.body = [
						{
							roleKey: "book_person",
						},
					]
					break
				// 兼职调查员
				case "part_surveyUser":
					this.roleForm.body = [
						{
							roleKey: "part_investigator",
						},
					]
					break
				// 全职调查员
				case "full_surveyUser":
					this.roleForm.body = [
						{
							roleKey: "full_investigator",
						},
					]
					break
				// PL
				case "pl":
					this.roleForm.body = [
						{
							roleKey: "PL",
						},
					]
					break
				// 督导人员
				case "supervisor":
					this.roleForm.body = [
						{
							roleKey: "supervisor",
						},
					]
					break
				// 内审人员
				case "audit":
					this.roleForm.body = [
						{
							roleKey: "verify_people",
						},
					]
					break
				// 用户
				case "user":
					this.roleForm.body = [
						{
							roleKey: "customer",
						},
					]
					break
				default:
					break
			}
			getRole(this.roleForm).then((response) => {
				if (response.header.retCode === "1") {
					this.staffData = response.body
					this.adjustFrom.userAfterRole = this.roleForm.body
						.map((item) => {
							return item.roleKey
						})
						.join(",")
				}
			})
		},
		// 判断调整人员
		async judgeAdjust(person) {
			this.adjustOk = []
			this.adjustNo = []
			let userRole = ""
			let surverUsers = []
			switch (person) {
				// 调整预约
				case "bookUser":
					this.selectRows.forEach((item) => {
						if (item.workOrderStatus === 2 || item.workOrderStatus === 11) {
							this.adjustOk.push(item.workOrderNo)
						} else {
							this.adjustNo.push(item.workOrderNo)
						}
					})
					break

				// case "surveyUser":
				//   this.selectRows.forEach((item) => {
				//     if (item.workOrderStatus === 4 || item.workOrderStatus === 5) {
				//       this.adjustOk.push(item.workOrderNo);
				//     } else {
				//       this.adjustNo.push(item.workOrderNo);
				//     }
				//   });
				// 调整全职调查员
				case "full_surveyUser":
					userRole = "full"
					this.selectRows.forEach((item) => {
						if (item.workOrderStatus === 4 || item.workOrderStatus === 5) {
							this.adjustOk.push(item.workOrderNo)
							surverUsers.push(item.surveyUser)
						} else {
							this.adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整兼职调查员
				case "part_surveyUser":
					userRole = "part"
					this.selectRows.forEach((item) => {
						if (item.workOrderStatus === 4 || item.workOrderStatus === 5) {
							this.adjustOk.push(item.workOrderNo)
							surverUsers.push(item.surveyUser)
						} else {
							this.adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整pl
				case "pl":
					this.selectRows.forEach((item) => {
						if (item.workOrderStatus === 1) {
							this.adjustOk.push(item.workOrderNo)
						} else {
							this.adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整督导
				case "supervisor":
					this.selectRows.forEach((item) => {
						if (item.workOrderStatus === 3 || item.workOrderStatus === 6) {
							this.adjustOk.push(item.workOrderNo)
						} else {
							this.adjustNo.push(item.workOrderNo)
						}
					})
					break
				// 调整内审
				case "audit":
					this.selectRows.forEach((item) => {
						if (item.workOrderStatus === 7) {
							this.adjustOk.push(item.workOrderNo)
						} else {
							this.adjustNo.push(item.workOrderNo)
						}
					})
					break
				default:
					break
			}
			let msg = "1"
			if (this.adjustNo.length === 0) {
				if (surverUsers.length != 0) {
					for (let i = 0; i < surverUsers.length; i++) {
						let userForm = {}
						userForm.body = surverUsers[i]
						let { header, body } = await queryRolesByUserNo(userForm)
						if (header.retCode === "1") {
							for (let j = 0; j < body.length; j++) {
								if (
									body[j].roleKey === "full_investigator" &&
									userRole !== "full"
								) {
									msg = "全职调查不能调整给兼职人员调查"
								} else if (
									body[j].roleKey === "part_investigator" &&
									userRole !== "part"
								) {
									msg = "兼职调查不能调整给全职人员调查"
								}
							}
						}
					}
				}
				if (msg != "1") {
					this.$message({
						dangerouslyUseHTMLString: true,
						showClose: true,
						duration: 10000,
						message: `<br>${msg}:</br>
                        <br>无法调整的工单: ${this.adjustOk.join(",")};</br>
                      `,
						type: "warning",
					})
					return
				}
				this.openAdjustStaff = true
			} else {
				this.$message({
					dangerouslyUseHTMLString: true,
					showClose: true,
					duration: 10000,
					message: `<br>以下工单无法调整人员:</br>
                      <br>无法调整的工单: ${this.adjustNo.join(",")};</br>
                    `,
					type: "warning",
				})
			}
		},
		//=======

		setSelectRows(val) {
			this.selectRows = val
		},
		// 重置
		resetForm(formName) {
			if (this.$refs[formName] !== undefined) {
				this.$refs[formName].resetFields()
				this.queryForm = this.$options.data().queryForm
			}
		},
		handleSizeChange(val) {
			this.queryForm.pageSize = val
			this.fetchData()
		},
		handleCurrentChange(val) {
			this.queryForm.pageNum = val
			this.fetchData()
		},
		heightQuery() {
			this.isShow = !this.isShow
			/** 优化接口查询 */
			if (this.isShow) {
				// 获取角色列表
				this.getRoleList()
				// 获取地区
				this.getAddress()
				// 获取工单状态
				this.getOrderStatus()
				// 退回原因
				this.getAllBack()
				// 放弃原因
				this.getAllAbandon()
			}
		},
		handleQuery() {
			this.queryForm.pageNum = 1
			this.fetchData()
		},
		// 打开工单单详情页面
		goDetailPage(row) {
			let routeData = this.$router.resolve({
				path: "/workplace/manager/orderList/detail",
				name: "Detail",
				query: {
					target: row.targetSubject,
					workOrderNo: row.workOrderNo,
					workOrderStatus: parseInt(row.workOrderStatus),
					address: row.address,
					projectName: row.projectName,
					projectId: row.projectId,
					seqId: row.seqId,
					userNo: this.userNo,
					procInsId: row.procInsId,
					taskId: row.taskId,
					userRoleNo: getRoleKey("manager", this.roles),
					surveyUser: row.surveyUser,
					projectSign: row.projectSign,
				},
			})
			/*** 截掉site字符 */
			routeData.href = routeData.href.slice(5)
			window.open(routeData.href, "_blank")
		},
		async handleReport(row) {
			this.reportForm.header.currentTime = returnBitTimestamp(new Date())
			this.reportForm.body.workOrderNo = row.workOrderNo
			const { header, body } = await generateReport(this.reportForm)
			if (header.retCode === "1") {
				downloadReport(`/site/downOssAndTemp?workOrderNo=${row.workOrderNo}`)
			} else {
				this.$baseMessage(`${header.retMessage}`, "error")
			}
		},
		// 获取项目
		getProject() {
			this.projectForm.header.currentTime = returnBitTimestamp(new Date())
			getProjectSource(this.projectForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.projectInfo = response.body
				}
			})
		},
		// 选择项目
		handleProjectChange(value) {
			this.queryForm.projectId = value.length > 0 ? value[1] : null
		},
		// 获取地区
		getAddress() {
			this.cityForm.header.currentTime = returnBitTimestamp(new Date())
			getBlock(this.cityForm).then((response) => {
				if ("1" === response.header.retCode) {
					let block_info = []
					block_info.push(response.body[0])
					this.cityInfo = block_info
				} else {
					this.$message.error(
						`${response.header.retMessage},获取地区失败，请重新刷新页面`
					)
				}
			})
		},
		// 选择地区
		handleBlockChange(value) {
			this.queryForm.address = value.join(",")
		},
		// 获取工单状态
		getOrderStatus() {
			this.orderStatusForm.header.currentTime = returnBitTimestamp(new Date())
			getWorkOrderStatus(this.orderStatusForm).then((response) => {
				if (response.header.retCode === "1") {
					this.orderStatusInfo = response.body
				} else {
					this.$message.error(
						`${response.header.retMessage},获取工单状态失败，请重新刷新页面`
					)
				}
			})
		},
		// 获取退回原因 普通退回+待定退回
		getAllBack() {
			this.allBackForm.header.currentTime = returnBitTimestamp(new Date())
			this.allBackForm.body.templateType = "81"
			getAllReasonOption(this.allBackForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.allBackInfo = response.body
				}
			})
		},
		// 获取放弃原因 上门前放弃&上门后
		getAllAbandon() {
			this.allAbandonForm.header.currentTime = returnBitTimestamp(new Date())
			this.allAbandonForm.body.templateType = "80"
			getAllReasonOption(this.allAbandonForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.allAbandonInfo = response.body
				}
			})
		},
		// 获取角色配置项
		getRoleList() {
			let role_arr = []
			let roleForm = {
				body: [],
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			}
			let roleOptions_arr = []
			// PL
			const pL = [
				{
					roleKey: "manager",
				},
				{
					roleKey: "PL",
				},
			]
			// 预约人员
			const bookUser = [
				{
					roleKey: "manager",
				},
				{
					roleKey: "book_person",
				},
			]
			// 调查人员
			const surveyUser = [
				{
					roleKey: "manager",
				},
				{
					roleKey: "full_investigator",
				},
				{
					roleKey: "part_investigator",
				},
			]
			// 督导人员
			const supervisor = [
				{
					roleKey: "manager",
				},
				{
					roleKey: "supervisor",
				},
			]
			// 内审人员
			const audit = [
				{
					roleKey: "manager",
				},
				{
					roleKey: "verify_people",
				},
			]
			// 用户
			const user = [
				{
					roleKey: "manager",
				},
				{
					roleKey: "customer",
				},
			]
			role_arr.push(pL, bookUser, surveyUser, supervisor, audit, user)
			// 异步调用接口，使其按顺序执行
			async function getAsyncRole() {
				roleForm.header.currentTime = returnBitTimestamp(new Date())
				for (let index = 0; index < role_arr.length; index++) {
					roleForm.body = role_arr[index]
					await getRole(roleForm).then((response) => {
						if (response.header.retCode === "1") {
							roleOptions_arr.push(response.body)
						} else {
							if (index === 1) {
								this.$message.error(
									`错误信息: ${response.header.retMessage},请重新刷新页面，获取预约人员选择信息`
								)
							} else if (index === 2) {
								this.$message.error(
									`错误信息: ${response.header.retMessage},请重新刷新页面，获取调查人员选择信息`
								)
							} else if (index === 3) {
								this.$message.error(
									`错误信息: ${response.header.retMessage},请重新刷新页面，获取调查人员选择信息`
								)
							} else if (index === 4) {
								this.$message.error(
									`错误信息: ${response.header.retMessage},请重新刷新页面，获取内审人员选择信息`
								)
							} else if (index === 5) {
								this.$message.error(
									`错误信息: ${response.header.retMessage},请重新刷新页面，获取用户选择信息`
								)
							}
							roleOptions_arr.push([])
						}
					})
					if (index === role_arr.length - 1) {
						return roleOptions_arr
					}
				}
			}
			getAsyncRole().then((res) => {
				this.roleOptions_arr = res
			})
		},
		// 根据角色匹配相关配置项
		getRoleOption(role) {
			switch (role) {
				case "pL":
					this.pLOptions = this.roleOptions_arr[0]
					break
				case "bookUser":
					this.bookUserOptions = this.roleOptions_arr[1]
					break
				case "surveyUser":
					this.surveyUserOptions = this.roleOptions_arr[2]
					break
				case "supervisor":
					this.supervisorOptions = this.roleOptions_arr[3]
					break
				case "audit":
					this.auditOptions = this.roleOptions_arr[4]
					break
				case "user":
					this.userOptions = this.roleOptions_arr[5]
					break
				default:
					break
			}
		},
		staffConfirm(formData) {
			this.$refs[formData].validate(async (valid) => {
				if (valid) {
					/** 记得处理userNo与 userRoleNo */
					this.staffPortForm.body.userAfterRole = this.adjustFrom.userAfterRole
					this.staffPortForm.body.userAfter = this.adjustFrom.staffValue
					this.staffPortForm.body.workflowInfoReqList =
						this.$refs.tableSort.selection
					this.staffPortForm.body.workflowInfoReqList.map((item) => {
						item.userNo = this.userNo
						item.userRoleNo = this.adjustFrom.userAfterRole
						return item
					})
					this.adjustLoading = true

					adjustPerson(this.staffPortForm).then((res) => {
						if (res.header.retCode === "1") {
							this.fetchData()
							this.$message({
								message: `${res.header.retMessage}`,
								type: "success",
							})
							this.adjustStaffClose()
						} else {
							this.adjustLoading = false
							this.$message.error(`${res.header.retMessage}`)
						}
					})
				} else {
					this.adjustLoading = false
					return false
				}
			})
		},
		// 获取人员信息
		async getStaffInfo() {
			this.staffForm.header.currentTime = returnBitTimestamp(new Date())
			const { header, body } = await querySystemUsers(this.staffForm)
			if (header.retCode === "1") {
				this.staffInfo = body
			}
		},
		// 选择人员数据框改变值
		handleSatffChange(value) {
			if (value) this.queryForm.userInfo = value
		},
		// 获取报告分类
		getReportType() {
			getReportTypeList(this.param).then((response) => {
				if (response.header.retCode === "1") {
					this.reportType = response.body
				}
			})
		},
	},
}
</script>
<style scoped>
.el-col {
	margin-bottom: 10px;
}
.el-col :last-child {
	margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100%;
}

.el-select,
.el-cascader {
	width: 100%;
}
.el-table /deep/.warning-row {
	background: #f8b7bc;
}
.my-form-class {
	margin-bottom: -10px !important;
}
</style>
