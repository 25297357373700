/**
 * @copyright CBI cbi@chinacbi.com
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import router from "@/router";
import store from "@/store";
import { getAccessToken } from "@/utils/accessToken";
import VabProgress from "nprogress";
import "nprogress/nprogress.css";
import getPageTitle from "@/utils/pageTitle";
import { Message } from "element-ui";
import {
  authentication,
  loginInterception,
  routesWhiteList,
  progressBar,
  recordRoute,
} from "./settings";
import cookie from "js-cookie";

VabProgress.configure({
  easing: "ease",
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
});
// 路由守卫相关配置
router.beforeResolve(async (to, from, next) => {
  if (progressBar) VabProgress.start();
  let hasToken = getAccessToken();
  if (!loginInterception) hasToken = true;
  if (hasToken) {
    if (hasToken !== store.getters["user/accessToken"]) {
      window.location.reload();
    }
    if (to.path === "/login" || to.path === "/404") {
      next({ path: "/" });
      if (progressBar) VabProgress.done();
    } else {
      const hasPermissions =
        store.getters["user/permissions"] &&
        store.getters["user/permissions"].length > 0;
      // 判断用户信息是否已获取完成，用户信息不存在时开始获取
      if (hasPermissions) {
        next();
      } else {
        try {
          let permissions;
          //settings.js loginInterception为false时，创建虚拟权限
          store.dispatch("user/setPermissions", ["admin"]);
          permissions = ["admin"];
          /**获取oss信息 */
          store.dispatch("user/getOssInfo");
          // 判断用户信息是否已获取完成，用户信息不存在时开始获取
          store
            .dispatch("user/getInfo")
            .then((res) => {
              let roleId = sessionStorage.getItem("roleId")
                ? sessionStorage.getItem("roleId")
                : res.roles[0].roleId;
              // 拉取user_info
              // 成功获取生成路由
              store
                .dispatch("routes/setRoute", { roleId })
                .then((accessRoutes) => {
                  // 根据roles权限生成可访问的路由表
                  router.addRoutes(accessRoutes); // 动态添加可访问路由表
                  next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
                });
            })
            .catch((err) => {
              store.dispatch("user/resetAccessToken").then(() => {
                Message.error(err || "Has Error");
                next({ path: "/" });
              });
            });
          /*
          let accessRoutes = [];
          if (authentication === "intelligence") {
            accessRoutes = await store.dispatch(
              "routes/setRoutes",
              permissions
            );
          } else if (authentication === "all") {
            accessRoutes = await store.dispatch("routes/setAllRoutes");
          }
          // 根据roles权限生成可访问的路由表
          router.addRoutes(accessRoutes);
          next({ ...to, replace: true });
          */
        } catch (e) {
          await store.dispatch("user/resetAccessToken");
          if (progressBar) VabProgress.done();
        }
      }
    }
  } else {
    if (routesWhiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      if (recordRoute) {
        next(`/login?redirect=${to.path}`);
      } else {
        store.dispatch("user/resetAccessToken");
        next("/login");
      }

      if (progressBar) VabProgress.done();
    }
  }
  document.title = getPageTitle(to.meta.title);
});
router.afterEach(() => {
  if (progressBar) VabProgress.done();
});
