var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "myDialog",
      attrs: {
        title: _vm.createFormTitle,
        visible: _vm.createFormVisible,
        "close-on-click-modal": false,
        width: "70%",
        fullscreen: _vm.flag,
        "before-close": _vm.createFormClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.createFormVisible = $event
        },
      },
    },
    [
      _c("el-backtop", { attrs: { target: ".myDialog" } }),
      _c("i", {
        class: _vm.fullIcon,
        staticStyle: {
          position: "absolute",
          top: "24px",
          right: "41px",
          cursor: "pointer",
        },
        attrs: { title: _vm.fullTitle },
        on: { click: _vm.fullOrClose },
      }),
      _c(
        "div",
        { staticClass: "createForm-box", staticStyle: { padding: "0 10px" } },
        [
          _c(
            "el-form",
            {
              ref: "createForm",
              attrs: {
                model: _vm.createForm,
                rules: _vm.createFormRules,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c("h3", [_vm._v("基础信息")]),
              _c(
                "el-row",
                { attrs: { gutter: 60 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Project Name",
                            prop: "projectNameNo",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.projectNameNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "projectNameNo", $$v)
                              },
                              expression: "createForm.projectNameNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目", prop: "project" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.projectInfo,
                              clearable: "",
                              filterable: true,
                              separator: ":",
                              placeholder: "请选择项目",
                            },
                            on: { change: _vm.handleProjectChange },
                            model: {
                              value: _vm.createForm.project,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "project", $$v)
                              },
                              expression: "createForm.project",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标主体", prop: "targetSubject" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.targetSubject,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "targetSubject", $$v)
                              },
                              expression: "createForm.targetSubject",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "国家/省份/城市/地域（县级市）",
                            prop: "block",
                          },
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.cityInfo,
                              clearable: "",
                              separator: "/",
                              props: { checkStrictly: true },
                              placeholder: "请选择地区",
                              filterable: true,
                            },
                            on: { change: _vm.handleBlockChange },
                            model: {
                              value: _vm.createForm.block,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "block", $$v)
                              },
                              expression: "createForm.block",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标地址1", prop: "targetAddr1" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.targetAddr1,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "targetAddr1", $$v)
                              },
                              expression: "createForm.targetAddr1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标地址2", prop: "targetAddr2" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.targetAddr2,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "targetAddr2", $$v)
                              },
                              expression: "createForm.targetAddr2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "目标联系人", prop: "targetContact" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.targetContact,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "targetContact", $$v)
                              },
                              expression: "createForm.targetContact",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "contactNumber" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.contactNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "contactNumber", $$v)
                              },
                              expression: "createForm.contactNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "职务", prop: "duty" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.duty,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "duty", $$v)
                              },
                              expression: "createForm.duty",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "下单时间", prop: "orderTimeTmp" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetime",
                              clearable: "",
                              placeholder: "选择下单时间",
                              format: "yyyy-MM-dd HH:mm",
                            },
                            on: { focus: _vm.pickFocus },
                            model: {
                              value: _vm.createForm.orderTimeTmp,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "orderTimeTmp", $$v)
                              },
                              expression: "createForm.orderTimeTmp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "内部截止日期",
                            prop: "innerDeadlineTmp",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              clearable: "",
                              placeholder: "请选择日期",
                              required: "",
                              "default-time": "23:59:59",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.createForm.innerDeadlineTmp,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "innerDeadlineTmp",
                                  $$v
                                )
                              },
                              expression: "createForm.innerDeadlineTmp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "对账ID", prop: "reconciliationId" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.reconciliationId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "reconciliationId",
                                  $$v
                                )
                              },
                              expression: "createForm.reconciliationId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "SV Code", prop: "svCode" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.svCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "svCode", $$v)
                              },
                              expression: "createForm.svCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "委托方全称",
                            prop: "clientFullName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.clientFullName,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "clientFullName", $$v)
                              },
                              expression: "createForm.clientFullName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "委托方简称", prop: "clientAbbr" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.clientAbbr,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "clientAbbr", $$v)
                              },
                              expression: "createForm.clientAbbr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "报告期限", prop: "reportTimeLimit" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.createForm.reportTimeLimit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createForm,
                                    "reportTimeLimit",
                                    $$v
                                  )
                                },
                                expression: "createForm.reportTimeLimit",
                              },
                            },
                            _vm._l(_vm.reportTime, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注1", prop: "remark1" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入",
                              clearable: "",
                            },
                            model: {
                              value: _vm.createForm.remark1,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "remark1", $$v)
                              },
                              expression: "createForm.remark1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注2", prop: "remark2" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入",
                              clearable: "",
                            },
                            model: {
                              value: _vm.createForm.remark2,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "remark2", $$v)
                              },
                              expression: "createForm.remark2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注3", prop: "remark3" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入",
                              clearable: "",
                            },
                            model: {
                              value: _vm.createForm.remark3,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "remark3", $$v)
                              },
                              expression: "createForm.remark3",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h3", [_vm._v("附加信息")]),
              _c(
                "el-row",
                { attrs: { gutter: 60 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预计走访日期",
                            prop: "expectVisitDateTmp",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              placeholder: "请选择日期",
                              clearable: "",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.createForm.expectVisitDateTmp,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "expectVisitDateTmp",
                                  $$v
                                )
                              },
                              expression: "createForm.expectVisitDateTmp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预计所需调查天数",
                            prop: "expectServeyDay",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.expectServeyDay,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "expectServeyDay", $$v)
                              },
                              expression: "createForm.expectServeyDay",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所需人手", prop: "needUser" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.needUser,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "needUser",
                                  _vm._n($$v)
                                )
                              },
                              expression: "createForm.needUser",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预计需时（小时）",
                            prop: "expectHour",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.expectHour,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "expectHour", $$v)
                              },
                              expression: "createForm.expectHour",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "注册地址", prop: "registerAddress" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.registerAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "registerAddress", $$v)
                              },
                              expression: "createForm.registerAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "目标主体注册状态",
                            prop: "targetSubjectRegStatus",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              type: "text",
                              clearable: "",
                            },
                            model: {
                              value: _vm.createForm.targetSubjectRegStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "targetSubjectRegStatus",
                                  $$v
                                )
                              },
                              expression: "createForm.targetSubjectRegStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "走访收费", prop: "visitFee" } },
                        [
                          _c("el-input", {
                            attrs: {
                              id: "visitFee",
                              placeholder: "请输入",
                              type: "password",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.createForm.visitFee,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "visitFee", $$v)
                              },
                              expression: "createForm.visitFee",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "加急走访收费",
                            prop: "urgentVisitFee",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              id: "urgentVisitFee",
                              placeholder: "请输入",
                              type: "password",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.createForm.urgentVisitFee,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "urgentVisitFee", $$v)
                              },
                              expression: "createForm.urgentVisitFee",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "再次走访收费",
                            prop: "againVisitFee",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              id: "againVisitFee",
                              placeholder: "请输入",
                              type: "password",
                              "auto-complete": "new-password",
                            },
                            model: {
                              value: _vm.createForm.againVisitFee,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "againVisitFee", $$v)
                              },
                              expression: "createForm.againVisitFee",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收费依据", prop: "feeBasis" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.feeBasis,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "feeBasis", $$v)
                              },
                              expression: "createForm.feeBasis",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "目标机构1",
                            prop: "targetOrganization1",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.targetOrganization1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "targetOrganization1",
                                  $$v
                                )
                              },
                              expression: "createForm.targetOrganization1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "目标机构2",
                            prop: "targetOrganization2",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.targetOrganization2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "targetOrganization2",
                                  $$v
                                )
                              },
                              expression: "createForm.targetOrganization2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "目标地址预核结果",
                            prop: "targetResult",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.targetResult,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "targetResult", $$v)
                              },
                              expression: "createForm.targetResult",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "不可行原因/待确认项目",
                            prop: "infeasibleReason",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.infeasibleReason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createForm,
                                  "infeasibleReason",
                                  $$v
                                )
                              },
                              expression: "createForm.infeasibleReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮件抬头", prop: "mailHeader" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.createForm.mailHeader,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "mailHeader", $$v)
                              },
                              expression: "createForm.mailHeader",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "特别留言", prop: "plSpecialMsg" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入特别留言",
                              rows: 7,
                              "show-word-limit": "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.createForm.plSpecialMsg,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "plSpecialMsg", $$v)
                              },
                              expression: "createForm.plSpecialMsg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 16, xl: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上传附件" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                "list-type": "picture-card",
                                action: "#",
                                "on-error": _vm.handleError,
                                "before-upload": _vm.beforeUpload,
                                "http-request": _vm.fnUploadRequest,
                                "file-list": _vm.fileList,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "file",
                                  fn: function ({ file }) {
                                    return _c("div", {}, [
                                      _c("img", {
                                        staticClass:
                                          "el-upload-list__item-thumbnail",
                                        attrs: {
                                          src: require("@/icon/annex.svg"),
                                          alt: file.name,
                                        },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "103px",
                                            left: "10px",
                                            color: "rgb(255, 255, 255)",
                                            "font-size": "13px",
                                            "font-weight": "100",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                file.name.length > 8
                                                  ? file.name.substring(0, 8) +
                                                      "..."
                                                  : file.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-actions",
                                        },
                                        [
                                          _vm.form_flag === "edit"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-upload-list__item-preview",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handlePictureCardPreview(
                                                        file
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-zoom-in",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.form_flag === "edit"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-upload-list__item-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDownload(
                                                        file
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-download",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          !_vm.disabled
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-upload-list__item-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleRemove(
                                                        file
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ])
                                  },
                                },
                              ]),
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            title: "预览",
                            visible: _vm.uploadDialog,
                            "append-to-body": "",
                            width: "40%",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.uploadDialog = $event
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "htmlContent" },
                            [
                              _c("viewer", [
                                _c("img", {
                                  staticStyle: { "max-height": "300px" },
                                  attrs: { src: _vm.dialogImageUrl },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h3", [_vm._v("派单信息")]),
              _c(
                "el-row",
                { attrs: { gutter: 60 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "预约人员", prop: "bookUser" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "bookUser_input",
                              attrs: {
                                "suffix-icon": "el-icon-user",
                                placeholder: "请选择",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.createForm.bookUser,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createForm, "bookUser", $$v)
                                },
                                expression: "createForm.bookUser",
                              },
                            },
                            _vm._l(_vm.bookUserData, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "小记", prop: "note" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 1,
                              placeholder: "请输入",
                              clearable: "",
                            },
                            model: {
                              value: _vm.createForm.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.createForm, "note", $$v)
                              },
                              expression: "createForm.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "large" },
              on: { click: _vm.createFormClose },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "large",
                loading: _vm.saveLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.saveOrder("createForm")
                },
              },
            },
            [_vm._v(" 保存 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "large",
                loading: _vm.submitLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.submitOrder("createForm")
                },
              },
            },
            [_vm._v(" 提交 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }