<template>
  <div>
    <!--检索模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            icon="el-icon-search"
            plain
            native-type="submit"
            style="margin-bottom: 15px"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            plain
            native-type="submit"
            @click="resetForm()"
          >
            重置
          </el-button>
          <el-form
            ref="query"
            :inline="true"
            :model="query"
            class="demo-form-inline my-form-class"
          >
            <el-row :gutter="20" class="myQuery">
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="updateTime">
                  <el-date-picker
                    v-model="query.updateTime"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="项目更新开始日期"
                    end-placeholder="项目更新结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="name">
                  <el-input
                    v-model.trim="query.name"
                    placeholder="请输入角色名称"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-table
            v-loading="listLoading"
            :data="list"
            :element-loading-text="elementLoadingText"
            border
            @selection-change="setSelectRows"
          >
            <el-table-column label="角色名称" prop="roleName" width="220">
              <template slot-scope="scope">
                {{ scope.row.roleName }}
              </template>
            </el-table-column>
            <el-table-column label="项目来源" prop="projectSource">
              <template slot-scope="scope">
                {{ scope.row.projectSource }}
              </template>
            </el-table-column>
            <el-table-column
              label="更新时间"
              prop="updateTime"
              width="220"
              sortable
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.updateTime || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="80"
              fixed="right"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="编辑"
                  placement="top-start"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit-outline"
                    circle
                    @click="handleEdit(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNo"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!--编辑项目-->
    <source-edit ref="sourceEdit" @fetchData="fetchData"></source-edit>
  </div>
</template>

<script>
  import sourceEdit from "./components/sourceEdit";
  import { getProjectSource } from "@/api/project/manage";
  import { returnBitTimestamp, returnDeadtimestamp } from "@/utils";
  export default {
    name: "ComprehensiveTable",
    components: {
      sourceEdit,
    },

    data() {
      return {
        list: [],
        imageList: [],
        listLoading: true,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
        background: true,
        selectRows: "",
        elementLoadingText: "正在加载...",
        query: {
          name: null,
          updateTime: null,
        },
        queryForm: {
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
          body: {
            pageNum: 1,
            pageSize: 10,
            roleName: null,
            status: null,
            updateTimeEnd: null,
            updateTimeStart: null,
          },
        },
        status: [
          {
            value: "选项1",
            label: "启用",
          },
          {
            value: "选项2",
            label: "禁用",
          },
        ],
        pickerOptions: {
          shortcuts: [
            {
              text: "最近一周",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近三个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              },
            },
          ],
        },
      };
    },

    created() {
      this.fetchData();
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      // 获取项目来源配置信息
      async fetchData() {
        this.listLoading = true;
        const { body } = await getProjectSource(this.queryForm);
        this.list = body.records;
        let listStr = [];
        let listStrA = [];
        if (this.list.length !== 0) {
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].updateTime !== null) {
              listStrA.push(this.list[i]);
            } else {
              listStr.push(this.list[i]);
            }
          }
        }
        this.list = [];
        this.list = this.list.concat(listStrA, listStr);
        this.total = body.total;
        setTimeout(() => {
          this.listLoading = false;
        }, 500);
      },
      setSelectRows(val) {
        this.selectRows = val;
      },
      // 编辑项目来源
      handleEdit(row) {
        this.$refs["sourceEdit"].showEdit(row);
      },

      handleSizeChange(val) {
        this.queryForm.body.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.queryForm.body.pageNum = val;
        this.fetchData();
      },
      // 检索
      handleQuery() {
        if (null !== this.query.updateTime) {
          this.queryForm.body.updateTimeStart = returnBitTimestamp(
            this.query.updateTime[0]
          );
          this.queryForm.body.updateTimeEnd = returnDeadtimestamp(
            this.query.updateTime[1]
          );
        } else {
          this.queryForm.body.updateTimeEnd = null;
          this.queryForm.body.updateTimeStart = null;
        }
        this.queryForm.body.pageNum = 1;
        this.queryForm.body.roleName = this.query.name;
        this.queryForm.body.status = this.query.status;
        this.fetchData();
      },
      // 重置
      resetForm() {
        this.query.updateTime = null;
        this.$refs.query.resetFields();
      },
    },
  };
</script>
<style scoped>
  .my-form-class {
    margin-bottom: -15px !important;
  }
</style>
