<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    :fullscreen="true"
    class="evidence"
  >
    <div v-if="reloadCreate" id="form-create"></div>
    <div v-if="!rule || rule.length === 0 || rule === {}">{{ content }}</div>
    <div v-if="tempSaveVisiable" class="evidence-operation">
      <el-button type="success" @click="handleTempSave">保存数据</el-button>
    </div>
  </div>
</template>

<script>
  let $f = {};
  let form_flag = true;
  import { uuid } from "@/utils/index";
  import { evidenceOper, evidenceSave } from "@/api/workplace/workDetail";
  export default {
    name: "Evidence",
    props: {
      row: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        background: true,
        reloadCreate: false,
        loading: false,
        // 实例对象
        fApi: {},
        rule: [],
        option: {
          onSubmit: function (formData) {
            alert(JSON.stringify(formData));
          },
        },
        // 保存数据按钮可见标识
        tempSaveVisiable: false,
        content: "",
        /**保存 */
        param: {
          body: {
            forms: [],
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: "",
            sourceType: "",
          },
        },
        parameter: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: "",
          },
          header: {
            currentTime: "",
            requestId: uuid(),
            sourceType: "site",
          },
        },
      };
    },
    updated: function () {
      if (form_flag) {
        const root = document.getElementById("form-create");
        // $f为表单api
        $f = window.formCreate.create(
          //表单生成规则
          this.rule,
          //组件参数配置
          {
            el: root,
            //显示表单重置按钮
            resetBtn: false,
            submitBtn: false,
            //表单提交事件
            onSubmit: function (formData) {
              //按钮进入提交状态
              alert(JSON.stringify(formData));
            },
          }
        );
      }
      form_flag = false;
    },
    methods: {
      async fetchData() {
        this.parameter.body.userNo = this.row.userNo;
        this.parameter.body.userRoleNo = this.row.userRoleNo;
        this.parameter.body.workOrderNo = this.row.workOrderNo;
        this.loading = true;
        const { body, header } = await evidenceOper(this.parameter);
        if (header.retCode === "1") {
          this.rule = body;
          form_flag = true;
          this.reloadCreate = true;
          /**只有督导兼职调查员和 调查工作台具体操作权限 */
          if (!body || body.length === 0) {
            this.content = "当前工单没有取证资料信息";
          } else {
            if (
              (this.row.workplace === "supervisor" &&
                this.row.isSurveyUser === "Y") ||
              this.row.workplace === "book_person" ||
              this.row.workplace === "investigator"
            ) {
              this.$nextTick(() => {
                $f.disabled(false);
              });
              this.tempSaveVisiable = true;
            } else {
              this.$nextTick(() => {
                $f.disabled(true);
              });
              this.tempSaveVisiable = false;
            }
          }
        } else {
          this.rule = [];
          this.content = "当前取证资料后台返回异常，请稍后尝试";
        }
        this.loading = false;
      },
      save() {
        this.param.body.workOrderNo = this.row.workOrderNo;
        $f.validate((valid) => {
          if (valid) {
            // 验证通过
            this.param.body.forms = $f.formData();
            evidenceSave(this.param).then((res) => {
              if (res.header.retCode === "1") {
                this.$baseMessage(res.header.retMessage, "success");
              } else {
                this.$message.error(`${res.header.retMessage}`);
              }
            });
          } else {
            // 验证未通过
            this.$message.error("请检查工单数据！");
            return false;
          }
        });
      },
      /** 暂存数据 */
      handleTempSave() {
        this.param.body.workOrderNo = this.row.workOrderNo;
        this.param.body.forms = $f.formData();
        evidenceSave(this.param).then((res) => {
          if (res.header.retCode === "1") {
            this.$baseMessage("数据保存成功！", "success");
          } else {
            this.$message.error(`${res.header.retMessage}`);
          }
        });
      },
      reset() {
        if (this.rule === null || this.rule === []) {
        } else {
          this.rule = [];
        }
        this.reloadCreate = false;
        form_flag = false;
      },
    },
  };
</script>

<style scoped>
  .label-title {
    width: 100%;
    display: block;
    background: #005dca;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-weight: 700;
    padding-left: 10px;
    text-align: center;
    margin: 10px 0 20px 0;
  }
  .myEl {
    margin: 40px 0;
  }
  .myEl img {
    width: 180px;
    height: 150px;
  }
  .evidence {
    min-height: 100px;
  }
  .evidence-operation {
    display: flex;
    justify-content: center;
  }
</style>
