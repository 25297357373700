<template>
  <el-dialog :title="title"
             :visible.sync="dialogFormVisible"
             :close-on-click-modal="false"
             width="600px"
             @close="close">
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-width="80px">
      <el-form-item label="项目名称"
                    prop="name">
        <el-input v-model="form.name"
                  placeholder="请输入项目名称"></el-input>
      </el-form-item>

      <el-form-item label="项目类型"
                    prop="type">
        <vab-query-form-left-panel>
          <el-input v-model="form.type"
                    placeholder="请输入项目类型"></el-input>
        </vab-query-form-left-panel>
        <vab-query-form-right-panel>
          <el-select v-model="form.property"
                     placeholder="选择属性"
                     clearable
                     disabled
                     filterable>
            <el-option v-for="item in propertyOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vab-query-form-right-panel>
      </el-form-item>
      <el-form-item label="水印选择"
                    prop="watermark">
        <el-checkbox-group v-model="form.watermark"
                           size="medium">
          <el-checkbox v-for="(item, index) in watermarkOptions"
                       :key="index"
                       :label="item.value"
                       :disabled="item.disabled">
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="项目来源"
                    prop="source">
        <el-select v-model="form.source"
                   placeholder="请选择项目来源"
                   clearable
                   disabled
                   filterable>
          <el-option v-for="item in resourceOptions"
                     :key="item.key"
                     :label="item.name"
                     :value="item.key"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary"
                 :loading="loading"
                 @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { update } from "@/api/project/manage";

export default {
  name: "NameEdit",
  data() {
    return {
      loading: false,
      form: {
        seqId: null,
        name: null,
        type: null,
        source: null,
        property: null,
        watermark: [],
      },
      parameter: {
        header: {
          currentTime: "2020-07-16 10:01:30",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
        body: {
          seqId: null,
          name: null,
          type: null,
          source: null,
          property: null,
        },
      },
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入项目名称" }],
        type: [{ required: true, trigger: "blur", message: "请输入项目类型" }],
        watermark: [
          {
            // required: true,
            type: "array",
            // message: "请至少选择一个水印",
            trigger: "change",
          },
        ],
        source: [
          { required: true, trigger: "blur", message: "请选择项目来源" },
        ],
      },
      watermarkOptions: [
        {
          label: "时间",
          value: 5,
        },
        {
          label: "地点",
          value: 3,
        },
        {
          label: "海拔",
          value: 2,
        },
        {
          label: "经纬度",
          value: 1,
        },
        {
          label: "天气",
          value: 4,
        },
      ],
      title: null,
      dialogFormVisible: false,
      resourceOptions: [
        {
          name: "香港业务流程",
          key: "process_hk",
        },
        {
          name: "本地业务流程",
          key: "process_nd",
        },
        {
          name: "BR业务流程",
          key: "process_br",
        },
      ],
      propertyOptions: [
        {
          value: 0,
          label: "调查员仅app",
        },
        {
          value: 1,
          label: "调查员允许app+web",
        },
      ],
      param: {
        header: {
          currentTime: "2020-07-16 10:01:30",
          requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
          sourceType: "site",
        },
      },
    };
  },
  methods: {
    showEdit(row) {
      this.title = "编辑项目";
      this.form = Object.assign({}, row);
      this.form.watermark = JSON.parse("[" + this.form.watermark + "]");
      this.parameter.body.seqId = this.form.seqId;
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.parameter.body.name = this.form.name;
          this.parameter.body.type = this.form.type;
          this.parameter.body.source = this.form.source;
          this.parameter.body.property = this.form.property;
          this.parameter.body.watermark = this.form.watermark.toString();
          const { header } = await update(this.parameter);
          if (header.retCode === "1") {
            this.$baseMessage(header.retMessage, "success");
          } else {
            this.$baseMessage(header.retMessage, "error");
          }
          this.$refs["form"].resetFields();
          this.dialogFormVisible = false;
          this.$emit("fetchData");
          this.form = this.$options.data().form;
          this.loading = false;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-select,
.el-cascader {
  width: 100%;
}
</style>
