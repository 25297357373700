import { render, staticRenderFns } from "./add.vue?vue&type=template&id=632b1d97&scoped=true&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"
import style0 from "./add.vue?vue&type=style&index=0&id=632b1d97&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632b1d97",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/site-front-pipline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('632b1d97')) {
      api.createRecord('632b1d97', component.options)
    } else {
      api.reload('632b1d97', component.options)
    }
    module.hot.accept("./add.vue?vue&type=template&id=632b1d97&scoped=true&", function () {
      api.rerender('632b1d97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/setting/components/add.vue"
export default component.exports