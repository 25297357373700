var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "imgDialog",
      attrs: { visible: _vm.dialogImgVisible, "append-to-body": "" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogImgVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { height: "350px" },
          attrs: { "tab-position": _vm.tabPosition, "destroy-on-close": true },
          model: {
            value: _vm.activeTabsValue,
            callback: function ($$v) {
              _vm.activeTabsValue = $$v
            },
            expression: "activeTabsValue",
          },
        },
        _vm._l(_vm.photoData, function (photoItem, photoIndex) {
          return _c(
            "el-tab-pane",
            {
              key: photoIndex,
              attrs: {
                name: photoItem.name,
                stretch: true,
                label:
                  photoItem.name.length > 12
                    ? photoItem.name.substring(0, 12) + "..."
                    : photoItem.name,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-carousel",
                        {
                          ref: "imgCarousel",
                          refInFor: true,
                          attrs: {
                            trigger: "click",
                            height: "350px",
                            "initial-index": _vm.pictureIndex,
                            "indicator-position": "none",
                            autoplay: false,
                          },
                        },
                        _vm._l(
                          photoItem.siteMediaResource,
                          function (subItem, subIndex) {
                            return _c(
                              "el-carousel-item",
                              { key: subItem, attrs: { name: subIndex } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: subItem.name,
                                              placement: "top",
                                              effect: "light",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  width: "95%",
                                                  margin: "0 auto",
                                                  "text-align": "center",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      subItem.name +
                                                        "." +
                                                        subItem.path
                                                          .split("?")[0]
                                                          .substring(
                                                            subItem.path.lastIndexOf(
                                                              "."
                                                            ) + 1
                                                          )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.judgeFileType(subItem.path)
                                          ? _c(
                                              "div",
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "max-height": "270px",
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.ossPath +
                                                      subItem.path,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleViewerShow(
                                                        subIndex,
                                                        photoItem.siteMediaResource
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "viewer",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: false,
                                                        expression: "false",
                                                      },
                                                    ],
                                                    attrs: {
                                                      images: _vm.imagesList,
                                                    },
                                                    on: { inited: _vm.inited },
                                                  },
                                                  _vm._l(
                                                    _vm.imagesList,
                                                    function (item) {
                                                      return _c("img", {
                                                        key: item,
                                                        staticStyle: {
                                                          "max-height": "270px",
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.ossPath +
                                                            item.path,
                                                          alt:
                                                            item.name +
                                                            "." +
                                                            item.path.substring(
                                                              item.path.lastIndexOf(
                                                                "."
                                                              ) + 1
                                                            ),
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            )
                                          : _c("img", {
                                              staticStyle: {
                                                width: "160px",
                                                height: "160px",
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                src: require("@/icon/pic.svg"),
                                                fit: _vm.fit,
                                                title: photoItem.name,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDownload(
                                                    subItem
                                                  )
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }