export default {
  zh: {
    hint: "点击，或拖动图片至此处",
    loading: "正在上传……",
    noSupported: "浏览器不支持该功能，请使用IE10以上或其他现在浏览器！",
    success: "上传成功",
    fail: "图片上传失败",
    preview: "头像预览",
    btn: {
      off: "取消",
      close: "关闭",
      back: "上一步",
      save: "保存",
    },
    error: {
      onlyImg: "仅限图片格式",
      outOfSize: "单文件大小不能超过 ",
      lowestPx: "图片最低像素为（宽*高）：",
    },
  },
  "zh-tw": {
    hint: "點擊，或拖動圖片至此處",
    loading: "正在上傳……",
    noSupported: "瀏覽器不支持該功能，請使用IE10以上或其他現代瀏覽器！",
    success: "上傳成功",
    fail: "圖片上傳失敗",
    preview: "頭像預覽",
    btn: {
      off: "取消",
      close: "關閉",
      back: "上一步",
      save: "保存",
    },
    error: {
      onlyImg: "僅限圖片格式",
      outOfSize: "單文件大小不能超過 ",
      lowestPx: "圖片最低像素為（寬*高）：",
    },
  },
  en: {
    hint: "Click or drag the file here to upload",
    loading: "Uploading…",
    noSupported: "Browser is not supported, please use IE10+ or other browsers",
    success: "Upload success",
    fail: "Upload failed",
    preview: "Preview",
    btn: {
      off: "Cancel",
      close: "Close",
      back: "Back",
      save: "Save",
    },
    error: {
      onlyImg: "Image only",
      outOfSize: "Image exceeds size limit: ",
      lowestPx: "Image's size is too low. Expected at least: ",
    },
  },
  ro: {
    hint: "Atinge sau trage fișierul aici",
    loading: "Se încarcă",
    noSupported:
      "Browser-ul tău nu suportă acest feature. Te rugăm încearcă cu alt browser.",
    success: "S-a încărcat cu succes",
    fail: "A apărut o problemă la încărcare",
    preview: "Previzualizează",

    btn: {
      off: "Anulează",
      close: "Închide",
      back: "Înapoi",
      save: "Salvează",
    },

    error: {
      onlyImg: "Doar imagini",
      outOfSize: "Imaginea depășește limita de: ",
      loewstPx: "Imaginea este prea mică; Minim: ",
    },
  },
  ru: {
    hint: "Нажмите, или перетащите файл в это окно",
    loading: "Загружаю……",
    noSupported:
      "Ваш браузер не поддерживается, пожалуйста, используйте IE10 + или другие браузеры",
    success: "Загрузка выполнена успешно",
    fail: "Ошибка загрузки",
    preview: "Предпросмотр",
    btn: {
      off: "Отменить",
      close: "Закрыть",
      back: "Назад",
      save: "Сохранить",
    },
    error: {
      onlyImg: "Только изображения",
      outOfSize: "Изображение превышает предельный размер: ",
      lowestPx: "Минимальный размер изображения: ",
    },
  },
  "pt-br": {
    hint: "Clique ou arraste o arquivo aqui para carregar",
    loading: "Carregando…",
    noSupported: "Browser não suportado, use o IE10+ ou outro browser",
    success: "Sucesso ao carregar imagem",
    fail: "Falha ao carregar imagem",
    preview: "Pré-visualizar",
    btn: {
      off: "Cancelar",
      close: "Fechar",
      back: "Voltar",
      save: "Salvar",
    },
    error: {
      onlyImg: "Apenas imagens",
      outOfSize: "A imagem excede o limite de tamanho: ",
      lowestPx: "O tamanho da imagem é muito pequeno. Tamanho mínimo: ",
    },
  },
  fr: {
    hint: "Cliquez ou glissez le fichier ici.",
    loading: "Téléchargement…",
    noSupported:
      "Votre navigateur n'est pas supporté. Utilisez IE10 + ou un autre navigateur s'il vous plaît.",
    success: "Téléchargement réussit",
    fail: "Téléchargement echoué",
    preview: "Aperçu",
    btn: {
      off: "Annuler",
      close: "Fermer",
      back: "Retour",
      save: "Enregistrer",
    },
    error: {
      onlyImg: "Image uniquement",
      outOfSize: "L'image sélectionnée dépasse la taille maximum: ",
      lowestPx: "L'image sélectionnée est trop petite. Dimensions attendues: ",
    },
  },
  nl: {
    hint: "Klik hier of sleep een afbeelding in dit vlak",
    loading: "Uploaden…",
    noSupported:
      "Je browser wordt helaas niet ondersteund. Gebruik IE10+ of een andere browser.",
    success: "Upload succesvol",
    fail: "Upload mislukt",
    preview: "Voorbeeld",
    btn: {
      off: "Annuleren",
      close: "Sluiten",
      back: "Terug",
      save: "Opslaan",
    },
    error: {
      onlyImg: "Alleen afbeeldingen",
      outOfSize: "De afbeelding is groter dan: ",
      lowestPx: "De afbeelding is te klein! Minimale afmetingen: ",
    },
  },
  tr: {
    hint: "Tıkla veya yüklemek istediğini buraya sürükle",
    loading: "Yükleniyor…",
    noSupported:
      "Tarayıcı desteklenmiyor, lütfen IE10+ veya farklı tarayıcı kullanın",
    success: "Yükleme başarılı",
    fail: "Yüklemede hata oluştu",
    preview: "Önizle",
    btn: {
      off: "İptal",
      close: "Kapat",
      back: "Geri",
      save: "Kaydet",
    },
    error: {
      onlyImg: "Sadece resim",
      outOfSize: "Resim yükleme limitini aşıyor: ",
      lowestPx: "Resmin boyutu çok küçük. En az olması gereken: ",
    },
  },
  "es-MX": {
    hint: "Selecciona o arrastra una imagen",
    loading: "Subiendo...",
    noSupported:
      "Tu navegador no es soportado, porfavor usa IE10+ u otros navegadores mas recientes",
    success: "Subido exitosamente",
    fail: "Sucedió un error",
    preview: "Vista previa",
    btn: {
      off: "Cancelar",
      close: "Cerrar",
      back: "Atras",
      save: "Guardar",
    },
    error: {
      onlyImg: "Unicamente imagenes",
      outOfSize: "La imagen excede el tamaño maximo:",
      lowestPx: "La imagen es demasiado pequeño. Se espera por lo menos:",
    },
  },
  de: {
    hint: "Klick hier oder zieh eine Datei hier rein zum Hochladen",
    loading: "Hochladen…",
    noSupported:
      "Browser wird nicht unterstützt, bitte verwende IE10+ oder andere Browser",
    success: "Upload erfolgreich",
    fail: "Upload fehlgeschlagen",
    preview: "Vorschau",
    btn: {
      off: "Abbrechen",
      close: "Schließen",
      back: "Zurück",
      save: "Speichern",
    },
    error: {
      onlyImg: "Nur Bilder",
      outOfSize: "Das Bild ist zu groß: ",
      lowestPx: "Das Bild ist zu klein. Mindestens: ",
    },
  },
  ja: {
    hint: "クリック・ドラッグしてファイルをアップロード",
    loading: "アップロード中...",
    noSupported:
      "このブラウザは対応されていません。IE10+かその他の主要ブラウザをお使いください。",
    success: "アップロード成功",
    fail: "アップロード失敗",
    preview: "プレビュー",
    btn: {
      off: "キャンセル",
      close: "閉じる",
      back: "戻る",
      save: "保存",
    },
    error: {
      onlyImg: "画像のみ",
      outOfSize: "画像サイズが上限を超えています。上限: ",
      lowestPx: "画像が小さすぎます。最小サイズ: ",
    },
  },
};
