// Kyle API 用户工作台 -> 工单二审 -> 已处理
import request from "@/utils/request";

// 查询用户工作台待处理列表
export function getTodo(data) {
  return request({
    url: "/user/queryPendingList",
    method: "post",
    data,
  });
}
// 查询用户工作台已处理列表
export function getDone(data) {
  return request({
    url: "/user/queryProcessedList",
    method: "post",
    data,
  });
}

// 用户审核
export function submit(data) {
  return request({
    url: "/user/verify",
    method: "post",
    data,
  });
}

// 用户-人员推荐 工单退回
export function fallBack(data) {
  return request({
    url: "/user/fallback",
    method: "post",
    data,
  });
}

// summary信息
export function summaryInfo(data) {
  return request({
    url: "/user/querySummaryInfo",
    method: "post",
    data,
  });
}

// 工单信息
export function workOrderInfo(data) {
  return request({
    url: "/user/queryWorkOrderInfo",
    method: "post",
    data,
  });
}

// 单独添加小记
export function saveNote(data) {
  return request({
    url: "/user/note",
    method: "post",
    data,
  });
}
