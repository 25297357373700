// Kyle API 工作台 -> 新建工单
import request from "@/utils/request";

// 批量上传模板
export function batchCreate(data) {
  return request({
    url: "/plWorkOrder/batchCreate",
    method: "post",
    data,
  });
}
