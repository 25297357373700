<template>
  <el-tabs type="border-card" value="todo" @tab-click="handleTriggerTab">
    <el-tab-pane name="todo">
      <span slot="label">
        <el-badge :value="todoVal > 99 ? '99⁺' : todoVal" class="item todo">
          待处理
        </el-badge>
      </span>

      <todo ref="todoChild" @child-todo="todoEvent"></todo>
    </el-tab-pane>
    <el-tab-pane name="done">
      <span slot="label">
        <el-badge :value="doneVal > 99 ? '99⁺' : doneVal" class="item done">
          已处理
        </el-badge>
      </span>
      <done ref="doneChild" :done-val.sync="doneVal"></done>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import todo from "./components/todo";
  import done from "./components/done";
  export default {
    name: "IndexVue",
    components: { todo, done },
    data() {
      return {
        todoVal: "",
        doneVal: "",
      };
    },
    mounted() {
      this.handleTriggerTab({ name: "todo" });
    },
    methods: {
      todoEvent(refresh_data) {
        this.todoVal = refresh_data.todo_total;
        if (refresh_data.isDone) {
          this.$nextTick(() => {
            this.$refs.doneChild.fetchData();
          });
        }
      },
      /** tabs切换 */
      handleTriggerTab(tab) {
        if (tab.name === "done") {
          this.$refs.doneChild.handleInitData();
        } else {
          this.$refs.todoChild.handleInitData();
        }
      },
    },
  };
</script>

<style>
  .todo sup {
    background: none;
    position: absolute !important;
    top: 10px !important;
    color: #ff0000;
    height: 0;
    line-height: 0;
    border: none;
    padding-left: 10px;
  }
  .done sup {
    background: none;
    position: absolute !important;
    top: 10px !important;
    color: #0d980d;
    height: 0;
    line-height: 0;
    border: none;
    padding-left: 10px;
  }
</style>
