<!-- 图片预览Dialog-->
<template>
  <!-- 图片预览 -->
  <el-dialog :visible.sync="dialogImgVisible" append-to-body class="imgDialog">
    <el-carousel
      ref="imgCarousel"
      trigger="click"
      height="350px"
      :initial-index="pictureIndex"
      indicator-position="none"
      :autoplay="false"
    >
      <el-carousel-item
        v-for="(subItem, subIndex) in photoData"
        :key="subIndex"
        :name="subIndex"
        :initial-index="pictureIndex"
      >
        <el-row>
          <el-col style="text-align: center">
            <viewer v-if="judgeFileType(subItem.path)">
              <img :src="ossPath + subItem.path" style="max-height: 270px" />
            </viewer>
            <!-- 图片 ——>不可预览 -->
            <img
              v-else
              style="width: 160px; height: 160px; cursor: pointer"
              src="@/icon/pic.svg"
              :fit="fit"
              @click="handleDownload(subItem)"
            />
          </el-col>
        </el-row>
      </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
  import { downloadFile, isExistOSS } from "@/utils/index";
  export default {
    name: "PhotoView",
    data() {
      return {
        photoData: "",
        tabPosition: "left",
        dialogImgVisible: false,
        fit: "fill",
        activeTabsValue: "2",
        img_index: 0,
        photo_index: 0,
        tab_name: "",
        ossPath: "",
        OSSClient: {},
        pictureIndex: null,
      };
    },
    created() {},
    methods: {
      /** 判断文件类型 */
      judgeFileType(filePath) {
        /** 可预览的文件类型 */
        const fileType = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
        const itemType = filePath.substring(filePath.lastIndexOf(".") + 1);
        return fileType.includes(itemType);
      },
      /** 点击下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
      showView() {
        this.dialogImgVisible = true;
      },
      bindData(photoData, tab_name, img_index, ossPath, OSSClient) {
        this.ossPath = ossPath;
        this.OSSClient = OSSClient;
        this.pictureIndex = img_index;
        this.photoData = photoData;
        this.$nextTick(() => {
          this.$refs["imgCarousel"].setActiveItem(this.pictureIndex);
        });
      },
    },
  };
</script>

<style scoped>
  .imgDialog /deep/.el-dialog__header {
    padding: 0 20px;
  }
  .imgDialog /deep/.el-dialog__body {
    padding-top: 40px;
  }
  .el-carousel__item.is-animating {
    transition: none !important;
  }
</style>
<style>
  .viewer-container {
    z-index: 9999 !important;
  }
</style>
