var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _vm.user
      ? _c(
          "div",
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 6, xs: 24 } },
                  [_c("user-card", { attrs: { user: _vm.user } })],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 18, xs: 24 } },
                  [
                    _c(
                      "el-card",
                      [
                        _c(
                          "el-tabs",
                          {
                            attrs: { "tab-position": _vm.tabPosition },
                            model: {
                              value: _vm.activeTab,
                              callback: function ($$v) {
                                _vm.activeTab = $$v
                              },
                              expression: "activeTab",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "基本信息", name: "account" } },
                              [_c("account", { attrs: { user: _vm.user } })],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "安全设置", name: "safe" } },
                              [_c("safe")],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }