var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        width: "573px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("上传附件")]),
      _c(
        "el-steps",
        {
          staticStyle: { margin: "30px -40%" },
          attrs: { active: 2, "align-center": "" },
        },
        [
          _c("el-step", {
            attrs: { title: "上传工单", icon: "el-icon-success" },
          }),
          _c(
            "el-step",
            { attrs: { title: "上传附件" } },
            [_c("vab-remix-icon", { attrs: { "icon-class": "number-2" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { attrs: { align: "middle", type: "flex", gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-weight": "600",
                      "font-size": "15px",
                      "margin-bottom": "10px",
                    },
                  },
                  [_vm._v(" 请您上传资源 ")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "upload-demo",
                        attrs: {
                          action: _vm.endpoinxBak,
                          "on-error": _vm.handleError,
                          "on-remove": _vm.handleRemove,
                          "on-change": _vm.handleFileChange,
                          "http-request": _vm.batch,
                          multiple: "",
                          "auto-upload": false,
                          "file-list": _vm.fileList,
                          drag: "",
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _vm._v(" 将文件拖到此处，或 "),
                          _c("em", [_vm._v("点击上传")]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [_vm._v("附件大小不得大于 1G")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { align: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      ref: "$complete",
                      attrs: {
                        loading: _vm.subLoading,
                        type: "primary",
                        disabled: _vm.submitDisabled,
                      },
                      on: { click: _vm.completeAnnex },
                    },
                    [_vm._v(" 完成 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }