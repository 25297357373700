<template>
  <el-form ref="form" v-loading="listLoading" :model="form" :rules="rules">
    <el-form-item label="账户名称" prop="name">
      <el-input v-model.trim="userName" disabled />
    </el-form-item>
    <el-form-item label="真实姓名" prop="nickName">
      <el-input v-model.trim="form.nickName" />
    </el-form-item>
    <el-form-item label="联系电话" prop="phoneNumber">
      <el-input
        v-model.number="form.phoneNumber"
        oninput="if(value.length > 11)value = value.slice(0, 11)"
        type="number"
      />
    </el-form-item>
    <el-form-item label="Email" prop="email">
      <el-input v-model.trim="form.email" />
    </el-form-item>
    <el-form-item label="入职时间" prop="inductionTime">
      <el-date-picker
        v-model="form.inductionTime"
        type="date"
        placeholder="请选择日期"
        style="width: 100%"
        required
        clearable
        disabled
      ></el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :loading="loading" @click="submit">
        确定
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { mapGetters } from "vuex";
  import { submit, getUser } from "@/api/user";
  import { parseTime } from "@/utils";
  export default {
    props: {
      user: {
        type: Object,
        default: () => {
          return {
            email: "",
          };
        },
      },
    },
    data() {
      return {
        loading: false,
        form: {
          nickName: "",
          phoneNumber: "",
          email: "",
          inductionTime: "",
        },
        listLoading: true,
        param: {
          body: {
            email: "",
            inductionTime: "",
            nickName: "",
            phoneNumber: "",
            userNo: "",
          },
          header: {
            currentTime: "2020-07-16 10:01:30",
            requestId: "2b81a030-bf6c-11ea-a387-f8a2d6d9d88d",
            sourceType: "site",
          },
        },
        rules: {
          phoneNumber: [
            {
              trigger: "blur",
              validator: (rule, value, callback) => {
                if ((this.form.phoneNumber + "").length !== 11) {
                  return callback(new Error("手机号码长度不对"));
                } else {
                  callback();
                }
              },
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters({
        userName: "user/userName",
        userNo: "user/userNo",
        avatar: "user/avatar",
      }),
    },
    created() {
      this.fetchData();
    },
    methods: {
      // 查询用户信息
      async fetchData() {
        this.listLoading = true;
        this.param.body.nickName = this.form.nickName;
        this.param.body.phoneNumber = this.form.phoneNumber;
        this.param.body.email = this.form.email;
        this.param.body.inductionTime = this.form.inductionTime;
        this.param.body.userNo = this.userNo;

        const { body } = await getUser(this.param);

        this.form.nickName = body[0].nickName;
        this.form.phoneNumber = body[0].phonenumber;
        this.form.email = body[0].email;
        this.form.inductionTime = body[0].createTime;
        this.listLoading = false;
      },
      async submit() {
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            this.loading = true;
            this.param.body.nickName = this.form.nickName;
            this.param.body.phoneNumber = this.form.phoneNumber;
            this.param.body.email = this.form.email;
            this.param.body.inductionTime =
              this.form.inductionTime === null
                ? parseTime(new Date())
                : parseTime(this.form.inductionTime);
            this.param.body.userNo = this.userNo;
            const { header } = await submit(this.param);
            if ("1" === header.retCode) {
              this.$baseMessage(header.retMessage, "success");
              await this.fetchData();
            } else {
              this.$baseMessage(header.retMessage, "error");
            }
            this.loading = false;
          }
        });
      },
    },
  };
</script>
