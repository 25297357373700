var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "myDialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "60%",
            fullscreen: _vm.flag,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.close(0)
            },
          },
        },
        [
          _c("el-backtop", { attrs: { target: ".myDialog" } }),
          _c("i", {
            class: _vm.fullIcon,
            staticStyle: {
              position: "absolute",
              top: "24px",
              right: "41px",
              cursor: "pointer",
            },
            attrs: { title: _vm.fullTitle },
            on: { click: _vm.fullOrClose },
          }),
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _vm._v(_vm._s(_vm.companyName)),
          ]),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "edit_title",
                  attrs: { label: "编辑预约", name: "detail" },
                },
                [
                  _c("order-process", { attrs: { param: _vm.param } }),
                  _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "workOrderNo",
                          label: "工单编号",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.workOrderNo || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brWorkNo",
                          label: "订单编号",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.brWorkNo || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reportType",
                          label: "报告类型",
                          "class-name":
                            _vm.brChange("reportTypeFlag") +
                            " " +
                            _vm.brChange("subReportTypeFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.reportType || "-") +
                                    " " +
                                    _vm._s(scope.row.subReportType ? "/" : "") +
                                    " " +
                                    _vm._s(scope.row.subReportType) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "projectSource",
                          label: "项目来源",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getProjectSource(
                                        scope.row.projectSource
                                      ) || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubject",
                          label: "目标主体",
                          "class-name": _vm.brChange("subjectFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetSubject || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetAddr1",
                          label: "目标地址1",
                          "class-name": _vm.brChange("subjectAddressFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetAddr1 || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetAddr2",
                          label: "目标地址2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetAddr2 || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetContact",
                          label: "目标联系人",
                          "class-name": _vm.brChange("subjectLinkNameFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetContact || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "contactNumber",
                          label: "联系电话",
                          "class-name": _vm.brChange("subjectMobileFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.contactNumber || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "duty",
                          label: "职务",
                          "class-name": _vm.brChange("dutyFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.duty || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderTime",
                          label: "下单时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.orderTime || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "innerDeadline",
                          label: "内部截止日期",
                          align: "center",
                          formatter: _vm.eighthBitTime,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "clientAbbr",
                          label: "委托方简称",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.clientAbbr || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reportTimeLimit",
                          label: "报告时限",
                          "class-name": _vm.brChange("reportTimeLimitFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.reportTimeLimit || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark1",
                          label: "备注1",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark1 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark2",
                          label: "备注2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark2 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark3",
                          label: "备注2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark3 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column"),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("附加信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectVisitDate",
                          label: "预计走访日期",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.expectVisitDate || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectServeyDay",
                          label: "预计所需调查天数",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.expectServeyDay || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "needUser",
                          label: "所需人手",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.needUser || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectHour",
                          label: "预计需时（小时）",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.expectHour || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "registerAddress",
                          label: "注册地址",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.registerAddress || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubjectRegStatus",
                          label: "目标主体注册状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.targetSubjectRegStatus || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("特别信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "plSpecialMsg",
                          label: "PL特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.plSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bookSpecialMsg",
                          label: "预约特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.bookSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "monitorSpecialMsg",
                          label: "督导特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.monitorSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("操作信息")]),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.operationInfo,
                        stripe: "",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "operator",
                          label: "操作者",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.operator || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "role", label: "角色", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.role || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "operAction",
                          label: "操作状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.operAction || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "操作时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.createTime || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark",
                          label: "备注",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("预约工单")]),
                  _c(
                    "el-form",
                    {
                      ref: "orderForm",
                      attrs: { model: _vm.orderForm, rules: _vm.rules },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 60 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _vm.tableData.length &&
                              _vm.tableData[0].projectSource &&
                              _vm.tableData[0].projectSource === "process_br"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "预约走访时间",
                                        prop: "bookVisitTimeTmp",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          placeholder: "选择预约走访时间",
                                          format: "yyyy-MM-dd HH:mm",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.orderForm.bookVisitTimeTmp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderForm,
                                              "bookVisitTimeTmp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderForm.bookVisitTimeTmp",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-form-item",
                                    { attrs: { label: "预约走访时间" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          placeholder: "选择预约走访时间",
                                          format: "yyyy-MM-dd HH:mm",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.orderForm.bookVisitTimeTmp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.orderForm,
                                              "bookVisitTimeTmp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "orderForm.bookVisitTimeTmp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否联系目标",
                                    prop: "contactTargetFlag",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择是否联系目标",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.orderForm.contactTargetFlag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.orderForm,
                                            "contactTargetFlag",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "orderForm.contactTargetFlag",
                                      },
                                    },
                                    _vm._l(
                                      _vm.contactTargetFlagOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否待定",
                                    prop: "pendingFlag",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择是否待定",
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.orderForm.pendingFlag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.orderForm,
                                            "pendingFlag",
                                            $$v
                                          )
                                        },
                                        expression: "orderForm.pendingFlag",
                                      },
                                    },
                                    _vm._l(
                                      _vm.pendingFlagOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "兼职调查人员",
                                    prop: "partSurveyUser",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.orderForm.partSurveyUser,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderForm,
                                          "partSurveyUser",
                                          $$v
                                        )
                                      },
                                      expression: "orderForm.partSurveyUser",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "小记", prop: "note" } },
                                [
                                  _c("el-input", {
                                    staticClass: "my-textarea",
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入小记",
                                    },
                                    model: {
                                      value: _vm.orderForm.note,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.orderForm, "note", $$v)
                                      },
                                      expression: "orderForm.note",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "特别留言",
                                    prop: "bookSpecialMsg",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "my-textarea",
                                    attrs: {
                                      type: "textarea",
                                      rows: "2",
                                      placeholder: "请输入特别留言",
                                    },
                                    model: {
                                      value: _vm.orderForm.bookSpecialMsg,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderForm,
                                          "bookSpecialMsg",
                                          $$v
                                        )
                                      },
                                      expression: "orderForm.bookSpecialMsg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "取证资料", name: "evidence" } },
                [_c("evidence", { ref: "evidence", attrs: { row: _vm.row } })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "拍摄照片" } },
                [
                  _c("photo", {
                    ref: "photo",
                    attrs: { type: "50", row: _vm.row },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "拍摄录像" } },
                [
                  _c("screenVideo", {
                    ref: "screenVideo",
                    attrs: { type: "51", row: _vm.row },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "扫描文件", name: "scanFile" } },
                [
                  _c("scanFile", {
                    ref: "scanFile",
                    attrs: { type: "52", row: _vm.row },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "录制音频" } },
                [
                  _c("screenAudio", {
                    ref: "screenAudio",
                    attrs: { type: "53", row: _vm.row },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "其他文件", name: "telRecord" } },
                [
                  _c("telRecord", {
                    ref: "telRecord",
                    attrs: { type: "54", row: _vm.row },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "打卡记录" } },
                [
                  _c("clockIn", {
                    ref: "clockIn",
                    attrs: { type: "55", row: _vm.row },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "会议信息" } },
                [
                  _c("meeting", {
                    ref: "meeting",
                    attrs: { type: "57", row: _vm.row },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "查看小记", name: "remark" } },
                [_c("remark", { ref: "remark", attrs: { param: _vm.param } })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "退回记录", name: "back" } },
                [
                  _c("back", {
                    ref: "back",
                    attrs: { order: _vm.workOrderNo },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "企业信息", name: "company" } },
                [_c("company", { ref: "company", attrs: { row: _vm.row } })],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.activeName === "detail"
                ? _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v(" 取 消 "),
                  ])
                : _vm._e(),
              _vm.activeName === "evidence"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.saveEnvidence },
                    },
                    [_vm._v(" 保存 ")]
                  )
                : _vm._e(),
              _vm.activeName === "detail"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", loading: _vm.loading },
                      on: { click: _vm.save },
                    },
                    [_vm._v(" 保 存 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submit },
                },
                [_vm._v(" 提 交 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("submit", { ref: "submit", on: { close: _vm.close } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }