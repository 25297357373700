var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "never" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticClass: "card-title" }, [
                        _vm._v("待处理事务"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 2 } },
                    _vm._l(_vm.todoArr, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 2 } },
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: { underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTrigger(
                                        item.workOderStatus
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.workOderStatusValue) + " "
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "tips" }, [
                                _vm._v(_vm._s(item.total)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card card-top-tools",
                  attrs: { shadow: "never" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c("vab-query-form-left-panel", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "card-title" }, [
                          _vm._v("工单统计"),
                        ]),
                      ]),
                      _c(
                        "vab-query-form-right-panel",
                        { attrs: { span: 21 } },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      on: { change: _vm.handleChart },
                                      model: {
                                        value: _vm.tabPosition,
                                        callback: function ($$v) {
                                          _vm.tabPosition = $$v
                                        },
                                        expression: "tabPosition",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "day" } },
                                        [_vm._v("本日")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "week" } },
                                        [_vm._v("本周")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "month" } },
                                        [_vm._v("本月")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "300px",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      align: "right",
                                      type: "daterange",
                                      "unlink-panels": "",
                                      clearable: "",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { change: _vm.dateChange },
                                    model: {
                                      value: _vm.listQuery.correctDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "correctDate",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.correctDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { align: "middle", type: "flex" },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c("el-col", [
                            _c("div", { staticClass: "time-record" }, [
                              _c("p", [_vm._v("本月工单总数")]),
                              _c("h3", [_vm._v(_vm._s(_vm.monthTotal))]),
                              _vm.contrastMonth !== "上月没有工单"
                                ? _c("div", [
                                    _vm.contrastMonth[0] === "-"
                                      ? _c(
                                          "div",
                                          [
                                            _c("vab-icon", {
                                              attrs: {
                                                icon: ["fas", "caret-down"],
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { staticClass: "order-down" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.contrastMonth.substring(
                                                      1
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("span", [_vm._v(" 同比上月")]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("vab-icon", {
                                              attrs: {
                                                icon: ["fas", "caret-up"],
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { staticClass: "order-up" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.contrastMonth)
                                                ),
                                              ]
                                            ),
                                            _c("span", [_vm._v(" 同比上月")]),
                                          ],
                                          1
                                        ),
                                  ])
                                : _c("div", [
                                    _c("span", [_vm._v(" 上月没有工单")]),
                                  ]),
                            ]),
                          ]),
                          _c("el-col", [
                            _c("div", { staticClass: "time-record" }, [
                              _c("p", [_vm._v("本周工单总数")]),
                              _c("h3", [_vm._v(_vm._s(_vm.weekTotal))]),
                              _vm.contrastWeek !== "上周没有工单"
                                ? _c("div", [
                                    _vm.contrastWeek[0] === "-"
                                      ? _c(
                                          "div",
                                          [
                                            _c("vab-icon", {
                                              attrs: {
                                                icon: ["fas", "caret-down"],
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { staticClass: "order-down" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.contrastWeek.substring(
                                                      1
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("span", [_vm._v(" 同比上周")]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("vab-icon", {
                                              attrs: {
                                                icon: ["fas", "caret-up"],
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { staticClass: "order-up" },
                                              [_vm._v(_vm._s(_vm.contrastWeek))]
                                            ),
                                            _c("span", [_vm._v(" 同比上周")]),
                                          ],
                                          1
                                        ),
                                  ])
                                : _c("div", [
                                    _c("span", [_vm._v(" 上周没有工单")]),
                                  ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c("div", { attrs: { id: "container" } }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }