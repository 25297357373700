<template>
  <div class="vlabeledit">
    <div v-if="!edit" class="vlabelLabel" @dblclick.stop.prevent="onLabelClick">
      <a :title="label" class="mediaLabel">{{ vlabel }}</a>
    </div>
    <el-input
      v-if="edit"
      ref="labeledit"
      v-model.trim="label"
      type="text"
      :placeholder="vplaceholder"
      class="vlabelInput"
      maxlength="50"
      @blur="updateTextBlur"
      @keyup.enter.native="updateTextEnter"
    />
  </div>
</template>
<script>
  export default {
    name: "LabelEdit",
    // eslint-disable-next-line vue/require-prop-types
    props: ["text", "placeholder", "ableChange"],
    data: function () {
      return {
        edit: false, // define whether it is in edit mode or not
        label: "", // v-bind data model for input text
        empty: "请输入名称", // empty place holder .. replace with your own localization for default
      };
    },
    computed: {
      vplaceholder: function () {
        // check if the placeholder is undefined or empty
        if (this.placeholder == undefined || this.placeholder == "") {
          // if it is empty or undefined, pre-populate with built-in place holder text
          return this.empty;
        } else {
          return this.placeholder;
        }
      },
      vlabel: function () {
        // after text has been updated
        // return text value or place holder value depends on value of the text
        if (this.text == undefined || this.text == "") {
          return this.vplaceholder;
        } else {
          return this.label.length > 10
            ? this.label.substring(0, 10) + "..."
            : this.label;
        }
      },
    },
    watch: {
      text: function (value) {
        if (value == "" || value == undefined) {
          this.label = this.vplaceholder;
        }
      },
    },
    mounted: function () {
      // initiate the label view
      this.initText();
    },
    updated: function () {
      var ed = this.$refs.labeledit;
      if (ed != null) {
        ed.focus();
      }
    }, // parent should provide :text or :placeholder
    methods: {
      initText: function () {
        if (this.text == "" || this.text == undefined) {
          this.label = this.vlabel;
        } else {
          this.label = this.text;
        }
      },
      // when the div label got clicked and trigger the text box
      onLabelClick: function () {
        if (this.ableChange == "" || this.ableChange == undefined) {
          this.edit = false;
        }
        /**父级传值 ok的时候可以编辑 */
        if (this.ableChange === true) {
          this.edit = true;
          this.label = this.text;
        } else {
          this.edit = false;
        }
      },
      // trigger when textbox got lost focus
      updateTextBlur: function () {
        // update the edit mode to false .. display div label text
        this.edit = false;
        // emit text updated callback
        this.$emit("text-updated-blur", this.label);
      },
      updateTextEnter: function () {
        this.edit = false;
        this.$emit("text-updated-enter", this.label);
      },
    },
  };
</script>
<style>
  .mediaLabel {
    color: #000;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none;
    background: none;
    text-decoration: none;
  }
</style>
