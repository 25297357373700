// 系统模板相关API
import request from "@/utils/request";

// 根据项目查询数据配置树
export function getSystemData(data) {
  return request({
    url: "/form/getSysTemplateForm",
    method: "post",
    data,
  });
}

// 模板配置新增接口
export function addSystemData(data) {
  return request({
    url: "/form/createSysTemplateForm",
    method: "post",
    data,
  });
}

// 模板配置删除接口
export function delSystemData(data) {
  return request({
    url: "/form/deleteSysTemplateForm",
    method: "post",
    data,
  });
}

// 模板配置修改接口
export function updateSystemData(data) {
  return request({
    url: "/form/updateSysTemplateForm",
    method: "post",
    data,
  });
}

// 模板配置复制接口
export function copySystemData(data) {
  return request({
    url: "/form/copySysTemplateForm",
    method: "post",
    data,
  });
}

// 模板配置复制接口
export function sortSystemData(data) {
  return request({
    url: "/form/sortSysTemplateForm",
    method: "post",
    data,
  });
}
