import { JSEncrypt } from "jsencrypt";

// 密钥对生成 http://web.chacuo.net/netrsakeypair
/** kyle add */
const publicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCkqXk0QoF4jQm+ApifXxJAuCfy89OhMxna99GVw0CQhbQr5g0gvjAfgA+k7WU0w+60HfBIaV2nVSh0MSuAKFQjtNQxNDr0n5BD4UxDwaw1hy9V68MIzcPq0FTHVcLNB47X6SBpe+DEhqhWwHn61F/YTksNVMtf9i6faAEypTSbwwIDAQAB";
const privateKey =
  "MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAKSpeTRCgXiNCb4CmJ9fEkC4J/Lz06EzGdr30ZXDQJCFtCvmDSC+MB+AD6TtZTTD7rQd8EhpXadVKHQxK4AoVCO01DE0OvSfkEPhTEPBrDWHL1XrwwjNw+rQVMdVws0HjtfpIGl74MSGqFbAefrUX9hOSw1Uy1/2Lp9oATKlNJvDAgMBAAECgYAcz5ZogXZ77FmXCIdB43WjI1XCGc2et/0/Jfti8+35G5vhznffkceDy3lxRyRdLic3FsHzOZRscecJJY1R+Dx4VrrTpWvZ5ADLMdMeMfhv8KLF9lcEqky97x4a0TqZJ7Ymfn5whQ6jq2y0h/uNF1yPXKGxrpySEi9sGt8FucfriQJBAOJ4Njr+vd8zT/OR72+qDVXSa+YourozoQhgPXCz14IalBTh6KmDQZNfJDf3vo5KFLjMwAnXSNntpYAEf4vvoacCQQC6Ig+kE6oyZ+j0CfCMWgep7IoW0NKF7NeXmTsratcEvn2i4p0NO4oA6wglKTOz9CvQlhIrZxfUiP1tTwr/EWCFAkAwDNNsxvngLpHrGu6TYaA8sk2kYsXjleq3zTub+vxhpWP/3kKwHKJAM3KK0WzD4eHtp7ujGuNhIbP4qnupZCmHAkACJx6zMR8I0jbZi7vig7APARNfzC6DKggcUsNibyvpfCKL3b8fRg8Ej7Naaq2UaUCQewvewKR/6s2zk/R2msbZAkEAjHDi+RlBHNyC4hkPcVKyGjtZYePhJQUewrFpIoKez1+cZQayyAPm8dRChPEv7awQ44wUJSGs59UiZzR7nLBC4A==";
//后端颁发的公钥
const publicJavaKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCXuw/bQoLzQdfYNBZrTDMbD0hBfMn+CRElw+14OxpqkW3bpkZ+pI0bVE/CtGUQvq5Cr8eHnIpeKPPJcKpB8W+gqfTTDQrw3SSDix7Ex19aFIzHiLKN9oI5d7POoaE/Z0MwmsTvzqnTs5JcTAqmsPnaJ6X4yRDNJu6R5Qq4ZrOahQIDAQAB";

/**
 * @copyright CBI cbi@chinacbi.com
 * @description RSA加密
 * @param data
 * @returns {Promise<{param: PromiseLike<ArrayBuffer>}|*>}
 */
export function encrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}
export function encryptEn(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicJavaKey); // 设置公钥
  return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description RSA解密
 * @param data
 * @returns {PromiseLike<ArrayBuffer>}
 */
export function decrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey);
  return encryptor.decrypt(txt);
}

/**
 * @copyright CBI cbi@chinacbi.com
 * @description 用于解密生成报告path和oss参数
 * @param data
 * @returns {PromiseLike<ArrayBuffer>}
 */
export function deBackcrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey);
  return encryptor.decrypt(txt);
}
