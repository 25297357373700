<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class"
                   @submit.native.prevent>
            <el-row :gutter="20">
              <!-- 流转日期范围  -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="transitDate">
                  <el-date-picker v-model="queryForm.transitDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="流转开始日期"
                                  end-placeholder="流转结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 工单编号 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="workOrderNo">
                  <el-input v-model.number.trim="queryForm.workOrderNo"
                            placeholder="工单编号"></el-input>
                </el-form-item>
              </el-col>
              <!-- 目标主体 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="targetSubject">
                  <el-input v-model.trim="queryForm.targetSubject"
                            placeholder="目标主体"></el-input>
                </el-form-item>
              </el-col>
              <!-- 项目 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectId">
                  <el-cascader :value="queryForm.projectId"
                               :options="projectInfo"
                               clearable
                               :filterable="true"
                               separator=":"
                               placeholder="项目"
                               @change="handleProjectChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="isShow"
                    :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="orderDate">
                  <el-date-picker v-model="queryForm.orderDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 选择人员 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="userInfo">
                  <el-cascader :value="queryForm.userInfo"
                               :options="staffInfo"
                               clearable
                               :filterable="true"
                               separator=":"
                               placeholder="选择人员"
                               @change="handleSatffChange"></el-cascader>
                </el-form-item>
              </el-col>

              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="normalFallbackFlag">
                  <el-select v-model="queryForm.normalFallbackFlag"
                             placeholder="是否普通退回"
                             clearable
                             filterable>
                    <el-option v-for="item in isGiveBacks"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 选择地区 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="selectedOptions">
                  <el-cascader v-model="queryForm.address"
                               :options="cityInfo"
                               :filterable="true"
                               clearable
                               separator="/"
                               placeholder="选择地区"
                               :props="{ checkStrictly: true }"
                               @change="handleBlockChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="isShow"
                    :gutter="20">
              <!-- 内部截止日期 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="innerDeadDate">
                  <el-date-picker v-model="queryForm.innerDeadDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="内部截止开始日期"
                                  end-placeholder="内部截止结束日期"
                                  :picker-options="pickerOptions"
                                  clearable></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 到期时间-->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="reachTime">
                  <el-select v-model="queryForm.reachTime"
                             placeholder="到期时间"
                             clearable
                             filterable>
                    <el-option v-for="item in reachTimeOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 订单编号 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="brWorkNo">
                  <el-input v-model.trim="queryForm.brWorkNo"
                            placeholder="订单编号"
                            clearable
                            @keyup.enter.native="handleQuery"></el-input>
                </el-form-item>
              </el-col>
              <!-- 报告类型 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="reportType">
                  <el-select v-model="queryForm.reportType"
                             placeholder="报告类型"
                             clearable
                             filterable>
                    <el-option v-for="item in reportType"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 项目来源 -->
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <el-form-item prop="projectSource">
                  <el-select v-model="queryForm.projectSource"
                             placeholder="请选择项目来源"
                             clearable
                             filterable>
                    <el-option v-for="item in resourceOptions"
                               :key="item.key"
                               :label="item.name"
                               :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-upload"
                     type="primary"
                     @click="handleSubmit">
            批量提交
          </el-button>
          <el-button icon="el-icon-back"
                     type="warning"
                     @click="handleWithdraw">
            批量退回
          </el-button>
          <el-button :loading="downloadLoading"
                     type="primary"
                     @click="handleDownload">
            导出
          </el-button>

          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    :row-class-name="tableRowClassName"
                    style="margin-top: 15px"
                    border
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"></el-table-column>
            <el-table-column label="工单编号"
                             prop="workOrderNo"
                             align="center"
                             width="130">
              <template slot-scope="scope">
                <div class="img-icon">
                  <img v-if="scope.row.operStatus === 31"
                       src="@/icon/back.svg"
                       alt="退"
                       class="my-back-img" />
                  <img v-if="scope.row.brChangeFlag"
                       src="@/icon/edit.svg"
                       alt="修"
                       class="my-edit-img" />
                </div>
                {{ scope.row.workOrderNo }}
              </template>
            </el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="目标地址"
                             min-width="250"
                             prop="address">
              <template slot-scope="scope">
                {{ scope.row.address || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目"
                             prop="projectName"
                             width="150">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="maturityDate"
                             label="到期时间(天)"
                             width="70"
                             align="center"></el-table-column>
            <el-table-column prop="circulationTime"
                             label="流转日期"
                             align="center"></el-table-column>
            <!-- 预约走访时间 -->
            <el-table-column prop="bookVisitTime"
                             label="预约走访时间"
                             width="130"
                             align="center"
                             sortable>
              <template slot-scope="scope">
                {{ scope.row.bookVisitTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="bookUserName"
                             label="预约人员"></el-table-column>
            <el-table-column prop="stepAndStranded"
                             align="center"
                             width="90"
                             label="环节/整体滞留(天)"
                             fit="true"></el-table-column>
            <el-table-column label="操作"
                             width="140px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="提交"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-top"
                             circle
                             @click="handleSubmit(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="编辑"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             circle
                             icon="el-icon-edit-outline"
                             @click="handleEdit(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item"
                            effect="dark"
                            content="退回"
                            placement="top-start">
                  <el-button size="mini"
                             type="warning"
                             icon="el-icon-back"
                             circle
                             @click="handleWithdraw(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!--提交工单-->
    <submit ref="submit"
            @refreshData="refreshData"></submit>
    <!--编辑工单-->
    <edit ref="edit"
          @refreshData="refreshData"
          @fetchData="fetchData"></edit>
    <!--退囘工單-->
    <withdraw ref="withdraw"
              @refreshData="refreshData"></withdraw>
  </div>
</template>

<script>
import { getTodo, doDelete } from "@/api/workplace/supervison/recommend"
import submit from "./submit"
import edit from "./edit"
import withdraw from "./withdraw"
import {
	returnBitTimestamp,
	returnDeadtimestamp,
	getRoleKey,
	eighthBitTimestamp,
} from "@/utils"
import { mapGetters } from "vuex"
import {
	getBlock,
	getGeneralReason,
	getProjectSource,
	querySystemUsers,
	getReportTypeList,
} from "@/api/workplace/tools"
import { export_json_to_excel } from "@/vendor/ExportExcel"

export default {
	name: "ComprehensiveTable",
	components: {
		submit,
		edit,
		withdraw,
	},
	filters: {
		statusFilter(status) {
			const statusMap = {
				published: "success",
				draft: "gray",
				deleted: "danger",
			}
			return statusMap[status]
		},
	},
	data() {
		return {
			transferTitle: ["选择员工", "已选择 1 个，还可以选 0 个"],
			mode: "transfer",
			isShow: false,
			imgShow: true,
			list: [],
			imageList: [],
			listLoading: true,
			layout: "total, sizes, prev, pager, next, jumper",
			total: 0,
			background: true,
			selectRows: "",
			elementLoadingText: "正在加载...",
			queryForm: {
				transitDate: [], // 流转日期范围，不传递到后端
				circulationTimeEnd: null,
				circulationTimeStart: null,
				orderDate: [], // 下单时间范围，不传递到后端
				orderTimeEnd: null,
				orderTimeStart: null,
				workOrderNo: "",
				targetSubject: "",
				projectId: "",
				userInfo: null, // 人员信息
				roleUser: null, // 选择人员参数

				innerDeadDate: [], // 内部截止日期范围，不传递到后端
				innerDeadlineEnd: null,
				innerDeadlineStart: null,
				bookUser: "",
				address: "",
				reachTime: "",

				normalFallbackFlag: "",
				normalFallbackReasonId: "",

				pageNum: 1,
				pageSize: 10,
				nextUserNo: "",
				nextUserRoleNo: "",
				// userNo: "",
				// userRoleNo: "",
			},
			// 查询人员推荐列表参数
			param: {
				body: {},
				header: {
					currentTime: "2020-09-09 12:12:12",
					requestId: "1",
					sourceType: "site",
				},
			},
			staffInfo: [],
			staffForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},

			// 项目
			projectInfo: [],
			projectForm: {
				body: {
					source: "",
				},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},
			//   角色人员
			roleOptions: [],
			roleOptions_arr: [],
			roleForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},

			cityInfo: [],
			cityForm: {
				body: {},
				header: {
					currentTime: "",
					requestId: "123",
					sourceType: "site",
				},
			},

			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit("pick", [start, end])
						},
					},
				],
			},

			// 选择到期时间
			reachTimeOptions: [
				{
					value: 0.5,
					label: "0.5天",
				},
				{
					value: 1,
					label: "1天",
				},
				{
					value: 2,
					label: "2天",
				},
				{
					value: 3,
					label: "3天",
				},
				{
					value: 5,
					label: "5天",
				},
				{
					value: 7,
					label: "7天",
				},
				{
					value: 10,
					label: "10天",
				},
				{
					value: 15,
					label: "15天",
				},
				{
					value: 20,
					label: "20天",
				},
				{
					value: 30,
					label: "30天",
				},
			],
			isGiveBacks: [
				{
					value: 1,
					label: "是",
				},
				{
					value: 0,
					label: "否",
				},
			],
			//  退回原因参数
			backReasonParam: {
				body: {
					templateTypeSeqId: "6694249429643431936",
				},
				header: {
					currentTime: "2020-09-09 12:12:12",
					requestId: "1",
					sourceType: "site",
				},
			},
			reasonOptions: [],
			downloadLoading: false,
			reportType: [], // 报告类型
			// 项目来源
			resourceOptions: [
				{
					name: "香港业务流程",
					key: "process_hk",
				},
				{
					name: "本地业务流程",
					key: "process_nd",
				},
				{
					name: "BR业务流程",
					key: "process_br",
				},
			],
		}
	},
	computed: {
		...mapGetters({
			userNo: "user/userNo",
			roles: "user/roles",
		}),
	},
	created() {
		this.param.body.userNo = this.userNo
		this.param.body.userRoleNo = getRoleKey("supervisor", this.roles)
	},
	beforeDestroy() {},
	mounted() {},
	methods: {
		/** 数据初始化 */
		handleInitData() {
			this.fetchData()
			// 获取项目
			this.getProject()
			// 获取人员选项
			this.getStaffInfo()
			// 获取报告类型
			this.getReportType()
		},
		/** 导出Excel */
		handleDownload() {
			if (this.selectRows.length > 0) {
				this.downloadLoading = true
				const tHeader = [
					"工单编号",
					"订单编号",
					"目标主体",
					"目标地址",
					"项目",
					"报告类型",
					"二级类型",
					"到期时间(天)",
					"流转日期",
					"预约走访时间",
					"预约人员",
					"环节/整体滞留(天)",
				]
				const filterVal = [
					"workOrderNo",
					"brWorkNo",
					"targetSubject",
					"address",
					"projectName",
					"reportType",
					"subReportType",
					"maturityDate",
					"circulationTime",
					"bookVisitTime",
					"bookUserName",
					"stepAndStranded",
				]
				const list = this.selectRows
				const data = this.formatJson(filterVal, list)
				export_json_to_excel({
					header: tHeader,
					data,
					filename: "工单列表",
				})
				this.downloadLoading = false
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},
		/** 组装导出数据 */
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					if (!v[j]) {
						return "—"
					}
					if ("circulationTime" === j && v["circulationTime"]) {
						//格式化下单日期
						return eighthBitTimestamp(Date.parse(v["circulationTime"]))
					}
					return v[j]
				})
			)
		},
		// 刷新数据
		refreshData() {
			/* 添加tab标签  传参 */
			let refresh_data = {
				todo_total: this.total,
				isDone: true,
			}
			this.$emit("child-todo", refresh_data)
			this.fetchData()
		},
		setSelectRows(val) {
			this.selectRows = val
		},
		// 重置
		resetForm(formName) {
			if (this.$refs[formName] !== undefined) {
				this.$refs[formName].resetFields()
				this.queryForm = this.$options.data().queryForm
			}
		},

		// 单个提交及批量提交
		handleSubmit(row) {
			// 单个提交
			if (row.id) {
				let rows = []
				rows.push(row)
				this.$refs["submit"].showSubmit(rows)
			} else {
				// 批量提交
				if (this.selectRows.length > 0) {
					let array = []
					this.selectRows.map((item) => {
						if (-1 === array.indexOf(item.workOrderStatus)) {
							array.push(item.workOrderStatus)
						}
					})
					if (1 !== array.length) {
						this.$baseMessage("当前工单状态不一致，无法批量提交", "warning")
						return false
					} else {
						this.$refs["submit"].showSubmit(this.selectRows)
					}
				} else {
					this.$baseMessage("未选中任何行", "error")
					return false
				}
			}
		},
		handleEdit(row) {
			row.userNo = this.queryForm.userNo
			row.userRoleNo = this.queryForm.userRoleNo
			this.$refs["edit"].showEdit(row)
		},
		// 单个退回及批量退回
		handleWithdraw(row) {
			// 单个退回
			if (row.id) {
				let rows = []
				rows.push(row)
				this.$refs["withdraw"].showWithdraw(rows)
			} else {
				// 批量退回
				if (this.selectRows.length > 0) {
					let array = []
					this.selectRows.map((item) => {
						if (-1 === array.indexOf(item.workOrderStatus)) {
							array.push(item.workOrderStatus)
						}
					})
					if (1 !== array.length) {
						this.$baseMessage("当前工单状态不一致，无法批量退回", "warning")
						return false
					} else {
						this.$refs["withdraw"].showWithdraw(this.selectRows)
					}
				} else {
					this.$baseMessage("未选中任何行", "error")
					return false
				}
			}
		},

		handleSizeChange(val) {
			this.queryForm.pageSize = val
			this.fetchData()
		},
		handleCurrentChange(val) {
			this.queryForm.pageNum = val
			this.fetchData()
		},
		heightQuery() {
			this.isShow = !this.isShow
			// 展开时查询选项的下拉数据
			if (this.isShow) {
				// 获取地区
				this.getAddress()
				// 获取普通退回原因
				this.getNormalReason()
			}
		},
		async getNormalReason() {
			this.reasonOptions = Object.assign(
				{},
				(await getGeneralReason(this.backReasonParam)).body
			)
		},
		handleQuery() {
			this.queryForm.pageNum = 1
			this.fetchData()
		},
		// 获取人员推荐列表分页信息
		async fetchData() {
			this.listLoading = true
			// 选择人员数据
			if (this.queryForm.userInfo && this.queryForm.userInfo.length > 0) {
				this.queryForm.roleUser = `${this.queryForm.userInfo[0]}:${this.queryForm.userInfo[1]}`
			} else {
				this.queryForm.roleUser = ""
			}
			// 流转日期范围
			if (null !== this.queryForm.transitDate) {
				if (0 < this.queryForm.transitDate.length) {
					this.queryForm.circulationTimeStart = returnBitTimestamp(
						this.queryForm.transitDate[0]
					)
					this.queryForm.circulationTimeEnd = returnDeadtimestamp(
						this.queryForm.transitDate[1]
					)
				}
			} else {
				this.queryForm.circulationTimeStart = null
				this.queryForm.circulationTimeEnd = null
			}
			// 下单日期范围
			if (null !== this.queryForm.orderDate) {
				if (0 < this.queryForm.orderDate.length) {
					this.queryForm.orderTimeStart = returnBitTimestamp(
						this.queryForm.orderDate[0]
					)
					this.queryForm.orderTimeEnd = returnDeadtimestamp(
						this.queryForm.orderDate[1]
					)
				}
			} else {
				this.queryForm.orderTimeStart = null
				this.queryForm.orderTimeEnd = null
			}
			// 内部截止日期范围
			if (null !== this.queryForm.innerDeadDate) {
				if (0 < this.queryForm.innerDeadDate.length) {
					this.queryForm.innerDeadlineStart = returnBitTimestamp(
						this.queryForm.innerDeadDate[0]
					)

					this.queryForm.innerDeadlineEnd = returnDeadtimestamp(
						this.queryForm.innerDeadDate[1]
					)
				}
			} else {
				this.queryForm.innerDeadlineStart = null
				this.queryForm.innerDeadlineEnd = null
			}
			this.param.body = Object.assign(this.param.body, this.queryForm)
			const { body } = await getTodo(this.param)
			this.list = body.records
			this.total = body.total
			let refresh_data = {
				todo_total: this.total,
				isDone: false,
			}
			this.$emit("child-todo", refresh_data)
			this.listLoading = false
		},

		// 表格显色
		tableRowClassName({ row }) {
			// 到期时间小于3，行内高亮
			if (row.maturityDate <= 3) {
				return "warning-row"
			} else return ""
		},

		// 获取地区
		getAddress() {
			this.cityForm.header.currentTime = returnBitTimestamp(new Date())
			getBlock(this.cityForm).then((response) => {
				if ("1" === response.header.retCode) {
					let block_info = []
					block_info.push(response.body[0])
					this.cityInfo = block_info
				} else {
					this.$message.error(
						`${response.header.retMessage},获取地区失败，请重新刷新页面`
					)
				}
			})
		},
		// 选择地区
		handleBlockChange(value) {
			this.queryForm.address = value.join(",")
		},
		// 获取项目
		getProject() {
			this.projectForm.header.currentTime = returnBitTimestamp(new Date())
			getProjectSource(this.projectForm).then((response) => {
				if ("1" === response.header.retCode) {
					this.projectInfo = response.body
				}
			})
		},
		/** 获取人员信息 */
		async getStaffInfo() {
			this.staffForm.header.currentTime = returnBitTimestamp(new Date())
			const { header, body } = await querySystemUsers(this.staffForm)
			if (header.retCode === "1") {
				this.staffInfo = body
			}
		},
		/** 选择人员数据框改变值 */
		handleSatffChange(value) {
			if (value) this.queryForm.userInfo = value
		},
		// 选择项目
		handleProjectChange(value) {
			this.queryForm.projectId = value[1]
		},
		// 获取报告分类
		getReportType() {
			getReportTypeList(this.param).then((response) => {
				if (response.header.retCode === "1") {
					this.reportType = response.body
				}
			})
		},
	},
}
</script>
<style scoped>
.el-col {
	margin-bottom: 10px;
}
.el-col :last-child {
	margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100%;
}

.el-select,
.el-cascader {
	width: 100%;
}

.el-dropdown-menu__item {
	padding: 0;
}
.el-table /deep/.warning-row {
	background: #f8b7bc;
}
.el-table /deep/.change-row {
	background: #ffff9f;
}
.my-form-class {
	margin-bottom: -10px !important;
}
</style>
<style>
.my-back-img {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 4px;
	left: 2px;
}
</style>
