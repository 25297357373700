var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "myDialog",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "60%",
            fullscreen: _vm.fullFlag,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("el-backtop", { attrs: { target: ".myDialog" } }),
          _c("i", {
            class: _vm.fullIcon,
            staticStyle: {
              position: "absolute",
              top: "24px",
              right: "41px",
              cursor: "pointer",
            },
            attrs: { title: _vm.fullTitle },
            on: { click: _vm.triggerFullOrHelf },
          }),
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _vm._v(_vm._s(_vm.companyName)),
          ]),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "edit_title",
                  attrs: { label: "工单详情", name: "detail" },
                },
                [
                  _c("order-process", { attrs: { param: _vm.param } }),
                  _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "workOrderNo",
                          label: "工单编号",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.workOrderNo || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brWorkNo",
                          label: "订单编号",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.brWorkNo || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reportType",
                          label: "报告类型",
                          "class-name":
                            _vm.brChange("reportTypeFlag") +
                            " " +
                            _vm.brChange("subReportTypeFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.reportType || "-") +
                                    " " +
                                    _vm._s(scope.row.subReportType ? "/" : "") +
                                    " " +
                                    _vm._s(scope.row.subReportType) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "projectSource",
                          label: "项目来源",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getProjectSource(
                                        scope.row.projectSource
                                      ) || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubject",
                          label: "目标主体",
                          "class-name": _vm.brChange("subjectFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetSubject || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetAddr1",
                          label: "目标地址1",
                          "class-name": _vm.brChange("subjectAddressFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetAddr1 || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetAddr2",
                          label: "目标地址2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetAddr2 || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetContact",
                          label: "目标联系人",
                          "class-name": _vm.brChange("subjectLinkNameFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.targetContact || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "contactNumber",
                          label: "联系电话",
                          "class-name": _vm.brChange("subjectMobileFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.contactNumber || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "duty",
                          label: "职务",
                          "class-name": _vm.brChange("dutyFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.duty || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderTime",
                          label: "下单时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.orderTime || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "innerDeadline",
                          label: "内部截止日期",
                          align: "center",
                          formatter: _vm.eighthBitTime,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "clientAbbr",
                          label: "委托方简称",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.clientAbbr || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reportTimeLimit",
                          label: "报告时限",
                          "class-name": _vm.brChange("reportTimeLimitFlag"),
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.reportTimeLimit || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark1",
                          label: "备注1",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark1 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark2",
                          label: "备注2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark2 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark3",
                          label: "备注2",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark3 || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column"),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("附加信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectVisitDate",
                          label: "预计走访日期",
                          align: "center",
                          formatter: _vm.eighthBitTime,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectServeyDay",
                          label: "预计所需调查天数",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.expectServeyDay || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "needUser",
                          label: "所需人手",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.needUser || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "expectHour",
                          label: "预计需时（小时）",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.expectHour || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "registerAddress",
                          label: "注册地址",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.registerAddress || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "targetSubjectRegStatus",
                          label: "目标主体注册状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.targetSubjectRegStatus || "-"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("特别信息")]),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, stripe: "", border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "plSpecialMsg",
                          label: "PL特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.plSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bookSpecialMsg",
                          label: "预约特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.bookSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "monitorSpecialMsg",
                          label: "督导特别留言",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.monitorSpecialMsg || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.annexGroup.length !== 0,
                          expression: "annexGroup.length !== 0",
                        },
                      ],
                      staticClass: "title",
                    },
                    [_vm._v("附件")]
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "20px",
                      },
                    },
                    _vm._l(_vm.annexGroup, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 } },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.handleDownload({
                                        name: item.fileName,
                                        path: item.path,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/icon/annex.svg"),
                                      alt: "",
                                      width: "80",
                                      height: "80",
                                    },
                                  }),
                                  _c("p", [_vm._v(_vm._s(item.fileName))]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("操作信息")]),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.operationInfo,
                        stripe: "",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "operator",
                          label: "操作者",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.operator || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "role", label: "角色", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.role || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "operAction",
                          label: "操作状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.operAction || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "操作时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.createTime || "-") +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark",
                          label: "备注",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.remark || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [_vm._v("补充工单")]),
                  _c(
                    "el-form",
                    {
                      ref: "orderForm",
                      staticClass: "table-class demo-form-inline",
                      attrs: { model: _vm.orderForm, rules: _vm.rules },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 80 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "会议开始时间",
                                    prop: "startTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetime",
                                      placeholder: "选择开始时间",
                                    },
                                    model: {
                                      value: _vm.orderForm.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderForm,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "orderForm.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "会议结束时间",
                                    prop: "endTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetime",
                                      placeholder: "选择结束时间",
                                    },
                                    model: {
                                      value: _vm.orderForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.orderForm, "endTime", $$v)
                                      },
                                      expression: "orderForm.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "录像名称",
                                    prop: "videoName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入录像名称",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.orderForm.videoName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderForm,
                                          "videoName",
                                          $$v
                                        )
                                      },
                                      expression: "orderForm.videoName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "录像路径",
                                    prop: "videoPath",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入录像路径",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.orderForm.videoPath,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.orderForm,
                                          "videoPath",
                                          $$v
                                        )
                                      },
                                      expression: "orderForm.videoPath",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "小记", prop: "note" } },
                                [
                                  _c("el-input", {
                                    staticClass: "my-textarea",
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入小记",
                                    },
                                    model: {
                                      value: _vm.orderForm.note,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.orderForm, "note", $$v)
                                      },
                                      expression: "orderForm.note",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "取证资料", name: "evidence" } },
                [
                  _c("evidence", {
                    ref: "evidence",
                    attrs: { row: _vm.object },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "拍摄照片" } },
                [
                  _c("photo", {
                    ref: "photo",
                    attrs: { type: "50", row: _vm.object },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "拍摄录像" } },
                [
                  _c("screenVideo", {
                    ref: "screenVideo",
                    attrs: { type: "51", row: _vm.object },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "扫描文件", name: "scanFile" } },
                [
                  _c("scanFile", {
                    ref: "scanFile",
                    attrs: { type: "52", row: _vm.object },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "录制音频" } },
                [
                  _c("screenAudio", {
                    ref: "screenAudio",
                    attrs: { type: "53", row: _vm.object },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "其他文件" } },
                [
                  _c("telRecord", {
                    ref: "telRecord",
                    attrs: { type: "54", row: _vm.object },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "打卡记录" } },
                [
                  _c("clockIn", {
                    ref: "clockIn",
                    attrs: { type: "55", row: _vm.object },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "查看小记" } },
                [_c("remark", { ref: "remark", attrs: { param: _vm.param } })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "退回记录" } },
                [_c("back", { ref: "back", attrs: { order: _vm.orderNo } })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "企业信息" } },
                [_c("company", { ref: "company", attrs: { row: _vm.object } })],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.activeName === "detail"
                ? _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v(" 取消 "),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.withdraw } },
                [_vm._v("退回")]
              ),
              _vm.activeName === "evidence"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleReport },
                    },
                    [_vm._v(" 生成报告 ")]
                  )
                : _vm._e(),
              _vm.activeName === "detail"
                ? _c(
                    "el-button",
                    { attrs: { type: "success" }, on: { click: _vm.save } },
                    [_vm._v(" 保存 ")]
                  )
                : _vm._e(),
              _vm.activeName === "evidence" && _vm.isSurveyUser === "Y"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.saveEnvidence },
                    },
                    [_vm._v(" 保存 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("withdraw", {
        ref: "withdraw",
        on: { close: _vm.close, refreshData: _vm.refreshData },
      }),
      _c("submit", {
        ref: "submit",
        on: { close: _vm.close, refreshData: _vm.refreshData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }