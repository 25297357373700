<!-- 录像预览Dialog-->
<template>
  <!-- 录像预览 -->
  <el-dialog
    :visible.sync="dialogvideoVisible"
    append-to-body
    class="videoDialog"
    @close="closeView"
  >
    <el-tabs
      v-model="activeTabsValue"
      :tab-position="tabPosition"
      style="height: 350px"
      @tab-click="handleTabsClick"
    >
      <el-tab-pane
        v-for="(videoItem, videoIndex) in videoData"
        :key="videoIndex"
        :name="videoItem.name"
        :stretch="true"
        :label="
          videoItem.name.length > 12
            ? videoItem.name.substring(0, 12) + '...'
            : videoItem.name
        "
      >
        <el-row>
          <el-col>
            <el-row>
              <el-col style="text-align: center">
                <el-tooltip
                  :content="videoItem.name"
                  placement="top"
                  effect="light"
                >
                  <p style="width: 95%; margin: 0 auto; text-align: center">
                    {{
                      videoItem.name +
                      "." +
                      videoItem.path.substring(
                        videoItem.path.lastIndexOf(".") + 1
                      )
                    }}
                  </p>
                </el-tooltip>
                <video
                  v-if="judgeFileType(videoItem.path)"
                  ref="myVideo"
                  :src="ossPath + videoItem.path"
                  controls="controls"
                  style="width: 95%; height: 300px"
                ></video>
                <!-- audio ——>不可预览 -->
                <img
                  v-else
                  style="width: 160px; height: 160px; cursor: pointer"
                  src="@/icon/luxiang.svg"
                  :fit="fit"
                  :title="videoItem.name"
                  @click="handleDownload(videoItem)"
                />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
  import { downloadFile, isExistOSS } from "@/utils/index";
  export default {
    name: "ScreenVideoView",
    data() {
      return {
        videoData: "",
        tabPosition: "left",
        dialogvideoVisible: false,
        fit: "fill",
        activeTabsValue: "2",
        video_index: "",
        urlList: [],
        tab_name: "",
        ossPath: "",
        OSSClient: {},
      };
    },
    created() {},
    methods: {
      showView() {
        this.dialogvideoVisible = true;
      },
      handleTabsClick() {
        this.$refs.myVideo.forEach((item) => {
          item.pause(); //暂停
        });
      },
      closeView() {
        this.$refs.myVideo.forEach((item) => {
          item.pause(); //暂停
        });
      },
      /** 判断文件类型 */
      judgeFileType(filePath) {
        /** 可预览的文件类型 */
        // 优化
        const fileType = ["mp4", "ogg", "webm"];
        fileType.map((item) => {
          fileType.push(item.toUpperCase());
        });
        const itemType = filePath.substring(filePath.lastIndexOf(".") + 1);
        return fileType.includes(itemType);
      },
      /** 点击下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
      bindData(videoData, videoIndex, videoName, ossPath, OSSClient) {
        this.ossPath = ossPath;
        this.OSSClient = OSSClient;
        this.activeTabsValue = videoName;
        this.videoData = videoData;
      },
    },
  };
</script>

<style scoped>
  .videoDialog {
    z-index: 10;
  }
  .videoDialog /deep/.el-dialog__header {
    padding: 0 20px;
  }
  .videoDialog /deep/.el-dialog__body {
    padding-top: 40px;
  }
</style>
