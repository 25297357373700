var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "预览",
        visible: _vm.phoneOpen,
        width: "60%",
        "close-on-click-modal": false,
        "append-to-body": "",
        "before-close": _vm.getBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.phoneOpen = $event
        },
      },
    },
    [
      _vm.reloadCreate ? _c("div", { attrs: { id: "form-create" } }) : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.getBack } }, [_vm._v("返 回")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }