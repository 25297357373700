<template>
  <el-tabs
    type="border-card"
    :tab-position="tabPosition"
    @tab-click="handleClick"
  >
    <el-tab-pane label="工单详情" class="edit_title">
      <el-card class="box-card">
        <!--工单进度-->
        <order-process :param="param"></order-process>
        <!--基础信息>-->
        <div class="title">基础信息</div>

        <el-table :data="tableData" stripe border>
          <el-table-column
            prop="workOrderNo"
            label="工单编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="projectNameNo"
            label="Project Name"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="projectName"
            label="项目"
            align="center"
          ></el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column
            prop="targetSubject"
            label="目标主体"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="address"
            label="国家/地区"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="targetAddr1"
            label="目标地址1"
            align="center"
          ></el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column
            prop="targetAddr2"
            label="目标地址2"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="targetContact"
            label="目标联系人"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.targetContact || "-" }}
            </template>
          </el-table-column>

          <el-table-column prop="contactNumber" label="联系电话" align="center">
            <template slot-scope="scope">
              {{ scope.row.contactNumber || "-" }}
            </template>
          </el-table-column>
        </el-table>

        <el-table :data="tableData" stripe border>
          <el-table-column prop="duty" label="职务" align="center">
            <template slot-scope="scope">
              {{ scope.row.duty || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="orderTime" label="下单时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.orderTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="innerDeadline"
            label="内部截止日期"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.innerDeadline || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column prop="clientAbbr" label="委托方简称" align="center">
            <template slot-scope="scope">
              {{ scope.row.clientAbbr || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="reportTimeLimit"
            label="报告时限"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.reportTimeLimit || "-" }}
            </template>
          </el-table-column>

          <el-table-column prop="remark1" label="备注1" align="center">
            <template slot-scope="scope">
              {{ scope.row.remark1 || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column prop="remark2" label="备注2" align="center">
            <template slot-scope="scope">
              {{ scope.row.remark2 || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark3" label="备注3" align="center">
            <template slot-scope="scope">
              {{ scope.row.remark3 || "-" }}
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>

        <!--附加信息-->
        <div class="title">附加信息</div>
        <el-table :data="tableData" stripe border>
          <el-table-column
            prop="expectVisitDate"
            label="预计走访日期"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.expectVisitDate || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="expectServeyDay"
            label="预计所需调查天数"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.expectServeyDay || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="needUser" label="所需人手" align="center">
            <template slot-scope="scope">
              {{ scope.row.needUser || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column
            prop="expectHour"
            label="预计需时（小时）"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.expectHour || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="registerAddress"
            label="注册地址"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.registerAddress || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="targetSubjectRegStatus"
            label="目标主体注册状态"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.targetSubjectRegStatus || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column prop="visitFee" label="走访收费" align="center">
            <template slot-scope="scope">
              {{ scope.row.visitFee || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="urgentVisitFee"
            label="加急走访收费"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.urgentVisitFee || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="againVisitFee"
            label="再次走访收费"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.againVisitFee || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column prop="feeBasis" label="收费依据" align="center">
            <template slot-scope="scope">
              {{ scope.row.feeBasis || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="targetOrganization1"
            label="目标机构1"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.targetOrganization1 || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="targetOrganization2"
            label="目标机构2"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.targetOrganization2 || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData" stripe border>
          <el-table-column
            prop="targetResult"
            label="目标地址预核结果"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.targetResult || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="infeasibleReason"
            label="不可行原因/待确认项目"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.infeasibleReason || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="mailHeader" label="邮件抬头" align="center">
            <template slot-scope="scope">
              {{ scope.row.mailHeader || "-" }}
            </template>
          </el-table-column>
        </el-table>
        <!--特别信息-->
        <div class="title">特别信息</div>

        <el-table :data="tableData" stripe border>
          <el-table-column
            prop="plSpecialMsg"
            label="PL特别留言"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.plSpecialMsg || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="bookSpecialMsg"
            label="预约特别留言"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.bookSpecialMsg || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="monitorSpecialMsg"
            label="督导特别留言"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.monitorSpecialMsg || "-" }}
            </template>
          </el-table-column>
        </el-table>

        <!--附件-->
        <div v-show="annexGroup.length !== 0" class="title">附件</div>

        <el-row style="text-align: center; margin-top: 20px">
          <div v-for="(item, index) in annexGroup" :key="index">
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <a
                @click.stop.prevent="
                  handleDownload({ name: item.fileName, path: item.path })
                "
              >
                <img src="@/icon/annex.svg" alt="" width="80" height="80" />
                <p>{{ item.fileName }}</p>
              </a>
            </el-col>
          </div>
        </el-row>

        <!--操作信息-->
        <div class="title">操作信息</div>
        <el-table :data="operationInfo" stripe border>
          <el-table-column prop="operator" label="操作者" align="center">
            <template slot-scope="scope">
              {{ scope.row.operator || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="role" label="角色" align="center">
            <template slot-scope="scope">
              {{ scope.row.role || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="operAction" label="操作状态" align="center">
            <template slot-scope="scope">
              {{ scope.row.operAction || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="操作时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template slot-scope="scope">
              {{ scope.row.remark || "-" }}
            </template>
          </el-table-column>
        </el-table>

        <el-row style="text-align: center; margin-top: 20px">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-button type="default" @click="back()">返回</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-tab-pane>
    <el-tab-pane label="取证资料" name="evidence">
      <evidence ref="evidence" :row="object"></evidence>
    </el-tab-pane>
    <el-tab-pane label="拍摄照片">
      <photo ref="photo" :type="'50'" :row="object"></photo>
    </el-tab-pane>
    <el-tab-pane label="拍摄录像">
      <screenVideo ref="screenVideo" :type="'51'" :row="object"></screenVideo>
    </el-tab-pane>
    <el-tab-pane label="扫描文件" name="scanFile">
      <scanFile ref="scanFile" :type="'52'" :row="object"></scanFile>
    </el-tab-pane>
    <el-tab-pane label="录制音频">
      <screenAudio ref="screenAudio" :type="'53'" :row="object"></screenAudio>
    </el-tab-pane>
    <el-tab-pane label="其他文件">
      <telRecord ref="telRecord" :type="'54'" :row="object"></telRecord>
    </el-tab-pane>
    <el-tab-pane label="打卡记录">
      <clockIn ref="clockIn" :type="'55'" :row="object"></clockIn>
    </el-tab-pane>
    <el-tab-pane label="会议信息">
      <meeting ref="meeting" :type="'57'" :row="object"></meeting>
    </el-tab-pane>
    <el-tab-pane label="查看小记">
      <remark ref="remark" :param="param"></remark>
    </el-tab-pane>
    <el-tab-pane label="退回记录">
      <back :order="orderNo"></back>
    </el-tab-pane>
    <el-tab-pane label="标注记录">
      <labelRecord ref="labelRecord" :type="'56'" :row="object"></labelRecord>
    </el-tab-pane>
    <el-tab-pane label="企业信息">
      <company :row="object"></company>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import back from "@/views/workplace/components/back";
  import company from "@/views/workplace/components/company";
  import orderProcess from "@/views/workplace/components/orderProcess";
  import remark from "@/views/workplace/components/remark";
  import meeting from "@/views/workplace/components/meeting";
  // 取证资料
  import evidence from "@/views/workplace/components/evidence";
  // 拍摄照片
  import photo from "@/views/workplace/components/photo";
  // 拍摄录像
  import screenVideo from "@/views/workplace/components/screenVideo";
  // 扫描文件
  import scanFile from "@/views/workplace/components/scanFile";
  // 录制音频
  import screenAudio from "@/views/workplace/components/screenAudio";
  // 其他文件
  import telRecord from "@/views/workplace/components/telRecord";
  // 打卡记录
  import clockIn from "@/views/workplace/components/clockIn";
  import {
    getOperationInfo,
    getOrderDetail,
    searchAttach,
  } from "@/api/workplace/tools";
  import {
    getRoleKey,
    returnBitTimestamp,
    downloadFile,
    isExistOSS,
  } from "@/utils";
  import { mapGetters } from "vuex";
  import labelRecord from "@/views/workplace/components/labelRecord";
  export default {
    name: "Detail",
    components: {
      evidence,
      photo,
      screenVideo,
      scanFile,
      screenAudio,
      telRecord,
      clockIn,
      meeting,
      remark,
      back,
      labelRecord,
      company,
      orderProcess,
    },
    data() {
      return {
        annexGroup: [],
        orderNo: null,
        tabPosition: "top",
        tableData: [],
        // 获取工单详情
        param: {
          body: {
            userNo: "",
            userRoleNo: "",
            workOrderNo: null,
            pageSize: 10,
            pageNum: 1,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        operationInfo: [],
        // 查询工单的操作日志
        logParam: {
          body: {
            workOrderNo: "",
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },
        // 给子组件传值
        object: {
          userNo: null,
          userRoleNo: null,
          workOrderNo: null,
          seqId: null,
          targetSubject: null,
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
        OSSClient: "user/OSSClient",
        endpoinxBak: "user/endpoinxBak",
      }),
    },
    created() {
      this.param.body.userNo = this.userNo;
      this.param.body.userRoleNo = getRoleKey("qc", this.roles);
      this.showDetail();
      /** 查询附件信息 */
      this.attach();
    },
    methods: {
      /** 查询附件信息 */
      async attach() {
        this.param.body.workOrderNo = this.$route.query.workOrderNo;
        this.param.header.currentTime = returnBitTimestamp(new Date());
        const { body, header } = await searchAttach(this.param);
        if (header.retCode === "1") {
          this.annexGroup = body;
        } else {
          this.$baseMessage(header.retMessage, "error");
        }
      },
      back() {
        this.$router.go(-1);
      },
      async showDetail() {
        this.tableData = [];
        this.object.projectId = this.$route.query.projectId;
        this.orderNo = this.$route.query.workOrderNo;
        this.object.userNo = this.$route.query.userNo;
        this.object.userRoleNo = this.$route.query.userRoleNo;
        this.object.workOrderNo = this.$route.query.workOrderNo;
        this.object.seqId = this.$route.query.seqId;
        this.object.targetSubject = this.$route.query.target;
        this.object.endpoinxBak = this.endpoinxBak;
        this.object.OSSClient = this.OSSClient;
        this.object.workplace = "qc";
        this.object.flag = "detail";

        // 获取工单详情
        this.param.body.workOrderNo = this.$route.query.workOrderNo;
        this.tableData.push(
          Object.assign({}, (await getOrderDetail(this.param)).body)
        );
        // 获取操作工单日志信息
        this.logParam.body.workOrderNo = this.$route.query.workOrderNo;
        this.operationInfo = (await getOperationInfo(this.logParam)).body;
      },
      handleClick(tab, event) {
        this.$refs["remark"].fetchData();
        if (tab.name === "evidence") {
          this.$refs["evidence"].reset();
          this.$refs["evidence"].fetchData();
        } else if (tab.name === "scanFile") {
          this.$refs["scanFile"].fetchData();
        }
      },
      /** 附件下载 */
      handleDownload(item) {
        isExistOSS(this.OSSClient, item.path)
          .then(() => {
            downloadFile(item);
          })
          .catch(() => {
            this.$message.error("当前文件不存在！");
          });
      },
    },
  };
</script>

<style>
  body {
    font-weight: 700;
    color: #666;
  }
  .edit_title .title {
    margin: 10px 0;
  }
  .el-step__title {
    font-size: 14px;
  }
  .el-step__title.is-process {
    font-weight: bold;
    color: #666;
  }
  html body .el-table th .cell {
    font-weight: 700;
    color: #666;
  }

  .el-form-item__label {
    font-weight: normal;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }

  .myDiv {
    width: 150px;
    height: 150px;
    text-align: center;
  }

  .myDiv img {
    width: 130px;
    height: 130px;
    text-align: center;
  }
</style>
